import Form from '@bfly/ui2/Form';
import Textarea from '@bfly/ui2/Textarea';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { number, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { formMessages } from 'messages/formMessages';
import ALLOWED_TIMEZONES from 'utils/allowedTimezones';

import {
  characterEncodingMessages,
  connectionIdMessages,
  domainIdMessages,
  ehrIdMessages,
  hospitalHostMessages,
  hospitalPortMessages,
  nameMessages,
  organizationIdMessages,
  procedureIdPathMessages,
  processingIdMessages,
  receivingApplicationMessages,
  receivingFacilityMessages,
  sendingFacilityMessages,
  studyDescriptionMappingMessages,
  studyUrlDisplayMessages,
  timeoutAckMessages,
  timeoutConnectionMessages,
  timezoneMessages,
} from '../messages/ehrCreation';

const PROCESSING_IDS = ['P', 'D', 'T'];
const STUDY_URLS = ['NORMAL', 'NONE'];
const ENCODINGS = ['UTF_8', 'ISO_8859_1'];
export const PROCEDUR_ID_PATHS = [
  {
    value: 'RequestedProcedureCodeSequence.CodeValue',
    label: '(0008,0100) Procedure Code Value',
  },
  {
    value: 'ScheduledProcedureStepSequence.ScheduledProcedureStepID',
    label: '(0040,0009) Scheduled Procedure Step ID',
  },
];

const timeoutSchema = (typeError) =>
  number().integer().min(0).max(1000).typeError(typeError);

export const basicEhrSchema = object({
  name: string()
    .required(nameMessages.required)
    .min(2, formMessages.minLength),
  organizationId: string().nullable(true),
  domainId: string().nullable(true),
  connectionId: string().required(connectionIdMessages.required),
  procedureIdPath: string()
    .required(procedureIdPathMessages.required)
    .oneOf(PROCEDUR_ID_PATHS.map(({ value }) => value)),
  processingId: string().default('P').oneOf(PROCESSING_IDS),
  hospitalHost: string().required(hospitalHostMessages.required),
  hospitalPort: number()
    .integer()
    .min(0)
    .required(hospitalPortMessages.required)
    .typeError(hospitalPortMessages.typeError),
  timeoutAck: timeoutSchema(timeoutAckMessages.typeError).required(
    timeoutAckMessages.required,
  ),
  timeoutConnection: timeoutSchema(
    timeoutConnectionMessages.typeError,
  ).required(timeoutConnectionMessages.required),
  timezone: string().required(timezoneMessages.required),
  characterEncoding: string().required(characterEncodingMessages.required),
  receivingApplication: string().nullable(),
  receivingFacility: string().nullable(),
  sendingFacility: string().nullable(),
  studyDescriptionMapping: string()
    .typeError(studyDescriptionMappingMessages.typeError)
    .default('{}'),
  studyUrlDisplay: string()
    .oneOf(STUDY_URLS)
    .required(studyUrlDisplayMessages.required),
});
const schemaDefaults = basicEhrSchema.getDefault();

export function EhrModal<TMutation extends MutationParameters>({
  defaultValue,
  ...props
}: ResourceModalProps<TMutation>) {
  const isCreate = !defaultValue?.ehrId;
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation>
      {...props}
      defaultValue={{ ...schemaDefaults, ...defaultValue }}
      transformOnSubmit={({ studyDescriptionMapping, ...ehr }) => ({
        ...ehr,
        // JSON.parse due to Textarea field
        studyDescriptionMapping: JSON.parse(studyDescriptionMapping),
      })}
    >
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="ehrId"
          label={<FormattedMessage {...ehrIdMessages.label} />}
          placeholder={formatMessage(ehrIdMessages.placeholder)}
          data-cy="ehrModal-ehrIdField"
        />
      )}
      <Form.FieldGroup
        name="name"
        label={<FormattedMessage {...nameMessages.label} />}
        placeholder={formatMessage(nameMessages.placeholder)}
        className="name"
        data-cy="ehrModal-nameField"
      />
      <Form.FieldGroup
        name="organizationId"
        label={<FormattedMessage {...organizationIdMessages.label} />}
        placeholder={formatMessage(organizationIdMessages.placeholder)}
        data-cy="ehrModal-organizationIdField"
      />
      <Form.FieldGroup
        name="domainId"
        label={<FormattedMessage {...domainIdMessages.label} />}
        placeholder={formatMessage(domainIdMessages.placeholder)}
        data-cy="ehrModal-domainIdField"
      />
      <Form.FieldGroup
        name="connectionId"
        label={<FormattedMessage {...connectionIdMessages.label} />}
        placeholder={formatMessage(connectionIdMessages.placeholder)}
        data-cy="ehrModal-connectionIdField"
      />
      <Form.FieldGroup
        name="procedureIdPath"
        as={DropdownList}
        data={PROCEDUR_ID_PATHS}
        textField="label"
        dataKey="value"
        mapFromValue={({ value }) => {
          return value;
        }}
        label={<FormattedMessage {...procedureIdPathMessages.label} />}
        placeholder={procedureIdPathMessages.placeholder}
        variant="light"
        data-cy="ehrModal-procedureIdPathField"
      />
      <Form.FieldGroup
        name="processingId"
        as={DropdownList}
        data={PROCESSING_IDS}
        label={<FormattedMessage {...processingIdMessages.label} />}
        placeholder={processingIdMessages.placeholder}
        description={
          <FormattedMessage {...processingIdMessages.description} />
        }
        variant="light"
        data-cy="ehrModal-processingIdField"
      />
      <Form.FieldGroup
        name="hospitalHost"
        label={<FormattedMessage {...hospitalHostMessages.label} />}
        placeholder={formatMessage(hospitalHostMessages.placeholder)}
        data-cy="ehrModal-hospitalHostField"
      />
      <Form.FieldGroup
        name="hospitalPort"
        label={<FormattedMessage {...hospitalPortMessages.label} />}
        placeholder={formatMessage(hospitalPortMessages.placeholder)}
        data-cy="ehrModal-hospitalPortField"
      />
      <Form.FieldGroup
        name="timeoutAck"
        label={<FormattedMessage {...timeoutAckMessages.label} />}
        placeholder={formatMessage(timeoutAckMessages.placeholder)}
        data-cy="ehrModal-timeoutAckField"
      />
      <Form.FieldGroup
        name="timeoutConnection"
        label={<FormattedMessage {...timeoutConnectionMessages.label} />}
        placeholder={formatMessage(timeoutConnectionMessages.placeholder)}
        data-cy="ehrModal-timeoutConnectionField"
      />
      <Form.FieldGroup
        name="timezone"
        as={DropdownList}
        data={ALLOWED_TIMEZONES}
        label={<FormattedMessage {...timezoneMessages.label} />}
        placeholder={timezoneMessages.placeholder}
        textField="name"
        dataKey="value"
        mapFromValue={({ value }) => value}
        data-cy="ehrModal-timezoneField"
      />
      <Form.FieldGroup
        name="characterEncoding"
        as={DropdownList}
        data={ENCODINGS}
        label={<FormattedMessage {...characterEncodingMessages.label} />}
        placeholder={characterEncodingMessages.placeholder}
        data-cy="ehrModal-characterEncodingField"
      />
      <Form.FieldGroup
        name="receivingApplication"
        label={<FormattedMessage {...receivingApplicationMessages.label} />}
        placeholder={formatMessage(receivingApplicationMessages.placeholder)}
        data-cy="ehrModal-receivingApplicationField"
      />
      <Form.FieldGroup
        name="receivingFacility"
        label={<FormattedMessage {...receivingFacilityMessages.label} />}
        placeholder={formatMessage(receivingFacilityMessages.placeholder)}
        data-cy="ehrModal-receivingFacilityField"
      />
      <Form.FieldGroup
        name="sendingFacility"
        label={<FormattedMessage {...sendingFacilityMessages.label} />}
        placeholder={formatMessage(sendingFacilityMessages.placeholder)}
        data-cy="ehrModal-sendingFacilityField"
      />
      <Form.FieldGroup
        name="studyUrlDisplay"
        as={DropdownList}
        data={STUDY_URLS}
        label={<FormattedMessage {...studyUrlDisplayMessages.label} />}
        placeholder={studyUrlDisplayMessages.placeholder}
        data-cy="ehrModal-studyUrlDisplayField"
      />
      <Form.FieldGroup
        name="studyDescriptionMapping"
        as={Textarea}
        label={<FormattedMessage {...studyDescriptionMappingMessages.label} />}
        placeholder={studyDescriptionMappingMessages.placeholder}
        description={
          <FormattedMessage {...studyDescriptionMappingMessages.description} />
        }
        data-cy="ehrModal-studyDescriptionMappingField"
      />
    </ResourceModal>
  );
}
