/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ShowUserInviteLinkAction_userInvite = {
    readonly userInviteId: string | null;
    readonly linkUrl: string | null;
    readonly " $refType": "ShowUserInviteLinkAction_userInvite";
};
export type ShowUserInviteLinkAction_userInvite$data = ShowUserInviteLinkAction_userInvite;
export type ShowUserInviteLinkAction_userInvite$key = {
    readonly " $data"?: ShowUserInviteLinkAction_userInvite$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ShowUserInviteLinkAction_userInvite">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowUserInviteLinkAction_userInvite",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userInviteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkUrl",
      "storageKey": null
    }
  ],
  "type": "UserInvite",
  "abstractKey": null
};
(node as any).hash = 'bb5ab6b571e28b21b8d602b084193c03';
export default node;
