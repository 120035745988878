import Anchor from '@bfly/ui2/Anchor';
import BlankSlate from '@bfly/ui2/BlankSlate';
import MainContent from '@bfly/ui2/MainContent';
import Navbar from '@bfly/ui2/Navbar';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function ErrorPage({ error }) {
  return (
    <Page>
      <Navbar>
        <Navbar.Brand as={Anchor} href="/" />
      </Navbar>
      <Page.Container center data-bni-id="ErrorPage">
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                id="errorPage.title"
                defaultMessage="Looks like something went wrong."
              >
                {error.title}
              </FormattedMessage>
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                id="errorPage.body"
                defaultMessage="To retry, refresh the page."
              >
                {error.body}
              </FormattedMessage>
              <BlankSlate.Action>
                Reset your region and refresh
              </BlankSlate.Action>
            </BlankSlate.Body>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </Page>
  );
}

export default ErrorPage;
