import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { EhrModal, basicEhrSchema } from './EhrModal';
import { UpdateEhrModalMutation } from './__generated__/UpdateEhrModalMutation.graphql';
import { UpdateEhrModal_ehr$key } from './__generated__/UpdateEhrModal_ehr.graphql';

const updateEhrSchema = basicEhrSchema.shape({
  ehrId: string().meta({ readOnly: true }),
});

const fragment = graphql`
  fragment UpdateEhrModal_ehr on Ehr {
    id
    ehrId
    name
    connectionId
    createdAt
    updatedAt
    deletedAt
    hospitalHost
    hospitalPort
    timeoutAck
    timeoutConnection
    timezone
    processingId
    procedureIdPath
    receivingApplication
    receivingFacility
    sendingFacility
    characterEncoding
    studyDescriptionMapping
    studyUrlDisplay
    organization {
      organizationId
    }
    domain {
      domainId
    }
  }
`;

const updateMutation = graphql`
  mutation UpdateEhrModalMutation($input: UpdateEhrInput!) {
    updateEhr(input: $input) {
      ehr {
        ...EhrDetailPage_ehr
      }
    }
  }
`;

interface UpdateEhrModalProps extends ActionModalProps {
  ehrRef: UpdateEhrModal_ehr$key;
}

export function UpdateEhrModal({ ehrRef, ...props }: UpdateEhrModalProps) {
  const { studyDescriptionMapping, organization, domain, ...ehr } =
    useFragment(fragment, ehrRef);
  return (
    <EhrModal<UpdateEhrModalMutation>
      {...props}
      schema={updateEhrSchema}
      mutation={updateMutation}
      defaultValue={{
        ...ehr,
        organizationId: organization?.organizationId,
        domainId: domain?.domainId,
        // JSON.stringify due to Textarea field
        studyDescriptionMapping: JSON.stringify(studyDescriptionMapping),
      }}
      title={
        <FormattedMessage
          id="UpdateEhrModal.title"
          defaultMessage="Update EHR"
        />
      }
      name={ehr.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
    />
  );
}
