import Header from '@bfly/ui2/Header';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import { UserDefinedRolesPage_userDefinedRole as UserDefinedRole } from './__generated__/UserDefinedRolesPage_userDefinedRole.graphql';
import { UserDefinedRolesPage_viewer$key } from './__generated__/UserDefinedRolesPage_viewer.graphql';

const resource = Resource.USER_DEFINED_ROLES;

const gridFilterFields: FilterField[] = [
  {
    title: tableHeadings.domainId.defaultMessage,
    field: 'domainId',
  },
  {
    title: 'Role Name',
    field: 'name',
  },
];

const _ = graphql`
  fragment UserDefinedRolesPage_userDefinedRole on UserDefinedRole {
    id
    name
    userDefinedRoleId
    domainId
    deletedAt
  }
`;

const fragment = graphql`
  fragment UserDefinedRolesPage_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    domainId: { type: "[String!]" }
    name: { type: "[String!]" }
  )
  @refetchable(queryName: "UserDefinedRolesPageRefetchQuery") {
    userDefinedRoles(
      first: $count
      after: $cursor
      domainId: $domainId
      name: $name
    ) @connection(key: "UserDefinedRolesPage_userDefinedRoles", filters: []) {
      edges {
        node {
          ...UserDefinedRolesPage_userDefinedRole @relay(mask: false)
        }
      }
    }
  }
`;
const tableSpec: ColumnSpec<UserDefinedRole>[] = [
  {
    key: 'userDefinedRoleName',
    label: <FormattedMessage {...tableHeadings.accessRoleName} />,
    render: ({ item }) => (
      <DataGridLink
        path="/access-roles/user-defined"
        id={item.userDefinedRoleId}
        title={item.name!}
      />
    ),
  },
  {
    key: 'userDefinedRoleId',
    label: <FormattedMessage {...tableHeadings.accessRoleId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.userDefinedRoleId}>
        <DataGridText value={item.userDefinedRoleId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domainId}>
        <DataGridLink path="/domains" id={item.domainId} />
      </QuickCopyText>
    ),
  },
];

interface UserDefinedRolesPageProps {
  viewer: UserDefinedRolesPage_viewer$key;
}

export default function UserDefinedRolesPage({
  viewer,
}: UserDefinedRolesPageProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.userDefinedRoles);
  useBreadcrumbDetector(pageTitles.userDefinedRoles);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader className="p-2">
        <Header.Title>
          <FormattedMessage {...pageTitles.userDefinedRoles} />
        </Header.Title>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        <DataGridPage
          data={nodes}
          loadNext={loadNext}
          hasNext={hasNext}
          spec={tableSpec}
          scrollKey="data-grid"
          getRowProps={({ deletedAt }) => ({
            classNames: deletedAt ? deletedClass : '',
          })}
        />
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
