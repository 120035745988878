import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import ResetHostDeviceSecretAction from './ResetHostDeviceSecretAction';
import { UpdateMdmSettingsModal } from './UpdateMdmSettingsModal';
import { MdmSettingsPage_domain$key } from './__generated__/MdmSettingsPage_domain.graphql';

const fragment = graphql`
  fragment MdmSettingsPage_domain on Domain {
    ...UpdateMdmSettingsModal_domain
    ...ResetHostDeviceSecretAction_domain
    id
    domainId
    name
    shouldAuthorizeHostDevices
    hostDeviceSecret
  }
`;

interface MdmSettingsPageProps {
  domain: MdmSettingsPage_domain$key;
}

const getAppConfiguration = (secret) =>
  `
<dict>
  <key>ApprovedEnterpriseDeviceSecret</key>
  <string>${secret}</string>
</dict>
`.trim();

export default function MdmSettingsPage({
  domain: domainRef,
}: MdmSettingsPageProps) {
  const domain = useFragment(fragment, domainRef);
  const { name, hostDeviceSecret } = domain;
  useBreadcrumbDetector(pageTitles.mdm, name);
  const { canWrite } = usePermissionsContext();

  const renderMap = useMemo(() => {
    const renderHostDeviceSecret = {
      label: () => 'hostDeviceSecret*',
      value: () => <pre>{getAppConfiguration(hostDeviceSecret)}</pre>,
    };
    return {
      hostDeviceSecret: renderHostDeviceSecret,
    };
  }, [hostDeviceSecret]);

  return (
    <PermissionsGuard resource={Resource.DOMAIN_USERS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.domainMdmSettings} />}
        name={domain.name!}
        data={domain}
        quickCopyFields={['domainId']}
        renderMap={renderMap}
        resourceActions={
          canWrite(Resource.DOMAINS) && (
            <ResetHostDeviceSecretAction domainRef={domain} />
          )
        }
        crudActions={
          canWrite(Resource.DOMAINS) && (
            <UpdateAction
              renderModal={(props) => (
                <UpdateMdmSettingsModal {...props} domainRef={domain} />
              )}
            />
          )
        }
      />
    </PermissionsGuard>
  );
}
