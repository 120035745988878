import { defineMessages } from 'react-intl';

export const membershipIdMessages = defineMessages({
  label: {
    id: 'newMembership.membershipId.label',
    defaultMessage: 'Organization Membership ID*',
  },
  placeholder: {
    id: 'newMembership.membershipId.placeholder',
    defaultMessage: 'Organization Membership ID',
  },
  required: {
    id: 'newMembership.membershipId.required',
    defaultMessage: 'Please enter a organization membership ID',
  },
});
