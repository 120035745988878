import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Errors } from 'react-formal/cjs/types';
import { GraphQLTaggedNode, useMutation } from 'react-relay';
import { MutationParameters, SelectorStoreUpdater } from 'relay-runtime';
import { AnyObjectSchema } from 'yup';

import ModalForm from 'components/ModalForm';
import { Resource } from 'components/ResourceDetail';
import { isValidationError, parseValidationError } from 'utils/validation';

export interface ActionModalProps<TData = Resource> {
  show: boolean;
  onClose: () => void;
  onCompleted?: (data: TData) => void;
}

export interface ResourceModalProps<
  TMutation extends MutationParameters,
  TData = Resource,
> extends ActionModalProps<TData> {
  defaultValue?: TData;
  title: ReactNode;
  name?: string;
  submitText: ReactNode;
  disabled?: boolean;
  schema: AnyObjectSchema;
  mutation: GraphQLTaggedNode;
  updater?: SelectorStoreUpdater<TMutation['response']> | null;
  alertMessage?: ReactNode;
  transformOnSubmit?: (record: Record<string, any>) => Record<string, any>;
  validationErrors?: ReactNode;
  value?: TData;
  handleCancel?: () => void;
  onChange?: (input: TData, changedPaths: string[]) => void;
}

export function getDefaultFormValue<TData = Resource>(
  schema: AnyObjectSchema,
  value?: TData,
) {
  return value
    ? schema.cast(value, { stripUnknown: true })
    : schema.getDefault();
}

export function ResourceModal<
  TMutation extends MutationParameters,
  TData = Resource,
>({
  onCompleted,
  onClose,
  schema,
  mutation,
  defaultValue,
  updater = null,
  transformOnSubmit,
  validationErrors,
  children,
  alertMessage,
  handleCancel,
  ...formProps
}: PropsWithChildren<ResourceModalProps<TMutation, TData>>) {
  const [serverError, setServerError] = useState('');
  const [errors, setErrors] = useState<Errors>({});
  const [mutate, loading] = useMutation<TMutation>(mutation);

  const defaultFormValue = useMemo(
    () => getDefaultFormValue(schema, defaultValue),
    [schema, defaultValue],
  );

  const handleSubmit = useCallback(
    async (input) => {
      setServerError('');
      if (validationErrors) return;
      await mutate({
        variables: {
          input: transformOnSubmit ? transformOnSubmit(input) : input,
        },
        onCompleted: (response: TMutation['response']) => {
          onCompleted?.(response as TData);
          onClose();
        },
        onError: (e) => {
          if (isValidationError(e)) {
            setErrors(parseValidationError(e));
          }
          setServerError(e.message);
        },
        updater,
      });
    },
    [
      mutate,
      updater,
      onClose,
      onCompleted,
      transformOnSubmit,
      validationErrors,
    ],
  );

  const customHandleCancel = () => {
    // if custom cancel handler is provided, call it first before normal cancel
    if (handleCancel) {
      handleCancel();
    }
    setServerError('');
    setErrors({});
    onClose();
  };

  return (
    <ModalForm
      {...formProps}
      schema={schema}
      defaultValue={defaultFormValue}
      loading={loading}
      submitForm={handleSubmit}
      onCancel={customHandleCancel}
      alertMessage={alertMessage}
      modalErrors={validationErrors || serverError}
      errors={errors}
      onError={setErrors}
      horizontal
      warnOnUnsavedNavigation
    >
      {children}
    </ModalForm>
  );
}
