import Layout from '@4c/layout';
import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import MainContent from '@bfly/ui2/MainContent';
import Navbar from '@bfly/ui2/Navbar';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { removeStorageItem } from 'utils/webStorage';

function ConnectionErrorPage() {
  removeStorageItem('region');
  return (
    <>
      <Navbar>
        <Navbar.Brand as={Link} to="/" />
        <Layout.Spacer />
      </Navbar>
      <Page.Container center data-bni-id="ConnectionErrorPage">
        <MainContent centerText>
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage
                id="connectionErrorPage.title"
                defaultMessage="An error occurred while connecting to Butterfly Kaleidoscope."
              />
            </BlankSlate.Title>
            <BlankSlate.Body>
              <FormattedMessage
                id="connectionErrorPage.body"
                defaultMessage="To retry, refresh the page."
              />
            </BlankSlate.Body>
          </BlankSlate>
        </MainContent>
      </Page.Container>
    </>
  );
}

export default ConnectionErrorPage;
