import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import EhrDetailPage from './components/EhrDetailPage';
import EhrsGrid from './components/EhrsGrid';
import EhrsPage from './components/EhrsPage';

export default (
  <Route path="ehrs">
    <Route
      Component={EhrsPage}
      query={graphql`
        query ehrs_EhrsPage_Query {
          viewer {
            ...EhrsPage_viewer
          }
        }
      `}
    >
      <Route
        Component={EhrsGrid}
        query={graphql`
          query ehrs_EhrsGrid_Query(
            $organizationId: [String!]
            $domainId: [String!]
          ) {
            viewer {
              ...EhrsGrid_viewer
                @arguments(
                  organizationId: $organizationId
                  domainId: $domainId
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          organizationId: location.query.organizationId,
          domainId: location.query.domainId,
        })}
      />
    </Route>
    <Route
      path=":ehrId"
      Component={EhrDetailPage}
      query={graphql`
        query ehrs_EhrDetailPage_Query($ehrId: String!) {
          ehr(ehrId: $ehrId) {
            ...EhrDetailPage_ehr
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.ehr) throw new HttpError(404);
      }}
    />
  </Route>
);
