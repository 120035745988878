import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';

import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';

import {
  domainIdMessages,
  shouldAuthorizeHostDevicesMessages,
} from '../messages/formMessages';

export function MdmSettingsModal<TMutation extends MutationParameters>(
  props: ResourceModalProps<TMutation>,
) {
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation> {...props}>
      <Form.FieldGroup
        disabled
        name="domainId"
        label={<FormattedMessage {...domainIdMessages.label} />}
        placeholder={formatMessage(domainIdMessages.placeholder)}
        data-cy="mdmSettingsModal-domainIdField"
      />
      <Form.FieldGroup
        name="shouldAuthorizeHostDevices"
        label={
          <FormattedMessage {...shouldAuthorizeHostDevicesMessages.label} />
        }
        description={
          <FormattedMessage
            {...shouldAuthorizeHostDevicesMessages.description}
          />
        }
        data-cy="mdmSettingsModal-shouldAuthorizeHostDevicesField"
      />
    </ResourceModal>
  );
}
