import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { domainUserIdMessages } from '../messages/domainUserCreation';
import {
  domainUserActions,
  setFlatUserRole,
  userRoleMessages,
} from '../messages/userDetails';
import { SetFlatUserRoleActionMutation } from './__generated__/SetFlatUserRoleActionMutation.graphql';
import { SetFlatUserRoleAction_roles$key } from './__generated__/SetFlatUserRoleAction_roles.graphql';
import { SetFlatUserRoleAction_user$key } from './__generated__/SetFlatUserRoleAction_user.graphql';

export const basicUserRoleSchema = object({
  flatUserId: string(),
  roleId: string(),
});

const rolesFragment = graphql`
  fragment SetFlatUserRoleAction_roles on Viewer {
    systemDefinedRoles(roleType: [SYSTEM_DEFINED]) @skip(if: $skipRolesQuery) {
      edges {
        node {
          name
          description
          systemDefinedRoleId
        }
      }
    }
    userDefinedRoles(domainId: $domainId) @skip(if: $skipRolesQuery) {
      edges {
        node {
          domainId
          name
          description
          userDefinedRoleId
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment SetFlatUserRoleAction_user on FlatUser {
    id
    userId
    domainId
    role {
      id
      roleId
      name
      roleType
    }
  }
`;

const mutation = graphql`
  mutation SetFlatUserRoleActionMutation($input: UpdateFlatUserInput!) {
    updateFlatUser(input: $input) {
      flatUser {
        role {
          id
          roleId
          name
          roleType
        }
      }
    }
  }
`;

interface SetFlatUserRoleActionProps {
  userRef: SetFlatUserRoleAction_user$key;
  viewerRef: SetFlatUserRoleAction_roles$key;
}

export default function SetFlatUserRoleAction({
  userRef,
  viewerRef,
}: SetFlatUserRoleActionProps) {
  const { domainId, userId, role } = useFragment(fragment, userRef);
  const { systemDefinedRoles, userDefinedRoles } = useFragment(
    rolesFragment,
    viewerRef,
  );

  const roles = useMemo(() => {
    return [
      ...(systemDefinedRoles?.edges || []).map((entry) => {
        return entry?.node
          ? {
              name: entry.node.name,
              roleId: entry.node.systemDefinedRoleId,
            }
          : null;
      }),
      ...(userDefinedRoles?.edges || []).map((entry) => {
        return entry?.node
          ? {
              name: entry.node.name,
              roleId: entry.node.userDefinedRoleId,
            }
          : null;
      }),
    ];
  }, [systemDefinedRoles, userDefinedRoles]);

  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  const defaultValue = useMemo(() => {
    return {
      flatUserId: `${userId}/${domainId}`,
      roleId: role?.roleId,
    };
  }, [userId, domainId, role]);

  return (
    <PermissionsGuard resource={Resource.USER_DEFINED_ROLES} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage {...domainUserActions.setRole} />
      </Button>
      <ResourceModal<SetFlatUserRoleActionMutation>
        defaultValue={defaultValue}
        show={showModal}
        onClose={() => setShowModal(false)}
        title={<FormattedMessage {...setFlatUserRole.title} />}
        submitText={<FormattedMessage {...setFlatUserRole.submitText} />}
        mutation={mutation}
        schema={basicUserRoleSchema}
      >
        <Form.FieldGroup
          disabled
          name="flatUserId"
          placeholder={formatMessage(domainUserIdMessages.placeholder)}
          label={<FormattedMessage {...domainUserIdMessages.label} />}
          data-cy="setFlatUserRole-flatUserIdField"
        />
        <Form.FieldGroup
          name="roleId"
          as={DropdownList}
          textField="name"
          dataKey="roleId"
          mapFromValue={({ roleId }) => roleId}
          data={roles}
          placeholder={userRoleMessages.placeholder}
          label={<FormattedMessage {...userRoleMessages.label} />}
          data-cy="setFlatUserRole-roleIdField"
        />
        {/* make some room for the dropdown */}
        <div css="min-height: 8rem;" />
      </ResourceModal>
    </PermissionsGuard>
  );
}
