import { defineMessages } from 'react-intl';

export default defineMessages({
  diagnosticTestResultId: {
    id: 'diagnosticTestResultTable.heading.diagnosticTestResultId',
    defaultMessage: 'Diagnostic Test Result ID',
  },
  deviceProductionId: {
    id: 'diagnosticTestResultTable.heading.deviceProductionId',
    defaultMessage: 'Device Production ID',
  },
  testVersion: {
    id: 'diagnosticTestResultTable.heading.testVersion',
    defaultMessage: 'Test Version',
  },
  testResultPassed: {
    id: 'diagnosticTestResultTable.heading.testResultPassed',
    defaultMessage: 'Passed',
  },
});
