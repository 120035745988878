/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateCountryModal_country = {
    readonly id: string;
    readonly code: string | null;
    readonly name: string | null;
    readonly enabled: boolean | null;
    readonly " $refType": "UpdateCountryModal_country";
};
export type UpdateCountryModal_country$data = UpdateCountryModal_country;
export type UpdateCountryModal_country$key = {
    readonly " $data"?: UpdateCountryModal_country$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateCountryModal_country">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateCountryModal_country",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    }
  ],
  "type": "Country",
  "abstractKey": null
};
(node as any).hash = '7546f2cd8a483a846cd534de3bc81659';
export default node;
