import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Card from '@bfly/ui2/Card';
import Link from '@bfly/ui2/Link';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface LinkTo {
  pathname: string;
  query: { [key: string]: string | null };
}

export interface RelatedItemProps {
  children: React.ReactNode;
  name: string;
  to: string | LinkTo;
}

interface ResourceDetailRelatedItemsProps {
  children: React.ReactNode;
}

export function RelatedItem({ children, name, to }: RelatedItemProps) {
  return (
    <Link
      as={Button}
      to={to}
      data-cy={`related-item-${kebabCase(name)}`}
      variant="text-secondary"
    >
      {children}
    </Link>
  );
}

export default function ResourceDetailRelatedItems({
  children,
}: ResourceDetailRelatedItemsProps) {
  return (
    <Card data-cy="resource-detail-related-items">
      <Card.Header ruled data-cy="resource-detail-related-items-title">
        <FormattedMessage
          id="resourceDetail.relatedItems.title"
          defaultMessage="Related Items"
        />
      </Card.Header>
      <Card.Body>
        <Layout pad direction="column" align="flex-start">
          {children}
        </Layout>
      </Card.Body>
    </Card>
  );
}
