/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type memberships_MembershipDetailPage_QueryVariables = {
    organizationMembershipId: string;
};
export type memberships_MembershipDetailPage_QueryResponse = {
    readonly membership: {
        readonly " $fragmentRefs": FragmentRefs<"MembershipDetailPage_membership">;
    } | null;
};
export type memberships_MembershipDetailPage_Query = {
    readonly response: memberships_MembershipDetailPage_QueryResponse;
    readonly variables: memberships_MembershipDetailPage_QueryVariables;
};



/*
query memberships_MembershipDetailPage_Query(
  $organizationMembershipId: String!
) {
  membership: organizationMembership(organizationMembershipId: $organizationMembershipId) {
    ...MembershipDetailPage_membership
    id
  }
}

fragment DeleteMembershipAction_membership on OrganizationMembership {
  organizationMembershipId
  organization {
    name
    id
  }
}

fragment MembershipDetailPage_membership on OrganizationMembership {
  ...UpdateMembershipModal_membership
  ...DeleteMembershipAction_membership
  ...UndeleteMembershipAction_membership
  ...SetMembershipRoleAction_membership
  id
  organizationMembershipId
  type
  isNurse
  canFinalize
  canQa
  role {
    name
    id
    roleId
    roleType
  }
  createdAt
  deletedAt
  organization {
    organizationId
    name
    domain {
      domainId
      id
    }
    customUserPermissionsEnabled
    id
  }
  user {
    userId
    name
    id
  }
  referrerToken
}

fragment SetMembershipRoleAction_membership on OrganizationMembership {
  organizationMembershipId
  role {
    id
    roleId
    name
    roleType
  }
}

fragment UndeleteMembershipAction_membership on OrganizationMembership {
  organizationMembershipId
  organization {
    name
    id
  }
}

fragment UpdateMembershipModal_membership on OrganizationMembership {
  id
  type
  organizationMembershipId
  organization {
    organizationId
    customUserPermissionsEnabled
    id
  }
  user {
    userId
    name
    id
  }
  isNurse
  canFinalize
  canQa
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationMembershipId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationMembershipId",
    "variableName": "organizationMembershipId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "memberships_MembershipDetailPage_Query",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": "OrganizationMembership",
        "kind": "LinkedField",
        "name": "organizationMembership",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MembershipDetailPage_membership"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "memberships_MembershipDetailPage_Query",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": "OrganizationMembership",
        "kind": "LinkedField",
        "name": "organizationMembership",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationMembershipId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customUserPermissionsEnabled",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isNurse",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFinalize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canQa",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipRole",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleId",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referrerToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb22c0445c3ab88ff332f113fd32991c",
    "id": null,
    "metadata": {},
    "name": "memberships_MembershipDetailPage_Query",
    "operationKind": "query",
    "text": "query memberships_MembershipDetailPage_Query(\n  $organizationMembershipId: String!\n) {\n  membership: organizationMembership(organizationMembershipId: $organizationMembershipId) {\n    ...MembershipDetailPage_membership\n    id\n  }\n}\n\nfragment DeleteMembershipAction_membership on OrganizationMembership {\n  organizationMembershipId\n  organization {\n    name\n    id\n  }\n}\n\nfragment MembershipDetailPage_membership on OrganizationMembership {\n  ...UpdateMembershipModal_membership\n  ...DeleteMembershipAction_membership\n  ...UndeleteMembershipAction_membership\n  ...SetMembershipRoleAction_membership\n  id\n  organizationMembershipId\n  type\n  isNurse\n  canFinalize\n  canQa\n  role {\n    name\n    id\n    roleId\n    roleType\n  }\n  createdAt\n  deletedAt\n  organization {\n    organizationId\n    name\n    domain {\n      domainId\n      id\n    }\n    customUserPermissionsEnabled\n    id\n  }\n  user {\n    userId\n    name\n    id\n  }\n  referrerToken\n}\n\nfragment SetMembershipRoleAction_membership on OrganizationMembership {\n  organizationMembershipId\n  role {\n    id\n    roleId\n    name\n    roleType\n  }\n}\n\nfragment UndeleteMembershipAction_membership on OrganizationMembership {\n  organizationMembershipId\n  organization {\n    name\n    id\n  }\n}\n\nfragment UpdateMembershipModal_membership on OrganizationMembership {\n  id\n  type\n  organizationMembershipId\n  organization {\n    organizationId\n    customUserPermissionsEnabled\n    id\n  }\n  user {\n    userId\n    name\n    id\n  }\n  isNurse\n  canFinalize\n  canQa\n}\n"
  }
};
})();
(node as any).hash = '2bedfb5697286afa8edc9db5a48431b1';
export default node;
