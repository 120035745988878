import { defineMessages } from 'react-intl';

export const membershipActions = defineMessages({
  setRole: {
    id: 'membershipActions.setRole',
    defaultMessage: 'Set Role',
  },
});

export const setMembershipRole = defineMessages({
  title: {
    id: 'setMembershipRole.title',
    defaultMessage: 'Set Organization Membership Role',
  },
  submitText: {
    id: 'setMembershipRole.submitText',
    defaultMessage: 'Set Organization Membership Role',
  },
});

export const membershipRoleMessages = defineMessages({
  required: {
    id: 'membershipRole.required',
    defaultMessage: 'Please select a role',
  },
  label: {
    id: 'membershipRole.label',
    defaultMessage: 'Organization Membership Role*',
  },
  placeholder: {
    id: 'membershipRole.placeholder',
    defaultMessage: 'Organization Membership Role',
  },
});
