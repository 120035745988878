import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { LinkFieldsType } from 'components/ResourceDetail';
import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';
import { getStorageItem } from 'utils/webStorage';

import CreateDomainAction from './CreateDomainAction';
import DLDataModeOverrideAction from './DLDataModeOverrideAction';
import DeleteOrganizationAction from './DeleteOrganizationAction';
import EnterpriseCapabilitiesAction from './EnterpriseCapabilitiesAction';
import JoinDomainAction from './JoinDomainAction';
import SetCountryAction from './SetCountryAction';
import SetSubscriptionAction from './SetSubscriptionAction';
import UndeleteOrganizationAction from './UndeleteOrganizationAction';
import { UpdateOrganizationModal } from './UpdateOrganizationModal';
import { OrganizationDetailPage_organization$key } from './__generated__/OrganizationDetailPage_organization.graphql';

const fragment = graphql`
  fragment OrganizationDetailPage_organization on Organization {
    ...DeleteOrganizationAction_organization
    ...UpdateOrganizationModal_organization
    ...UndeleteOrganizationAction_organization
    ...CreateDomainAction_organization
    ...JoinDomainAction_organization
    ...DLDataModeOverrideAction_organization
    ...SetCountryAction_organization
    ...SetSubscriptionAction_organization
    ...EnterpriseCapabilitiesAction_organization
    id
    organizationId
    name
    slug
    deletedAt
    subscription {
      subscriptionId
      subscriptionEndsAt
      accessEndsAt
      hasStartedActiveSubscription
      hasCompletedTrial
      canAccessProFeatures
      isInGracePeriod
      isTeam
      billingStatus
      billingProvider
      stripeSubscriptionId
      sfSubscriptionId
      planType
      maxNumSeats
      featureModuleIds
      practiceType
    }
    isActive
    customer {
      customerId
    }
    domain {
      domainId
    }
    dlDataMode
    dlDataModeOverride
    enterpriseCapabilityManagementEnabled
    enabledPresetIds
    enabledToolIds
    enabledCaptureModes
    enabledCapabilities
    specialType
    numOutstandingInvitations
    country
    dataRegion: region
  }
`;

interface OrganizationDetailPageProps {
  organization: OrganizationDetailPage_organization$key;
}

const linkFields: LinkFieldsType = {
  'subscription: stripeSubscriptionId': (value) =>
    `https://dashboard.stripe.com/subscriptions/${value}`,
};

export default function OrganizationDetailPage({
  organization,
}: OrganizationDetailPageProps) {
  const data = useFragment(fragment, organization);
  let dataCopy = data as Record<string, unknown>;
  let computeRegion = getStorageItem('region');
  if (computeRegion) computeRegion = computeRegion.toUpperCase();
  dataCopy = { ...dataCopy, computeRegion };
  const { organizationId, subscription, customer, domain, name } = data;
  useBreadcrumbDetector(pageTitles.organization, name);

  const { canWrite, canWriteSome } = usePermissionsContext();
  return (
    <PermissionsGuard resource={Resource.ORGANIZATIONS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.organization} />}
        name={data.name!}
        data={dataCopy}
        quickCopyFields={[
          'name',
          'organizationId',
          'slug',
          'subscription: subscriptionId',
          'subscription: stripeSubscriptionId',
          'subscription: sfSubscriptionId',
          'customer: customerId',
          'domain: domainId',
        ]}
        linkFields={linkFields}
        resourceActions={
          canWriteSome(
            Resource.ENTERPRISE_UPGRADES,
            Resource.ORGANIZATION_MANAGEMENT_ADMIN_READ_WRITE,
            Resource.ORGANIZATIONS,
          ) && (
            <>
              {!domain && (
                <>
                  <CreateDomainAction organizationRef={data} />
                  <JoinDomainAction organizationRef={data} />
                </>
              )}
              <DLDataModeOverrideAction organizationRef={data} />
              <SetCountryAction organizationRef={data} />
              <SetSubscriptionAction organizationRef={data} />
              <EnterpriseCapabilitiesAction organizationRef={data} />
            </>
          )
        }
        crudActions={
          canWrite(Resource.ORGANIZATIONS) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateOrganizationModal {...props} organizationRef={data} />
                )}
              />
              {data.deletedAt ? (
                <UndeleteOrganizationAction organizationRef={data} />
              ) : (
                <DeleteOrganizationAction organizationRef={data} />
              )}
            </>
          )
        }
        relatedItems={
          <>
            <RelatedItem
              name="Memberships"
              to={{
                pathname: '/memberships',
                query: { organizationId },
              }}
            >
              <FormattedMessage
                id="organizationDetail.relatedItem.memberships"
                defaultMessage="Memberships"
              />
            </RelatedItem>
            <RelatedItem
              name="Invites"
              to={{
                pathname: '/invites',
                query: { organizationId },
              }}
            >
              <FormattedMessage
                id="organizationDetail.relatedItem.invites"
                defaultMessage="Invites"
              />
            </RelatedItem>
            <RelatedItem
              name="Butterfly Devices"
              to={{
                pathname: '/butterfly-devices',
                query: { organizationId },
              }}
            >
              <FormattedMessage
                id="organizationDetail.relatedItem.butterflyDevices"
                defaultMessage="Butterfly Devices"
              />
            </RelatedItem>
            <RelatedItem
              name="Subscription"
              to={`/subscriptions/${subscription!.subscriptionId}`}
            >
              <FormattedMessage
                id="organizationDetail.relatedItem.subscription"
                defaultMessage="Subscription"
              />
            </RelatedItem>
            <RelatedItem
              name="Customer"
              to={`/customers/${customer!.customerId}`}
            >
              <FormattedMessage
                id="organizationDetail.relatedItem.customer"
                defaultMessage="Customer"
              />
            </RelatedItem>
            {domain?.domainId && (
              <RelatedItem name="Domain" to={`/domains/${domain.domainId}`}>
                <FormattedMessage
                  id="organizationDetail.relatedItem.domain"
                  defaultMessage="Domain"
                />
              </RelatedItem>
            )}
            <RelatedItem
              name="Ehrs"
              to={{
                pathname: '/ehrs',
                query: { organizationId },
              }}
            >
              <FormattedMessage
                id="organizationDetail.relatedItem.ehrs"
                defaultMessage="Ehrs"
              />
            </RelatedItem>
          </>
        }
      />
    </PermissionsGuard>
  );
}
