/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomersGridRefetchQueryVariables = {
    count?: number | null | undefined;
    cursor?: string | null | undefined;
    id?: Array<string> | null | undefined;
    name?: Array<string> | null | undefined;
    netsuiteId?: Array<string> | null | undefined;
    sfAccountId?: Array<string> | null | undefined;
    stripeId?: Array<string> | null | undefined;
    zendeskId?: Array<string> | null | undefined;
};
export type CustomersGridRefetchQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"CustomersGrid_viewer">;
    } | null;
};
export type CustomersGridRefetchQuery = {
    readonly response: CustomersGridRefetchQueryResponse;
    readonly variables: CustomersGridRefetchQueryVariables;
};



/*
query CustomersGridRefetchQuery(
  $count: Int = 20
  $cursor: String
  $id: [String!]
  $name: [String!]
  $netsuiteId: [String!]
  $sfAccountId: [String!]
  $stripeId: [String!]
  $zendeskId: [String!]
) {
  viewer {
    ...CustomersGrid_viewer_43qnnq
    id
  }
}

fragment CustomersGrid_viewer_43qnnq on Viewer {
  customers(first: $count, after: $cursor, zendeskId: $zendeskId, stripeId: $stripeId, sfAccountId: $sfAccountId, netsuiteId: $netsuiteId, name: $name, id: $id) {
    edges {
      node {
        id
        name
        customerId
        zendeskId
        netsuiteId
        stripeId
        sfAccountId
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "netsuiteId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sfAccountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stripeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "zendeskId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v2 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v3 = {
  "kind": "Variable",
  "name": "netsuiteId",
  "variableName": "netsuiteId"
},
v4 = {
  "kind": "Variable",
  "name": "sfAccountId",
  "variableName": "sfAccountId"
},
v5 = {
  "kind": "Variable",
  "name": "stripeId",
  "variableName": "stripeId"
},
v6 = {
  "kind": "Variable",
  "name": "zendeskId",
  "variableName": "zendeskId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomersGridRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "CustomersGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomersGridRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "CustomerConnection",
            "kind": "LinkedField",
            "name": "customers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customerId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "zendeskId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "netsuiteId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripeId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sfAccountId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "CustomersGrid_customers",
            "kind": "LinkedHandle",
            "name": "customers"
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99fae7e196442bc1b44f2e926115de91",
    "id": null,
    "metadata": {},
    "name": "CustomersGridRefetchQuery",
    "operationKind": "query",
    "text": "query CustomersGridRefetchQuery(\n  $count: Int = 20\n  $cursor: String\n  $id: [String!]\n  $name: [String!]\n  $netsuiteId: [String!]\n  $sfAccountId: [String!]\n  $stripeId: [String!]\n  $zendeskId: [String!]\n) {\n  viewer {\n    ...CustomersGrid_viewer_43qnnq\n    id\n  }\n}\n\nfragment CustomersGrid_viewer_43qnnq on Viewer {\n  customers(first: $count, after: $cursor, zendeskId: $zendeskId, stripeId: $stripeId, sfAccountId: $sfAccountId, netsuiteId: $netsuiteId, name: $name, id: $id) {\n    edges {\n      node {\n        id\n        name\n        customerId\n        zendeskId\n        netsuiteId\n        stripeId\n        sfAccountId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '58cb7a053f37e030b3cd93c498d9611c';
export default node;
