/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ButterflyDevicesGrid_butterflyDevice = {
    readonly id: string;
    readonly productionId: string | null;
    readonly registeredAt: string | null;
    readonly isEnabled: boolean | null;
    readonly disabledStatusMessage: string | null;
    readonly lostAt: string | null;
    readonly stolenAt: string | null;
    readonly recallId: string | null;
    readonly subscription: {
        readonly subscriptionId: string | null;
    } | null;
    readonly hasActiveDiagnosticTestRequest: boolean | null;
    readonly lastDiagnosticTestRequestMinTestVersion: number | null;
    readonly " $refType": "ButterflyDevicesGrid_butterflyDevice";
};
export type ButterflyDevicesGrid_butterflyDevice$data = ButterflyDevicesGrid_butterflyDevice;
export type ButterflyDevicesGrid_butterflyDevice$key = {
    readonly " $data"?: ButterflyDevicesGrid_butterflyDevice$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ButterflyDevicesGrid_butterflyDevice">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ButterflyDevicesGrid_butterflyDevice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disabledStatusMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lostAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stolenAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recallId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasActiveDiagnosticTestRequest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastDiagnosticTestRequestMinTestVersion",
      "storageKey": null
    }
  ],
  "type": "ButterflyDevice",
  "abstractKey": null
};
(node as any).hash = 'e6d40ffbd16b66ce13910c60d2820739';
export default node;
