/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type JoinDomainAction_SubscriptionQueryVariables = {
    domainId?: Array<string> | null | undefined;
};
export type JoinDomainAction_SubscriptionQueryResponse = {
    readonly viewer: {
        readonly subscriptions: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly subscriptionId: string | null;
                    readonly customer: {
                        readonly name: string | null;
                        readonly netsuiteId: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type JoinDomainAction_SubscriptionQuery = {
    readonly response: JoinDomainAction_SubscriptionQueryResponse;
    readonly variables: JoinDomainAction_SubscriptionQueryVariables;
};



/*
query JoinDomainAction_SubscriptionQuery(
  $domainId: [String!]
) {
  viewer {
    subscriptions(domainId: $domainId) {
      edges {
        node {
          subscriptionId
          customer {
            name
            netsuiteId
            id
          }
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "domainId",
    "variableName": "domainId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netsuiteId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinDomainAction_SubscriptionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SubscriptionConnection",
            "kind": "LinkedField",
            "name": "subscriptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subscription",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JoinDomainAction_SubscriptionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SubscriptionConnection",
            "kind": "LinkedField",
            "name": "subscriptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subscription",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be15b4d8a4b6891ae2092439e1298666",
    "id": null,
    "metadata": {},
    "name": "JoinDomainAction_SubscriptionQuery",
    "operationKind": "query",
    "text": "query JoinDomainAction_SubscriptionQuery(\n  $domainId: [String!]\n) {\n  viewer {\n    subscriptions(domainId: $domainId) {\n      edges {\n        node {\n          subscriptionId\n          customer {\n            name\n            netsuiteId\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '769a9be2c0eadca141efd085dfa16d9b';
export default node;
