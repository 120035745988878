import { Query } from 'farce';
import useRouter from 'found/useRouter';
import uniqueId from 'lodash/uniqueId';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import BreadcrumbsContext, {
  Breadcrumb,
  BreadcrumbGroup,
} from 'utils/BreadcrumbsContext';
import { BREADCRUMBS_HASH } from 'utils/useBreadcrumbDetector';

export const BREADCRUMBS_MAX_DEPTH = 15;
export const BREADCRUMBS_MAX_MENU_ITEMS = 10;

/**
 * Detects whether a group of breadcrumbs contains a breadcrumb element
 * @param group
 * @param breadcrumb
 */
function containBreadcrumb(group: BreadcrumbGroup, breadcrumb: Breadcrumb) {
  return group.breadcrumbs.some(
    ({ pathname, query }) =>
      pathname === breadcrumb.pathname &&
      JSON.stringify(query) === JSON.stringify(breadcrumb.query),
  );
}

/**
 * Adds a new breadcrumb to the list. If the group already exists, it will add
 * a link to the list, otherwise it will create a new group.
 * @param prev previous value
 * @param breadcrumb new breadcrumb to be added
 */
function mergeBreadcrumbs(prev: BreadcrumbGroup[], breadcrumb: Breadcrumb) {
  const groups = [...prev];
  const activeGroup = groups.length ? groups[groups.length - 1] : null;

  // if the last breadcrumbs group has the same URL, we add a link to the same group
  if (activeGroup && activeGroup.pathname === breadcrumb.pathname) {
    if (!containBreadcrumb(activeGroup, breadcrumb)) {
      activeGroup.breadcrumbs = [...activeGroup.breadcrumbs, breadcrumb].slice(
        -BREADCRUMBS_MAX_MENU_ITEMS,
      );
    }
  } else {
    // otherwise we create a new group
    groups.push({
      pathname: breadcrumb.pathname,
      key: uniqueId('group'),
      breadcrumbs: [breadcrumb],
    });
  }

  return groups.slice(-BREADCRUMBS_MAX_DEPTH);
}

export default function BreadcrumbsProvider({
  children,
}: {
  children?: ReactNode | undefined;
}) {
  const {
    match: {
      location: { hash },
    },
  } = useRouter();
  const [breadcrumbGroups, setBreadcrumbGroups] = useState<BreadcrumbGroup[]>(
    [],
  );

  /**
   * Detects whether a breadcrumb hash exists in the list
   */
  const hasBreadcrumb = useCallback(
    (hashKey: string) => {
      return breadcrumbGroups.some(({ breadcrumbs }) =>
        breadcrumbs.some(({ key }) => hashKey === `#${key}`),
      );
    },
    [breadcrumbGroups],
  );

  /**
   * Creates and adds a new breadcrumb to the list
   */
  const addBreadcrumb = useCallback(
    (
      pathname: string,
      query: Query,
      title: MessageDescriptor,
      subtitle?: string | null,
    ) => {
      const value: Breadcrumb = {
        pathname,
        query,
        title,
        subtitle,
        key: uniqueId(BREADCRUMBS_HASH),
      };

      setBreadcrumbGroups((prev) => mergeBreadcrumbs(prev, value));
    },
    [setBreadcrumbGroups],
  );

  /**
   * Clears all but last breadcrumb
   */
  const clearBreadcrumbs = useCallback(() => {
    setBreadcrumbGroups((prev) => {
      let activeItem = prev.find((group) =>
        group.breadcrumbs.some((item) => `#${item.key}` === hash),
      );

      if (!activeItem) {
        activeItem = prev.slice(-1).pop(); // get last item if no active breadcrumbs
      }

      return activeItem
        ? [{ ...activeItem, breadcrumbs: activeItem.breadcrumbs.slice(-1) }]
        : [];
    });
  }, [setBreadcrumbGroups, hash]);

  const deleteBreadcrumb = useCallback(
    (id: string) => {
      setBreadcrumbGroups(
        breadcrumbGroups.filter((item) => item.pathname !== id),
      );
    },
    [breadcrumbGroups],
  );

  const value = useMemo(
    () => ({
      addBreadcrumb,
      hasBreadcrumb,
      breadcrumbGroups,
      clearBreadcrumbs,
      deleteBreadcrumb,
    }),
    [
      addBreadcrumb,
      hasBreadcrumb,
      breadcrumbGroups,
      clearBreadcrumbs,
      deleteBreadcrumb,
    ],
  );

  return (
    <BreadcrumbsContext.Provider value={value}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}
