export const Lung = '6154f454-c2c1-11e9-8a3a-8c85908620ba';

export default [
  { label: 'Abdomen', value: '26a8b700-c2c1-11e9-a3e7-8c85908620ba' },
  { label: 'Abdomen Deep', value: '48e0aa3a-c2c1-11e9-8717-8c85908620ba' },
  { label: 'Bladder', value: '4c3a9f92-c2c1-11e9-8194-8c85908620ba' },
  { label: 'Cardiac', value: '4fb3f006-c2c1-11e9-b3be-8c85908620ba' },
  {
    label: 'Cardiac Coherence',
    value: '22be641e-71c7-11ee-98d8-acde48001122',
  },
  {
    label: 'Cardiac Harmonics',
    value: '5478b810-c2c1-11e9-9264-8c85908620ba',
  },
  { label: 'Cardiac Deep', value: '5a05f368-c2c1-11e9-8fac-8c85908620ba' },
  {
    label: 'Cardiac Deep Coherence',
    value: 'fa2928b2-5737-11ee-8795-acde48001122',
  },
  {
    label: 'Cardiac Deep Harmonics',
    value: '5d9c3d36-c2c1-11e9-a33c-8c85908620ba',
  },
  { label: 'Lung', value: '6154f454-c2c1-11e9-8a3a-8c85908620ba' },
  { label: 'Musculoskeletal', value: '650a5490-c2c1-11e9-96f6-8c85908620ba' },
  {
    label: 'Musculoskeletal Equine',
    value: '6c20a626-c2c1-11e9-91f7-8c85908620ba',
  },
  {
    label: 'Nerve',
    value: 'feca60cc-c814-11e9-93d6-8c85908620ba',
  },
  {
    label: 'Ophthalmic',
    value: '057b8a76-5642-11ee-abe3-acde48001122',
  },
  { label: 'Small Organ', value: '6fec6736-c2c1-11e9-8d6f-8c85908620ba' },
  { label: 'Vascular', value: '736fe540-c2c1-11e9-8073-8c85908620ba' },
];
