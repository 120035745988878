import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { MembershipModal, membershipSchema } from './MembershipModal';
import { CreateMembershipModalMutation } from './__generated__/CreateMembershipModalMutation.graphql';
import { CreateMembershipModal_viewer$key } from './__generated__/CreateMembershipModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateMembershipModalMutation(
    $input: CreateOrganizationMembershipInput!
  ) {
    createOrganizationMembership(input: $input) {
      organizationMembershipEdge {
        node {
          id
          ...MembershipsGrid_membership
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateMembershipModal_viewer on Viewer {
    id
  }
`;

interface CreateMembershipModalProps extends ActionModalProps {
  viewerRef: CreateMembershipModal_viewer$key;
}

export function CreateMembershipModal({
  viewerRef,
  ...props
}: CreateMembershipModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'MembershipsGrid_organizationMemberships',
        rootFieldName: 'createOrganizationMembership',
        edgeName: 'organizationMembershipEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <MembershipModal<CreateMembershipModalMutation>
      orgCustomUserPermissionsEnabled={false}
      isDomainWithCustomPermissions={false}
      title={
        <FormattedMessage
          id="createMembershipModal.title"
          defaultMessage="Create Membership"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={membershipSchema}
      mutation={createMutation}
      updater={updater}
      {...props}
    />
  );
}
