/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UndeleteOrganizationInput = {
    organizationId: string;
    clientMutationId?: string | null | undefined;
};
export type UndeleteOrganizationActionMutationVariables = {
    input: UndeleteOrganizationInput;
};
export type UndeleteOrganizationActionMutationResponse = {
    readonly undeleteOrganization: {
        readonly organization: {
            readonly deletedAt: string | null;
        } | null;
    } | null;
};
export type UndeleteOrganizationActionMutation = {
    readonly response: UndeleteOrganizationActionMutationResponse;
    readonly variables: UndeleteOrganizationActionMutationVariables;
};



/*
mutation UndeleteOrganizationActionMutation(
  $input: UndeleteOrganizationInput!
) {
  undeleteOrganization(input: $input) {
    organization {
      deletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UndeleteOrganizationActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UndeleteOrganizationPayload",
        "kind": "LinkedField",
        "name": "undeleteOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UndeleteOrganizationActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UndeleteOrganizationPayload",
        "kind": "LinkedField",
        "name": "undeleteOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "599476e9be6c4db7ec88a8f07934f778",
    "id": null,
    "metadata": {},
    "name": "UndeleteOrganizationActionMutation",
    "operationKind": "mutation",
    "text": "mutation UndeleteOrganizationActionMutation(\n  $input: UndeleteOrganizationInput!\n) {\n  undeleteOrganization(input: $input) {\n    organization {\n      deletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'df8ad4ca2156b7d0d134269fbae935e4';
export default node;
