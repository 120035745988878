/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainUserIntegrationConfigsPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateDomainUserIntegrationConfigModal_viewer">;
    readonly " $refType": "DomainUserIntegrationConfigsPage_viewer";
};
export type DomainUserIntegrationConfigsPage_viewer$data = DomainUserIntegrationConfigsPage_viewer;
export type DomainUserIntegrationConfigsPage_viewer$key = {
    readonly " $data"?: DomainUserIntegrationConfigsPage_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DomainUserIntegrationConfigsPage_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainUserIntegrationConfigsPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateDomainUserIntegrationConfigModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '6a113e87ce27717b5cfea789d66f52c6';
export default node;
