import DropdownListBase, { DropdownListProps } from '@bfly/ui2/DropdownList';
import React, { useEffect, useRef } from 'react';

export type { DropdownListProps };

// TODO: Remove this once the bug is fixed
/**
 * Temporary code to fix Chrome browser crashing.
 * See: https://issues.chromium.org/issues/335553723?pli=1
 */
export default function DropdownListTempFix<TDataItem>(
  props: DropdownListProps<TDataItem> & React.RefAttributes<HTMLDivElement>,
) {
  const observer = useRef<MutationObserver>();

  useEffect(() => {
    return () => observer.current?.disconnect();
  }, []);

  return (
    <DropdownListBase
      ref={(node: HTMLElement | null) => {
        if (!node) return;
        if (observer.current) observer.current?.disconnect();
        const nextObserver = new MutationObserver(() => {
          node.removeAttribute('aria-owns');
          node.removeAttribute('aria-labelledby');

          node.querySelectorAll('[aria-owns]').forEach((element) => {
            element.removeAttribute('aria-owns');
          });
          node.querySelectorAll('[aria-labelledby]').forEach((element) => {
            element.removeAttribute('aria-labelledby');
          });
        });
        nextObserver.observe(node, { childList: true, subtree: true });
        observer.current = nextObserver;
      }}
      {...props}
    />
  );
}
