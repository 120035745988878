/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EducationContentPracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type EducationContentVideoType = "ACQUISITION" | "INTERPRETATION" | "%future added value";
export type CreateEducationContentInput = {
    name: string;
    description?: string | null | undefined;
    authoredBy: string;
    type: EducationContentVideoType;
    practiceType: EducationContentPracticeType;
    categoryId: string;
    isPremium: boolean;
    vimeoId: string;
    toolIds?: Array<string | null> | null | undefined;
    presetIds?: Array<string | null> | null | undefined;
    captureModes?: Array<string | null> | null | undefined;
    duration?: number | null | undefined;
    ranking?: number | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateEducationContentModalMutationVariables = {
    input: CreateEducationContentInput;
};
export type CreateEducationContentModalMutationResponse = {
    readonly createEducationContent: {
        readonly educationContentEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"EducationContentPage_educationContent">;
            } | null;
        } | null;
    } | null;
};
export type CreateEducationContentModalMutation = {
    readonly response: CreateEducationContentModalMutationResponse;
    readonly variables: CreateEducationContentModalMutationVariables;
};



/*
mutation CreateEducationContentModalMutation(
  $input: CreateEducationContentInput!
) {
  createEducationContent(input: $input) {
    educationContentEdge {
      node {
        id
        ...EducationContentPage_educationContent
      }
    }
  }
}

fragment EducationContentPage_educationContent on EducationContent {
  id
  educationContentId
  name
  description
  type
  contentType
  practiceType
  isPremium
  vimeoId
  absorbId
  duration
  ranking
  status
  presetLocalIds
  toolLocalIds
  captureModes
  deletedAt
  authoredBy {
    name
    id
  }
  category {
    educationCategoryId
    name
    id
  }
  courseLanguage
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateEducationContentModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateEducationContentPayload",
        "kind": "LinkedField",
        "name": "createEducationContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EducationContentEdge",
            "kind": "LinkedField",
            "name": "educationContentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EducationContentPage_educationContent"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateEducationContentModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateEducationContentPayload",
        "kind": "LinkedField",
        "name": "createEducationContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EducationContentEdge",
            "kind": "LinkedField",
            "name": "educationContentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContent",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "educationContentId",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contentType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "practiceType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPremium",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vimeoId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "absorbId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ranking",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "presetLocalIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "toolLocalIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "captureModes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "authoredBy",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EducationCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "educationCategoryId",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "courseLanguage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d029dd7e59ffb0ea03dca02ca5cf7f9",
    "id": null,
    "metadata": {},
    "name": "CreateEducationContentModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateEducationContentModalMutation(\n  $input: CreateEducationContentInput!\n) {\n  createEducationContent(input: $input) {\n    educationContentEdge {\n      node {\n        id\n        ...EducationContentPage_educationContent\n      }\n    }\n  }\n}\n\nfragment EducationContentPage_educationContent on EducationContent {\n  id\n  educationContentId\n  name\n  description\n  type\n  contentType\n  practiceType\n  isPremium\n  vimeoId\n  absorbId\n  duration\n  ranking\n  status\n  presetLocalIds\n  toolLocalIds\n  captureModes\n  deletedAt\n  authoredBy {\n    name\n    id\n  }\n  category {\n    educationCategoryId\n    name\n    id\n  }\n  courseLanguage\n}\n"
  }
};
})();
(node as any).hash = 'b171d65b164d2b4bec8b8fec087d4d14';
export default node;
