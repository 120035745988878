import Caret from '@bfly/ui2/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import Navbar from '@bfly/ui2/Navbar';
import React from 'react';

const envLinks = [
  {
    key: 'dev',
    name: 'DEV',
    link: 'https://kaleidoscope.dev.bfly.io/',
  },
  {
    key: 'staging',
    name: 'STAGING',
    link: 'https://kaleidoscope.staging.bfly.io/',
  },
  {
    key: 'prod',
    name: 'PROD',
    link: 'https://kaleidoscope.bfly.io/',
  },
];

export default function EnvDropdown() {
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={Navbar.NavItem}
        id="current-env"
        data-cy="current-env"
      >
        Env:&nbsp;
        <span className="text-danger" data-bni-id="AppEnvironment">
          {/* @ts-ignore */}
          {process.env.BNI_ENV_DISPLAY}
        </span>
        <Caret />
      </Dropdown.Toggle>
      <Dropdown.Menu data-cy="env-details" variant="dark">
        {envLinks
          // @ts-ignore
          .filter((item) => item.key !== process.env.BNI_ENV)
          .map((item) => (
            <Dropdown.Item
              key={item.key}
              href={item.link}
              data-cy={`env-${item.key}`}
            >
              {item.name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
