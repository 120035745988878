/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateEducationCategoryModal_educationCategory = {
    readonly id: string;
    readonly educationCategoryId: string | null;
    readonly name: string | null;
    readonly ordering: number | null;
    readonly " $refType": "UpdateEducationCategoryModal_educationCategory";
};
export type UpdateEducationCategoryModal_educationCategory$data = UpdateEducationCategoryModal_educationCategory;
export type UpdateEducationCategoryModal_educationCategory$key = {
    readonly " $data"?: UpdateEducationCategoryModal_educationCategory$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateEducationCategoryModal_educationCategory">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateEducationCategoryModal_educationCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationCategoryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordering",
      "storageKey": null
    }
  ],
  "type": "EducationCategory",
  "abstractKey": null
};
(node as any).hash = 'fb22b5f285e0446cf040d634247b6e1d';
export default node;
