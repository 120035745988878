/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SetSubscriptionAction_organization = {
    readonly organizationId: string | null;
    readonly subscription: {
        readonly subscriptionId: string | null;
    } | null;
    readonly " $refType": "SetSubscriptionAction_organization";
};
export type SetSubscriptionAction_organization$data = SetSubscriptionAction_organization;
export type SetSubscriptionAction_organization$key = {
    readonly " $data"?: SetSubscriptionAction_organization$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SetSubscriptionAction_organization">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetSubscriptionAction_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
(node as any).hash = '596651929af3ecfbf296e065632ba171';
export default node;
