/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlaceOfWorkSource = "DEFINITIVE_HEALTHCARE" | "INTERNAL" | "%future added value";
export type PlaceOfWorkStatus = "ACTIVE" | "CLOSED" | "OPENING" | "%future added value";
export type UpdatePlaceOfWorkModal_placeOfWork = {
    readonly id: string;
    readonly placeOfWorkId: string | null;
    readonly name: string | null;
    readonly countries: ReadonlyArray<string | null> | null;
    readonly status: PlaceOfWorkStatus | null;
    readonly source: PlaceOfWorkSource | null;
    readonly " $refType": "UpdatePlaceOfWorkModal_placeOfWork";
};
export type UpdatePlaceOfWorkModal_placeOfWork$data = UpdatePlaceOfWorkModal_placeOfWork;
export type UpdatePlaceOfWorkModal_placeOfWork$key = {
    readonly " $data"?: UpdatePlaceOfWorkModal_placeOfWork$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdatePlaceOfWorkModal_placeOfWork">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdatePlaceOfWorkModal_placeOfWork",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeOfWorkId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    }
  ],
  "type": "PlaceOfWork",
  "abstractKey": null
};
(node as any).hash = '75b540c2efb603afde5a89881b2a00c8';
export default node;
