/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type FlatUserDetailPage_roles = {
    readonly userDefinedRoles?: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly domainId: string | null;
                readonly name: string | null;
                readonly userDefinedRoleId: string | null;
            } | null;
        } | null> | null;
    } | null | undefined;
    readonly " $refType": "FlatUserDetailPage_roles";
};
export type FlatUserDetailPage_roles$data = FlatUserDetailPage_roles;
export type FlatUserDetailPage_roles$key = {
    readonly " $data"?: FlatUserDetailPage_roles$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FlatUserDetailPage_roles">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "domainId"
    },
    {
      "kind": "RootArgument",
      "name": "skipRolesQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlatUserDetailPage_roles",
  "selections": [
    {
      "condition": "skipRolesQuery",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "domainId",
              "variableName": "domainId"
            }
          ],
          "concreteType": "UserDefinedRoleConnection",
          "kind": "LinkedField",
          "name": "userDefinedRoles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserDefinedRoleEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserDefinedRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "domainId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userDefinedRoleId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '31cb57e0a5145ba6da722032d9171e29';
export default node;
