/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import SubscriptionsGridRefetchQuery from "./SubscriptionsGridRefetchQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type BillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type BillingStatus = "ACTIVE" | "CANCELED" | "PAST_DUE" | "TRIALING" | "UNPAID" | "%future added value";
export type PlanType = "BASIC" | "CLINIC" | "DEPARTMENT" | "EDUCATION" | "EMS" | "ENTERPRISE_SECURITY" | "ENTERPRISE_WORKFLOW" | "GOVERNMENT" | "HOSPITAL" | "INDIVIDUAL" | "INDIVIDUAL_FOREVER" | "MEDICAL_SCHOOL" | "NONE" | "PRO_CUSTOM" | "PRO_THREE_YEAR" | "PRO_TRIAL" | "RESIDENCY_PROGRAM" | "TEAM" | "%future added value";
export type PracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type SubscriptionsGrid_viewer = {
    readonly subscriptions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly subscriptionId: string | null;
                readonly subscriptionEndsAt: string | null;
                readonly accessEndsAt: string | null;
                readonly hasStartedActiveSubscription: boolean | null;
                readonly hasCompletedTrial: boolean | null;
                readonly canAccessProFeatures: boolean | null;
                readonly isInGracePeriod: boolean | null;
                readonly isTeam: boolean | null;
                readonly billingStatus: BillingStatus | null;
                readonly billingProvider: BillingProvider | null;
                readonly stripeSubscriptionId: string | null;
                readonly sfSubscriptionId: string | null;
                readonly planType: PlanType | null;
                readonly maxNumSeats: number | null;
                readonly practiceType: PracticeType | null;
                readonly domain: {
                    readonly domainId: string | null;
                } | null;
                readonly customer: {
                    readonly customerId: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "SubscriptionsGrid_viewer";
};
export type SubscriptionsGrid_viewer$data = SubscriptionsGrid_viewer;
export type SubscriptionsGrid_viewer$key = {
    readonly " $data"?: SubscriptionsGrid_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SubscriptionsGrid_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "subscriptions"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "customerId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "domainId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sfSubscriptionId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "stripeSubscriptionId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": SubscriptionsGridRefetchQuery
    }
  },
  "name": "SubscriptionsGrid_viewer",
  "selections": [
    {
      "alias": "subscriptions",
      "args": [
        {
          "kind": "Variable",
          "name": "customerId",
          "variableName": "customerId"
        },
        {
          "kind": "Variable",
          "name": "domainId",
          "variableName": "domainId"
        },
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        },
        {
          "kind": "Variable",
          "name": "sfSubscriptionId",
          "variableName": "sfSubscriptionId"
        },
        {
          "kind": "Variable",
          "name": "stripeSubscriptionId",
          "variableName": "stripeSubscriptionId"
        }
      ],
      "concreteType": "SubscriptionConnection",
      "kind": "LinkedField",
      "name": "__SubscriptionsGrid_subscriptions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Subscription",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscriptionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscriptionEndsAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accessEndsAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasStartedActiveSubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasCompletedTrial",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canAccessProFeatures",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isInGracePeriod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isTeam",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billingStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billingProvider",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "stripeSubscriptionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sfSubscriptionId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "planType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxNumSeats",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "practiceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Domain",
                  "kind": "LinkedField",
                  "name": "domain",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "domainId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Customer",
                  "kind": "LinkedField",
                  "name": "customer",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "customerId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = '2eda044ce01c5255b62215c546931b79';
export default node;
