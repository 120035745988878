import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import InviteDetailPage from './components/InviteDetailPage';
import InvitesGrid from './components/InvitesGrid';
import InvitesPage from './components/InvitesPage';

export default (
  <Route path="invites">
    <Route
      Component={InvitesPage}
      query={graphql`
        query invites_OrganizationInvitesPage_Query {
          viewer {
            ...InvitesPage_viewer
          }
        }
      `}
    >
      <Route
        Component={InvitesGrid}
        query={graphql`
          query invites_InvitesGrid_Query(
            $organizationId: [String!]
            $email: [String!]
            $isDeleted: [String!]
          ) {
            viewer {
              ...InvitesGrid_viewer
                @arguments(
                  organizationId: $organizationId
                  email: $email
                  isDeleted: $isDeleted
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          organizationId: location.query.organizationId,
          email: location.query.email,
          isDeleted:
            location.query.isDeleted === 'true' ||
            location.query.isDeleted === 'false'
              ? location.query.isDeleted
              : undefined,
        })}
      />
    </Route>
    <Route
      path=":organizationInviteId"
      Component={InviteDetailPage}
      query={graphql`
        query invites_OrganizationInviteDetailPage_Query(
          $organizationInviteId: String!
        ) {
          invite: organizationInvite(
            organizationInviteId: $organizationInviteId
          ) {
            ...InviteDetailPage_organizationInvite
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.invite) throw new HttpError(404);
      }}
    />
  </Route>
);
