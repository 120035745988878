/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateCustomerInput = {
    name: string;
    zendeskId?: string | null | undefined;
    netsuiteId?: string | null | undefined;
    stripeId?: string | null | undefined;
    sfAccountId?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateCustomerModal_CreateMutationVariables = {
    input: CreateCustomerInput;
};
export type CreateCustomerModal_CreateMutationResponse = {
    readonly createCustomer: {
        readonly customerEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"CustomersPage_customer">;
            } | null;
        } | null;
    } | null;
};
export type CreateCustomerModal_CreateMutation = {
    readonly response: CreateCustomerModal_CreateMutationResponse;
    readonly variables: CreateCustomerModal_CreateMutationVariables;
};



/*
mutation CreateCustomerModal_CreateMutation(
  $input: CreateCustomerInput!
) {
  createCustomer(input: $input) {
    customerEdge {
      node {
        id
        ...CustomersPage_customer
      }
    }
  }
}

fragment CustomersPage_customer on Customer {
  id
  name
  customerId
  zendeskId
  netsuiteId
  stripeId
  sfAccountId
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCustomerModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerPayload",
        "kind": "LinkedField",
        "name": "createCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "customerEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CustomersPage_customer"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCustomerModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCustomerPayload",
        "kind": "LinkedField",
        "name": "createCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "customerEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zendeskId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "netsuiteId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stripeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sfAccountId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a53791b4b841b2e3c5d6029dad272e6",
    "id": null,
    "metadata": {},
    "name": "CreateCustomerModal_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCustomerModal_CreateMutation(\n  $input: CreateCustomerInput!\n) {\n  createCustomer(input: $input) {\n    customerEdge {\n      node {\n        id\n        ...CustomersPage_customer\n      }\n    }\n  }\n}\n\nfragment CustomersPage_customer on Customer {\n  id\n  name\n  customerId\n  zendeskId\n  netsuiteId\n  stripeId\n  sfAccountId\n}\n"
  }
};
})();
(node as any).hash = 'b9cdd5257b056ed82b859142340c8e0e';
export default node;
