/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type BillingStatus = "ACTIVE" | "CANCELED" | "PAST_DUE" | "TRIALING" | "UNPAID" | "%future added value";
export type DlDataMode = "EXCLUDE" | "INCLUDE" | "PARTIAL" | "%future added value";
export type PlanType = "BASIC" | "CLINIC" | "DEPARTMENT" | "EDUCATION" | "EMS" | "ENTERPRISE_SECURITY" | "ENTERPRISE_WORKFLOW" | "GOVERNMENT" | "HOSPITAL" | "INDIVIDUAL" | "INDIVIDUAL_FOREVER" | "MEDICAL_SCHOOL" | "NONE" | "PRO_CUSTOM" | "PRO_THREE_YEAR" | "PRO_TRIAL" | "RESIDENCY_PROGRAM" | "TEAM" | "%future added value";
export type PracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type SpecialType = "DEMO" | "INTERNAL" | "NONE" | "TEST" | "%future added value";
export type OrganizationsGrid_organization = {
    readonly id: string;
    readonly organizationId: string | null;
    readonly name: string | null;
    readonly slug: string | null;
    readonly country: string | null;
    readonly deletedAt: string | null;
    readonly subscription: {
        readonly subscriptionId: string | null;
        readonly subscriptionEndsAt: string | null;
        readonly accessEndsAt: string | null;
        readonly hasStartedActiveSubscription: boolean | null;
        readonly hasCompletedTrial: boolean | null;
        readonly canAccessProFeatures: boolean | null;
        readonly isInGracePeriod: boolean | null;
        readonly isTeam: boolean | null;
        readonly billingStatus: BillingStatus | null;
        readonly billingProvider: BillingProvider | null;
        readonly stripeSubscriptionId: string | null;
        readonly planType: PlanType | null;
        readonly maxNumSeats: number | null;
        readonly practiceType: PracticeType | null;
    } | null;
    readonly isActive: boolean | null;
    readonly customer: {
        readonly customerId: string | null;
    } | null;
    readonly domain: {
        readonly domainId: string | null;
    } | null;
    readonly dlDataMode: DlDataMode | null;
    readonly dlDataModeOverride: DlDataMode | null;
    readonly enterpriseCapabilityManagementEnabled: boolean | null;
    readonly enabledPresetIds: ReadonlyArray<string | null> | null;
    readonly enabledToolIds: ReadonlyArray<string | null> | null;
    readonly enabledCaptureModes: ReadonlyArray<string | null> | null;
    readonly enabledCapabilities: ReadonlyArray<string | null> | null;
    readonly specialType: SpecialType | null;
    readonly numAvailableSeats: number | null;
    readonly numOutstandingInvitations: number | null;
    readonly " $refType": "OrganizationsGrid_organization";
};
export type OrganizationsGrid_organization$data = OrganizationsGrid_organization;
export type OrganizationsGrid_organization$key = {
    readonly " $data"?: OrganizationsGrid_organization$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OrganizationsGrid_organization">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationsGrid_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionEndsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessEndsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasStartedActiveSubscription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCompletedTrial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canAccessProFeatures",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInGracePeriod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTeam",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billingStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billingProvider",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stripeSubscriptionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "planType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxNumSeats",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "practiceType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dlDataMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dlDataModeOverride",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enterpriseCapabilityManagementEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabledPresetIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabledToolIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabledCaptureModes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabledCapabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numAvailableSeats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOutstandingInvitations",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
(node as any).hash = 'a7a2af2affe833d877efccbfe2762ad3';
export default node;
