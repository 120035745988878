import { defineMessages } from 'react-intl';

export const organizationInviteIdMessages = defineMessages({
  label: {
    id: 'organizationInviteId.label',
    defaultMessage: 'Organization Invite ID',
  },
  labelRequired: {
    id: 'organizationInviteId.labelRequired',
    defaultMessage: 'Organization Invite ID*',
  },
  placeholder: {
    id: 'organizationInviteId.placeholder',
    defaultMessage: 'Organization Invite ID',
  },
  required: {
    id: 'organizationInviteId.required',
    defaultMessage: 'Organization Invite ID is required field',
  },
});

export const organizationIdMessages = defineMessages({
  label: {
    id: 'organizationId.label',
    defaultMessage: 'Organization ID*',
  },
  placeholder: {
    id: 'organizationId.placeholder',
    defaultMessage: 'Organization ID',
  },
  required: {
    id: 'organizationId.required',
    defaultMessage: 'Organization ID is required field',
  },
});

export const userDefinedRolesMessages = defineMessages({
  label: {
    id: 'userDefinedRole.label',
    defaultMessage: 'User Defined Roles',
  },
  placeholder: {
    id: 'userDefinedRole.placeholder',
    defaultMessage: 'User Defined Roles',
  },
  required: {
    id: 'userDefinedRole.required',
    defaultMessage: 'User Defined Roles is required field',
  },
});

export const emailMessages = defineMessages({
  label: {
    id: 'email.label',
    defaultMessage: 'Email*',
  },
  placeholder: {
    id: 'email.placeholder',
    defaultMessage: 'Email',
  },
  required: {
    id: 'email.required',
    defaultMessage: 'Email is required field',
  },
});

export const makeOrganizationAdminMessages = defineMessages({
  label: {
    id: 'makeOrganizationAdmin.label',
    defaultMessage: 'Make Organization Admin',
  },
});

export const inviteLinkUrlMessages = defineMessages({
  label: {
    id: 'inviteLinkUrl.label',
    defaultMessage: 'Link URL',
  },
});
