/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import CountriesGridRefetchQuery from "./CountriesGridRefetchQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type CountriesGrid_viewer = {
    readonly countries: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly code: string | null;
                readonly name: string | null;
                readonly enabled: boolean | null;
                readonly countryConfiguration: {
                    readonly dataRegion: string | null;
                    readonly configuration: any | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "CountriesGrid_viewer";
};
export type CountriesGrid_viewer$data = CountriesGrid_viewer;
export type CountriesGrid_viewer$key = {
    readonly " $data"?: CountriesGrid_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CountriesGrid_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "countries"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": CountriesGridRefetchQuery
    }
  },
  "name": "CountriesGrid_viewer",
  "selections": [
    {
      "alias": "countries",
      "args": null,
      "concreteType": "CountryConnection",
      "kind": "LinkedField",
      "name": "__CountriesGrid_countries_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CountryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CountryConfiguration",
                  "kind": "LinkedField",
                  "name": "countryConfiguration",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dataRegion",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "configuration",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = '21f8d3cb066ca4546a00cde177f78ff6';
export default node;
