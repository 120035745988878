import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { educationVideoMessages } from '../messages/formMessages';
import {
  EducationVideoModal,
  educationVideoSchema,
} from './EducationVideoModal';
import { CreateEducationContentModalMutation } from './__generated__/CreateEducationContentModalMutation.graphql';
import { CreateEducationContentModal_viewer$key } from './__generated__/CreateEducationContentModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateEducationContentModalMutation(
    $input: CreateEducationContentInput!
  ) {
    createEducationContent(input: $input) {
      educationContentEdge {
        node {
          id
          ...EducationContentPage_educationContent
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateEducationContentModal_viewer on Viewer {
    id
  }
`;

interface CreateEducationVideoModalProps extends ActionModalProps {
  viewerRef: CreateEducationContentModal_viewer$key;
}

export function CreateEducationContentModal({
  viewerRef,
  ...props
}: CreateEducationVideoModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'EducationContentGrid_educationContent',
        rootFieldName: 'createEducationContent',
        edgeName: 'educationContentEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <EducationVideoModal<CreateEducationContentModalMutation>
      title={<FormattedMessage {...educationVideoMessages.create} />}
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={educationVideoSchema}
      defaultValue={educationVideoSchema.getDefault()}
      mutation={createMutation}
      updater={updater}
      {...props}
    />
  );
}
