import Button from '@bfly/ui2/Button';
import useRouter from 'found/useRouter';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import Alert from 'components/Alert';
import {
  PROVISION_WITH_DOMAIN_REGULAR_PLAN_TYPES,
  SubscriptionModal,
  subscriptionSchema,
} from 'components/SubscriptionModal';
import { planTypeMessages } from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import {
  ProvisionSubscriptionActionMutation,
  ProvisionSubscriptionActionMutationResponse,
} from './__generated__/ProvisionSubscriptionActionMutation.graphql';
import { ProvisionSubscriptionAction_domain$key } from './__generated__/ProvisionSubscriptionAction_domain.graphql';

const fragment = graphql`
  fragment ProvisionSubscriptionAction_domain on Domain {
    id
    domainId
  }
`;

const mutation = graphql`
  mutation ProvisionSubscriptionActionMutation(
    $input: CreateSubscriptionInput!
  ) {
    createSubscription(input: $input) {
      subscription {
        id
        subscriptionId
        featureModuleIds
        ...SubscriptionsGrid_subscription
      }
    }
  }
`;

export const provisionSubscriptionWithDomainSchema = subscriptionSchema.shape({
  domainId: string(),
  planType: string()
    .required(planTypeMessages.required)
    .oneOf(PROVISION_WITH_DOMAIN_REGULAR_PLAN_TYPES),
});

interface ProvisionSubscriptionActionProps {
  domainRef: ProvisionSubscriptionAction_domain$key;
}

export default function ProvisionSubscriptionAction({
  domainRef,
}: ProvisionSubscriptionActionProps) {
  const { router } = useRouter();
  const domain = useFragment(fragment, domainRef);
  const { domainId } = domain;
  const [showModal, setShowModal] = useState(false);

  return (
    <PermissionsGuard resource={Resource.PROVISIONING} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage
          id="domainActions.provisionSubscription"
          defaultMessage="Provision Subscription"
        />
      </Button>
      <SubscriptionModal<ProvisionSubscriptionActionMutation>
        withDomain
        show={showModal}
        defaultValue={{ domainId }}
        onClose={() => setShowModal(false)}
        onCompleted={({
          createSubscription,
        }: ProvisionSubscriptionActionMutationResponse) => {
          if (createSubscription?.subscription?.subscriptionId) {
            router.push(
              `/subscriptions/${createSubscription.subscription.subscriptionId}`,
            );
          }
        }}
        title={
          <FormattedMessage
            id="provisionSubscriptionAction.title"
            defaultMessage="Provision Subscription"
          />
        }
        submitText={
          <FormattedMessage
            id="provisionSubscriptionAction.title"
            defaultMessage="Provision Subscription"
          />
        }
        mutation={mutation}
        schema={provisionSubscriptionWithDomainSchema}
      >
        <Alert
          variant="warning"
          className="mb-4"
          data-cy="provisionSubscriptionAction-warning"
        >
          <FormattedMessage
            id="provisionSubscriptionAction.note"
            defaultMessage="This form will provision ONLY a new Subscription. It will not provision an Organization. After completing this form you will be redirected to the new Subscription's detail page. From there you can provision Organizations if need be."
          />
        </Alert>
      </SubscriptionModal>
    </PermissionsGuard>
  );
}
