/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type FlatUsersPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateUserModal_viewer">;
    readonly " $refType": "FlatUsersPage_viewer";
};
export type FlatUsersPage_viewer$data = FlatUsersPage_viewer;
export type FlatUsersPage_viewer$key = {
    readonly " $data"?: FlatUsersPage_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FlatUsersPage_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlatUsersPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateUserModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'e554943ff0d9fc48420c0dab4a95d359';
export default node;
