export enum Resource {
  APPLICATION_VERSION_REQUIREMENTS = 'application-version-requirements',
  AUDIT_LOGS = 'audit-logs',
  BUTTERFLY_DEVICES = 'butterfly-devices',
  BUTTERFLY_DEVICE_RECALLS = 'butterfly-device-recalls',
  COUNTRIES = 'countries',
  CUSTOMERS = 'customers',
  DIAGNOSTIC_TEST_RESULTS = 'diagnostic-test-results',
  DOMAINS = 'domains',
  DOMAIN_INVITES = 'domain-invites',
  DOMAIN_USERS = 'domain-users',
  DOMAIN_USER_INTEGRATIONS_CONFIGS = 'domain-user-integrations-configs',
  DOMAIN_USER_TYPE = 'domain-user-type',
  EHRS = 'ehrs',
  INVITES = 'invites',
  IOS_VERSION_BLACKLIST_ENTRIES = 'ios-version-blacklist-entries',
  ANDROID_VERSION_BLACKLIST_ENTRIES = 'android-version-blacklist-entries',
  MEMBERSHIPS = 'memberships',
  MEMBERSHIP_TYPE = 'membership-type',
  ORGANIZATIONS = 'organizations',
  ORGANIZATION_MANAGEMENT_ADMIN_READ_WRITE = 'organization-management-admin-read-write',
  PROVISIONING = 'provisioning',
  STUDIES = 'studies',
  SUBSCRIPTIONS = 'subscriptions',
  USERS = 'users',
  USER_REVIEW_REQUESTS = 'user-review-requests',
  ENTERPRISE_UPGRADES = 'enterprise-upgrades',
  EDUCATION_CONTENT = 'education-content',
  EDUCATION_CATEGORIES = 'education-categories',
  PLACES_OF_WORK = 'places-of-work',
  GENERATE_SHARED_DEVICE_KEY = 'generate-shared-device-key',
  SYSTEM_DEFINED_ROLES = 'system-defined',
  USER_DEFINED_ROLES = 'user-defined',
  EMAIL_CHANGES = 'email-changes',
}

export enum Access {
  NO_ACCESS,
  READ_ONLY,
  READ_WRITE,
}

export type Permissions = { read: boolean; write: boolean };

type AccessRegistry = {
  [x in Access]: Permissions;
};

export const accessRegistry: AccessRegistry = {
  [Access.NO_ACCESS]: { read: false, write: false },
  [Access.READ_ONLY]: { read: true, write: false },
  [Access.READ_WRITE]: { read: true, write: true },
};

type ResourceRegistry = {
  [x in Resource]: { [y: string]: Access };
};

export const resourceRegistry: ResourceRegistry = {
  [Resource.ANDROID_VERSION_BLACKLIST_ENTRIES]: {
    HELIOS: Access.READ_WRITE,
  },
  [Resource.APPLICATION_VERSION_REQUIREMENTS]: {
    HELIOS: Access.READ_WRITE,
  },
  [Resource.AUDIT_LOGS]: {
    MANAGEMENT: Access.READ_WRITE,
  },
  [Resource.BUTTERFLY_DEVICES]: {
    BUTTERFLY_DEVICE: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.BUTTERFLY_DEVICE_RECALLS]: {
    BUTTERFLY_DEVICE: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.COUNTRIES]: {
    MANAGEMENT: Access.READ_WRITE,
  },
  [Resource.CUSTOMERS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.DIAGNOSTIC_TEST_RESULTS]: {
    DIAGNOSTIC: Access.READ_WRITE,
  },
  [Resource.DOMAINS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.DOMAIN_INVITES]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.DOMAIN_USERS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.DOMAIN_USER_INTEGRATIONS_CONFIGS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.DOMAIN_USER_TYPE]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
  },
  [Resource.EDUCATION_CONTENT]: {
    EDUCATION: Access.READ_WRITE,
  },
  [Resource.EDUCATION_CATEGORIES]: {
    EDUCATION: Access.READ_WRITE,
  },
  [Resource.EHRS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.ENTERPRISE_UPGRADES]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
  },
  [Resource.INVITES]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.IOS_VERSION_BLACKLIST_ENTRIES]: {
    HELIOS: Access.READ_WRITE,
  },
  [Resource.MEMBERSHIPS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.MEMBERSHIP_TYPE]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
  },
  [Resource.ORGANIZATIONS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.STUDIES]: {
    STUDY: Access.READ_WRITE,
  },
  [Resource.SUBSCRIPTIONS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.PLACES_OF_WORK]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_WRITE,
  },
  [Resource.PROVISIONING]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
  },
  [Resource.ORGANIZATION_MANAGEMENT_ADMIN_READ_WRITE]: {
    MANAGEMENT: Access.READ_WRITE,
  },
  [Resource.USERS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.USER_REVIEW_REQUESTS]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_WRITE,
  },
  [Resource.GENERATE_SHARED_DEVICE_KEY]: {
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_WRITE,
  },
  [Resource.SYSTEM_DEFINED_ROLES]: {
    BUTTERFLY_ACCESS_ROLES: Access.READ_WRITE,
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.USER_DEFINED_ROLES]: {
    BUTTERFLY_ACCESS_ROLES: Access.READ_WRITE,
    MANAGEMENT: Access.READ_WRITE,
    SALES_OPS: Access.READ_ONLY,
    SUPPORT_OPS: Access.READ_ONLY,
  },
  [Resource.EMAIL_CHANGES]: {
    MANAGEMENT: Access.READ_WRITE,
    SUPPORT_OPS: Access.READ_ONLY,
    SALES_OPS: Access.READ_WRITE,
  },
};
