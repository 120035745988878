/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateCustomerModal_customer = {
    readonly id: string;
    readonly customerId: string | null;
    readonly name: string | null;
    readonly zendeskId: string | null;
    readonly netsuiteId: string | null;
    readonly stripeId: string | null;
    readonly sfAccountId: string | null;
    readonly " $refType": "UpdateCustomerModal_customer";
};
export type UpdateCustomerModal_customer$data = UpdateCustomerModal_customer;
export type UpdateCustomerModal_customer$key = {
    readonly " $data"?: UpdateCustomerModal_customer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateCustomerModal_customer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateCustomerModal_customer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zendeskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netsuiteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sfAccountId",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
(node as any).hash = 'f72c189f5503a490e05fb7132c795c93';
export default node;
