/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry = {
    readonly id: string;
    readonly androidVersionBlacklistEntryId: string | null;
    readonly applicationIdentifier: string | null;
    readonly versionRange: string | null;
    readonly versionCode: number | null;
    readonly " $refType": "AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry";
};
export type AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry$data = AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry;
export type AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry$key = {
    readonly " $data"?: AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "androidVersionBlacklistEntryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionCode",
      "storageKey": null
    }
  ],
  "type": "AndroidVersionBlacklistEntry",
  "abstractKey": null
};
(node as any).hash = 'fab127e2c52e7c493cd3d631ce0f1a5a';
export default node;
