import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import AndroidVersionBlacklistEntriesPage from './components/AndroidVersionBlacklistEntriesPage';
import AndroidVersionBlacklistEntryDetailPage from './components/AndroidVersionBlacklistEntryDetailPage';

export default (
  <Route path="android-version-blacklist-entries">
    <Route
      Component={AndroidVersionBlacklistEntriesPage}
      query={graphql`
        query androidVersionBlacklistEntries_AndroidVersionBlacklistEntries_Query {
          viewer {
            ...AndroidVersionBlacklistEntriesPage_viewer
          }
        }
      `}
    />
    <Route
      path=":androidVersionBlacklistEntryId"
      Component={AndroidVersionBlacklistEntryDetailPage}
      query={graphql`
        query androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_Query(
          $androidVersionBlacklistEntryId: String!
        ) {
          androidVersionBlacklistEntry(
            androidVersionBlacklistEntryId: $androidVersionBlacklistEntryId
          ) {
            ...AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.androidVersionBlacklistEntry) throw new HttpError(404);
      }}
    />
  </Route>
);
