import { defineMessages } from 'react-intl';

export default defineMessages({
  recallId: {
    id: 'butterflyDeviceRecallsTable.heading.recallId',
    defaultMessage: 'Recall ID',
  },
  message: {
    id: 'butterflyDeviceRecallsTable.heading.message',
    defaultMessage: 'Message',
  },
});
