/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userDefinedRoles_UserDefinedRoleDetailPage_QueryVariables = {
    userDefinedRoleId: string;
};
export type userDefinedRoles_UserDefinedRoleDetailPage_QueryResponse = {
    readonly role: {
        readonly " $fragmentRefs": FragmentRefs<"UserDefinedRoleDetailPage_role">;
    } | null;
};
export type userDefinedRoles_UserDefinedRoleDetailPage_Query = {
    readonly response: userDefinedRoles_UserDefinedRoleDetailPage_QueryResponse;
    readonly variables: userDefinedRoles_UserDefinedRoleDetailPage_QueryVariables;
};



/*
query userDefinedRoles_UserDefinedRoleDetailPage_Query(
  $userDefinedRoleId: String!
) {
  role: userDefinedRole(userDefinedRoleId: $userDefinedRoleId) {
    ...UserDefinedRoleDetailPage_role
    id
  }
}

fragment DeleteUserDefinedRoleAction_role on UserDefinedRole {
  id
  userDefinedRoleId
  name
  deletedAt
}

fragment UndeleteUserDefinedRoleAction_role on UserDefinedRole {
  userDefinedRoleId
  name
}

fragment UserDefinedRoleDetailPage_role on UserDefinedRole {
  id
  userDefinedRoleId
  domainId
  name
  description
  permissions
  deletedAt
  ...DeleteUserDefinedRoleAction_role
  ...UndeleteUserDefinedRoleAction_role
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userDefinedRoleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userDefinedRoleId",
    "variableName": "userDefinedRoleId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userDefinedRoles_UserDefinedRoleDetailPage_Query",
    "selections": [
      {
        "alias": "role",
        "args": (v1/*: any*/),
        "concreteType": "UserDefinedRole",
        "kind": "LinkedField",
        "name": "userDefinedRole",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserDefinedRoleDetailPage_role"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userDefinedRoles_UserDefinedRoleDetailPage_Query",
    "selections": [
      {
        "alias": "role",
        "args": (v1/*: any*/),
        "concreteType": "UserDefinedRole",
        "kind": "LinkedField",
        "name": "userDefinedRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userDefinedRoleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domainId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d7eb607842458981fe3ffd3901e8a767",
    "id": null,
    "metadata": {},
    "name": "userDefinedRoles_UserDefinedRoleDetailPage_Query",
    "operationKind": "query",
    "text": "query userDefinedRoles_UserDefinedRoleDetailPage_Query(\n  $userDefinedRoleId: String!\n) {\n  role: userDefinedRole(userDefinedRoleId: $userDefinedRoleId) {\n    ...UserDefinedRoleDetailPage_role\n    id\n  }\n}\n\nfragment DeleteUserDefinedRoleAction_role on UserDefinedRole {\n  id\n  userDefinedRoleId\n  name\n  deletedAt\n}\n\nfragment UndeleteUserDefinedRoleAction_role on UserDefinedRole {\n  userDefinedRoleId\n  name\n}\n\nfragment UserDefinedRoleDetailPage_role on UserDefinedRole {\n  id\n  userDefinedRoleId\n  domainId\n  name\n  description\n  permissions\n  deletedAt\n  ...DeleteUserDefinedRoleAction_role\n  ...UndeleteUserDefinedRoleAction_role\n}\n"
  }
};
})();
(node as any).hash = '1a7a6aaf10a4f5f84795f508ca1aaef6';
export default node;
