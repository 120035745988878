import Header from '@bfly/ui2/Header';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import testResultsTableHeadings from '../messages/tableHeadings';

const resource = Resource.DIAGNOSTIC_TEST_RESULTS;

const gridFilterFields: FilterField[] = [
  {
    title: testResultsTableHeadings.deviceProductionId.defaultMessage,
    field: 'deviceProductionId',
  },
];

interface DiagnosticTestResultsPageProps {
  children?: ReactNode;
}

export default function DiagnosticTestResultsPage({
  children,
}: DiagnosticTestResultsPageProps) {
  useBreadcrumbDetector(pageTitles.diagnosticTestResults);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.diagnosticTestResults} />
        </Header.Title>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
