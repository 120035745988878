import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import debounce from 'lodash/debounce';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  graphql,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { PreloadedQuery } from 'react-relay/relay-hooks/EntryPointTypes';
import { object, string } from 'yup';

import DropdownList, { DropdownListProps } from 'components/DropdownList';
import { ActionModalProps, ResourceModal } from 'components/ResourceModal';
import {
  domainMessages,
  subscriptionIdMessages,
} from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { organizationIdMessages } from '../messages/updateOrganizationAction';
import { JoinDomainActionMutation } from './__generated__/JoinDomainActionMutation.graphql';
import { JoinDomainAction_DomainQuery } from './__generated__/JoinDomainAction_DomainQuery.graphql';
import { JoinDomainAction_SubscriptionQuery } from './__generated__/JoinDomainAction_SubscriptionQuery.graphql';
import { JoinDomainAction_organization$key } from './__generated__/JoinDomainAction_organization.graphql';

const fragment = graphql`
  fragment JoinDomainAction_organization on Organization {
    organizationId
  }
`;

const mutation = graphql`
  mutation JoinDomainActionMutation($input: AddOrganizationToDomainInput!) {
    addOrganizationToDomain(input: $input) {
      organization {
        id
        organizationId
        name
        slug
        country
        deletedAt
        subscription {
          subscriptionId
          subscriptionEndsAt
          accessEndsAt
          hasStartedActiveSubscription
          hasCompletedTrial
          canAccessProFeatures
          isInGracePeriod
          isTeam
          billingStatus
          billingProvider
          stripeSubscriptionId
          sfSubscriptionId
          planType
          maxNumSeats
          practiceType
        }
        isActive
        customer {
          customerId
        }
        domain {
          domainId
        }
        dlDataMode
        dlDataModeOverride
        enterpriseCapabilityManagementEnabled
        enabledPresetIds
        enabledToolIds
        enabledCaptureModes
        enabledCapabilities
        specialType
      }
    }
  }
`;

const domainQuery = graphql`
  query JoinDomainAction_DomainQuery($search: String) {
    viewer {
      domains(search: $search) {
        edges {
          node {
            subdomainLabel
            name
            domainId
          }
        }
      }
    }
  }
`;

const subscriptionQuery = graphql`
  query JoinDomainAction_SubscriptionQuery($domainId: [String!]) {
    viewer {
      subscriptions(domainId: $domainId) {
        edges {
          node {
            subscriptionId
            customer {
              name
              netsuiteId
            }
          }
        }
      }
    }
  }
`;

interface Domain {
  name: string | null;
  domainId: string | null;
  subdomainLabel: string | null;
}

function DomainDropdown(props: DropdownListProps<Domain>) {
  const [search, setSearch] = useState<string>('');
  const setSearchDebounced = debounce(setSearch, 500);

  const { data } = useQuery<JoinDomainAction_DomainQuery>(domainQuery, {
    variables: { search },
    skip: !search,
  });

  const list =
    data?.viewer?.domains && search ? getNodes(data.viewer.domains) : [];

  return (
    <DropdownList
      data={list}
      textField={(domain: Domain) =>
        `${domain.name} (${domain.subdomainLabel})`
      }
      dataKey="domainId"
      filter={() => true}
      onToggle={() => setSearch('')}
      busy={!data && !!search}
      onKeyUp={({ target }) => {
        setSearchDebounced((target as HTMLInputElement).value);
      }}
      {...props}
    />
  );
}

interface SubscriptionDropdownProps
  extends DropdownListProps<{
    id: string;
    label: string;
  }> {
  queryReference: PreloadedQuery<JoinDomainAction_SubscriptionQuery>;
}

function SubscriptionDropdown({
  queryReference,
  ...props
}: SubscriptionDropdownProps) {
  const response = usePreloadedQuery<JoinDomainAction_SubscriptionQuery>(
    subscriptionQuery,
    queryReference,
  );

  const list = useMemo(
    () =>
      getNodes(response.viewer!.subscriptions).map((subscription) => ({
        id: subscription.subscriptionId!,
        label: `${subscription.subscriptionId} | Customer: ${
          subscription!.customer!.name
        } (${
          subscription?.customer?.netsuiteId
            ? subscription.customer.netsuiteId
            : 'No Netsuite ID'
        })`,
      })),
    [response],
  );

  return (
    <DropdownList data={list} dataKey="id" textField="label" {...props} />
  );
}

const schema = object({
  domain: object().required(domainMessages.required),
  subscription: object().required(subscriptionIdMessages.required),
  organizationId: string().required(organizationIdMessages.required),
});

interface JoinDomainModalProps extends ActionModalProps {
  organizationRef: JoinDomainAction_organization$key;
}

function JoinDomainModal({
  show,
  onClose,
  organizationRef,
}: JoinDomainModalProps) {
  const { organizationId } = useFragment(fragment, organizationRef);
  const [domain, setDomain] = useState<Domain>();
  const [subscriptionQueryRef, loadSubscriptions] =
    useQueryLoader<JoinDomainAction_SubscriptionQuery>(subscriptionQuery);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (domain) {
      loadSubscriptions({
        domainId: [domain.domainId!],
      });
    }
  }, [domain, loadSubscriptions]);

  const handleClose = () => {
    setDomain(undefined);
    onClose();
  };

  return (
    <ResourceModal<JoinDomainActionMutation>
      title={
        <FormattedMessage
          id="joinExistingDomain.title"
          defaultMessage="Join Existing Domain"
        />
      }
      show={show}
      submitText={
        <FormattedMessage
          id="joinExistingDomain.submitText"
          defaultMessage="Join Existing Domain"
        />
      }
      onClose={handleClose}
      schema={schema}
      mutation={mutation}
      defaultValue={{ organizationId }}
      transformOnSubmit={(value) => ({
        organizationId: value.organizationId,
        subscriptionId: value.subscription.id,
      })}
    >
      <Form.FieldGroup
        disabled
        name="organizationId"
        placeholder={formatMessage(organizationIdMessages.placeholder)}
        label={<FormattedMessage {...organizationIdMessages.label} />}
        data-cy="joinDomain-organizationId"
      />
      <Form.FieldGroup
        name="domain"
        as={DomainDropdown}
        value={domain}
        readOnly={!!domain}
        onChange={setDomain}
        label={<FormattedMessage {...domainMessages.label} />}
        placeholder={domainMessages.placeholder}
        data-cy="joinDomain-domain"
      />
      {domain && subscriptionQueryRef && (
        <Suspense fallback={<LoadingIndicator size="sm" />}>
          <Form.FieldGroup
            name="subscription"
            as={SubscriptionDropdown}
            queryReference={subscriptionQueryRef}
            label={<FormattedMessage {...subscriptionIdMessages.label} />}
            placeholder={subscriptionIdMessages.placeholder}
            data-cy="joinDomain-subscription"
          />
        </Suspense>
      )}
    </ResourceModal>
  );
}

interface JoinDomainActionProps {
  organizationRef: JoinDomainAction_organization$key;
}

export default function JoinDomainAction({
  organizationRef,
}: JoinDomainActionProps) {
  const [showJoinDomain, setShowJoinDomain] = useState(false);
  return (
    <PermissionsGuard
      resource={Resource.ENTERPRISE_UPGRADES}
      write
      hideContent
    >
      <Button
        variant="secondary"
        data-cy="join-existing-domain"
        onClick={() => setShowJoinDomain(true)}
      >
        <FormattedMessage
          id="organizationDetail.actions.joinExistingDomain"
          defaultMessage="Join Existing Domain"
        />
      </Button>
      <JoinDomainModal
        organizationRef={organizationRef}
        show={showJoinDomain}
        onClose={() => setShowJoinDomain(false)}
      />
    </PermissionsGuard>
  );
}
