/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type invites_OrganizationInviteDetailPage_QueryVariables = {
    organizationInviteId: string;
};
export type invites_OrganizationInviteDetailPage_QueryResponse = {
    readonly invite: {
        readonly " $fragmentRefs": FragmentRefs<"InviteDetailPage_organizationInvite">;
    } | null;
};
export type invites_OrganizationInviteDetailPage_Query = {
    readonly response: invites_OrganizationInviteDetailPage_QueryResponse;
    readonly variables: invites_OrganizationInviteDetailPage_QueryVariables;
};



/*
query invites_OrganizationInviteDetailPage_Query(
  $organizationInviteId: String!
) {
  invite: organizationInvite(organizationInviteId: $organizationInviteId) {
    ...InviteDetailPage_organizationInvite
    id
  }
}

fragment DeleteInviteAction_organizationInvite on OrganizationInvite {
  organizationInviteId
  deletedAt
}

fragment InviteDetailPage_organizationInvite on OrganizationInvite {
  ...UpdateInviteModal_organizationInvite
  ...DeleteInviteAction_organizationInvite
  ...ShowInviteLinkAction_organizationInvite
  id
  organizationInviteId
  email
  roleId
  makeOrganizationAdmin
  createdAt
  deletedAt
  user {
    userId
    id
  }
  organization {
    domain {
      domainId
      id
    }
    organizationId
    name
    id
  }
}

fragment ShowInviteLinkAction_organizationInvite on OrganizationInvite {
  organizationInviteId
}

fragment UpdateInviteModal_organizationInvite on OrganizationInvite {
  id
  organizationInviteId
  email
  roleId
  makeOrganizationAdmin
  organization {
    organizationId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationInviteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationInviteId",
    "variableName": "organizationInviteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "invites_OrganizationInviteDetailPage_Query",
    "selections": [
      {
        "alias": "invite",
        "args": (v1/*: any*/),
        "concreteType": "OrganizationInvite",
        "kind": "LinkedField",
        "name": "organizationInvite",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InviteDetailPage_organizationInvite"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "invites_OrganizationInviteDetailPage_Query",
    "selections": [
      {
        "alias": "invite",
        "args": (v1/*: any*/),
        "concreteType": "OrganizationInvite",
        "kind": "LinkedField",
        "name": "organizationInvite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationInviteId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "makeOrganizationAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationId",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5aaa39f4dffc6b8b033b022087d4fccb",
    "id": null,
    "metadata": {},
    "name": "invites_OrganizationInviteDetailPage_Query",
    "operationKind": "query",
    "text": "query invites_OrganizationInviteDetailPage_Query(\n  $organizationInviteId: String!\n) {\n  invite: organizationInvite(organizationInviteId: $organizationInviteId) {\n    ...InviteDetailPage_organizationInvite\n    id\n  }\n}\n\nfragment DeleteInviteAction_organizationInvite on OrganizationInvite {\n  organizationInviteId\n  deletedAt\n}\n\nfragment InviteDetailPage_organizationInvite on OrganizationInvite {\n  ...UpdateInviteModal_organizationInvite\n  ...DeleteInviteAction_organizationInvite\n  ...ShowInviteLinkAction_organizationInvite\n  id\n  organizationInviteId\n  email\n  roleId\n  makeOrganizationAdmin\n  createdAt\n  deletedAt\n  user {\n    userId\n    id\n  }\n  organization {\n    domain {\n      domainId\n      id\n    }\n    organizationId\n    name\n    id\n  }\n}\n\nfragment ShowInviteLinkAction_organizationInvite on OrganizationInvite {\n  organizationInviteId\n}\n\nfragment UpdateInviteModal_organizationInvite on OrganizationInvite {\n  id\n  organizationInviteId\n  email\n  roleId\n  makeOrganizationAdmin\n  organization {\n    organizationId\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2c03582a13f0c008d853e72bdb55ba17';
export default node;
