import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';

import emailChangeTableHeadings from '../messages/emailChangesTableHeadings';
import { EmailChangeRequestsGrid_emailChangeRequest as EmailChangeRequest } from './__generated__/EmailChangeRequestsGrid_emailChangeRequest.graphql';
import { EmailChangeRequestsGrid_viewer$key } from './__generated__/EmailChangeRequestsGrid_viewer.graphql';

const _ = graphql`
  fragment EmailChangeRequestsGrid_emailChangeRequest on EmailChangeRequest {
    id
    emailChangeRequestId
    email
    oldEmail
    createdAt
    expiresAt
    auth0Id
  }
`;

const fragment = graphql`
  fragment EmailChangeRequestsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    email: { type: "[String!]" }
    oldEmail: { type: "[String!]" }
  )
  @refetchable(queryName: "EmailChangeRequestsGridRefetchQuery") {
    emailChangeRequests(
      first: $count
      after: $cursor
      email: $email
      oldEmail: $oldEmail
    ) @connection(key: "EmailChangeRequestsGrid_emailChangeRequests") {
      edges {
        node {
          ...EmailChangeRequestsGrid_emailChangeRequest @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<EmailChangeRequest>[] = [
  {
    key: 'emailChangeRequestId',
    label: <FormattedMessage {...emailChangeTableHeadings.id} />,
    render: ({ item }) => (
      <QuickCopyText text={item.emailChangeRequestId}>
        <DataGridLink
          path="/email-changes"
          title={item.emailChangeRequestId!}
          id={item.emailChangeRequestId!}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'email',
    label: <FormattedMessage {...emailChangeTableHeadings.email} />,
    render: ({ item }) => <DataGridText value={item.email} />,
  },
  {
    key: 'oldEmail',
    label: <FormattedMessage {...emailChangeTableHeadings.oldEmail} />,
    render: ({ item }) => <DataGridText value={item.oldEmail} />,
  },
  {
    key: 'auth0Id',
    label: <FormattedMessage {...emailChangeTableHeadings.auth0Id} />,
    render: ({ item }) => (
      <QuickCopyText text={item.auth0Id}>
        <DataGridText value={item.auth0Id} />
      </QuickCopyText>
    ),
  },
  {
    key: 'createdAt',
    label: <FormattedMessage {...emailChangeTableHeadings.createdAt} />,
    render: ({ item }) => <DataGridDateTime value={item.createdAt} />,
  },
  {
    key: 'expiresAt',
    label: <FormattedMessage {...emailChangeTableHeadings.expiresAt} />,
    render: ({ item }) => <DataGridDateTime value={item.expiresAt} />,
  },
];

interface EmailChangeRequestsGridProps {
  viewer: EmailChangeRequestsGrid_viewer$key;
}

export default function EmailChangeRequestsGrid({
  viewer,
}: EmailChangeRequestsGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.emailChangeRequests);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      spec={tableSpec}
      hasNext={hasNext}
      scrollKey="data-grid"
    />
  );
}
