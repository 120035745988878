import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import Multiselect from '@bfly/ui2/Multiselect';
import useQuery from '@bfly/ui2/useQuery';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters, graphql } from 'relay-runtime';
import { object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { PresetLoader, PresetTemplate } from 'components/PresetLoader';
import {
  ResourceModal,
  ResourceModalProps,
  getDefaultFormValue,
} from 'components/ResourceModal';
import { formMessages, practiceTypeMessages } from 'messages/formMessages';
import {
  adminEmailMessages,
  billingProviderMessages,
  billingStatusMessages,
  countryMessages,
  customerIdMessages,
  dlDataModeOverrideMessages,
  featureModuleIdsMessages,
  maxNumSeatsMessages,
  organizationIdMessages,
  organizationNameMessages,
  organizationSlugMessages,
  planTypeMessages,
  salesforceSubscriptionIdMessages,
  specialTypeMessages,
  stripeSubscriptionIdMessages,
  subscriptionEndsAtMessages,
  subscriptionIdMessages,
} from 'messages/organizationActions';
import {
  billingProviderData,
  billingStatusData,
  dlDataModeOverrideData,
  planTypeData,
  practiceTypeData,
  specialTypesData,
} from 'messages/organizationOptionsData';
import { presetLoaderMessages } from 'messages/presetLoader';

import Alert from './Alert';

export const PRO_CUSTOM = 'PRO_CUSTOM';
export const INDIVIDUAL_FOREVER = 'INDIVIDUAL_FOREVER';
export const EDUCATION = 'EDUCATION';
export const ENTERPRISE_WORKFLOW = 'ENTERPRISE_WORKFLOW';
export const RESIDENCY_PROGRAM = 'RESIDENCY_PROGRAM';
export const DEPARTMENT = 'DEPARTMENT';
export const HOSPITAL = 'HOSPITAL';
export const MEDICAL_SCHOOL = 'MEDICAL_SCHOOL';

export const PRO_CUSTOM_FEATURE_MODULE_IDS = [
  'CORE',
  'CPTNAI1',
  'PCUS-DIAT',
  'PCUS-ED',
  'PCUS-COLB',
  'PCUS-PROT',
];
export const EDU_FEATURE_MODULE_IDS = ['BFLYACADMEDED'];

const countryConfigurations = graphql`
  query OrganizationModalCountryConfiguration_Query($dataRegion: [String!]) {
    viewer {
      countryConfigurations(dataRegion: $dataRegion) {
        edges {
          node {
            dataRegion
            countryId
          }
        }
      }
    }
  }
`;
export const basicOrganizationSchema = object({
  name: string()
    .required(organizationNameMessages.required)
    .min(2, formMessages.minLength)
    .max(30, formMessages.maxLength),
  slug: string()
    .trim()
    .matches(/^[A-Za-z0-9](-?[A-Za-z0-9])*$/, {
      message: organizationSlugMessages.matches,
      excludeEmptyString: true,
    })
    .transform((v) => v || undefined),
  specialType: string()
    .required(specialTypeMessages.required)
    .oneOf(specialTypesData.map(({ value }) => value)),
});
const basicTemplateValues = {
  practiceType: 'HUMAN',
  billingStatus: 'ACTIVE',
  billingProvider: 'STRIPE',
  specialType: 'NONE',
  dlDataModeOverride: 'EXCLUDE',
};
const organizationPresets: PresetTemplate[] = [
  {
    title: presetLoaderMessages.educationPreset,
    template: {
      planType: 'EDUCATION',
      maxNumSeats: 100,
      subscriptionEndsAt: new Date(2098, 0, 1),
      ...basicTemplateValues,
    },
  },
  {
    title: presetLoaderMessages.individualLifetimePreset,
    template: {
      planType: 'INDIVIDUAL_FOREVER',
      maxNumSeats: 1,
      subscriptionEndsAt: null,
      ...basicTemplateValues,
    },
  },
];
interface OrganizationModalProps<TMutation extends MutationParameters>
  extends ResourceModalProps<TMutation> {
  forSubscription?: boolean;
}
export function OrganizationModal<TMutation extends MutationParameters>({
  alertMessage,
  forSubscription,
  ...props
}: OrganizationModalProps<TMutation>) {
  const regionFromLocalStorage = window.localStorage.getItem('kldsp:region');
  const { data } = useQuery<any>(countryConfigurations, {
    variables: {},
  });
  const countryConfigurationsData: any[] = [];
  data?.viewer?.countryConfigurations.edges.forEach((x) => {
    countryConfigurationsData.push({
      name: x.node.countryId,
      value: x.node.countryId,
    });
  });
  const { formatMessage } = useIntl();
  const isCreate = !props.defaultValue?.organizationId;
  const [formValue, setFormValue] = useState(
    getDefaultFormValue(props.schema, props.defaultValue),
  );
  const [planType, setPlanType] = useState<string | null>(null);
  const isIndividualForever = planType === INDIVIDUAL_FOREVER;
  return (
    <ResourceModal<TMutation>
      {...props}
      value={formValue}
      onChange={(input) => setFormValue(input)}
      transformOnSubmit={({ subscriptionEndsAt, ...fields }) => {
        return {
          ...fields,
          subscriptionEndsAt: isIndividualForever ? null : subscriptionEndsAt,
        };
      }}
    >
      {alertMessage}
      {isCreate && !forSubscription && (
        <PresetLoader
          templates={organizationPresets}
          onApply={(input) => setFormValue({ ...formValue, ...input })}
        />
      )}
      {isCreate && !forSubscription && (
        <>
          <Form.FieldGroup
            name="planType"
            as={DropdownList}
            data={planTypeData}
            onChange={({ value }) => {
              setPlanType(value);
            }}
            placeholder={planTypeMessages.placeholder}
            label={<FormattedMessage {...planTypeMessages.label} />}
            description={
              <FormattedMessage {...planTypeMessages.description} />
            }
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="organization-planTypeField"
          />
          <Form.FieldGroup
            name="maxNumSeats"
            placeholder={formatMessage(maxNumSeatsMessages.placeholder)}
            label={<FormattedMessage {...maxNumSeatsMessages.label} />}
            description={
              <FormattedMessage {...maxNumSeatsMessages.description} />
            }
            data-cy="organization-maxNumSeatsMessagesField"
          />
          <Form.FieldGroup
            name="practiceType"
            as={DropdownList}
            data={practiceTypeData}
            placeholder={practiceTypeMessages.placeholder}
            label={
              <FormattedMessage {...practiceTypeMessages.requiredLabel} />
            }
            description={
              <FormattedMessage {...practiceTypeMessages.description} />
            }
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="organization-practiceTypeField"
          />
          <Form.FieldGroup
            name="subscriptionEndsAt"
            as={DatePicker}
            valueFormat={{
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }}
            includeTime
            disabled={isIndividualForever}
            placeholder={subscriptionEndsAtMessages.placeholder}
            label={<FormattedMessage {...subscriptionEndsAtMessages.label} />}
            description={
              isIndividualForever ? (
                <FormattedMessage
                  {...subscriptionEndsAtMessages.description}
                />
              ) : (
                ''
              )
            }
            data-cy="organization-subscriptionEndsAtField"
          />
          <Form.FieldGroup
            name="billingStatus"
            as={DropdownList}
            data={billingStatusData}
            placeholder={billingStatusMessages.placeholder}
            label={
              <FormattedMessage {...billingStatusMessages.requiredLabel} />
            }
            description={
              <FormattedMessage {...billingStatusMessages.description} />
            }
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="organization-billingStatusField"
          />
          <Form.FieldGroup
            name="billingProvider"
            as={DropdownList}
            data={billingProviderData}
            placeholder={billingProviderMessages.placeholder}
            label={
              <FormattedMessage {...billingProviderMessages.requiredLabel} />
            }
            description={
              <FormattedMessage {...billingProviderMessages.description} />
            }
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="organization-billingProviderField"
          />
          <Form.FieldGroup
            name="stripeSubscriptionId"
            placeholder={formatMessage(
              stripeSubscriptionIdMessages.placeholder,
            )}
            label={
              <FormattedMessage {...stripeSubscriptionIdMessages.label} />
            }
            description={
              <FormattedMessage
                {...stripeSubscriptionIdMessages.description}
              />
            }
            data-cy="organization-stripeSubscriptionIdField"
          />
          <Form.FieldGroup
            name="sfSubscriptionId"
            placeholder={formatMessage(
              salesforceSubscriptionIdMessages.placeholder,
            )}
            label={
              <FormattedMessage {...salesforceSubscriptionIdMessages.label} />
            }
            description={
              <FormattedMessage
                {...salesforceSubscriptionIdMessages.description}
              />
            }
            data-cy="organization-salesforceSubscriptionIdField"
          />
          <Form.FieldGroup
            name="featureModuleIds"
            as={Multiselect}
            data={
              planType === PRO_CUSTOM
                ? PRO_CUSTOM_FEATURE_MODULE_IDS
                : EDU_FEATURE_MODULE_IDS
            }
            label={<FormattedMessage {...featureModuleIdsMessages.label} />}
            placeholder={formatMessage(featureModuleIdsMessages.placeholder)}
            description={
              <FormattedMessage {...featureModuleIdsMessages.description} />
            }
            disabled={
              !planType ||
              ![PRO_CUSTOM, ENTERPRISE_WORKFLOW, EDUCATION].includes(planType)
            }
            data-cy="organization-featureModuleIds"
          />
          <Form.FieldGroup
            name="customerId"
            placeholder={formatMessage(customerIdMessages.placeholder)}
            label={<FormattedMessage {...customerIdMessages.label} />}
            description={
              <FormattedMessage {...customerIdMessages.description} />
            }
            data-cy="organization-customerIdField"
          />
        </>
      )}
      <Form.FieldGroup
        name="name"
        placeholder={formatMessage(organizationNameMessages.placeholder)}
        label={<FormattedMessage {...organizationNameMessages.label} />}
        description={
          <FormattedMessage {...organizationNameMessages.description} />
        }
        data-cy="organization-nameField"
      />
      <Form.FieldGroup
        name="slug"
        placeholder={formatMessage(organizationSlugMessages.placeholder)}
        label={<FormattedMessage {...organizationSlugMessages.label} />}
        description={
          <FormattedMessage {...organizationSlugMessages.description} />
        }
        data-cy="organization-slugField"
      />
      <Form.FieldGroup
        name="specialType"
        as={DropdownList}
        data={specialTypesData}
        placeholder={specialTypeMessages.placeholder}
        label={<FormattedMessage {...specialTypeMessages.label} />}
        description={<FormattedMessage {...specialTypeMessages.description} />}
        textField="name"
        dataKey="value"
        mapFromValue={({ value }) => value}
        data-cy="organization-specialTypeField"
      />
      {!isCreate && !forSubscription && (
        <Form.FieldGroup
          disabled
          name="organizationId"
          placeholder={formatMessage(organizationIdMessages.placeholder)}
          label={<FormattedMessage {...organizationIdMessages.label} />}
          data-cy="organization-organizationIdField"
        />
      )}
      {(isCreate || forSubscription) && (
        <>
          <Form.FieldGroup
            name="adminEmail"
            placeholder={formatMessage(adminEmailMessages.placeholder)}
            label={<FormattedMessage {...adminEmailMessages.label} />}
            description={
              <FormattedMessage {...adminEmailMessages.description} />
            }
            data-cy="organization-adminEmailField"
          />
          <Form.FieldGroup
            name="dlDataModeOverride"
            as={DropdownList}
            data={dlDataModeOverrideData}
            label={<FormattedMessage {...dlDataModeOverrideMessages.label} />}
            placeholder={dlDataModeOverrideMessages.placeholder}
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="organization-dlDataModeOverride"
          />
          <Alert variant="danger" className="mb-5">
            <FormattedMessage
              id="createOrganizationModal.danger"
              defaultMessage="{message}"
              values={{
                message: `You are currently in the ${regionFromLocalStorage} region. Please be sure that both the region and country selected are as intended before proceeding.`,
              }}
            />
          </Alert>
          <Form.FieldGroup
            name="country"
            as={DropdownList}
            data={countryConfigurationsData}
            placeholder={countryMessages.placeholder}
            label={<FormattedMessage {...countryMessages.label} />}
            description={<FormattedMessage {...countryMessages.description} />}
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="organization-countryField"
          />
        </>
      )}
      {forSubscription && (
        <Form.FieldGroup
          disabled
          name="subscriptionId"
          placeholder={formatMessage(subscriptionIdMessages.placeholder)}
          label={<FormattedMessage {...subscriptionIdMessages.label} />}
          data-cy="organization-subscriptionIdField"
        />
      )}
    </ResourceModal>
  );
}
