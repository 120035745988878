/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EmailChangeRequestsGrid_emailChangeRequest = {
    readonly id: string;
    readonly emailChangeRequestId: string | null;
    readonly email: string | null;
    readonly oldEmail: string | null;
    readonly createdAt: string | null;
    readonly expiresAt: string | null;
    readonly auth0Id: string | null;
    readonly " $refType": "EmailChangeRequestsGrid_emailChangeRequest";
};
export type EmailChangeRequestsGrid_emailChangeRequest$data = EmailChangeRequestsGrid_emailChangeRequest;
export type EmailChangeRequestsGrid_emailChangeRequest$key = {
    readonly " $data"?: EmailChangeRequestsGrid_emailChangeRequest$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EmailChangeRequestsGrid_emailChangeRequest">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailChangeRequestsGrid_emailChangeRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailChangeRequestId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oldEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auth0Id",
      "storageKey": null
    }
  ],
  "type": "EmailChangeRequest",
  "abstractKey": null
};
(node as any).hash = '9304d9853d0248bfd5645ba9481a632c';
export default node;
