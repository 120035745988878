import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import butterflyDeviceActionMessages, {
  productionIdMessages,
  recallIdMessages,
} from '../messages/butterflyDeviceActionMessages';
import {
  SetButterflyDeviceStatusInput,
  SetProbeStatusActionMutation,
} from './__generated__/SetProbeStatusActionMutation.graphql';
import { SetProbeStatusAction_butterflyDevice$key } from './__generated__/SetProbeStatusAction_butterflyDevice.graphql';

const messages = defineMessages({
  statusLabel: {
    id: 'setStatusAction.statuslabel',
    defaultMessage: 'Status*',
  },
  statusPlaceholder: {
    id: 'setStatusAction.statusPlaceholder',
    defaultMessage: 'Device status',
  },
  brickedReasonLabel: {
    id: 'setStatusAction.brickedReasonlabel',
    defaultMessage: 'Reason for bricking*',
  },
  activeReasonLabel: {
    id: 'setStatusAction.activeReasonlabel',
    defaultMessage: 'Reason for activating*',
  },
  recalledReasonLabel: {
    id: 'setStatusAction.recalledReasonlabel',
    defaultMessage: 'Reason for recalling*',
  },
  lostNoteLabel: {
    id: 'setStatusAction.lostNotelabel',
    defaultMessage: 'Note for losing*',
  },
  stolenNoteLabel: {
    id: 'setStatusAction.stolenReasonlabel',
    defaultMessage: 'Note for stealing*',
  },
  brickedReasonPlaceholder: {
    id: 'setStatusAction.brickedReasonPlaceholder',
    defaultMessage: 'Reason for bricking',
  },
  brickedReasonRequired: {
    id: 'setStatusAction.brickedReasonrequired',
    defaultMessage: 'Reason for bricking is a required field',
  },
  lostNotePlaceholder: {
    id: 'setStatusAction.lostNotePlaceholder',
    defaultMessage: 'Note for loosing',
  },
  lostNoteRequired: {
    id: 'setStatusAction.lostNoterequired',
    defaultMessage: 'Note for losing is a required field',
  },
  stolenNotePlaceholder: {
    id: 'setStatusAction.stolenNotePlaceholder',
    defaultMessage: 'Reason for stealing',
  },
  stolenNoteRequired: {
    id: 'setStatusAction.stolenNoterequired',
    defaultMessage: 'Note for stealing is a required field',
  },
  activeReasonPlaceholder: {
    id: 'setStatusAction.brickedReasonPlaceholder',
    defaultMessage: 'Reason for active',
  },
  activeReasonRequired: {
    id: 'setStatusAction.activeReasonrequired',
    defaultMessage: 'Reason for active is a required field',
  },
});

const STATUSES = ['ACTIVE', 'LOST', 'STOLEN', 'RECALLED', 'BRICKED'];

const setStatusSchema = object({
  productionId: string().meta({ readOnly: true }).required(),
  status: string().oneOf(STATUSES).required(),
  recallId: string()
    .nullable()
    .when('status', {
      is: 'RECALLED',
      then: string().nullable().required(recallIdMessages.required),
      otherwise: string().strip(),
    }),
  brickedReason: string()
    .nullable()
    .when('status', {
      is: 'BRICKED',
      then: string().nullable().required(messages.brickedReasonRequired),
      otherwise: string().strip(),
    }),
  activeReason: string()
    .nullable()
    .when('status', {
      is: 'ACTIVE',
      then: string().nullable().required(messages.activeReasonRequired),
      otherwise: string().strip(),
    }),
  stolenNote: string()
    .nullable()
    .when('status', {
      is: 'STOLEN',
      then: string().nullable().required(messages.stolenNoteRequired),
      otherwise: string().strip(),
    }),
  lostNote: string()
    .nullable()
    .when('status', {
      is: 'LOST',
      then: string().nullable().required(messages.lostNoteRequired),
      otherwise: string().strip(),
    }),
});

const fragment = graphql`
  fragment SetProbeStatusAction_butterflyDevice on ButterflyDevice {
    id
    productionId
    recallId
    brickedAt
    brickedReason
    activeReason
    stolenNote
    lostNote
    status
  }
`;

const mutation = graphql`
  mutation SetProbeStatusActionMutation(
    $input: SetButterflyDeviceStatusInput!
  ) {
    setButterflyDeviceStatus(input: $input) {
      butterflyDevice {
        ...ButterflyDeviceDetailPage_butterflyDevice
      }
    }
  }
`;

interface SetProbeStatusActionProps {
  butterflyDeviceRef: SetProbeStatusAction_butterflyDevice$key;
}

export default function SetProbeStatusAction({
  butterflyDeviceRef,
}: SetProbeStatusActionProps) {
  const {
    productionId,
    recallId,
    brickedReason,
    activeReason,
    stolenNote,
    lostNote,
    status,
  } = useFragment(fragment, butterflyDeviceRef);
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState<SetButterflyDeviceStatusInput>(() => ({
    productionId: productionId!,
    status: status!,
    brickedReason,
    activeReason,
    stolenNote,
    lostNote,
    recallId,
  }));
  const { formatMessage } = useIntl();

  return (
    <PermissionsGuard resource={Resource.BUTTERFLY_DEVICES} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage {...butterflyDeviceActionMessages.setStatus} />
      </Button>
      <ResourceModal<
        SetProbeStatusActionMutation,
        SetButterflyDeviceStatusInput
      >
        schema={setStatusSchema}
        mutation={mutation}
        value={value}
        onChange={setValue}
        transformOnSubmit={(data) => {
          const serialized = setStatusSchema.cast(data, {
            stripUnknown: true,
          });
          return serialized;
        }}
        show={showModal}
        onClose={() => setShowModal(false)}
        title={
          <FormattedMessage {...butterflyDeviceActionMessages.setStatus} />
        }
        submitText={
          <FormattedMessage {...butterflyDeviceActionMessages.setStatus} />
        }
      >
        <Form.FieldGroup
          disabled
          name="productionId"
          label={<FormattedMessage {...productionIdMessages.label} />}
          placeholder={formatMessage(productionIdMessages.placeholder)}
          data-cy="setStatusAction-productionIdField"
        />
        <Form.FieldGroup
          name="status"
          as={DropdownList}
          data={STATUSES}
          disabled={
            status !== 'ACTIVE'
              ? ['LOST', 'STOLEN', 'RECALLED', 'BRICKED']
              : []
          }
          label={<FormattedMessage {...messages.statusLabel} />}
          placeholder={messages.statusPlaceholder}
          data-cy="setStatusAction-statusField"
        />
        {value.status === 'ACTIVE' && (
          <Form.FieldGroup
            name="activeReason"
            label={<FormattedMessage {...messages.activeReasonLabel} />}
            placeholder={formatMessage(messages.activeReasonPlaceholder)}
            data-cy="setStatusAction-activeReasonField"
          />
        )}
        {value.status === 'BRICKED' && (
          <Form.FieldGroup
            name="brickedReason"
            label={<FormattedMessage {...messages.brickedReasonLabel} />}
            placeholder={formatMessage(messages.brickedReasonPlaceholder)}
            data-cy="setStatusAction-brickedReasonField"
          />
        )}
        {value.status === 'RECALLED' && (
          <Form.FieldGroup
            name="recallId"
            label={<FormattedMessage {...recallIdMessages.label} />}
            placeholder={formatMessage(recallIdMessages.placeholder)}
            data-cy="setStatusAction-recallIdField"
          />
        )}
        {value.status === 'LOST' && (
          <Form.FieldGroup
            name="lostNote"
            label={<FormattedMessage {...messages.lostNoteLabel} />}
            placeholder={formatMessage(messages.lostNotePlaceholder)}
            data-cy="setStatusAction-lostNoteField"
          />
        )}
        {value.status === 'STOLEN' && (
          <Form.FieldGroup
            name="stolenNote"
            label={<FormattedMessage {...messages.stolenNoteLabel} />}
            placeholder={formatMessage(messages.stolenNotePlaceholder)}
            data-cy="setStatusAction-stolenNoteField"
          />
        )}
        {/* make some room for the dropdown */}
        <div css="min-height: 18rem;" />
      </ResourceModal>
    </PermissionsGuard>
  );
}
