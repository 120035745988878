import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteEhrAction from './DeleteEhrAction';
import { PROCEDUR_ID_PATHS } from './EhrModal';
import { UpdateEhrModal } from './UpdateEhrModal';
import { EhrDetailPage_ehr$key } from './__generated__/EhrDetailPage_ehr.graphql';

const fragment = graphql`
  fragment EhrDetailPage_ehr on Ehr {
    ...UpdateEhrModal_ehr
    ...DeleteEhrAction_ehr
    id
    ehrId
    name
    connectionId
    createdAt
    updatedAt
    deletedAt
    hospitalHost
    hospitalPort
    timeoutAck
    timeoutConnection
    timezone
    procedureIdPath
    processingId
    receivingApplication
    receivingFacility
    sendingFacility
    characterEncoding
    studyDescriptionMapping
    studyUrlDisplay
    organization {
      organizationId
    }
    domain {
      domainId
    }
  }
`;

interface EhrDetailPageProps {
  ehr: EhrDetailPage_ehr$key;
}

export default function EhrDetailPage({ ehr: ehrRef }: EhrDetailPageProps) {
  const ehr = useFragment(fragment, ehrRef);
  const { organization, domain, name, procedureIdPath } = ehr;
  useBreadcrumbDetector(pageTitles.ehr, name);
  const { canWrite } = usePermissionsContext();

  const renderMap = useMemo(() => {
    return {
      procedureIdPath: {
        label: () => 'procedureIdPath',
        value: () => {
          return PROCEDUR_ID_PATHS.find((x) => x.value === procedureIdPath)
            ?.label;
        },
      },
    };
  }, [procedureIdPath]);

  return (
    <PermissionsGuard resource={Resource.EHRS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.ehr} />}
        name={ehr.name!}
        data={ehr}
        quickCopyFields={[
          'name',
          'ehrId',
          'connectionId',
          'receivingFacility',
          'sendingFacility',
          'organization: organizationId',
          'domain: domainId',
        ]}
        objectFields={['studyDescriptionMapping']}
        relatedItems={
          <>
            {organization && (
              <RelatedItem
                name="Organization"
                to={`/organizations/${organization.organizationId}`}
              >
                <FormattedMessage
                  id="ehrDetail.relatedItem.organization"
                  defaultMessage="Organization"
                />
              </RelatedItem>
            )}
            {domain && (
              <RelatedItem name="Domain" to={`/domains/${domain.domainId}`}>
                <FormattedMessage
                  id="ehrDetail.relatedItem.domain"
                  defaultMessage="Domain"
                />
              </RelatedItem>
            )}
          </>
        }
        crudActions={
          canWrite(Resource.EHRS) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateEhrModal {...props} ehrRef={ehr} />
                )}
              />
              <DeleteEhrAction ehrRef={ehr} />
            </>
          )
        }
        renderMap={renderMap}
      />
    </PermissionsGuard>
  );
}
