/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type organizations_OrganizationsPage_QueryVariables = {};
export type organizations_OrganizationsPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"OrganizationsPage_viewer">;
    } | null;
};
export type organizations_OrganizationsPage_Query = {
    readonly response: organizations_OrganizationsPage_QueryResponse;
    readonly variables: organizations_OrganizationsPage_QueryVariables;
};



/*
query organizations_OrganizationsPage_Query {
  viewer {
    ...OrganizationsPage_viewer
    id
  }
}

fragment CreateOrganizationModal_viewer on Viewer {
  id
}

fragment OrganizationsPage_viewer on Viewer {
  ...CreateOrganizationModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "organizations_OrganizationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationsPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "organizations_OrganizationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fcff105c768f5ef6e1f931592bb9dd1d",
    "id": null,
    "metadata": {},
    "name": "organizations_OrganizationsPage_Query",
    "operationKind": "query",
    "text": "query organizations_OrganizationsPage_Query {\n  viewer {\n    ...OrganizationsPage_viewer\n    id\n  }\n}\n\nfragment CreateOrganizationModal_viewer on Viewer {\n  id\n}\n\nfragment OrganizationsPage_viewer on Viewer {\n  ...CreateOrganizationModal_viewer\n}\n"
  }
};
(node as any).hash = '4b286054558153d592c9301df4635608';
export default node;
