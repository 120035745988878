import { defineMessages } from 'react-intl';

export const organizationNameMessages = defineMessages({
  label: {
    id: 'organization.name.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'organization.name.placeholder',
    defaultMessage: 'Name',
  },
  required: {
    id: 'organization.name.required',
    defaultMessage: 'Please enter an organization name',
  },
  description: {
    id: 'organization.name.description',
    defaultMessage: 'The name of the Organization',
  },
  length: {
    id: 'organization.name.length',
    defaultMessage:
      'The name of the Organization must be at least 2 characters long',
  },
});

export const organizationSlugMessages = defineMessages({
  label: {
    id: 'organization.slug.label',
    defaultMessage: 'Slug',
  },
  placeholder: {
    id: 'organization.slug.placeholder',
    defaultMessage: 'Slug',
  },
  matches: {
    id: 'organization.slug.matches',
    defaultMessage: 'Invalid slug',
  },
  description: {
    id: 'organization.slug.description',
    defaultMessage:
      'An organization identifier formatted for display in the URL. Slugs must only include letters, numbers and "-". They must also begin and end with a letter or number e.g: my-org',
  },
});

export const specialTypeMessages = defineMessages({
  label: {
    id: 'specialType.label',
    defaultMessage: 'Special Org Type*',
  },
  placeholder: {
    id: 'specialType.placeholder',
    defaultMessage: 'Special Org Type',
  },
  required: {
    id: 'specialType.required',
    defaultMessage: 'Please enter a special organization type',
  },
  description: {
    id: 'specialType.description',
    defaultMessage: 'Whether the Organization is an internal or testing org\n',
  },
  none: {
    id: 'specialType.none',
    defaultMessage: 'NONE',
  },
  test: {
    id: 'specialType.test',
    defaultMessage: 'TEST',
  },
  demo: {
    id: 'specialType.demo',
    defaultMessage: 'DEMO',
  },
  internal: {
    id: 'specialType.internal',
    defaultMessage: 'INTERNAL',
  },
});

export const dlDataModeOverrideMessages = defineMessages({
  label: {
    id: 'dlDataModeOverride.label',
    defaultMessage: 'Dl Data Mode Override',
  },
  placeholder: {
    id: 'dlDataModeOverride.placeholder',
    defaultMessage: 'Dl Data Mode Override',
  },
  noOverride: {
    id: 'dlDataModeOverride.noOverride',
    defaultMessage: 'no override',
  },
  include: {
    id: 'dlDataModeOverride.include',
    defaultMessage: 'INCLUDE',
  },
  partial: {
    id: 'dlDataModeOverride.partial',
    defaultMessage: 'PARTIAL',
  },
  exclude: {
    id: 'dlDataModeOverride.exclude',
    defaultMessage: 'EXCLUDE',
  },
});

export const adminEmailMessages = defineMessages({
  label: {
    id: 'organization.email.label',
    defaultMessage: 'Admin Email*',
  },
  placeholder: {
    id: 'organization.email.placeholder',
    defaultMessage: 'Admin Email',
  },
  description: {
    id: 'organization.email.description',
    defaultMessage: "The new organization administrator's email",
  },
  required: {
    id: 'organization.email.required',
    defaultMessage: "Please enter administrator's email",
  },
  validation: {
    id: 'organization.email.validation',
    defaultMessage: 'Admin email must be a valid email',
  },
});

export const countryMessages = defineMessages({
  label: {
    id: 'country.label',
    defaultMessage: 'Country*',
  },
  placeholder: {
    id: 'country.placeholder',
    defaultMessage: 'Country',
  },
  required: {
    id: 'country.required',
    defaultMessage: 'Please set a country',
  },
  description: {
    id: 'country.description',
    defaultMessage:
      'CAUTION: Changing this value can have serious implications for the organization’s data storage.',
  },
});

export const organizationIdMessages = defineMessages({
  label: {
    id: 'organizationId.label',
    defaultMessage: 'Organization ID',
  },
  placeholder: {
    id: 'organizationId.placeholder',
    defaultMessage: 'Organization ID',
  },
});

export const subscriptionIdMessages = defineMessages({
  label: {
    id: 'subscriptionId.label',
    defaultMessage: 'Subscription ID*',
  },
  placeholder: {
    id: 'subscriptionId.placeholder',
    defaultMessage: 'Subscription ID',
  },
  required: {
    id: 'subscriptionId.required',
    defaultMessage: 'Please enter a subscription id',
  },
});

export const domainMessages = defineMessages({
  label: {
    id: 'domain.label',
    defaultMessage: 'Domain Name*',
  },
  placeholder: {
    id: 'domain.placeholder',
    defaultMessage: 'Domain Name',
  },
  required: {
    id: 'domain.required',
    defaultMessage: 'Please enter domain name',
  },
});

export const enterpriseCapabilityMessages = defineMessages({
  enabledPresetsLegend: {
    id: 'enterpriseCapability.enabledPresetsLegend',
    defaultMessage: 'Enabled Presets',
  },
  enabledToolsLegend: {
    id: 'enterpriseCapability.enabledToolsLegend',
    defaultMessage: 'Enabled Tools',
  },
  enabledCaptureModesLegend: {
    id: 'enterpriseCapability.enabledCaptureModesLegend',
    defaultMessage: 'Enabled Capture Modes',
  },
  enabledCapabilities: {
    id: 'enterpriseCapability.enabledCapabilities',
    defaultMessage: 'Enabled Capabilities',
  },
  presetsIsRequired: {
    id: 'enterpriseCapability.presets.isRequired',
    defaultMessage: 'Enabled Presets field must have at least 1 item',
  },
  ophthalmicCannotBeSinge: {
    id: 'enterpriseCapability.presets.ophthalmicCannotBeSinge',
    defaultMessage: 'Ophthalmic cannot be the only preset selected',
  },
  BModeMustBeSelected: {
    id: 'enterpriseCapability.presets.bModeMustBeSelected',
    defaultMessage: 'B Mode must be selected',
  },
});

export const domainNameMessages = defineMessages({
  label: {
    id: 'createDomain.domainName.label',
    defaultMessage: 'Domain Name*',
  },
  placeholder: {
    id: 'createDomain.domainName.placeholder',
    defaultMessage: 'Domain Name',
  },
  description: {
    id: 'createDomain.domainName.description',
    defaultMessage: 'Human friendly name for the domain',
  },
  required: {
    id: 'createDomain.domainName.required',
    defaultMessage: 'Please enter a domain name',
  },
  length: {
    id: 'createDomain.domainName.length',
    defaultMessage: 'Domain name must be at least 2 characters long',
  },
});

export const subdomainLabelMessages = defineMessages({
  label: {
    id: 'createDomain.subdomainLabel.label',
    defaultMessage: 'Subdomain Label*',
  },
  placeholder: {
    id: 'createDomain.subdomainLabel.placeholder',
    defaultMessage: 'Subdomain Label',
  },
  description: {
    id: 'createDomain.subdomainLabel.description',
    defaultMessage:
      'Users will need to navigate to <\nsubdomainLabel\n>.butterflynetwork.com, instead of cloud.',
  },
  required: {
    id: 'createDomain.subdomainLabel.required',
    defaultMessage: 'Please enter a subdomain label',
  },
  length: {
    id: 'createDomain.subdomainLabel.length',
    defaultMessage: 'Subdomain Label name must be at least 2 characters long',
  },
});

export const planTypeMessages = defineMessages({
  label: {
    id: 'planType.label',
    defaultMessage: 'Plan Type*',
  },
  placeholder: {
    id: 'planType.placeholder',
    defaultMessage: 'Plan Type',
  },
  description: {
    id: 'planType.description',
    defaultMessage: 'Cloud Subscription plan type',
  },
  required: {
    id: 'planType.required',
    defaultMessage: 'Please choose a plan type',
  },
  none: {
    id: 'planType.none',
    defaultMessage: 'NONE',
  },
  basic: {
    id: 'planType.basic',
    defaultMessage: 'BASIC',
  },
  proTrial: {
    id: 'planType.proTrial',
    defaultMessage: 'PRO_TRIAL',
  },
  proCustom: {
    id: 'planType.proCustom',
    defaultMessage: 'PRO_CUSTOM',
  },
  individual: {
    id: 'planType.individual',
    defaultMessage: 'INDIVIDUAL',
  },
  individualForever: {
    id: 'planType.individualForever',
    defaultMessage: 'INDIVIDUAL_FOREVER',
  },
  team: {
    id: 'planType.team',
    defaultMessage: 'TEAM',
  },
  education: {
    id: 'planType.education',
    defaultMessage: 'EDUCATION',
  },
  proThreeYear: {
    id: 'planType.proThreeYear',
    defaultMessage: 'PRO_THREE_YEAR',
  },
  enterpriseSecurity: {
    id: 'planType.enterpriseSecurity',
    defaultMessage: 'ENTERPRISE_SECURITY',
  },
  enterpriseWorkflow: {
    id: 'planType.enterpriseWorkflow',
    defaultMessage: 'ENTERPRISE_WORKFLOW',
  },
  government: {
    id: 'planType.government',
    defaultMessage: 'GOVERNMENT',
  },
  ems: {
    id: 'planType.ems',
    defaultMessage: 'EMS',
  },
  clinic: {
    id: 'planType.clinic',
    defaultMessage: 'CLINIC',
  },
  residencyProgram: {
    id: 'planType.residencyProgram',
    defaultMessage: 'RESIDENCY_PROGRAM',
  },
  department: {
    id: 'planType.department',
    defaultMessage: 'DEPARTMENT',
  },
  hospital: {
    id: 'planType.hospital',
    defaultMessage: 'HOSPITAL',
  },
  medicalSchool: {
    id: 'planType.medicalSchool',
    defaultMessage: 'MEDICAL_SCHOOL',
  },
});

export const maxNumSeatsMessages = defineMessages({
  label: {
    id: 'maxNumSeatsMessages.label',
    defaultMessage: 'Max number of seats*',
  },
  placeholder: {
    id: 'maxNumSeatsMessages.placeholder',
    defaultMessage: 'Max number of seats',
  },
  description: {
    id: 'maxNumSeatsMessages.description',
    defaultMessage: 'Available active memberships + extended memberships',
  },
  required: {
    id: 'maxNumSeatsMessages.required',
    defaultMessage: 'Please set max number of seats ',
  },
});

export const subscriptionEndsAtMessages = defineMessages({
  label: {
    id: 'subscriptionEndsAt.label',
    defaultMessage: 'Subscription End Date*',
  },
  placeholder: {
    id: 'subscriptionEndsAt.placeholder',
    defaultMessage: 'Subscription End Date',
  },
  description: {
    id: 'subscriptionModal.endsAt.description',
    defaultMessage:
      'The Subscription End Date is not used in "INDIVIDUAL_FOREVER" plan type',
  },
  required: {
    id: 'subscriptionEndsAt.required',
    defaultMessage: 'Please select subscription end date',
  },
});

export const billingStatusMessages = defineMessages({
  label: {
    id: 'billingStatus.label',
    defaultMessage: 'Billing Status',
  },
  placeholder: {
    id: 'billingStatus.placeholder',
    defaultMessage: 'Billing Status',
  },
  description: {
    id: 'billingStatus.description',
    defaultMessage:
      'Determines messaging on the account. Subscriptions that are PAST_DUE, CANCELED, or UNPAID may see notifications that their subscriptions require renewal',
  },
  requiredLabel: {
    id: 'billingStatus.requiredLabel',
    defaultMessage: 'Billing Status*',
  },
  required: {
    id: 'billingStatus.required',
    defaultMessage: 'Please set a billing status',
  },
  trialing: {
    id: 'billingStatus.trialing',
    defaultMessage: 'TRIALING',
  },
  active: {
    id: 'billingStatus.active',
    defaultMessage: 'ACTIVE',
  },
  pastDue: {
    id: 'billingStatus.pastDue',
    defaultMessage: 'PAST_DUE',
  },
  canceled: {
    id: 'billingStatus.canceled',
    defaultMessage: 'CANCELED',
  },
  unpaid: {
    id: 'billingStatus.unpaid',
    defaultMessage: 'UNPAID',
  },
});

export const billingProviderMessages = defineMessages({
  label: {
    id: 'billingProvider.label',
    defaultMessage: 'Billing Provider',
  },
  placeholder: {
    id: 'billingProvider.placeholder',
    defaultMessage: 'Billing Provider',
  },
  description: {
    id: 'billingProvider.description',
    defaultMessage:
      'The external provider considered to be the billing source of truth',
  },
  requiredLabel: {
    id: 'billingProvider.requiredLabel',
    defaultMessage: 'Billing Provider*',
  },
  required: {
    id: 'billingProvider.required',
    defaultMessage: 'Please set a billing provider',
  },
  stripe: {
    id: 'billingStatus.stripe',
    defaultMessage: 'STRIPE',
  },
  apple: {
    id: 'billingStatus.apple',
    defaultMessage: 'APPLE',
  },
  salesforce: {
    id: 'billingStatus.salesforce',
    defaultMessage: 'SALESFORCE',
  },
});

export const stripeSubscriptionIdMessages = defineMessages({
  label: {
    id: 'stripeSubscriptionId.label',
    defaultMessage: 'Stripe Subscription ID',
  },
  placeholder: {
    id: 'stripeSubscriptionId.placeholder',
    defaultMessage: 'Stripe Subscription ID',
  },
  description: {
    id: 'stripeSubscriptionId.description',
    defaultMessage:
      'The id of the Stripe subscription corresponding to the Cloud Subscription',
  },
});

export const salesforceSubscriptionIdMessages = defineMessages({
  label: {
    id: 'createDomain.salesforceSubscriptionId.label',
    defaultMessage: 'Salesforce Subscription ID',
  },
  placeholder: {
    id: 'createDomain.salesforceSubscriptionId.placeholder',
    defaultMessage: 'Salesforce Subscription ID',
  },
  description: {
    id: 'createDomain.salesforceSubscriptionId.description',
    defaultMessage:
      'The id of the Salesforce subscription corresponding to the Cloud Subscription',
  },
});

export const featureModuleIdsMessages = defineMessages({
  label: {
    id: 'featureModuleIds.label',
    defaultMessage: 'Feature Module IDs',
  },
  placeholder: {
    id: 'featureModuleIds.placeholder',
    defaultMessage: 'Feature Module IDs',
  },
  description: {
    id: 'featureModuleIds.description',
    defaultMessage:
      'List of Feature Module IDs that this subscription correlates to',
  },
  required: {
    id: 'featureModuleIds.required',
    defaultMessage: 'Feature Module IDs field must have at least 1 item',
  },
});

export const customerIdMessages = defineMessages({
  label: {
    id: 'customerId.label',
    defaultMessage: 'Customer ID*',
  },
  placeholder: {
    id: 'customerId.placeholder',
    defaultMessage: 'Customer ID',
  },
  description: {
    id: 'customerId.description',
    defaultMessage:
      'The id of the Cloud Customer corresponding to the entity that purchased the Subscription',
  },
  required: {
    id: 'customerId.required',
    defaultMessage: 'Please enter a cloud customer id',
  },
});

export const domainIdMessages = defineMessages({
  label: {
    id: 'createDomain.domainId.label',
    defaultMessage: 'Domain ID',
  },
  placeholder: {
    id: 'createDomain.domainId.placeholder',
    defaultMessage: 'Domain ID',
  },
});

export const firstOrganizationIdMessages = defineMessages({
  label: {
    id: 'createDomain.firstOrganizationId.label',
    defaultMessage: 'First Organization ID',
  },
  placeholder: {
    id: 'createDomain.firstOrganizationId.placeholder',
    defaultMessage: 'First Organization ID',
  },
});

export const firstOrganizationNameMessages = defineMessages({
  label: {
    id: 'createDomain.firstOrganizationName.label',
    defaultMessage: 'First Organization Name',
  },
  placeholder: {
    id: 'createDomain.firstOrganizationName.placeholder',
    defaultMessage: 'First Organization Name',
  },
  required: {
    id: 'createDomain.firstOrganizationName.required',
    defaultMessage: 'Please enter a first organization name',
  },
});

export const firstAdminIdMessages = defineMessages({
  label: {
    id: 'createDomain.firstAdminId.label',
    defaultMessage: 'First Admin ID*',
  },
  placeholder: {
    id: 'createDomain.firstAdminId.placeholder',
    defaultMessage: 'First Admin ID',
  },
  description: {
    id: 'createDomain.firstAdminId.description',
    defaultMessage:
      'User ID of first domain admin (must already be member of organization)',
  },
  required: {
    id: 'createDomain.firstAdminId.required',
    defaultMessage: 'Please enter a first admin id',
  },
});
