import PlusIcon from '@bfly/icons/Plus';
import Dropdown from '@bfly/ui2/Dropdown';
import FormCheck from '@bfly/ui2/FormCheck';
import maxSize from 'popper-max-size-modifier';
import React from 'react';

interface Props {
  selectedColumns: Record<string, boolean>;
  onColumnChange: (selected: Record<string, boolean>) => void;
  columns: Array<{ key: string; label: React.ReactNode }>;
}

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite' as const,
  requires: ['maxSize'],
  fn({ state }) {
    // The `maxSize` modifier provides this data
    const { height } = state.modifiersData.maxSize;

    // eslint-disable-next-line no-param-reassign
    state.styles.popper = {
      ...state.styles.popper,
      maxHeight: `${Math.max(300, height - 20)}px`,
      overflowY: 'auto',
    };
  },
};

export default function DataGridColumnPicker({
  selectedColumns,
  columns,
  onColumnChange,
}: Props) {
  const selectedColumnsCount =
    Object.values(selectedColumns).filter(Boolean).length;

  return (
    <div className="px-3 pt-3 bg-grey-85">
      <Dropdown>
        <Dropdown.Toggle
          id="study-grid-column-spec"
          variant="text-secondary"
          className="rounded-full p-0 bg-grey-80 w-6 h-6"
        >
          <PlusIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight
          flip={false}
          popperConfig={{
            strategy: 'fixed',
            modifiers: [maxSize, applyMaxSize],
          }}
        >
          {columns.map(({ key, label }) => (
            <FormCheck
              key={key}
              checked={selectedColumns[key]}
              disabled={selectedColumnsCount === 1 && selectedColumns[key]}
              onChange={() => {
                onColumnChange({
                  ...selectedColumns,
                  [key]: !selectedColumns[key],
                });
              }}
              className="flex px-4 py-3 border-b border-solid border-grey-5 mb-0"
            >
              {label}
            </FormCheck>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
