/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UndeleteDomainUserAction_user = {
    readonly id: string;
    readonly name: string | null;
    readonly domainUserDeletedAt: string | null;
    readonly userId: string | null;
    readonly domainId: string | null;
    readonly " $refType": "UndeleteDomainUserAction_user";
};
export type UndeleteDomainUserAction_user$data = UndeleteDomainUserAction_user;
export type UndeleteDomainUserAction_user$key = {
    readonly " $data"?: UndeleteDomainUserAction_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UndeleteDomainUserAction_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UndeleteDomainUserAction_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    }
  ],
  "type": "FlatUser",
  "abstractKey": null
};
(node as any).hash = '411c847a77a291149907025b31c15b70';
export default node;
