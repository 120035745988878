import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import {
  domainUserIdMessages,
  domainUserTypeMessages,
} from '../messages/domainUserCreation';
import { domainUserActions } from '../messages/userDetails';
import { DOMAIN_USER_TYPES } from './UserModal';
import { SetDomainUserTypeActionMutation } from './__generated__/SetDomainUserTypeActionMutation.graphql';
import { SetDomainUserTypeAction_user$key } from './__generated__/SetDomainUserTypeAction_user.graphql';

export const basicDomainUserSchema = object({
  domainUserId: string(),
  type: string()
    .required(domainUserTypeMessages.required)
    .oneOf(DOMAIN_USER_TYPES),
});

const fragment = graphql`
  fragment SetDomainUserTypeAction_user on FlatUser {
    id
    userId
    domainId
    type
  }
`;

const mutation = graphql`
  mutation SetDomainUserTypeActionMutation($input: SetDomainUserTypeInput!) {
    setDomainUserType(input: $input) {
      domainUser {
        domainUserId
        type
      }
    }
  }
`;

interface SetDomainUserTypeActionProps {
  userRef: SetDomainUserTypeAction_user$key;
}

export default function SetDomainUserTypeAction({
  userRef,
}: SetDomainUserTypeActionProps) {
  const domainUser = useFragment(fragment, userRef);
  const { domainId, userId, type } = domainUser;
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <PermissionsGuard resource={Resource.DOMAIN_USER_TYPE} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage {...domainUserActions.setType} />
      </Button>
      <ResourceModal<SetDomainUserTypeActionMutation>
        defaultValue={{ domainUserId: `${domainId}/${userId}`, type }}
        show={showModal}
        onClose={() => setShowModal(false)}
        title={
          <FormattedMessage
            id="setDomainUserType.title"
            defaultMessage="Set Domain User Type"
          />
        }
        submitText={
          <FormattedMessage
            id="setDomainUserType.submitText"
            defaultMessage="Set Domain User Type"
          />
        }
        mutation={mutation}
        updater={(store, { setDomainUserType: response }) => {
          store
            .get(domainUser.id!)!
            .setValue(response!.domainUser!.type, 'type');
        }}
        schema={basicDomainUserSchema}
      >
        <Form.FieldGroup
          disabled
          name="domainUserId"
          placeholder={formatMessage(domainUserIdMessages.placeholder)}
          label={<FormattedMessage {...domainUserIdMessages.label} />}
          data-cy="setDomainUserType-domainUserIdField"
        />
        <Form.FieldGroup
          name="type"
          as={DropdownList}
          data={DOMAIN_USER_TYPES}
          placeholder={domainUserTypeMessages.placeholder}
          label={<FormattedMessage {...domainUserTypeMessages.label} />}
          data-cy="setDomainUserType-typeField"
        />
        {/* make some room for the dropdown */}
        <div css="min-height: 4rem;" />
      </ResourceModal>
    </PermissionsGuard>
  );
}
