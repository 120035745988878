import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import { CreateUserModal } from './CreateUserModal';
import { FlatUsersPage_viewer$key } from './__generated__/FlatUsersPage_viewer.graphql';

const resource = Resource.USERS;

const gridFilterFields: FilterField[] = [
  { title: tableHeadings.userId.defaultMessage, field: 'id' },
  { title: tableHeadings.domainId.defaultMessage, field: 'domainId' },
  { title: tableHeadings.email.defaultMessage, field: 'email' },
  { title: tableHeadings.emailDomain.defaultMessage, field: 'emailDomain' },
  { title: tableHeadings.phoneNumber.defaultMessage, field: 'phoneNumber' },
  { title: tableHeadings.accessRoleId.defaultMessage, field: 'roleId' },
  { title: tableHeadings.name.defaultMessage, field: 'name' },
  {
    title: tableHeadings.userDeleted.defaultMessage,
    field: 'isUserDeleted',
    isBoolean: true,
  },
];

const fragment = graphql`
  fragment FlatUsersPage_viewer on Viewer {
    ...CreateUserModal_viewer
  }
`;

interface FlatUsersPageProps {
  viewer: FlatUsersPage_viewer$key;
}

export default function FlatUsersPage({
  viewer,
  children,
}: PropsWithChildren<FlatUsersPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.users);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage
            id="flatUsersPage.title"
            defaultMessage="Flat Users"
          />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateUserModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage
            id="userPage.actonTitle"
            defaultMessage="Create User"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
