import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import customerTableHeadings from '../messages/customerTableHeadings';
import { CreateCustomerModal } from './CreateCustomerModal';
import { CustomersPage_viewer$key } from './__generated__/CustomersPage_viewer.graphql';

const resource = Resource.CUSTOMERS;

const gridFilterFields: FilterField[] = [
  { title: tableHeadings.customerId.defaultMessage, field: 'id' },
  {
    title: customerTableHeadings.zendeskId.defaultMessage,
    field: 'zendeskId',
  },
  {
    title: customerTableHeadings.netsuiteId.defaultMessage,
    field: 'netsuiteId',
  },
  { title: customerTableHeadings.stripeId.defaultMessage, field: 'stripeId' },
  {
    title: customerTableHeadings.sfAccountId.defaultMessage,
    field: 'sfAccountId',
  },
  { title: tableHeadings.customerName.defaultMessage, field: 'name' },
];

const _ = graphql`
  fragment CustomersPage_customer on Customer {
    id
    name
    customerId
    zendeskId
    netsuiteId
    stripeId
    sfAccountId
  }
`;

const fragment = graphql`
  fragment CustomersPage_viewer on Viewer {
    ...CreateCustomerModal_viewer
  }
`;

interface CustomersPageProps {
  viewer: CustomersPage_viewer$key;
}

export default function CustomersPage({
  viewer,
  children,
}: PropsWithChildren<CustomersPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.customers);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.customers} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateCustomerModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage
            id="customersPage.actonTitle"
            defaultMessage="Create Customer"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
