/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type UpdateUserModal_user = {
    readonly flatUserId: string | null;
    readonly auth0Id: string | null;
    readonly userId: string | null;
    readonly name: string | null;
    readonly userName: string | null;
    readonly domainUserName: string | null;
    readonly email: string | null;
    readonly hasUnacceptedInvite: boolean | null;
    readonly specialtyKey: string | null;
    readonly specialtyFreeform: string | null;
    readonly phoneNumber: string | null;
    readonly placeOfWork: {
        readonly placeOfWorkId: string | null;
        readonly name: string | null;
    } | null;
    readonly placeOfWorkFreeform: string | null;
    readonly setupAt: string | null;
    readonly userCreatedAt: string | null;
    readonly userDeletedAt: string | null;
    readonly lastReviewRequestedAt: string | null;
    readonly lastCloudReviewRequestTriggeredAt: string | null;
    readonly lastImmediateReviewRequestTriggeredAt: string | null;
    readonly isImplicitPublic: boolean | null;
    readonly domainId: string | null;
    readonly type: DomainUserType | null;
    readonly domainUserCreatedAt: string | null;
    readonly domainUserDeletedAt: string | null;
    readonly integrationDisplayNameFirst: string | null;
    readonly integrationDisplayNameMiddle: string | null;
    readonly integrationDisplayNameLast: string | null;
    readonly integrationConfigs: ReadonlyArray<{
        readonly domainUserIntegrationConfigId: string | null;
    } | null> | null;
    readonly hasTableauDashboardAccess: boolean | null;
    readonly isNurse: boolean | null;
    readonly canFinalize: boolean | null;
    readonly canQa: boolean | null;
    readonly customUserPermissionsEnabled: boolean | null;
    readonly " $refType": "UpdateUserModal_user";
};
export type UpdateUserModal_user$data = UpdateUserModal_user;
export type UpdateUserModal_user$key = {
    readonly " $data"?: UpdateUserModal_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateUserModal_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateUserModal_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flatUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auth0Id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": "hasUnacceptedInvite",
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingOrganizationInvite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialtyKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialtyFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlaceOfWork",
      "kind": "LinkedField",
      "name": "placeOfWork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeOfWorkId",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeOfWorkFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastReviewRequestedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCloudReviewRequestTriggeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastImmediateReviewRequestTriggeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isImplicitPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameFirst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameMiddle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationDisplayNameLast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUserIntegrationConfig",
      "kind": "LinkedField",
      "name": "integrationConfigs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainUserIntegrationConfigId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasTableauDashboardAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNurse",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customUserPermissionsEnabled",
      "storageKey": null
    }
  ],
  "type": "FlatUser",
  "abstractKey": null
};
})();
(node as any).hash = '3163d15b29e625ebcb96ed7bf7c9491c';
export default node;
