/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlaceOfWorkStatus = "ACTIVE" | "CLOSED" | "OPENING" | "%future added value";
export type UpdatePlaceOfWorkInput = {
    placeOfWorkId: string;
    name?: string | null | undefined;
    countries: Array<string>;
    status?: PlaceOfWorkStatus | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdatePlaceOfWorkModalMutationVariables = {
    input: UpdatePlaceOfWorkInput;
};
export type UpdatePlaceOfWorkModalMutationResponse = {
    readonly updatePlaceOfWork: {
        readonly placeOfWork: {
            readonly " $fragmentRefs": FragmentRefs<"PlaceOfWorkDetailPage_placeOfWork">;
        } | null;
    } | null;
};
export type UpdatePlaceOfWorkModalMutation = {
    readonly response: UpdatePlaceOfWorkModalMutationResponse;
    readonly variables: UpdatePlaceOfWorkModalMutationVariables;
};



/*
mutation UpdatePlaceOfWorkModalMutation(
  $input: UpdatePlaceOfWorkInput!
) {
  updatePlaceOfWork(input: $input) {
    placeOfWork {
      ...PlaceOfWorkDetailPage_placeOfWork
      id
    }
  }
}

fragment PlaceOfWorkDetailPage_placeOfWork on PlaceOfWork {
  ...UpdatePlaceOfWorkModal_placeOfWork
  id
  placeOfWorkId
  name
  countries
  status
  source
}

fragment UpdatePlaceOfWorkModal_placeOfWork on PlaceOfWork {
  id
  placeOfWorkId
  name
  countries
  status
  source
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePlaceOfWorkModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlaceOfWorkPayload",
        "kind": "LinkedField",
        "name": "updatePlaceOfWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceOfWork",
            "kind": "LinkedField",
            "name": "placeOfWork",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PlaceOfWorkDetailPage_placeOfWork"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePlaceOfWorkModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlaceOfWorkPayload",
        "kind": "LinkedField",
        "name": "updatePlaceOfWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceOfWork",
            "kind": "LinkedField",
            "name": "placeOfWork",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "placeOfWorkId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countries",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af1cd23a4085cba9eeca25fb37ec0bfb",
    "id": null,
    "metadata": {},
    "name": "UpdatePlaceOfWorkModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePlaceOfWorkModalMutation(\n  $input: UpdatePlaceOfWorkInput!\n) {\n  updatePlaceOfWork(input: $input) {\n    placeOfWork {\n      ...PlaceOfWorkDetailPage_placeOfWork\n      id\n    }\n  }\n}\n\nfragment PlaceOfWorkDetailPage_placeOfWork on PlaceOfWork {\n  ...UpdatePlaceOfWorkModal_placeOfWork\n  id\n  placeOfWorkId\n  name\n  countries\n  status\n  source\n}\n\nfragment UpdatePlaceOfWorkModal_placeOfWork on PlaceOfWork {\n  id\n  placeOfWorkId\n  name\n  countries\n  status\n  source\n}\n"
  }
};
})();
(node as any).hash = '2bc621a1f59b67ae68392eb46ab4d159';
export default node;
