import { defineMessages } from 'react-intl';

export const educationVideoMessages = defineMessages({
  label: {
    id: 'educationVideoMessages.educationVideo.label',
    defaultMessage: 'Education Video',
  },
  create: {
    id: 'educationVideoMessages.educationVideo.create',
    defaultMessage: 'Create Education Video',
  },
  update: {
    id: 'educationVideoMessages.educationVideo.update',
    defaultMessage: 'Update Education Video',
  },
});

export const educationCourseMessages = defineMessages({
  update: {
    id: 'educationCourseModal.educationCourse.update',
    defaultMessage: 'Update Education Course',
  },
});

export const educationVideoIdMessages = defineMessages({
  label: {
    id: 'educationVideoModal.educationVideoIdMessages.label',
    defaultMessage: 'Education Video ID*',
  },
  placeholder: {
    id: 'educationVideoModal.educationVideoIdMessages.placeholder',
    defaultMessage: 'Education Video ID',
  },
});

export const videoNameMessages = defineMessages({
  label: {
    id: 'educationVideoModal.videoNameMessages.label',
    defaultMessage: 'Video Name*',
  },
  placeholder: {
    id: 'educationVideoModal.videoNameMessages.placeholder',
    defaultMessage: 'Video Name',
  },
  required: {
    id: 'educationVideoModal.videoNameMessages.required',
    defaultMessage: 'Please enter a valid video name',
  },
});

export const courseNameMessages = defineMessages({
  label: {
    id: 'courseModal.courseNameMessages.label',
    defaultMessage: 'Course Name',
  },
  placeholder: {
    id: 'courseModal.courseNameMessages.placeholder',
    defaultMessage: 'Course Name',
  },
  required: {
    id: 'educationCourseModal.videoNameMessages.required',
    defaultMessage: 'Please enter a valid course name',
  },
});

export const courseDescriptionMessages = defineMessages({
  label: {
    id: 'educationCourseModal.descriptionMessages.label',
    defaultMessage: 'Course Description',
  },
  placeholder: {
    id: 'educationCourseModal.descriptionMessages.placeholder',
    defaultMessage: 'Course Description',
  },
});

export const courseStatusMessages = defineMessages({
  label: {
    id: 'educationCourseModal.courseStatusMessages.label',
    defaultMessage: 'Course Status',
  },
  placeholder: {
    id: 'educationCourseModal.courseStatusMessages.placeholder',
    defaultMessage: 'Course Status',
  },
});

export const descriptionMessages = defineMessages({
  label: {
    id: 'educationVideoModal.descriptionMessages.label',
    defaultMessage: 'Video Description*',
  },
  placeholder: {
    id: 'educationVideoModal.descriptionMessages.placeholder',
    defaultMessage: 'Video Description',
  },
  required: {
    id: 'educationVideoModal.descriptionMessages.required',
    defaultMessage: 'Please enter a valid video description',
  },
});

export const absorbIdMessages = defineMessages({
  label: {
    id: 'educationCourseModal.absorbIdMessages.label',
    defaultMessage: 'Absorb ID*',
  },
  placeholder: {
    id: 'educationCourseModal.absorbIdMessages.placeholder',
    defaultMessage: 'Absorb ID',
  },
  required: {
    id: 'educationCourseModal.absorbIdMessages.required',
    defaultMessage: 'Please enter a valid Absorb ID',
  },
  description: {
    id: 'educationVideoModal.absorbIdMessages.description',
    defaultMessage: 'Identifier for the Absorb course',
  },
});

export const vimeoIdMessages = defineMessages({
  label: {
    id: 'educationVideoModal.vimeoIdMessages.label',
    defaultMessage: 'Vimeo ID*',
  },
  placeholder: {
    id: 'educationVideoModal.vimeoIdMessages.placeholder',
    defaultMessage: 'Vimeo ID',
  },
  required: {
    id: 'educationVideoModal.vimeoIdMessages.required',
    defaultMessage: 'Please enter a valid Vimeo video ID',
  },
  description: {
    id: 'educationVideoModal.vimeoIdMessages.description',
    defaultMessage: 'Identifier for the Vimeo video',
  },
});

export const authoredByMessages = defineMessages({
  label: {
    id: 'educationVideoModal.authoredByMessages.label',
    defaultMessage: "Author's user ID*",
  },
  placeholder: {
    id: 'educationVideoModal.authoredByMessages.placeholder',
    defaultMessage: "Author's user ID",
  },
  required: {
    id: 'educationVideoModal.authoredByMessages.required',
    defaultMessage: 'Please enter a valid Author ID',
  },
});

export const categoryIdMessages = defineMessages({
  label: {
    id: 'educationVideoModal.categoryIdMessages.label',
    defaultMessage: 'Video Category ID*',
  },
  placeholder: {
    id: 'educationVideoModal.categoryIdMessages.placeholder',
    defaultMessage: 'Video Category ID',
  },
  required: {
    id: 'educationVideoModal.categoryIdMessages.required',
    defaultMessage: 'Please enter a valid Category ID',
  },
});

export const courseCategoryIdMessages = defineMessages({
  label: {
    id: 'educationCourseModal.categoryIdMessages.label',
    defaultMessage: 'Course Category ID*',
  },
  placeholder: {
    id: 'educationCourseModal.categoryIdMessages.placeholder',
    defaultMessage: 'Course Category ID',
  },
  required: {
    id: 'educationCourseModal.categoryIdMessages.required',
    defaultMessage: 'Please enter a valid Category ID',
  },
});

export const isPremiumMessages = defineMessages({
  label: {
    id: 'educationVideoModal.isPremiumMessages.label',
    defaultMessage: 'Is the Video premium?',
  },
  placeholder: {
    id: 'educationVideoModal.isPremiumMessages.placeholder',
    defaultMessage: 'Is the Video premium?',
  },
});

export const typeMessages = defineMessages({
  label: {
    id: 'educationVideoModal.typeMessages.label',
    defaultMessage: 'Video Type',
  },
  placeholder: {
    id: 'educationVideoModal.typeMessages.placeholder',
    defaultMessage: 'Video Type',
  },
});

export const presetIdsMessages = defineMessages({
  label: {
    id: 'educationVideoModal.presetIdsMessages.label',
    defaultMessage: 'Preset IDs',
  },
  placeholder: {
    id: 'educationVideoModal.presetIdsMessages.placeholder',
    defaultMessage: 'Preset IDs',
  },
  description: {
    id: 'educationVideoModal.presetIdsMessages.description',
    defaultMessage: 'List of preset IDs (UUID) that this video correlates to',
  },
});

export const toolIdsMessages = defineMessages({
  label: {
    id: 'educationVideoModal.toolIdsMessages.label',
    defaultMessage: 'Tool IDs',
  },
  placeholder: {
    id: 'educationVideoModal.toolIdsMessages.placeholder',
    defaultMessage: 'Tool IDs',
  },
  description: {
    id: 'educationVideoModal.toolIdsMessages.description',
    defaultMessage: 'List of tool IDs (UUID) that this video correlates to',
  },
});

export const captureModesMessages = defineMessages({
  label: {
    id: 'educationVideoModal.captureModesMessages.label',
    defaultMessage: 'Capture Modes',
  },
  placeholder: {
    id: 'educationVideoModal.captureModesMessages.placeholder',
    defaultMessage: 'Capture Modes',
  },
  description: {
    id: 'educationVideoModal.captureModesMessages.description',
    defaultMessage: 'List of modes that this video correlates to',
  },
});

export const durationMessages = defineMessages({
  label: {
    id: 'educationVideoModal.durationMessages.label',
    defaultMessage: 'Duration*',
  },
  placeholder: {
    id: 'educationVideoModal.durationMessages.placeholder',
    defaultMessage: 'Duration',
  },
  description: {
    id: 'educationVideoModal.durationMessages.description',
    defaultMessage: 'Content duration',
  },
  required: {
    id: 'educationCourseModal.durationMessages.required',
    defaultMessage: 'Please enter a valid Duration',
  },
});

export const rankingMessages = defineMessages({
  label: {
    id: 'educationVideoModal.rankingMessages.label',
    defaultMessage: 'Ranking*',
  },
  placeholder: {
    id: 'educationVideoModal.rankingMessages.placeholder',
    defaultMessage: 'Ranking',
  },
  description: {
    id: 'educationVideoModal.rankingMessages.description',
    defaultMessage: 'Content ranking',
  },
  required: {
    id: 'educationCourseModal.rankingMessages.required',
    defaultMessage: 'Please enter a valid Ranking',
  },
});

export const thumbnailMessages = defineMessages({
  uploadThumbnailSuccess: {
    id: 'educationContent.courseThumbnailForm.uploadThumbnailSuccess',
    defaultMessage: 'Thumbnail updated.',
  },
  uploadThumbnailError: {
    id: 'educationContent.courseThumbnailForm.uploadThumbnailError',
    defaultMessage: 'Could not upload thumbnail.',
  },
  dimensionsRestrictionError: {
    id: 'educationContent.courseThumbnailForm.dimensionsRestrictionError',
    defaultMessage: `Your file does not meet the requirements. Upload a {width}px x {height}px .jpg or .png.`,
  },
  uploadInstructions: {
    id: 'educationContent.courseThumbnailForm.uploadInstructions',
    defaultMessage: 'Upload an image to publish a course.',
  },
  imageFormatRequirement: {
    id: 'educationContent.courseThumbnailForm.imageFormatRequirement',
    defaultMessage: '{width}px x {height}px .jpg or .png required',
  },
  selectFileButtonText: {
    id: 'educationContent.courseThumbnailForm.selectFileButtonText',
    defaultMessage: 'Select file',
  },
  replaceButtonText: {
    id: 'educationContent.courseThumbnailForm.replaceButtonText',
    defaultMessage: 'Replace file',
  },
});
