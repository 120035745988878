/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UndeleteFlatUserInput = {
    flatUserId: string;
    clientMutationId?: string | null | undefined;
};
export type UndeleteFlatUserActionMutationVariables = {
    input: UndeleteFlatUserInput;
};
export type UndeleteFlatUserActionMutationResponse = {
    readonly undeleteFlatUser: {
        readonly flatUser: {
            readonly name: string | null;
            readonly flatUserId: string | null;
            readonly userDeletedAt: string | null;
            readonly domainUserDeletedAt: string | null;
        } | null;
    } | null;
};
export type UndeleteFlatUserActionMutation = {
    readonly response: UndeleteFlatUserActionMutationResponse;
    readonly variables: UndeleteFlatUserActionMutationVariables;
};



/*
mutation UndeleteFlatUserActionMutation(
  $input: UndeleteFlatUserInput!
) {
  undeleteFlatUser(input: $input) {
    flatUser {
      name
      flatUserId
      userDeletedAt
      domainUserDeletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatUserId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userDeletedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainUserDeletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UndeleteFlatUserActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UndeleteFlatUserPayload",
        "kind": "LinkedField",
        "name": "undeleteFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUser",
            "kind": "LinkedField",
            "name": "flatUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UndeleteFlatUserActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UndeleteFlatUserPayload",
        "kind": "LinkedField",
        "name": "undeleteFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUser",
            "kind": "LinkedField",
            "name": "flatUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0fd45c669fa4f88beb28e87bea2bd1ff",
    "id": null,
    "metadata": {},
    "name": "UndeleteFlatUserActionMutation",
    "operationKind": "mutation",
    "text": "mutation UndeleteFlatUserActionMutation(\n  $input: UndeleteFlatUserInput!\n) {\n  undeleteFlatUser(input: $input) {\n    flatUser {\n      name\n      flatUserId\n      userDeletedAt\n      domainUserDeletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '71c4468fc7de67ea4f5b34d54856ac56';
export default node;
