/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EducationContentCourseLanguage = "DA" | "DE" | "EN" | "ES" | "FI" | "FR" | "IT" | "NL" | "NO" | "PL" | "PT" | "SV" | "%future added value";
export type EducationContentCourseStatus = "ACTIVE" | "INACTIVE" | "PENDING" | "%future added value";
export type EducationContentPracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type EducationContentVideoType = "ACQUISITION" | "INTERPRETATION" | "%future added value";
export type UpdateEducationCourseModal_educationContent = {
    readonly id: string;
    readonly educationContentId: string | null;
    readonly absorbId: string | null;
    readonly name: string | null;
    readonly description: string | null;
    readonly type: EducationContentVideoType | null;
    readonly practiceType: EducationContentPracticeType | null;
    readonly isPremium: boolean | null;
    readonly vimeoId: string | null;
    readonly status: EducationContentCourseStatus | null;
    readonly presetLocalIds: ReadonlyArray<string | null> | null;
    readonly toolLocalIds: ReadonlyArray<string | null> | null;
    readonly captureModes: ReadonlyArray<string | null> | null;
    readonly authoredBy: {
        readonly userId: string | null;
    } | null;
    readonly category: {
        readonly educationCategoryId: string | null;
    } | null;
    readonly ranking: number | null;
    readonly duration: number | null;
    readonly courseLanguage: EducationContentCourseLanguage | null;
    readonly " $refType": "UpdateEducationCourseModal_educationContent";
};
export type UpdateEducationCourseModal_educationContent$data = UpdateEducationCourseModal_educationContent;
export type UpdateEducationCourseModal_educationContent$key = {
    readonly " $data"?: UpdateEducationCourseModal_educationContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateEducationCourseModal_educationContent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateEducationCourseModal_educationContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationContentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "absorbId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPremium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vimeoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presetLocalIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toolLocalIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "captureModes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "authoredBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "educationCategoryId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ranking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "courseLanguage",
      "storageKey": null
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
(node as any).hash = 'b282b909fbdf7a95878250446663a17c';
export default node;
