import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import { RouteObject, RouteProps } from 'found';
import HttpError from 'found/HttpError';
import BaseRoute from 'found/Route'; // eslint-disable-line no-restricted-imports
import React from 'react';

function defaultGetFetchPolicy({ location }) {
  return location.action === 'POP' ? 'store-or-network' : 'store-and-network';
}

type Props = (RouteObject | RouteProps) & {
  renderError?: (error) => React.ReactNode;
};

export function createRender({
  prerender,
  render,
  renderFetched,
  renderError,
}: Props) {
  return (renderArgs) => {
    const { resolving, Component, props, error } = renderArgs;

    if (error) {
      if (error instanceof HttpError) {
        throw error;
      }
      if (renderError) {
        return <div data-cy="route-errorBoundary">{renderError(error)}</div>;
      }
      throw new HttpError(error?.status ?? 500, error.message);
    }

    if (prerender && resolving) {
      prerender(renderArgs);
    }

    if (render) {
      return render(renderArgs);
    }

    if (props && renderFetched) {
      return renderFetched(renderArgs);
    }

    // An undefined component means the route has no defined component, in
    //  which case we want to ignore the route for rendering to match upstream.
    if (Component === undefined) {
      return null;
    }

    // A null component or props means that the route is not done loading,
    //  unless someone has done something weird and explicitly set Component
    //  to null.
    if (!Component || !props) {
      return <LoadingIndicator />;
    }

    return <Component {...props} />;
  };
}

export default class Route extends BaseRoute {
  constructor(props) {
    if (!(props.query || props.getQuery)) {
      super(props);
      return;
    }

    super({
      getFetchPolicy: defaultGetFetchPolicy,
      ...props,
      render: createRender(props),
    });
  }
}
