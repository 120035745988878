/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ShowInviteLinkAction_Link_QueryVariables = {
    organizationInviteId: string;
};
export type ShowInviteLinkAction_Link_QueryResponse = {
    readonly invite: {
        readonly linkUrl: string | null;
    } | null;
};
export type ShowInviteLinkAction_Link_Query = {
    readonly response: ShowInviteLinkAction_Link_QueryResponse;
    readonly variables: ShowInviteLinkAction_Link_QueryVariables;
};



/*
query ShowInviteLinkAction_Link_Query(
  $organizationInviteId: String!
) {
  invite: organizationInvite(organizationInviteId: $organizationInviteId) {
    linkUrl
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationInviteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationInviteId",
    "variableName": "organizationInviteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShowInviteLinkAction_Link_Query",
    "selections": [
      {
        "alias": "invite",
        "args": (v1/*: any*/),
        "concreteType": "OrganizationInvite",
        "kind": "LinkedField",
        "name": "organizationInvite",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShowInviteLinkAction_Link_Query",
    "selections": [
      {
        "alias": "invite",
        "args": (v1/*: any*/),
        "concreteType": "OrganizationInvite",
        "kind": "LinkedField",
        "name": "organizationInvite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e900811972a5ac36e427e1d35fdd6f5",
    "id": null,
    "metadata": {},
    "name": "ShowInviteLinkAction_Link_Query",
    "operationKind": "query",
    "text": "query ShowInviteLinkAction_Link_Query(\n  $organizationInviteId: String!\n) {\n  invite: organizationInvite(organizationInviteId: $organizationInviteId) {\n    linkUrl\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '3b3e001b7c6fbba9bda5a81e2d05b8cd';
export default node;
