import { defineMessages } from 'react-intl';

export default defineMessages({
  domainUserIntegrationConfigId: {
    id: 'domainUserIntegrationConfigTable.heading.domainUserIntegrationsConfigId',
    defaultMessage: 'Domain User Integrations Config ID',
  },
  interfaceCode: {
    id: 'domainUserIntegrationConfigTable.heading.interfaceCode',
    defaultMessage: 'Interface Code',
  },
  userEmail: {
    id: 'domainUserIntegrationConfigTable.heading.userEmail',
    defaultMessage: 'User Email',
  },
});
