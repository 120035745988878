import Layout from '@4c/layout';
import FormCheck from '@bfly/ui2/FormCheck';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface DangerousActionConfirmationProps {
  confirmed: boolean;
  setConfirmed: (value: boolean) => void;
}

export default function DangerousActionConfirmation({
  confirmed,
  setConfirmed,
}: DangerousActionConfirmationProps) {
  return (
    <Layout className="mt-6 justify-content-end">
      <FormCheck
        checked={confirmed}
        onChange={(event) =>
          setConfirmed((event.target as HTMLInputElement).checked)
        }
        data-cy="dangerousActionConfirmation-check"
      >
        <FormattedMessage
          id="dangerousActionConfirmation.content"
          defaultMessage="I understand the risks and want to continue"
        />
      </FormCheck>
    </Layout>
  );
}
