import Form from '@bfly/ui2/Form';
import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import { ActionModalProps, ResourceModal } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { productionIdMessages } from '../messages/butterflyDeviceCreation';
import { CreateButterflyDeviceModalMutation } from './__generated__/CreateButterflyDeviceModalMutation.graphql';
import { CreateButterflyDeviceModal_viewer$key } from './__generated__/CreateButterflyDeviceModal_viewer.graphql';

const createSchema = object({
  productionId: string().required(productionIdMessages.required),
});

const createMutation = graphql`
  mutation CreateButterflyDeviceModalMutation(
    $input: CreateButterflyDeviceInput!
  ) {
    createButterflyDevice(input: $input) {
      butterflyDeviceEdge {
        node {
          id
          ...ButterflyDevicesGrid_butterflyDevice
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateButterflyDeviceModal_viewer on Viewer {
    id
  }
`;

interface CreateButterflyDeviceModalProps extends ActionModalProps {
  viewerRef: CreateButterflyDeviceModal_viewer$key;
}

export default function CreateButterflyDeviceModal({
  viewerRef,
  ...props
}: CreateButterflyDeviceModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'ButterflyDevicesGrid_butterflyDevices',
        rootFieldName: 'createButterflyDevice',
        edgeName: 'butterflyDeviceEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );
  const { formatMessage } = useIntl();

  return (
    <PermissionsGuard resource={Resource.BUTTERFLY_DEVICES} write hideContent>
      <ResourceModal<CreateButterflyDeviceModalMutation>
        {...props}
        schema={createSchema}
        mutation={createMutation}
        updater={updater}
        title={
          <FormattedMessage
            id="createButterflyDevice.title"
            defaultMessage="Create Butterfly Device"
          />
        }
        submitText={<FormattedMessage {...actionMessages.create} />}
      >
        <Form.FieldGroup
          name="productionId"
          label={<FormattedMessage {...productionIdMessages.label} />}
          placeholder={formatMessage(productionIdMessages.placeholder)}
          data-cy="createButterflyDevice-productionIdField"
        />
      </ResourceModal>
    </PermissionsGuard>
  );
}
