import { Query } from 'farce';
import React from 'react';
import { MessageDescriptor } from 'react-intl';

export interface Breadcrumb {
  key: string;
  pathname: string;
  query: Query;
  title: MessageDescriptor;
  subtitle?: string | null;
}

export interface BreadcrumbGroup {
  key: string;
  pathname: string;
  breadcrumbs: Breadcrumb[];
}

export interface BreadcrumbsContextValues {
  breadcrumbGroups: BreadcrumbGroup[];
  addBreadcrumb: (
    pathname: string,
    query: Query,
    title: MessageDescriptor,
    subtitle?: string | null,
  ) => void;
  hasBreadcrumb: (key: string) => boolean;
  clearBreadcrumbs: () => void;
  deleteBreadcrumb: (id: string) => void;
}

const BreadcrumbsContext =
  React.createContext<BreadcrumbsContextValues | null>(null);

export default BreadcrumbsContext;
