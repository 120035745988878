import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import ResourceGridAction from 'components/ResourceGridAction';
import ResourceGridData from 'components/ResourceGridData';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import iosVersionBlacklistEntriesTableHeadings from '../messages/iosVersionBlacklistEntriesTableHeadings';
import { CreateIosVersionBlacklistEntryModal } from './CreateIosVersionBlacklistEntryModal';
import { IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry as IosVersionBlacklistEntry } from './__generated__/IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry.graphql';
import { IosVersionBlacklistEntriesPage_viewer$key } from './__generated__/IosVersionBlacklistEntriesPage_viewer.graphql';

const resource = Resource.IOS_VERSION_BLACKLIST_ENTRIES;

const _ = graphql`
  fragment IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
    id
    iosVersionBlacklistEntryId
    applicationIdentifier
    versionRange
    buildNumber
  }
`;

const fragment = graphql`
  fragment IosVersionBlacklistEntriesPage_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "IosVersionBlacklistEntriesPageRefetchQuery") {
    ...CreateIosVersionBlacklistEntryModal_viewer
    iosVersionBlacklistEntries(first: $count, after: $cursor)
      @connection(
        key: "IosVersionBlacklistEntriesPage_iosVersionBlacklistEntries"
      ) {
      edges {
        node {
          ...IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry
            @relay(mask: false)
        }
      }
    }
  }
`;

export const tableSpec: ColumnSpec<IosVersionBlacklistEntry>[] = [
  {
    key: 'iosVersionBlacklistEntryId',
    label: (
      <FormattedMessage
        {...iosVersionBlacklistEntriesTableHeadings.iosVersionBlacklistEntryId}
      />
    ),
    frozen: true,
    render: ({ item }) => (
      <QuickCopyText text={item.iosVersionBlacklistEntryId}>
        <DataGridLink
          path="/ios-version-blacklist-entries"
          id={item.iosVersionBlacklistEntryId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'applicationIdentifier',
    label: <FormattedMessage {...tableHeadings.applicationIdentifier} />,
    render: ({ item }) => (
      <QuickCopyText text={item.applicationIdentifier}>
        <DataGridText value={item.applicationIdentifier} />
      </QuickCopyText>
    ),
  },
  {
    key: 'versionRange',
    label: <FormattedMessage {...tableHeadings.versionRange} />,
    render: ({ item }) => <DataGridText value={item.versionRange} />,
  },
  {
    key: 'buildNumber',
    label: (
      <FormattedMessage
        {...iosVersionBlacklistEntriesTableHeadings.buildNumber}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.buildNumber}>
        <DataGridText value={item.buildNumber} />
      </QuickCopyText>
    ),
  },
];

interface IosVersionBlacklistEntriesPage {
  viewer: IosVersionBlacklistEntriesPage_viewer$key;
}

export default function IosVersionBlacklistEntriesPage({
  viewer,
}: IosVersionBlacklistEntriesPage) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.iosVersionBlacklistEntries);
  useBreadcrumbDetector(pageTitles.iosVersionBlacklistEntries);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader className="p-2">
        <Header.Title>
          <FormattedMessage {...pageTitles.iosVersionBlacklistEntries} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateIosVersionBlacklistEntryModal {...props} viewerRef={data} />
          )}
        >
          <FormattedMessage
            id="iosVersionBlacklistEntriesPage.actonTitle"
            defaultMessage="Create iOS Version"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridData
        data={nodes}
        loadNext={loadNext}
        hasNext={hasNext}
        spec={tableSpec}
      />
    </PermissionsGuard>
  );
}
