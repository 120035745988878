import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { MembershipModal, membershipSchema } from './MembershipModal';
import {
  UpdateMembershipModalMutation,
  UpdateOrganizationMembershipInput,
} from './__generated__/UpdateMembershipModalMutation.graphql';
import {
  UpdateMembershipModal_membership as Membership,
  UpdateMembershipModal_membership$key,
} from './__generated__/UpdateMembershipModal_membership.graphql';

const fragment = graphql`
  fragment UpdateMembershipModal_membership on OrganizationMembership {
    id
    type
    organizationMembershipId
    organization {
      organizationId
      customUserPermissionsEnabled
    }
    user {
      userId
      name
    }
    isNurse
    canFinalize
    canQa
  }
`;

const updateMutation = graphql`
  mutation UpdateMembershipModalMutation(
    $input: UpdateOrganizationMembershipInput!
  ) {
    updateOrganizationMembership(input: $input) {
      organizationMembership {
        ...MembershipsGrid_membership
      }
    }
  }
`;

const transformOnSubmit = (
  value: Membership,
): UpdateOrganizationMembershipInput => ({
  type: value.type,
  organizationMembershipId: value.organizationMembershipId!,
  isNurse: !!value.isNurse,
  canFinalize: !!value.canFinalize,
  canQa: !!value.canQa,
});

interface UpdateMembershipModalProps extends ActionModalProps {
  membershipRef: UpdateMembershipModal_membership$key;
  isDomainWithCustomPermissions: boolean;
}

export function UpdateMembershipModal({
  show,
  onClose,
  membershipRef,
  isDomainWithCustomPermissions,
}: UpdateMembershipModalProps) {
  const membership = useFragment(fragment, membershipRef);
  const transformedDefaultValue = useMemo(
    () => ({
      organizationId: membership.organization!.organizationId,
      userId: membership.user!.userId,
      ...membership,
    }),
    [membership],
  );
  return (
    <MembershipModal<UpdateMembershipModalMutation>
      transformOnSubmit={transformOnSubmit}
      title={
        <FormattedMessage
          id="updateMembershipModal.title"
          defaultMessage="Update Membership"
        />
      }
      name={transformedDefaultValue.user!.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      schema={membershipSchema}
      mutation={updateMutation}
      defaultValue={transformedDefaultValue}
      isDomainWithCustomPermissions={isDomainWithCustomPermissions}
      orgCustomUserPermissionsEnabled={
        membership.organization?.customUserPermissionsEnabled
      }
    />
  );
}
