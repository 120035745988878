import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import SystemDefinedRoleDetailPage from './components/SystemDefinedRoleDetailPage';
import SystemDefinedButterflyAccessRolesPage from './components/SystemDefinedRolesPage';

export default (
  <Route path="system-defined">
    <Route
      Component={SystemDefinedButterflyAccessRolesPage}
      query={graphql`
        query systemDefinedRolesPage_systemDefinedRoles_Query(
          $name: [String!]
        ) {
          viewer {
            ...SystemDefinedRolesPage_viewer @arguments(name: $name)
          }
        }
      `}
      prepareVariables={(_params, { location }) => ({
        name: location.query.name,
      })}
    />
    <Route
      path=":systemDefinedRoleId"
      Component={SystemDefinedRoleDetailPage}
      query={graphql`
        query systemDefinedRoles_SystemDefinedRoleDetailPage_Query(
          $systemDefinedRoleId: String!
        ) {
          role: systemDefinedRole(systemDefinedRoleId: $systemDefinedRoleId) {
            ...SystemDefinedRoleDetailPage_role
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.role) throw new HttpError(404);
      }}
    />
  </Route>
);
