import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import Section from '@bfly/ui2/Section';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import waveImage from '../assets/image/elysium-home.svg';

export default function LandingPage() {
  return (
    <Layout align="center" className="w-full h-full">
      <Header className="sr-only">
        <Header.Title>
          <FormattedMessage
            id="landingPage.title"
            defaultMessage="Landing Page"
          />
        </Header.Title>
      </Header>
      <Section
        as={Layout}
        className="p-10 w-full h-full"
        align="center"
        justify="center"
      >
        <img src={waveImage} width="529" height="437" alt="illustration" />
      </Section>
    </Layout>
  );
}
