import Layout from '@4c/layout';
import Spinner from '@bfly/ui2/Spinner';
import { css } from 'astroturf';
import React from 'react';

export default function LoadingOverlay() {
  return (
    <Layout
      className="fixed h-screen w-full top-0 left-0 z-10"
      align="center"
      justify="center"
    >
      <div
        css={css`
          max-width: 50%;
        `}
      >
        <Spinner size="lg" />
      </div>
    </Layout>
  );
}
