import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  ButterflyDeviceRecallModal,
  butterflyDeviceRecallSchema,
} from './ButterflyDeviceRecallModal';
import { CreateButterflyDeviceRecallModalMutation } from './__generated__/CreateButterflyDeviceRecallModalMutation.graphql';
import { CreateButterflyDeviceRecallModal_viewer$key } from './__generated__/CreateButterflyDeviceRecallModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateButterflyDeviceRecallModalMutation(
    $input: CreateButterflyDeviceRecallInput!
  ) {
    createButterflyDeviceRecall(input: $input) {
      butterflyDeviceRecallEdge {
        node {
          id
          ...ButterflyDeviceRecallDetailPage_butterflyDeviceRecall
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateButterflyDeviceRecallModal_viewer on Viewer {
    id
  }
`;

interface CreateButterflyDeviceRecallModalProps extends ActionModalProps {
  viewerRef: CreateButterflyDeviceRecallModal_viewer$key;
}

export function CreateButterflyDeviceRecallModal({
  viewerRef,
  ...props
}: CreateButterflyDeviceRecallModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'ButterflyDeviceRecallsPage_butterflyDeviceRecalls',
        rootFieldName: 'createButterflyDeviceRecall',
        edgeName: 'butterflyDeviceRecallEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <ButterflyDeviceRecallModal<CreateButterflyDeviceRecallModalMutation>
      {...props}
      schema={butterflyDeviceRecallSchema}
      mutation={createMutation}
      updater={updater}
      title={
        <FormattedMessage
          id="createButterflyDeviceRecallModal.title"
          defaultMessage="Create Butterfly Device Recalls"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
    />
  );
}
