import { defineMessages } from 'react-intl';

export const recallIdMessages = defineMessages({
  label: {
    id: 'butterflyDeviceRecall.recallId.label',
    defaultMessage: 'Recall ID*',
  },
  placeholder: {
    id: 'butterflyDeviceRecall.recallId.placeholder',
    defaultMessage: 'Recall ID',
  },
});

export const messageMessages = defineMessages({
  label: {
    id: 'butterflyDeviceRecall.message.label',
    defaultMessage: 'Message*',
  },
  placeholder: {
    id: 'butterflyDeviceRecall.message.placeholder',
    defaultMessage: 'Message',
  },
  description: {
    id: 'butterflyDeviceRecall.message.description',
    defaultMessage: 'Details about the recall (customer facing)',
  },
  required: {
    id: 'butterflyDeviceRecall.message.required',
    defaultMessage: 'Please enter a Message',
  },
});
