import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  domainIdMessages,
  integrationIdMessages,
  userIdMessages,
} from '../messages/domainUserIntegrationConfigCreation';
import {
  DomainUserIntegrationConfigModal,
  basicDomainUserIntegrationConfigSchema,
} from './DomainUserIntegrationConfigModal';
import { CreateDomainUserIntegrationConfigModalMutation } from './__generated__/CreateDomainUserIntegrationConfigModalMutation.graphql';
import { CreateDomainUserIntegrationConfigModal_viewer$key } from './__generated__/CreateDomainUserIntegrationConfigModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateDomainUserIntegrationConfigModalMutation(
    $input: CreateDomainUserIntegrationConfigInput!
  ) {
    createDomainUserIntegrationConfig(input: $input) {
      domainUserIntegrationConfigEdge {
        node {
          id
          ...DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateDomainUserIntegrationConfigModal_viewer on Viewer {
    id
  }
`;

const createDomainUserIntegrationConfigSchema =
  basicDomainUserIntegrationConfigSchema.shape({
    domainId: string().required(domainIdMessages.required),
    userId: string().required(userIdMessages.required),
    integrationId: string().required(integrationIdMessages.required),
  });

interface CreateDomainUserIntegrationConfigModalProps
  extends ActionModalProps {
  viewerRef: CreateDomainUserIntegrationConfigModal_viewer$key;
}

export function CreateDomainUserIntegrationConfigModal({
  viewerRef,
  ...props
}: CreateDomainUserIntegrationConfigModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey:
          'DomainUserIntegrationConfigsGrid_domainUserIntegrationConfigs',
        rootFieldName: 'createDomainUserIntegrationConfig',
        edgeName: 'domainUserIntegrationConfigEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <DomainUserIntegrationConfigModal<CreateDomainUserIntegrationConfigModalMutation>
      {...props}
      title={
        <FormattedMessage
          id="createDomainUserIntegrationConfigModal.title"
          defaultMessage="Create Domain User Integration Config"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={createDomainUserIntegrationConfigSchema}
      mutation={createMutation}
      updater={updater}
    />
  );
}
