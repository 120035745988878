import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteEducationCategoryAction from './DeleteEducationCategoryAction';
import { UpdateEducationCategoryModal } from './UpdateEducationCategoryModal';
import { EducationCategoryDetailPage_educationCategory$key } from './__generated__/EducationCategoryDetailPage_educationCategory.graphql';

const fragment = graphql`
  fragment EducationCategoryDetailPage_educationCategory on EducationCategory {
    ...UpdateEducationCategoryModal_educationCategory
    ...DeleteEducationCategoryAction_educationCategory
    id
    educationCategoryId
    name
    color
    ordering
  }
`;

interface EducationCategoryDetailPageProps {
  educationCategory: EducationCategoryDetailPage_educationCategory$key;
}

export default function EducationCategoryDetailPage({
  educationCategory: educationCategoryRef,
}: EducationCategoryDetailPageProps) {
  const educationCategory = useFragment(fragment, educationCategoryRef);
  const { educationCategoryId: categoryId, name } = educationCategory;
  useBreadcrumbDetector(pageTitles.educationCategory, name);
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.EDUCATION_CATEGORIES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.educationCategory} />}
        name={educationCategory.name!}
        data={educationCategory}
        quickCopyFields={['educationCategoryId', 'name']}
        relatedItems={
          <RelatedItem
            name="Education Content"
            to={{
              pathname: '/education-content',
              query: { categoryId },
            }}
          >
            <FormattedMessage
              id="educationCategoryDetail.relatedItem.educationVideos"
              defaultMessage="Education Videos"
            />
          </RelatedItem>
        }
        crudActions={
          canWrite(Resource.EDUCATION_CATEGORIES) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateEducationCategoryModal
                    {...props}
                    educationCategoryRef={educationCategory}
                  />
                )}
              />
              <DeleteEducationCategoryAction
                educationCategoryRef={educationCategory}
              />
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
