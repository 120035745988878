/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteInviteAction_organizationInvite = {
    readonly organizationInviteId: string | null;
    readonly deletedAt: string | null;
    readonly " $refType": "DeleteInviteAction_organizationInvite";
};
export type DeleteInviteAction_organizationInvite$data = DeleteInviteAction_organizationInvite;
export type DeleteInviteAction_organizationInvite$key = {
    readonly " $data"?: DeleteInviteAction_organizationInvite$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteInviteAction_organizationInvite">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteInviteAction_organizationInvite",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationInviteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    }
  ],
  "type": "OrganizationInvite",
  "abstractKey": null
};
(node as any).hash = '427e2f216ed62bca83d92ca77c45e05d';
export default node;
