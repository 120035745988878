/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateEducationCategoryModal_viewer = {
    readonly id: string;
    readonly " $refType": "CreateEducationCategoryModal_viewer";
};
export type CreateEducationCategoryModal_viewer$data = CreateEducationCategoryModal_viewer;
export type CreateEducationCategoryModal_viewer$key = {
    readonly " $data"?: CreateEducationCategoryModal_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CreateEducationCategoryModal_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateEducationCategoryModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'f0072e9c95f01ae53f52435f1e683754';
export default node;
