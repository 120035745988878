/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type invites_OrganizationInvitesPage_QueryVariables = {};
export type invites_OrganizationInvitesPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"InvitesPage_viewer">;
    } | null;
};
export type invites_OrganizationInvitesPage_Query = {
    readonly response: invites_OrganizationInvitesPage_QueryResponse;
    readonly variables: invites_OrganizationInvitesPage_QueryVariables;
};



/*
query invites_OrganizationInvitesPage_Query {
  viewer {
    ...InvitesPage_viewer
    id
  }
}

fragment CreateInviteModal_viewer on Viewer {
  id
}

fragment InvitesPage_viewer on Viewer {
  ...CreateInviteModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "invites_OrganizationInvitesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InvitesPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "invites_OrganizationInvitesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26d787323c0f8830fe31e19e0f1184e4",
    "id": null,
    "metadata": {},
    "name": "invites_OrganizationInvitesPage_Query",
    "operationKind": "query",
    "text": "query invites_OrganizationInvitesPage_Query {\n  viewer {\n    ...InvitesPage_viewer\n    id\n  }\n}\n\nfragment CreateInviteModal_viewer on Viewer {\n  id\n}\n\nfragment InvitesPage_viewer on Viewer {\n  ...CreateInviteModal_viewer\n}\n"
  }
};
(node as any).hash = 'ac7022a9ba550c5cddc5df9e4b265eb9';
export default node;
