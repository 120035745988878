/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteMembershipAction_membership = {
    readonly organizationMembershipId: string | null;
    readonly organization: {
        readonly name: string | null;
    } | null;
    readonly " $refType": "DeleteMembershipAction_membership";
};
export type DeleteMembershipAction_membership$data = DeleteMembershipAction_membership;
export type DeleteMembershipAction_membership$key = {
    readonly " $data"?: DeleteMembershipAction_membership$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteMembershipAction_membership">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteMembershipAction_membership",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationMembershipId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
(node as any).hash = 'ee91910bc551595d03dee5be779e5b95';
export default node;
