/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type BillingStatus = "ACTIVE" | "CANCELED" | "PAST_DUE" | "TRIALING" | "UNPAID" | "%future added value";
export type DlDataMode = "EXCLUDE" | "INCLUDE" | "PARTIAL" | "%future added value";
export type PlanType = "BASIC" | "CLINIC" | "DEPARTMENT" | "EDUCATION" | "EMS" | "ENTERPRISE_SECURITY" | "ENTERPRISE_WORKFLOW" | "GOVERNMENT" | "HOSPITAL" | "INDIVIDUAL" | "INDIVIDUAL_FOREVER" | "MEDICAL_SCHOOL" | "NONE" | "PRO_CUSTOM" | "PRO_THREE_YEAR" | "PRO_TRIAL" | "RESIDENCY_PROGRAM" | "TEAM" | "%future added value";
export type PracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type SpecialType = "DEMO" | "INTERNAL" | "NONE" | "TEST" | "%future added value";
export type ProvisionOrganizationInput = {
    name: string;
    slug?: string | null | undefined;
    country: string;
    specialType?: SpecialType | null | undefined;
    dlDataModeOverride?: DlDataMode | null | undefined;
    adminEmail: string;
    planType: PlanType;
    maxNumSeats: number;
    practiceType: PracticeType;
    subscriptionEndsAt?: string | null | undefined;
    billingStatus?: BillingStatus | null | undefined;
    billingProvider?: BillingProvider | null | undefined;
    stripeSubscriptionId?: string | null | undefined;
    sfSubscriptionId?: string | null | undefined;
    customerId: string;
    featureModuleIds?: Array<string | null> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type ProvisionOrganizationActionMutationVariables = {
    input: ProvisionOrganizationInput;
};
export type ProvisionOrganizationActionMutationResponse = {
    readonly provisionOrganization: {
        readonly organization: {
            readonly id: string;
            readonly organizationId: string | null;
        } | null;
    } | null;
};
export type ProvisionOrganizationActionMutation = {
    readonly response: ProvisionOrganizationActionMutationResponse;
    readonly variables: ProvisionOrganizationActionMutationVariables;
};



/*
mutation ProvisionOrganizationActionMutation(
  $input: ProvisionOrganizationInput!
) {
  provisionOrganization(input: $input) {
    organization {
      id
      organizationId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProvisionOrganizationPayload",
    "kind": "LinkedField",
    "name": "provisionOrganization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvisionOrganizationActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProvisionOrganizationActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ca5c4f9cd2c6b488add1a75961563775",
    "id": null,
    "metadata": {},
    "name": "ProvisionOrganizationActionMutation",
    "operationKind": "mutation",
    "text": "mutation ProvisionOrganizationActionMutation(\n  $input: ProvisionOrganizationInput!\n) {\n  provisionOrganization(input: $input) {\n    organization {\n      id\n      organizationId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '933de650996ce3622d832ddfc8bfab6c';
export default node;
