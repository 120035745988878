import type { RecordProxy, RecordSourceSelectorProxy } from 'relay-runtime';
import { ConnectionHandler } from 'relay-runtime';

type CreateConfig<PayloadType> = {
  connectionContainerId: string;
  connectionKey: string;
  getNode: (payload: PayloadType) => { id: string };
};

/*
The "createUpdater" is used due to the old implementation of some mutations.
All mutations should return an "edge" and "node".
Based on that fact the "olympus-admin-graphql" have mutations that return old structure without "edge".
This custom updater was implemented to parse not consistent responses.
The updater duplicates existing realization in Elysium.
If all mutations will be migrated to support "edge" then this custom updater should be removed and used
  `@bfly/utils/rangeAddUpdater`
 */
export default function createUpdater<PayloadType>({
  connectionContainerId,
  connectionKey,
  getNode,
}: CreateConfig<PayloadType>) {
  return (store: RecordSourceSelectorProxy, data: PayloadType) => {
    const nodeId = getNode(data).id;
    const parentProxy = store.get(connectionContainerId) as RecordProxy;
    const connection = ConnectionHandler.getConnection(
      parentProxy,
      connectionKey,
      {},
    );
    if (connection) {
      const node = store.get(nodeId) as RecordProxy;
      const edge = ConnectionHandler.createEdge(
        store,
        connection,
        node,
        `${connectionKey}Edge`,
      );
      ConnectionHandler.insertEdgeBefore(connection, edge);
    }
  };
}
