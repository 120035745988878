import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import ButterflyDeviceRecallDetailPage from './components/ButterflyDeviceRecallDetailPage';
import ButterflyDeviceRecallsPage from './components/ButterflyDeviceRecallsPage';

export default (
  <Route path="butterfly-device-recalls">
    <Route
      Component={ButterflyDeviceRecallsPage}
      query={graphql`
        query butterflyDeviceRecalls_ButterflyDeviceRecallsPage_Query {
          viewer {
            ...ButterflyDeviceRecallsPage_viewer
          }
        }
      `}
    />
    <Route
      path=":recallId"
      Component={ButterflyDeviceRecallDetailPage}
      query={graphql`
        query butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_Query(
          $recallId: String!
        ) {
          butterflyDeviceRecall(recallId: $recallId) {
            ...ButterflyDeviceRecallDetailPage_butterflyDeviceRecall
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.butterflyDeviceRecall) throw new HttpError(404);
      }}
    />
  </Route>
);
