/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainInvitesPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateUserInviteModal_viewer">;
    readonly " $refType": "DomainInvitesPage_viewer";
};
export type DomainInvitesPage_viewer$data = DomainInvitesPage_viewer;
export type DomainInvitesPage_viewer$key = {
    readonly " $data"?: DomainInvitesPage_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DomainInvitesPage_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainInvitesPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateUserInviteModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'df11d4e70d2ef3ef9afe6430b2e2aad6';
export default node;
