/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateDomainUserIntegrationConfigModal_viewer = {
    readonly id: string;
    readonly " $refType": "CreateDomainUserIntegrationConfigModal_viewer";
};
export type CreateDomainUserIntegrationConfigModal_viewer$data = CreateDomainUserIntegrationConfigModal_viewer;
export type CreateDomainUserIntegrationConfigModal_viewer$key = {
    readonly " $data"?: CreateDomainUserIntegrationConfigModal_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CreateDomainUserIntegrationConfigModal_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateDomainUserIntegrationConfigModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '401c4475887cf2a8d1901a005a48b6d1';
export default node;
