import DropdownIcon from '@bfly/icons/Dropdown';
import Dropdown from '@bfly/ui2/Dropdown';
import { useEventListener } from '@restart/hooks';
import styled, { css, stylesheet } from 'astroturf/react';
import clsx from 'clsx';
import React, { useRef } from 'react';

import ScrollView from 'components/ScrollView';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';
  @mixin frozen($z-index: 1) {
    position: sticky;
    left: 0;
    z-index: $z-index;
    border-right: 4px solid $grey-80;

    & + * {
      border-left: none;
    }
  }

  .stuck .frozen {
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  }

  .header {
    background-color: $grey-80;
    color: white;
    position: sticky;
    top: 0;
    z-index: 2; // want headers to be above frozen cells to avoid seeing their drop shadow

    &.frozen {
      @include frozen(3);
    }
    &.empty {
      opacity: 0.7;
      padding: 0;
    }
  }

  .cell {
    color: $grey-25;
    vertical-align: center;
    min-height: 4.6rem;
    background-color: $grey-85;

    &.frozen {
      @include frozen();
    }

    &.empty {
      opacity: 0.5;
      padding: 0;
    }
  }

  .header, .cell {
    display: flex;
    align-items: center;
    padding: 0 spacer(4);
    min-height: #{spacer(3) * 2 + 2rem};
    border-top: 1px solid $grey-80;
    border-left: 1px solid $grey-80;
  }

  .header,
  .content {
    text-align: left;
    white-space: nowrap;

    &.flush-y {
      padding-top: 0;
      padding-bottom: 0;
    }
    &.flush-x{
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

interface Props extends React.ComponentPropsWithoutRef<'table'> {
  className?: string;
  children: React.ReactNode;
  scrollKey: string | null;
}

export default function DataGrid({ children, className, ...props }: Props) {
  const ref = useRef(null);

  useEventListener(
    () => ref.current!,
    'scroll',
    (e) => {
      const table = e.currentTarget! as HTMLElement;

      table.classList.toggle(styles.stuck, !!table.scrollLeft);
    },
  );
  return (
    <ScrollView
      {...props}
      ref={ref}
      as="table"
      className={clsx(
        className,
        'grid relative content-start gap-0 bg-grey-90 bg-opacity-50 border border-solid border-grey-80',
      )}
      css={css`
        @import '~@bfly/ui/styles/theme';

        thead,
        tbody,
        tr {
          display: contents;
        }

        tr:last-child ${styles.cell} {
          border-bottom: 1px solid $grey-80;
        }
      `}
    >
      {children}
    </ScrollView>
  );
}

export type CellProps<T extends 'th' | 'td'> = React.ComponentProps<T> & {
  empty?: boolean;
  frozen?: boolean;
};

DataGrid.Header = ({
  frozen,
  empty,
  className,
  ...props
}: CellProps<'th'>) => (
  <th
    className={clsx(
      className,
      styles.header,
      empty && styles.empty,
      frozen && styles.frozen,
    )}
    {...props}
  />
);

DataGrid.SortMenuButton = ({ children, id, ...props }: any) => {
  return (
    <Dropdown
      {...props}
      className={clsx(props.className, 'inline-block -mr-4')}
    >
      <Dropdown.Toggle id={id} variant="text-secondary" className="h-auto">
        <DropdownIcon className="ml-2" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        role="menu"
        placement="bottom-end"
        flip={false}
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
        }}
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DataGrid.Anchor = styled('a')`
  @import '~@bfly/ui/styles/theme';
  composes: ellipsis from '~@bfly/ui/styles/text.module.scss';
  max-width: 100%;
  overflow: hidden;

  &:not(.flush) {
    padding: spacer(2) 0;
  }

  &,
  &:hover {
    color: var(--theme-text-color);
  }
`;

DataGrid.Cell = React.forwardRef(
  (
    {
      empty,
      frozen,
      href,
      children,
      ...props
    }: CellProps<'td'> & { href?: string },
    ref: React.Ref<HTMLTableCellElement>,
  ) => (
    <td
      {...props}
      ref={ref}
      className={clsx(
        props.className,
        styles.cell,
        empty && styles.empty,
        frozen && styles.frozen,
        !href && styles.content,
      )}
    >
      {href ? (
        <DataGrid.Anchor
          href={href}
          className={`${styles.content} d-block w-100 h-100`}
        >
          {children}
        </DataGrid.Anchor>
      ) : (
        children
      )}
    </td>
  ),
);

DataGrid.Row = styled('tr').attrs<{ selected?: boolean }>((props) => ({
  ...props,
  'aria-selected': !!props.selected,
}))`
  @import '~@bfly/ui/styles/theme';
  // for group focus, astroturf interpolation doesn't work here
  composes: __datagrid_row_hook from global;

  & > [draggable='true'] * {
    -webkit-user-drag: none;
  }

  &.selected ${styles.cell} {
    background-color: rgb(27, 44, 73); // roughly rgba($blue-80, 0.5);
  }
`;
