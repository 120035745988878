import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import ResourceGridAction from 'components/ResourceGridAction';
import ResourceGridData from 'components/ResourceGridData';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import educationCategoriesTableHeadings from '../messages/educationCategoriesTableHeadings';
import { CreateEducationCategoryModal } from './CreateEducationCategoryModal';
import { EducationCategoriesPage_educationCategory as EducationCategory } from './__generated__/EducationCategoriesPage_educationCategory.graphql';
import { EducationCategoriesPage_viewer$key } from './__generated__/EducationCategoriesPage_viewer.graphql';

const resource = Resource.EDUCATION_CATEGORIES;

const _ = graphql`
  fragment EducationCategoriesPage_educationCategory on EducationCategory {
    id
    educationCategoryId
    name
    color
    ordering
  }
`;

const fragment = graphql`
  fragment EducationCategoriesPage_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "EducationCategoriesPageRefetchQuery") {
    ...CreateEducationCategoryModal_viewer
    educationCategories(first: $count, after: $cursor)
      @connection(
        key: "EducationCategoriesPage_educationCategories"
        filters: []
      ) {
      edges {
        node {
          ...EducationCategoriesPage_educationCategory @relay(mask: false)
        }
      }
    }
  }
`;

export const tableSpec: ColumnSpec<EducationCategory>[] = [
  {
    key: 'educationCategoryId',
    frozen: true,
    label: (
      <FormattedMessage
        {...educationCategoriesTableHeadings.educationCategoryId}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.educationCategoryId}>
        <DataGridLink
          path="/education-categories"
          id={item.educationCategoryId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'name',
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridText value={item.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'color',
    label: <FormattedMessage {...educationCategoriesTableHeadings.color} />,
    render: ({ item }) => <DataGridText value={item.color} />,
  },
  {
    key: 'ordering',
    label: <FormattedMessage {...educationCategoriesTableHeadings.ordering} />,
    render: ({ item }) => <DataGridText value={item.ordering} />,
  },
];

interface EducationCategoriesPageProps {
  viewer: EducationCategoriesPage_viewer$key;
}

export default function EducationCategoriesPage({
  viewer,
}: EducationCategoriesPageProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.educationCategories);
  useBreadcrumbDetector(pageTitles.educationCategories);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.educationCategories} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateEducationCategoryModal {...props} viewerRef={data} />
          )}
        >
          <FormattedMessage
            id="EducationCategoriesPage.create"
            defaultMessage="Create New Education Category"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridData
        data={nodes}
        loadNext={loadNext}
        hasNext={hasNext}
        spec={tableSpec}
      />
    </PermissionsGuard>
  );
}
