/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type memberships_MembershipsPage_QueryVariables = {};
export type memberships_MembershipsPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"MembershipsPage_viewer">;
    } | null;
};
export type memberships_MembershipsPage_Query = {
    readonly response: memberships_MembershipsPage_QueryResponse;
    readonly variables: memberships_MembershipsPage_QueryVariables;
};



/*
query memberships_MembershipsPage_Query {
  viewer {
    ...MembershipsPage_viewer
    id
  }
}

fragment CreateMembershipModal_viewer on Viewer {
  id
}

fragment MembershipsPage_viewer on Viewer {
  ...CreateMembershipModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "memberships_MembershipsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MembershipsPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "memberships_MembershipsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "571a29a5ecf19aec193e9ad4d5be4b2f",
    "id": null,
    "metadata": {},
    "name": "memberships_MembershipsPage_Query",
    "operationKind": "query",
    "text": "query memberships_MembershipsPage_Query {\n  viewer {\n    ...MembershipsPage_viewer\n    id\n  }\n}\n\nfragment CreateMembershipModal_viewer on Viewer {\n  id\n}\n\nfragment MembershipsPage_viewer on Viewer {\n  ...CreateMembershipModal_viewer\n}\n"
  }
};
(node as any).hash = '93419d6351c835c1b053f09866a21a2d';
export default node;
