import Layout from '@4c/layout';
import Badge from '@bfly/ui2/Badge';
import Caret from '@bfly/ui2/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import SrOnly from '@bfly/ui2/SrOnly';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from 'components/Breadcrumbs';
import EnvDropdown from 'components/EnvDropdown';
import { signOut } from 'utils/authActions';
import { usePermissionsContext } from 'utils/usePermissionsContext';
import { getStorageItem, setStorageItem } from 'utils/webStorage';

import { SUPPORTED_REGIONS } from '../../env';

export default function AppHeader() {
  const { viewer } = usePermissionsContext();
  const viewerInitials = viewer?.email ? viewer.email.substring(0, 2) : '';
  return (
    <Navbar>
      <Navbar.NavItem className="mr-0 pr-0">
        <Navbar.Brand as={Link} to="/">
          <Text data-bni-id="AppTitle" className="inline-block mt-1">
            Kaleidoscope
          </Text>
        </Navbar.Brand>
      </Navbar.NavItem>
      <Navbar.Divider />
      <Dropdown>
        <Dropdown.Toggle as={Navbar.NavItem}>
          Region:&nbsp;
          <Text color="primary" transform="uppercase">
            {getStorageItem('region') || 'us'}
          </Text>
          <Caret />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark">
          {SUPPORTED_REGIONS.map((region) => (
            <Dropdown.Item
              key={region}
              onClick={() => {
                setStorageItem('region', region);
                window.location.assign(window.location.href);
              }}
            >
              <Text transform="uppercase">{region}</Text>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <EnvDropdown />

      <Navbar.Divider />
      <Breadcrumbs />
      <Layout.Spacer />
      <Navbar.Divider />
      {viewer && (
        <Dropdown>
          <Dropdown.Toggle
            id="viewer-menu"
            as={Navbar.Button}
            data-cy="viewer-menu"
          >
            <div className="w-8 h-8 bg-gradient-bni rounded-full flex justify-center items-center text-white">
              <Text variant="sm" color="headline" transform="uppercase">
                {viewerInitials}
              </Text>
            </div>
            <SrOnly>{viewer?.email}</SrOnly>
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight className="pt-4">
            <Dropdown.Header data-cy="viewer-details">
              <Layout pad={2}>
                <div className="w-10 h-10 bg-gradient-bni rounded-full flex justify-center items-center text-white">
                  <Text variant="sm" color="inherit" transform="uppercase">
                    {viewerInitials}
                  </Text>
                </div>
                <Layout direction="column" pad={2}>
                  <Text color="body" variant="body">
                    <FormattedMessage
                      id="appHeader.account"
                      defaultMessage="Account Settings"
                    />
                  </Text>
                  <Text color="subtitle" variant="sm" data-cy="viewer-email">
                    {viewer?.email}
                  </Text>
                </Layout>
              </Layout>
              <Layout pad={1} wrap>
                <Text color="subtitle" variant="sm" className="w-100 my-4">
                  <FormattedMessage
                    id="appHeader.adminRoles"
                    defaultMessage="Your admin roles:"
                  />
                </Text>
                {viewer?.adminRoles &&
                  viewer.adminRoles.map((adminRole) => (
                    <Badge
                      color="subtitle"
                      key={adminRole}
                      data-cy={`role-${(adminRole || '')?.toLowerCase()}`}
                    >
                      {adminRole}
                    </Badge>
                  ))}
              </Layout>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item data-cy="logout" onClick={signOut}>
              <Text color="body" variant="body">
                <FormattedMessage
                  id="appHeader.signOut"
                  defaultMessage="Sign out"
                />
              </Text>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Navbar>
  );
}
