/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import EducationContentGridPageRefetchQuery from "./EducationContentGridPageRefetchQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type EducationContentCourseLanguage = "DA" | "DE" | "EN" | "ES" | "FI" | "FR" | "IT" | "NL" | "NO" | "PL" | "PT" | "SV" | "%future added value";
export type EducationContentCourseStatus = "ACTIVE" | "INACTIVE" | "PENDING" | "%future added value";
export type EducationContentPracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type EducationContentType = "COURSE" | "VIDEO" | "%future added value";
export type EducationContentVideoType = "ACQUISITION" | "INTERPRETATION" | "%future added value";
export type EducationContentGrid_viewer = {
    readonly educationContent: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly educationContentId: string | null;
                readonly name: string | null;
                readonly description: string | null;
                readonly type: EducationContentVideoType | null;
                readonly contentType: EducationContentType | null;
                readonly practiceType: EducationContentPracticeType | null;
                readonly isPremium: boolean | null;
                readonly vimeoId: string | null;
                readonly absorbId: string | null;
                readonly duration: number | null;
                readonly ranking: number | null;
                readonly status: EducationContentCourseStatus | null;
                readonly presetLocalIds: ReadonlyArray<string | null> | null;
                readonly toolLocalIds: ReadonlyArray<string | null> | null;
                readonly captureModes: ReadonlyArray<string | null> | null;
                readonly deletedAt: string | null;
                readonly authoredBy: {
                    readonly name: string | null;
                } | null;
                readonly category: {
                    readonly educationCategoryId: string | null;
                    readonly name: string | null;
                } | null;
                readonly courseLanguage: EducationContentCourseLanguage | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "EducationContentGrid_viewer";
};
export type EducationContentGrid_viewer$data = EducationContentGrid_viewer;
export type EducationContentGrid_viewer$key = {
    readonly " $data"?: EducationContentGrid_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EducationContentGrid_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "educationContent"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "absorbId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categoryId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categoryName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contentType"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "name"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "practiceType"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": EducationContentGridPageRefetchQuery
    }
  },
  "name": "EducationContentGrid_viewer",
  "selections": [
    {
      "alias": "educationContent",
      "args": null,
      "concreteType": "EducationContentConnection",
      "kind": "LinkedField",
      "name": "__EducationContentGrid_educationContent_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EducationContentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EducationContent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "educationContentId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contentType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "practiceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPremium",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "vimeoId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "absorbId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "duration",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ranking",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "presetLocalIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "toolLocalIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "captureModes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deletedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "authoredBy",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EducationCategory",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "educationCategoryId",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "courseLanguage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = 'e4bfae3c8502fc1d1f1aa016de501927';
export default node;
