import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import { CreateEhrModal } from './CreateEhrModal';
import { EhrsPage_viewer$key } from './__generated__/EhrsPage_viewer.graphql';

const resource = Resource.EHRS;

const gridFilterFields: FilterField[] = [
  {
    title: tableHeadings.organizationId.defaultMessage,
    field: 'organizationId',
  },
  { title: tableHeadings.domainId.defaultMessage, field: 'domainId' },
];

const fragment = graphql`
  fragment EhrsPage_viewer on Viewer {
    ...CreateEhrModal_viewer
  }
`;

interface EhrsPageProps {
  viewer: EhrsPage_viewer$key;
}

export default function EhrsPage({
  viewer,
  children,
}: PropsWithChildren<EhrsPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.ehrs);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader className="p-2">
        <Header.Title>
          <FormattedMessage {...pageTitles.ehrs} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateEhrModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage
            id="ehrsPage.actonTitle"
            defaultMessage="Create EHR"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
