import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridBoolean from 'components/DataGridBoolean';
import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';

import { SubscriptionsGrid_subscription as Subscription } from './__generated__/SubscriptionsGrid_subscription.graphql';
import { SubscriptionsGrid_viewer$key } from './__generated__/SubscriptionsGrid_viewer.graphql';

const _ = graphql`
  fragment SubscriptionsGrid_subscription on Subscription {
    id
    subscriptionId
    subscriptionEndsAt
    accessEndsAt
    hasStartedActiveSubscription
    hasCompletedTrial
    canAccessProFeatures
    isInGracePeriod
    isTeam
    billingStatus
    billingProvider
    stripeSubscriptionId
    sfSubscriptionId
    planType
    maxNumSeats
    practiceType
    domain {
      domainId
    }
    customer {
      customerId
    }
  }
`;

const fragment = graphql`
  fragment SubscriptionsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    id: { type: "[String!]" }
    customerId: { type: "[String!]" }
    domainId: { type: "[String!]" }
    stripeSubscriptionId: { type: "[String!]" }
    sfSubscriptionId: { type: "[String!]" }
  )
  @refetchable(queryName: "SubscriptionsGridRefetchQuery") {
    subscriptions(
      first: $count
      after: $cursor
      id: $id
      customerId: $customerId
      domainId: $domainId
      stripeSubscriptionId: $stripeSubscriptionId
      sfSubscriptionId: $sfSubscriptionId
    ) @connection(key: "SubscriptionsGrid_subscriptions") {
      edges {
        node {
          ...SubscriptionsGrid_subscription @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Subscription>[] = [
  {
    key: 'subscriptionId',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.subscriptionId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.subscriptionId}>
        <DataGridLink path="/subscriptions" id={item.subscriptionId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'subscriptionEndsAt',
    label: <FormattedMessage {...tableHeadings.subscriptionEndsAt} />,
    render: ({ item }) => <DataGridDateTime value={item.subscriptionEndsAt} />,
  },
  {
    key: 'accessEndsAt',
    label: <FormattedMessage {...tableHeadings.accessEndsAt} />,
    render: ({ item }) => <DataGridDateTime value={item.accessEndsAt} />,
  },
  {
    key: 'hasStartedActiveSubscription',
    label: (
      <FormattedMessage {...tableHeadings.hasStartedActiveSubscription} />
    ),
    render: ({ item }) => (
      <DataGridBoolean value={item.hasStartedActiveSubscription} />
    ),
  },
  {
    key: 'hasCompletedTrial',
    label: <FormattedMessage {...tableHeadings.hasCompletedTrial} />,
    render: ({ item }) => <DataGridBoolean value={item.hasCompletedTrial} />,
  },
  {
    key: 'canAccessProFeatures',
    label: <FormattedMessage {...tableHeadings.canAccessProFeatures} />,
    render: ({ item }) => (
      <DataGridBoolean value={item.canAccessProFeatures} />
    ),
  },
  {
    key: 'isInGracePeriod',
    label: <FormattedMessage {...tableHeadings.isInGracePeriod} />,
    render: ({ item }) => <DataGridBoolean value={item.isInGracePeriod} />,
  },
  {
    key: 'isTeam',
    label: <FormattedMessage {...tableHeadings.isTeam} />,
    render: ({ item }) => <DataGridBoolean value={item.isTeam} />,
  },
  {
    key: 'billingStatus',
    label: <FormattedMessage {...tableHeadings.billingStatus} />,
    render: ({ item }) => <DataGridText value={item.billingStatus} />,
  },
  {
    key: 'billingProvider',
    label: <FormattedMessage {...tableHeadings.billingProvider} />,
    render: ({ item }) => <DataGridText value={item.billingProvider} />,
  },
  {
    key: 'stripeSubscriptionId',
    label: <FormattedMessage {...tableHeadings.stripeSubscriptionId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.stripeSubscriptionId}>
        <DataGridLink
          path="https://dashboard.stripe.com/subscriptions"
          id={item.stripeSubscriptionId}
          target="_blank"
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'sfSubscriptionId',
    label: <FormattedMessage {...tableHeadings.sfSubscriptionId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.sfSubscriptionId}>
        <DataGridText value={item.sfSubscriptionId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'planType',
    label: <FormattedMessage {...tableHeadings.planType} />,
    render: ({ item }) => <DataGridText value={item.planType} />,
  },
  {
    key: 'maxNumSeats',
    label: <FormattedMessage {...tableHeadings.maxNumSeats} />,
    render: ({ item }) => <DataGridText value={item.maxNumSeats} />,
  },
  {
    key: 'practiceType',
    label: <FormattedMessage {...tableHeadings.practiceType} />,
    render: ({ item }) => <DataGridText value={item.practiceType} />,
  },
  {
    key: 'domainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domain?.domainId}>
        <DataGridLink path="/domains" id={item.domain?.domainId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'customerId',
    label: <FormattedMessage {...tableHeadings.customerId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.customer?.customerId}>
        <DataGridLink path="/customers" id={item.customer?.customerId} />
      </QuickCopyText>
    ),
  },
];

interface SubscriptionsGridProps {
  viewer: SubscriptionsGrid_viewer$key;
}

export default function SubscriptionsGrid({ viewer }: SubscriptionsGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.subscriptions);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
    />
  );
}
