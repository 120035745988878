import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import { CountriesPage_viewer$key } from './__generated__/CountriesPage_viewer.graphql';

const resource = Resource.COUNTRIES;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fragment = graphql`
  fragment CountriesPage_viewer on Viewer {
    id
  }
`;

interface CountriesPageProps {
  viewer: CountriesPage_viewer$key;
}

export default function CountriesPage({
  children,
}: PropsWithChildren<CountriesPageProps>) {
  useBreadcrumbDetector(pageTitles.countries);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.countries} />
        </Header.Title>
        <Layout.Spacer />
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
