import InfiniteList from '@bfly/ui2/InfiniteList';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Text from '@bfly/ui2/Text';
import useResponsive from '@bfly/ui2/useResponsive';
import { stylesheet } from 'astroturf';
import { css } from 'astroturf/react';
import clsx from 'clsx';
import useRouter from 'found/useRouter';
import React, { useEffect, useMemo, useState } from 'react';

import DataGridColumnPicker from 'components/DataGridColumnPicker';
import { deletedClass } from 'styles';
import {
  LoadMoreFn,
  NodeEntity,
  ResourceGridDataConfig,
} from 'utils/ResourceGridTypes';
import usePersistedUrlState from 'utils/usePersistedUrlState';

import DataGrid from './DataGrid';
import DataGridRow from './DataGridRow';

export const ITEMS_PER_PAGE = 20;

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .status::before {
    content: '';
    position: absolute;
    width: 2px;
    top: 0;
    left: 0;
    bottom: 0;

  }
  .danger::before {
    background-color: theme-color(danger);
  }
  .warning::before {
    background-color: theme-color(draft);
  }
`;
interface ColumnProps<T> {
  item: T;
  className?: string;
}

export interface ColumnSpec<T> {
  key: string;
  label: React.ReactElement;
  width?: string;
  frozen?: boolean;
  linked?: boolean;
  defaultHidden?: boolean;
  getStatus?: (props: ColumnProps<T>) => 'danger' | 'warning' | null;
  render?: (props: ColumnProps<T>) => React.ReactNode;
  renderCell?: (spec: ColumnSpec<T>, props: ColumnProps<T>) => React.ReactNode;
}

function getDefaultColumns<T>(colSpec: ColumnSpec<T>[]) {
  return Object.fromEntries(
    Object.values(colSpec).map((e) => [e.key, !e.defaultHidden]),
  );
}

// TODO replace location.pathname with context.viewerLocalId
function useUrlState<T>(colSpec: ColumnSpec<T>[]) {
  const {
    match: { location },
  } = useRouter();

  const [storedColumns, setSelectedColumns] = usePersistedUrlState<
    Record<string, boolean>
  >('selectedColumns', `@bfly/grid-view-layout/${location.pathname}`, {});

  // merge with defaults so new columns get included
  const columns = useMemo(() => {
    const result = {};
    const entries = Object.entries(getDefaultColumns<T>(colSpec));
    for (const [key, dflt] of entries) {
      result[key] = key in storedColumns ? storedColumns[key] : dflt;
    }
    return result;
  }, [storedColumns, colSpec]);

  function setQuery({
    selectedColumns = columns,
  }: {
    selectedColumns: Record<string, boolean>;
  }) {
    setSelectedColumns(selectedColumns, {
      ...location,
      query: { ...location.query }, // null -> undefined so it doesn't show up in query
    });
  }

  const state = useMemo(() => {
    return { selectedColumns: columns };
  }, [columns]);

  return [state, setQuery] as const;
}

type DataGridTableProps<T> = {
  data: T[];
  loadNext: LoadMoreFn;
  hasNext: boolean;
  itemsPerPage?: number;
  spec: ColumnSpec<T>[];
  scrollKey: string | null;
} & ResourceGridDataConfig<T>;

const frozenStyles = css`
  @import '~@bfly/ui/styles/theme';

  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    top: 0;
    left: 0;
    bottom: 0;
  }
`;

export default function DataGridPage<T extends NodeEntity>({
  data,
  loadNext,
  hasNext,
  itemsPerPage = ITEMS_PER_PAGE,
  spec,
  getRowProps,
  scrollKey,
}: DataGridTableProps<T>) {
  const [bottomOffset, setBottomOffset] = useState(200);
  const [query, setQuery] = useUrlState<T>(spec);

  useEffect(() => {
    if (!hasNext) {
      setBottomOffset(0);
    }
  }, [hasNext]);

  const { selectedColumns } = query;

  const isLarge = useResponsive('xl', 'up');

  const columnTemplate = spec
    .filter((col) => selectedColumns[col.key])
    .map((col) => `[${col.key}] ${col.width || 'minmax(auto, auto)'}`)
    .join(' ');

  function handleSelectColumns(next: Record<string, boolean>) {
    setQuery({ selectedColumns: next });
  }

  function renderColSpec(col: ColumnSpec<T>, props: ColumnProps<T>) {
    const status = col.getStatus?.(props);

    return (
      <DataGrid.Cell
        className={clsx(
          props.className,
          col.frozen ?? frozenStyles,
          status && styles.status,
          styles[status!],
        )}
        data-cy={props.className === deletedClass ? 'deleted' : 'notDeleted'}
        key={col.key}
        frozen={isLarge && col.frozen}
      >
        {col.renderCell ? col.renderCell(col, props) : col.render?.(props)}
      </DataGrid.Cell>
    );
  }

  return (
    <div
      className="grid relative bg-grey-85 min-h-0 flex-grow"
      css={css`
        // XXX(safari): This is a 1D grid because Safari won't respect any
        //  version of height: 100% on the table which we need to move the scrollbar
        //  to the bottom of the screen (vs the table container)
        grid-template: 'table toolbar' 100% / 1fr auto;
      `}
    >
      <DataGrid
        scrollKey={scrollKey}
        css={css`
          //Todo why o-f use 2fr
          grid-template-columns: ${columnTemplate};
        `}
      >
        <thead>
          <tr>
            {spec.map(
              (col) =>
                selectedColumns[col.key] && (
                  <DataGrid.Header
                    key={col.key}
                    frozen={isLarge && col.frozen}
                  >
                    <Text>{col.label}</Text>
                  </DataGrid.Header>
                ),
            )}
          </tr>
        </thead>
        <tbody>
          <InfiniteList
            bottomOffset={bottomOffset}
            hasMore={() => hasNext}
            loadMore={() => {
              loadNext(itemsPerPage);
            }}
            loadingIndicator={
              <tr>
                <td className="col-span-full">
                  <div
                    css={css`
                      max-width: calc(100vw - 26rem);
                    `}
                  >
                    <LoadingIndicator />
                  </div>
                </td>
              </tr>
            }
            renderScrollObserver={(scrollObserver) => (
              <tr>
                <td className="col-span-full">
                  <div
                    className="flex"
                    css={css`
                      & > span {
                        width: 100%;
                      }
                    `}
                  >
                    {scrollObserver}
                  </div>
                </td>
              </tr>
            )}
          >
            {data.map((item) => {
              return (
                <DataGridRow
                  item={item}
                  getRowProps={getRowProps}
                  key={item.id}
                >
                  {({ classNames: className }) =>
                    spec.map(
                      (col) =>
                        selectedColumns[col.key] &&
                        renderColSpec(col, { item, className }),
                    )
                  }
                </DataGridRow>
              );
            })}
          </InfiniteList>
        </tbody>
      </DataGrid>
      <DataGridColumnPicker
        columns={spec}
        selectedColumns={selectedColumns}
        onColumnChange={handleSelectColumns}
      />
    </div>
  );
}
