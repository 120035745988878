import { defineMessages } from 'react-intl';

export default defineMessages({
  organizationMembershipId: {
    id: 'membershipsTable.heading.organizationMembershipId',
    defaultMessage: 'Membership ID',
  },
  referrerToken: {
    id: 'membershipsTable.heading.referrerToken',
    defaultMessage: 'Referrer Token',
  },
});
