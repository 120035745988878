import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';

import ehrTableHeadings from '../messages/ehrTableHeadings';
import { EhrsGrid_ehr as Ehr } from './__generated__/EhrsGrid_ehr.graphql';
import { EhrsGrid_viewer$key } from './__generated__/EhrsGrid_viewer.graphql';

const _ = graphql`
  fragment EhrsGrid_ehr on Ehr {
    id
    ehrId
    name
    connectionId
    createdAt
    updatedAt
    deletedAt
    hospitalHost
    hospitalPort
    timeoutAck
    timeoutConnection
    timezone
    processingId
    receivingApplication
    receivingFacility
    sendingFacility
    characterEncoding
    studyDescriptionMapping
    studyUrlDisplay
    organization {
      organizationId
    }
    domain {
      domainId
    }
  }
`;

const fragment = graphql`
  fragment EhrsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    organizationId: { type: "[String!]" }
    domainId: { type: "[String!]" }
  )
  @refetchable(queryName: "EhrGridRefetchQuery") {
    ehrs(
      first: $count
      after: $cursor
      organizationId: $organizationId
      domainId: $domainId
    ) @connection(key: "EhrsGrid_ehrs") {
      edges {
        node {
          ...EhrsGrid_ehr @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Ehr>[] = [
  {
    key: 'name',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridLink path="/ehrs" id={item.ehrId} title={item.name!} />
      </QuickCopyText>
    ),
  },
  {
    key: 'ehrId',
    label: <FormattedMessage {...tableHeadings.ehrId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.ehrId}>
        <DataGridLink path="/ehrs" id={item.ehrId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'connectionId',
    label: <FormattedMessage {...tableHeadings.connectionId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.connectionId}>
        <DataGridText value={item.connectionId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'hospitalHost',
    label: <FormattedMessage {...ehrTableHeadings.hospitalHost} />,
    render: ({ item }) => <DataGridText value={item.hospitalHost} />,
  },
  {
    key: 'hospitalPort',
    label: <FormattedMessage {...ehrTableHeadings.hospitalPort} />,
    render: ({ item }) => <DataGridText value={item.hospitalPort} />,
  },
  {
    key: 'timeoutAck',
    label: <FormattedMessage {...ehrTableHeadings.timeoutAck} />,
    render: ({ item }) => <DataGridText value={item.timeoutAck} />,
  },
  {
    key: 'timeoutConnection',
    label: <FormattedMessage {...ehrTableHeadings.timeoutConnection} />,
    render: ({ item }) => <DataGridText value={item.timeoutConnection} />,
  },
  {
    key: 'timezone',
    label: <FormattedMessage {...ehrTableHeadings.timezone} />,
    render: ({ item }) => <DataGridText value={item.timezone} />,
  },
  {
    key: 'processingId',
    label: <FormattedMessage {...ehrTableHeadings.processingId} />,
    render: ({ item }) => <DataGridText value={item.processingId} />,
  },
  {
    key: 'receivingApplication',
    label: <FormattedMessage {...ehrTableHeadings.receivingApplication} />,
    render: ({ item }) => <DataGridText value={item.receivingApplication} />,
  },
  {
    key: 'receivingFacility',
    label: <FormattedMessage {...ehrTableHeadings.receivingFacility} />,
    render: ({ item }) => <DataGridText value={item.receivingFacility} />,
  },
  {
    key: 'sendingFacility',
    label: <FormattedMessage {...ehrTableHeadings.sendingFacility} />,
    render: ({ item }) => <DataGridText value={item.sendingFacility} />,
  },
  {
    key: 'characterEncoding',
    label: <FormattedMessage {...ehrTableHeadings.characterEncoding} />,
    render: ({ item }) => <DataGridText value={item.characterEncoding} />,
  },
  {
    key: 'studyUrlDisplay',
    label: <FormattedMessage {...ehrTableHeadings.studyUrlDisplay} />,
    render: ({ item }) => <DataGridText value={item.studyUrlDisplay} />,
  },
  {
    key: 'organizationId',
    label: <FormattedMessage {...tableHeadings.organizationId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organization?.organizationId}>
        <DataGridLink
          path="/organizations"
          id={item.organization?.organizationId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domain?.domainId}>
        <DataGridLink path="/domains" id={item.domain?.domainId} />
      </QuickCopyText>
    ),
  },
];

interface EhrsGridProps {
  viewer: EhrsGrid_viewer$key;
}

export default function EhrsGrid({ viewer }: EhrsGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.ehrs);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      getRowProps={({ deletedAt }) => ({
        classNames: deletedAt ? deletedClass : '',
      })}
      scrollKey="data-grid"
    />
  );
}
