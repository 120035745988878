export default class TokenStorage {
  private token: string | null = null;

  private expiresOn: Date | null = null;

  getToken(): string | null {
    return this.token;
  }

  isTokenExpired() {
    return this.expiresOn && this.expiresOn < new Date();
  }

  setToken(token: string | null, expiresOn?: Date | null): void {
    this.token = token;
    if (expiresOn) {
      this.expiresOn = expiresOn;
    }
  }
}
