import Header from '@bfly/ui2/Header';
import Section from '@bfly/ui2/Section';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function PermissionDeniedPage() {
  return (
    <>
      <Header>
        <Header.Title data-cy="permission-denied-title">
          <FormattedMessage
            id="permissionDenied.info"
            defaultMessage="Permission Denied"
          />
        </Header.Title>
      </Header>
      <Section className="p-10" align="center" height={400} />
    </>
  );
}
