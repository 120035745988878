import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { array, boolean, number, object, string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import { formMessages } from 'messages/formMessages';
import { nullableString } from 'utils/yupSchemaUtils';

import { nameMessages } from '../messages/formMessages';
import { ANDROID_CAPABILITIES, DomainModal } from './DomainModal';
import { UpdateDomainModalMutation } from './__generated__/UpdateDomainModalMutation.graphql';
import { UpdateDomainModal_domain$key } from './__generated__/UpdateDomainModal_domain.graphql';

const fragment = graphql`
  fragment UpdateDomainModal_domain on Domain {
    id
    domainId
    createdAt
    deletedAt
    subdomainLabel
    name
    enableButterflyLogin
    enableSamlLogin
    enableSharedDeviceCodeLogin
    samlEmailAttributeName
    samlEmailAttributeNameRaw
    samlMetadata
    samlNicknameAttributeName
    samlNicknameAttributeNameRaw
    cognitoClientId
    inactivityTimeoutSeconds
    requiredAndroidCapabilities
    sharedDeviceKey
    maxNumSeats
    country
  }
`;

const updateMutation = graphql`
  mutation UpdateDomainModalMutation($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      domain {
        ...DomainsGrid_domain
      }
    }
  }
`;

const updateDomainSchema = object({
  domainId: string(),
  name: string()
    .required(nameMessages.required)
    .min(2, formMessages.minLength),
  samlEmailAttributeNameRaw: nullableString(),
  samlNicknameAttributeNameRaw: nullableString(),
  requiredAndroidCapabilities: array().of(
    string().oneOf(ANDROID_CAPABILITIES),
  ),
  enableSharedDeviceCodeLogin: boolean(),
  enableButterflyLogin: boolean(),
  maxNumSeats: number().integer().moreThan(-1).nullable(),
  country: string().max(2),
});

interface UpdateDomainModalProps extends ActionModalProps {
  domainRef: UpdateDomainModal_domain$key;
}

export function UpdateDomainModal({
  show,
  onClose,
  domainRef,
}: UpdateDomainModalProps) {
  const domain = useFragment(fragment, domainRef);

  return (
    <DomainModal<UpdateDomainModalMutation>
      title={
        <FormattedMessage
          id="updateDomainModal.title"
          defaultMessage="Update Domain"
        />
      }
      name={domain.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      schema={updateDomainSchema}
      mutation={updateMutation}
      defaultValue={domain}
    />
  );
}
