import { useToast } from '@bfly/ui2/ToastContext';
import Tooltip from '@bfly/ui2/Tooltip';
import { css } from 'astroturf';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import actionMessages from 'messages/actions';

export default function QuickCopyButton({ text }) {
  const toast = useToast();
  if (!text) return null;
  return (
    <Tooltip.Trigger
      id="quick-copy-button"
      tooltip={<FormattedMessage {...actionMessages.clickToCopy} />}
    >
      <button
        type="button"
        className="absolute invisible group-hover:visible w-4 h-5 right-0 cursor-pointer brightness-50"
        css={css`
          background: url('../../assets/image/quick-copy.svg') no-repeat;
        `}
        data-bni-id="quickCopyButton"
        onClick={() => {
          navigator.clipboard.writeText(text).then(() => {
            toast!.success(
              <FormattedMessage {...actionMessages.copiedToClipboard} />,
            );
          });
        }}
      >
        &nbsp;
      </button>
    </Tooltip.Trigger>
  );
}
