/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlaceOfWorkSource = "DEFINITIVE_HEALTHCARE" | "INTERNAL" | "%future added value";
export type PlaceOfWorkStatus = "ACTIVE" | "CLOSED" | "OPENING" | "%future added value";
export type PlaceOfWorkDetailPage_placeOfWork = {
    readonly id: string;
    readonly placeOfWorkId: string | null;
    readonly name: string | null;
    readonly countries: ReadonlyArray<string | null> | null;
    readonly status: PlaceOfWorkStatus | null;
    readonly source: PlaceOfWorkSource | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdatePlaceOfWorkModal_placeOfWork">;
    readonly " $refType": "PlaceOfWorkDetailPage_placeOfWork";
};
export type PlaceOfWorkDetailPage_placeOfWork$data = PlaceOfWorkDetailPage_placeOfWork;
export type PlaceOfWorkDetailPage_placeOfWork$key = {
    readonly " $data"?: PlaceOfWorkDetailPage_placeOfWork$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PlaceOfWorkDetailPage_placeOfWork">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaceOfWorkDetailPage_placeOfWork",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeOfWorkId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countries",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdatePlaceOfWorkModal_placeOfWork"
    }
  ],
  "type": "PlaceOfWork",
  "abstractKey": null
};
(node as any).hash = '228596f4c2f272056cd14e90636debbc';
export default node;
