import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import RolePermissions from '../../components/AccessRolePermissions';
import { SystemDefinedRoleDetailPage_role$key } from './__generated__/SystemDefinedRoleDetailPage_role.graphql';

const fragment = graphql`
  fragment SystemDefinedRoleDetailPage_role on SystemDefinedRole {
    id
    systemDefinedRoleId
    name
    description
    permissions
  }
`;
interface SystemDefinedRoleDetailPageProps {
  role: SystemDefinedRoleDetailPage_role$key;
}

export default function SystemDefinedRoleDetailPage({
  role,
}: SystemDefinedRoleDetailPageProps) {
  const {
    systemDefinedRoleId: roleId,
    name,
    description,
    permissions,
  } = useFragment(fragment, role);
  useBreadcrumbDetector(pageTitles.systemDefinedRole, name);

  const renderMap = useMemo(() => {
    const renderPermissions = {
      label: () => 'permissions',
      value: () => <RolePermissions permissions={permissions} />,
    };
    return {
      permissions: renderPermissions,
    };
  }, [permissions]);

  return (
    <PermissionsGuard resource={Resource.SYSTEM_DEFINED_ROLES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.systemDefinedRole} />}
        name={name!}
        data={{
          name,
          roleId,
          type: 'System Defined',
          description,
          permissions,
        }}
        quickCopyFields={['roleId', 'name']}
        renderMap={renderMap}
        relatedItems={
          <RelatedItem
            name="Users"
            to={{
              pathname: '/users',
              query: { roleId },
            }}
          >
            <FormattedMessage {...pageTitles.users} />
          </RelatedItem>
        }
      />
    </PermissionsGuard>
  );
}
