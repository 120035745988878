import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { userPasswordMessages } from '../messages/userCreation';
import { UserModal, basicUserSchema } from './UserModal';
import { CreateUserModalMutation } from './__generated__/CreateUserModalMutation.graphql';
import { CreateUserModal_viewer$key } from './__generated__/CreateUserModal_viewer.graphql';

const createUserSchema = basicUserSchema.shape({
  auth0Id: string(),
  password: string().when('auth0Id', {
    is: (value) => !value,
    then: (s) =>
      s
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/, {
          message: userPasswordMessages.validation,
          allowEmptyString: true,
        })
        .min(8, userPasswordMessages.length)
        .required(userPasswordMessages.required),
  }),
});

const createMutation = graphql`
  mutation CreateUserModalMutation($input: CreateFlatUserInput!) {
    createFlatUser(input: $input) {
      flatUserEdge {
        node {
          id
          ...FlatUsersGrid_user
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateUserModal_viewer on Viewer {
    id
  }
`;

interface CreateUserModalProps extends ActionModalProps {
  viewerRef: CreateUserModal_viewer$key;
}

export function CreateUserModal({
  viewerRef,
  ...props
}: CreateUserModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'FlatUsersGrid_flatUsers',
        rootFieldName: 'createFlatUser',
        edgeName: 'flatUserEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <UserModal<CreateUserModalMutation>
      title={
        <FormattedMessage
          id="createUserModal.title"
          defaultMessage="Create User"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={createUserSchema}
      mutation={createMutation}
      updater={updater}
      {...props}
    />
  );
}
