import Layout from '@4c/layout';
import Link from '@bfly/ui2/Link';
import Page from '@bfly/ui2/Page';
import SideMenu from '@bfly/ui2/SideMenu';
import Tooltip from '@bfly/ui2/Tooltip';
import { css } from 'astroturf';
import useRouter from 'found/useRouter';
import React, { Fragment, useState } from 'react';

import { ViewerAdminRole } from 'utils/__generated__/PermissionsProvider_viewer.graphql';
import { Resource } from 'utils/permissions';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import applicationIcon from '../../assets/image/application.svg';
import devicesIcon from '../../assets/image/devices.svg';
import educationIcon from '../../assets/image/education.svg';
import kaleidoscopeIcon from '../../assets/image/kaleidoscope.svg';
import lockIcon from '../../assets/image/lock.svg';

interface NavigationConfig {
  key: string;
  categoryTitle: string;
  icon: string;
  children: Array<NavigationConfigLink>;
}

interface NavigationConfigLink {
  title: string;
  slug: string;
  roles?: ViewerAdminRole[];
  resource?: Resource;
}

const navigationConfig: NavigationConfig[] = [
  {
    key: 'kaleidoscope',
    categoryTitle: 'Kaleidoscope',
    icon: kaleidoscopeIcon,
    children: [
      {
        title: 'Countries',
        slug: '/countries',
        resource: Resource.COUNTRIES,
      },
      {
        title: 'Customers',
        slug: '/customers',
        resource: Resource.CUSTOMERS,
      },
      {
        title: 'Domains',
        slug: '/domains',
        resource: Resource.DOMAINS,
      },
      {
        title: 'Domain Invites',
        slug: '/domain-invites',
        resource: Resource.DOMAIN_INVITES,
      },
      {
        title: 'Domain Users Integrations Config',
        slug: '/domain-user-integrations-configs',
        resource: Resource.DOMAIN_USER_INTEGRATIONS_CONFIGS,
      },
      {
        title: 'EHRs',
        slug: '/ehrs',
        resource: Resource.EHRS,
      },
      {
        title: 'Invites',
        slug: '/invites',
        resource: Resource.INVITES,
      },
      {
        title: 'Memberships',
        slug: '/memberships',
        resource: Resource.MEMBERSHIPS,
      },
      {
        title: 'Organizations',
        slug: '/organizations',
        resource: Resource.ORGANIZATIONS,
      },
      {
        title: 'Place Of Work',
        slug: '/place-of-work',
        resource: Resource.PLACES_OF_WORK,
      },
      {
        title: 'Subscriptions',
        slug: '/subscriptions',
        resource: Resource.SUBSCRIPTIONS,
      },
      {
        title: 'Users',
        slug: '/users',
        resource: Resource.USERS,
      },
      {
        title: 'Email Changes',
        slug: '/email-changes',
        resource: Resource.EMAIL_CHANGES,
      },
    ],
  },
  {
    key: 'accessRoles',
    categoryTitle: 'Butterfly Access Roles',
    icon: lockIcon,
    children: [
      {
        title: 'System Defined',
        slug: '/access-roles/system-defined',
        resource: Resource.SYSTEM_DEFINED_ROLES,
      },
      {
        title: 'User Defined',
        slug: '/access-roles/user-defined',
        resource: Resource.USER_DEFINED_ROLES,
      },
    ],
  },
  {
    key: 'applicationVersion',
    categoryTitle: 'Application Version',
    icon: applicationIcon,
    children: [
      {
        title: 'Android Version Recall Entries',
        slug: '/android-version-blacklist-entries',
        resource: Resource.ANDROID_VERSION_BLACKLIST_ENTRIES,
      },
      {
        title: 'Application Version Requirements',
        slug: '/application-version-requirements',
        resource: Resource.APPLICATION_VERSION_REQUIREMENTS,
      },
      {
        title: 'iOS Version Recall Entries',
        slug: '/ios-version-blacklist-entries',
        resource: Resource.IOS_VERSION_BLACKLIST_ENTRIES,
      },
    ],
  },
  {
    key: 'devices',
    categoryTitle: 'Devices',
    icon: devicesIcon,
    children: [
      {
        title: 'Butterfly Devices',
        slug: '/butterfly-devices',
        resource: Resource.BUTTERFLY_DEVICES,
      },
      {
        title: 'Butterfly Device Recalls',
        slug: '/butterfly-device-recalls',
        resource: Resource.BUTTERFLY_DEVICE_RECALLS,
      },
      {
        title: 'Diagnostic Test Results',
        slug: '/diagnostic-test-results',
        resource: Resource.DIAGNOSTIC_TEST_RESULTS,
      },
    ],
  },
  {
    key: 'education',
    categoryTitle: 'Education',
    icon: educationIcon,
    children: [
      {
        title: 'Education Categories',
        slug: '/education-categories',
        resource: Resource.EDUCATION_CATEGORIES,
      },
      {
        title: 'Education Content',
        slug: '/education-content',
        resource: Resource.EDUCATION_CONTENT,
      },
    ],
  },
];

function isExpanded(
  groupKey: string,
  pathname: string,
  config: NavigationConfig[],
): boolean {
  return !!config
    .find((group) => group.key === groupKey)
    ?.children?.some(({ slug }) => pathname.startsWith(slug));
}

const getResources = (list: NavigationConfigLink[]) =>
  list.map(({ resource }) => resource).filter(Boolean) as Resource[];

export default function AppNavigation() {
  const { canRead } = usePermissionsContext();
  const {
    match: {
      location: { pathname },
    },
  } = useRouter();

  const [expandedList, setExpandedList] = useState(() => ({
    kaleidoscope: true,
    accessRoles: isExpanded('accessRoles', pathname, navigationConfig),
    applicationVersion: isExpanded(
      'applicationVersion',
      pathname,
      navigationConfig,
    ),
    devices: isExpanded('devices', pathname, navigationConfig),
    education: isExpanded('education', pathname, navigationConfig),
  }));

  const { canReadSome } = usePermissionsContext();

  return (
    <Page.SidePanel
      css={css`
        max-width: 26rem;
        z-index: 2;
      `}
      data-cy="sideMenu"
      drawerBreakpoint="xs"
    >
      <Layout className="hidden sm:flex">
        <Page.SidePanelCollapseButton className="ml-auto mt-1" />
      </Layout>
      {navigationConfig.map(
        ({ key, categoryTitle, icon, children }) =>
          canReadSome(...getResources(children)) && (
            <Fragment key={key}>
              <SideMenu.Header
                collapsible
                expanded={expandedList[key]}
                onClick={() =>
                  setExpandedList({
                    ...expandedList,
                    [key]: !expandedList[key],
                  })
                }
                id={`${key}MenuHeader`}
                aria-controls={`${key}MenuHeader`}
              >
                <Layout align="center">
                  <img
                    src={icon}
                    alt={`${key}-icon`}
                    width="24"
                    height="24"
                    className="mr-1"
                  />
                  {categoryTitle}
                </Layout>
              </SideMenu.Header>
              <SideMenu
                data-cy={`${key}-sideMenu`}
                expanded={expandedList[key]}
              >
                {children.map(
                  ({ title, slug, resource }) =>
                    (!resource || canRead(resource)) && (
                      <Link to={slug} key={slug}>
                        {(linkProps) => (
                          <Tooltip.Trigger
                            id={`navigation-${slug}`}
                            tooltip={title}
                          >
                            <SideMenu.ListItem>
                              <SideMenu.Link
                                {...linkProps}
                                active={linkProps.active}
                                data-cy={`nav-${slug.substring(1)}`}
                              >
                                {title}
                              </SideMenu.Link>
                            </SideMenu.ListItem>
                          </Tooltip.Trigger>
                        )}
                      </Link>
                    ),
                )}
              </SideMenu>
            </Fragment>
          ),
      )}
    </Page.SidePanel>
  );
}
