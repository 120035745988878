import Button from '@bfly/ui2/Button';
import CopyToClipboardControl from '@bfly/ui2/CopyToClipboardControl';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import ModalForm from 'components/ModalForm';
import { ActionModalProps } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import {
  showUserInviteLinkMessages,
  userInviteIdMessages,
  userInviteLinkUrlMessages,
} from '../messages/userInviteActions';
import { ShowUserInviteLinkAction_userInvite$key } from './__generated__/ShowUserInviteLinkAction_userInvite.graphql';

const showUserInviteLinkSchema = object({
  userInviteId: string().meta({ readOnly: true }),
});

const fragment = graphql`
  fragment ShowUserInviteLinkAction_userInvite on UserInvite {
    userInviteId
    linkUrl
  }
`;

interface ShowUserInviteLinkModalProps extends ActionModalProps {
  userInviteId: string;
  linkUrl: string;
}

function ShowUserInviteLinkModal({
  show,
  onClose,
  userInviteId,
  linkUrl,
}: ShowUserInviteLinkModalProps) {
  return (
    <ModalForm
      title={<FormattedMessage {...showUserInviteLinkMessages.title} />}
      schema={showUserInviteLinkSchema}
      defaultValue={{ userInviteId }}
      show={show}
      onCancel={() => onClose()}
      horizontal
    >
      <Form.FieldGroup
        name="userInviteId"
        label={<FormattedMessage {...userInviteIdMessages.label} />}
        data-cy="showUserInviteLinkModal-userInviteId"
        disabled
      />
      <Form.Group
        label={<FormattedMessage {...userInviteLinkUrlMessages.label} />}
        data-cy="showUserInviteLinkModal-linkUrl"
      >
        <CopyToClipboardControl
          autoFocus
          value={linkUrl || ''}
          data-cy="showUserInviteLinkModal-copyLink"
          buttonLabel={
            <FormattedMessage {...userInviteLinkUrlMessages.copyLink} />
          }
          successMessage={
            <FormattedMessage {...userInviteLinkUrlMessages.copySuccess} />
          }
        />
      </Form.Group>
    </ModalForm>
  );
}

interface UserInviteActionsProps {
  userInviteRef: ShowUserInviteLinkAction_userInvite$key;
}

export default function ShowInviteLinkAction({
  userInviteRef,
}: UserInviteActionsProps) {
  const { userInviteId, linkUrl } = useFragment(fragment, userInviteRef);
  const [showInviteLinkModal, setShowUserInviteLinkModal] = useState(false);
  return (
    <PermissionsGuard resource={Resource.DOMAIN_INVITES} write hideContent>
      <Button
        variant="secondary"
        onClick={() => setShowUserInviteLinkModal(true)}
        data-cy="userInviteDetail-actions-showUserInviteLink"
      >
        <FormattedMessage {...showUserInviteLinkMessages.title} />
      </Button>
      <ShowUserInviteLinkModal
        show={showInviteLinkModal}
        onClose={() => setShowUserInviteLinkModal(false)}
        userInviteId={userInviteId!}
        linkUrl={linkUrl!}
      />
    </PermissionsGuard>
  );
}
