import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { object, string } from 'yup';

import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';

import {
  domainIdMessages,
  userIdMessages,
  userInviteIdMessages,
} from '../messages/userInviteActions';

export const userInviteSchema = object({
  userInviteId: string(),
  domainId: string().required(domainIdMessages.required),
  userId: string().required(userIdMessages.required),
});
const schemaDefaults = userInviteSchema.getDefault();

export default function InviteModal<TMutation extends MutationParameters>({
  defaultValue = {},
  ...props
}: ResourceModalProps<TMutation>) {
  const { domainId, userId } = defaultValue;
  const isCreate = !domainId && !userId;
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation>
      {...props}
      defaultValue={{ ...schemaDefaults, ...defaultValue }}
    >
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="userInviteId"
          label={<FormattedMessage {...userInviteIdMessages.labelRequired} />}
          placeholder={formatMessage(userInviteIdMessages.placeholder)}
          data-cy="userInviteModal-userInviteId"
        />
      )}
      <Form.FieldGroup
        name="domainId"
        disabled={!isCreate}
        label={<FormattedMessage {...domainIdMessages.label} />}
        placeholder={formatMessage(domainIdMessages.placeholder)}
        data-cy="userInviteModal-domainId"
      />
      <Form.FieldGroup
        name="userId"
        disabled={!isCreate}
        label={<FormattedMessage {...userIdMessages.label} />}
        placeholder={formatMessage(userIdMessages.placeholder)}
        data-cy="userInviteModal-userId"
      />
    </ResourceModal>
  );
}
