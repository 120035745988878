import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import FormCheck from '@bfly/ui2/FormCheck';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import Text from '@bfly/ui2/Text';
import isArray from 'lodash/isArray';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { array, boolean, object, string } from 'yup';

import { ActionModalProps, ResourceModal } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import {
  enterpriseCapabilityMessages,
  organizationIdMessages,
} from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import capabilities from '../utils/capabilities';
import captureModes, { BMode } from '../utils/captureModes';
import presetsHum, { Ophthalmic } from '../utils/presetsHum';
import presetsVet from '../utils/presetsVet';
import tools from '../utils/tools';
import { EnterpriseCapabilitiesAction_organization$key } from './__generated__/EnterpriseCapabilitiesAction_organization.graphql';

const recordSchema = object({
  label: string(),
  value: string().nullable(),
});

const enterpriseCapabilitiesSchema = object({
  organizationId: string().meta({ readOnly: true }),
  enterpriseCapabilityManagementEnabled: boolean(),
  enabledPresetIds: array().of(recordSchema),
  enabledToolIds: array().of(recordSchema),
  enabledCaptureModes: array().of(recordSchema),
  enabledCapabilities: array().of(recordSchema),
});

const _ = {
  organization: graphql`
    fragment EnterpriseCapabilitiesActionOrganization on Organization {
      id
      organizationId
      name
      slug
      country
      deletedAt
      subscription {
        subscriptionId
        subscriptionEndsAt
        accessEndsAt
        hasStartedActiveSubscription
        hasCompletedTrial
        canAccessProFeatures
        isInGracePeriod
        isTeam
        billingStatus
        billingProvider
        stripeSubscriptionId
        planType
        maxNumSeats
        practiceType
      }
      isActive
      customer {
        customerId
      }
      domain {
        domainId
      }
      dlDataMode
      dlDataModeOverride
      enterpriseCapabilityManagementEnabled
      enabledPresetIds
      enabledToolIds
      enabledCaptureModes
      enabledCapabilities
      specialType
    }
  `,
};

const fragment = graphql`
  fragment EnterpriseCapabilitiesAction_organization on Organization {
    organizationId
    enabledCapabilities
    enabledCaptureModes
    enabledPresetIds
    enabledToolIds
    enterpriseCapabilityManagementEnabled
    subscription {
      practiceType
    }
  }
`;

const enterpriseCapabilitiesMutation = graphql`
  mutation EnterpriseCapabilitiesAction_UpdateMutation(
    $input: UpdateOrganizationInput!
  ) {
    updateOrganization(input: $input) {
      organization {
        ...EnterpriseCapabilitiesActionOrganization
      }
    }
  }
`;

function deserializeFormValues(formValues, presets) {
  const fieldsMap = {
    enabledCapabilities: capabilities,
    enabledCaptureModes: captureModes,
    enabledPresetIds: presets,
    enabledToolIds: tools,
  };
  const valueFilter = (data, values) =>
    data.filter(({ value }) => values.indexOf(value) !== -1);

  return Object.entries(formValues).reduce(
    (memo, [field, value]) => ({
      ...memo,
      [field]: fieldsMap[field] ? valueFilter(fieldsMap[field], value) : value,
    }),
    {},
  );
}

function serializeFormValues(fieldsMap) {
  return Object.entries(fieldsMap).reduce(
    (memo, [field, value]) => ({
      ...memo,
      [field]: isArray(value) ? value.map((item) => item.value) : value,
    }),
    {},
  );
}

function isAllToggled(data, value) {
  return value && data && value.length === data.length;
}

interface CheckGroupType {
  label: string;
  value: string;
}

interface EnterpriseCapabilitiesModalProps extends ActionModalProps {
  organizationRef: EnterpriseCapabilitiesAction_organization$key;
}

const presetsVariant = {
  none: presetsHum,
  human: presetsHum,
  veterinary: presetsVet,
};

function EnterpriseCapabilitiesModal({
  organizationRef,
  ...props
}: EnterpriseCapabilitiesModalProps) {
  const organization = useFragment(fragment, organizationRef);
  const { organizationId, subscription } = organization;
  const presets =
    subscription?.practiceType &&
    presetsVariant[subscription.practiceType.toLowerCase()];
  const [enterpriseCapabilityManagement, setEnterpriseCapabilityManagement] =
    useState(false);
  const [selectedPresets, setSelectedPresets] = useState<CheckGroupType[]>([]);
  const [selectedTools, setSelectedTools] = useState<CheckGroupType[]>([]);
  const [selectedCaptureModes, setSelectedCaptureModes] = useState<
    CheckGroupType[]
  >([]);
  const [selectedCapabilities, setSelectedCapabilities] = useState<
    CheckGroupType[]
  >([]);
  const [presetsValidationMsg, setPresetsValidationMsg] = useState<string>('');
  const [captureModesValidationMsg, setCaptureModesValidationMsg] =
    useState<string>('');

  const defaultFormValues = useMemo(
    () => deserializeFormValues(organization, presets),
    [organization, presets],
  );

  const formValues = useMemo(() => {
    const fieldsMap = {
      enabledCapabilities: selectedCapabilities,
      enabledCaptureModes: selectedCaptureModes,
      enabledPresetIds: selectedPresets,
      enabledToolIds: selectedTools,
      enterpriseCapabilityManagementEnabled: enterpriseCapabilityManagement,
    };
    return serializeFormValues(fieldsMap);
  }, [
    selectedCapabilities,
    selectedCaptureModes,
    selectedPresets,
    selectedTools,
    enterpriseCapabilityManagement,
  ]);

  useEffect(() => {
    const {
      enabledCapabilities,
      enabledCaptureModes,
      enabledPresetIds,
      enabledToolIds,
      enterpriseCapabilityManagementEnabled,
    } = defaultFormValues as Record<string, CheckGroupType[] | boolean>;
    setEnterpriseCapabilityManagement(
      enterpriseCapabilityManagementEnabled as boolean,
    );
    setSelectedPresets(enabledPresetIds as CheckGroupType[]);
    setSelectedTools(enabledToolIds as CheckGroupType[]);
    setSelectedCaptureModes(enabledCaptureModes as CheckGroupType[]);
    setSelectedCapabilities(enabledCapabilities as CheckGroupType[]);
  }, [
    defaultFormValues,
    setEnterpriseCapabilityManagement,
    setSelectedPresets,
    setSelectedTools,
    setSelectedCaptureModes,
    setSelectedCapabilities,
  ]);

  useEffect(() => {
    const presetsCount = selectedPresets.length;
    const presetsIsRequiredMsg =
      enterpriseCapabilityManagement &&
      !presetsCount &&
      enterpriseCapabilityMessages.presetsIsRequired.defaultMessage;
    const ophthalmicCannotBeSinge =
      enterpriseCapabilityManagement &&
      presetsCount === 1 &&
      selectedPresets.find(({ value }) => value === Ophthalmic) &&
      enterpriseCapabilityMessages.ophthalmicCannotBeSinge.defaultMessage;
    setPresetsValidationMsg(
      presetsIsRequiredMsg || ophthalmicCannotBeSinge || '',
    );
  }, [
    setPresetsValidationMsg,
    enterpriseCapabilityManagement,
    selectedPresets,
  ]);

  useEffect(() => {
    setCaptureModesValidationMsg(
      enterpriseCapabilityManagement &&
        !selectedCaptureModes.find(({ value }) => value === BMode)
        ? enterpriseCapabilityMessages.BModeMustBeSelected.defaultMessage
        : '',
    );
  }, [
    setCaptureModesValidationMsg,
    enterpriseCapabilityManagement,
    selectedCaptureModes,
  ]);
  const { formatMessage } = useIntl();

  return (
    <ResourceModal
      {...props}
      title={
        <FormattedMessage
          id="enterpriseCapabilities.title"
          defaultMessage="Enterprise Capabilities"
        />
      }
      handleCancel={() => {
        const {
          enabledCapabilities,
          enabledCaptureModes,
          enabledPresetIds,
          enabledToolIds,
          enterpriseCapabilityManagementEnabled,
        } = defaultFormValues as Record<string, CheckGroupType[] | boolean>;
        setEnterpriseCapabilityManagement(
          enterpriseCapabilityManagementEnabled as boolean,
        );
        setSelectedPresets(enabledPresetIds as CheckGroupType[]);
        setSelectedTools(enabledToolIds as CheckGroupType[]);
        setSelectedCaptureModes(enabledCaptureModes as CheckGroupType[]);
        setSelectedCapabilities(enabledCapabilities as CheckGroupType[]);
      }}
      submitText={<FormattedMessage {...actionMessages.save} />}
      schema={enterpriseCapabilitiesSchema}
      mutation={enterpriseCapabilitiesMutation}
      defaultValue={{ organizationId }}
      transformOnSubmit={() => ({ organizationId, ...formValues })}
      validationErrors={
        (presetsValidationMsg || captureModesValidationMsg) && (
          <ul>
            {presetsValidationMsg && <li>{presetsValidationMsg}</li>}
            {captureModesValidationMsg && <li>{captureModesValidationMsg}</li>}
          </ul>
        )
      }
    >
      <Form.FieldGroup
        disabled
        name="organizationId"
        label={<FormattedMessage {...organizationIdMessages.label} />}
        placeholder={formatMessage(organizationIdMessages.placeholder)}
        data-cy="EnterpriseCapabilitiesModal-organizationId"
      />
      <FormCheck
        name="enterpriseCapabilityManagementEnabled"
        checked={enterpriseCapabilityManagement}
        onChange={(event) => {
          setEnterpriseCapabilityManagement(
            (event.target as HTMLInputElement).checked,
          );
        }}
        data-cy="EnterpriseCapabilitiesModal-enterpriseCapability-check"
      >
        <Text variant="body-bold">
          <FormattedMessage
            id="enterpriseCapabilities.enableFeature"
            defaultMessage="Enable Enterprise Capability Management"
          />
        </Text>
      </FormCheck>
      <Form.FieldSet
        legend={
          enterpriseCapabilityMessages.enabledPresetsLegend.defaultMessage
        }
      >
        <FormCheck
          checked={isAllToggled(presets, selectedPresets)}
          onChange={(event) => {
            setSelectedPresets(
              (event.target as HTMLInputElement).checked ? presets : [],
            );
          }}
          data-cy="EnterpriseCapabilitiesModal-presets-toggleAll"
        >
          <FormattedMessage
            id="enterpriseCapabilities.toggleAll"
            defaultMessage="Toggle All"
          />
        </FormCheck>
        <FormCheckGroup
          name="enabledPresetIds"
          dataKey="value"
          className="grid items-end grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          value={selectedPresets}
          data={presets}
          renderItem={({ label }) => label}
          onChange={(values: CheckGroupType[]) => {
            setSelectedPresets(values);
          }}
          data-cy="EnterpriseCapabilitiesModal-presets-checkGroup"
        />
        <Layout pad direction="column">
          <Text
            color="danger"
            data-cy="EnterpriseCapabilitiesModal-presets-validationMsg"
          >
            <FormattedMessage
              id="enterpriseCapabilities.validationMsg"
              defaultMessage="{presetsValidationMsg}"
              values={{ presetsValidationMsg }}
            />
          </Text>
          <Text data-cy="EnterpriseCapabilitiesModal-presets-description">
            <FormattedMessage
              id="enterpriseCapabilities.listOfPresets"
              defaultMessage="List of presets to whitelist under Enterprise Capability Management"
            />
          </Text>
        </Layout>
      </Form.FieldSet>
      <Form.FieldSet
        legend={enterpriseCapabilityMessages.enabledToolsLegend.defaultMessage}
      >
        <FormCheck
          checked={isAllToggled(tools, selectedTools)}
          onChange={(event) => {
            setSelectedTools(
              (event.target as HTMLInputElement).checked ? tools : [],
            );
          }}
          data-cy="EnterpriseCapabilitiesModal-tools-toggleAll"
        >
          <FormattedMessage
            id="enterpriseCapabilities.toggleAll"
            defaultMessage="Toggle All"
          />
        </FormCheck>
        <FormCheckGroup
          name="enabledToolIds"
          dataKey="value"
          className="grid items-end grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          value={selectedTools}
          data={tools}
          renderItem={({ label }) => label}
          onChange={(values: CheckGroupType[]) => {
            setSelectedTools(values);
          }}
          data-cy="EnterpriseCapabilitiesModal-tools-checkGroup"
        />
        <Layout pad direction="column">
          <Text data-cy="EnterpriseCapabilitiesModal-tools-description">
            <FormattedMessage
              id="enterpriseCapabilities.listOfTools"
              defaultMessage="List of tools to whitelist under Enterprise Capability Management"
            />
          </Text>
        </Layout>
      </Form.FieldSet>
      <Form.FieldSet
        legend={
          enterpriseCapabilityMessages.enabledCaptureModesLegend.defaultMessage
        }
      >
        <FormCheck
          checked={isAllToggled(captureModes, selectedCaptureModes)}
          onChange={(event) => {
            setSelectedCaptureModes(
              (event.target as HTMLInputElement).checked ? captureModes : [],
            );
          }}
          data-cy="EnterpriseCapabilitiesModal-captureModes-toggleAll"
        >
          <FormattedMessage
            id="enterpriseCapabilities.toggleAll"
            defaultMessage="Toggle All"
          />
        </FormCheck>
        <FormCheckGroup
          name="enabledCaptureModes"
          dataKey="value"
          className="grid items-end grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          value={selectedCaptureModes}
          data={captureModes}
          renderItem={({ label }) => label}
          onChange={(values: CheckGroupType[]) => {
            setSelectedCaptureModes(values);
          }}
          data-cy="EnterpriseCapabilitiesModal-captureModes-checkGroup"
        />
        <Layout pad direction="column">
          <Text
            color="danger"
            data-cy="EnterpriseCapabilitiesModal-captureModes-validationMsg"
          >
            {captureModesValidationMsg}
          </Text>
          <Text data-cy="EnterpriseCapabilitiesModal-captureModes-description">
            <FormattedMessage
              id="enterpriseCapabilities.listOfCaptureModes"
              defaultMessage="List of capture modes to whitelist under Enterprise Capability Management"
            />
          </Text>
        </Layout>
      </Form.FieldSet>
      <Form.FieldSet
        legend={
          enterpriseCapabilityMessages.enabledCapabilities.defaultMessage
        }
      >
        <FormCheck
          checked={isAllToggled(capabilities, selectedCapabilities)}
          onChange={(event) => {
            setSelectedCapabilities(
              (event.target as HTMLInputElement).checked ? capabilities : [],
            );
          }}
          data-cy="EnterpriseCapabilitiesModal-capabilities-toggleAll"
        >
          <FormattedMessage
            id="enterpriseCapabilities.toggleAll"
            defaultMessage="Toggle All"
          />
        </FormCheck>
        <FormCheckGroup
          name="enabledCapabilities"
          dataKey="value"
          className="grid items-end grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          value={selectedCapabilities}
          data={capabilities}
          renderItem={({ label }) => label}
          onChange={(values: CheckGroupType[]) => {
            setSelectedCapabilities(values);
          }}
          data-cy="EnterpriseCapabilitiesModal-capabilities-checkGroup"
        />
        <Layout pad direction="column">
          <Text data-cy="EnterpriseCapabilitiesModal-capabilities-description">
            <FormattedMessage
              id="enterpriseCapabilities.listOfCapabilities"
              defaultMessage="List of cloud capabilities to whitelist under Enterprise Capability Management"
            />
          </Text>
        </Layout>
      </Form.FieldSet>
    </ResourceModal>
  );
}

interface EnterpriseCapabilitiesAction {
  organizationRef: EnterpriseCapabilitiesAction_organization$key;
}

export default function EnterpriseCapabilitiesAction({
  organizationRef,
}: EnterpriseCapabilitiesAction) {
  const [showEnterpriseCapabilities, setShowEnterpriseCapabilities] =
    useState(false);
  return (
    <PermissionsGuard resource={Resource.ORGANIZATIONS} write hideContent>
      <Button
        variant="secondary"
        data-cy="enterprise-capabilities"
        onClick={() => setShowEnterpriseCapabilities(true)}
      >
        <FormattedMessage
          id="organizationDetail.actions.enterpriseCapabilities"
          defaultMessage="Enterprise Capabilities"
        />
      </Button>
      <EnterpriseCapabilitiesModal
        organizationRef={organizationRef}
        show={showEnterpriseCapabilities}
        onClose={() => setShowEnterpriseCapabilities(false)}
      />
    </PermissionsGuard>
  );
}
