import Layout from '@4c/layout';
import React from 'react';

import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import ResourceGridFilter, {
  FilterField,
} from 'components/ResourceGridFilter';
import {
  LoadMoreFn,
  NodeEntity,
  ResourceGridDataConfig,
} from 'utils/ResourceGridTypes';

interface ResourceGridDataLayoutWithFiltersProps {
  filterFields?: FilterField[];
}

export function ResourceGridDataLayoutWithFilters({
  filterFields,
  children,
}: React.PropsWithChildren<ResourceGridDataLayoutWithFiltersProps>) {
  return (
    <Layout
      className="h-100 overflow-hidden"
      direction="column"
      data-cy="resource-grid"
    >
      {filterFields && (
        <Layout
          className="justify-center sm:py-4 sm:px-8 sm:justify-start"
          data-cy="resource-grid-filter"
        >
          <ResourceGridFilter filterFields={filterFields} />
        </Layout>
      )}
      {children}
    </Layout>
  );
}

interface ResourceGridProps<T> extends ResourceGridDataConfig<T> {
  data: T[];
  loadNext: LoadMoreFn;
  hasNext: boolean;
  itemsPerPage?: number;
  spec: ColumnSpec<T>[];
  filterFields?: FilterField[];
}

export default function ResourceGridData<T extends NodeEntity>({
  data,
  loadNext,
  hasNext,
  itemsPerPage,
  spec,
  getRowProps,
  filterFields,
}: ResourceGridProps<T>) {
  return (
    <ResourceGridDataLayoutWithFilters filterFields={filterFields}>
      <DataGridPage
        data={data}
        loadNext={loadNext}
        hasNext={hasNext}
        itemsPerPage={itemsPerPage}
        getRowProps={getRowProps}
        scrollKey="data-grid"
        spec={spec}
      />
    </ResourceGridDataLayoutWithFilters>
  );
}
