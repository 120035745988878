/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type customers_CustomersPage_QueryVariables = {};
export type customers_CustomersPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"CustomersPage_viewer">;
    } | null;
};
export type customers_CustomersPage_Query = {
    readonly response: customers_CustomersPage_QueryResponse;
    readonly variables: customers_CustomersPage_QueryVariables;
};



/*
query customers_CustomersPage_Query {
  viewer {
    ...CustomersPage_viewer
    id
  }
}

fragment CreateCustomerModal_viewer on Viewer {
  id
}

fragment CustomersPage_viewer on Viewer {
  ...CreateCustomerModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "customers_CustomersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomersPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "customers_CustomersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33cf8309a5fde2a13a0317e0e1975d42",
    "id": null,
    "metadata": {},
    "name": "customers_CustomersPage_Query",
    "operationKind": "query",
    "text": "query customers_CustomersPage_Query {\n  viewer {\n    ...CustomersPage_viewer\n    id\n  }\n}\n\nfragment CreateCustomerModal_viewer on Viewer {\n  id\n}\n\nfragment CustomersPage_viewer on Viewer {\n  ...CreateCustomerModal_viewer\n}\n"
  }
};
(node as any).hash = '59a70205d6f85256c2510ee8654855a4';
export default node;
