/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EducationContentCourseLanguage = "DA" | "DE" | "EN" | "ES" | "FI" | "FR" | "IT" | "NL" | "NO" | "PL" | "PT" | "SV" | "%future added value";
export type EducationContentCourseStatus = "ACTIVE" | "INACTIVE" | "PENDING" | "%future added value";
export type EducationContentPracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type EducationContentType = "COURSE" | "VIDEO" | "%future added value";
export type EducationContentVideoType = "ACQUISITION" | "INTERPRETATION" | "%future added value";
export type EducationContentPage_educationContent = {
    readonly id: string;
    readonly educationContentId: string | null;
    readonly name: string | null;
    readonly description: string | null;
    readonly type: EducationContentVideoType | null;
    readonly contentType: EducationContentType | null;
    readonly practiceType: EducationContentPracticeType | null;
    readonly isPremium: boolean | null;
    readonly vimeoId: string | null;
    readonly absorbId: string | null;
    readonly duration: number | null;
    readonly ranking: number | null;
    readonly status: EducationContentCourseStatus | null;
    readonly presetLocalIds: ReadonlyArray<string | null> | null;
    readonly toolLocalIds: ReadonlyArray<string | null> | null;
    readonly captureModes: ReadonlyArray<string | null> | null;
    readonly deletedAt: string | null;
    readonly authoredBy: {
        readonly name: string | null;
    } | null;
    readonly category: {
        readonly educationCategoryId: string | null;
        readonly name: string | null;
    } | null;
    readonly courseLanguage: EducationContentCourseLanguage | null;
    readonly " $refType": "EducationContentPage_educationContent";
};
export type EducationContentPage_educationContent$data = EducationContentPage_educationContent;
export type EducationContentPage_educationContent$key = {
    readonly " $data"?: EducationContentPage_educationContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EducationContentPage_educationContent">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationContentPage_educationContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationContentId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPremium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vimeoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "absorbId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ranking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presetLocalIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toolLocalIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "captureModes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "authoredBy",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EducationCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "educationCategoryId",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "courseLanguage",
      "storageKey": null
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
})();
(node as any).hash = 'fbdc0ad454e0dcf9da17090309c17c83';
export default node;
