/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type InviteModalOrganizationQueryVariables = {
    organizationId: string;
};
export type InviteModalOrganizationQueryResponse = {
    readonly organization: {
        readonly customUserPermissionsEnabled: boolean | null;
        readonly domain: {
            readonly domainId: string | null;
        } | null;
    } | null;
};
export type InviteModalOrganizationQuery = {
    readonly response: InviteModalOrganizationQueryResponse;
    readonly variables: InviteModalOrganizationQueryVariables;
};



/*
query InviteModalOrganizationQuery(
  $organizationId: String!
) {
  organization(organizationId: $organizationId) {
    customUserPermissionsEnabled
    domain {
      domainId
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customUserPermissionsEnabled",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteModalOrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteModalOrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e647aac597e8b2d2346e2be0c215e113",
    "id": null,
    "metadata": {},
    "name": "InviteModalOrganizationQuery",
    "operationKind": "query",
    "text": "query InviteModalOrganizationQuery(\n  $organizationId: String!\n) {\n  organization(organizationId: $organizationId) {\n    customUserPermissionsEnabled\n    domain {\n      domainId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '79fb7f493fb9a1c34345b642ff5c53fd';
export default node;
