import { defineMessages } from 'react-intl';

export default defineMessages({
  stripeSubscriptionId: {
    id: 'organizationTable.heading.stripeSubscriptionId',
    defaultMessage: 'Stripe Subscription ID',
  },
  slug: {
    id: 'organizationTable.heading.slug',
    defaultMessage: 'Slug',
  },
  specialType: {
    id: 'organizationTable.heading.specialType',
    defaultMessage: 'Special Type',
  },
  country: {
    id: 'organizationTable.heading.country',
    defaultMessage: 'Country',
  },
  isActive: {
    id: 'organizationTable.heading.isActive',
    defaultMessage: 'Is Active',
  },
  dlDataMode: {
    id: 'organizationTable.heading.dlDataMode',
    defaultMessage: 'DL Data Mode',
  },
  dlDataModeOverride: {
    id: 'organizationTable.heading.dlDataModeOverride',
    defaultMessage: 'DL Data Mode Override',
  },
  enterpriseCapabilityManagementEnabled: {
    id: 'organizationTable.heading.enterpriseCapabilityManagementEnabled',
    defaultMessage: 'Enterprise Capability Management Enabled',
  },
  enabledCapabilities: {
    id: 'organizationTable.heading.enabledCapabilities',
    defaultMessage: 'Enabled Capabilities',
  },
  numAvailableSeats: {
    id: 'organizationTable.heading.numAvailableSeats',
    defaultMessage: 'Available Seats',
  },
  numPendingInvites: {
    id: 'organizationInvitesTables.heading.numPendingInvites',
    defaultMessage: 'Number of Invites pending',
  },
});
