import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridArray from 'components/DataGridArray';
import DataGridBoolean from 'components/DataGridBoolean';
import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';

import organizationTableHeadings from '../messages/organizationTableHeadings';
import { getCountryName } from '../utils/countries';
import { OrganizationsGrid_organization as Organization } from './__generated__/OrganizationsGrid_organization.graphql';
import { OrganizationsGrid_viewer$key } from './__generated__/OrganizationsGrid_viewer.graphql';

const _ = graphql`
  fragment OrganizationsGrid_organization on Organization {
    id
    organizationId
    name
    slug
    country
    deletedAt
    subscription {
      subscriptionId
      subscriptionEndsAt
      accessEndsAt
      hasStartedActiveSubscription
      hasCompletedTrial
      canAccessProFeatures
      isInGracePeriod
      isTeam
      billingStatus
      billingProvider
      stripeSubscriptionId
      planType
      maxNumSeats
      practiceType
    }
    isActive
    customer {
      customerId
    }
    domain {
      domainId
    }
    dlDataMode
    dlDataModeOverride
    enterpriseCapabilityManagementEnabled
    enabledPresetIds
    enabledToolIds
    enabledCaptureModes
    enabledCapabilities
    specialType
    numAvailableSeats
    numOutstandingInvitations
  }
`;

const fragment = graphql`
  fragment OrganizationsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    customerId: { type: "[String!]" }
    subscriptionId: { type: "[String!]" }
    domainId: { type: "[String!]" }
    specialType: { type: "[String!]" }
    stripeSubscriptionId: { type: "[String!]" }
    slug: { type: "[String!]" }
    name: { type: "[String!]" }
    id: { type: "[String!]" }
  )
  @refetchable(queryName: "OrganizationsGridRefetchQuery") {
    organizations(
      first: $count
      after: $cursor
      customerId: $customerId
      subscriptionId: $subscriptionId
      domainId: $domainId
      specialType: $specialType
      stripeSubscriptionId: $stripeSubscriptionId
      slug: $slug
      name: $name
      id: $id
    ) @connection(key: "OrganizationsGrid_organizations", filters: []) {
      edges {
        node {
          ...OrganizationsGrid_organization @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Organization>[] = [
  {
    key: 'name',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridLink
          path="/organizations"
          id={item.organizationId}
          title={item.name!}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'organizationId',
    label: <FormattedMessage {...tableHeadings.organizationId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organizationId}>
        <DataGridLink path="/organizations" id={item.organizationId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'slug',
    label: <FormattedMessage {...organizationTableHeadings.slug} />,
    render: ({ item }) => (
      <QuickCopyText text={item.slug}>
        <DataGridText value={item.slug} />
      </QuickCopyText>
    ),
  },
  {
    key: 'country',
    label: <FormattedMessage {...organizationTableHeadings.country} />,
    render: ({ item }) => (
      <DataGridText value={item.country ? getCountryName(item.country) : ''} />
    ),
  },
  {
    key: 'deletedAt',
    label: <FormattedMessage {...tableHeadings.deletedAt} />,
    render: ({ item }) => <DataGridDateTime value={item.deletedAt} />,
  },
  {
    key: 'subscriptionId',
    label: <FormattedMessage {...tableHeadings.subscriptionId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.subscription?.subscriptionId}>
        <DataGridLink
          path="/subscriptions"
          id={item.subscription?.subscriptionId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'subscriptionEndsAt',
    label: <FormattedMessage {...tableHeadings.subscriptionEndsAt} />,
    render: ({ item }) => (
      <DataGridDateTime value={item.subscription?.subscriptionEndsAt} />
    ),
  },
  {
    key: 'accessEndsAt',
    label: <FormattedMessage {...tableHeadings.accessEndsAt} />,
    render: ({ item }) => (
      <DataGridDateTime value={item.subscription?.accessEndsAt} />
    ),
  },
  {
    key: 'hasStartedActiveSubscription',
    label: (
      <FormattedMessage {...tableHeadings.hasStartedActiveSubscription} />
    ),
    render: ({ item }) => (
      <DataGridBoolean
        value={item.subscription?.hasStartedActiveSubscription}
      />
    ),
  },
  {
    key: 'hasCompletedTrial',
    label: <FormattedMessage {...tableHeadings.hasCompletedTrial} />,
    render: ({ item }) => (
      <DataGridBoolean value={item.subscription?.hasCompletedTrial} />
    ),
  },
  {
    key: 'canAccessProFeatures',
    label: <FormattedMessage {...tableHeadings.canAccessProFeatures} />,
    render: ({ item }) => (
      <DataGridBoolean value={item.subscription?.canAccessProFeatures} />
    ),
  },
  {
    key: 'isInGracePeriod',
    label: <FormattedMessage {...tableHeadings.isInGracePeriod} />,
    render: ({ item }) => (
      <DataGridBoolean value={item.subscription?.isInGracePeriod} />
    ),
  },
  {
    key: 'isTeam',
    label: <FormattedMessage {...tableHeadings.isTeam} />,
    render: ({ item }) => (
      <DataGridBoolean value={item.subscription?.isTeam} />
    ),
  },
  {
    key: 'billingStatus',
    label: <FormattedMessage {...tableHeadings.billingStatus} />,
    render: ({ item }) => (
      <DataGridText value={item.subscription?.billingStatus} />
    ),
  },
  {
    key: 'billingProvider',
    label: <FormattedMessage {...tableHeadings.billingProvider} />,
    render: ({ item }) => (
      <DataGridText value={item.subscription?.billingProvider} />
    ),
  },
  {
    key: 'stripeSubscriptionId',
    label: (
      <FormattedMessage {...organizationTableHeadings.stripeSubscriptionId} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.subscription?.stripeSubscriptionId}>
        <DataGridLink
          path="https://dashboard.stripe.com/subscriptions"
          id={item.subscription?.stripeSubscriptionId}
          target="_blank"
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'planType',
    label: <FormattedMessage {...tableHeadings.planType} />,
    render: ({ item }) => <DataGridText value={item.subscription?.planType} />,
  },
  {
    key: 'maxNumSeats',
    label: <FormattedMessage {...tableHeadings.maxNumSeats} />,
    render: ({ item }) => (
      <DataGridText value={item.subscription?.maxNumSeats} />
    ),
  },
  {
    key: 'numAvailableSeats',
    label: (
      <FormattedMessage {...organizationTableHeadings.numAvailableSeats} />
    ),
    render: ({ item }) => (
      <div>
        {item.numAvailableSeats && item.numAvailableSeats >= 0 ? (
          <DataGridText value={item.numAvailableSeats} />
        ) : (
          <div>-</div>
        )}{' '}
      </div>
    ),
  },
  {
    key: 'practiceType',
    label: <FormattedMessage {...tableHeadings.practiceType} />,
    render: ({ item }) => (
      <DataGridText value={item.subscription?.practiceType} />
    ),
  },
  {
    key: 'isActive',
    label: <FormattedMessage {...organizationTableHeadings.isActive} />,
    render: ({ item }) => <DataGridBoolean value={item.isActive} />,
  },
  {
    key: 'customerId',
    label: <FormattedMessage {...tableHeadings.customerId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.customer?.customerId}>
        <DataGridLink path="/customers" id={item.customer?.customerId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domain?.domainId}>
        <DataGridLink path="/domains" id={item.domain?.domainId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'dlDataMode',
    label: <FormattedMessage {...organizationTableHeadings.dlDataMode} />,
    render: ({ item }) => <DataGridText value={item.dlDataMode} />,
  },
  {
    key: 'dlDataModeOverride',
    label: (
      <FormattedMessage {...organizationTableHeadings.dlDataModeOverride} />
    ),
    render: ({ item }) => <DataGridText value={item.dlDataModeOverride} />,
  },
  {
    key: 'enterpriseCapabilityManagementEnabled',
    label: (
      <FormattedMessage
        {...organizationTableHeadings.enterpriseCapabilityManagementEnabled}
      />
    ),
    render: ({ item }) => (
      <DataGridBoolean value={item.enterpriseCapabilityManagementEnabled} />
    ),
  },
  {
    key: 'enabledCapabilities',
    label: (
      <FormattedMessage {...organizationTableHeadings.enabledCapabilities} />
    ),
    render: ({ item }) => <DataGridArray value={item.enabledCapabilities} />,
  },
  {
    key: 'specialType',
    label: <FormattedMessage {...organizationTableHeadings.specialType} />,
    render: ({ item }) => <DataGridText value={item.specialType} />,
  },
  {
    key: 'numPendingInvites',
    label: (
      <FormattedMessage {...organizationTableHeadings.numPendingInvites} />
    ),
    render: ({ item }) => (
      <DataGridText value={item.numOutstandingInvitations} />
    ),
  },
];

interface OrganizationsGridProps {
  viewer: OrganizationsGrid_viewer$key;
}

export default function OrganizationsGrid({ viewer }: OrganizationsGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.organizations);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      getRowProps={({ deletedAt }) => ({
        classNames: deletedAt ? deletedClass : '',
      })}
      scrollKey="data-grid"
    />
  );
}
