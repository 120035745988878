/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ViewerAdminRole = "BUTTERFLY_ACCESS_ROLES" | "BUTTERFLY_DEVICE" | "DIAGNOSTIC" | "DL" | "EDUCATION" | "HELIOS" | "MANAGEMENT" | "SALES_OPS" | "STUDY" | "SUPPORT_OPS" | "TELEMED" | "%future added value";
export type PermissionsProvider_viewer = {
    readonly email: string | null;
    readonly adminRoles: ReadonlyArray<ViewerAdminRole | null> | null;
    readonly " $refType": "PermissionsProvider_viewer";
};
export type PermissionsProvider_viewer$data = PermissionsProvider_viewer;
export type PermissionsProvider_viewer$key = {
    readonly " $data"?: PermissionsProvider_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PermissionsProvider_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PermissionsProvider_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminRoles",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'bb9d63dbcef6af296c197dcc988bedee';
export default node;
