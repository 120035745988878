/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_QueryVariables = {};
export type domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"DomainUserIntegrationConfigsPage_viewer">;
    } | null;
};
export type domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_Query = {
    readonly response: domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_QueryResponse;
    readonly variables: domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_QueryVariables;
};



/*
query domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_Query {
  viewer {
    ...DomainUserIntegrationConfigsPage_viewer
    id
  }
}

fragment CreateDomainUserIntegrationConfigModal_viewer on Viewer {
  id
}

fragment DomainUserIntegrationConfigsPage_viewer on Viewer {
  ...CreateDomainUserIntegrationConfigModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainUserIntegrationConfigsPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be56f1d308d8b6fbf17dbbee6737a77d",
    "id": null,
    "metadata": {},
    "name": "domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_Query",
    "operationKind": "query",
    "text": "query domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_Query {\n  viewer {\n    ...DomainUserIntegrationConfigsPage_viewer\n    id\n  }\n}\n\nfragment CreateDomainUserIntegrationConfigModal_viewer on Viewer {\n  id\n}\n\nfragment DomainUserIntegrationConfigsPage_viewer on Viewer {\n  ...CreateDomainUserIntegrationConfigModal_viewer\n}\n"
  }
};
(node as any).hash = 'f1287096ee05e03ae092bebc5ac7b5c0';
export default node;
