import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';

import { DeleteInviteAction_organizationInvite$key } from './__generated__/DeleteInviteAction_organizationInvite.graphql';

const fragment = graphql`
  fragment DeleteInviteAction_organizationInvite on OrganizationInvite {
    organizationInviteId
    deletedAt
  }
`;

const deleteMutation = graphql`
  mutation DeleteInviteActionMutation($input: DeleteOrganizationInviteInput!) {
    deleteOrganizationInvite(input: $input) {
      organizationInvite {
        ...DeleteInviteAction_organizationInvite @relay(mask: false)
      }
    }
  }
`;

interface DeleteInviteActionProps {
  inviteRef: DeleteInviteAction_organizationInvite$key;
}

export default function DeleteInviteAction({
  inviteRef,
}: DeleteInviteActionProps) {
  const { organizationInviteId, deletedAt } = useFragment(fragment, inviteRef);
  return (
    <DeleteAction
      itemName={
        <FormattedMessage
          id="inviteDeleteAction.invite"
          defaultMessage="Invite"
        />
      }
      mutation={deleteMutation}
      redirectTo="/invites"
      disabled={!!deletedAt}
      input={{ organizationInviteId }}
    />
  );
}
