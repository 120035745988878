import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import ResourceGridAction from 'components/ResourceGridAction';
import ResourceGridData from 'components/ResourceGridData';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import applicationVersionRequirementsTableHeadings from '../messages/applicationVersionRequirementsTableHeadings';
import { CreateApplicationVersionRequirementModal } from './CreateApplicationVersionRequirementModal';
import { ApplicationVersionRequirementsPage_applicationVersionRequirement as ApplicationVersionRequirement } from './__generated__/ApplicationVersionRequirementsPage_applicationVersionRequirement.graphql';
import { ApplicationVersionRequirementsPage_viewer$key } from './__generated__/ApplicationVersionRequirementsPage_viewer.graphql';

const resource = Resource.APPLICATION_VERSION_REQUIREMENTS;

const _ = graphql`
  fragment ApplicationVersionRequirementsPage_applicationVersionRequirement on ApplicationVersionRequirement {
    id
    applicationIdentifier
    minimumVersion
  }
`;

const fragment = graphql`
  fragment ApplicationVersionRequirementsPage_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "ApplicationVersionRequirementsPageRefetchQuery") {
    ...CreateApplicationVersionRequirementModal_viewer
    applicationVersionRequirements(first: $count, after: $cursor)
      @connection(
        key: "ApplicationVersionRequirementsPage_applicationVersionRequirements"
        filters: []
      ) {
      edges {
        node {
          ...ApplicationVersionRequirementsPage_applicationVersionRequirement
            @relay(mask: false)
        }
      }
    }
  }
`;

export const tableSpec: ColumnSpec<ApplicationVersionRequirement>[] = [
  {
    key: 'applicationIdentifier',
    frozen: true,
    label: (
      <FormattedMessage
        {...applicationVersionRequirementsTableHeadings.applicationIdentifier}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.applicationIdentifier}>
        <DataGridLink
          path="/application-version-requirements"
          id={item.applicationIdentifier}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'minimumVersion',
    label: (
      <FormattedMessage
        {...applicationVersionRequirementsTableHeadings.minimumVersion}
      />
    ),
    render: ({ item }) => <DataGridText value={item.minimumVersion} />,
  },
];

interface ApplicationVersionRequirementsPageProps {
  viewer: ApplicationVersionRequirementsPage_viewer$key;
}

export default function ApplicationVersionRequirementsPage({
  viewer,
}: ApplicationVersionRequirementsPageProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.applicationVersionRequirements);
  useBreadcrumbDetector(pageTitles.applicationVersionRequirements);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.applicationVersionRequirements} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateApplicationVersionRequirementModal
              {...props}
              viewerRef={data}
            />
          )}
        >
          <FormattedMessage
            id="ApplicationVersionRequirementsPage.create"
            defaultMessage="Create New Application Version Requirement"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridData
        data={nodes}
        loadNext={loadNext}
        hasNext={hasNext}
        spec={tableSpec}
      />
    </PermissionsGuard>
  );
}
