import Layout from '@4c/layout';
import ArrowLeft from '@bfly/icons/ArrowLeft';
import Button from '@bfly/ui2/Button';
import Card from '@bfly/ui2/Card';
import Header from '@bfly/ui2/Header';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LinkFieldsType, Resource, ResourceDetail } from './ResourceDetail';
import ResourceDetailRelatedItems from './ResourceDetailRelatedItems';
import ScrollView from './ScrollView';

interface ResourceDetailPageProps<T extends Resource> {
  title: React.ReactNode;
  data: T;
  name?: string;
  quickCopyFields?: string[];
  objectFields?: string[];
  resourceActions?: React.ReactNode;
  relatedItems?: React.ReactNode;
  crudActions?: React.ReactNode;
  linkFields?: LinkFieldsType;
  renderMap?: Record<
    string,
    {
      label?: () => React.ReactNode;
      value?: () => React.ReactNode;
    }
  >;
  orderComparator?: (a: string, b: string) => 1 | -1 | 0;
}

export default function ResourceDetailPage<T extends Resource>({
  title,
  name,
  data,
  quickCopyFields,
  objectFields,
  resourceActions,
  relatedItems,
  crudActions,
  linkFields,
  renderMap,
  orderComparator,
}: ResourceDetailPageProps<T>) {
  const { router, match } = useRouter();
  return (
    <>
      <Header className="flex lg:flex-row flex-col lg:h-auto h-14 items-start lg:items-center">
        <Layout align="center">
          <Button
            variant="text-secondary"
            data-cy="resource-detail-back"
            onClick={() => router.go(-1)}
            disabled={match.location.index === 0}
          >
            <ArrowLeft width={20} />
          </Button>
          <Header.Title className="mb-0" data-cy="resource-detail-title">
            {title}
          </Header.Title>
        </Layout>
        {name && (
          <Text
            variant="body-bold"
            color="subtitle"
            className="pt-0.5"
            data-cy="resource-detail-name"
          >
            {name}
          </Text>
        )}
      </Header>
      <ScrollView
        scrollKey="resource-detail"
        className="flex px-10 py-6 relative lg:flex-row flex-col min-h-0"
      >
        <ResourceDetail
          linkFields={linkFields}
          data={data}
          orderComparator={orderComparator}
          quickCopyFields={quickCopyFields}
          objectFields={objectFields}
          className="lg:w-full w-3/4"
          renderMap={renderMap}
        />
        {(resourceActions || crudActions || relatedItems) && (
          <Layout
            pad
            direction="column"
            className="lg:sticky static t-0 lg:pl-4 lg:mb-0 sm:mb-4 lg:w-auto w-full lg:scrollable-y lg:order-none order-first"
            css={css`
              min-width: 260px;
            `}
          >
            {(resourceActions || crudActions) && (
              <Card data-cy="resource-detail-actions">
                <Card.Header ruled data-cy="resource-detail-actions-title">
                  <FormattedMessage
                    id="resourceDetail.actions.title"
                    defaultMessage="Actions"
                  />
                </Card.Header>
                {resourceActions && (
                  <Layout as={Card.Body} pad direction="column">
                    {resourceActions}
                  </Layout>
                )}
                {crudActions && (
                  <Layout as={Card.Footer} pad direction="column">
                    {crudActions}
                  </Layout>
                )}
              </Card>
            )}
            {relatedItems && (
              <ResourceDetailRelatedItems>
                {relatedItems}
              </ResourceDetailRelatedItems>
            )}
          </Layout>
        )}
      </ScrollView>
    </>
  );
}
