/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DlDataMode = "EXCLUDE" | "INCLUDE" | "PARTIAL" | "%future added value";
export type SpecialType = "DEMO" | "INTERNAL" | "NONE" | "TEST" | "%future added value";
export type ProvisionOrganizationForSubscriptionInput = {
    subscriptionId: string;
    name: string;
    slug?: string | null | undefined;
    country: string;
    specialType?: SpecialType | null | undefined;
    dlDataModeOverride?: DlDataMode | null | undefined;
    adminEmail: string;
    clientMutationId?: string | null | undefined;
};
export type ProvisionOrganizationForSubscriptionActionMutationVariables = {
    input: ProvisionOrganizationForSubscriptionInput;
};
export type ProvisionOrganizationForSubscriptionActionMutationResponse = {
    readonly provisionOrganizationForSubscription: {
        readonly organization: {
            readonly id: string;
            readonly organizationId: string | null;
        } | null;
    } | null;
};
export type ProvisionOrganizationForSubscriptionActionMutation = {
    readonly response: ProvisionOrganizationForSubscriptionActionMutationResponse;
    readonly variables: ProvisionOrganizationForSubscriptionActionMutationVariables;
};



/*
mutation ProvisionOrganizationForSubscriptionActionMutation(
  $input: ProvisionOrganizationForSubscriptionInput!
) {
  provisionOrganizationForSubscription(input: $input) {
    organization {
      id
      organizationId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProvisionOrganizationForSubscriptionPayload",
    "kind": "LinkedField",
    "name": "provisionOrganizationForSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvisionOrganizationForSubscriptionActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProvisionOrganizationForSubscriptionActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3f45661a10da62390a3e516fb682a6be",
    "id": null,
    "metadata": {},
    "name": "ProvisionOrganizationForSubscriptionActionMutation",
    "operationKind": "mutation",
    "text": "mutation ProvisionOrganizationForSubscriptionActionMutation(\n  $input: ProvisionOrganizationForSubscriptionInput!\n) {\n  provisionOrganizationForSubscription(input: $input) {\n    organization {\n      id\n      organizationId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '08deb2d36d6c4d53b6f5e700c9772ad0';
export default node;
