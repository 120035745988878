import { useContext } from 'react';

import BreadcrumbsContext, {
  BreadcrumbsContextValues,
} from 'utils/BreadcrumbsContext';

export function useBreadcrumbsContext(): BreadcrumbsContextValues {
  const context = useContext(BreadcrumbsContext);

  if (!context) {
    throw new Error(
      'useBreadcrumbsContext must be called within BreadcrumbsContext',
    );
  }

  return context;
}
