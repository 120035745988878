import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import IosVersionBlacklistEntriesPage from './components/IosVersionBlacklistEntriesPage';
import IosVersionBlacklistEntryDetailPage from './components/IosVersionBlacklistEntryDetailPage';

export default (
  <Route path="ios-version-blacklist-entries">
    <Route
      Component={IosVersionBlacklistEntriesPage}
      query={graphql`
        query iosVersionBlacklistEntries_IosVersionBlacklistEntries_Query {
          viewer {
            ...IosVersionBlacklistEntriesPage_viewer
          }
        }
      `}
    />
    <Route
      path=":iosVersionBlacklistEntryId"
      Component={IosVersionBlacklistEntryDetailPage}
      query={graphql`
        query iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_Query(
          $iosVersionBlacklistEntryId: String!
        ) {
          iosVersionBlacklistEntry(
            iosVersionBlacklistEntryId: $iosVersionBlacklistEntryId
          ) {
            ...IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.iosVersionBlacklistEntry) throw new HttpError(404);
      }}
    />
  </Route>
);
