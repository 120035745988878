import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import CustomerModal, { customerSchema } from './CustomerModal';
import { UpdateCustomerModal_UpdateMutation } from './__generated__/UpdateCustomerModal_UpdateMutation.graphql';
import { UpdateCustomerModal_customer$key } from './__generated__/UpdateCustomerModal_customer.graphql';

const fragment = graphql`
  fragment UpdateCustomerModal_customer on Customer {
    id
    customerId
    name
    zendeskId
    netsuiteId
    stripeId
    sfAccountId
  }
`;

const updateMutation = graphql`
  mutation UpdateCustomerModal_UpdateMutation($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        ...CustomersPage_customer
      }
    }
  }
`;

interface UpdateCustomerModalProps extends ActionModalProps {
  customerRef: UpdateCustomerModal_customer$key;
}

export function UpdateCustomerModal({
  customerRef,
  ...props
}: UpdateCustomerModalProps) {
  const customer = useFragment(fragment, customerRef);
  return (
    <CustomerModal<UpdateCustomerModal_UpdateMutation>
      {...props}
      title={
        <FormattedMessage
          id="updateCustomerModal.title"
          defaultMessage="Update Customer"
        />
      }
      name={customer.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      schema={customerSchema}
      mutation={updateMutation}
      defaultValue={customer}
    />
  );
}
