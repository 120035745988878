import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteEmailChangeRequestAction_emailChangeRequest$key } from './__generated__/DeleteEmailChangeRequestAction_emailChangeRequest.graphql';

const fragment = graphql`
  fragment DeleteEmailChangeRequestAction_emailChangeRequest on EmailChangeRequest {
    emailChangeRequestId
    email
  }
`;

const mutation = graphql`
  mutation DeleteEmailChangeRequestActionMutation(
    $input: DeleteEmailChangeRequestInput!
  ) {
    deleteEmailChangeRequest(input: $input) {
      __typename
    }
  }
`;

interface DeleteEmailChangeRequestActionProps {
  emailChangeRequestRef: DeleteEmailChangeRequestAction_emailChangeRequest$key;
}

export default function DeleteEmailChangeRequestAction({
  emailChangeRequestRef,
}: DeleteEmailChangeRequestActionProps) {
  const { emailChangeRequestId, email } = useFragment(
    fragment,
    emailChangeRequestRef,
  );
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      mutation={mutation}
      itemName={email}
      input={{ emailChangeRequestId }}
      redirectTo="/email-changes"
      onDelete={() =>
        deleteBreadcrumb(`/email-changes/${emailChangeRequestId}`)
      }
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
