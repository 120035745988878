/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry = {
    readonly id: string;
    readonly androidVersionBlacklistEntryId: string | null;
    readonly applicationIdentifier: string | null;
    readonly versionRange: string | null;
    readonly versionCode: number | null;
    readonly " $refType": "UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry";
};
export type UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry$data = UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry;
export type UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry$key = {
    readonly " $data"?: UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "androidVersionBlacklistEntryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionCode",
      "storageKey": null
    }
  ],
  "type": "AndroidVersionBlacklistEntry",
  "abstractKey": null
};
(node as any).hash = 'f145f2bac925d42408a59eceb5b6c6c1';
export default node;
