/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomerDetailPage_customer = {
    readonly id: string;
    readonly customerId: string | null;
    readonly name: string | null;
    readonly zendeskId: string | null;
    readonly netsuiteId: string | null;
    readonly stripeId: string | null;
    readonly sfAccountId: string | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateCustomerModal_customer" | "ProvisionOrganizationAction_customer" | "ProvisionDomainAction_customer">;
    readonly " $refType": "CustomerDetailPage_customer";
};
export type CustomerDetailPage_customer$data = CustomerDetailPage_customer;
export type CustomerDetailPage_customer$key = {
    readonly " $data"?: CustomerDetailPage_customer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CustomerDetailPage_customer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerDetailPage_customer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zendeskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netsuiteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sfAccountId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateCustomerModal_customer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProvisionOrganizationAction_customer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProvisionDomainAction_customer"
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
(node as any).hash = '705924c2c45e7c30b891668e6d113724';
export default node;
