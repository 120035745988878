import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import Multiselect from '@bfly/ui2/Multiselect';
import React, { PropsWithChildren, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { array, date, number, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import {
  DEPARTMENT,
  EDUCATION,
  EDU_FEATURE_MODULE_IDS,
  ENTERPRISE_WORKFLOW,
  HOSPITAL,
  MEDICAL_SCHOOL,
  PRO_CUSTOM_FEATURE_MODULE_IDS,
  RESIDENCY_PROGRAM,
} from 'components/OrganizationModal';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { practiceTypeMessages } from 'messages/formMessages';
import {
  billingProviderMessages,
  billingStatusMessages,
  customerIdMessages,
  domainIdMessages,
  featureModuleIdsMessages,
  maxNumSeatsMessages,
  planTypeMessages,
  salesforceSubscriptionIdMessages,
  stripeSubscriptionIdMessages,
  subscriptionEndsAtMessages,
  subscriptionIdMessages,
} from 'messages/organizationActions';
import getOneYearFromNow from 'utils/getOneYearFromNow';
import { nullableString } from 'utils/yupSchemaUtils';

const INDIVIDUAL_FOREVER = 'INDIVIDUAL_FOREVER';
const PRO_CUSTOM = 'PRO_CUSTOM';
const REGULAR_PLAN_TYPES = [
  'BASIC',
  'PRO_TRIAL',
  PRO_CUSTOM,
  'INDIVIDUAL',
  INDIVIDUAL_FOREVER,
  'TEAM',
  'PRO_THREE_YEAR',
];

const ENTERPRISE_PLAN_TYPES = [
  'ENTERPRISE_SECURITY',
  'ENTERPRISE_WORKFLOW',
  'GOVERNMENT',
  'EMS',
  'CLINIC',
  'RESIDENCY_PROGRAM',
  'DEPARTMENT',
  'HOSPITAL',
  'MEDICAL_SCHOOL',
];

const PROVISION_REGULAR_PLAN_TYPES = ['NONE', ...REGULAR_PLAN_TYPES];

export const PROVISION_WITH_DOMAIN_REGULAR_PLAN_TYPES = [
  'NONE',
  'PRO_THREE_YEAR',
  'EDUCATION',
  'BASIC',
  'PRO_TRIAL',
  PRO_CUSTOM,
  'INDIVIDUAL_FOREVER',
  'INDIVIDUAL',
  'TEAM',
  ...ENTERPRISE_PLAN_TYPES,
];

export const SUBSCRIPTION_REGULAR_PLAN_TYPES = [
  'NONE',
  'EDUCATION',
  ...REGULAR_PLAN_TYPES,
  ...ENTERPRISE_PLAN_TYPES,
];

const PRACTICE_TYPE = ['NONE', 'HUMAN', 'VETERINARY'];

const BILLING_STATUS = [
  'TRIALING',
  'ACTIVE',
  'PAST_DUE',
  'CANCELED',
  'UNPAID',
];

const BILLING_PROVIDER = ['STRIPE', 'APPLE', 'SALESFORCE'];

export const subscriptionSchema = object({
  planType: string()
    .required(planTypeMessages.required)
    .oneOf(PROVISION_REGULAR_PLAN_TYPES),
  maxNumSeats: number().required(maxNumSeatsMessages.required).integer(),
  practiceType: string()
    .required(practiceTypeMessages.required)
    .oneOf(PRACTICE_TYPE),
  subscriptionEndsAt: date()
    .nullable()
    .default(getOneYearFromNow)
    .meta({ type: 'datetime' })
    .when('planType', (planType, fieldSchema) =>
      planType === INDIVIDUAL_FOREVER
        ? fieldSchema
        : fieldSchema.required(subscriptionEndsAtMessages.required),
    ),
  billingStatus: string()
    .required(billingStatusMessages.required)
    .oneOf(BILLING_STATUS),
  billingProvider: string()
    .required(billingProviderMessages.required)
    .oneOf(BILLING_PROVIDER),
  stripeSubscriptionId: nullableString(),
  sfSubscriptionId: nullableString(),
  featureModuleIds: array()
    .nullable()
    .of(string())
    .default([])
    .when('planType', {
      is: PRO_CUSTOM,
      then: (f) =>
        f
          .min(1, featureModuleIdsMessages.required)
          .of(string().oneOf(PRO_CUSTOM_FEATURE_MODULE_IDS)),
    })
    .when('planType', {
      is: (planType) =>
        [
          EDUCATION,
          ENTERPRISE_WORKFLOW,
          RESIDENCY_PROGRAM,
          DEPARTMENT,
          HOSPITAL,
          MEDICAL_SCHOOL,
        ].includes(planType),
      then: (f) => f.of(string().oneOf(EDU_FEATURE_MODULE_IDS)),
    }),
  customerId: string().required(customerIdMessages.required),
});

interface SubscriptionModalProps<TMutation extends MutationParameters>
  extends ResourceModalProps<TMutation> {
  withDomain?: boolean;
}

export function SubscriptionModal<TMutation extends MutationParameters>({
  withDomain = false,
  defaultValue,
  transformOnSubmit,
  children,
  ...props
}: PropsWithChildren<SubscriptionModalProps<TMutation>>) {
  const { formatMessage } = useIntl();
  const isNewSubscription = !defaultValue?.subscriptionId;
  const [planType, setPlanType] = useState<string | null>(
    defaultValue?.planType as string,
  );
  const isIndividualForever = planType === INDIVIDUAL_FOREVER;

  return (
    <ResourceModal<TMutation>
      {...props}
      defaultValue={defaultValue}
      transformOnSubmit={({ subscriptionEndsAt, ...fields }) => {
        const submitData = {
          ...fields,
          subscriptionEndsAt: isIndividualForever ? null : subscriptionEndsAt,
        };
        return transformOnSubmit ? transformOnSubmit(submitData) : submitData;
      }}
    >
      {children}
      <Form.FieldGroup
        name="planType"
        as={DropdownList}
        data={
          withDomain
            ? PROVISION_WITH_DOMAIN_REGULAR_PLAN_TYPES
            : SUBSCRIPTION_REGULAR_PLAN_TYPES
        }
        onChange={(value) => {
          setPlanType(value);
        }}
        label={<FormattedMessage {...planTypeMessages.label} />}
        placeholder={planTypeMessages.placeholder}
        description={<FormattedMessage {...planTypeMessages.description} />}
        data-cy="subscriptionModal-planType"
      />
      <Form.FieldGroup
        name="maxNumSeats"
        label={<FormattedMessage {...maxNumSeatsMessages.label} />}
        placeholder={formatMessage(maxNumSeatsMessages.placeholder)}
        description={<FormattedMessage {...maxNumSeatsMessages.description} />}
        data-cy="subscriptionModal-maxNumSeats"
      />
      <Form.FieldGroup
        name="practiceType"
        as={DropdownList}
        data={PRACTICE_TYPE}
        label={<FormattedMessage {...practiceTypeMessages.requiredLabel} />}
        placeholder={practiceTypeMessages.placeholder}
        description={
          <FormattedMessage {...practiceTypeMessages.description} />
        }
        data-cy="subscriptionModal-practiceType"
      />
      <Form.FieldGroup
        name="subscriptionEndsAt"
        as={DatePicker}
        valueFormat={{
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }}
        includeTime
        disabled={isIndividualForever}
        label={<FormattedMessage {...subscriptionEndsAtMessages.label} />}
        placeholder={subscriptionEndsAtMessages.placeholder}
        description={
          isIndividualForever ? (
            <FormattedMessage {...subscriptionEndsAtMessages.description} />
          ) : (
            ''
          )
        }
        data-cy="subscriptionModal-subscriptionEndsAt"
      />
      <Form.FieldGroup
        name="billingStatus"
        as={DropdownList}
        data={BILLING_STATUS}
        label={<FormattedMessage {...billingStatusMessages.requiredLabel} />}
        placeholder={billingStatusMessages.placeholder}
        description={
          <FormattedMessage {...billingStatusMessages.description} />
        }
        data-cy="subscriptionModal-billingStatus"
      />
      <Form.FieldGroup
        name="billingProvider"
        as={DropdownList}
        data={BILLING_PROVIDER}
        label={<FormattedMessage {...billingProviderMessages.requiredLabel} />}
        placeholder={billingProviderMessages.placeholder}
        description={
          <FormattedMessage {...billingProviderMessages.description} />
        }
        data-cy="subscriptionModal-billingProvider"
      />
      <Form.FieldGroup
        name="stripeSubscriptionId"
        label={<FormattedMessage {...stripeSubscriptionIdMessages.label} />}
        placeholder={formatMessage(stripeSubscriptionIdMessages.placeholder)}
        description={
          <FormattedMessage {...stripeSubscriptionIdMessages.description} />
        }
        data-cy="subscriptionModal-stripeSubscriptionId"
      />
      <Form.FieldGroup
        name="sfSubscriptionId"
        label={
          <FormattedMessage {...salesforceSubscriptionIdMessages.label} />
        }
        placeholder={formatMessage(
          salesforceSubscriptionIdMessages.placeholder,
        )}
        description={
          <FormattedMessage
            {...salesforceSubscriptionIdMessages.description}
          />
        }
        data-cy="subscriptionModal-sfSubscriptionId"
      />
      <Form.FieldGroup
        name="featureModuleIds"
        as={Multiselect}
        data={
          planType === PRO_CUSTOM
            ? PRO_CUSTOM_FEATURE_MODULE_IDS
            : EDU_FEATURE_MODULE_IDS
        }
        label={<FormattedMessage {...featureModuleIdsMessages.label} />}
        placeholder={formatMessage(featureModuleIdsMessages.placeholder)}
        description={
          <FormattedMessage {...featureModuleIdsMessages.description} />
        }
        disabled={
          !planType ||
          ![
            PRO_CUSTOM,
            ENTERPRISE_WORKFLOW,
            EDUCATION,
            RESIDENCY_PROGRAM,
            DEPARTMENT,
            HOSPITAL,
            MEDICAL_SCHOOL,
          ].includes(planType)
        }
        data-cy="subscriptionModal-featureModuleIds"
      />
      <Form.FieldGroup
        name="customerId"
        label={<FormattedMessage {...customerIdMessages.label} />}
        placeholder={formatMessage(customerIdMessages.placeholder)}
        description={<FormattedMessage {...customerIdMessages.description} />}
        data-cy="subscriptionModal-customerId"
      />
      <Form.FieldGroup
        disabled
        name="domainId"
        label={<FormattedMessage {...domainIdMessages.label} />}
        placeholder={formatMessage(domainIdMessages.placeholder)}
        data-cy="subscriptionModal-domainId"
      />
      {!isNewSubscription && (
        <Form.FieldGroup
          disabled
          name="subscriptionId"
          label={<FormattedMessage {...subscriptionIdMessages.label} />}
          placeholder={formatMessage(subscriptionIdMessages.placeholder)}
          data-cy="subscriptionModal-subscriptionId"
        />
      )}
    </ResourceModal>
  );
}
