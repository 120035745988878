import getNodes from '@bfly/utils/getNodes';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridBoolean from 'components/DataGridBoolean';
import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';
import {
  hasButterflyAccessRoles,
  hasTableColumn,
} from 'utils/accessRolesUtils';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import userTableHeadings from '../messages/userTableHeadings';
import { FlatUsersGrid_user as User } from './__generated__/FlatUsersGrid_user.graphql';
import { FlatUsersGrid_viewer$key } from './__generated__/FlatUsersGrid_viewer.graphql';

const _ = graphql`
  fragment FlatUsersGrid_user on FlatUser {
    id
    flatUserId
    userId
    auth0Id
    name
    email
    hasUnacceptedInvite: hasPendingOrganizationInvite
    specialtyKey
    specialtyFreeform
    phoneNumber
    placeOfWork {
      placeOfWorkId
      name
    }
    placeOfWorkFreeform
    setupAt
    userCreatedAt
    userDeletedAt
    lastReviewRequestedAt
    lastCloudReviewRequestTriggeredAt
    lastImmediateReviewRequestTriggeredAt
    isImplicitPublic

    # domain user fields
    domainId
    type
    domainUserCreatedAt
    domainUserDeletedAt
    integrationConfigs {
      domainUserIntegrationConfigId
    }
    hasTableauDashboardAccess
    isNurse
    canFinalize
    canQa
    role {
      name
    }
    domain {
      subdomainLabel
    }
  }
`;

const fragment = graphql`
  fragment FlatUsersGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    id: { type: "[String!]" }
    domainId: { type: "[String!]" }
    email: { type: "[String!]" }
    emailDomain: { type: "[String!]" }
    phoneNumber: { type: "[String!]" }
    roleId: { type: "[String!]" }
    name: { type: "[String!]" }
    isUserDeleted: { type: "[String!]" }
  )
  @refetchable(queryName: "FlatUsersGridRefetchQuery") {
    flatUsers(
      first: $count
      after: $cursor
      id: $id
      domainId: $domainId
      email: $email
      emailDomain: $emailDomain
      phoneNumber: $phoneNumber
      name: $name
      isUserDeleted: $isUserDeleted
    ) @connection(key: "FlatUsersGrid_flatUsers") {
      edges {
        node {
          ...FlatUsersGrid_user @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<User>[] = [
  {
    key: 'name',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.name} />,
    getStatus: ({ item }) => (item.isImplicitPublic ? 'danger' : null),
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridLink path="/users" id={item.flatUserId!} title={item.name!} />
      </QuickCopyText>
    ),
  },
  {
    key: 'userId',
    label: <FormattedMessage {...tableHeadings.userId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.userId}>
        <DataGridLink
          path="/users"
          title={item.userId!}
          id={item.flatUserId!}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'subdomainLabel',
    label: <FormattedMessage {...tableHeadings.subdomainLabel} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domain?.subdomainLabel}>
        <DataGridText value={item.domain?.subdomainLabel} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domainId}>
        {item.domainId ? (
          <DataGridLink path="/domains" id={item.domainId} />
        ) : (
          <DataGridText value="-" />
        )}
      </QuickCopyText>
    ),
  },
  {
    key: 'email',
    label: <FormattedMessage {...tableHeadings.email} />,
    render: ({ item }) => (
      <QuickCopyText text={item.email}>
        <DataGridText value={item.email} />
      </QuickCopyText>
    ),
  },
  {
    key: 'auth0Id',
    label: <FormattedMessage {...userTableHeadings.auth0Id} />,
    render: ({ item }) => (
      <QuickCopyText text={item.auth0Id}>
        <DataGridText value={item.auth0Id} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domain Admin',
    label: <FormattedMessage {...tableHeadings.domainAdmin} />,
    render: ({ item }) => <DataGridBoolean value={item.type === 'ADMIN'} />,
  },
  {
    key: 'isNurse',
    label: <FormattedMessage {...tableHeadings.isNurse} />,
    render: ({ item }) => <DataGridBoolean value={item.isNurse} />,
  },
  {
    key: 'canFinalize',
    label: <FormattedMessage {...userTableHeadings.canFinalize} />,
    render: ({ item }) => <DataGridBoolean value={item.canFinalize} />,
  },
  {
    key: 'canQa',
    label: <FormattedMessage {...tableHeadings.canQA} />,
    render: ({ item }) => <DataGridBoolean value={item.canQa} />,
  },
  {
    key: 'hasTableauDashboardAccess',
    label: (
      <FormattedMessage {...userTableHeadings.hasTableauDashboardAccess} />
    ),
    render: ({ item }) => (
      <DataGridBoolean value={item.hasTableauDashboardAccess} />
    ),
  },
  {
    key: 'hasUnacceptedInvite',
    label: <FormattedMessage {...userTableHeadings.hasUnacceptedInvite} />,
    render: ({ item }) => <DataGridBoolean value={item.hasUnacceptedInvite} />,
  },
  {
    key: 'specialtyKey',
    label: <FormattedMessage {...userTableHeadings.specialtyKey} />,
    render: ({ item }) => <DataGridText value={item.specialtyKey} />,
  },
  {
    key: 'specialtyFreeform',
    label: <FormattedMessage {...userTableHeadings.specialtyFreeform} />,
    render: ({ item }) => <DataGridText value={item.specialtyFreeform} />,
  },
  {
    key: 'phoneNumber',
    label: <FormattedMessage {...tableHeadings.phoneNumber} />,
    render: ({ item }) => (
      <QuickCopyText text={item.phoneNumber}>
        <DataGridText value={item.phoneNumber} />
      </QuickCopyText>
    ),
  },
  {
    key: 'placeOfWorkId',
    label: <FormattedMessage {...userTableHeadings.placeOfWorkId} />,
    render: ({ item }) => (
      <DataGridLink
        path="/place-of-work"
        id={item.placeOfWork?.placeOfWorkId}
      />
    ),
  },
  {
    key: 'placeOfWorkName',
    label: <FormattedMessage {...userTableHeadings.placeOfWorkName} />,
    render: ({ item }) => <DataGridText value={item.placeOfWork?.name} />,
  },
  {
    key: 'placeOfWorkFreeform',
    label: <FormattedMessage {...userTableHeadings.placeOfWorkFreeform} />,
    render: ({ item }) => <DataGridText value={item.placeOfWorkFreeform} />,
  },
  {
    key: 'createdAt',
    label: <FormattedMessage {...tableHeadings.createdAt} />,
    render: ({ item }) => <DataGridDateTime value={item.userCreatedAt} />,
  },
  {
    key: 'setupAt',
    label: <FormattedMessage {...userTableHeadings.setupAt} />,
    render: ({ item }) => <DataGridDateTime value={item.setupAt} />,
  },
  {
    key: 'deletedAt',
    label: <FormattedMessage {...tableHeadings.deletedAt} />,
    render: ({ item }) => <DataGridDateTime value={item.userDeletedAt} />,
  },
  {
    key: 'lastReviewRequestedAt',
    label: <FormattedMessage {...userTableHeadings.lastReviewRequestedAt} />,
    render: ({ item }) => (
      <DataGridDateTime value={item.lastReviewRequestedAt} />
    ),
  },
  {
    key: 'lastCloudReviewRequestTriggeredAt',
    label: (
      <FormattedMessage
        {...userTableHeadings.lastCloudReviewRequestTriggeredAt}
      />
    ),
    render: ({ item }) => (
      <DataGridDateTime value={item.lastCloudReviewRequestTriggeredAt} />
    ),
  },
  {
    key: 'lastImmediateReviewRequestTriggeredAt',
    label: (
      <FormattedMessage
        {...userTableHeadings.lastImmediateReviewRequestTriggeredAt}
      />
    ),
    render: ({ item }) => (
      <DataGridDateTime value={item.lastImmediateReviewRequestTriggeredAt} />
    ),
  },
];

const accessRoleColumn = {
  key: 'accessRole',
  label: <FormattedMessage {...tableHeadings.accessRole} />,
  render: ({ item }) => <DataGridText value={item.role?.name} />,
};

interface FlatUsersGridProps {
  viewer: FlatUsersGrid_viewer$key;
}

export default function FlatUsersGrid({ viewer }: FlatUsersGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.flatUsers);
  useBreadcrumbDetector(pageTitles.users);

  const { viewer: viewerWithAdminRoles } = usePermissionsContext();

  const spec = useMemo(() => {
    const result = [...tableSpec];

    if (
      hasButterflyAccessRoles(viewerWithAdminRoles) &&
      !hasTableColumn(tableSpec, accessRoleColumn.key)
    ) {
      result.push(accessRoleColumn);
    }

    return result;
  }, [viewerWithAdminRoles]);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      spec={spec}
      hasNext={hasNext}
      getRowProps={({ userDeletedAt }) => ({
        classNames: userDeletedAt ? deletedClass : '',
      })}
      scrollKey="data-grid"
    />
  );
}
