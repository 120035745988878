import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteEducationVideoAction_educationContent$key } from './__generated__/DeleteEducationVideoAction_educationContent.graphql';

const fragment = graphql`
  fragment DeleteEducationVideoAction_educationContent on EducationContent {
    name
    educationContentId
  }
`;

const mutation = graphql`
  mutation DeleteEducationVideoActionMutation(
    $input: DeleteEducationContentInput!
  ) {
    deleteEducationContent(input: $input) {
      educationContent {
        educationContentId
        deletedAt
      }
    }
  }
`;

interface DeleteEducationVideoActionProps {
  educationVideoRef: DeleteEducationVideoAction_educationContent$key;
}

export default function DeleteEducationVideoAction({
  educationVideoRef,
}: DeleteEducationVideoActionProps) {
  const educationVideo = useFragment(fragment, educationVideoRef);
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      mutation={mutation}
      itemName={educationVideo.name}
      input={{ educationContentId: educationVideo.educationContentId }}
      redirectTo="/education-content"
      onDelete={() =>
        deleteBreadcrumb(
          `/education-content/${educationVideo.educationContentId}`,
        )
      }
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
