import { defineMessages } from 'react-intl';

export const organizationNameMessages = defineMessages({
  label: {
    id: 'updateOrganization.name.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'updateOrganization.name.placeholder',
    defaultMessage: 'Name',
  },
  required: {
    id: 'updateOrganization.name.required',
    defaultMessage: 'Please enter an organization name',
  },
  description: {
    id: 'updateOrganization.name.description',
    defaultMessage: 'The name of the Organization',
  },
});

export const organizationSlugMessages = defineMessages({
  label: {
    id: 'updateOrganization.slug.label',
    defaultMessage: 'Slug*',
  },
  placeholder: {
    id: 'updateOrganization.slug.placeholder',
    defaultMessage: 'Slug',
  },
  matches: {
    id: 'updateOrganization.slug.matches',
    defaultMessage: 'Invalid slug',
  },
  description: {
    id: 'updateOrganization.slug.description',
    defaultMessage:
      'An organization identifier formatted for display in the URL. Slugs must only include letters, numbers and "-". They must also begin and end with a letter or number e.g: my-org',
  },
});

export const organizationSpecialTypeMessages = defineMessages({
  label: {
    id: 'updateOrganization.specialType.label',
    defaultMessage: 'Special Org Type*',
  },
  placeholder: {
    id: 'updateOrganization.specialType.placeholder',
    defaultMessage: 'Special Org Type',
  },
  required: {
    id: 'updateOrganization.specialType.required',
    defaultMessage: 'Please enter a special org type',
  },
  description: {
    id: 'updateOrganization.specialType.description',
    defaultMessage: 'Whether the Organization is an internal or testing org\n',
  },
});

export const organizationIdMessages = defineMessages({
  label: {
    id: 'updateOrganization.organizationId.label',
    defaultMessage: 'Organization ID',
  },
  placeholder: {
    id: 'updateOrganization.organizationId.placeholder',
    defaultMessage: 'Organization ID',
  },
  required: {
    id: 'updateOrganization.organizationId.required',
    defaultMessage: 'Please enter an organization ID',
  },
});
