import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  EducationCategoryModal,
  basicEducationCategorySchema,
} from './EducationCategoryModal';
import { UpdateEducationCategoryModalMutation } from './__generated__/UpdateEducationCategoryModalMutation.graphql';
import { UpdateEducationCategoryModal_educationCategory$key } from './__generated__/UpdateEducationCategoryModal_educationCategory.graphql';

const fragment = graphql`
  fragment UpdateEducationCategoryModal_educationCategory on EducationCategory {
    id
    educationCategoryId
    name
    ordering
  }
`;

const updateMutation = graphql`
  mutation UpdateEducationCategoryModalMutation(
    $input: UpdateEducationCategoryInput!
  ) {
    updateEducationCategory(input: $input) {
      educationCategory {
        ...EducationCategoriesPage_educationCategory
      }
    }
  }
`;

interface UpdateEducationCategoryModalProps extends ActionModalProps {
  educationCategoryRef: UpdateEducationCategoryModal_educationCategory$key;
}

export function UpdateEducationCategoryModal({
  show,
  onClose,
  educationCategoryRef,
}: UpdateEducationCategoryModalProps) {
  const educationCategory = useFragment(fragment, educationCategoryRef);
  return (
    <EducationCategoryModal<UpdateEducationCategoryModalMutation>
      title={
        <FormattedMessage
          id="updateEducationCategoryModal.title"
          defaultMessage="Update Education Category"
        />
      }
      name={educationCategory.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      mutation={updateMutation}
      schema={basicEducationCategorySchema}
      defaultValue={educationCategory}
    />
  );
}
