import { defineMessages } from 'react-intl';

export default defineMessages({
  inviteId: {
    id: 'invitesTable.heading.inviteId',
    defaultMessage: 'Organization Invite ID',
  },
  isAdmin: {
    id: 'invitesTable.heading.isAdmin',
    defaultMessage: 'Is Admin',
  },
});
