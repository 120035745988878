import Button from '@bfly/ui2/Button';
import { useToast } from '@bfly/ui2/ToastContext';
import useDialog from '@bfly/ui2/useDialog';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment, useMutation } from 'react-relay';

import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { deregisterMessages } from '../messages/butterflyDeviceActionMessages';
import { DeregisterAction_butterflyDevice$key } from './__generated__/DeregisterAction_butterflyDevice.graphql';

const fragment = graphql`
  fragment DeregisterAction_butterflyDevice on ButterflyDevice {
    id
    productionId
  }
`;

const mutation = graphql`
  mutation DeregisterActionMutation($input: DeregisterButterflyDeviceInput!) {
    deregisterButterflyDevice(input: $input) {
      butterflyDevice {
        registeredAt
        subscription {
          subscriptionId
        }
      }
    }
  }
`;

interface DeregisterActionProps {
  butterflyDeviceRef: DeregisterAction_butterflyDevice$key;
}

export default function DeregisterAction({
  butterflyDeviceRef,
}: DeregisterActionProps) {
  const dialog = useDialog();
  const toast = useToast();
  const [mutate] = useMutation(mutation);
  const { productionId } = useFragment(fragment, butterflyDeviceRef);

  const handleDeregister = async () => {
    await mutate({
      variables: { input: { productionId } },
      onError: () => {
        toast!.error(
          <FormattedMessage
            {...deregisterMessages.deregisterFailed}
            values={{ productionId }}
          />,
        );
      },
      onCompleted: () => {
        toast!.success(
          <FormattedMessage
            {...deregisterMessages.deregisterSuccessful}
            values={{ productionId }}
          />,
        );
      },
    });
  };
  const handleDeregisterClick = () => {
    return dialog.open(
      <FormattedMessage
        {...deregisterMessages.bodyMsg}
        values={{ productionId }}
      />,
      {
        runConfirm: () => handleDeregister(),
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        title: <FormattedMessage {...deregisterMessages.titleMsg} />,
        confirmLabel: <FormattedMessage {...deregisterMessages.deregister} />,
      },
    );
  };

  return (
    <PermissionsGuard resource={Resource.BUTTERFLY_DEVICES} write hideContent>
      <Button
        variant="secondary"
        data-cy="deregisterAction-deregisterButton"
        onClick={handleDeregisterClick}
      >
        <FormattedMessage {...deregisterMessages.deregister} />
      </Button>
    </PermissionsGuard>
  );
}
