/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteApplicationVersionRequirementAction_applicationVersionRequirement = {
    readonly id: string;
    readonly applicationIdentifier: string | null;
    readonly " $refType": "DeleteApplicationVersionRequirementAction_applicationVersionRequirement";
};
export type DeleteApplicationVersionRequirementAction_applicationVersionRequirement$data = DeleteApplicationVersionRequirementAction_applicationVersionRequirement;
export type DeleteApplicationVersionRequirementAction_applicationVersionRequirement$key = {
    readonly " $data"?: DeleteApplicationVersionRequirementAction_applicationVersionRequirement$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteApplicationVersionRequirementAction_applicationVersionRequirement">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteApplicationVersionRequirementAction_applicationVersionRequirement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    }
  ],
  "type": "ApplicationVersionRequirement",
  "abstractKey": null
};
(node as any).hash = 'ad6c261eb2c8390e86a9dc0d6440aeb3';
export default node;
