import { defineMessages } from 'react-intl';

export const presetLoaderMessages = defineMessages({
  dropdownPlaceholder: {
    id: 'presetLoaderMessages.dropdownPlaceholder',
    defaultMessage: 'Choose a preset to apply',
  },
  educationPreset: {
    id: 'presetLoaderMessages.educationPreset',
    defaultMessage: 'Education Preset',
  },
  individualLifetimePreset: {
    id: 'presetLoaderMessages.individualLifetimePreset',
    defaultMessage: 'Individual Lifetime Preset',
  },
  enterpriseWorkflowPreset: {
    id: 'presetLoaderMessages.enterpriseWorkflowPreset',
    defaultMessage: 'Enterprise Workflow Preset',
  },
  enterpriseSecurityPreset: {
    id: 'presetLoaderMessages.enterpriseSecurityPreset',
    defaultMessage: 'Enterprise Security Preset',
  },
});
