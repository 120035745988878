import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import useQuery from '@bfly/ui2/useQuery';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters, graphql } from 'relay-runtime';
import { date, number, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { PresetLoader, PresetTemplate } from 'components/PresetLoader';
import {
  ResourceModal,
  ResourceModalProps,
  getDefaultFormValue,
} from 'components/ResourceModal';
import { practiceTypeMessages } from 'messages/formMessages';
import {
  adminEmailMessages,
  billingProviderMessages,
  billingStatusMessages,
  countryMessages,
  customerIdMessages,
  dlDataModeOverrideMessages,
  domainIdMessages,
  domainNameMessages,
  firstAdminIdMessages,
  firstOrganizationIdMessages,
  firstOrganizationNameMessages,
  maxNumSeatsMessages,
  organizationSlugMessages,
  planTypeMessages,
  salesforceSubscriptionIdMessages,
  specialTypeMessages,
  stripeSubscriptionIdMessages,
  subdomainLabelMessages,
  subscriptionEndsAtMessages,
} from 'messages/organizationActions';
import {
  dlDataModeOverrideData,
  specialTypesData,
} from 'messages/organizationOptionsData';
import { presetLoaderMessages } from 'messages/presetLoader';
import getOneYearFromNow from 'utils/getOneYearFromNow';

const ENTERPRISE_WORKFLOW = 'ENTERPRISE_WORKFLOW';

const planTypeData = [
  'NONE',
  'PRO_THREE_YEAR',
  'ENTERPRISE_SECURITY',
  ENTERPRISE_WORKFLOW,
  'GOVERNMENT',
  'EDUCATION',
  'EMS',
  'CLINIC',
  'RESIDENCY_PROGRAM',
  'DEPARTMENT',
  'HOSPITAL',
  'MEDICAL_SCHOOL',
];

const practiceTypeData = ['HUMAN', 'VETERINARY'];

const billingStatusData = [
  'TRIALING',
  'ACTIVE',
  'PAST_DUE',
  'CANCELED',
  'UNPAID',
];

const billingProviderData = ['STRIPE', 'APPLE', 'SALESFORCE'];
const countryConfigurations = graphql`
  query DomainModalCountryConfiguration_Query($dataRegion: [String!]) {
    viewer {
      countryConfigurations(dataRegion: $dataRegion) {
        edges {
          node {
            dataRegion
            countryId
          }
        }
      }
    }
  }
`;
export const basicDomainSchema = object({
  name: string()
    .required(domainNameMessages.required)
    .min(2, domainNameMessages.length),
  subdomainLabel: string().required(subdomainLabelMessages.required),
  planType: string()
    .required(planTypeMessages.required)
    .oneOf(planTypeData)
    .default(ENTERPRISE_WORKFLOW),
  maxNumSeats: number().required(maxNumSeatsMessages.required),
  practiceType: string()
    .oneOf(practiceTypeData)
    .required(practiceTypeMessages.required),
  subscriptionEndsAt: date()
    .nullable()
    .default(getOneYearFromNow)
    .meta({ type: 'datetime' })
    .required(subscriptionEndsAtMessages.required),
  billingStatus: string().oneOf(billingStatusData),
  billingProvider: string().oneOf(billingProviderData),
  stripeSubscriptionId: string(),
  sfSubscriptionId: string(),
  domainId: string().meta({
    readOnly: true,
  }),
});

const basicTemplateValues = {
  maxNumSeats: 200,
  practiceType: 'HUMAN',
  subscriptionEndsAt: new Date(2098, 0, 1),
  billingStatus: 'ACTIVE',
  billingProvider: 'STRIPE',
  specialType: 'NONE',
  dlDataModeOverride: 'EXCLUDE',
};

const domainPresets: PresetTemplate[] = [
  {
    title: presetLoaderMessages.enterpriseWorkflowPreset,
    template: {
      planType: 'ENTERPRISE_WORKFLOW',
      ...basicTemplateValues,
    },
  },
  {
    title: presetLoaderMessages.enterpriseSecurityPreset,
    template: {
      planType: 'ENTERPRISE_SECURITY',
      ...basicTemplateValues,
    },
  },
];

interface DomainModalProps<TMutation extends MutationParameters>
  extends ResourceModalProps<TMutation> {
  provisionDomain?: boolean;
  alertMessage?: ReactNode;
}

export default function DomainModal<TMutation extends MutationParameters>({
  provisionDomain,
  alertMessage,
  ...props
}: DomainModalProps<TMutation>) {
  const { data } = useQuery<any>(countryConfigurations, {
    variables: {},
  });

  const countryConfigurationsData: any[] = [];
  data?.viewer?.countryConfigurations.edges.forEach((x) => {
    countryConfigurationsData.push({
      name: x.node.countryId,
      value: x.node.countryId,
    });
  });

  const { formatMessage } = useIntl();
  const isCreate = !props.defaultValue?.domainId;
  const [formValue, setFormValue] = useState(
    getDefaultFormValue(props.schema, props.defaultValue),
  );
  return (
    <ResourceModal
      {...props}
      value={formValue}
      onChange={(input) => setFormValue(input)}
    >
      {alertMessage}
      {isCreate && (
        <PresetLoader
          templates={domainPresets}
          onApply={(input) => setFormValue({ ...formValue, ...input })}
        />
      )}
      <Form.FieldGroup
        name="name"
        placeholder={formatMessage(domainNameMessages.placeholder)}
        label={<FormattedMessage {...domainNameMessages.label} />}
        description={<FormattedMessage {...domainNameMessages.description} />}
        data-cy="createDomain-nameField"
      />
      <Form.FieldGroup
        name="subdomainLabel"
        placeholder={formatMessage(subdomainLabelMessages.placeholder)}
        label={<FormattedMessage {...subdomainLabelMessages.label} />}
        description={
          <FormattedMessage {...subdomainLabelMessages.description} />
        }
        data-cy="createDomain-subdomainLabelField"
      />
      <Form.FieldGroup
        name="planType"
        as={DropdownList}
        data={planTypeData}
        placeholder={planTypeMessages.placeholder}
        label={<FormattedMessage {...planTypeMessages.label} />}
        description={<FormattedMessage {...planTypeMessages.description} />}
        data-cy="createDomain-planTypeField"
      />
      <Form.FieldGroup
        name="maxNumSeats"
        placeholder={formatMessage(maxNumSeatsMessages.placeholder)}
        label={<FormattedMessage {...maxNumSeatsMessages.label} />}
        description={<FormattedMessage {...maxNumSeatsMessages.description} />}
        data-cy="createDomain-maxNumSeatsMessagesField"
      />
      <Form.FieldGroup
        name="practiceType"
        as={DropdownList}
        data={practiceTypeData}
        placeholder={practiceTypeMessages.placeholder}
        label={<FormattedMessage {...practiceTypeMessages.label} />}
        description={
          <FormattedMessage {...practiceTypeMessages.description} />
        }
        data-cy="createDomain-practiceTypeField"
      />
      <Form.FieldGroup
        name="subscriptionEndsAt"
        as={DatePicker}
        valueFormat={{
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }}
        includeTime
        placeholder={subscriptionEndsAtMessages.placeholder}
        label={<FormattedMessage {...subscriptionEndsAtMessages.label} />}
        data-cy="createDomain-subscriptionEndsAtField"
      />
      <Form.FieldGroup
        name="billingStatus"
        as={DropdownList}
        data={billingStatusData}
        placeholder={billingStatusMessages.placeholder}
        label={<FormattedMessage {...billingStatusMessages.label} />}
        description={
          <FormattedMessage {...billingStatusMessages.description} />
        }
        data-cy="createDomain-billingStatusField"
      />
      <Form.FieldGroup
        name="billingProvider"
        as={DropdownList}
        data={billingProviderData}
        placeholder={billingProviderMessages.placeholder}
        label={<FormattedMessage {...billingProviderMessages.label} />}
        description={
          <FormattedMessage {...billingProviderMessages.description} />
        }
        data-cy="createDomain-billingProviderField"
      />
      <Form.FieldGroup
        name="stripeSubscriptionId"
        placeholder={formatMessage(stripeSubscriptionIdMessages.placeholder)}
        label={<FormattedMessage {...stripeSubscriptionIdMessages.label} />}
        description={
          <FormattedMessage {...stripeSubscriptionIdMessages.description} />
        }
        data-cy="createDomain-stripeSubscriptionIdField"
      />
      <Form.FieldGroup
        name="sfSubscriptionId"
        placeholder={formatMessage(
          salesforceSubscriptionIdMessages.placeholder,
        )}
        label={
          <FormattedMessage {...salesforceSubscriptionIdMessages.label} />
        }
        description={
          <FormattedMessage
            {...salesforceSubscriptionIdMessages.description}
          />
        }
        data-cy="createDomain-salesforceSubscriptionIdField"
      />
      <Form.FieldGroup
        name="customerId"
        placeholder={formatMessage(customerIdMessages.placeholder)}
        label={<FormattedMessage {...customerIdMessages.label} />}
        description={<FormattedMessage {...customerIdMessages.description} />}
        data-cy="createDomain-customerIdField"
      />
      <Form.FieldGroup
        name="domainId"
        placeholder={formatMessage(domainIdMessages.placeholder)}
        label={<FormattedMessage {...domainIdMessages.label} />}
        data-cy="createDomain-domainIdField"
        disabled
      />
      {!provisionDomain && (
        <>
          <Form.FieldGroup
            name="firstOrganizationId"
            placeholder={formatMessage(
              firstOrganizationIdMessages.placeholder,
            )}
            label={<FormattedMessage {...firstOrganizationIdMessages.label} />}
            data-cy="createDomain-firstOrganizationIdField"
            readOnly
          />
          <Form.FieldGroup
            name="firstAdminId"
            placeholder={formatMessage(firstAdminIdMessages.placeholder)}
            label={<FormattedMessage {...firstAdminIdMessages.label} />}
            description={
              <FormattedMessage {...firstAdminIdMessages.description} />
            }
            data-cy="createDomain-firstAdminIdField"
          />
        </>
      )}
      {provisionDomain && (
        <>
          <Form.FieldGroup
            name="slug"
            placeholder={formatMessage(organizationSlugMessages.placeholder)}
            label={<FormattedMessage {...organizationSlugMessages.label} />}
            description={
              <FormattedMessage {...organizationSlugMessages.description} />
            }
            data-cy="provisionDomain-slugField"
          />
          <Form.FieldGroup
            name="specialType"
            as={DropdownList}
            data={specialTypesData}
            placeholder={specialTypeMessages.placeholder}
            label={<FormattedMessage {...specialTypeMessages.label} />}
            description={
              <FormattedMessage {...specialTypeMessages.description} />
            }
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="provisionDomain-specialTypeField"
          />
          <Form.FieldGroup
            name="adminEmail"
            placeholder={formatMessage(adminEmailMessages.placeholder)}
            label={<FormattedMessage {...adminEmailMessages.label} />}
            description={
              <FormattedMessage {...adminEmailMessages.description} />
            }
            data-cy="provisionDomain-adminEmailField"
          />
          <Form.FieldGroup
            name="country"
            as={DropdownList}
            data={countryConfigurationsData}
            placeholder={countryMessages.placeholder}
            label={<FormattedMessage {...countryMessages.label} />}
            description={<FormattedMessage {...countryMessages.description} />}
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="provisionDomain-countryField"
          />
          <Form.FieldGroup
            name="dlDataModeOverride"
            as={DropdownList}
            data={dlDataModeOverrideData}
            label={<FormattedMessage {...dlDataModeOverrideMessages.label} />}
            placeholder={dlDataModeOverrideMessages.placeholder}
            textField="name"
            dataKey="value"
            mapFromValue={({ value }) => value}
            data-cy="provisionDomain-dlDataModeOverride"
          />
          <Form.FieldGroup
            name="organizationName"
            label={
              <FormattedMessage {...firstOrganizationNameMessages.label} />
            }
            placeholder={formatMessage(
              firstOrganizationNameMessages.placeholder,
            )}
            data-cy="provisionDomain-firstOrganizationName"
          />
        </>
      )}
    </ResourceModal>
  );
}
