import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  IosVersionBlacklistEntryModal,
  iosVersionBlacklistEntrySchema,
} from './IosVersionBlacklistEntryModal';
import { CreateIosVersionBlacklistEntryModalMutation } from './__generated__/CreateIosVersionBlacklistEntryModalMutation.graphql';
import { CreateIosVersionBlacklistEntryModal_viewer$key } from './__generated__/CreateIosVersionBlacklistEntryModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateIosVersionBlacklistEntryModalMutation(
    $input: CreateIosVersionBlacklistEntryInput!
  ) {
    createIosVersionBlacklistEntry(input: $input) {
      iosVersionBlacklistEntryEdge {
        node {
          id
          ...IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateIosVersionBlacklistEntryModal_viewer on Viewer {
    id
  }
`;

interface CreateIosVersionBlacklistEntryModalProps extends ActionModalProps {
  viewerRef: CreateIosVersionBlacklistEntryModal_viewer$key;
}

export function CreateIosVersionBlacklistEntryModal({
  viewerRef,
  ...props
}: CreateIosVersionBlacklistEntryModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey:
          'IosVersionBlacklistEntriesPage_iosVersionBlacklistEntries',
        rootFieldName: 'createIosVersionBlacklistEntry',
        edgeName: 'iosVersionBlacklistEntryEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <IosVersionBlacklistEntryModal<CreateIosVersionBlacklistEntryModalMutation>
      {...props}
      schema={iosVersionBlacklistEntrySchema}
      mutation={createMutation}
      updater={updater}
      title={
        <FormattedMessage
          id="createIosVersionBlacklistEntryModal.title"
          defaultMessage="Create iOS Version Recall Entry"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
    />
  );
}
