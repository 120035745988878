import Header from '@bfly/ui2/Header';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

const resource = Resource.SUBSCRIPTIONS;

const gridFilterFields: FilterField[] = [
  { title: tableHeadings.subscriptionId.defaultMessage, field: 'id' },
  { title: tableHeadings.customerId.defaultMessage, field: 'customerId' },
  { title: tableHeadings.domainId.defaultMessage, field: 'domainId' },
  {
    title: tableHeadings.stripeSubscriptionId.defaultMessage,
    field: 'stripeSubscriptionId',
  },
  {
    title: tableHeadings.sfSubscriptionId.defaultMessage,
    field: 'sfSubscriptionId',
  },
];

interface SubscriptionsPageProps {
  children?: ReactNode;
}

export default function SubscriptionsPage({
  children,
}: SubscriptionsPageProps) {
  useBreadcrumbDetector(pageTitles.subscriptions);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.subscriptions} />
        </Header.Title>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
