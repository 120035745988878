import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'actions.save',
    defaultMessage: 'Save',
  },
  submit: {
    id: 'actions.submit',
    defaultMessage: 'Submit',
  },
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
  },
  deactivate: {
    id: 'actions.deactivate',
    defaultMessage: 'Deactivate',
  },
  activate: {
    id: 'actions.activate',
    defaultMessage: 'Activate',
  },
  update: {
    id: 'actions.update',
    defaultMessage: 'Update',
  },
  edit: {
    id: 'actions.edit',
    defaultMessage: 'Edit',
  },
  reset: {
    id: 'actions.reset',
    defaultMessage: 'Reset',
  },
  confirm: {
    id: 'actions.confirm',
    defaultMessage: 'Confirm',
  },
  close: {
    id: 'actions.close',
    defaultMessage: 'Close',
  },
  complete: {
    id: 'actions.complete',
    defaultMessage: 'Complete',
  },
  done: {
    id: 'actions.done',
    defaultMessage: 'Done',
  },
  create: {
    id: 'actions.create',
    defaultMessage: 'Create',
  },
  copy: {
    id: 'actions.copy',
    defaultMessage: 'Copy',
  },
  yes: {
    id: 'actions.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'actions.no',
    defaultMessage: 'No',
  },
  pass: {
    id: 'actions.pass',
    defaultMessage: 'Pass',
  },
  fail: {
    id: 'actions.fail',
    defaultMessage: 'Fail',
  },
  clickToCopy: {
    id: 'actions.clickToCopy',
    defaultMessage: 'Click to copy',
  },
  copiedToClipboard: {
    id: 'actions.copiedToClipboard',
    defaultMessage: 'Copied to clipboard',
  },
  clearHistory: {
    id: 'actions.clearHistory',
    defaultMessage: 'Clear History',
  },
});
