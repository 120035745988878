/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EnableCaptionHealthInput = {
    subscriptionId: string;
    clientMutationId?: string | null | undefined;
};
export type EnableCaptionHealthActionMutationVariables = {
    input: EnableCaptionHealthInput;
};
export type EnableCaptionHealthActionMutationResponse = {
    readonly enableCaptionHealth: {
        readonly subscription: {
            readonly id: string;
            readonly featureModuleIds: ReadonlyArray<string | null> | null;
        } | null;
    } | null;
};
export type EnableCaptionHealthActionMutation = {
    readonly response: EnableCaptionHealthActionMutationResponse;
    readonly variables: EnableCaptionHealthActionMutationVariables;
};



/*
mutation EnableCaptionHealthActionMutation(
  $input: EnableCaptionHealthInput!
) {
  enableCaptionHealth(input: $input) {
    subscription {
      id
      featureModuleIds
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EnableCaptionHealthPayload",
    "kind": "LinkedField",
    "name": "enableCaptionHealth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureModuleIds",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnableCaptionHealthActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnableCaptionHealthActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d233738695a0fb05b5f5bfa4b09267ed",
    "id": null,
    "metadata": {},
    "name": "EnableCaptionHealthActionMutation",
    "operationKind": "mutation",
    "text": "mutation EnableCaptionHealthActionMutation(\n  $input: EnableCaptionHealthInput!\n) {\n  enableCaptionHealth(input: $input) {\n    subscription {\n      id\n      featureModuleIds\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '30ef46c770c1a89705dec12b4cefd3c9';
export default node;
