/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipType = "ADMIN" | "GUEST" | "REGULAR" | "%future added value";
export type RoleType = "PUBLIC_ORG" | "SYSTEM_DEFINED" | "USER_DEFINED" | "%future added value";
export type MembershipDetailPage_membership = {
    readonly id: string;
    readonly organizationMembershipId: string | null;
    readonly type: OrganizationMembershipType | null;
    readonly isNurse: boolean | null;
    readonly canFinalize: boolean | null;
    readonly canQa: boolean | null;
    readonly role: {
        readonly name: string | null;
        readonly id: string;
        readonly roleId: string | null;
        readonly roleType: RoleType | null;
    } | null;
    readonly createdAt: string | null;
    readonly deletedAt: string | null;
    readonly organization: {
        readonly organizationId: string | null;
        readonly name: string | null;
        readonly domain: {
            readonly domainId: string | null;
        } | null;
        readonly customUserPermissionsEnabled: boolean | null;
    } | null;
    readonly user: {
        readonly userId: string | null;
        readonly name: string | null;
    } | null;
    readonly referrerToken: string | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateMembershipModal_membership" | "DeleteMembershipAction_membership" | "UndeleteMembershipAction_membership" | "SetMembershipRoleAction_membership">;
    readonly " $refType": "MembershipDetailPage_membership";
};
export type MembershipDetailPage_membership$data = MembershipDetailPage_membership;
export type MembershipDetailPage_membership$key = {
    readonly " $data"?: MembershipDetailPage_membership$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MembershipDetailPage_membership">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipDetailPage_membership",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationMembershipId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNurse",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationId",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Domain",
          "kind": "LinkedField",
          "name": "domain",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "domainId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customUserPermissionsEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referrerToken",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateMembershipModal_membership"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteMembershipAction_membership"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteMembershipAction_membership"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SetMembershipRoleAction_membership"
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
})();
(node as any).hash = '6a6581028e66d770532f5cdd85f4f4cf';
export default node;
