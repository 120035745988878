import Text from '@bfly/ui2/Text';
import React from 'react';

interface DataGridTextProps {
  value: string | number | undefined | null;
}

export default function DataGridText({ value }: DataGridTextProps) {
  return <Text>{value || '-'}</Text>;
}
