/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteOrganizationAction_organization = {
    readonly organizationId: string | null;
    readonly name: string | null;
    readonly deletedAt: string | null;
    readonly " $refType": "DeleteOrganizationAction_organization";
};
export type DeleteOrganizationAction_organization$data = DeleteOrganizationAction_organization;
export type DeleteOrganizationAction_organization$key = {
    readonly " $data"?: DeleteOrganizationAction_organization$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteOrganizationAction_organization">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteOrganizationAction_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
(node as any).hash = '85942e98d98b8271e45048cac4aa2de2';
export default node;
