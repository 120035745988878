/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SetMembershipRoleActionQueryVariables = {};
export type SetMembershipRoleActionQueryResponse = {
    readonly viewer: {
        readonly systemDefinedRoles: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly name: string | null;
                    readonly description: string | null;
                    readonly systemDefinedRoleId: string | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type SetMembershipRoleActionQuery = {
    readonly response: SetMembershipRoleActionQueryResponse;
    readonly variables: SetMembershipRoleActionQueryVariables;
};



/*
query SetMembershipRoleActionQuery {
  viewer {
    systemDefinedRoles(roleType: [PUBLIC_ORG]) {
      edges {
        node {
          name
          description
          systemDefinedRoleId
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "roleType",
    "value": [
      "PUBLIC_ORG"
    ]
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "systemDefinedRoleId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SetMembershipRoleActionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "SystemDefinedRoleConnection",
            "kind": "LinkedField",
            "name": "systemDefinedRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SystemDefinedRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SystemDefinedRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "systemDefinedRoles(roleType:[\"PUBLIC_ORG\"])"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SetMembershipRoleActionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "SystemDefinedRoleConnection",
            "kind": "LinkedField",
            "name": "systemDefinedRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SystemDefinedRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SystemDefinedRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "systemDefinedRoles(roleType:[\"PUBLIC_ORG\"])"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ba4590ee1e52ca857174cad69648017",
    "id": null,
    "metadata": {},
    "name": "SetMembershipRoleActionQuery",
    "operationKind": "query",
    "text": "query SetMembershipRoleActionQuery {\n  viewer {\n    systemDefinedRoles(roleType: [PUBLIC_ORG]) {\n      edges {\n        node {\n          name\n          description\n          systemDefinedRoleId\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '91cee6d5da59add4b32d49895e132e0b';
export default node;
