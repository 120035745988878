import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { object, string } from 'yup';

import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';

import {
  domainIdMessages,
  domainUserIntegrationConfigIdMessages,
  integrationIdMessages,
  interfaceCodeMessages,
  userIdMessages,
} from '../messages/domainUserIntegrationConfigCreation';

export const basicDomainUserIntegrationConfigSchema = object({
  domainUserIntegrationConfigId: string().meta({ readOnly: true }),
  interfaceCode: string().nullable(),
});

export function DomainUserIntegrationConfigModal<
  TMutation extends MutationParameters,
>(props: ResourceModalProps<TMutation>) {
  const isCreate = !props.defaultValue?.domainUserIntegrationConfigId;
  const { formatMessage } = useIntl();
  return (
    <ResourceModal<TMutation> {...props}>
      <Form.FieldGroup
        disabled
        name="domainUserIntegrationConfigId"
        placeholder={formatMessage(
          domainUserIntegrationConfigIdMessages.placeholder,
        )}
        label={
          <FormattedMessage {...domainUserIntegrationConfigIdMessages.label} />
        }
        data-cy="domainUserIntegrationConfigCreate-domainUserIntegrationsConfigsIdField"
      />
      <Form.FieldGroup
        disabled={!isCreate}
        name="domainId"
        placeholder={formatMessage(domainIdMessages.placeholder)}
        label={<FormattedMessage {...domainIdMessages.label} />}
        data-cy="domainUserIntegrationConfigCreate-domainIdField"
      />
      <Form.FieldGroup
        disabled={!isCreate}
        name="userId"
        placeholder={formatMessage(userIdMessages.placeholder)}
        label={<FormattedMessage {...userIdMessages.label} />}
        data-cy="domainUserIntegrationConfigCreate-userIdField"
      />
      <Form.FieldGroup
        disabled={!isCreate}
        name="integrationId"
        placeholder={formatMessage(integrationIdMessages.placeholder)}
        label={<FormattedMessage {...integrationIdMessages.label} />}
        data-cy="domainUserIntegrationConfigCreate-integrationIdField"
      />
      <Form.FieldGroup
        name="interfaceCode"
        placeholder={formatMessage(interfaceCodeMessages.placeholder)}
        label={<FormattedMessage {...interfaceCodeMessages.label} />}
        data-cy="domainUserIntegrationConfigCreate-interfaceCodeField"
      />
    </ResourceModal>
  );
}
