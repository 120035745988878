/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ButterflyDeviceStatus = "ACTIVE" | "BRICKED" | "LOST" | "RECALLED" | "STOLEN" | "%future added value";
export type SetButterflyDeviceStatusInput = {
    productionId: string;
    status: ButterflyDeviceStatus;
    recallId?: string | null | undefined;
    brickedReason?: string | null | undefined;
    lostNote?: string | null | undefined;
    stolenNote?: string | null | undefined;
    activeReason?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type SetProbeStatusActionMutationVariables = {
    input: SetButterflyDeviceStatusInput;
};
export type SetProbeStatusActionMutationResponse = {
    readonly setButterflyDeviceStatus: {
        readonly butterflyDevice: {
            readonly " $fragmentRefs": FragmentRefs<"ButterflyDeviceDetailPage_butterflyDevice">;
        } | null;
    } | null;
};
export type SetProbeStatusActionMutation = {
    readonly response: SetProbeStatusActionMutationResponse;
    readonly variables: SetProbeStatusActionMutationVariables;
};



/*
mutation SetProbeStatusActionMutation(
  $input: SetButterflyDeviceStatusInput!
) {
  setButterflyDeviceStatus(input: $input) {
    butterflyDevice {
      ...ButterflyDeviceDetailPage_butterflyDevice
      id
    }
  }
}

fragment ButterflyDeviceDetailPage_butterflyDevice on ButterflyDevice {
  ...DeregisterAction_butterflyDevice
  ...RequestDiagnosticTestAction_butterflyDevice
  ...DeleteButterflyDeviceAction_butterflyDevice
  ...SetProbeStatusAction_butterflyDevice
  id
  productionId
  registeredAt
  isEnabled
  status
  disabledStatusMessage
  lostAt
  stolenAt
  brickedAt
  brickedReason
  activeReason
  lostNote
  stolenNote
  recallId
  subscription {
    subscriptionId
    id
  }
  hasActiveDiagnosticTestRequest
  lastDiagnosticTestRequestMinTestVersion
}

fragment DeleteButterflyDeviceAction_butterflyDevice on ButterflyDevice {
  id
  productionId
}

fragment DeregisterAction_butterflyDevice on ButterflyDevice {
  id
  productionId
}

fragment RequestDiagnosticTestAction_butterflyDevice on ButterflyDevice {
  id
  productionId
  lastDiagnosticTestRequestMinTestVersion
}

fragment SetProbeStatusAction_butterflyDevice on ButterflyDevice {
  id
  productionId
  recallId
  brickedAt
  brickedReason
  activeReason
  stolenNote
  lostNote
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetProbeStatusActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetButterflyDeviceStatusPayload",
        "kind": "LinkedField",
        "name": "setButterflyDeviceStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDevice",
            "kind": "LinkedField",
            "name": "butterflyDevice",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ButterflyDeviceDetailPage_butterflyDevice"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetProbeStatusActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetButterflyDeviceStatusPayload",
        "kind": "LinkedField",
        "name": "setButterflyDeviceStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDevice",
            "kind": "LinkedField",
            "name": "butterflyDevice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastDiagnosticTestRequestMinTestVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recallId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brickedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brickedReason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activeReason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stolenNote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lostNote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registeredAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disabledStatusMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lostAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stolenAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasActiveDiagnosticTestRequest",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fccd65d7c18e3cd7ead99c3f92ed5991",
    "id": null,
    "metadata": {},
    "name": "SetProbeStatusActionMutation",
    "operationKind": "mutation",
    "text": "mutation SetProbeStatusActionMutation(\n  $input: SetButterflyDeviceStatusInput!\n) {\n  setButterflyDeviceStatus(input: $input) {\n    butterflyDevice {\n      ...ButterflyDeviceDetailPage_butterflyDevice\n      id\n    }\n  }\n}\n\nfragment ButterflyDeviceDetailPage_butterflyDevice on ButterflyDevice {\n  ...DeregisterAction_butterflyDevice\n  ...RequestDiagnosticTestAction_butterflyDevice\n  ...DeleteButterflyDeviceAction_butterflyDevice\n  ...SetProbeStatusAction_butterflyDevice\n  id\n  productionId\n  registeredAt\n  isEnabled\n  status\n  disabledStatusMessage\n  lostAt\n  stolenAt\n  brickedAt\n  brickedReason\n  activeReason\n  lostNote\n  stolenNote\n  recallId\n  subscription {\n    subscriptionId\n    id\n  }\n  hasActiveDiagnosticTestRequest\n  lastDiagnosticTestRequestMinTestVersion\n}\n\nfragment DeleteButterflyDeviceAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n}\n\nfragment DeregisterAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n}\n\nfragment RequestDiagnosticTestAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n  lastDiagnosticTestRequestMinTestVersion\n}\n\nfragment SetProbeStatusAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n  recallId\n  brickedAt\n  brickedReason\n  activeReason\n  stolenNote\n  lostNote\n  status\n}\n"
  }
};
})();
(node as any).hash = '19db4035c55bca9f47eeb762bc8b180a';
export default node;
