import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';

import { DeleteUserDefinedRoleAction_role$key } from './__generated__/DeleteUserDefinedRoleAction_role.graphql';

const fragment = graphql`
  fragment DeleteUserDefinedRoleAction_role on UserDefinedRole {
    id
    userDefinedRoleId
    name
    deletedAt
  }
`;

const mutation = graphql`
  mutation DeleteUserDefinedRoleActionMutation(
    $input: DeleteUserDefinedRoleInput!
  ) {
    deleteUserDefinedRole(input: $input) {
      userDefinedRole {
        userDefinedRoleId
        deletedAt
      }
    }
  }
`;

interface DeleteUserDefinedRoleActionProps {
  roleRef: DeleteUserDefinedRoleAction_role$key;
}

export default function DeleteUserDefinedRoleAction({
  roleRef,
}: DeleteUserDefinedRoleActionProps) {
  const { userDefinedRoleId, name, deletedAt } = useFragment(
    fragment,
    roleRef,
  );

  return (
    <DeleteAction
      itemName={name}
      mutation={mutation}
      redirectTo="/access-roles/user-defined"
      disabled={!!deletedAt}
      input={{ userDefinedRoleId }}
    />
  );
}
