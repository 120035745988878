import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteEmailChangeRequestAction from './DeleteEmailChangeRequestAction';
import { EmailChangeRequestDetailPage_emailChangeRequest$key } from './__generated__/EmailChangeRequestDetailPage_emailChangeRequest.graphql';

const fragment = graphql`
  fragment EmailChangeRequestDetailPage_emailChangeRequest on EmailChangeRequest {
    ...DeleteEmailChangeRequestAction_emailChangeRequest
    emailChangeRequestId
    email
    oldEmail
    createdAt
    expiresAt
    auth0Id
  }
`;

interface EmailChangeRequestDetailPageProps {
  emailChangeRequest: EmailChangeRequestDetailPage_emailChangeRequest$key;
}

export default function EmailChangeRequestDetailPage({
  emailChangeRequest,
}: EmailChangeRequestDetailPageProps) {
  const fragmentData = useFragment(fragment, emailChangeRequest);
  useBreadcrumbDetector(
    pageTitles.emailChangeRequests,
    fragmentData.emailChangeRequestId,
  );
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.EMAIL_CHANGES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.emailChangeRequests} />}
        name={fragmentData.emailChangeRequestId!}
        data={fragmentData}
        relatedItems={
          <RelatedItem
            name="User"
            to={`/users/?email=${fragmentData.oldEmail}`}
          >
            <FormattedMessage
              id="emailChangeRequestDetail.relatedItem.user"
              defaultMessage="Users"
            />
          </RelatedItem>
        }
        crudActions={
          canWrite(Resource.EMAIL_CHANGES) && (
            <DeleteEmailChangeRequestAction
              emailChangeRequestRef={fragmentData}
            />
          )
        }
      />
    </PermissionsGuard>
  );
}
