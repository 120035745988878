/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProcessingId = "D" | "P" | "T" | "%future added value";
export type StudyUrlDisplay = "NONE" | "NORMAL" | "%future added value";
export type UpdateEhrModal_ehr = {
    readonly id: string;
    readonly ehrId: string | null;
    readonly name: string | null;
    readonly connectionId: string | null;
    readonly createdAt: string | null;
    readonly updatedAt: string | null;
    readonly deletedAt: string | null;
    readonly hospitalHost: string | null;
    readonly hospitalPort: number | null;
    readonly timeoutAck: number | null;
    readonly timeoutConnection: number | null;
    readonly timezone: string | null;
    readonly processingId: ProcessingId | null;
    readonly procedureIdPath: string | null;
    readonly receivingApplication: string | null;
    readonly receivingFacility: string | null;
    readonly sendingFacility: string | null;
    readonly characterEncoding: string | null;
    readonly studyDescriptionMapping: Record<string, any> | null;
    readonly studyUrlDisplay: StudyUrlDisplay | null;
    readonly organization: {
        readonly organizationId: string | null;
    } | null;
    readonly domain: {
        readonly domainId: string | null;
    } | null;
    readonly " $refType": "UpdateEhrModal_ehr";
};
export type UpdateEhrModal_ehr$data = UpdateEhrModal_ehr;
export type UpdateEhrModal_ehr$key = {
    readonly " $data"?: UpdateEhrModal_ehr$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateEhrModal_ehr">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateEhrModal_ehr",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ehrId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hospitalHost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hospitalPort",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeoutAck",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeoutConnection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "processingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "procedureIdPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivingApplication",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivingFacility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendingFacility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "characterEncoding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyDescriptionMapping",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyUrlDisplay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ehr",
  "abstractKey": null
};
(node as any).hash = 'a4bb6346166875a7e86e8f542b8ec693';
export default node;
