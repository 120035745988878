/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry = {
    readonly id: string;
    readonly iosVersionBlacklistEntryId: string | null;
    readonly applicationIdentifier: string | null;
    readonly versionRange: string | null;
    readonly buildNumber: string | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry" | "DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry">;
    readonly " $refType": "IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry";
};
export type IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry$data = IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry;
export type IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry$key = {
    readonly " $data"?: IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosVersionBlacklistEntryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildNumber",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry"
    }
  ],
  "type": "IosVersionBlacklistEntry",
  "abstractKey": null
};
(node as any).hash = '85137ff6251a9cb0c153b12fa61b5e86';
export default node;
