import { defineMessages } from 'react-intl';

export const educationCategoryIdMessages = defineMessages({
  label: {
    id: 'educationCategoryModal.educationCategoryId.label',
    defaultMessage: 'Education Category ID',
  },
  placeholder: {
    id: 'educationCategoryModal.educationCategoryId.placeholder',
    defaultMessage: 'Education Category ID',
  },
});

export const educationCategoryNameMessages = defineMessages({
  label: {
    id: 'educationCategoryModal.educationCategoryName.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'educationCategoryModal.educationCategoryName.placeholder',
    defaultMessage: 'Name',
  },
  required: {
    id: 'educationCategoryModal.educationCategoryName.required',
    defaultMessage: 'Please enter a category name',
  },
  description: {
    id: 'educationCategoryModal.educationCategoryName.description',
    defaultMessage: 'Category name',
  },
});

export const educationCategoryOrderingMessages = defineMessages({
  label: {
    id: 'educationCategoryModal.educationCategoryOrdering.label',
    defaultMessage: 'Ordering*',
  },
  placeholder: {
    id: 'educationCategoryModal.educationCategoryOrdering.placeholder',
    defaultMessage: 'Ordering',
  },
  required: {
    id: 'educationCategoryModal.educationCategoryOrdering.required',
    defaultMessage: 'Please enter a ordering',
  },
  description: {
    id: 'educationCategoryModal.educationCategoryOrdering.description',
    defaultMessage: 'Order of the category to be displayed on the UI',
  },
});
