import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import CreateInviteModal from './CreateInviteModal';
import { InvitesPage_viewer$key } from './__generated__/InvitesPage_viewer.graphql';

const resource = Resource.INVITES;

const gridFilterFields: FilterField[] = [
  {
    title: tableHeadings.organizationId.defaultMessage,
    field: 'organizationId',
  },

  { title: tableHeadings.email.defaultMessage, field: 'email' },
  {
    title: tableHeadings.invitesDeleted.defaultMessage,
    field: 'isDeleted',
    isBoolean: true,
  },
];

const fragment = graphql`
  fragment InvitesPage_viewer on Viewer {
    ...CreateInviteModal_viewer
  }
`;

interface InvitesPageProps {
  viewer: InvitesPage_viewer$key;
}

export default function InvitesPage({
  viewer,
  children,
}: PropsWithChildren<InvitesPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.invites);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.invites} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateInviteModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage
            id="InvitesPage.createInvite"
            defaultMessage="Create Invite"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
