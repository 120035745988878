/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type CreateFlatUserInput = {
    domainId?: string | null | undefined;
    email: string;
    password?: string | null | undefined;
    auth0Id?: string | null | undefined;
    name?: string | null | undefined;
    userName?: string | null | undefined;
    phoneNumber?: string | null | undefined;
    specialtyKey?: string | null | undefined;
    specialtyFreeform?: string | null | undefined;
    placeOfWorkId?: string | null | undefined;
    placeOfWorkFreeform?: string | null | undefined;
    domainUserName?: string | null | undefined;
    type?: DomainUserType | null | undefined;
    canQa?: boolean | null | undefined;
    canFinalize?: boolean | null | undefined;
    isNurse?: boolean | null | undefined;
    hasTableauDashboardAccess?: boolean | null | undefined;
    integrationDisplayNameFirst?: string | null | undefined;
    integrationDisplayNameLast?: string | null | undefined;
    integrationDisplayNameMiddle?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateUserModalMutationVariables = {
    input: CreateFlatUserInput;
};
export type CreateUserModalMutationResponse = {
    readonly createFlatUser: {
        readonly flatUserEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"FlatUsersGrid_user">;
            } | null;
        } | null;
    } | null;
};
export type CreateUserModalMutation = {
    readonly response: CreateUserModalMutationResponse;
    readonly variables: CreateUserModalMutationVariables;
};



/*
mutation CreateUserModalMutation(
  $input: CreateFlatUserInput!
) {
  createFlatUser(input: $input) {
    flatUserEdge {
      node {
        id
        ...FlatUsersGrid_user
      }
    }
  }
}

fragment FlatUsersGrid_user on FlatUser {
  id
  flatUserId
  userId
  auth0Id
  name
  email
  hasUnacceptedInvite: hasPendingOrganizationInvite
  specialtyKey
  specialtyFreeform
  phoneNumber
  placeOfWork {
    placeOfWorkId
    name
    id
  }
  placeOfWorkFreeform
  setupAt
  userCreatedAt
  userDeletedAt
  lastReviewRequestedAt
  lastCloudReviewRequestTriggeredAt
  lastImmediateReviewRequestTriggeredAt
  isImplicitPublic
  domainId
  type
  domainUserCreatedAt
  domainUserDeletedAt
  integrationConfigs {
    domainUserIntegrationConfigId
    id
  }
  hasTableauDashboardAccess
  isNurse
  canFinalize
  canQa
  role {
    name
    id
  }
  domain {
    subdomainLabel
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUserModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFlatUserPayload",
        "kind": "LinkedField",
        "name": "createFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUserEdge",
            "kind": "LinkedField",
            "name": "flatUserEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FlatUser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FlatUsersGrid_user"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateUserModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateFlatUserPayload",
        "kind": "LinkedField",
        "name": "createFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUserEdge",
            "kind": "LinkedField",
            "name": "flatUserEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FlatUser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flatUserId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "auth0Id",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": "hasUnacceptedInvite",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPendingOrganizationInvite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "specialtyKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "specialtyFreeform",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlaceOfWork",
                    "kind": "LinkedField",
                    "name": "placeOfWork",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "placeOfWorkId",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "placeOfWorkFreeform",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "setupAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userCreatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userDeletedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastReviewRequestedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastCloudReviewRequestTriggeredAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastImmediateReviewRequestTriggeredAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isImplicitPublic",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainUserCreatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainUserDeletedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUserIntegrationConfig",
                    "kind": "LinkedField",
                    "name": "integrationConfigs",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainUserIntegrationConfigId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasTableauDashboardAccess",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isNurse",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canFinalize",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canQa",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembershipRole",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "domain",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subdomainLabel",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c6c18e0878e529140034e55dd6fad648",
    "id": null,
    "metadata": {},
    "name": "CreateUserModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateUserModalMutation(\n  $input: CreateFlatUserInput!\n) {\n  createFlatUser(input: $input) {\n    flatUserEdge {\n      node {\n        id\n        ...FlatUsersGrid_user\n      }\n    }\n  }\n}\n\nfragment FlatUsersGrid_user on FlatUser {\n  id\n  flatUserId\n  userId\n  auth0Id\n  name\n  email\n  hasUnacceptedInvite: hasPendingOrganizationInvite\n  specialtyKey\n  specialtyFreeform\n  phoneNumber\n  placeOfWork {\n    placeOfWorkId\n    name\n    id\n  }\n  placeOfWorkFreeform\n  setupAt\n  userCreatedAt\n  userDeletedAt\n  lastReviewRequestedAt\n  lastCloudReviewRequestTriggeredAt\n  lastImmediateReviewRequestTriggeredAt\n  isImplicitPublic\n  domainId\n  type\n  domainUserCreatedAt\n  domainUserDeletedAt\n  integrationConfigs {\n    domainUserIntegrationConfigId\n    id\n  }\n  hasTableauDashboardAccess\n  isNurse\n  canFinalize\n  canQa\n  role {\n    name\n    id\n  }\n  domain {\n    subdomainLabel\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c3bed74d3fb296e6cea5ec7d31459f07';
export default node;
