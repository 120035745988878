import { defineMessages } from 'react-intl';

export const emailMessages = defineMessages({
  validation: {
    id: 'emailMessages.email.validation',
    defaultMessage: 'Please enter a valid email',
  },
});

export const osVersionBlacklistEntryIdMessages = defineMessages({
  label: {
    id: 'osVersionBlacklistEntryIdMessages.osVersionBlacklistEntryId.label',
    defaultMessage: '{operationSystem} Version Recall Entry ID',
  },
});

export const applicationIdentifierMessages = defineMessages({
  label: {
    id: 'applicationIdentifierMessages.applicationIdentifier.label',
    defaultMessage: 'Application Identifier*',
  },
  placeholder: {
    id: 'applicationIdentifierMessages.applicationIdentifier.placeholder',
    defaultMessage: 'Application Identifier',
  },
  required: {
    id: 'applicationIdentifierMessages.applicationIdentifier.required',
    defaultMessage: 'Please enter an Application Identifier',
  },
  description: {
    id: 'applicationIdentifierMessages.applicationIdentifier.description',
    defaultMessage: 'The {operationSystem} application identifier',
  },
});

export const versionRangeMessages = defineMessages({
  label: {
    id: 'versionRangeMessages.versionRange.label',
    defaultMessage: 'Version Range*',
  },
  placeholder: {
    id: 'versionRangeMessages.versionRange.placeholder',
    defaultMessage: 'Version Range',
  },
  description: {
    id: 'versionRangeMessages.versionRange.description',
    defaultMessage: 'The semantic version range',
  },
  required: {
    id: 'versionRangeMessages.versionRange.required',
    defaultMessage: 'Please enter a Version Range',
  },
});

export const setCountryMessages = defineMessages({
  label: {
    id: 'setCountryMessages.setCountry.label',
    defaultMessage: 'Set Country',
  },
});

export const practiceTypeMessages = defineMessages({
  label: {
    id: 'practiceType.label',
    defaultMessage: 'Practice Type',
  },
  placeholder: {
    id: 'practiceType.placeholder',
    defaultMessage: 'Practice Type',
  },
  description: {
    id: 'practiceType.description',
    defaultMessage:
      'Whether the Subscription pertains to Organizations practicing human or veterinary medicine',
  },
  requiredLabel: {
    id: 'practiceType.requiredLabel',
    defaultMessage: 'Practice Type*',
  },
  required: {
    id: 'practiceType.required',
    defaultMessage: 'Please set a practice type',
  },
  none: {
    id: 'practiceType.none',
    defaultMessage: 'NONE',
  },
  human: {
    id: 'practiceType.human',
    defaultMessage: 'HUMAN',
  },
  veterinary: {
    id: 'practiceType.veterinary',
    defaultMessage: 'VETERINARY',
  },
});

export const courseLanguageMessages = defineMessages({
  label: {
    id: 'courseLanguage.label',
    defaultMessage: 'Language',
  },
  placeholder: {
    id: 'courseLanguage.placeholder',
    defaultMessage: 'Language',
  },
  description: {
    id: 'courseLanguage.description',
    defaultMessage: '',
  },
  none: {
    id: 'courseLanguage.none',
    defaultMessage: 'NONE',
  },
});

export const formMessages = defineMessages({
  maxLength: {
    id: 'formMessages.maxLength',
    defaultMessage:
      'Organization name exceeds the maximum length of 30 characters.',
  },
  minLength: {
    id: 'formMessages.minLength',
    defaultMessage: 'At least 2 characters required',
  },
});
