function notDefined(msg): Error {
  throw new Error(`not defined: ${msg}`);
}
// @ts-ignore
export const ROUTER_UPSTREAM = process.env.BNI_ROUTER_UPSTREAM;

export const GRAPHQL_UPSTREAM: string | Error =
  // @ts-ignore
  process.env.BNI_GRAPHQL_UPSTREAM || notDefined('BNI_GRAPHQL_UPSTREAM');

export const SUPPORTED_REGIONS = ['us', 'eu'];

export const MSAL_CLIENT_ID: string =
  // @ts-ignore
  process.env.OLYMPUS_MSAL_CLIENT_ID || notDefined('OLYMPUS_MSAL_CLIENT_ID');

export const MSAL_TENANT_ID: string =
  // @ts-ignore
  process.env.OLYMPUS_MSAL_TENANT_ID || notDefined('OLYMPUS_MSAL_TENANT_ID');
