import DialogProvider from '@bfly/ui2/DialogProvider';
import ToastContainer from '@bfly/ui2/ToastContainer';
import ToastProvider from '@bfly/ui2/ToastProvider';
import checkForObtrusiveScrollbars from '@bfly/ui2/utils/checkForObtrusiveScrollbars';
import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import 'intl';
import { IntlProvider } from 'react-intl';
import { RelayEnvironmentProvider } from 'react-relay/hooks';

import './assets/styles/tailwind.css';

import TokenStorage from 'utils/tokenStorage';
import { getStorageItem, removeStorageItem } from 'utils/webStorage';

import azureAuthClient from './azure';
import Router from './components/Router';
import { GRAPHQL_UPSTREAM, ROUTER_UPSTREAM, SUPPORTED_REGIONS } from './env';
import generateEnvironment from './relayEnvironment';

const authTokenStorage = new TokenStorage();

async function getApiUpstream() {
  if (
    !ROUTER_UPSTREAM ||
    // @ts-ignore
    !!window.cypressIntegrationTest
  ) {
    return GRAPHQL_UPSTREAM;
  }

  const region = getStorageItem('region') || 'us';

  if (!SUPPORTED_REGIONS.includes(region)) {
    removeStorageItem('region');
    return GRAPHQL_UPSTREAM;
  }
  try {
    const resp = await fetch(
      new URL(`/regions/${region}`, ROUTER_UPSTREAM).toString(),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (!resp.ok) return GRAPHQL_UPSTREAM;

    const { apiUrl } = await resp.json();

    return apiUrl;
  } catch (err: any) {
    console.error(err);
    return GRAPHQL_UPSTREAM;
  }
}

interface ApplicationProps {
  tokenStorage: TokenStorage;
  upstreamUrl: string;
}

function Application({ tokenStorage, upstreamUrl }: ApplicationProps) {
  const relayEnvironment = useMemo(() => {
    return generateEnvironment(tokenStorage, upstreamUrl);
  }, [tokenStorage, upstreamUrl]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <IntlProvider locale="en">
        <DialogProvider>
          <ToastProvider>
            <Router environment={relayEnvironment} />
            <ToastContainer dismissAfter={5000} />
          </ToastProvider>
        </DialogProvider>
      </IntlProvider>
    </RelayEnvironmentProvider>
  );
}

(async () => {
  checkForObtrusiveScrollbars();

  await azureAuthClient.register(authTokenStorage);

  const root = createRoot(document.getElementById('root')!);

  root.render(
    <Application
      tokenStorage={authTokenStorage}
      upstreamUrl={await getApiUpstream()}
    />,
  );
})();
