import React from 'react';

import { ViewerAdminRole } from './__generated__/PermissionsProvider_viewer.graphql';
import { Permissions, Resource } from './permissions';

export interface PermissionsContextValues {
  viewer: {
    readonly email: string | null;
    readonly adminRoles: ReadonlyArray<ViewerAdminRole>;
  };
  canRead: (resource: Resource) => boolean;
  canWrite: (resource: Resource) => boolean;
  canWriteSome: (...resources: Resource[]) => boolean;
  canReadSome: (...resources: Resource[]) => boolean;
  getPermissions: (resource: Resource) => Permissions;
}

const PermissionsContext =
  React.createContext<PermissionsContextValues | null>(null);

export default PermissionsContext;
