export const BMode = 'b-mode';

export default [
  { label: 'B Mode', value: BMode },
  { label: 'M Mode', value: 'm-mode' },
  { label: 'Color Doppler', value: 'color doppler' },
  { label: 'Power Doppler', value: 'power doppler' },
  { label: 'Pulsed Wave Doppler', value: 'pulsed wave doppler' },
  { label: 'Biplane', value: 'biplane' },
  { label: 'Fetal Heart Sounds', value: 'fetal heart sounds' },
];
