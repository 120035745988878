/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RequestUserReviewInput = {
    userId: string;
    clientMutationId?: string | null | undefined;
};
export type FlatUserDetailPage_RequestUserReviewMutationVariables = {
    input: RequestUserReviewInput;
};
export type FlatUserDetailPage_RequestUserReviewMutationResponse = {
    readonly requestUserReview: {
        readonly __typename: string;
        readonly user: {
            readonly lastImmediateReviewRequestTriggeredAt: string | null;
        } | null;
    } | null;
};
export type FlatUserDetailPage_RequestUserReviewMutation = {
    readonly response: FlatUserDetailPage_RequestUserReviewMutationResponse;
    readonly variables: FlatUserDetailPage_RequestUserReviewMutationVariables;
};



/*
mutation FlatUserDetailPage_RequestUserReviewMutation(
  $input: RequestUserReviewInput!
) {
  requestUserReview(input: $input) {
    __typename
    user {
      lastImmediateReviewRequestTriggeredAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastImmediateReviewRequestTriggeredAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FlatUserDetailPage_RequestUserReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestUserReviewPayload",
        "kind": "LinkedField",
        "name": "requestUserReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FlatUserDetailPage_RequestUserReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestUserReviewPayload",
        "kind": "LinkedField",
        "name": "requestUserReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c02168031c177a97d8438ccceeeb20dd",
    "id": null,
    "metadata": {},
    "name": "FlatUserDetailPage_RequestUserReviewMutation",
    "operationKind": "mutation",
    "text": "mutation FlatUserDetailPage_RequestUserReviewMutation(\n  $input: RequestUserReviewInput!\n) {\n  requestUserReview(input: $input) {\n    __typename\n    user {\n      lastImmediateReviewRequestTriggeredAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd2b37b3e5720ba47a32461b7a26c7cb2';
export default node;
