/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrganizationsPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateOrganizationModal_viewer">;
    readonly " $refType": "OrganizationsPage_viewer";
};
export type OrganizationsPage_viewer$data = OrganizationsPage_viewer;
export type OrganizationsPage_viewer$key = {
    readonly " $data"?: OrganizationsPage_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OrganizationsPage_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationsPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateOrganizationModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'bec5c20333c8e2cbacc0b454cee27c61';
export default node;
