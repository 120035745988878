import Header from '@bfly/ui2/Header';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import domainTableHeadings from '../messages/tableHeadings';

const resource = Resource.DOMAINS;

const gridFilterFields: FilterField[] = [
  { title: tableHeadings.domainId.defaultMessage, field: 'domainId' },
  {
    title: domainTableHeadings.subdomainLabel.defaultMessage,
    field: 'subdomainLabel',
  },
];

interface DomainsPageProps {
  children?: ReactNode;
}

export default function DomainsPage({ children }: DomainsPageProps) {
  useBreadcrumbDetector(pageTitles.domains);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.domains} />
        </Header.Title>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
