/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_QueryVariables = {
    applicationIdentifier: string;
};
export type applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_QueryResponse = {
    readonly applicationVersionRequirement: {
        readonly " $fragmentRefs": FragmentRefs<"ApplicationVersionRequirementDetailPage_applicationVersionRequirement">;
    } | null;
};
export type applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_Query = {
    readonly response: applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_QueryResponse;
    readonly variables: applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_QueryVariables;
};



/*
query applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_Query(
  $applicationIdentifier: String!
) {
  applicationVersionRequirement(applicationIdentifier: $applicationIdentifier) {
    ...ApplicationVersionRequirementDetailPage_applicationVersionRequirement
    id
  }
}

fragment ApplicationVersionRequirementDetailPage_applicationVersionRequirement on ApplicationVersionRequirement {
  ...UpdateApplicationVersionRequirementModal_applicationVersionRequirement
  ...DeleteApplicationVersionRequirementAction_applicationVersionRequirement
  id
  applicationIdentifier
  minimumVersion
}

fragment DeleteApplicationVersionRequirementAction_applicationVersionRequirement on ApplicationVersionRequirement {
  id
  applicationIdentifier
}

fragment UpdateApplicationVersionRequirementModal_applicationVersionRequirement on ApplicationVersionRequirement {
  id
  applicationIdentifier
  minimumVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "applicationIdentifier"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "applicationIdentifier",
    "variableName": "applicationIdentifier"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplicationVersionRequirement",
        "kind": "LinkedField",
        "name": "applicationVersionRequirement",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ApplicationVersionRequirementDetailPage_applicationVersionRequirement"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplicationVersionRequirement",
        "kind": "LinkedField",
        "name": "applicationVersionRequirement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "applicationIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimumVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23f36f588974ddac0fb91a1ec3e144c2",
    "id": null,
    "metadata": {},
    "name": "applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_Query",
    "operationKind": "query",
    "text": "query applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_Query(\n  $applicationIdentifier: String!\n) {\n  applicationVersionRequirement(applicationIdentifier: $applicationIdentifier) {\n    ...ApplicationVersionRequirementDetailPage_applicationVersionRequirement\n    id\n  }\n}\n\nfragment ApplicationVersionRequirementDetailPage_applicationVersionRequirement on ApplicationVersionRequirement {\n  ...UpdateApplicationVersionRequirementModal_applicationVersionRequirement\n  ...DeleteApplicationVersionRequirementAction_applicationVersionRequirement\n  id\n  applicationIdentifier\n  minimumVersion\n}\n\nfragment DeleteApplicationVersionRequirementAction_applicationVersionRequirement on ApplicationVersionRequirement {\n  id\n  applicationIdentifier\n}\n\nfragment UpdateApplicationVersionRequirementModal_applicationVersionRequirement on ApplicationVersionRequirement {\n  id\n  applicationIdentifier\n  minimumVersion\n}\n"
  }
};
})();
(node as any).hash = '77dfc2f4565b08b5735dddcf41100f1f';
export default node;
