/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateButterflyDeviceRecallModal_butterflyDeviceRecall = {
    readonly id: string;
    readonly recallId: string | null;
    readonly message: string | null;
    readonly " $refType": "UpdateButterflyDeviceRecallModal_butterflyDeviceRecall";
};
export type UpdateButterflyDeviceRecallModal_butterflyDeviceRecall$data = UpdateButterflyDeviceRecallModal_butterflyDeviceRecall;
export type UpdateButterflyDeviceRecallModal_butterflyDeviceRecall$key = {
    readonly " $data"?: UpdateButterflyDeviceRecallModal_butterflyDeviceRecall$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateButterflyDeviceRecallModal_butterflyDeviceRecall">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateButterflyDeviceRecallModal_butterflyDeviceRecall",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recallId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "ButterflyDeviceRecall",
  "abstractKey": null
};
(node as any).hash = '8933c7b68a3ffc3dc3d3183443bb9607';
export default node;
