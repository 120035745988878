/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateMdmSettingsModal_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly name: string | null;
    readonly shouldAuthorizeHostDevices: boolean | null;
    readonly hostDeviceSecret: string | null;
    readonly " $refType": "UpdateMdmSettingsModal_domain";
};
export type UpdateMdmSettingsModal_domain$data = UpdateMdmSettingsModal_domain;
export type UpdateMdmSettingsModal_domain$key = {
    readonly " $data"?: UpdateMdmSettingsModal_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateMdmSettingsModal_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateMdmSettingsModal_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldAuthorizeHostDevices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hostDeviceSecret",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = '2d52f875ed8d403780a3a79fdec10eb0';
export default node;
