import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import type { FormProps } from '@bfly/ui2/Form';
import Modal from '@bfly/ui2/Modal';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AnyObjectSchema } from 'yup';

import actionMessages from 'messages/actions';

interface ModalFormProps<
  TSchema extends AnyObjectSchema,
  TValue = Record<string, any>,
> extends FormProps<TSchema, TValue> {
  children: ReactNode;
  title?: ReactNode;
  name?: string;
  submitText?: ReactNode;
  disabled?: boolean;
  modalErrors?: ReactNode;
  loading?: boolean;
  show: boolean;
  onCancel: () => void;
  alertMessage?: ReactNode;
}

export default function ModalForm<
  TSchema extends AnyObjectSchema,
  TValue = Record<string, any>,
>({
  children,
  title,
  name,
  submitText,
  modalErrors,
  loading = false,
  show,
  onCancel,
  alertMessage,
  disabled = false,
  ...formProps
}: ModalFormProps<TSchema, TValue>) {
  return (
    <Modal show={show} onHide={onCancel} data-cy="modal">
      <Form<TSchema> {...formProps}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          {name && (
            <Text
              variant="body-bold"
              color="subtitle"
              data-cy="modal-title-name"
            >
              {name}
            </Text>
          )}
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
          {children}
        </Modal.Body>
        <Modal.Footer className="flex-col items-stretch">
          {!!modalErrors && (
            <Section
              data-cy="modal-error"
              className="rounded mb-4 px-4 py-2 text-danger bg-danger bg-opacity-10 border border-solid border-danger border-opacity-10 relative overflow-y-auto max-h-48"
            >
              {modalErrors}
            </Section>
          )}
          <Modal.ButtonGroup>
            {submitText && (
              <Form.Submit
                busy={loading}
                disabled={disabled}
                size="lg"
                variant="success"
                data-cy="modal-submitButton"
              >
                {submitText}
              </Form.Submit>
            )}
            <Button
              size="lg"
              variant="secondary"
              onClick={onCancel}
              data-cy="modal-cancelButton"
            >
              <FormattedMessage {...actionMessages.cancel} />
            </Button>
          </Modal.ButtonGroup>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
