import {
  AccountInfo,
  AuthenticationResult as BaseAuthenticationResult,
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';

import TokenStorage from 'utils/tokenStorage';

import { MSAL_CLIENT_ID, MSAL_TENANT_ID } from './env';

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${MSAL_TENANT_ID}`,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

type AuthenticationResult = Omit<BaseAuthenticationResult, 'idTokenClaims'> & {
  idTokenClaims: { exp: number };
};

export class AzureAuthClient {
  private client: PublicClientApplication;

  private expirationTimer?: number;

  private tokenStorage?: TokenStorage;

  private account: AccountInfo | null;

  constructor() {
    this.client = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;
  }

  async register(tokenStorage: TokenStorage) {
    this.tokenStorage = tokenStorage;
    const response =
      (await this.client.handleRedirectPromise()) as AuthenticationResult;
    if (response !== null) {
      this.account = response.account;
      this.updateToken(response);
    } else {
      await this.login();
    }

    // listening for tab reactivation to check token, setInterval doesn't work properly in inactive tab due to timer throttling
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        if (this.tokenStorage!.isTokenExpired()) {
          this.login();
        }
      }
    });
  }

  setTokenRefreshTimer(expiresOn: Date) {
    const timeLeft = expiresOn.getTime() - Date.now();

    clearTimeout(this.expirationTimer);

    this.expirationTimer = setTimeout(async () => {
      await this.login();
    }, timeLeft);
  }

  private getAccount(): AccountInfo | null {
    const currentAccounts = this.client.getAllAccounts();

    if (currentAccounts === null) {
      return null;
    }

    if (currentAccounts.length) {
      return currentAccounts[0];
    }

    return null;
  }

  async login() {
    const token = await this.acquireToken();
    if (token) {
      this.account = this.getAccount();
      this.updateToken(token);
    } else {
      await this.client.loginRedirect();
    }
  }

  async logout() {
    let account: AccountInfo | undefined;
    if (this.account) {
      account = this.account;
    }

    await this.client.logoutRedirect({
      account,
    });
  }

  async acquireToken(): Promise<AuthenticationResult | null> {
    const scopes = ['openid', 'profile'];

    try {
      const account = this.getAccount();
      if (account) {
        return (await this.client.acquireTokenSilent({
          account,
          scopes,
          // @ts-ignore
          forceRefresh: !window.cypressIntegrationTest,
        })) as AuthenticationResult;
      }
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        await this.client.acquireTokenRedirect({
          scopes,
        });
      }
    }

    return null;
  }

  updateToken(
    token: Omit<AuthenticationResult, 'idTokenClaims'> & {
      idTokenClaims: { exp: number };
    },
  ) {
    const expiresOn = token?.idTokenClaims?.exp
      ? new Date(token.idTokenClaims.exp * 1000)
      : null;

    this.tokenStorage!.setToken(token.idToken, expiresOn);

    if (expiresOn) {
      this.setTokenRefreshTimer(expiresOn);
    }
  }
}

const azureAuthClient = new AzureAuthClient();

export default azureAuthClient;
