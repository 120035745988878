/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainInviteDetailPage_userInvite = {
    readonly id: string;
    readonly userInviteId: string | null;
    readonly createdAt: string | null;
    readonly usedAt: string | null;
    readonly user: {
        readonly userId: string | null;
        readonly email: string | null;
    } | null;
    readonly domain: {
        readonly domainId: string | null;
        readonly name: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ResendUserInviteAction_userInvite" | "ShowUserInviteLinkAction_userInvite">;
    readonly " $refType": "DomainInviteDetailPage_userInvite";
};
export type DomainInviteDetailPage_userInvite$data = DomainInviteDetailPage_userInvite;
export type DomainInviteDetailPage_userInvite$key = {
    readonly " $data"?: DomainInviteDetailPage_userInvite$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DomainInviteDetailPage_userInvite">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainInviteDetailPage_userInvite",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userInviteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResendUserInviteAction_userInvite"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShowUserInviteLinkAction_userInvite"
    }
  ],
  "type": "UserInvite",
  "abstractKey": null
};
(node as any).hash = 'f68b9f8071b17c55eb9e89cbc67525ce';
export default node;
