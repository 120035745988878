/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProvisionOrganizationForSubscriptionAction_subscription = {
    readonly id: string;
    readonly subscriptionId: string | null;
    readonly " $refType": "ProvisionOrganizationForSubscriptionAction_subscription";
};
export type ProvisionOrganizationForSubscriptionAction_subscription$data = ProvisionOrganizationForSubscriptionAction_subscription;
export type ProvisionOrganizationForSubscriptionAction_subscription$key = {
    readonly " $data"?: ProvisionOrganizationForSubscriptionAction_subscription$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProvisionOrganizationForSubscriptionAction_subscription">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProvisionOrganizationForSubscriptionAction_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionId",
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};
(node as any).hash = '56503a98e6b00cd7f1950aed3be4a3f7';
export default node;
