import { defineMessages } from 'react-intl';

export const deactivateMessages = defineMessages({
  actionFailed: {
    id: 'deactivateAction.error',
    defaultMessage: 'Failed to deactivate {name}.',
  },
  deactiveLastAdminFailed: {
    id: 'deactivateAction.error.lastAdmin',
    defaultMessage:
      "Can't deactivate, user is the last member of one of their organizations",
  },
  actionSuccessful: {
    id: 'deactivateAction.success',
    defaultMessage: '{name} was successfully deactivated.',
  },
  confirmationBody: {
    id: 'deactivateAction.dialog.body',
    defaultMessage: '{name} will be deactivated.',
  },
  confirmationTitle: {
    id: 'deactivateAction.dialog.title',
    defaultMessage: 'Are you sure you want to deactivate {name}?',
  },
});

export const deleteMessages = defineMessages({
  actionFailed: {
    id: 'deleteAction.error',
    defaultMessage: 'Failed to delete {name}.',
  },
  actionSuccessful: {
    id: 'deleteAction.success',
    defaultMessage: '{name} was successfully deleted.',
  },
  confirmationBody: {
    id: 'deleteAction.dialog.body',
    defaultMessage: '{name} will be deleted.',
  },
  confirmationTitle: {
    id: 'deleteAction.dialog.title',
    defaultMessage: 'Are you sure you want to delete {name}?',
  },
  actionMessage: {
    id: 'deleteAction.button',
    defaultMessage: 'Delete',
  },
});
