/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type users_UsersPage_QueryVariables = {};
export type users_UsersPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"FlatUsersPage_viewer">;
    } | null;
};
export type users_UsersPage_Query = {
    readonly response: users_UsersPage_QueryResponse;
    readonly variables: users_UsersPage_QueryVariables;
};



/*
query users_UsersPage_Query {
  viewer {
    ...FlatUsersPage_viewer
    id
  }
}

fragment CreateUserModal_viewer on Viewer {
  id
}

fragment FlatUsersPage_viewer on Viewer {
  ...CreateUserModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "users_UsersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FlatUsersPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "users_UsersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87c3c4893acf6b84317f9160fcb4b236",
    "id": null,
    "metadata": {},
    "name": "users_UsersPage_Query",
    "operationKind": "query",
    "text": "query users_UsersPage_Query {\n  viewer {\n    ...FlatUsersPage_viewer\n    id\n  }\n}\n\nfragment CreateUserModal_viewer on Viewer {\n  id\n}\n\nfragment FlatUsersPage_viewer on Viewer {\n  ...CreateUserModal_viewer\n}\n"
  }
};
(node as any).hash = 'c0fa460abc0260fc442bb3f82c421dbd';
export default node;
