import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { EhrModal, basicEhrSchema } from './EhrModal';
import { CreateEhrModalMutation } from './__generated__/CreateEhrModalMutation.graphql';
import { CreateEhrModal_viewer$key } from './__generated__/CreateEhrModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateEhrModalMutation($input: CreateEhrInput!) {
    createEhr(input: $input) {
      ehrEdge {
        node {
          id
          ...EhrsGrid_ehr
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateEhrModal_viewer on Viewer {
    id
  }
`;

interface CreateEhrModalProps extends ActionModalProps {
  viewerRef: CreateEhrModal_viewer$key;
}

export function CreateEhrModal({ viewerRef, ...props }: CreateEhrModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'EhrsGrid_ehrs',
        rootFieldName: 'createEhr',
        edgeName: 'ehrEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <EhrModal<CreateEhrModalMutation>
      {...props}
      title={
        <FormattedMessage
          id="createEhrModal.title"
          defaultMessage="Create EHR"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={basicEhrSchema}
      mutation={createMutation}
      updater={updater}
    />
  );
}
