import Button from '@bfly/ui2/Button';
import { useToast } from '@bfly/ui2/ToastContext';
import useDialog from '@bfly/ui2/useDialog';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GraphQLTaggedNode, useMutation } from 'react-relay';
import { MutationParameters, SelectorStoreUpdater } from 'relay-runtime';

import { Resource } from 'components/ResourceDetail';
import actionMessages from 'messages/actions';

interface UndeleteActionProps<TMutation extends MutationParameters> {
  itemName: string;
  disabled?: boolean;
  redirectTo?: string;
  mutation: GraphQLTaggedNode;
  input: Resource;
  updater?: SelectorStoreUpdater<TMutation['response']> | null | undefined;
}

export default function UndeleteAction<TMutation extends MutationParameters>({
  mutation,
  redirectTo,
  itemName: name,
  input,
  disabled = false,
  updater,
}: UndeleteActionProps<TMutation>) {
  const { router } = useRouter();
  const dialog = useDialog();
  const toast = useToast();
  const [mutate] = useMutation(mutation);

  const handleUndelete = async () => {
    await mutate({
      variables: {
        input,
      },
      updater,
      onError: () => {
        toast!.error(
          <FormattedMessage
            id="undeleteAction.error"
            defaultMessage="Failed to activate {name}."
            values={{ name }}
          />,
        );
      },
      onCompleted: () => {
        toast!.success(
          <FormattedMessage
            id="undeleteAction.success"
            defaultMessage="{name} was successfully activated."
            values={{ name }}
          />,
        );
        if (redirectTo) router.replace(redirectTo);
      },
    });
  };

  const handleDeleteClick = () => {
    return dialog.open(
      <FormattedMessage
        id="undeleteAction.dialog.content"
        defaultMessage="{name} will be activated."
        values={{ name }}
      />,
      {
        runConfirm: () => handleUndelete(),
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        title: (
          <FormattedMessage
            id="undeleteAction.dialog.title"
            defaultMessage="Are you sure you want to activate {name}?"
            values={{ name }}
          />
        ),
        confirmLabel: <FormattedMessage {...actionMessages.activate} />,
      },
    );
  };

  return (
    <Button
      variant="danger"
      data-cy="undelete"
      onClick={handleDeleteClick}
      disabled={disabled}
    >
      <FormattedMessage {...actionMessages.activate} />
    </Button>
  );
}
