import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { number, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import {
  applicationIdentifierMessages,
  osVersionBlacklistEntryIdMessages,
  versionRangeMessages,
} from 'messages/formMessages';
import applicationIdentifies from 'utils/applicationIdentifies';

import { versionCodeMessages } from '../messages/androidVersionBlacklistEntryModal';

const OPERATION_SYSTEM = 'Android';

export const androidVersionBlacklistEntrySchema = object({
  applicationIdentifier: string()
    .required(applicationIdentifierMessages.required)
    .oneOf(applicationIdentifies),
  versionRange: string().required(versionRangeMessages.required),
  versionCode: number().integer().nullable(),
});

export function AndroidVersionBlacklistEntryModal<
  TMutation extends MutationParameters,
>({ defaultValue, ...props }: ResourceModalProps<TMutation>) {
  const isCreate = !defaultValue?.androidVersionBlacklistEntryId;
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation> {...props} defaultValue={defaultValue}>
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="androidVersionBlacklistEntryId"
          label={
            <FormattedMessage
              {...osVersionBlacklistEntryIdMessages.label}
              values={{ operationSystem: OPERATION_SYSTEM }}
            />
          }
          data-cy="androidVersionBlacklistEntry-androidVersionBlacklistEntryIdField"
        />
      )}
      <Form.FieldGroup
        as={DropdownList}
        data={applicationIdentifies}
        name="applicationIdentifier"
        label={<FormattedMessage {...applicationIdentifierMessages.label} />}
        placeholder={applicationIdentifierMessages.placeholder}
        description={
          <FormattedMessage
            {...applicationIdentifierMessages.description}
            values={{ operationSystem: OPERATION_SYSTEM }}
          />
        }
        data-cy="androidVersionBlacklistEntry-applicationIdentifierField"
      />
      <Form.FieldGroup
        name="versionRange"
        label={<FormattedMessage {...versionRangeMessages.label} />}
        placeholder={formatMessage(versionRangeMessages.placeholder)}
        description={
          <FormattedMessage {...versionRangeMessages.description} />
        }
        data-cy="androidVersionBlacklistEntry-versionRangeField"
      />
      <Form.FieldGroup
        name="versionCode"
        label={<FormattedMessage {...versionCodeMessages.label} />}
        placeholder={formatMessage(versionCodeMessages.placeholder)}
        description={<FormattedMessage {...versionCodeMessages.description} />}
        data-cy="androidVersionBlacklistEntry-versionCodeField"
      />
    </ResourceModal>
  );
}
