/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type butterflyDevices_ButterflyDevicesPage_QueryVariables = {};
export type butterflyDevices_ButterflyDevicesPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ButterflyDevicesPage_viewer">;
    } | null;
};
export type butterflyDevices_ButterflyDevicesPage_Query = {
    readonly response: butterflyDevices_ButterflyDevicesPage_QueryResponse;
    readonly variables: butterflyDevices_ButterflyDevicesPage_QueryVariables;
};



/*
query butterflyDevices_ButterflyDevicesPage_Query {
  viewer {
    ...ButterflyDevicesPage_viewer
    id
  }
}

fragment ButterflyDevicesPage_viewer on Viewer {
  ...CreateButterflyDeviceModal_viewer
}

fragment CreateButterflyDeviceModal_viewer on Viewer {
  id
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "butterflyDevices_ButterflyDevicesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ButterflyDevicesPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "butterflyDevices_ButterflyDevicesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "18bb2079ea0be11f26a66e527021a011",
    "id": null,
    "metadata": {},
    "name": "butterflyDevices_ButterflyDevicesPage_Query",
    "operationKind": "query",
    "text": "query butterflyDevices_ButterflyDevicesPage_Query {\n  viewer {\n    ...ButterflyDevicesPage_viewer\n    id\n  }\n}\n\nfragment ButterflyDevicesPage_viewer on Viewer {\n  ...CreateButterflyDeviceModal_viewer\n}\n\nfragment CreateButterflyDeviceModal_viewer on Viewer {\n  id\n}\n"
  }
};
(node as any).hash = '7606d82c486502b2b00252b2f651706e';
export default node;
