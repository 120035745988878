import { defineMessages } from 'react-intl';

export default defineMessages({
  resetHostDeviceSecret: {
    id: 'mdmSettings.action.resetHostDeviceSecret',
    defaultMessage: 'Reset Secret',
  },
  updateMdmSettings: {
    id: 'mdmSettings.modal.updateMdmSettings',
    defaultMessage: 'Update Domain MDM Settings',
  },
});

export const resetHostDeviceSecretMessages = defineMessages({
  note: {
    id: 'resetHostDeviceSecretAction.note',
    defaultMessage:
      'NOTE: You will need to re-provision all of your devices with an updated secret after resetting the secret.',
  },
});
