import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  ApplicationVersionRequirementModal,
  basicApplicationVersionRequirementSchema,
} from './ApplicationVersionRequirementModal';
import { UpdateApplicationVersionRequirementModalMutation } from './__generated__/UpdateApplicationVersionRequirementModalMutation.graphql';
import { UpdateApplicationVersionRequirementModal_applicationVersionRequirement$key } from './__generated__/UpdateApplicationVersionRequirementModal_applicationVersionRequirement.graphql';

const fragment = graphql`
  fragment UpdateApplicationVersionRequirementModal_applicationVersionRequirement on ApplicationVersionRequirement {
    id
    applicationIdentifier
    minimumVersion
  }
`;

const updateMutation = graphql`
  mutation UpdateApplicationVersionRequirementModalMutation(
    $input: SetApplicationVersionRequirementInput!
  ) {
    setApplicationVersionRequirement(input: $input) {
      applicationVersionRequirement {
        id
        applicationIdentifier
        minimumVersion
      }
    }
  }
`;

const updateApplicationVersionRequirementSchema =
  basicApplicationVersionRequirementSchema.shape({
    applicationIdentifier: string().meta({
      readOnly: true,
    }),
  });

interface UpdateApplicationVersionRequirementModalProps
  extends ActionModalProps {
  applicationVersionRequirementRef: UpdateApplicationVersionRequirementModal_applicationVersionRequirement$key;
}

export function UpdateApplicationVersionRequirementModal({
  show,
  onClose,
  applicationVersionRequirementRef,
}: UpdateApplicationVersionRequirementModalProps) {
  const applicationVersionRequirementCategory = useFragment(
    fragment,
    applicationVersionRequirementRef,
  );

  return (
    <ApplicationVersionRequirementModal<UpdateApplicationVersionRequirementModalMutation>
      title={
        <FormattedMessage
          id="updateApplicationVersionRequirementModal.title"
          defaultMessage="Update Application Version Requirement"
        />
      }
      name={applicationVersionRequirementCategory.applicationIdentifier!}
      submitText={<FormattedMessage {...actionMessages.create} />}
      show={show}
      onClose={onClose}
      mutation={updateMutation}
      schema={updateApplicationVersionRequirementSchema}
      defaultValue={applicationVersionRequirementCategory}
    />
  );
}
