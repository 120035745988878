import Badge from '@bfly/ui2/Badge';
import React from 'react';

const valueToYesNo = {
  0: 'No',
  50: 'Yes',
};
const valueToNoneReadWrite = {
  0: 'None',
  50: 'Read',
  100: 'Write',
};

const PERMISSION_BUCKETS = {
  1: {
    name: 'ACCESS_ALL_ORGANIZATIONS',
    valueMapper: valueToYesNo,
  },
  2: {
    name: 'ARCHIVE_MANAGEMENT',
    valueMapper: valueToYesNo,
  },
  3: {
    name: 'CONNECTIVITY_MANAGEMENT',
    valueMapper: valueToNoneReadWrite,
  },
  4: {
    name: 'CREDENTIAL_MANAGEMENT',
    valueMapper: valueToYesNo,
  },
  5: {
    name: 'DATA_DELETION',
    valueMapper: valueToYesNo,
  },
  6: {
    name: 'DATA_EXPORT',
    valueMapper: valueToYesNo,
  },
  7: {
    name: 'BULK_DATA_EXPORT',
    valueMapper: valueToYesNo,
  },
  8: {
    name: 'EDUCATION_MANAGEMENT',
    valueMapper: valueToYesNo,
  },
  9: {
    name: 'EXAM_TYPE_MANAGEMENT',
    valueMapper: valueToYesNo,
  },
  10: {
    name: 'SIGNING',
    valueMapper: valueToYesNo,
  },
  11: {
    name: 'FLEET_MANAGEMENT',
    valueMapper: valueToNoneReadWrite,
  },
  12: {
    name: 'IQ_CARE_RESTRICTED_EXPERIENCE',
    valueMapper: valueToYesNo,
  },
  13: {
    name: 'LOGIN_AND_SECURITY_MANAGEMENT',
    valueMapper: valueToNoneReadWrite,
  },
  14: {
    name: 'ORGANIZATION_MANAGEMENT',
    valueMapper: valueToYesNo,
  },
  15: {
    name: 'QA',
    valueMapper: valueToYesNo,
  },
  16: {
    name: 'QA_MANAGEMENT',
    valueMapper: valueToYesNo,
  },
  17: {
    name: 'STUDY_DOCUMENTATION',
    valueMapper: valueToYesNo,
  },
  18: {
    name: 'STUDY_DOCUMENTATION_MANAGEMENT',
    valueMapper: valueToNoneReadWrite,
  },
  19: {
    name: 'USER_MANAGEMENT',
    valueMapper: valueToNoneReadWrite,
  },
  20: {
    name: 'USER_PERMISSIONS',
    valueMapper: valueToNoneReadWrite,
  },
  21: {
    name: 'STUDY_REVERSION',
    valueMapper: valueToYesNo,
  },
};

interface RolePermissionsProps {
  permissions: [
    bucketId: keyof typeof PERMISSION_BUCKETS,
    value: keyof typeof valueToYesNo | keyof typeof valueToNoneReadWrite,
  ][];
}

export default function RolePermissions({
  permissions,
}: RolePermissionsProps) {
  return (
    <table data-cy="role-permissions">
      <tbody>
        {permissions.map(([bucketId, value]) => (
          <tr key={bucketId} data-cy="role-permission">
            <td>{PERMISSION_BUCKETS[bucketId].name}</td>
            <td>
              <Badge>{PERMISSION_BUCKETS[bucketId].valueMapper[value]}</Badge>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
