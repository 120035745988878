/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateStudyExportAction_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly " $refType": "CreateStudyExportAction_domain";
};
export type CreateStudyExportAction_domain$data = CreateStudyExportAction_domain;
export type CreateStudyExportAction_domain$key = {
    readonly " $data"?: CreateStudyExportAction_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CreateStudyExportAction_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateStudyExportAction_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = 'de06d544bd7c1c79bfb408ab72b72acf';
export default node;
