import getNodes from '@bfly/utils/getNodes';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridBoolean from 'components/DataGridBoolean';
import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';
import {
  hasButterflyAccessRoles,
  hasTableColumn,
} from 'utils/accessRolesUtils';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import membershipsTableHeadings from '../messages/tableHeadings';
import { MembershipsGrid_membership as Membership } from './__generated__/MembershipsGrid_membership.graphql';
import { MembershipsGrid_viewer$key } from './__generated__/MembershipsGrid_viewer.graphql';

const _ = graphql`
  fragment MembershipsGrid_membership on OrganizationMembership {
    id
    organizationMembershipId
    type
    createdAt
    deletedAt
    organization {
      organizationId
      specialType
      name
      domain {
        domainId
      }
    }
    user {
      userId
      name
      email
    }
    referrerToken
    isNurse
    canFinalize
    canQa
    role {
      name
    }
  }
`;

const fragment = graphql`
  fragment MembershipsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    organizationId: { type: "[String!]" }
    userId: { type: "[String!]" }
    isDeleted: { type: "[String!]" }
  )
  @refetchable(queryName: "MembershipsGridRefetchQuery") {
    organizationMemberships(
      first: $count
      after: $cursor
      organizationId: $organizationId
      userId: $userId
      isDeleted: $isDeleted
    ) @connection(key: "MembershipsGrid_organizationMemberships") {
      edges {
        node {
          ...MembershipsGrid_membership @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Membership>[] = [
  {
    key: 'userName',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.userName} />,
    render: ({ item }) => (
      <QuickCopyText text={item.user!.name}>
        <DataGridLink
          path="/memberships"
          id={item.organizationMembershipId}
          title={item.user!.name!}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'organizationMembershipId',
    label: (
      <FormattedMessage
        {...membershipsTableHeadings.organizationMembershipId}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.organizationMembershipId}>
        <DataGridLink path="/memberships" id={item.organizationMembershipId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'userId',
    label: <FormattedMessage {...tableHeadings.userId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.user?.userId}>
        <DataGridLink
          path="/users"
          title={item.user!.userId!}
          id={`${item.user?.userId}/${
            item.organization?.domain?.domainId ?? '-'
          }`}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'email',
    label: <FormattedMessage {...tableHeadings.email} />,
    render: ({ item }) => (
      <QuickCopyText text={item.user?.email}>
        <DataGridText value={item.user?.email} />
      </QuickCopyText>
    ),
  },

  {
    key: 'type',
    label: <FormattedMessage {...tableHeadings.type} />,
    render: ({ item }) => <DataGridText value={item.type} />,
  },
  {
    key: 'isNurse',
    label: <FormattedMessage {...tableHeadings.isNurse} />,
    render: ({ item }) => <DataGridBoolean value={item.isNurse} />,
  },
  {
    key: 'canFinalize',
    label: <FormattedMessage {...tableHeadings.canFinalize} />,
    render: ({ item }) => <DataGridBoolean value={item.canFinalize} />,
  },
  {
    key: 'canQa',
    label: <FormattedMessage {...tableHeadings.canQA} />,
    render: ({ item }) => <DataGridBoolean value={item.canQa} />,
  },
  {
    key: 'createdAt',
    label: <FormattedMessage {...tableHeadings.createdAt} />,
    render: ({ item }) => <DataGridDateTime value={item.createdAt} />,
  },
  {
    key: 'deletedAt',
    label: <FormattedMessage {...tableHeadings.deletedAt} />,
    render: ({ item }) => <DataGridDateTime value={item.deletedAt} />,
  },
  {
    key: 'organizationId',
    label: <FormattedMessage {...tableHeadings.organizationId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organization?.organizationId}>
        <DataGridLink
          path="/organizations"
          id={item.organization?.organizationId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'organizationName',
    label: <FormattedMessage {...tableHeadings.organizationName} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organization?.name}>
        <DataGridText value={item.organization?.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'organizationSpecialType',
    label: <FormattedMessage {...tableHeadings.organizationSpecialType} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organization?.specialType}>
        <DataGridText value={item.organization?.specialType} />
      </QuickCopyText>
    ),
  },
  {
    key: 'referrerToken',
    label: <FormattedMessage {...membershipsTableHeadings.referrerToken} />,
    render: ({ item }) => (
      <QuickCopyText text={item.referrerToken}>
        <DataGridText value={item.referrerToken} />
      </QuickCopyText>
    ),
  },
];

const accessRoleColumn = {
  key: 'accessRole',
  label: <FormattedMessage {...tableHeadings.accessRole} />,
  render: ({ item }) => <DataGridText value={item.role?.name} />,
};

interface MembershipsGridProps {
  viewer: MembershipsGrid_viewer$key;
}

export default function MembershipsGrid({ viewer }: MembershipsGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.organizationMemberships);
  useBreadcrumbDetector(pageTitles.memberships);

  const { viewer: viewerWithAdminRoles } = usePermissionsContext();

  const spec = useMemo(() => {
    const result = [...tableSpec];

    if (
      hasButterflyAccessRoles(viewerWithAdminRoles) &&
      !hasTableColumn(tableSpec, accessRoleColumn.key)
    ) {
      result.push(accessRoleColumn);
    }

    return result;
  }, [viewerWithAdminRoles]);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={spec}
      getRowProps={({ deletedAt }) => ({
        classNames: deletedAt ? deletedClass : '',
      })}
      scrollKey="data-grid"
    />
  );
}
