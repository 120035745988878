/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type diagnosticTestResults_DiagnosticTestResultDetailPage_QueryVariables = {
    diagnosticTestResultId: string;
};
export type diagnosticTestResults_DiagnosticTestResultDetailPage_QueryResponse = {
    readonly diagnosticTestResult: {
        readonly " $fragmentRefs": FragmentRefs<"DiagnosticTestResultDetailPage_diagnosticTestResult">;
    } | null;
};
export type diagnosticTestResults_DiagnosticTestResultDetailPage_Query = {
    readonly response: diagnosticTestResults_DiagnosticTestResultDetailPage_QueryResponse;
    readonly variables: diagnosticTestResults_DiagnosticTestResultDetailPage_QueryVariables;
};



/*
query diagnosticTestResults_DiagnosticTestResultDetailPage_Query(
  $diagnosticTestResultId: String!
) {
  diagnosticTestResult(diagnosticTestResultId: $diagnosticTestResultId) {
    ...DiagnosticTestResultDetailPage_diagnosticTestResult
    id
  }
}

fragment DiagnosticTestResultDetailPage_diagnosticTestResult on DiagnosticTestResult {
  id
  diagnosticTestResultId
  deviceProductionId
  createdAt
  testVersion
  results
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "diagnosticTestResultId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "diagnosticTestResultId",
    "variableName": "diagnosticTestResultId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "diagnosticTestResults_DiagnosticTestResultDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DiagnosticTestResult",
        "kind": "LinkedField",
        "name": "diagnosticTestResult",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DiagnosticTestResultDetailPage_diagnosticTestResult"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "diagnosticTestResults_DiagnosticTestResultDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DiagnosticTestResult",
        "kind": "LinkedField",
        "name": "diagnosticTestResult",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "diagnosticTestResultId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deviceProductionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "testVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "results",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "957031b9bae51e42384c41d80025ea25",
    "id": null,
    "metadata": {},
    "name": "diagnosticTestResults_DiagnosticTestResultDetailPage_Query",
    "operationKind": "query",
    "text": "query diagnosticTestResults_DiagnosticTestResultDetailPage_Query(\n  $diagnosticTestResultId: String!\n) {\n  diagnosticTestResult(diagnosticTestResultId: $diagnosticTestResultId) {\n    ...DiagnosticTestResultDetailPage_diagnosticTestResult\n    id\n  }\n}\n\nfragment DiagnosticTestResultDetailPage_diagnosticTestResult on DiagnosticTestResult {\n  id\n  diagnosticTestResultId\n  deviceProductionId\n  createdAt\n  testVersion\n  results\n}\n"
  }
};
})();
(node as any).hash = 'a3f36694072fc921bd0f87eb90a9833e';
export default node;
