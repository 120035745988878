import { css } from 'astroturf/react';

export const deletedClass = css`
  @import '~@bfly/ui/styles/theme';
  //mix-blend-mode: luminosity;
  color: rgba($text-color, 0.6);
  a {
    color: rgba($link-dark-color, 0.6);
  }
`;
