import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';

import { DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig$key } from './__generated__/DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig.graphql';

const fragment = graphql`
  fragment DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig on DomainUserIntegrationConfig {
    id
    domainUserIntegrationConfigId
    domainUser {
      name
    }
  }
`;

const mutation = graphql`
  mutation DeleteDomainUserIntegrationConfigActionMutation(
    $input: DeleteDomainUserIntegrationConfigInput!
  ) {
    deleteDomainUserIntegrationConfig(input: $input) {
      deletedId
    }
  }
`;

interface DeleteDomainUserIntegrationConfigActionProps {
  domainUserIntegrationConfigRef: DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig$key;
}

export default function DeleteDomainUserIntegrationConfigAction({
  domainUserIntegrationConfigRef,
}: DeleteDomainUserIntegrationConfigActionProps) {
  const domainUserIntegrationConfig = useFragment(
    fragment,
    domainUserIntegrationConfigRef,
  );

  return (
    <DeleteAction
      itemName={
        `Integration Config for ${
          domainUserIntegrationConfig.domainUser!.name
        }` ?? 'Domain User Integration Config'
      }
      mutation={mutation}
      redirectTo="/domain-user-integrations-configs"
      input={{
        domainUserIntegrationConfigId:
          domainUserIntegrationConfig.domainUserIntegrationConfigId,
      }}
    />
  );
}
