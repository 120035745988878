import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import { formMessages } from 'messages/formMessages';

import countryActionMessages from '../messages/countryActionMessages';
import { countryNameMessages } from '../messages/countryCreation';
import CountryModal from './CountryModal';
import { UpdateCountryModalMutation } from './__generated__/UpdateCountryModalMutation.graphql';
import { UpdateCountryModal_country$key } from './__generated__/UpdateCountryModal_country.graphql';

const fragment = graphql`
  fragment UpdateCountryModal_country on Country {
    id
    code
    name
    enabled
  }
`;

const updateMutation = graphql`
  mutation UpdateCountryModalMutation($input: UpdateCountryInput!) {
    updateCountry(input: $input) {
      country {
        ...CountriesGrid_country
      }
    }
  }
`;

const updateCountrySchema = object({
  code: string(),
  name: string()
    .required(countryNameMessages.required)
    .min(2, formMessages.minLength),
});

interface UpdateCountryModalProps extends ActionModalProps {
  countryRef: UpdateCountryModal_country$key;
}

export function UpdateCountryModal({
  show,
  onClose,
  countryRef,
}: UpdateCountryModalProps) {
  const country = useFragment(fragment, countryRef);

  return (
    <CountryModal<UpdateCountryModalMutation>
      title={<FormattedMessage {...countryActionMessages.updateCountry} />}
      name={country.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      schema={updateCountrySchema}
      mutation={updateMutation}
      defaultValue={country}
    />
  );
}
