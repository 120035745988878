import Header from '@bfly/ui2/Header';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

const resource = Resource.EMAIL_CHANGES;

const gridFilterFields: FilterField[] = [
  { title: tableHeadings.newEmail.defaultMessage, field: 'email' },
  { title: tableHeadings.oldEmail.defaultMessage, field: 'oldEmail' },
];

interface EmailChangeRequestsPageProps {
  children?: ReactNode;
}

export default function EmailChangeRequestsPage({
  children,
}: EmailChangeRequestsPageProps) {
  useBreadcrumbDetector(pageTitles.emailChangeRequests);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.emailChangeRequests} />
        </Header.Title>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
