import React from 'react';
import { graphql, useFragment } from 'react-relay';

import UndeleteAction from 'components/UndeleteAction';

import { UndeleteDomainUserActionMutation } from './__generated__/UndeleteDomainUserActionMutation.graphql';
import { UndeleteDomainUserAction_user$key } from './__generated__/UndeleteDomainUserAction_user.graphql';

const fragment = graphql`
  fragment UndeleteDomainUserAction_user on FlatUser {
    id
    name
    domainUserDeletedAt
    userId
    domainId
  }
`;

const mutation = graphql`
  mutation UndeleteDomainUserActionMutation($input: UndeleteDomainUserInput!) {
    undeleteDomainUser(input: $input) {
      domainUser {
        __typename
        deletedAt
      }
    }
  }
`;

interface UndeleteDomainUserActionProps {
  userRef: UndeleteDomainUserAction_user$key;
}

export default function UndeleteDomainUserAction({
  userRef,
}: UndeleteDomainUserActionProps) {
  const { id, name, userId, domainId } = useFragment(fragment, userRef);
  return (
    <UndeleteAction<UndeleteDomainUserActionMutation>
      mutation={mutation}
      itemName={name ?? 'Domain User'}
      input={{ domainUserId: `${domainId}/${userId}` }}
      updater={(store, { undeleteDomainUser: response }) => {
        store
          .get(id)!
          .setValue(response!.domainUser!.deletedAt, 'domainUserDeletedAt');
      }}
    />
  );
}
