import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import butterflyDevicesTableHeadings from '../messages/butterflyDevicesTableHeadings';
import CreateButterflyDeviceModal from './CreateButterflyDeviceModal';
import { ButterflyDevicesPage_viewer$key } from './__generated__/ButterflyDevicesPage_viewer.graphql';

const resource = Resource.BUTTERFLY_DEVICES;

const gridFilterFields: FilterField[] = [
  {
    title: butterflyDevicesTableHeadings.productionId.defaultMessage,
    field: 'productionId',
  },
  {
    title: tableHeadings.organizationId.defaultMessage,
    field: 'organizationId',
  },
];

const fragment = graphql`
  fragment ButterflyDevicesPage_viewer on Viewer {
    ...CreateButterflyDeviceModal_viewer
  }
`;

interface ButterflyDevicesPageProps {
  viewer: ButterflyDevicesPage_viewer$key;
}

export default function ButterflyDevicesPage({
  viewer,
  children,
}: PropsWithChildren<ButterflyDevicesPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.butterflyDevices);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.butterflyDevices} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateButterflyDeviceModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage
            id="butterflyDevicesPage.actonTitle"
            defaultMessage="Create Butterfly Device"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
