import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import CustomerDetailPage from './components/CustomerDetailPage';
import CustomersGrid from './components/CustomersGrid';
import CustomersPage from './components/CustomersPage';

export default (
  <Route path="customers">
    <Route
      Component={CustomersPage}
      query={graphql`
        query customers_CustomersPage_Query {
          viewer {
            ...CustomersPage_viewer
          }
        }
      `}
    >
      <Route
        Component={CustomersGrid}
        query={graphql`
          query customers_CustomersGrid_Query(
            $zendeskId: [String!]
            $netsuiteId: [String!]
            $stripeId: [String!]
            $sfAccountId: [String!]
            $name: [String!]
            $id: [String!]
          ) {
            viewer {
              ...CustomersGrid_viewer
                @arguments(
                  zendeskId: $zendeskId
                  netsuiteId: $netsuiteId
                  stripeId: $stripeId
                  sfAccountId: $sfAccountId
                  name: $name
                  id: $id
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          zendeskId: location.query.zendeskId,
          netsuiteId: location.query.netsuiteId,
          stripeId: location.query.stripeId,
          sfAccountId: location.query.sfAccountId,
          name: location.query.name,
          id: location.query.id,
        })}
      />
    </Route>
    <Route
      path=":customerId"
      Component={CustomerDetailPage}
      query={graphql`
        query customers_CustomerDetailPage_Query($customerId: String!) {
          customer(customerId: $customerId) {
            ...CustomerDetailPage_customer
          }
        }
      `}
      prepareVariables={({ customerId }) => ({ customerId })}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.customer) throw new HttpError(404);
      }}
    />
  </Route>
);
