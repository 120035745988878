import MutationButton from '@bfly/ui2/MutationButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { MigrateEnterpriseConnectivityActionMutation } from './__generated__/MigrateEnterpriseConnectivityActionMutation.graphql';
import { MigrateEnterpriseConnectivityAction_domain$key } from './__generated__/MigrateEnterpriseConnectivityAction_domain.graphql';

const fragment = graphql`
  fragment MigrateEnterpriseConnectivityAction_domain on Domain {
    id
    domainId
  }
`;

const mutation = graphql`
  mutation MigrateEnterpriseConnectivityActionMutation(
    $input: MigrateDomainToEnterpriseConnectivityInput!
  ) {
    migrateDomainToEnterpriseConnectivity(input: $input) {
      __typename
      domain {
        id
        ...DomainDetailPage_domain
      }
    }
  }
`;

interface MigrateEnterpriseConnectivityActionProps {
  domainRef: MigrateEnterpriseConnectivityAction_domain$key;
}

export default function MigrateEnterpriseConnectivityAction({
  domainRef,
}: MigrateEnterpriseConnectivityActionProps) {
  const domain = useFragment(fragment, domainRef);
  return (
    <PermissionsGuard
      write
      hideContent
      resource={Resource.ENTERPRISE_UPGRADES}
    >
      <MutationButton<MigrateEnterpriseConnectivityActionMutation>
        mutation={mutation}
        input={{ domainId: domain.domainId! }}
        variant="secondary"
        successToastMessage={
          <FormattedMessage
            id="migrateEnterpriseConnectivityAction.success"
            defaultMessage="Enterprise Connectivity has been successfully migrated"
          />
        }
        errorToastMessage={
          <FormattedMessage
            id="migrateEnterpriseConnectivityAction.error"
            defaultMessage="Failed to migrate Enterprise Connectivity"
          />
        }
      >
        <FormattedMessage
          id="migrateConnectivityAction.requestReview"
          defaultMessage="Migrate Connectivity"
        />
      </MutationButton>
    </PermissionsGuard>
  );
}
