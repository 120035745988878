/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig = {
    readonly id: string;
    readonly domainUserIntegrationConfigId: string | null;
    readonly interfaceCode: string | null;
    readonly ehr: {
        readonly ehrId: string | null;
    } | null;
    readonly domainUser: {
        readonly domainUserId: string | null;
        readonly domain: {
            readonly domainId: string | null;
        } | null;
        readonly user: {
            readonly userId: string | null;
        } | null;
        readonly name: string | null;
        readonly email: string | null;
    } | null;
    readonly " $refType": "DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig";
};
export type DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig$data = DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig;
export type DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig$key = {
    readonly " $data"?: DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserIntegrationConfigId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interfaceCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Ehr",
      "kind": "LinkedField",
      "name": "ehr",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ehrId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUser",
      "kind": "LinkedField",
      "name": "domainUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainUserId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Domain",
          "kind": "LinkedField",
          "name": "domain",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "domainId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DomainUserIntegrationConfig",
  "abstractKey": null
};
(node as any).hash = '23e22650b30830cde69b61253f992188';
export default node;
