import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { PlaceOfWorkModal, placeOfWorkSchema } from './PlaceOfWorkModal';
import { UpdatePlaceOfWorkModalMutation } from './__generated__/UpdatePlaceOfWorkModalMutation.graphql';
import { UpdatePlaceOfWorkModal_placeOfWork$key } from './__generated__/UpdatePlaceOfWorkModal_placeOfWork.graphql';

const fragment = graphql`
  fragment UpdatePlaceOfWorkModal_placeOfWork on PlaceOfWork {
    id
    placeOfWorkId
    name
    countries
    status
    source
  }
`;

const updateMutation = graphql`
  mutation UpdatePlaceOfWorkModalMutation($input: UpdatePlaceOfWorkInput!) {
    updatePlaceOfWork(input: $input) {
      placeOfWork {
        ...PlaceOfWorkDetailPage_placeOfWork
      }
    }
  }
`;

interface UpdatePlaceOfWorkModalProps extends ActionModalProps {
  placeOfWorkRef: UpdatePlaceOfWorkModal_placeOfWork$key;
}

export function UpdatePlaceOfWorkModal({
  placeOfWorkRef,
  ...props
}: UpdatePlaceOfWorkModalProps) {
  const placeOfWork = useFragment(fragment, placeOfWorkRef);
  return (
    <PlaceOfWorkModal<UpdatePlaceOfWorkModalMutation>
      title={
        <FormattedMessage
          id="updatePlaceOfWorkModal.title"
          defaultMessage="Update Place Of Work"
        />
      }
      name={placeOfWork.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      schema={placeOfWorkSchema}
      mutation={updateMutation}
      defaultValue={placeOfWork}
      {...props}
    />
  );
}
