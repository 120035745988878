import Button from '@bfly/ui2/Button';
import { useToast } from '@bfly/ui2/ToastContext';
import useDialog from '@bfly/ui2/useDialog';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment, useMutation } from 'react-relay';

import actionMessages from 'messages/actions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { EnableCaptionHealthAction_subscription$key as SubscriptionKey } from './__generated__/EnableCaptionHealthAction_subscription.graphql';

interface EnableCaptionHealthActionProps {
  subscriptionRef: SubscriptionKey;
}

const fragment = graphql`
  fragment EnableCaptionHealthAction_subscription on Subscription {
    id
    subscriptionId
  }
`;

export default function EnableCaptionHealthAction({
  subscriptionRef,
}: EnableCaptionHealthActionProps) {
  const dialog = useDialog();
  const toast = useToast();
  const subscription = useFragment(fragment, subscriptionRef);

  const [mutate] = useMutation(graphql`
    mutation EnableCaptionHealthActionMutation(
      $input: EnableCaptionHealthInput!
    ) {
      enableCaptionHealth(input: $input) {
        subscription {
          id
          featureModuleIds
        }
      }
    }
  `);

  const handleMutate = async () => {
    await mutate({
      variables: {
        input: { subscriptionId: subscription.subscriptionId },
      },
      onError: () => {
        toast!.error(
          <FormattedMessage
            id="enableCaptionHealthAction.error"
            defaultMessage="Something went wrong"
          />,
        );
      },
      onCompleted: () => {
        toast!.success(
          <FormattedMessage
            id="enableCaptionHealthAction.success"
            defaultMessage="Caption Health enabled."
          />,
        );
      },
    });
  };

  const handleClick = () => {
    return dialog.open(
      <FormattedMessage
        id="enableCaptionHealthAction.dialog.content"
        defaultMessage="Are you sure you want to enable caption health features? This will have no effect if already enabled."
      />,
      {
        runConfirm: () => handleMutate(),
        title: (
          <FormattedMessage
            id="enableCaptionHealthAction.dialog.title"
            defaultMessage="Enable Caption Health?"
          />
        ),
        confirmLabel: <FormattedMessage {...actionMessages.confirm} />,
      },
    );
  };

  return (
    <PermissionsGuard resource={Resource.SUBSCRIPTIONS} write hideContent>
      <Button
        variant="secondary"
        data-cy="enable-caption-health"
        onClick={handleClick}
      >
        <FormattedMessage
          id="enableCaptionHealthAction.action"
          defaultMessage="Enable Caption Health"
        />
      </Button>
    </PermissionsGuard>
  );
}
