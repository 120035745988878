import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import {
  applicationIdentifierMessages,
  osVersionBlacklistEntryIdMessages,
  versionRangeMessages,
} from 'messages/formMessages';
import applicationIdentifies from 'utils/applicationIdentifies';
import { nullableString } from 'utils/yupSchemaUtils';

import { buildNumberMessages } from '../messages/iosVersionBlacklistEntryModal';

const OPERATION_SYSTEM = 'iOS';

export const iosVersionBlacklistEntrySchema = object({
  applicationIdentifier: string()
    .required(applicationIdentifierMessages.required)
    .oneOf(applicationIdentifies),
  versionRange: string().required(versionRangeMessages.required),
  buildNumber: nullableString(),
});

export function IosVersionBlacklistEntryModal<
  TMutation extends MutationParameters,
>({ defaultValue, ...props }: ResourceModalProps<TMutation>) {
  const isCreate = !defaultValue?.iosVersionBlacklistEntryId;
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation> {...props} defaultValue={defaultValue}>
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="iosVersionBlacklistEntryId"
          label={
            <FormattedMessage
              {...osVersionBlacklistEntryIdMessages.label}
              values={{ operationSystem: OPERATION_SYSTEM }}
            />
          }
          data-cy="iosVersionBlacklistEntry-iosVersionBlacklistEntryIdField"
        />
      )}
      <Form.FieldGroup
        as={DropdownList}
        data={applicationIdentifies}
        name="applicationIdentifier"
        label={<FormattedMessage {...applicationIdentifierMessages.label} />}
        placeholder={applicationIdentifierMessages.placeholder}
        description={
          <FormattedMessage
            {...applicationIdentifierMessages.description}
            values={{ operationSystem: 'iOS' }}
          />
        }
        data-cy="iosVersionBlacklistEntry-applicationIdentifierField"
      />
      <Form.FieldGroup
        name="versionRange"
        label={<FormattedMessage {...versionRangeMessages.label} />}
        placeholder={formatMessage(versionRangeMessages.placeholder)}
        description={
          <FormattedMessage {...versionRangeMessages.description} />
        }
        data-cy="iosVersionBlacklistEntry-versionRangeField"
      />
      <Form.FieldGroup
        name="buildNumber"
        label={<FormattedMessage {...buildNumberMessages.label} />}
        placeholder={formatMessage(buildNumberMessages.placeholder)}
        description={<FormattedMessage {...buildNumberMessages.description} />}
        data-cy="iosVersionBlacklistEntry-buildNumberField"
      />
    </ResourceModal>
  );
}
