import { useContext } from 'react';

import PermissionsContext, {
  PermissionsContextValues,
} from './PermissionsContext';

export function usePermissionsContext(): PermissionsContextValues {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error(
      'usePermissionsContext must be called within PermissionsContext',
    );
  }

  return context;
}
