/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ButterflyDeviceStatus = "ACTIVE" | "BRICKED" | "LOST" | "RECALLED" | "STOLEN" | "%future added value";
export type SetProbeStatusAction_butterflyDevice = {
    readonly id: string;
    readonly productionId: string | null;
    readonly recallId: string | null;
    readonly brickedAt: string | null;
    readonly brickedReason: string | null;
    readonly activeReason: string | null;
    readonly stolenNote: string | null;
    readonly lostNote: string | null;
    readonly status: ButterflyDeviceStatus | null;
    readonly " $refType": "SetProbeStatusAction_butterflyDevice";
};
export type SetProbeStatusAction_butterflyDevice$data = SetProbeStatusAction_butterflyDevice;
export type SetProbeStatusAction_butterflyDevice$key = {
    readonly " $data"?: SetProbeStatusAction_butterflyDevice$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SetProbeStatusAction_butterflyDevice">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetProbeStatusAction_butterflyDevice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recallId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brickedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brickedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stolenNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lostNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ButterflyDevice",
  "abstractKey": null
};
(node as any).hash = 'fa54daab8e5c53f77f9826e7bb022897';
export default node;
