export interface UploadInfo {
  readonly method: string;
  readonly postData: ReadonlyArray<any>;
  readonly url: string;
}

export default async function uploadToS3(
  file: File,
  { method, postData, url }: UploadInfo,
) {
  const body = new FormData();

  for (const [key, value] of postData) {
    body.append(key, value);
  }
  body.append('file', file);

  await fetch(url, { method, body });
}
