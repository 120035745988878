import { defineMessages } from 'react-intl';

export default defineMessages({
  id: {
    id: 'emailChangeTable.heading.id',
    defaultMessage: 'ID',
  },
  auth0Id: {
    id: 'emailChangeTable.heading.auth0Id',
    defaultMessage: 'Auth0 ID',
  },
  email: {
    id: 'emailChangeTable.heading.email',
    defaultMessage: 'Email',
  },
  oldEmail: {
    id: 'emailChangeTable.heading.oldEmail',
    defaultMessage: 'Old Email',
  },
  createdAt: {
    id: 'emailChangeTable.heading.createdAt',
    defaultMessage: 'Created At',
  },
  expiresAt: {
    id: 'emailChangeTable.heading.expiresAt',
    defaultMessage: 'Expires At',
  },
});
