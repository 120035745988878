import { defineMessages } from 'react-intl';

export default defineMessages({
  iosVersionBlacklistEntryId: {
    id: 'iosVersionBlacklistEntriesTable.heading.iosVersionBlacklistEntryId',
    defaultMessage: 'iOS Version Recall Entry ID',
  },
  buildNumber: {
    id: 'iosVersionBlacklistEntriesTable.heading.buildNumber',
    defaultMessage: 'Build Number',
  },
});
