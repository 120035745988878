import omit from 'lodash/omit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteInviteAction from './DeleteInviteAction';
import ShowInviteLinkAction from './ShowInviteLinkAction';
import UpdateInviteModal from './UpdateInviteModal';
import { InviteDetailPage_organizationInvite$key } from './__generated__/InviteDetailPage_organizationInvite.graphql';

const fragment = graphql`
  fragment InviteDetailPage_organizationInvite on OrganizationInvite {
    ...UpdateInviteModal_organizationInvite
    ...DeleteInviteAction_organizationInvite
    ...ShowInviteLinkAction_organizationInvite
    id
    organizationInviteId
    email
    roleId
    makeOrganizationAdmin
    createdAt
    deletedAt
    user {
      userId
    }
    organization {
      domain {
        domainId
      }
      organizationId
      name
    }
  }
`;

interface InviteDetailPageProps {
  invite: InviteDetailPage_organizationInvite$key;
}

export default function InviteDetailPage({ invite }: InviteDetailPageProps) {
  const data = useFragment(fragment, invite);
  const { organization, user, email } = data;
  useBreadcrumbDetector(pageTitles.invite, email);
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.INVITES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.invite} />}
        name={data.email!}
        data={omit(data, 'organization.domain')}
        quickCopyFields={[
          'organizationInviteId',
          'email',
          'user: userId',
          'organization: organizationId',
          'organization: name',
        ]}
        resourceActions={
          canWrite(Resource.INVITES) && (
            <ShowInviteLinkAction inviteRef={data} />
          )
        }
        relatedItems={
          <>
            {organization && (
              <RelatedItem
                name="Organization"
                to={`/organizations/${organization.organizationId}`}
              >
                <FormattedMessage
                  id="inviteDetail.relatedItem.organization"
                  defaultMessage="Organization"
                />
              </RelatedItem>
            )}
            {user && (
              <RelatedItem
                name="User"
                to={`/users/${user.userId}/${
                  organization?.domain?.domainId ?? '-'
                }`}
              >
                <FormattedMessage
                  id="inviteDetail.relatedItem.User"
                  defaultMessage="User"
                />
              </RelatedItem>
            )}
          </>
        }
        crudActions={
          canWrite(Resource.INVITES) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateInviteModal {...props} inviteRef={data} />
                )}
              />
              <DeleteInviteAction inviteRef={data} />
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
