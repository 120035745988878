import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';

import { DeleteOrganizationAction_organization$key } from './__generated__/DeleteOrganizationAction_organization.graphql';

const fragment = graphql`
  fragment DeleteOrganizationAction_organization on Organization {
    organizationId
    name
    deletedAt
  }
`;

const mutation = graphql`
  mutation DeleteOrganizationActionMutation($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      organization {
        ...OrganizationsGrid_organization @relay(mask: false)
      }
    }
  }
`;

interface DeleteOrganizationActionProps {
  organizationRef: DeleteOrganizationAction_organization$key;
}

export default function DeleteOrganizationAction({
  organizationRef,
}: DeleteOrganizationActionProps) {
  const { organizationId, name, deletedAt } = useFragment(
    fragment,
    organizationRef,
  );

  return (
    <DeleteAction
      itemName={name}
      mutation={mutation}
      redirectTo="/organizations"
      disabled={!!deletedAt}
      input={{ organizationId }}
    />
  );
}
