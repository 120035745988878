import { defineMessages } from 'react-intl';

export default defineMessages({
  subdomainLabel: {
    id: 'domainsTable.subdomainLabel.name',
    defaultMessage: 'Subdomain Label',
  },
  enableButterflyLogin: {
    id: 'domainsTable.enableButterflyLogin.name',
    defaultMessage: 'Enable Butterfly Login',
  },
  enableSamlLogin: {
    id: 'domainsTable.enableSamlLogin.name',
    defaultMessage: 'Enable SAML Login',
  },
  enableSharedDeviceCodeLogin: {
    id: 'domainsTable.enableSharedDeviceCodeLogin.name',
    defaultMessage: 'Enable Shared Device Code Login',
  },
  samlEmailAttributeName: {
    id: 'domainsTable.samlEmailAttributeName',
    defaultMessage: 'SAML Email Attribute Name',
  },
  samlEmailAttributeNameRaw: {
    id: 'domainsTable.samlEmailAttributeNameRaw',
    defaultMessage: 'SAML Email Attribute Name Raw',
  },
  samlNicknameAttributeName: {
    id: 'domainsTable.samlNicknameAttributeName',
    defaultMessage: 'SAML Nickname Attribute Name',
  },
  samlNicknameAttributeNameRaw: {
    id: 'domainsTable.samlNicknameAttributeNameRaw',
    defaultMessage: 'SAML Nickname Attribute Name Raw',
  },
  cognitoClientId: {
    id: 'domainsTable.cognitoClientId',
    defaultMessage: 'Cognito Client ID',
  },
  inactivityTimeoutSeconds: {
    id: 'domainsTable.inactivityTimeoutSeconds',
    defaultMessage: 'Inactivity Timeout Seconds',
  },
  requiredAndroidCapabilities: {
    id: 'domainsTable.requiredAndroidCapabilities',
    defaultMessage: 'Required Android Capabilities',
  },
  sharedDeviceKey: {
    id: 'domainsTable.sharedDeviceKey',
    defaultMessage: 'Shared Device Key',
  },
  numUsers: {
    id: 'domainsTable.numUsers',
    defaultMessage: 'Number of Users',
  },
  maxNumSeats: {
    id: 'domainsTable.maxNumSeats',
    defaultMessage: 'Maximum Number of Seats',
  },
  numAvailableSeats: {
    id: 'domainsTable.numAvailableSeats',
    defaultMessage: 'Number of Available Seats',
  },
});
