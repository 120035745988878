export const Ophthalmic = '93fe907e-8949-11e9-a600-8c85908620ba';

export default [
  { label: 'Abdomen', value: 'fd5a4a14-8948-11e9-8579-8c85908620ba' },
  { label: 'Abdomen Deep', value: '08372542-8949-11e9-bc91-8c85908620ba' },
  {
    label: 'Aorta & Gallbladder',
    value: '11d84edc-8949-11e9-ab53-8c85908620ba',
  },
  { label: 'Bladder', value: '19df655c-8949-11e9-8c49-8c85908620ba' },
  { label: 'Cardiac', value: '226d86b8-8949-11e9-9cbd-8c85908620ba' },
  {
    label: 'Cardiac Coherence',
    value: '1f1f6e5a-d15d-11eb-becc-acde48001122',
  },
  { label: 'Cardiac Deep', value: '27c9529a-8949-11e9-b301-8c85908620ba' },
  { label: 'FAST', value: '2c5f4b98-8949-11e9-829b-8c85908620ba' },
  { label: 'Lung', value: '30c03cf6-8949-11e9-bb66-8c85908620ba' },
  {
    label: 'Lung Consolidation',
    value: 'fb4e956e-6e9b-11ec-aa50-acde48001122',
  },
  { label: 'MSK-Soft Tissue', value: '7ee7fb58-8949-11e9-ae53-8c85908620ba' },
  { label: 'Musculoskeletal', value: '35d0609a-8949-11e9-b21a-8c85908620ba' },
  { label: 'Needle: Out-of-Plane', value: '83703231-8949-11e9-b250-8c85908620ba' },
  { label: 'Nerve', value: '3b64a610-8949-11e9-8c68-8c85908620ba' },
  { label: 'OB 1/GYN', value: '753d7200-6af6-11eb-87b2-acde48001122' },
  { label: 'OB 2/3', value: 'e44f61ee-22d0-11eb-bd25-acde48001122' },
  { label: 'Obstetric', value: '649eaf9e-8949-11e9-aba2-8c85908620ba' },
  { label: 'Ophthalmic', value: Ophthalmic },
  {
    label: 'Pediatric Abdomen',
    value: '6ad94fb8-8949-11e9-8504-8c85908620ba',
  },
  {
    label: 'Pediatric Cardiac',
    value: '70f1b874-8949-11e9-9617-8c85908620ba',
  },
  { label: 'Pediatric Lung', value: '75321cd8-8949-11e9-8160-8c85908620ba' },
  { label: 'Small Organ', value: '79b1dee2-8949-11e9-a3f6-8c85908620ba' },
  { label: 'Subxiphoid Tilt - Cardiac', value: 'da4d6a38-e226-11ee-83a5-acde48001122' },
  { label: 'Subxiphoid Tilt - FAST', value: '9cf64eca-2f10-11ef-913b-52d1a8afadac' },
  { label: 'Vascular: Access', value: '83703230-8949-11e9-b250-8c85908620ba' },
  {
    label: 'Vascular: Carotid',
    value: '880f28dc-8949-11e9-b320-8c85908620ba',
  },
  {
    label: 'Vascular: Deep Vein',
    value: '8d3fadc2-8949-11e9-8d29-8c85908620ba',
  },
  {
    label: 'Vascular: Facial',
    value: 'e64e2d0e-40cf-11ec-9ad9-aa665a0d9036',
  },
];
