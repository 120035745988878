import useRouter from 'found/useRouter';
import { useEffect, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

export const BREADCRUMBS_HASH = 'nav';

/**
 * This hook is used on pages to automatically detect and store breadcrumbs.
 * It will create a group of breadcrumbs when changes in the query are detected.
 * @param title page title, eg. Users, Domains, etc.
 * @param subtitle optional, entity title, eg. specific user or domain name, email, ID, etc.
 */
export function useBreadcrumbDetector(
  title: MessageDescriptor,
  subtitle?: string | null,
) {
  const { addBreadcrumb, hasBreadcrumb } = useBreadcrumbsContext();
  const {
    match: {
      location: { pathname, query, hash },
    },
  } = useRouter();
  const [initialPathname] = useState(pathname);
  const [isNotDirectLink] = useState(hasBreadcrumb(hash));

  useEffect(() => {
    const exists = hash.startsWith(`#${BREADCRUMBS_HASH}`) && isNotDirectLink;
    if (initialPathname === pathname && !exists) {
      addBreadcrumb(pathname, query, title, subtitle);
    }
  }, [
    addBreadcrumb,
    isNotDirectLink,
    initialPathname,
    pathname,
    query,
    title,
    subtitle,
    hash,
  ]);
}
