/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EducationContentGridPageRefetchQueryVariables = {
    absorbId?: Array<string> | null | undefined;
    categoryId?: Array<string> | null | undefined;
    categoryName?: Array<string> | null | undefined;
    contentType?: Array<string> | null | undefined;
    count?: number | null | undefined;
    cursor?: string | null | undefined;
    name?: Array<string> | null | undefined;
    practiceType?: Array<string> | null | undefined;
};
export type EducationContentGridPageRefetchQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"EducationContentGrid_viewer">;
    } | null;
};
export type EducationContentGridPageRefetchQuery = {
    readonly response: EducationContentGridPageRefetchQueryResponse;
    readonly variables: EducationContentGridPageRefetchQueryVariables;
};



/*
query EducationContentGridPageRefetchQuery(
  $absorbId: [String!]
  $categoryId: [String!]
  $categoryName: [String!]
  $contentType: [String!]
  $count: Int = 20
  $cursor: String
  $name: [String!]
  $practiceType: [String!]
) {
  viewer {
    ...EducationContentGrid_viewer_4Df3Fh
    id
  }
}

fragment EducationContentGrid_viewer_4Df3Fh on Viewer {
  educationContent(first: $count, after: $cursor, name: $name, absorbId: $absorbId, contentType: $contentType, practiceType: $practiceType, categoryId: $categoryId, categoryName: $categoryName) {
    edges {
      node {
        id
        educationContentId
        name
        description
        type
        contentType
        practiceType
        isPremium
        vimeoId
        absorbId
        duration
        ranking
        status
        presetLocalIds
        toolLocalIds
        captureModes
        deletedAt
        authoredBy {
          name
          id
        }
        category {
          educationCategoryId
          name
          id
        }
        courseLanguage
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "absorbId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categoryId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categoryName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentType"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "practiceType"
  }
],
v1 = {
  "kind": "Variable",
  "name": "absorbId",
  "variableName": "absorbId"
},
v2 = {
  "kind": "Variable",
  "name": "categoryId",
  "variableName": "categoryId"
},
v3 = {
  "kind": "Variable",
  "name": "categoryName",
  "variableName": "categoryName"
},
v4 = {
  "kind": "Variable",
  "name": "contentType",
  "variableName": "contentType"
},
v5 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v6 = {
  "kind": "Variable",
  "name": "practiceType",
  "variableName": "practiceType"
},
v7 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EducationContentGridPageRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "EducationContentGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EducationContentGridPageRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EducationContent",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "educationContentId",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "practiceType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPremium",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vimeoId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "absorbId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ranking",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "presetLocalIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "toolLocalIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "captureModes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "authoredBy",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "educationCategoryId",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "courseLanguage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "EducationContentGrid_educationContent",
            "kind": "LinkedHandle",
            "name": "educationContent"
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06263f725fc898495cb550376a12118d",
    "id": null,
    "metadata": {},
    "name": "EducationContentGridPageRefetchQuery",
    "operationKind": "query",
    "text": "query EducationContentGridPageRefetchQuery(\n  $absorbId: [String!]\n  $categoryId: [String!]\n  $categoryName: [String!]\n  $contentType: [String!]\n  $count: Int = 20\n  $cursor: String\n  $name: [String!]\n  $practiceType: [String!]\n) {\n  viewer {\n    ...EducationContentGrid_viewer_4Df3Fh\n    id\n  }\n}\n\nfragment EducationContentGrid_viewer_4Df3Fh on Viewer {\n  educationContent(first: $count, after: $cursor, name: $name, absorbId: $absorbId, contentType: $contentType, practiceType: $practiceType, categoryId: $categoryId, categoryName: $categoryName) {\n    edges {\n      node {\n        id\n        educationContentId\n        name\n        description\n        type\n        contentType\n        practiceType\n        isPremium\n        vimeoId\n        absorbId\n        duration\n        ranking\n        status\n        presetLocalIds\n        toolLocalIds\n        captureModes\n        deletedAt\n        authoredBy {\n          name\n          id\n        }\n        category {\n          educationCategoryId\n          name\n          id\n        }\n        courseLanguage\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e4bfae3c8502fc1d1f1aa016de501927';
export default node;
