import { BrowserProtocol, queryMiddleware } from 'farce';
import { ElementsRenderer, createFarceRouter, createRender } from 'found';
import { Resolver } from 'found-relay';
import { ScrollManager } from 'found-scroll';
import React from 'react';
import StaticContainer from 'react-static-container';

import { signIn } from 'utils/authActions';

import {
  ConnectionErrorPage,
  ErrorBoundary,
  ErrorPage,
  LoadingOverlay,
  LoadingPage,
  NotFoundPage,
} from '.';
import routeConfig from '../routeConfig';

const render = createRender({
  renderPending: () => (
    <>
      <StaticContainer>{null}</StaticContainer>
      <LoadingOverlay />
    </>
  ),
  renderReady: (
    { elements }, // eslint-disable-line react/prop-types
  ) => {
    return (
      <StaticContainer shouldUpdate>
        {/* @ts-ignore */}
        <ElementsRenderer elements={elements} />
      </StaticContainer>
    );
  },
  renderError: ({ error }) => {
    switch (error.status) {
      case 401:
        signIn();
        return null;
      case 500:
        return <ConnectionErrorPage />;
      case 400:
      case 403:
        return <ErrorPage error={error} />;
      case 404:
        return <NotFoundPage />;
      case 'loading' as any:
        return <LoadingPage />;
      default:
        return null;
    }
  },
});

const FarceRouter = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig,
  render: (renderArgs) => (
    <ScrollManager renderArgs={renderArgs as any}>
      {render(renderArgs)}
    </ScrollManager>
  ),
});

export default function Router({ environment }) {
  const resolver = new Resolver(environment);
  return (
    <ErrorBoundary>
      <FarceRouter resolver={resolver as any} />
    </ErrorBoundary>
  );
}
