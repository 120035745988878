/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteEducationContentInput = {
    educationContentId: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteEducationVideoActionMutationVariables = {
    input: DeleteEducationContentInput;
};
export type DeleteEducationVideoActionMutationResponse = {
    readonly deleteEducationContent: {
        readonly educationContent: {
            readonly educationContentId: string | null;
            readonly deletedAt: string | null;
        } | null;
    } | null;
};
export type DeleteEducationVideoActionMutation = {
    readonly response: DeleteEducationVideoActionMutationResponse;
    readonly variables: DeleteEducationVideoActionMutationVariables;
};



/*
mutation DeleteEducationVideoActionMutation(
  $input: DeleteEducationContentInput!
) {
  deleteEducationContent(input: $input) {
    educationContent {
      educationContentId
      deletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "educationContentId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteEducationVideoActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteEducationContentPayload",
        "kind": "LinkedField",
        "name": "deleteEducationContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EducationContent",
            "kind": "LinkedField",
            "name": "educationContent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteEducationVideoActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteEducationContentPayload",
        "kind": "LinkedField",
        "name": "deleteEducationContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EducationContent",
            "kind": "LinkedField",
            "name": "educationContent",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09ce4ed38f3494dc4f05c5200d638c86",
    "id": null,
    "metadata": {},
    "name": "DeleteEducationVideoActionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteEducationVideoActionMutation(\n  $input: DeleteEducationContentInput!\n) {\n  deleteEducationContent(input: $input) {\n    educationContent {\n      educationContentId\n      deletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cd46fa4e75bff79969e627ac2826f3fe';
export default node;
