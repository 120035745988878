import Button from '@bfly/ui2/Button';
import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { date, object, string } from 'yup';

import Alert from 'components/Alert';
import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import {
  domainIdMessages,
  endDateMessages,
  startDateMessages,
} from '../messages/formMessages';
import {
  RequestAuditLogsActionMutation,
  RequestAuditLogsActionMutationResponse,
} from './__generated__/RequestAuditLogsActionMutation.graphql';
import { RequestAuditLogsAction_domain$key } from './__generated__/RequestAuditLogsAction_domain.graphql';

const fragment = graphql`
  fragment RequestAuditLogsAction_domain on Domain {
    id
    domainId
  }
`;

const mutation = graphql`
  mutation RequestAuditLogsActionMutation(
    $input: RequestDomainAuditLogsInput!
  ) {
    requestDomainAuditLogs(input: $input) {
      downloadLink
      password
    }
  }
`;

const requestAuditLogsSchema = object({
  domainId: string().required(domainIdMessages.required),
  startDate: date().required(startDateMessages.required),
  endDate: date().required(endDateMessages.required),
});

export type AuditLogsResponse = NonNullable<
  RequestAuditLogsActionMutationResponse['requestDomainAuditLogs']
>;

interface RequestAuditLogsActionProps {
  domainRef: RequestAuditLogsAction_domain$key;
  onCompleted: (data: AuditLogsResponse) => void;
}

export default function RequestAuditLogsAction({
  domainRef,
  onCompleted,
}: RequestAuditLogsActionProps) {
  const domain = useFragment(fragment, domainRef);
  const { domainId } = domain;
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <PermissionsGuard resource={Resource.AUDIT_LOGS} write hideContent>
      <Button
        variant="secondary"
        onClick={() => setShowModal(true)}
        data-cy="request-audit-logs"
      >
        <FormattedMessage
          id="domainActions.requestAuditLogs"
          defaultMessage="Request Audit Logs"
        />
      </Button>
      <ResourceModal<RequestAuditLogsActionMutation>
        defaultValue={{ domainId }}
        show={showModal}
        onClose={() => setShowModal(false)}
        onCompleted={({
          requestDomainAuditLogs,
        }: RequestAuditLogsActionMutationResponse) =>
          onCompleted(requestDomainAuditLogs!)
        }
        title={
          <FormattedMessage
            id="requestAuditLogsAction.title"
            defaultMessage="Request Audit Logs"
          />
        }
        submitText={
          <FormattedMessage
            id="requestAuditLogsAction.submitText"
            defaultMessage="Request Audit Logs"
          />
        }
        mutation={mutation}
        schema={requestAuditLogsSchema}
      >
        <Form.FieldGroup
          disabled
          name="domainId"
          label={<FormattedMessage {...domainIdMessages.label} />}
          placeholder={formatMessage(domainIdMessages.placeholder)}
          data-cy="requestAuditLogsAction-domainId"
        />
        <Form.FieldGroup
          name="startDate"
          as={DatePicker}
          valueFormat={{
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }}
          label={<FormattedMessage {...startDateMessages.label} />}
          placeholder={startDateMessages.placeholder}
          data-cy="requestAuditLogsField-startDate"
        />
        <Form.FieldGroup
          name="endDate"
          as={DatePicker}
          valueFormat={{
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }}
          label={<FormattedMessage {...endDateMessages.label} />}
          placeholder={endDateMessages.placeholder}
          data-cy="requestAuditLogsField-endDate"
        />
        <Alert variant="info" data-cy="requestAuditLogsAction-info">
          <FormattedMessage
            id="requestAuditLogsAction.note"
            defaultMessage="NOTE: The request may take as long as 30 seconds to complete. Please don't double-click."
          />
        </Alert>
      </ResourceModal>
    </PermissionsGuard>
  );
}
