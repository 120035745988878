import Layout from '@4c/layout';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import AppNavigation from 'components/AppNavigation';
import BreadcrumbsProvider from 'utils/BreadcrumbsProvider';
import PermissionsProvider from 'utils/PermissionsProvider';

const fragment = graphql`
  fragment RootPage_viewer on Viewer {
    ...PermissionsProvider_viewer
  }
`;

export default function RootPage({ children, viewer }) {
  const viewerFragment = useFragment(fragment, viewer);
  return (
    <PermissionsProvider viewer={viewerFragment}>
      <BreadcrumbsProvider>
        <Page>
          <AppHeader />
          <Page.Container>
            <AppNavigation />
            <Page.Main className="fixed flex flex-col bottom-0 w-full top-navbar">
              <Layout className="sm:hidden">
                <Page.DrawerToggleButton />
              </Layout>
              <div className="flex flex-1 overflow-hidden flex-col">
                {children}
              </div>
              <AppFooter />
            </Page.Main>
          </Page.Container>
        </Page>
      </BreadcrumbsProvider>
    </PermissionsProvider>
  );
}
