import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteApplicationVersionRequirementAction from './DeleteApplicationVersionRequirementAction';
import { UpdateApplicationVersionRequirementModal } from './UpdateApplicationVersionRequirementModal';
import { ApplicationVersionRequirementDetailPage_applicationVersionRequirement$key } from './__generated__/ApplicationVersionRequirementDetailPage_applicationVersionRequirement.graphql';

const fragment = graphql`
  fragment ApplicationVersionRequirementDetailPage_applicationVersionRequirement on ApplicationVersionRequirement {
    ...UpdateApplicationVersionRequirementModal_applicationVersionRequirement
    ...DeleteApplicationVersionRequirementAction_applicationVersionRequirement
    id
    applicationIdentifier
    minimumVersion
  }
`;

interface ApplicationVersionRequirementDetailPageProps {
  applicationVersionRequirement: ApplicationVersionRequirementDetailPage_applicationVersionRequirement$key;
}

export default function ApplicationVersionRequirementDetailPage({
  applicationVersionRequirement,
}: ApplicationVersionRequirementDetailPageProps) {
  const applicationVersionRequirementFragment = useFragment(
    fragment,
    applicationVersionRequirement,
  );

  useBreadcrumbDetector(
    pageTitles.applicationVersionRequirement,
    applicationVersionRequirementFragment.applicationIdentifier,
  );

  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.APPLICATION_VERSION_REQUIREMENTS}>
      <ResourceDetailPage
        title={
          <FormattedMessage {...pageTitles.applicationVersionRequirement} />
        }
        name={applicationVersionRequirementFragment.applicationIdentifier!}
        data={applicationVersionRequirementFragment}
        quickCopyFields={['applicationIdentifier']}
        crudActions={
          canWrite(Resource.APPLICATION_VERSION_REQUIREMENTS) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateApplicationVersionRequirementModal
                    {...props}
                    applicationVersionRequirementRef={
                      applicationVersionRequirementFragment
                    }
                  />
                )}
              />
              <DeleteApplicationVersionRequirementAction
                applicationVersionRequirementRef={
                  applicationVersionRequirementFragment
                }
              />
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
