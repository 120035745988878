/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type BillingStatus = "ACTIVE" | "CANCELED" | "PAST_DUE" | "TRIALING" | "UNPAID" | "%future added value";
export type DlDataMode = "EXCLUDE" | "INCLUDE" | "PARTIAL" | "%future added value";
export type PlanType = "BASIC" | "CLINIC" | "DEPARTMENT" | "EDUCATION" | "EMS" | "ENTERPRISE_SECURITY" | "ENTERPRISE_WORKFLOW" | "GOVERNMENT" | "HOSPITAL" | "INDIVIDUAL" | "INDIVIDUAL_FOREVER" | "MEDICAL_SCHOOL" | "NONE" | "PRO_CUSTOM" | "PRO_THREE_YEAR" | "PRO_TRIAL" | "RESIDENCY_PROGRAM" | "TEAM" | "%future added value";
export type PracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type SpecialType = "DEMO" | "INTERNAL" | "NONE" | "TEST" | "%future added value";
export type ProvisionOrganizationInput = {
    name: string;
    slug?: string | null | undefined;
    country: string;
    specialType?: SpecialType | null | undefined;
    dlDataModeOverride?: DlDataMode | null | undefined;
    adminEmail: string;
    planType: PlanType;
    maxNumSeats: number;
    practiceType: PracticeType;
    subscriptionEndsAt?: string | null | undefined;
    billingStatus?: BillingStatus | null | undefined;
    billingProvider?: BillingProvider | null | undefined;
    stripeSubscriptionId?: string | null | undefined;
    sfSubscriptionId?: string | null | undefined;
    customerId: string;
    featureModuleIds?: Array<string | null> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateOrganizationModal_ProvisionMutationVariables = {
    input: ProvisionOrganizationInput;
};
export type CreateOrganizationModal_ProvisionMutationResponse = {
    readonly provisionOrganization: {
        readonly organization: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"OrganizationsGrid_organization">;
        } | null;
    } | null;
};
export type CreateOrganizationModal_ProvisionMutation = {
    readonly response: CreateOrganizationModal_ProvisionMutationResponse;
    readonly variables: CreateOrganizationModal_ProvisionMutationVariables;
};



/*
mutation CreateOrganizationModal_ProvisionMutation(
  $input: ProvisionOrganizationInput!
) {
  provisionOrganization(input: $input) {
    organization {
      id
      ...OrganizationsGrid_organization
    }
  }
}

fragment OrganizationsGrid_organization on Organization {
  id
  organizationId
  name
  slug
  country
  deletedAt
  subscription {
    subscriptionId
    subscriptionEndsAt
    accessEndsAt
    hasStartedActiveSubscription
    hasCompletedTrial
    canAccessProFeatures
    isInGracePeriod
    isTeam
    billingStatus
    billingProvider
    stripeSubscriptionId
    planType
    maxNumSeats
    practiceType
    id
  }
  isActive
  customer {
    customerId
    id
  }
  domain {
    domainId
    id
  }
  dlDataMode
  dlDataModeOverride
  enterpriseCapabilityManagementEnabled
  enabledPresetIds
  enabledToolIds
  enabledCaptureModes
  enabledCapabilities
  specialType
  numAvailableSeats
  numOutstandingInvitations
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrganizationModal_ProvisionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProvisionOrganizationPayload",
        "kind": "LinkedField",
        "name": "provisionOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationsGrid_organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOrganizationModal_ProvisionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProvisionOrganizationPayload",
        "kind": "LinkedField",
        "name": "provisionOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionEndsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessEndsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasStartedActiveSubscription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCompletedTrial",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canAccessProFeatures",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInGracePeriod",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTeam",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billingStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billingProvider",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stripeSubscriptionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "planType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxNumSeats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "practiceType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dlDataMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dlDataModeOverride",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enterpriseCapabilityManagementEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledPresetIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledToolIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledCaptureModes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledCapabilities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numAvailableSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numOutstandingInvitations",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a39d5b41ec45232d3f080ca5709efee",
    "id": null,
    "metadata": {},
    "name": "CreateOrganizationModal_ProvisionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOrganizationModal_ProvisionMutation(\n  $input: ProvisionOrganizationInput!\n) {\n  provisionOrganization(input: $input) {\n    organization {\n      id\n      ...OrganizationsGrid_organization\n    }\n  }\n}\n\nfragment OrganizationsGrid_organization on Organization {\n  id\n  organizationId\n  name\n  slug\n  country\n  deletedAt\n  subscription {\n    subscriptionId\n    subscriptionEndsAt\n    accessEndsAt\n    hasStartedActiveSubscription\n    hasCompletedTrial\n    canAccessProFeatures\n    isInGracePeriod\n    isTeam\n    billingStatus\n    billingProvider\n    stripeSubscriptionId\n    planType\n    maxNumSeats\n    practiceType\n    id\n  }\n  isActive\n  customer {\n    customerId\n    id\n  }\n  domain {\n    domainId\n    id\n  }\n  dlDataMode\n  dlDataModeOverride\n  enterpriseCapabilityManagementEnabled\n  enabledPresetIds\n  enabledToolIds\n  enabledCaptureModes\n  enabledCapabilities\n  specialType\n  numAvailableSeats\n  numOutstandingInvitations\n}\n"
  }
};
})();
(node as any).hash = '7a76ecafb8639dc8a8b2dcddc2e1cb7f';
export default node;
