/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MigrateDomainToEnterpriseConnectivityInput = {
    domainId: string;
    clientMutationId?: string | null | undefined;
};
export type MigrateEnterpriseConnectivityActionMutationVariables = {
    input: MigrateDomainToEnterpriseConnectivityInput;
};
export type MigrateEnterpriseConnectivityActionMutationResponse = {
    readonly migrateDomainToEnterpriseConnectivity: {
        readonly __typename: string;
        readonly domain: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"DomainDetailPage_domain">;
        } | null;
    } | null;
};
export type MigrateEnterpriseConnectivityActionMutation = {
    readonly response: MigrateEnterpriseConnectivityActionMutationResponse;
    readonly variables: MigrateEnterpriseConnectivityActionMutationVariables;
};



/*
mutation MigrateEnterpriseConnectivityActionMutation(
  $input: MigrateDomainToEnterpriseConnectivityInput!
) {
  migrateDomainToEnterpriseConnectivity(input: $input) {
    __typename
    domain {
      id
      ...DomainDetailPage_domain
    }
  }
}

fragment CreateStudyExportAction_domain on Domain {
  id
  domainId
}

fragment DomainDetailPage_domain on Domain {
  ...UpdateDomainModal_domain
  ...RequestAuditLogsAction_domain
  ...CreateStudyExportAction_domain
  ...ProvisionSubscriptionAction_domain
  ...GenerateSharedDeviceKeyAction_domain
  ...MigrateEnterpriseWorkflowAction_domain
  ...MigrateEnterpriseConnectivityAction_domain
  id
  domainId
  createdAt
  deletedAt
  subdomainLabel
  name
  enableButterflyLogin
  enableSamlLogin
  enableSharedDeviceCodeLogin
  samlEmailAttributeName
  samlEmailAttributeNameRaw
  samlMetadata
  samlNicknameAttributeName
  samlNicknameAttributeNameRaw
  cognitoClientId
  inactivityTimeoutSeconds
  requiredAndroidCapabilities
  sharedDeviceKey
  numUsers
  maxNumSeats
  numAvailableSeats
  country
}

fragment GenerateSharedDeviceKeyAction_domain on Domain {
  id
  domainId
}

fragment MigrateEnterpriseConnectivityAction_domain on Domain {
  id
  domainId
}

fragment MigrateEnterpriseWorkflowAction_domain on Domain {
  id
  domainId
}

fragment ProvisionSubscriptionAction_domain on Domain {
  id
  domainId
}

fragment RequestAuditLogsAction_domain on Domain {
  id
  domainId
}

fragment UpdateDomainModal_domain on Domain {
  id
  domainId
  createdAt
  deletedAt
  subdomainLabel
  name
  enableButterflyLogin
  enableSamlLogin
  enableSharedDeviceCodeLogin
  samlEmailAttributeName
  samlEmailAttributeNameRaw
  samlMetadata
  samlNicknameAttributeName
  samlNicknameAttributeNameRaw
  cognitoClientId
  inactivityTimeoutSeconds
  requiredAndroidCapabilities
  sharedDeviceKey
  maxNumSeats
  country
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MigrateEnterpriseConnectivityActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MigrateDomainToEnterpriseConnectivityPayload",
        "kind": "LinkedField",
        "name": "migrateDomainToEnterpriseConnectivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DomainDetailPage_domain"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MigrateEnterpriseConnectivityActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MigrateDomainToEnterpriseConnectivityPayload",
        "kind": "LinkedField",
        "name": "migrateDomainToEnterpriseConnectivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domainId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subdomainLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableButterflyLogin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableSamlLogin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableSharedDeviceCodeLogin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlEmailAttributeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlEmailAttributeNameRaw",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlMetadata",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlNicknameAttributeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlNicknameAttributeNameRaw",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cognitoClientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inactivityTimeoutSeconds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredAndroidCapabilities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sharedDeviceKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxNumSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numAvailableSeats",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "338bbdb199cfcfa74077a70886c88365",
    "id": null,
    "metadata": {},
    "name": "MigrateEnterpriseConnectivityActionMutation",
    "operationKind": "mutation",
    "text": "mutation MigrateEnterpriseConnectivityActionMutation(\n  $input: MigrateDomainToEnterpriseConnectivityInput!\n) {\n  migrateDomainToEnterpriseConnectivity(input: $input) {\n    __typename\n    domain {\n      id\n      ...DomainDetailPage_domain\n    }\n  }\n}\n\nfragment CreateStudyExportAction_domain on Domain {\n  id\n  domainId\n}\n\nfragment DomainDetailPage_domain on Domain {\n  ...UpdateDomainModal_domain\n  ...RequestAuditLogsAction_domain\n  ...CreateStudyExportAction_domain\n  ...ProvisionSubscriptionAction_domain\n  ...GenerateSharedDeviceKeyAction_domain\n  ...MigrateEnterpriseWorkflowAction_domain\n  ...MigrateEnterpriseConnectivityAction_domain\n  id\n  domainId\n  createdAt\n  deletedAt\n  subdomainLabel\n  name\n  enableButterflyLogin\n  enableSamlLogin\n  enableSharedDeviceCodeLogin\n  samlEmailAttributeName\n  samlEmailAttributeNameRaw\n  samlMetadata\n  samlNicknameAttributeName\n  samlNicknameAttributeNameRaw\n  cognitoClientId\n  inactivityTimeoutSeconds\n  requiredAndroidCapabilities\n  sharedDeviceKey\n  numUsers\n  maxNumSeats\n  numAvailableSeats\n  country\n}\n\nfragment GenerateSharedDeviceKeyAction_domain on Domain {\n  id\n  domainId\n}\n\nfragment MigrateEnterpriseConnectivityAction_domain on Domain {\n  id\n  domainId\n}\n\nfragment MigrateEnterpriseWorkflowAction_domain on Domain {\n  id\n  domainId\n}\n\nfragment ProvisionSubscriptionAction_domain on Domain {\n  id\n  domainId\n}\n\nfragment RequestAuditLogsAction_domain on Domain {\n  id\n  domainId\n}\n\nfragment UpdateDomainModal_domain on Domain {\n  id\n  domainId\n  createdAt\n  deletedAt\n  subdomainLabel\n  name\n  enableButterflyLogin\n  enableSamlLogin\n  enableSharedDeviceCodeLogin\n  samlEmailAttributeName\n  samlEmailAttributeNameRaw\n  samlMetadata\n  samlNicknameAttributeName\n  samlNicknameAttributeNameRaw\n  cognitoClientId\n  inactivityTimeoutSeconds\n  requiredAndroidCapabilities\n  sharedDeviceKey\n  maxNumSeats\n  country\n}\n"
  }
};
})();
(node as any).hash = 'ca5a03ec76cf83b286cd5c296936e9c7';
export default node;
