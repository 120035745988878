/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ResetUserReviewRequestsInput = {
    userId: string;
    clientMutationId?: string | null | undefined;
};
export type FlatUserDetailPage_ResetUserReviewRequestsMutationVariables = {
    input: ResetUserReviewRequestsInput;
};
export type FlatUserDetailPage_ResetUserReviewRequestsMutationResponse = {
    readonly resetUserReviewRequests: {
        readonly __typename: string;
        readonly user: {
            readonly lastReviewRequestedAt: string | null;
            readonly lastImmediateReviewRequestTriggeredAt: string | null;
            readonly lastCloudReviewRequestTriggeredAt: string | null;
        } | null;
    } | null;
};
export type FlatUserDetailPage_ResetUserReviewRequestsMutation = {
    readonly response: FlatUserDetailPage_ResetUserReviewRequestsMutationResponse;
    readonly variables: FlatUserDetailPage_ResetUserReviewRequestsMutationVariables;
};



/*
mutation FlatUserDetailPage_ResetUserReviewRequestsMutation(
  $input: ResetUserReviewRequestsInput!
) {
  resetUserReviewRequests(input: $input) {
    __typename
    user {
      lastReviewRequestedAt
      lastImmediateReviewRequestTriggeredAt
      lastCloudReviewRequestTriggeredAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastReviewRequestedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastImmediateReviewRequestTriggeredAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastCloudReviewRequestTriggeredAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FlatUserDetailPage_ResetUserReviewRequestsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResetUserReviewRequestsPayload",
        "kind": "LinkedField",
        "name": "resetUserReviewRequests",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FlatUserDetailPage_ResetUserReviewRequestsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResetUserReviewRequestsPayload",
        "kind": "LinkedField",
        "name": "resetUserReviewRequests",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "399d412f6c630fdfb0a8e37d3d5b29a6",
    "id": null,
    "metadata": {},
    "name": "FlatUserDetailPage_ResetUserReviewRequestsMutation",
    "operationKind": "mutation",
    "text": "mutation FlatUserDetailPage_ResetUserReviewRequestsMutation(\n  $input: ResetUserReviewRequestsInput!\n) {\n  resetUserReviewRequests(input: $input) {\n    __typename\n    user {\n      lastReviewRequestedAt\n      lastImmediateReviewRequestTriggeredAt\n      lastCloudReviewRequestTriggeredAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b10e2eec4f99a190f7e68928d4c9eefe';
export default node;
