/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlaceOfWorkStatus = "ACTIVE" | "CLOSED" | "OPENING" | "%future added value";
export type CreatePlaceOfWorkInput = {
    name: string;
    countries: Array<string>;
    status: PlaceOfWorkStatus;
    clientMutationId?: string | null | undefined;
};
export type CreatePlaceOfWorkModalMutationVariables = {
    input: CreatePlaceOfWorkInput;
};
export type CreatePlaceOfWorkModalMutationResponse = {
    readonly createPlaceOfWork: {
        readonly placeOfWork: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"PlacesOfWorkGrid_placeOfWork">;
        } | null;
    } | null;
};
export type CreatePlaceOfWorkModalMutation = {
    readonly response: CreatePlaceOfWorkModalMutationResponse;
    readonly variables: CreatePlaceOfWorkModalMutationVariables;
};



/*
mutation CreatePlaceOfWorkModalMutation(
  $input: CreatePlaceOfWorkInput!
) {
  createPlaceOfWork(input: $input) {
    placeOfWork {
      id
      ...PlacesOfWorkGrid_placeOfWork
    }
  }
}

fragment PlacesOfWorkGrid_placeOfWork on PlaceOfWork {
  id
  placeOfWorkId
  name
  countries
  status
  source
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePlaceOfWorkModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePlaceOfWorkPayload",
        "kind": "LinkedField",
        "name": "createPlaceOfWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceOfWork",
            "kind": "LinkedField",
            "name": "placeOfWork",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PlacesOfWorkGrid_placeOfWork"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePlaceOfWorkModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePlaceOfWorkPayload",
        "kind": "LinkedField",
        "name": "createPlaceOfWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlaceOfWork",
            "kind": "LinkedField",
            "name": "placeOfWork",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "placeOfWorkId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countries",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "source",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee4a56b3b1be3562c1aa87c1e1a3cb8d",
    "id": null,
    "metadata": {},
    "name": "CreatePlaceOfWorkModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePlaceOfWorkModalMutation(\n  $input: CreatePlaceOfWorkInput!\n) {\n  createPlaceOfWork(input: $input) {\n    placeOfWork {\n      id\n      ...PlacesOfWorkGrid_placeOfWork\n    }\n  }\n}\n\nfragment PlacesOfWorkGrid_placeOfWork on PlaceOfWork {\n  id\n  placeOfWorkId\n  name\n  countries\n  status\n  source\n}\n"
  }
};
})();
(node as any).hash = 'f2ec59b2058cd80c8f6b24e5b670a6bd';
export default node;
