/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ApplicationVersionRequirementsPage_applicationVersionRequirement = {
    readonly id: string;
    readonly applicationIdentifier: string | null;
    readonly minimumVersion: string | null;
    readonly " $refType": "ApplicationVersionRequirementsPage_applicationVersionRequirement";
};
export type ApplicationVersionRequirementsPage_applicationVersionRequirement$data = ApplicationVersionRequirementsPage_applicationVersionRequirement;
export type ApplicationVersionRequirementsPage_applicationVersionRequirement$key = {
    readonly " $data"?: ApplicationVersionRequirementsPage_applicationVersionRequirement$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ApplicationVersionRequirementsPage_applicationVersionRequirement">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationVersionRequirementsPage_applicationVersionRequirement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumVersion",
      "storageKey": null
    }
  ],
  "type": "ApplicationVersionRequirement",
  "abstractKey": null
};
(node as any).hash = 'c5c620e0272d0d32ce06d4a37e70d1a5';
export default node;
