import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import organizationTableHeadings from '../messages/organizationTableHeadings';
import CopyStudyModal from './CopyStudyModal';
import CreateOrganizationModal from './CreateOrganizationModal';
import { OrganizationsPage_viewer$key } from './__generated__/OrganizationsPage_viewer.graphql';

const resource = Resource.ORGANIZATIONS;

const gridFilterFields: FilterField[] = [
  { title: tableHeadings.organizationId.defaultMessage, field: 'id' },
  { title: tableHeadings.customerId.defaultMessage, field: 'customerId' },
  {
    title: tableHeadings.subscriptionId.defaultMessage,
    field: 'subscriptionId',
  },
  { title: tableHeadings.domainId.defaultMessage, field: 'domainId' },
  {
    title: tableHeadings.stripeSubscriptionId.defaultMessage,
    field: 'stripeSubscriptionId',
  },
  { title: organizationTableHeadings.slug.defaultMessage, field: 'slug' },
  { title: tableHeadings.organizationName.defaultMessage, field: 'name' },
  {
    title: organizationTableHeadings.specialType.defaultMessage,
    field: 'specialType',
  },
];

const fragment = graphql`
  fragment OrganizationsPage_viewer on Viewer {
    ...CreateOrganizationModal_viewer
  }
`;

interface OrganizationsPageProps {
  viewer: OrganizationsPage_viewer$key;
}

export default function OrganizationsPage({
  viewer,
  children,
}: PropsWithChildren<OrganizationsPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  const [studyId, setStudyId] = useState('');
  useBreadcrumbDetector(pageTitles.organizations);

  const onCopyStudy = (response) => {
    const newStudyId = response?.copyStudy?.study?.studyId;
    if (newStudyId) setStudyId(newStudyId);
  };

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.organizations} />
        </Header.Title>
        <Layout.Spacer />
        <Layout className="flex-col sm:flex-row sm:justify-between">
          <ResourceGridAction
            resource={Resource.STUDIES}
            renderActionModal={(props) => (
              <CopyStudyModal onCompleted={onCopyStudy} {...props} />
            )}
          >
            {studyId || (
              <FormattedMessage
                id="organizationsPage.copyStudy"
                defaultMessage="Copy Study"
              />
            )}
          </ResourceGridAction>
          <ResourceGridAction
            className="sm:ml-3"
            resource={resource}
            renderActionModal={(props) => (
              <CreateOrganizationModal viewerRef={viewerRef} {...props} />
            )}
          >
            <FormattedMessage
              id="organizationsPage.createOrganization"
              defaultMessage="Create Organization"
            />
          </ResourceGridAction>
        </Layout>
      </SectionHeader>

      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
