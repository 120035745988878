import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { educationVideoMessages } from '../messages/formMessages';
import {
  EducationVideoModal,
  educationVideoSchema,
} from './EducationVideoModal';
import { UpdateEducationVideoModalMutation } from './__generated__/UpdateEducationVideoModalMutation.graphql';
import { UpdateEducationVideoModal_educationContent$key } from './__generated__/UpdateEducationVideoModal_educationContent.graphql';

const fragment = graphql`
  fragment UpdateEducationVideoModal_educationContent on EducationContent {
    id
    educationContentId
    name
    description
    type
    practiceType
    isPremium
    vimeoId
    presetLocalIds
    toolLocalIds
    captureModes
    authoredBy {
      userId
    }
    category {
      educationCategoryId
    }
    ranking
    duration
  }
`;

const updateMutation = graphql`
  mutation UpdateEducationVideoModalMutation(
    $input: UpdateEducationContentInput!
  ) {
    updateEducationContent(input: $input) {
      educationContent {
        id
        ...EducationContentPage_educationContent
      }
    }
  }
`;

interface UpdateEducationVideoModalProps extends ActionModalProps {
  educationVideoRef: UpdateEducationVideoModal_educationContent$key;
}

export function UpdateEducationVideoModal({
  show,
  onClose,
  educationVideoRef,
}: UpdateEducationVideoModalProps) {
  const educationVideo = useFragment(fragment, educationVideoRef);

  const transformedDefaultValue = useMemo(
    () => ({
      ...educationVideo,
      authoredBy: educationVideo.authoredBy?.userId,
      categoryId: educationVideo.category?.educationCategoryId,
      presetIds: educationVideo.presetLocalIds ?? [],
      toolIds: educationVideo.toolLocalIds ?? [],
    }),
    [educationVideo],
  );

  return (
    <EducationVideoModal<UpdateEducationVideoModalMutation>
      title={<FormattedMessage {...educationVideoMessages.update} />}
      name={educationVideo.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      schema={educationVideoSchema}
      mutation={updateMutation}
      defaultValue={transformedDefaultValue}
    />
  );
}
