import CheckIcon from '@bfly/icons/CheckMarkSmall';
import Text from '@bfly/ui2/Text';
import React from 'react';

interface DataGridBooleanBadgeProps {
  value: boolean | undefined | null;
}

export default function DataGridBoolean({ value }: DataGridBooleanBadgeProps) {
  return value ? (
    <Text color="primary">
      <CheckIcon />
    </Text>
  ) : (
    <Text>-</Text>
  );
}
