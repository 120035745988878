import { RefForwardingComponentWithAs } from '@bfly/ui2/types';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import clsx from 'clsx';
import { useScrollContainer } from 'found-scroll';
import React from 'react';

interface ScrollViewProps {
  as?: React.ElementType;

  /**
   * A key identifying a single element per page through time.
   * There can `scrollKey` only be one ScrollView with a given key rendered
   * at one time.
   *
   * > Note: set to `null` to disable scroll restoration on navigating back
   */
  scrollKey: string | null;

  className?: string;
}

/**
 * Create a scrollable area with styled scrollbars as well as optional
 * scroll restoration when navigating back to the containing page.
 */
const ScrollView: RefForwardingComponentWithAs<'div', ScrollViewProps> =
  React.forwardRef(
    (
      { as: Tag = 'div', scrollKey, className, ...props }: ScrollViewProps,
      ref,
    ) => {
      const scrollRef = useScrollContainer(scrollKey);

      return (
        <Tag
          ref={useMergedRefs(ref, scrollRef)}
          className={clsx(className, 'scrollable-y')}
          {...props}
        />
      );
    },
  );

ScrollView.displayName = 'ScrollView';

export default ScrollView;
