export default [
  {
    value: 'UPLOAD',
    label: 'Upload',
  },
  {
    value: 'CAPTURE',
    label: 'Capture',
  },
];
