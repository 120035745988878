/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type SetDomainUserTypeInput = {
    domainUserId: string;
    type: DomainUserType;
    clientMutationId?: string | null | undefined;
};
export type SetDomainUserTypeActionMutationVariables = {
    input: SetDomainUserTypeInput;
};
export type SetDomainUserTypeActionMutationResponse = {
    readonly setDomainUserType: {
        readonly domainUser: {
            readonly domainUserId: string | null;
            readonly type: DomainUserType | null;
        } | null;
    } | null;
};
export type SetDomainUserTypeActionMutation = {
    readonly response: SetDomainUserTypeActionMutationResponse;
    readonly variables: SetDomainUserTypeActionMutationVariables;
};



/*
mutation SetDomainUserTypeActionMutation(
  $input: SetDomainUserTypeInput!
) {
  setDomainUserType(input: $input) {
    domainUser {
      domainUserId
      type
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainUserId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetDomainUserTypeActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetDomainUserTypePayload",
        "kind": "LinkedField",
        "name": "setDomainUserType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUser",
            "kind": "LinkedField",
            "name": "domainUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetDomainUserTypeActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetDomainUserTypePayload",
        "kind": "LinkedField",
        "name": "setDomainUserType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUser",
            "kind": "LinkedField",
            "name": "domainUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dede5b4b69f2065e505752f0614780cd",
    "id": null,
    "metadata": {},
    "name": "SetDomainUserTypeActionMutation",
    "operationKind": "mutation",
    "text": "mutation SetDomainUserTypeActionMutation(\n  $input: SetDomainUserTypeInput!\n) {\n  setDomainUserType(input: $input) {\n    domainUser {\n      domainUserId\n      type\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c9b1067c60a8fc75ec98b89f0e09144f';
export default node;
