/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MigrateEnterpriseWorkflowAction_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly " $refType": "MigrateEnterpriseWorkflowAction_domain";
};
export type MigrateEnterpriseWorkflowAction_domain$data = MigrateEnterpriseWorkflowAction_domain;
export type MigrateEnterpriseWorkflowAction_domain$key = {
    readonly " $data"?: MigrateEnterpriseWorkflowAction_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MigrateEnterpriseWorkflowAction_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MigrateEnterpriseWorkflowAction_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = 'f6700c38e846c6e8f0f3a0a0bfb16c5f';
export default node;
