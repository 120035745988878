import React from 'react';
import { graphql, useFragment } from 'react-relay';

import UndeleteAction from 'components/UndeleteAction';

import { UndeleteFlatUserAction_user$key } from './__generated__/UndeleteFlatUserAction_user.graphql';

const fragment = graphql`
  fragment UndeleteFlatUserAction_user on FlatUser {
    name
    flatUserId
    userDeletedAt
    domainUserDeletedAt
  }
`;

const mutation = graphql`
  mutation UndeleteFlatUserActionMutation($input: UndeleteFlatUserInput!) {
    undeleteFlatUser(input: $input) {
      flatUser {
        ...UndeleteFlatUserAction_user @relay(mask: false)
      }
    }
  }
`;

interface UndeleteFlatUserActionProps {
  userRef: UndeleteFlatUserAction_user$key;
}

export default function UndeleteFlatUserAction({
  userRef,
}: UndeleteFlatUserActionProps) {
  const user = useFragment(fragment, userRef);
  return (
    <UndeleteAction
      mutation={mutation}
      itemName={user.name ?? 'User'}
      input={{ flatUserId: user.flatUserId }}
    />
  );
}
