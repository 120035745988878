import MutationButton from '@bfly/ui2/MutationButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { ResendUserInviteActionMutation } from './__generated__/ResendUserInviteActionMutation.graphql';
import { ResendUserInviteAction_userInvite$key } from './__generated__/ResendUserInviteAction_userInvite.graphql';

const fragment = graphql`
  fragment ResendUserInviteAction_userInvite on UserInvite {
    id
    userInviteId
    usedAt
    user {
      userId
    }
    domain {
      domainId
    }
  }
`;

const mutation = graphql`
  mutation ResendUserInviteActionMutation($input: ResendUserInviteInput!) {
    resendUserInvite(input: $input) {
      __typename
    }
  }
`;

interface ResendUserInviteActionProps {
  userInviteRef: ResendUserInviteAction_userInvite$key;
}

export default function ResendUserInviteAction({
  userInviteRef,
}: ResendUserInviteActionProps) {
  const data = useFragment(fragment, userInviteRef);
  const { domain, user, usedAt } = data;
  return (
    <PermissionsGuard resource={Resource.DOMAIN_INVITES} write hideContent>
      <MutationButton<ResendUserInviteActionMutation>
        mutation={mutation}
        input={{ domainId: domain!.domainId!, userId: user!.userId! }}
        variant="secondary"
        disabled={!!usedAt}
        successToastMessage={
          <FormattedMessage
            id="ResendUserInviteAction.success"
            defaultMessage="Invitation resent successfully"
          />
        }
        errorToastMessage={
          <FormattedMessage
            id="ResendUserInviteAction.error"
            defaultMessage="An error has occurred resending the invitation"
          />
        }
      >
        <FormattedMessage
          id="ResendUserInviteAction.requestReview"
          defaultMessage="Resend Invitation"
        />
      </MutationButton>
    </PermissionsGuard>
  );
}
