import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridArray from 'components/DataGridArray';
import DataGridBoolean from 'components/DataGridBoolean';
import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';

import domainTableHeadings from '../messages/tableHeadings';
import { DomainsGrid_domain as Domain } from './__generated__/DomainsGrid_domain.graphql';
import { DomainsGrid_viewer$key } from './__generated__/DomainsGrid_viewer.graphql';

const _ = graphql`
  fragment DomainsGrid_domain on Domain {
    id
    domainId
    createdAt
    deletedAt
    subdomainLabel
    name
    enableButterflyLogin
    enableSamlLogin
    enableSharedDeviceCodeLogin
    samlEmailAttributeName
    samlEmailAttributeNameRaw
    samlNicknameAttributeName
    samlNicknameAttributeNameRaw
    cognitoClientId
    inactivityTimeoutSeconds
    requiredAndroidCapabilities
    sharedDeviceKey
    numUsers
    maxNumSeats
    numAvailableSeats
    country
  }
`;

const fragment = graphql`
  fragment DomainsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    id: { type: "[String!]" }
    subdomainLabel: { type: "[String!]" }
  )
  @refetchable(queryName: "DomainsGridRefetchQuery") {
    domains(
      first: $count
      after: $cursor
      id: $id
      subdomainLabel: $subdomainLabel
    ) @connection(key: "DomainsGrid_domains") {
      edges {
        node {
          ...DomainsGrid_domain @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Domain>[] = [
  {
    key: 'name',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridLink path="/domains" id={item.domainId} title={item.name!} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domainId}>
        <DataGridLink path="/domains" id={item.domainId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'subdomainLabel',
    label: <FormattedMessage {...domainTableHeadings.subdomainLabel} />,
    render: ({ item }) => <DataGridText value={item.subdomainLabel} />,
  },
  {
    key: 'createdAt',
    label: <FormattedMessage {...tableHeadings.createdAt} />,
    render: ({ item }) => <DataGridDateTime value={item.createdAt} />,
  },
  {
    key: 'deletedAt',
    label: <FormattedMessage {...tableHeadings.deletedAt} />,
    render: ({ item }) => <DataGridDateTime value={item.deletedAt} />,
  },
  {
    key: 'enableButterflyLogin',
    label: <FormattedMessage {...domainTableHeadings.enableButterflyLogin} />,
    render: ({ item }) => (
      <DataGridBoolean value={item.enableButterflyLogin} />
    ),
  },
  {
    key: 'enableSamlLogin',
    label: <FormattedMessage {...domainTableHeadings.enableSamlLogin} />,
    render: ({ item }) => <DataGridBoolean value={item.enableSamlLogin} />,
  },
  {
    key: 'enableSharedDeviceCodeLogin',
    label: (
      <FormattedMessage {...domainTableHeadings.enableSharedDeviceCodeLogin} />
    ),
    render: ({ item }) => (
      <DataGridBoolean value={item.enableSharedDeviceCodeLogin} />
    ),
  },
  {
    key: 'samlEmailAttributeName',
    label: (
      <FormattedMessage {...domainTableHeadings.samlEmailAttributeName} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.samlEmailAttributeName}>
        <DataGridText value={item.samlEmailAttributeName} />
      </QuickCopyText>
    ),
  },
  {
    key: 'samlEmailAttributeNameRaw',
    label: (
      <FormattedMessage {...domainTableHeadings.samlEmailAttributeNameRaw} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.samlEmailAttributeNameRaw}>
        <DataGridText value={item.samlEmailAttributeNameRaw} />
      </QuickCopyText>
    ),
  },
  {
    key: 'samlNicknameAttributeName',
    label: (
      <FormattedMessage {...domainTableHeadings.samlNicknameAttributeName} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.samlNicknameAttributeName}>
        <DataGridText value={item.samlNicknameAttributeName} />
      </QuickCopyText>
    ),
  },
  {
    key: 'samlNicknameAttributeNameRaw',
    label: (
      <FormattedMessage
        {...domainTableHeadings.samlNicknameAttributeNameRaw}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.samlNicknameAttributeNameRaw}>
        <DataGridText value={item.samlNicknameAttributeNameRaw} />
      </QuickCopyText>
    ),
  },
  {
    key: 'cognitoClientId',
    label: <FormattedMessage {...domainTableHeadings.cognitoClientId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.cognitoClientId}>
        <DataGridText value={item.cognitoClientId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'inactivityTimeoutSeconds',
    label: (
      <FormattedMessage {...domainTableHeadings.inactivityTimeoutSeconds} />
    ),
    render: ({ item }) => (
      <DataGridText value={item.inactivityTimeoutSeconds} />
    ),
  },
  {
    key: 'requiredAndroidCapabilities',
    label: (
      <FormattedMessage {...domainTableHeadings.requiredAndroidCapabilities} />
    ),
    render: ({ item }) => (
      <DataGridArray value={item.requiredAndroidCapabilities} />
    ),
  },
  {
    key: 'sharedDeviceKey',
    label: <FormattedMessage {...domainTableHeadings.sharedDeviceKey} />,
    render: ({ item }) => (
      <QuickCopyText text={item.sharedDeviceKey}>
        <DataGridText value={item.sharedDeviceKey} />
      </QuickCopyText>
    ),
  },
  {
    key: 'numUsers',
    label: <FormattedMessage {...domainTableHeadings.numUsers} />,
    render: ({ item }) => <DataGridText value={item.numUsers} />,
  },
  {
    key: 'maxNumSeats',
    label: <FormattedMessage {...domainTableHeadings.maxNumSeats} />,
    render: ({ item }) => <DataGridText value={item.maxNumSeats} />,
  },
  {
    key: 'numAvailableSeats',
    label: <FormattedMessage {...domainTableHeadings.numAvailableSeats} />,
    render: ({ item }) => <DataGridText value={item.numAvailableSeats} />,
  },
];

interface DomainsGridProps {
  viewer: DomainsGrid_viewer$key;
}

export default function DomainsGrid({ viewer: viewerRef }: DomainsGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(
    fragment,
    viewerRef,
  );
  const nodes = data ? getNodes(data.domains) : [];

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
      getRowProps={({ deletedAt }) => ({
        classNames: deletedAt ? deletedClass : '',
      })}
    />
  );
}
