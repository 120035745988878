/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MdmSettingsPage_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly name: string | null;
    readonly shouldAuthorizeHostDevices: boolean | null;
    readonly hostDeviceSecret: string | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateMdmSettingsModal_domain" | "ResetHostDeviceSecretAction_domain">;
    readonly " $refType": "MdmSettingsPage_domain";
};
export type MdmSettingsPage_domain$data = MdmSettingsPage_domain;
export type MdmSettingsPage_domain$key = {
    readonly " $data"?: MdmSettingsPage_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MdmSettingsPage_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MdmSettingsPage_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldAuthorizeHostDevices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hostDeviceSecret",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateMdmSettingsModal_domain"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResetHostDeviceSecretAction_domain"
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = 'be9ebd0ddf69a0a6146d3dbcc2529bdb';
export default node;
