import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import domainInvitesMessages from '../messages/domainInvitesMessages';
import CreateUserInviteModal from './CreateUserInviteModal';
import { DomainInvitesPage_viewer$key } from './__generated__/DomainInvitesPage_viewer.graphql';

const resource = Resource.DOMAIN_INVITES;

const gridFilterFields: FilterField[] = [
  {
    title: tableHeadings.domainId.defaultMessage,
    field: 'domainId',
  },

  { title: tableHeadings.email.defaultMessage, field: 'email' },
];

const fragment = graphql`
  fragment DomainInvitesPage_viewer on Viewer {
    ...CreateUserInviteModal_viewer
  }
`;

interface DomainInvitesPageProps {
  viewer: DomainInvitesPage_viewer$key;
}

export default function DomainInvitesPage({
  viewer,
  children,
}: PropsWithChildren<DomainInvitesPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.domainInvites);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.domainInvites} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateUserInviteModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage {...domainInvitesMessages.createInvite} />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
