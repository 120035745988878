import { defineMessages } from 'react-intl';

export default defineMessages({
  auth0Id: {
    id: 'userTable.heading.auth0Id',
    defaultMessage: 'Auth0 ID',
  },
  canFinalize: {
    id: 'userTable.heading.canFinalize',
    defaultMessage: 'Can Sign Studies',
  },
  hasTableauDashboardAccess: {
    id: 'userTable.heading.hasTableauDashboardAccess',
    defaultMessage: 'Has Tableau Dashboard Access',
  },
  hasUnacceptedInvite: {
    id: 'userTable.heading.hasUnacceptedInvite',
    defaultMessage: 'Has Unaccepted Invite',
  },
  specialtyKey: {
    id: 'userTable.heading.specialtyKey',
    defaultMessage: 'Specialty Key',
  },
  specialtyFreeform: {
    id: 'userTable.heading.specialtyFreeform',
    defaultMessage: 'Specialty Freeform',
  },
  placeOfWorkId: {
    id: 'userTable.heading.placeOfWorkId',
    defaultMessage: 'Place Of Work ID',
  },
  placeOfWorkName: {
    id: 'userTable.heading.placeOfWorkName',
    defaultMessage: 'Place Of Work Name',
  },
  placeOfWorkFreeform: {
    id: 'userTable.heading.placeOfWorkFreeform',
    defaultMessage: 'Place Of Work Freeform',
  },
  setupAt: {
    id: 'userTable.heading.setupAt',
    defaultMessage: 'Setup At',
  },
  lastReviewRequestedAt: {
    id: 'userTable.heading.lastReviewRequestedAt',
    defaultMessage: 'Last Review Requested At',
  },
  lastCloudReviewRequestTriggeredAt: {
    id: 'userTable.heading.lastCloudReviewRequestTriggeredAt',
    defaultMessage: 'Last Cloud Review Request Triggered At',
  },
  lastImmediateReviewRequestTriggeredAt: {
    id: 'userTable.heading.lastImmediateReviewRequestTriggeredAt',
    defaultMessage: 'Last Immediate Review Request Triggered At',
  },
});
