import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';

import { getCountryName } from '../../organizations/utils/countries';
import placeOfWorkTableHeadings from '../messages/tableHeadings';
import { PlacesOfWorkGrid_placeOfWork as PlaceOfWork } from './__generated__/PlacesOfWorkGrid_placeOfWork.graphql';
import { PlacesOfWorkGrid_viewer$key } from './__generated__/PlacesOfWorkGrid_viewer.graphql';

const _ = graphql`
  fragment PlacesOfWorkGrid_placeOfWork on PlaceOfWork {
    id
    placeOfWorkId
    name
    countries
    status
    source
  }
`;

const fragment = graphql`
  fragment PlacesOfWorkGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    name: { type: "[String!]" }
  )
  @refetchable(queryName: "PlacesOfWorkGridRefetchQuery") {
    placesOfWork(first: $count, after: $cursor, name: $name)
      @connection(key: "PlacesOfWorkGrid_placesOfWork") {
      edges {
        node {
          ...PlacesOfWorkGrid_placeOfWork @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<PlaceOfWork>[] = [
  {
    key: 'placeOfWorkId',
    label: <FormattedMessage {...placeOfWorkTableHeadings.placeOfWorkId} />,
    frozen: true,
    render: ({ item }) => (
      <QuickCopyText text={item.placeOfWorkId}>
        <DataGridLink path="/place-of-work" id={item.placeOfWorkId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'name',
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridText value={item.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'countries',
    label: <FormattedMessage {...placeOfWorkTableHeadings.countries} />,
    render: ({ item }) => (
      <DataGridText value={item.countries?.map(getCountryName).join(', ')} />
    ),
  },
  {
    key: 'status',
    label: <FormattedMessage {...tableHeadings.status} />,
    render: ({ item }) => <DataGridText value={item.status} />,
  },
  {
    key: 'source',
    label: <FormattedMessage {...placeOfWorkTableHeadings.source} />,
    render: ({ item }) => <DataGridText value={item.source} />,
  },
];

interface PlacesOfWorkGridProps {
  viewer: PlacesOfWorkGrid_viewer$key;
}

export default function PlacesOfWorkGrid({
  viewer: viewerRef,
}: PlacesOfWorkGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(
    fragment,
    viewerRef,
  );
  const nodes = getNodes(data!.placesOfWork);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
    />
  );
}
