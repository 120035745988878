import { defineMessages } from 'react-intl';

export const domainIdMessages = defineMessages({
  label: {
    id: 'domainModal.domainIdMessages.label',
    defaultMessage: 'Domain ID*',
  },
  placeholder: {
    id: 'domainModal.domainIdMessages.placeholder',
    defaultMessage: 'Domain ID',
  },
  required: {
    id: 'domainModal.domainIdMessages.required',
    defaultMessage: 'Please enter domain id',
  },
});

export const nameMessages = defineMessages({
  label: {
    id: 'domainModal.nameMessages.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'domainModal.nameMessages.placeholder',
    defaultMessage: 'Name',
  },
  description: {
    id: 'domainModal.nameMessages.description',
    defaultMessage: 'Human friendly name for the domain',
  },
  required: {
    id: 'domainModal.nameMessages.required',
    defaultMessage: 'Please enter domain name',
  },
});

export const samlEmailAttributeNameRawMessages = defineMessages({
  label: {
    id: 'domainModal.samlEmailAttributeNameRawMessages.label',
    defaultMessage: 'SAML Email Attribute Name Raw',
  },
  placeholder: {
    id: 'domainModal.samlEmailAttributeNameRawMessages.placeholder',
    defaultMessage: 'SAML Email Attribute Name Raw',
  },
  description: {
    id: 'domainModal.samlEmailAttributeNameRawMessages.description',
    defaultMessage: 'Leave blank to use the default attribute name',
  },
});

export const samlNicknameAttributeNameRawMessages = defineMessages({
  label: {
    id: 'domainModal.samlNicknameAttributeNameRawMessages.label',
    defaultMessage: 'SAML Nickname Attribute Name Raw',
  },
  placeholder: {
    id: 'domainModal.samlNicknameAttributeNameRawMessages.placeholder',
    defaultMessage: 'SAML Nickname Attribute Name Raw',
  },
  description: {
    id: 'domainModal.samlNicknameAttributeNameRawMessages.description',
    defaultMessage: 'Leave blank to use the default attribute name',
  },
});

export const requiredAndroidCapabilitiesMessages = defineMessages({
  label: {
    id: 'domainModal.requiredAndroidCapabilitiesMessages.label',
    defaultMessage: 'Required Android Capabilities',
  },
  placeholder: {
    id: 'domainModal.requiredAndroidCapabilitiesMessages.placeholder',
    defaultMessage: 'Required Android Capabilities',
  },
  description: {
    id: 'domainModal.requiredAndroidCapabilitiesMessages.description',
    defaultMessage: 'List of required capabilities for Android devices',
  },
});

export const enableSharedDeviceCodeLoginMessages = defineMessages({
  label: {
    id: 'domainModal.enableSharedDeviceCodeLoginMessages.label',
    defaultMessage: 'Enable Shared Device Code Login',
  },
  placeholder: {
    id: 'domainModal.enableSharedDeviceCodeLoginMessages.placeholder',
    defaultMessage: 'Enable Shared Device Code Login',
  },
  description: {
    id: 'domainModal.enableSharedDeviceCodeLoginMessages.description',
    defaultMessage: 'Whether or not QR code login is enabled for the domain',
  },
});

export const enableButterflyLoginMessages = defineMessages({
  label: {
    id: 'domainModal.enableButterflyLoginMessages.label',
    defaultMessage: 'Enable Butterfly Login',
  },
  placeholder: {
    id: 'domainModal.enableButterflyLoginMessages.placeholder',
    defaultMessage: 'Enable Butterfly Login',
  },
  description: {
    id: 'domainModal.enableButterflyLoginMessages.description',
    defaultMessage: 'Enable Butterfly Login',
  },
});

export const startDateMessages = defineMessages({
  label: {
    id: 'domainModal.startDateMessages.label',
    defaultMessage: 'Start Date*',
  },
  placeholder: {
    id: 'domainModal.startDateMessages.placeholder',
    defaultMessage: 'Start Date',
  },
  required: {
    id: 'domainModal.startDateMessages.required',
    defaultMessage: 'Please enter start date',
  },
});
export const endDateMessages = defineMessages({
  label: {
    id: 'domainModal.endDateMessages.label',
    defaultMessage: 'End Date*',
  },
  placeholder: {
    id: 'domainModal.endDateMessages.placeholder',
    defaultMessage: 'End Date',
  },
  required: {
    id: 'domainModal.endDateMessages.required',
    defaultMessage: 'Please enter end date',
  },
});

export const shouldAuthorizeHostDevicesMessages = defineMessages({
  label: {
    id: 'mdmSettingsModal.shouldAuthorizeHostDevicesMessages.label',
    defaultMessage: 'Restrict Login',
  },
  description: {
    id: 'mdmSettingsModal.shouldAuthorizeHostDevicesMessages.description',
    defaultMessage:
      'Restrict logging into Butterfly iQ to devices provisioned with secret app configuration',
  },
});

export const maxNumSeatsMessages = defineMessages({
  label: {
    id: 'domainModal.maxNumSeatsMessages.label',
    defaultMessage: 'Maximum Number of Seats',
  },
  placeholder: {
    id: 'domainModal.maxNumSeatsMessages.placeholder',
    defaultMessage: 'Maximum Number of Seats',
  },
  description: {
    id: 'domainModal.maxNumSeatsMessages.description',
    defaultMessage: 'Maximum Number of Seats can be null or positive integer',
  },
});

export const countryMessages = defineMessages({
  label: {
    id: 'country.label',
    defaultMessage: 'Domain Country',
  },
  update: {
    id: 'country.update',
    defaultMessage: `Updating the domain's country will update all related organizations' countries to match.`,
  },
  danger: {
    id: 'country.danger',
    defaultMessage: `CAUTION: Updating this value can have serious implications for related organizations' data storage.`,
  },
});
