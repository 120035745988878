import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { UserModal, basicUserSchema } from './UserModal';
import { UpdateUserModalMutation } from './__generated__/UpdateUserModalMutation.graphql';
import { UpdateUserModal_user$key } from './__generated__/UpdateUserModal_user.graphql';

const updateUserSchema = basicUserSchema.shape({
  flatUserId: string(),
  userId: string(),

  userName: string(),
  domainUserName: string().nullable(),
});

const fragment = graphql`
  fragment UpdateUserModal_user on FlatUser {
    flatUserId
    auth0Id
    userId
    name
    userName
    domainUserName
    email
    hasUnacceptedInvite: hasPendingOrganizationInvite
    specialtyKey
    specialtyFreeform
    phoneNumber
    placeOfWork {
      placeOfWorkId
      name
    }
    placeOfWorkFreeform
    setupAt
    userCreatedAt
    userDeletedAt
    lastReviewRequestedAt
    lastCloudReviewRequestTriggeredAt
    lastImmediateReviewRequestTriggeredAt
    isImplicitPublic
    domainId
    type
    domainUserCreatedAt
    domainUserDeletedAt
    integrationDisplayNameFirst
    integrationDisplayNameMiddle
    integrationDisplayNameLast
    integrationConfigs {
      domainUserIntegrationConfigId
    }
    hasTableauDashboardAccess
    isNurse
    canFinalize
    canQa
    customUserPermissionsEnabled
  }
`;

const updateMutation = graphql`
  mutation UpdateUserModalMutation($input: UpdateFlatUserInput!) {
    updateFlatUser(input: $input) {
      flatUser {
        ...FlatUsersGrid_user
      }
    }
  }
`;

interface UpdateUserModalProps extends ActionModalProps {
  userRef: UpdateUserModal_user$key;
}

export function UpdateUserModal({
  show,
  onClose,
  userRef,
}: UpdateUserModalProps) {
  const user = useFragment(fragment, userRef);

  const defaultValue = useMemo(
    () => ({
      ...updateUserSchema.cast(user, { stripUnknown: true }),
      specialtyKey: user.specialtyKey,
    }),
    [user],
  );
  return (
    <UserModal<UpdateUserModalMutation>
      title={
        <FormattedMessage
          id="updateUserModal.title"
          defaultMessage="Update User"
        />
      }
      name={user.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      isImplicitPublicUser={!!user.isImplicitPublic}
      schema={updateUserSchema}
      mutation={updateMutation}
      defaultValue={defaultValue}
    />
  );
}
