/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type butterflyDevices_ButterflyDeviceDetailPage_QueryVariables = {
    productionId: string;
};
export type butterflyDevices_ButterflyDeviceDetailPage_QueryResponse = {
    readonly butterflyDevice: {
        readonly " $fragmentRefs": FragmentRefs<"ButterflyDeviceDetailPage_butterflyDevice">;
    } | null;
};
export type butterflyDevices_ButterflyDeviceDetailPage_Query = {
    readonly response: butterflyDevices_ButterflyDeviceDetailPage_QueryResponse;
    readonly variables: butterflyDevices_ButterflyDeviceDetailPage_QueryVariables;
};



/*
query butterflyDevices_ButterflyDeviceDetailPage_Query(
  $productionId: String!
) {
  butterflyDevice(productionId: $productionId) {
    ...ButterflyDeviceDetailPage_butterflyDevice
    id
  }
}

fragment ButterflyDeviceDetailPage_butterflyDevice on ButterflyDevice {
  ...DeregisterAction_butterflyDevice
  ...RequestDiagnosticTestAction_butterflyDevice
  ...DeleteButterflyDeviceAction_butterflyDevice
  ...SetProbeStatusAction_butterflyDevice
  id
  productionId
  registeredAt
  isEnabled
  status
  disabledStatusMessage
  lostAt
  stolenAt
  brickedAt
  brickedReason
  activeReason
  lostNote
  stolenNote
  recallId
  subscription {
    subscriptionId
    id
  }
  hasActiveDiagnosticTestRequest
  lastDiagnosticTestRequestMinTestVersion
}

fragment DeleteButterflyDeviceAction_butterflyDevice on ButterflyDevice {
  id
  productionId
}

fragment DeregisterAction_butterflyDevice on ButterflyDevice {
  id
  productionId
}

fragment RequestDiagnosticTestAction_butterflyDevice on ButterflyDevice {
  id
  productionId
  lastDiagnosticTestRequestMinTestVersion
}

fragment SetProbeStatusAction_butterflyDevice on ButterflyDevice {
  id
  productionId
  recallId
  brickedAt
  brickedReason
  activeReason
  stolenNote
  lostNote
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "productionId",
    "variableName": "productionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "butterflyDevices_ButterflyDeviceDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ButterflyDevice",
        "kind": "LinkedField",
        "name": "butterflyDevice",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ButterflyDeviceDetailPage_butterflyDevice"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "butterflyDevices_ButterflyDeviceDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ButterflyDevice",
        "kind": "LinkedField",
        "name": "butterflyDevice",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastDiagnosticTestRequestMinTestVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recallId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brickedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "brickedReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stolenNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lostNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disabledStatusMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lostAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stolenAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subscriptionId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasActiveDiagnosticTestRequest",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77f24475605f2ea76f360571838c4756",
    "id": null,
    "metadata": {},
    "name": "butterflyDevices_ButterflyDeviceDetailPage_Query",
    "operationKind": "query",
    "text": "query butterflyDevices_ButterflyDeviceDetailPage_Query(\n  $productionId: String!\n) {\n  butterflyDevice(productionId: $productionId) {\n    ...ButterflyDeviceDetailPage_butterflyDevice\n    id\n  }\n}\n\nfragment ButterflyDeviceDetailPage_butterflyDevice on ButterflyDevice {\n  ...DeregisterAction_butterflyDevice\n  ...RequestDiagnosticTestAction_butterflyDevice\n  ...DeleteButterflyDeviceAction_butterflyDevice\n  ...SetProbeStatusAction_butterflyDevice\n  id\n  productionId\n  registeredAt\n  isEnabled\n  status\n  disabledStatusMessage\n  lostAt\n  stolenAt\n  brickedAt\n  brickedReason\n  activeReason\n  lostNote\n  stolenNote\n  recallId\n  subscription {\n    subscriptionId\n    id\n  }\n  hasActiveDiagnosticTestRequest\n  lastDiagnosticTestRequestMinTestVersion\n}\n\nfragment DeleteButterflyDeviceAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n}\n\nfragment DeregisterAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n}\n\nfragment RequestDiagnosticTestAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n  lastDiagnosticTestRequestMinTestVersion\n}\n\nfragment SetProbeStatusAction_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n  recallId\n  brickedAt\n  brickedReason\n  activeReason\n  stolenNote\n  lostNote\n  status\n}\n"
  }
};
})();
(node as any).hash = '6a7afe282a08c6e92a215df67020aa12';
export default node;
