/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type SetDomainUserTypeAction_user = {
    readonly id: string;
    readonly userId: string | null;
    readonly domainId: string | null;
    readonly type: DomainUserType | null;
    readonly " $refType": "SetDomainUserTypeAction_user";
};
export type SetDomainUserTypeAction_user$data = SetDomainUserTypeAction_user;
export type SetDomainUserTypeAction_user$key = {
    readonly " $data"?: SetDomainUserTypeAction_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SetDomainUserTypeAction_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetDomainUserTypeAction_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "FlatUser",
  "abstractKey": null
};
(node as any).hash = 'dcaf8b2a3d0995cad3afb3de7d4129ca';
export default node;
