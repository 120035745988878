import { defineMessages } from 'react-intl';

export const userInviteIdMessages = defineMessages({
  label: {
    id: 'userInviteId.label',
    defaultMessage: 'User Invite ID',
  },
  labelRequired: {
    id: 'userInviteId.labelRequired',
    defaultMessage: 'User Invite ID*',
  },
  placeholder: {
    id: 'userInviteId.placeholder',
    defaultMessage: 'User Invite ID',
  },
  required: {
    id: 'userInviteId.required',
    defaultMessage: 'User Invite ID is required field',
  },
});

export const domainIdMessages = defineMessages({
  label: {
    id: 'domainId.label',
    defaultMessage: 'Domain ID*',
  },
  placeholder: {
    id: 'domainId.placeholder',
    defaultMessage: 'Domain ID',
  },
  required: {
    id: 'domainId.required',
    defaultMessage: 'Domain ID is required field',
  },
});

export const userIdMessages = defineMessages({
  label: {
    id: 'userId.label',
    defaultMessage: 'User ID*',
  },
  placeholder: {
    id: 'userId.placeholder',
    defaultMessage: 'User ID',
  },
  required: {
    id: 'userId.required',
    defaultMessage: 'User ID is required field',
  },
});

export const showUserInviteLinkMessages = defineMessages({
  title: {
    id: 'showUserInviteLinkModal.title',
    defaultMessage: 'Show Invite Link',
  },
});

export const userInviteLinkUrlMessages = defineMessages({
  label: {
    id: 'userUserInviteLinkUrl.label',
    defaultMessage: 'Link URL',
  },
  copyLink: {
    id: 'showUserInviteLinkUrl.copyLink',
    defaultMessage: 'Copy URL',
  },
  copySuccess: {
    id: 'showUserInviteLinkUrl.copyToClipboard.success',
    defaultMessage: 'Link URL is copied to clipboard',
  },
});
