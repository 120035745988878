/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DisableCaptionHealthInput = {
    subscriptionId: string;
    clientMutationId?: string | null | undefined;
};
export type DisableCaptionHealthActionMutationVariables = {
    input: DisableCaptionHealthInput;
};
export type DisableCaptionHealthActionMutationResponse = {
    readonly disableCaptionHealth: {
        readonly subscription: {
            readonly featureModuleIds: ReadonlyArray<string | null> | null;
        } | null;
    } | null;
};
export type DisableCaptionHealthActionMutation = {
    readonly response: DisableCaptionHealthActionMutationResponse;
    readonly variables: DisableCaptionHealthActionMutationVariables;
};



/*
mutation DisableCaptionHealthActionMutation(
  $input: DisableCaptionHealthInput!
) {
  disableCaptionHealth(input: $input) {
    subscription {
      featureModuleIds
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featureModuleIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DisableCaptionHealthActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableCaptionHealthPayload",
        "kind": "LinkedField",
        "name": "disableCaptionHealth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DisableCaptionHealthActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisableCaptionHealthPayload",
        "kind": "LinkedField",
        "name": "disableCaptionHealth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff7c4e98238ec49e0a3983183c25cd99",
    "id": null,
    "metadata": {},
    "name": "DisableCaptionHealthActionMutation",
    "operationKind": "mutation",
    "text": "mutation DisableCaptionHealthActionMutation(\n  $input: DisableCaptionHealthInput!\n) {\n  disableCaptionHealth(input: $input) {\n    subscription {\n      featureModuleIds\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e70156599962aa4615898a0728a100ac';
export default node;
