/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DlDataMode = "EXCLUDE" | "INCLUDE" | "PARTIAL" | "%future added value";
export type DLDataModeOverrideAction_organization = {
    readonly organizationId: string | null;
    readonly dlDataModeOverride: DlDataMode | null;
    readonly " $refType": "DLDataModeOverrideAction_organization";
};
export type DLDataModeOverrideAction_organization$data = DLDataModeOverrideAction_organization;
export type DLDataModeOverrideAction_organization$key = {
    readonly " $data"?: DLDataModeOverrideAction_organization$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DLDataModeOverrideAction_organization">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DLDataModeOverrideAction_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dlDataModeOverride",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
(node as any).hash = '032d8f3527a0ec3f211cfd5d2442356d';
export default node;
