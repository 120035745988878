import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deactivateMessages } from 'messages/deleteAction';

import { DeleteMembershipAction_membership$key } from './__generated__/DeleteMembershipAction_membership.graphql';

const fragment = graphql`
  fragment DeleteMembershipAction_membership on OrganizationMembership {
    organizationMembershipId
    organization {
      name
    }
  }
`;

const mutation = graphql`
  mutation DeleteMembershipActionMutation(
    $input: DeleteOrganizationMembershipInput!
  ) {
    deleteOrganizationMembership(input: $input) {
      organizationMembership {
        deletedAt
      }
    }
  }
`;

interface DeleteMembershipActionProps {
  membershipRef: DeleteMembershipAction_membership$key;
}

function isLastMembershipError(err) {
  return err?.json?.errors?.some(({ upstream }) => {
    return upstream?.errors.some(
      ({ code }) =>
        code ===
        'invalid_deletion.deleting_last_user_with_user_permission_is_forbidden',
    );
  });
}

function isLastAdminError(error): boolean {
  return error?.json?.errors?.some(
    ({ message }) => message === 'Sole admin cannot be demoted or removed',
  );
}

function getCustomErrorMessage(err, defaultMsg) {
  return isLastMembershipError(err) || isLastAdminError(err)
    ? deactivateMessages.deactiveLastAdminFailed
    : defaultMsg;
}

export default function DeleteMembershipAction({
  membershipRef,
}: DeleteMembershipActionProps) {
  const membership = useFragment(fragment, membershipRef);

  return (
    <DeleteAction
      mutation={mutation}
      itemName={`${membership.organization?.name} Membership` ?? 'Membership'}
      input={{ organizationMembershipId: membership.organizationMembershipId }}
      getCustomErrorMessage={getCustomErrorMessage}
    />
  );
}
