import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteIosVersionBlacklistEntryAction from './DeleteIosVersionBlacklistEntryAction';
import { UpdateIosVersionBlacklistEntryModal } from './UpdateIosVersionBlacklistEntryModal';
import { IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry$key } from './__generated__/IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry.graphql';

const fragment = graphql`
  fragment IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
    ...UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry
    ...DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry
    id
    iosVersionBlacklistEntryId
    applicationIdentifier
    versionRange
    buildNumber
  }
`;

interface IosVersionBlacklistEntryDetailPageProps {
  iosVersionBlacklistEntry: IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry$key;
}

export default function IosVersionBlacklistEntryDetailPage({
  iosVersionBlacklistEntry: iosVersionBlacklistEntryRef,
}: IosVersionBlacklistEntryDetailPageProps) {
  const iosVersionBlacklistEntry = useFragment(
    fragment,
    iosVersionBlacklistEntryRef,
  );
  useBreadcrumbDetector(
    pageTitles.iosVersionBlacklistEntry,
    iosVersionBlacklistEntry.iosVersionBlacklistEntryId,
  );
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.IOS_VERSION_BLACKLIST_ENTRIES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.iosVersionBlacklistEntry} />}
        name={iosVersionBlacklistEntry.iosVersionBlacklistEntryId!}
        data={iosVersionBlacklistEntry}
        quickCopyFields={[
          'iosVersionBlacklistEntryId',
          'applicationIdentifier',
          'buildNumber',
        ]}
        crudActions={
          canWrite(Resource.IOS_VERSION_BLACKLIST_ENTRIES) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateIosVersionBlacklistEntryModal
                    {...props}
                    iosVersionBlacklistEntryRef={iosVersionBlacklistEntry}
                  />
                )}
              />
              <DeleteIosVersionBlacklistEntryAction
                iosVersionBlacklistEntryRef={iosVersionBlacklistEntry}
              />
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
