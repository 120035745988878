import { createFetch } from 'relay-network-layer';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import { signIn } from 'utils/authActions';
import TokenStorage from 'utils/tokenStorage';

function assignAuthHeader(tokenStorage: TokenStorage) {
  return () => {
    const token = tokenStorage.getToken();
    if (!token) {
      signIn();
      throw new Error('no token data available');
    }
    const headers = new Headers();
    headers.set('Authorization', `Bearer azure:${token}`);
    return { headers };
  };
}

function fetchRelay(tokenStorage: TokenStorage, upstreamUrl: string) {
  return createFetch({
    url: `${upstreamUrl}/_admin/graphql`,
    init: assignAuthHeader(tokenStorage),
  });
}

export default function generateEnvironment(
  tokenStorage: TokenStorage,
  upstreamUrl: string,
) {
  return new Environment({
    network: Network.create(fetchRelay(tokenStorage, upstreamUrl)),
    store: new Store(new RecordSource()),
  });
}
