/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateButterflyDeviceRecallInput = {
    recallId: string;
    message?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateButterflyDeviceRecallModalMutationVariables = {
    input: UpdateButterflyDeviceRecallInput;
};
export type UpdateButterflyDeviceRecallModalMutationResponse = {
    readonly updateButterflyDeviceRecall: {
        readonly butterflyDeviceRecall: {
            readonly " $fragmentRefs": FragmentRefs<"UpdateButterflyDeviceRecallModal_butterflyDeviceRecall">;
        } | null;
    } | null;
};
export type UpdateButterflyDeviceRecallModalMutation = {
    readonly response: UpdateButterflyDeviceRecallModalMutationResponse;
    readonly variables: UpdateButterflyDeviceRecallModalMutationVariables;
};



/*
mutation UpdateButterflyDeviceRecallModalMutation(
  $input: UpdateButterflyDeviceRecallInput!
) {
  updateButterflyDeviceRecall(input: $input) {
    butterflyDeviceRecall {
      ...UpdateButterflyDeviceRecallModal_butterflyDeviceRecall
      id
    }
  }
}

fragment UpdateButterflyDeviceRecallModal_butterflyDeviceRecall on ButterflyDeviceRecall {
  id
  recallId
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateButterflyDeviceRecallModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateButterflyDeviceRecallPayload",
        "kind": "LinkedField",
        "name": "updateButterflyDeviceRecall",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDeviceRecall",
            "kind": "LinkedField",
            "name": "butterflyDeviceRecall",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateButterflyDeviceRecallModal_butterflyDeviceRecall"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateButterflyDeviceRecallModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateButterflyDeviceRecallPayload",
        "kind": "LinkedField",
        "name": "updateButterflyDeviceRecall",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDeviceRecall",
            "kind": "LinkedField",
            "name": "butterflyDeviceRecall",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recallId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14bf5390d39e8d94ba99f0c669000385",
    "id": null,
    "metadata": {},
    "name": "UpdateButterflyDeviceRecallModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateButterflyDeviceRecallModalMutation(\n  $input: UpdateButterflyDeviceRecallInput!\n) {\n  updateButterflyDeviceRecall(input: $input) {\n    butterflyDeviceRecall {\n      ...UpdateButterflyDeviceRecallModal_butterflyDeviceRecall\n      id\n    }\n  }\n}\n\nfragment UpdateButterflyDeviceRecallModal_butterflyDeviceRecall on ButterflyDeviceRecall {\n  id\n  recallId\n  message\n}\n"
  }
};
})();
(node as any).hash = 'ad57ddb426e2a70ff3a2bb4d2260ccdd';
export default node;
