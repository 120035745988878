import { defineMessages } from 'react-intl';

export default defineMessages({
  deregister: {
    id: 'butterflyDeviceDetail.action.deregister',
    defaultMessage: 'Deregister',
  },
  setLostStatus: {
    id: 'butterflyDeviceDetail.action.setLostStatus',
    defaultMessage: 'Set Lost Status',
  },
  setStolenStatus: {
    id: 'butterflyDeviceDetail.action.setStolenStatus',
    defaultMessage: 'Set Stolen Status',
  },
  setRecalledStatus: {
    id: 'butterflyDeviceDetail.action.setRecalledStatus',
    defaultMessage: 'Set Recalled Status',
  },
  requestDiagnosticTest: {
    id: 'butterflyDeviceDetail.action.requestDiagnosticTest',
    defaultMessage: 'Request Diagnostic Test',
  },
  setStatus: {
    id: 'butterflyDeviceDetail.action.setStatus',
    defaultMessage: 'Set Device Status',
  },
});

export const productionIdMessages = defineMessages({
  label: {
    id: 'butterflyDeviceAction.productionId.label',
    defaultMessage: 'Production ID',
  },
  placeholder: {
    id: 'butterflyDeviceAction.productionId.placeholder',
    defaultMessage: 'Production ID',
  },
});

export const isLostMessages = defineMessages({
  label: {
    id: 'butterflyDeviceAction.isLost.label',
    defaultMessage: 'Is Lost',
  },
  placeholder: {
    id: 'butterflyDeviceAction.isLost.placeholder',
    defaultMessage: 'Is Lost',
  },
  description: {
    id: 'butterflyDeviceAction.isLost.description',
    defaultMessage: 'Is the device lost?',
  },
});

export const isStolenMessages = defineMessages({
  label: {
    id: 'butterflyDeviceAction.isStolen.label',
    defaultMessage: 'Is Stolen',
  },
  placeholder: {
    id: 'butterflyDeviceAction.isStolen.placeholder',
    defaultMessage: 'Is Stolen',
  },
  description: {
    id: 'butterflyDeviceAction.isStolen.description',
    defaultMessage: 'Is the device stolen?',
  },
});

export const recallIdMessages = defineMessages({
  label: {
    id: 'butterflyDeviceAction.recallId.label',
    defaultMessage: 'Recall ID*',
  },
  placeholder: {
    id: 'butterflyDeviceAction.recallId.placeholder',
    defaultMessage: 'Recall ID',
  },
  description: {
    id: 'butterflyDeviceAction.recallId.description',
    defaultMessage: 'The Butterfly Device Recall this device is a part of',
  },
  required: {
    id: 'butterflyDeviceAction.recallId.required',
    defaultMessage: 'Recall ID is a required field',
  },
});

export const minTestVersionMessages = defineMessages({
  label: {
    id: 'butterflyDeviceAction.minTestVersion.label',
    defaultMessage: 'Min Test Version*',
  },
  placeholder: {
    id: 'butterflyDeviceAction.minTestVersion.placeholder',
    defaultMessage: 'Min Test Version',
  },
  description: {
    id: 'butterflyDeviceAction.minTestVersion.description',
    defaultMessage: 'The minimum diagnostic test version requested',
  },
  required: {
    id: 'butterflyDeviceAction.minTestVersion.required',
    defaultMessage: 'Please enter a minimum diagnostic test version',
  },
});

export const deregisterMessages = defineMessages({
  titleMsg: {
    id: 'butterflyDeviceAction.deregister.titleMsg',
    defaultMessage: 'Deregister Butterfly Device',
  },
  bodyMsg: {
    id: 'butterflyDeviceAction.deregister.bodyMsg',
    defaultMessage:
      'Are you sure you want to deregister {productionId} device?',
  },
  deregister: {
    id: 'butterflyDeviceAction.deregister.deregister',
    defaultMessage: 'Deregister',
  },
  deregisterFailed: {
    id: 'butterflyDeviceAction.deregister.error',
    defaultMessage: 'Failed to deregister {productionId}.',
  },
  deregisterSuccessful: {
    id: 'butterflyDeviceAction.deregister.success',
    defaultMessage: '{productionId} was successfully deregistered.',
  },
});
