/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateDomainUserIntegrationConfigInput = {
    domainId: string;
    userId: string;
    integrationId: string;
    interfaceCode?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateDomainUserIntegrationConfigModalMutationVariables = {
    input: CreateDomainUserIntegrationConfigInput;
};
export type CreateDomainUserIntegrationConfigModalMutationResponse = {
    readonly createDomainUserIntegrationConfig: {
        readonly domainUserIntegrationConfigEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig">;
            } | null;
        } | null;
    } | null;
};
export type CreateDomainUserIntegrationConfigModalMutation = {
    readonly response: CreateDomainUserIntegrationConfigModalMutationResponse;
    readonly variables: CreateDomainUserIntegrationConfigModalMutationVariables;
};



/*
mutation CreateDomainUserIntegrationConfigModalMutation(
  $input: CreateDomainUserIntegrationConfigInput!
) {
  createDomainUserIntegrationConfig(input: $input) {
    domainUserIntegrationConfigEdge {
      node {
        id
        ...DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig
      }
    }
  }
}

fragment DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig on DomainUserIntegrationConfig {
  id
  domainUserIntegrationConfigId
  interfaceCode
  ehr {
    ehrId
    id
  }
  domainUser {
    domainUserId
    domain {
      domainId
      id
    }
    user {
      userId
      id
    }
    name
    email
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDomainUserIntegrationConfigModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDomainUserIntegrationConfigPayload",
        "kind": "LinkedField",
        "name": "createDomainUserIntegrationConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserIntegrationConfigEdge",
            "kind": "LinkedField",
            "name": "domainUserIntegrationConfigEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserIntegrationConfig",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDomainUserIntegrationConfigModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateDomainUserIntegrationConfigPayload",
        "kind": "LinkedField",
        "name": "createDomainUserIntegrationConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserIntegrationConfigEdge",
            "kind": "LinkedField",
            "name": "domainUserIntegrationConfigEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserIntegrationConfig",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainUserIntegrationConfigId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "interfaceCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Ehr",
                    "kind": "LinkedField",
                    "name": "ehr",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ehrId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DomainUser",
                    "kind": "LinkedField",
                    "name": "domainUser",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainUserId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "domain",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domainId",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "userId",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7461991791fd9da217b560669f610399",
    "id": null,
    "metadata": {},
    "name": "CreateDomainUserIntegrationConfigModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDomainUserIntegrationConfigModalMutation(\n  $input: CreateDomainUserIntegrationConfigInput!\n) {\n  createDomainUserIntegrationConfig(input: $input) {\n    domainUserIntegrationConfigEdge {\n      node {\n        id\n        ...DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig\n      }\n    }\n  }\n}\n\nfragment DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig on DomainUserIntegrationConfig {\n  id\n  domainUserIntegrationConfigId\n  interfaceCode\n  ehr {\n    ehrId\n    id\n  }\n  domainUser {\n    domainUserId\n    domain {\n      domainId\n      id\n    }\n    user {\n      userId\n      id\n    }\n    name\n    email\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd3e28776c108eaacba9cc2cfe8687931';
export default node;
