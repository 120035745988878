import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridBoolean from 'components/DataGridBoolean';
import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';

import invitesTableHeadings from '../messages/invitesTableHeadings';
import { InvitesGrid_organizationInvite as Invite } from './__generated__/InvitesGrid_organizationInvite.graphql';
import { InvitesGrid_viewer$key } from './__generated__/InvitesGrid_viewer.graphql';

const _ = graphql`
  fragment InvitesGrid_organizationInvite on OrganizationInvite {
    id
    organizationInviteId
    email
    roleId
    makeOrganizationAdmin
    createdAt
    deletedAt
    organization {
      organizationId
      name
    }
  }
`;

const fragment = graphql`
  fragment InvitesGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    organizationId: { type: "[String!]" }
    email: { type: "[String!]" }
    isDeleted: { type: "[String!]" }
  )
  @refetchable(queryName: "InvitesGridRefetchQuery") {
    organizationInvites(
      first: $count
      after: $cursor
      organizationId: $organizationId
      email: $email
      isDeleted: $isDeleted
    ) @connection(key: "InvitesGrid_organizationInvites", filters: []) {
      edges {
        node {
          ...InvitesGrid_organizationInvite @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Invite>[] = [
  {
    key: 'organizationInviteId',
    frozen: true,
    label: <FormattedMessage {...invitesTableHeadings.inviteId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organizationInviteId}>
        <DataGridLink path="/invites" id={item.organizationInviteId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'email',
    label: <FormattedMessage {...tableHeadings.email} />,
    render: ({ item }) => (
      <QuickCopyText text={item.email}>
        <DataGridText value={item.email} />
      </QuickCopyText>
    ),
  },
  {
    key: 'makeOrganizationAdmin',
    label: <FormattedMessage {...invitesTableHeadings.isAdmin} />,
    render: ({ item }) => (
      <DataGridBoolean value={item.makeOrganizationAdmin} />
    ),
  },
  {
    key: 'organizationId',
    label: <FormattedMessage {...tableHeadings.organizationId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organization?.organizationId}>
        <DataGridLink
          path="/organizations"
          id={item.organization?.organizationId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'organizationName',
    label: <FormattedMessage {...tableHeadings.organizationName} />,
    render: ({ item }) => (
      <QuickCopyText text={item.organization?.name}>
        <DataGridText value={item.organization?.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'createdAt',
    label: <FormattedMessage {...tableHeadings.createdAt} />,
    render: ({ item }) => <DataGridDateTime value={item.createdAt} />,
  },
  {
    key: 'deletedAt',
    label: <FormattedMessage {...tableHeadings.deletedAt} />,
    render: ({ item }) => <DataGridDateTime value={item.deletedAt} />,
  },
];

interface InvitesGridProps {
  viewer: InvitesGrid_viewer$key;
}

export default function InvitesGrid({ viewer }: InvitesGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.organizationInvites);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      getRowProps={({ deletedAt }) => ({
        classNames: deletedAt ? deletedClass : '',
      })}
      scrollKey="data-grid"
    />
  );
}
