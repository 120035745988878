import Layout from '@4c/layout';
import { UploadFileInputButton } from '@bfly/ui2/FormControlFile';
import Text from '@bfly/ui2/Text';
import useRelayMutation from '@bfly/ui2/useRelayMutation';
import useToast from '@bfly/ui2/useToast';
import { css } from 'astroturf';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Environment,
  RelayProp,
  createFragmentContainer,
  fetchQuery,
  graphql,
} from 'react-relay';

import uploadToS3, { UploadInfo } from 'utils/uploadToS3';

import { thumbnailMessages } from '../messages/formMessages';
import { CourseThumbnailFormThumbnailUploadInfoQuery } from './__generated__/CourseThumbnailFormThumbnailUploadInfoQuery.graphql';

const THUMBNAIL_MIME_TYPES = {
  'image/png': 'IMAGE_PNG',
  'image/jpeg': 'IMAGE_JPEG',
};

const HEIGHT = 173;
const WIDTH = 229;
const THUMBNAIL_ACCEPT_TYPES = Object.keys(THUMBNAIL_MIME_TYPES).join(',');

async function fetchThumbnailUploadInfo(
  environment: Environment,
  thumbnailFile: File,
  educationContentId: string,
) {
  const result = await fetchQuery<CourseThumbnailFormThumbnailUploadInfoQuery>(
    environment,
    graphql`
      query CourseThumbnailFormThumbnailUploadInfoQuery(
        $educationContentId: String!
        $contentType: ThumbnailContentType!
      ) {
        educationContent(educationContentId: $educationContentId) {
          thumbnailUploadInfo(contentType: $contentType) {
            method
            postData
            url
          }
        }
      }
    `,
    {
      educationContentId,
      contentType: THUMBNAIL_MIME_TYPES[thumbnailFile!.type],
    },
  ).toPromise();
  return result!.educationContent!.thumbnailUploadInfo;
}

const RESTRICTIONS_ERROR_MESSAGE = (
  <FormattedMessage
    {...thumbnailMessages.dimensionsRestrictionError}
    values={{
      width: WIDTH,
      height: HEIGHT,
    }}
  />
);

const UPLOAD_ERROR_MESSAGE = (
  <FormattedMessage {...thumbnailMessages.uploadThumbnailError} />
);

interface Props {
  relay: RelayProp;
  educationContentId: string;
  imageSrc?: string;
}

function CourseThumbnailForm({ relay, educationContentId, imageSrc }: Props) {
  const toast = useToast();
  const fileControlRef = useRef<HTMLInputElement | null>(null);

  const [markUploaded] = useRelayMutation(
    graphql`
      mutation CourseThumbnailForm_MarkUploadedMutation(
        $input: MarkThumbnailUploadedInput!
      ) {
        markThumbnailUploaded(input: $input) {
          clientMutationId
          __typename
        }
      }
    `,
  );

  const [thumbnailPreviewSrc, setThumbnailPreviewSrc] = useState<string>();
  const [latestSavedThumbnail, setLatestSavedThumbnail] = useState<
    string | undefined
  >(imageSrc);
  const handleUpload = useCallback(
    async (nextthumbnailFile: File, preview: string) => {
      try {
        const thumbnailUploadInfo = await fetchThumbnailUploadInfo(
          relay.environment,
          nextthumbnailFile,
          educationContentId,
        );
        if (!thumbnailUploadInfo) {
          throw new Error('Empty thumbnail upload info');
        }
        await uploadToS3(nextthumbnailFile, thumbnailUploadInfo as UploadInfo);
        await markUploaded({
          input: {
            educationContentId,
          },
        });
        setLatestSavedThumbnail(preview);
        toast!.success(
          <FormattedMessage {...thumbnailMessages.uploadThumbnailSuccess} />,
        );
      } catch (e) {
        toast!.error(UPLOAD_ERROR_MESSAGE);
        setThumbnailPreviewSrc(undefined);
      }
    },
    [markUploaded, relay, educationContentId, toast],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const thumbnailFile = e.target.files![0];

    if (!Object.keys(THUMBNAIL_MIME_TYPES).includes(thumbnailFile.type)) {
      toast!.error(RESTRICTIONS_ERROR_MESSAGE);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(thumbnailFile);
    reader.onload = (readerOnLoadEvent) => {
      const image = new Image();

      const readerResult = readerOnLoadEvent.target?.result;

      if (typeof readerResult === 'string') {
        image.src = readerResult;
      }

      image.onload = () => {
        if (typeof readerResult === 'string') {
          setThumbnailPreviewSrc(readerResult);
        }
        if (image.height !== HEIGHT || image.width !== WIDTH) {
          setThumbnailPreviewSrc(undefined);
          toast!.error(RESTRICTIONS_ERROR_MESSAGE);
        } else if (typeof readerResult === 'string') {
          handleUpload(thumbnailFile, readerResult);
        }
      };

      image.onerror = () => {
        toast!.error(UPLOAD_ERROR_MESSAGE);
      };
    };
  };

  const image = thumbnailPreviewSrc || latestSavedThumbnail;

  return (
    <Layout
      direction="column"
      align="center"
      css={css`
        width: 240px;
        padding: 14px 20px 16px;
        border-radius: 4px;
        background-color: #333333;
      `}
    >
      {image ? (
        <img
          css={css`
            width: 100%;
            aspect-ratio: 1 / 1;
            border-radius: 4px;
            object-fit: contain;
            background-color: black;
          `}
          alt="Course thumbnail"
          src={image}
        />
      ) : (
        <Layout
          align="center"
          justify="center"
          css={css`
            aspect-ratio: 1 / 1;
            padding: 30px;
            border-radius: 4px;
            border: 1px dashed #000000;
            text-align: center;
          `}
        >
          <Text color="body">
            <FormattedMessage {...thumbnailMessages.uploadInstructions} />
          </Text>
        </Layout>
      )}

      <Text color="body" className="mt-2 text-sm">
        <FormattedMessage
          {...thumbnailMessages.imageFormatRequirement}
          values={{
            width: WIDTH,
            height: HEIGHT,
          }}
        />
      </Text>
      <span className="mt-4">
        <UploadFileInputButton
          ref={fileControlRef}
          onChange={handleChange}
          name="courseThumbnailFile"
          accept={THUMBNAIL_ACCEPT_TYPES}
          variant="primary"
        >
          <FormattedMessage
            {...(image
              ? thumbnailMessages.replaceButtonText
              : thumbnailMessages.selectFileButtonText)}
          />
        </UploadFileInputButton>
      </span>
    </Layout>
  );
}

export default createFragmentContainer(CourseThumbnailForm, {});
