/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import DomainInvitesGridRefetchQuery from "./DomainInvitesGridRefetchQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type DomainInvitesGrid_viewer = {
    readonly userInvites: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly userInviteId: string | null;
                readonly createdAt: string | null;
                readonly usedAt: string | null;
                readonly domain: {
                    readonly domainId: string | null;
                    readonly name: string | null;
                } | null;
                readonly user: {
                    readonly email: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "DomainInvitesGrid_viewer";
};
export type DomainInvitesGrid_viewer$data = DomainInvitesGrid_viewer;
export type DomainInvitesGrid_viewer$key = {
    readonly " $data"?: DomainInvitesGrid_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DomainInvitesGrid_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "userInvites"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "domainId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "email"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": DomainInvitesGridRefetchQuery
    }
  },
  "name": "DomainInvitesGrid_viewer",
  "selections": [
    {
      "alias": "userInvites",
      "args": null,
      "concreteType": "UserInviteConnection",
      "kind": "LinkedField",
      "name": "__DomainInvitesGrid_userInvites_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInviteEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserInvite",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userInviteId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Domain",
                  "kind": "LinkedField",
                  "name": "domain",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "domainId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = '5bb9e38ad10a7f86157b43f19859a6f7';
export default node;
