import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  AndroidVersionBlacklistEntryModal,
  androidVersionBlacklistEntrySchema,
} from './AndroidVersionBlacklistEntryModal';
import { UpdateAndroidVersionBlacklistEntryModalMutation } from './__generated__/UpdateAndroidVersionBlacklistEntryModalMutation.graphql';
import { UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry$key } from './__generated__/UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry.graphql';

const updateAndroidVersionBlacklistEntrySchema =
  androidVersionBlacklistEntrySchema.shape({
    androidVersionBlacklistEntryId: string().meta({ readOnly: true }),
  });

const fragment = graphql`
  fragment UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
    id
    androidVersionBlacklistEntryId
    applicationIdentifier
    versionRange
    versionCode
  }
`;

const updateMutation = graphql`
  mutation UpdateAndroidVersionBlacklistEntryModalMutation(
    $input: UpdateAndroidVersionBlacklistEntryInput!
  ) {
    updateAndroidVersionBlacklistEntry(input: $input) {
      androidVersionBlacklistEntry {
        ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry
      }
    }
  }
`;

interface UpdateAndroidVersionBlacklistEntryModalProps
  extends ActionModalProps {
  androidVersionBlacklistEntryRef: UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry$key;
}

export function UpdateAndroidVersionBlacklistEntryModal({
  androidVersionBlacklistEntryRef,
  ...props
}: UpdateAndroidVersionBlacklistEntryModalProps) {
  const androidVersionBlacklistEntry = useFragment(
    fragment,
    androidVersionBlacklistEntryRef,
  );

  return (
    <AndroidVersionBlacklistEntryModal<UpdateAndroidVersionBlacklistEntryModalMutation>
      {...props}
      schema={updateAndroidVersionBlacklistEntrySchema}
      mutation={updateMutation}
      defaultValue={androidVersionBlacklistEntry}
      title={
        <FormattedMessage
          id="updateAndroidVersionBlacklistEntryModal.title"
          defaultMessage="Update Android Version Recall Entry"
        />
      }
      name={androidVersionBlacklistEntry.androidVersionBlacklistEntryId!}
      submitText={<FormattedMessage {...actionMessages.update} />}
    />
  );
}
