import Button from '@bfly/ui2/Button';
import DatePicker from '@bfly/ui2/DatePicker';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { date, object, string } from 'yup';

import Alert from 'components/Alert';
import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import {
  domainIdMessages,
  endDateMessages,
  startDateMessages,
} from '../messages/formMessages';
import {
  CreateStudyExportActionMutation,
  CreateStudyExportActionMutationResponse,
} from './__generated__/CreateStudyExportActionMutation.graphql';
import { CreateStudyExportAction_domain$key } from './__generated__/CreateStudyExportAction_domain.graphql';

const fragment = graphql`
  fragment CreateStudyExportAction_domain on Domain {
    id
    domainId
  }
`;

const mutation = graphql`
  mutation CreateStudyExportActionMutation($input: CreateStudyExportInput!) {
    createStudyExport(input: $input) {
      downloadLink
      password
    }
  }
`;

const createStudyExportSchema = object({
  domainId: string().required(domainIdMessages.required),
  startDate: date().required(startDateMessages.required),
  endDate: date().required(endDateMessages.required),
});

export type CreateStudyExportResponse = NonNullable<
  CreateStudyExportActionMutationResponse['createStudyExport']
>;

interface CreateStudyExportActionProps {
  domainRef: CreateStudyExportAction_domain$key;
  onCompleted: (data: CreateStudyExportResponse) => void;
}

export default function CreateStudyExportAction({
  domainRef,
  onCompleted,
}: CreateStudyExportActionProps) {
  const { domainId } = useFragment(fragment, domainRef);
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <PermissionsGuard resource={Resource.AUDIT_LOGS} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage
          id="domainActions.createStudyExport"
          defaultMessage="Create Study Export"
        />
      </Button>
      <ResourceModal<CreateStudyExportActionMutation>
        defaultValue={{ domainId }}
        show={showModal}
        onClose={() => setShowModal(false)}
        onCompleted={({
          createStudyExport,
        }: CreateStudyExportActionMutationResponse) =>
          onCompleted(createStudyExport!)
        }
        title={
          <FormattedMessage
            id="createStudyExportAction.title"
            defaultMessage="Create Study Export"
          />
        }
        submitText={
          <FormattedMessage
            id="createStudyExportAction.submitText"
            defaultMessage="Create Study Export"
          />
        }
        mutation={mutation}
        schema={createStudyExportSchema}
      >
        <Form.FieldGroup
          disabled
          name="domainId"
          label={<FormattedMessage {...domainIdMessages.label} />}
          placeholder={formatMessage(domainIdMessages.placeholder)}
          data-cy="createStudyExportField-domainId"
        />
        <Form.FieldGroup
          name="startDate"
          as={DatePicker}
          valueFormat={{
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }}
          label={<FormattedMessage {...startDateMessages.label} />}
          placeholder={startDateMessages.placeholder}
          data-cy="createStudyExportField-startDate"
        />
        <Form.FieldGroup
          name="endDate"
          as={DatePicker}
          valueFormat={{
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          }}
          label={<FormattedMessage {...endDateMessages.label} />}
          placeholder={endDateMessages.placeholder}
          data-cy="createStudyExportField-endDate"
        />
        <Alert variant="info" data-cy="createStudyExportAction-info">
          <FormattedMessage
            id="createStudyExportAction.note"
            defaultMessage="NOTE: The request may take as long as 30 seconds to complete. Please don't double-click."
          />
        </Alert>
      </ResourceModal>
    </PermissionsGuard>
  );
}
