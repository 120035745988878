/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeregisterButterflyDeviceInput = {
    productionId: string;
    clientMutationId?: string | null | undefined;
};
export type DeregisterActionMutationVariables = {
    input: DeregisterButterflyDeviceInput;
};
export type DeregisterActionMutationResponse = {
    readonly deregisterButterflyDevice: {
        readonly butterflyDevice: {
            readonly registeredAt: string | null;
            readonly subscription: {
                readonly subscriptionId: string | null;
            } | null;
        } | null;
    } | null;
};
export type DeregisterActionMutation = {
    readonly response: DeregisterActionMutationResponse;
    readonly variables: DeregisterActionMutationVariables;
};



/*
mutation DeregisterActionMutation(
  $input: DeregisterButterflyDeviceInput!
) {
  deregisterButterflyDevice(input: $input) {
    butterflyDevice {
      registeredAt
      subscription {
        subscriptionId
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeregisterActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeregisterButterflyDevicePayload",
        "kind": "LinkedField",
        "name": "deregisterButterflyDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDevice",
            "kind": "LinkedField",
            "name": "butterflyDevice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeregisterActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeregisterButterflyDevicePayload",
        "kind": "LinkedField",
        "name": "deregisterButterflyDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDevice",
            "kind": "LinkedField",
            "name": "butterflyDevice",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b38fca57d53bd66a4114687403f0d467",
    "id": null,
    "metadata": {},
    "name": "DeregisterActionMutation",
    "operationKind": "mutation",
    "text": "mutation DeregisterActionMutation(\n  $input: DeregisterButterflyDeviceInput!\n) {\n  deregisterButterflyDevice(input: $input) {\n    butterflyDevice {\n      registeredAt\n      subscription {\n        subscriptionId\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7b053c757c4c4ba788906bbd6fb967be';
export default node;
