import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { number, object, string } from 'yup';

import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import butterflyDeviceActionMessages, {
  minTestVersionMessages,
  productionIdMessages,
} from '../messages/butterflyDeviceActionMessages';
import { RequestDiagnosticTestActionMutation } from './__generated__/RequestDiagnosticTestActionMutation.graphql';
import { RequestDiagnosticTestAction_butterflyDevice$key } from './__generated__/RequestDiagnosticTestAction_butterflyDevice.graphql';

const requestDiagnosticTestSchema = object({
  productionId: string().meta({ readOnly: true }),
  minTestVersion: number().integer().required(minTestVersionMessages.required),
});

const fragment = graphql`
  fragment RequestDiagnosticTestAction_butterflyDevice on ButterflyDevice {
    id
    productionId
    lastDiagnosticTestRequestMinTestVersion
  }
`;

const mutation = graphql`
  mutation RequestDiagnosticTestActionMutation(
    $input: RequestButterflyDeviceDiagnosticTestInput!
  ) {
    requestButterflyDeviceDiagnosticTest(input: $input) {
      butterflyDevice {
        hasActiveDiagnosticTestRequest
        lastDiagnosticTestRequestMinTestVersion
      }
    }
  }
`;

interface RequestDiagnosticTestActionProps {
  butterflyDeviceRef: RequestDiagnosticTestAction_butterflyDevice$key;
}

export default function RequestDiagnosticTestAction({
  butterflyDeviceRef,
}: RequestDiagnosticTestActionProps) {
  const { productionId, lastDiagnosticTestRequestMinTestVersion } =
    useFragment(fragment, butterflyDeviceRef);
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <PermissionsGuard resource={Resource.BUTTERFLY_DEVICES} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage
          {...butterflyDeviceActionMessages.requestDiagnosticTest}
        />
      </Button>
      <ResourceModal<RequestDiagnosticTestActionMutation>
        schema={requestDiagnosticTestSchema}
        mutation={mutation}
        defaultValue={{
          productionId,
          minTestVersion: lastDiagnosticTestRequestMinTestVersion,
        }}
        show={showModal}
        onClose={() => setShowModal(false)}
        title={
          <FormattedMessage
            {...butterflyDeviceActionMessages.requestDiagnosticTest}
          />
        }
        submitText={
          <FormattedMessage
            {...butterflyDeviceActionMessages.requestDiagnosticTest}
          />
        }
      >
        <Form.FieldGroup
          disabled
          name="productionId"
          label={<FormattedMessage {...productionIdMessages.label} />}
          placeholder={formatMessage(productionIdMessages.placeholder)}
          data-cy="requestDiagnosticTestAction-productionIdField"
        />
        <Form.FieldGroup
          name="minTestVersion"
          label={<FormattedMessage {...minTestVersionMessages.label} />}
          placeholder={formatMessage(minTestVersionMessages.placeholder)}
          description={
            <FormattedMessage {...minTestVersionMessages.description} />
          }
          data-cy="requestDiagnosticTestAction-minTestVersionField"
        />
      </ResourceModal>
    </PermissionsGuard>
  );
}
