import Form from '@bfly/ui2/Form';
import Multiselect from '@bfly/ui2/Multiselect';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { array, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { formMessages } from 'messages/formMessages';

import { Country, countries } from '../../organizations/utils/countries';
import {
  countriesMessages,
  nameMessages,
  placeOfWorkIdMessages,
  statusMessages,
} from '../messages/formMessages';

export enum PlaceOfWorkSource { // TODO replace with GraphQL enum
  INTERNAL = 'INTERNAL',
}

const PLACE_OF_WORK_STATUSES = ['ACTIVE', 'CLOSED', 'OPENING']; // TODO replace with GraphQL enum

export const placeOfWorkSchema = object({
  placeOfWorkId: string(),
  name: string()
    .required(nameMessages.required)
    .min(2, formMessages.minLength),
  countries: array().of(string()).required(countriesMessages.required).min(1),
  status: string()
    .required(statusMessages.required)
    .oneOf(PLACE_OF_WORK_STATUSES),
});

export function PlaceOfWorkModal<TMutation extends MutationParameters>(
  props: ResourceModalProps<TMutation>,
) {
  const isCreate = !props.defaultValue?.placeOfWorkId;
  const { formatMessage } = useIntl();
  return (
    <ResourceModal<TMutation> {...props}>
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="placeOfWorkId"
          placeholder={formatMessage(placeOfWorkIdMessages.placeholder)}
          label={<FormattedMessage {...placeOfWorkIdMessages.label} />}
          data-cy="placesOfWorkModal-placeOfWorkIdField"
        />
      )}
      <Form.FieldGroup
        name="name"
        placeholder={formatMessage(nameMessages.placeholder)}
        label={<FormattedMessage {...nameMessages.label} />}
        data-cy="placesOfWorkModal-nameField"
      />
      <Form.FieldGroup
        name="countries"
        as={Multiselect}
        data={countries}
        dataKey="code"
        textField="title"
        mapFromValue={(value: Country[]) =>
          value.map((country) => country.code)
        }
        label={<FormattedMessage {...countriesMessages.label} />}
        data-cy="placesOfWorkModal-countriesField"
      />
      <Form.FieldGroup
        name="status"
        as={DropdownList}
        data={PLACE_OF_WORK_STATUSES}
        placeholder={statusMessages.placeholder}
        label={<FormattedMessage {...statusMessages.label} />}
        data-cy="placesOfWorkModal-statusField"
      />
    </ResourceModal>
  );
}
