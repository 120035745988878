import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import useQuery from '@bfly/ui2/useQuery';
import omit from 'lodash/omit';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { hasButterflyAccessRoles } from 'utils/accessRolesUtils';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteMembershipAction from './DeleteMembershipAction';
import SetMembershipRoleAction from './SetMembershipRoleAction';
import UndeleteMembershipAction from './UndeleteMembershipAction';
import { UpdateMembershipModal } from './UpdateMembershipModal';
import { MembershipDetailPage_FlatUserQuery as FlatUserQuery } from './__generated__/MembershipDetailPage_FlatUserQuery.graphql';
import { MembershipDetailPage_membership$key } from './__generated__/MembershipDetailPage_membership.graphql';

const flatUserQuery = graphql`
  query MembershipDetailPage_FlatUserQuery($flatUserId: String!) {
    flatUser(flatUserId: $flatUserId) {
      domainId
      customUserPermissionsEnabled
      role {
        name
      }
    }
  }
`;

const fragment = graphql`
  fragment MembershipDetailPage_membership on OrganizationMembership {
    ...UpdateMembershipModal_membership
    ...DeleteMembershipAction_membership
    ...UndeleteMembershipAction_membership
    ...SetMembershipRoleAction_membership
    id
    organizationMembershipId
    type
    isNurse
    canFinalize
    canQa
    role {
      name
    }
    createdAt
    deletedAt
    organization {
      organizationId
      name
      domain {
        domainId
      }
      customUserPermissionsEnabled
    }
    user {
      userId
      name
    }
    referrerToken
    role {
      id
      roleId
      name
      roleType
    }
  }
`;

interface MembershipDetailPageProps {
  membership: MembershipDetailPage_membership$key;
}

export default function MembershipDetailPage({
  membership,
}: MembershipDetailPageProps) {
  const membershipData = useFragment(fragment, membership);
  const { organization, user, role: membershipRole } = membershipData;
  const domainId = organization?.domain?.domainId;
  useBreadcrumbDetector(pageTitles.membership, user?.name);
  const { canWrite, viewer } = usePermissionsContext();

  const { data } = useQuery<FlatUserQuery>(flatUserQuery, {
    variables: {
      flatUserId: `${user!.userId}/${domainId ?? '-'}`,
    },
  });

  const customUserPermissionsEnabled =
    data?.flatUser?.customUserPermissionsEnabled;

  const isDomainWithCustomPermissions = !!(
    domainId && customUserPermissionsEnabled
  );

  const orgCustomUserPermissionsEnabled =
    membershipData?.organization?.customUserPermissionsEnabled;

  const renderMap = useMemo(() => {
    if (
      !membershipRole ||
      (!isDomainWithCustomPermissions && !orgCustomUserPermissionsEnabled)
    ) {
      return undefined;
    }
    const { name: roleName } = membershipRole;
    // only dealing with system defined roles here
    // because there wouldn't be a valid case where you assign user defined roles to a membership
    const roleDetailsUrl = `/access-roles/system-defined/${membershipRole.roleId}`;

    return {
      role: {
        label: () => 'role',
        value: () => (
          <Link as={Anchor} to={roleDetailsUrl} data-cy="role-details-link">
            {roleName}
          </Link>
        ),
      },
    };
  }, [
    membershipRole,
    isDomainWithCustomPermissions,
    orgCustomUserPermissionsEnabled,
  ]);

  // delcaring membershipDataCopy so we can remove fields
  let membershipDataCopy = membershipData as Record<string, unknown>;

  membershipDataCopy = { ...membershipDataCopy };
  // overwrite customUserPermissionsEnabled, we don't need to display it
  membershipDataCopy.organization = {
    organizationId: membershipData?.organization?.organizationId,
    name: membershipData?.organization?.name,
    domain: membershipData?.organization?.domain,
  };

  if (isDomainWithCustomPermissions || orgCustomUserPermissionsEnabled) {
    delete membershipDataCopy.type;
    delete membershipDataCopy.canQa;
    delete membershipDataCopy.canFinalize;
    delete membershipDataCopy.isNurse;
  } else {
    delete membershipDataCopy.role;
  }

  if (!data) {
    return <LoadingIndicator />;
  }

  return (
    <PermissionsGuard resource={Resource.MEMBERSHIPS}>
      <ResourceDetailPage
        data={omit(membershipDataCopy, ['organization.domain'])}
        name={membershipData.user!.name!}
        quickCopyFields={[
          'organizationMembershipId',
          'organization: organizationId',
          'organization: name',
          'user: userId',
          'user: name',
          'referrerToken',
        ]}
        objectFields={['role']}
        renderMap={renderMap}
        title={<FormattedMessage {...pageTitles.membership} />}
        relatedItems={
          <>
            <RelatedItem
              name="Organization"
              to={`/organizations/${organization!.organizationId}`}
            >
              <FormattedMessage
                id="membershipDetail.relatedItem.organizations"
                defaultMessage="Organization"
              />
            </RelatedItem>
            <RelatedItem
              name="User"
              to={`/users/${user!.userId}/${domainId ?? '-'}`}
            >
              <FormattedMessage
                id="membershipDetail.relatedItem.user"
                defaultMessage="User"
              />
            </RelatedItem>
            {hasButterflyAccessRoles(viewer) &&
              (isDomainWithCustomPermissions ||
                orgCustomUserPermissionsEnabled) &&
              !!membershipRole && (
                <RelatedItem
                  name="Butterfly Access Role"
                  to={`/access-roles/system-defined/${membershipRole.roleId}`}
                >
                  <FormattedMessage
                    id="membershipDetail.relatedItem.butterflyAccessRole"
                    defaultMessage="Butterfly Access Role"
                  />
                </RelatedItem>
              )}
          </>
        }
        crudActions={
          canWrite(Resource.MEMBERSHIPS) && (
            <>
              {!isDomainWithCustomPermissions &&
                orgCustomUserPermissionsEnabled && (
                  <SetMembershipRoleAction membershipRef={membershipData} />
                )}
              <UpdateAction
                renderModal={(props) => (
                  <UpdateMembershipModal
                    {...props}
                    membershipRef={membershipData}
                    isDomainWithCustomPermissions={
                      isDomainWithCustomPermissions
                    }
                  />
                )}
              />
              {membershipData.deletedAt ? (
                <UndeleteMembershipAction membershipRef={membershipData} />
              ) : (
                <DeleteMembershipAction membershipRef={membershipData} />
              )}
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
