import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import createUpdater from 'utils/createUpdater';

import InviteModal, { getInviteSchema } from './InviteModal';
import { CreateInviteModal_viewer$key } from './__generated__/CreateInviteModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateInviteModalMutation($input: InviteUserInput!) {
    inviteUser(input: $input) {
      organizationInvite {
        id
        ...InvitesGrid_organizationInvite
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateInviteModal_viewer on Viewer {
    id
  }
`;

interface CreateInviteModalProps extends ActionModalProps {
  viewerRef: CreateInviteModal_viewer$key;
}

export default function CreateInviteModal({
  viewerRef,
  ...props
}: CreateInviteModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  return (
    <InviteModal
      title={
        <FormattedMessage
          id="createInviteModal.title"
          defaultMessage="Create Invite"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={getInviteSchema()}
      mutation={createMutation}
      updater={createUpdater({
        connectionKey: 'InvitesGrid_organizationInvites',
        getNode: ({ inviteUser }) => inviteUser.organizationInvite,
        connectionContainerId: viewer.id,
      })}
      {...props}
    />
  );
}
