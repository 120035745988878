import Textarea, { TextareaProps } from '@bfly/ui2/Textarea';
import React, { useCallback, useRef } from 'react';

const DELIMITER_REGEX = /[\n|,]/g;

function toTags(value: string) {
  return value
    .split(DELIMITER_REGEX)
    .map((v) => v.trim())
    .filter(Boolean);
}

function toString(value: unknown) {
  return Array.isArray(value) ? value.join(',\n') : '';
}

interface MultilineListTextareaProps extends Omit<TextareaProps, 'onChange'> {
  onChange: (value: string[]) => void;
}

const MultilineListTextarea = React.forwardRef<
  HTMLTextAreaElement,
  MultilineListTextareaProps
>(({ value, onChange, ...props }: MultilineListTextareaProps, ref) => {
  const stringValueRef = useRef(toString(value));

  const nextStringValue = toString(value);
  if (nextStringValue !== toString(toTags(stringValueRef.current))) {
    stringValueRef.current = nextStringValue;
  }

  const handleChange = useCallback(
    (event) => {
      const nextValue = event.target.value;
      stringValueRef.current = nextValue;
      onChange(toTags(nextValue));
    },
    [onChange],
  );

  return (
    <Textarea
      value={stringValueRef.current}
      onChange={handleChange}
      {...props}
      ref={ref}
    />
  );
});

export default MultilineListTextarea;
