import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { applicationIdentifierMessages } from 'messages/formMessages';
import applicationIdentifies from 'utils/applicationIdentifies';

import { minimumVersionMessages } from '../messages/applicationVersionRequirementModal';

export const basicApplicationVersionRequirementSchema = object({
  minimumVersion: string().required(minimumVersionMessages.required),
});

export function ApplicationVersionRequirementModal<
  TMutation extends MutationParameters,
>(props: ResourceModalProps<TMutation>) {
  const isCreate = !props.defaultValue?.applicationIdentifier;

  return (
    <ResourceModal<TMutation> {...props}>
      <Form.FieldGroup
        disabled={!isCreate}
        as={DropdownList}
        data={applicationIdentifies}
        name="applicationIdentifier"
        placeholder={applicationIdentifierMessages.placeholder}
        label={<FormattedMessage {...applicationIdentifierMessages.label} />}
        description={
          <FormattedMessage
            {...applicationIdentifierMessages.description}
            values={{ operationSystem: 'iOS' }}
          />
        }
        data-cy="applicationVersionRequirementModal-applicationIdentifierIdField"
      />
      <Form.FieldGroup
        name="minimumVersion"
        placeholder={minimumVersionMessages.placeholder}
        label={<FormattedMessage {...minimumVersionMessages.label} />}
        description={
          <FormattedMessage {...minimumVersionMessages.description} />
        }
        data-cy="applicationVersionRequirementModal-minimumVersionField"
      />
    </ResourceModal>
  );
}
