import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import { applicationIdentifierMessages } from 'messages/formMessages';
import applicationIdentifies from 'utils/applicationIdentifies';
import createUpdater from 'utils/createUpdater';

import {
  ApplicationVersionRequirementModal,
  basicApplicationVersionRequirementSchema,
} from './ApplicationVersionRequirementModal';
import { CreateApplicationVersionRequirementModalMutation } from './__generated__/CreateApplicationVersionRequirementModalMutation.graphql';
import { CreateApplicationVersionRequirementModal_viewer$key } from './__generated__/CreateApplicationVersionRequirementModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateApplicationVersionRequirementModalMutation(
    $input: SetApplicationVersionRequirementInput!
  ) {
    setApplicationVersionRequirement(input: $input) {
      applicationVersionRequirement {
        id
        applicationIdentifier
        minimumVersion
      }
    }
  }
`;

const createSchema = basicApplicationVersionRequirementSchema.shape({
  applicationIdentifier: string()
    .oneOf(applicationIdentifies)
    .required(applicationIdentifierMessages.required),
});

const fragment = graphql`
  fragment CreateApplicationVersionRequirementModal_viewer on Viewer {
    id
  }
`;

interface CreateApplicationVersionRequirementModalProps
  extends ActionModalProps {
  viewerRef: CreateApplicationVersionRequirementModal_viewer$key;
}

export function CreateApplicationVersionRequirementModal({
  viewerRef,
  ...props
}: CreateApplicationVersionRequirementModalProps) {
  const viewer = useFragment(fragment, viewerRef);

  return (
    <ApplicationVersionRequirementModal<CreateApplicationVersionRequirementModalMutation>
      {...props}
      title={
        <FormattedMessage
          id="createApplicationVersionRequirementModal.title"
          defaultMessage="Create Application Version Requirement"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={createSchema}
      mutation={createMutation}
      updater={createUpdater({
        connectionKey:
          'ApplicationVersionRequirementsPage_applicationVersionRequirements',
        getNode: ({ setApplicationVersionRequirement }) =>
          setApplicationVersionRequirement!.applicationVersionRequirement!,
        connectionContainerId: viewer.id,
      })}
    />
  );
}
