import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

interface ClippedTextProps {
  className?: string;
  maxWidth?: number;
}

const ClippedText = React.forwardRef<
  HTMLSpanElement,
  PropsWithChildren<ClippedTextProps>
>(({ children, className, maxWidth = 320 }, ref) => (
  <Text
    ref={ref}
    className={clsx('block overflow-ellipsis overflow-hidden', className)}
    style={{ maxWidth }}
  >
    {children}
  </Text>
));

export default ClippedText;
