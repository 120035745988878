import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import SubscriptionDetailPage from './components/SubscriptionDetailPage';
import SubscriptionsGrid from './components/SubscriptionsGrid';
import SubscriptionsPage from './components/SubscriptionsPage';

export default (
  <Route path="subscriptions">
    <Route Component={SubscriptionsPage}>
      <Route
        Component={SubscriptionsGrid}
        query={graphql`
          query subscriptions_SubscriptionsGrid_Query(
            $id: [String!]
            $customerId: [String!]
            $domainId: [String!]
            $stripeSubscriptionId: [String!]
            $sfSubscriptionId: [String!]
          ) {
            viewer {
              ...SubscriptionsGrid_viewer
                @arguments(
                  id: $id
                  customerId: $customerId
                  domainId: $domainId
                  stripeSubscriptionId: $stripeSubscriptionId
                  sfSubscriptionId: $sfSubscriptionId
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          id: location.query.id,
          customerId: location.query.customerId,
          domainId: location.query.domainId,
          stripeSubscriptionId: location.query.stripeSubscriptionId,
          sfSubscriptionId: location.query.sfSubscriptionId,
        })}
      />
    </Route>
    <Route
      path=":subscriptionId"
      Component={SubscriptionDetailPage}
      query={graphql`
        query subscriptions_SubscriptionDetailPage_Query(
          $subscriptionId: String!
        ) {
          subscription(subscriptionId: $subscriptionId) {
            ...SubscriptionDetailPage_subscription
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.subscription) throw new HttpError(404);
      }}
    />
  </Route>
);
