import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import RolePermissions from '../../components/AccessRolePermissions';
import DeleteUserDefinedRoleAction from './DeleteUserDefinedRoleAction';
import UndeleteUserDefinedRoleAction from './UndeleteUserDefinedRoleAction';
import { UserDefinedRoleDetailPage_role$key } from './__generated__/UserDefinedRoleDetailPage_role.graphql';

const fragment = graphql`
  fragment UserDefinedRoleDetailPage_role on UserDefinedRole {
    id
    userDefinedRoleId
    domainId
    name
    description
    permissions
    deletedAt
    ...DeleteUserDefinedRoleAction_role
    ...UndeleteUserDefinedRoleAction_role
  }
`;
interface UserDefinedRoleDetailPageProps {
  role: UserDefinedRoleDetailPage_role$key;
}

export default function UserDefinedRoleDetailPage({
  role,
}: UserDefinedRoleDetailPageProps) {
  const { canWrite } = usePermissionsContext();

  const data = useFragment(fragment, role);
  const {
    userDefinedRoleId: roleId,
    name,
    description,
    permissions,
    deletedAt,
  } = data;

  useBreadcrumbDetector(pageTitles.userDefinedRole, name);

  const renderMap = useMemo(() => {
    const renderPermissions = {
      label: () => 'permissions',
      value: () => <RolePermissions permissions={permissions} />,
    };
    return {
      permissions: renderPermissions,
    };
  }, [permissions]);

  return (
    <PermissionsGuard resource={Resource.USER_DEFINED_ROLES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.userDefinedRole} />}
        name={name!}
        data={{
          name,
          roleId,
          type: 'User Defined',
          description,
          deletedAt,
          permissions,
        }}
        quickCopyFields={['roleId', 'name']}
        renderMap={renderMap}
        relatedItems={
          <RelatedItem
            name="Users"
            to={{
              pathname: '/users',
              query: { roleId },
            }}
          >
            <FormattedMessage {...pageTitles.users} />
          </RelatedItem>
        }
        crudActions={
          canWrite(Resource.USER_DEFINED_ROLES) && (
            <>
              {deletedAt ? (
                <UndeleteUserDefinedRoleAction roleRef={data} />
              ) : (
                <DeleteUserDefinedRoleAction roleRef={data} />
              )}
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
