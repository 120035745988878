export default [
  'com.butterflynetinc.develop.Helios',
  'com.butterflynetinc.release.Helios',
  'com.butterflynetinc.ios.Helios',
  'com.butterflynetinc.iq', // iOS Buttefly iQ
  'com.butterflynetinc.rc.Helios',
  'com.butterflynetinc.enterprise.Helios', // iOS Helios (Early Adopter)
  'com.butterflynetinc.master.Helios',
  'com.butterflynetinc.helios.debug', // Android Helios (L) (Yellow Icon)
  'com.butterflynetinc.helios.release', // Android Helios (R) (Green Icon)
  'com.butterflynetinc.helios', // Android Butterfly iQ (Blue Icon)
];
