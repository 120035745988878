import { defineMessages } from 'react-intl';

export const countryCodeMessages = defineMessages({
  label: {
    id: 'countryModal.code.label',
    defaultMessage: 'Country Code*',
  },
  placeholder: {
    id: 'countryModal.code.placeholder',
    defaultMessage: 'Country Code',
  },
  required: {
    id: 'countryModal.code.required',
    defaultMessage: 'Please enter a country code',
  },
  length: {
    id: 'countryModal.code.length',
    defaultMessage: 'Country code must be at least 2 characters long',
  },
  description: {
    id: 'countryModal.code.description',
    defaultMessage: 'Country Code',
  },
});

export const countryNameMessages = defineMessages({
  label: {
    id: 'countryModal.name.label',
    defaultMessage: 'Country Name*',
  },
  placeholder: {
    id: 'countryModal.name.placeholder',
    defaultMessage: 'Country Name',
  },
  required: {
    id: 'countryModal.name.required',
    defaultMessage: 'Please enter a country name',
  },
  length: {
    id: 'countryModal.name.length',
    defaultMessage: 'Country name must be at least 2 characters long',
  },
  description: {
    id: 'countryModal.name.description',
    defaultMessage: 'Country Name',
  },
});

export const enabledMessages = defineMessages({
  label: {
    id: 'countryModal.enabled.label',
    defaultMessage: 'Enabled',
  },
  placeholder: {
    id: 'countryModal.enabled.placeholder',
    defaultMessage: 'Enabled',
  },
});
