import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { boolean, object, string } from 'yup';

import Alert from 'components/Alert';
import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';

import {
  canFinalizeMessages,
  canQAMessages,
  isNurseMessages,
  membershipIdMessages,
  membershipOrganizationIdMessages,
  membershipTypeMessages,
  membershipUserIdMessages,
} from '../messages/formMessages';

export const MEMBERSHIP_TYPE = ['ADMIN', 'REGULAR', 'GUEST'];

export const membershipSchema = object({
  organizationMembershipId: string(),
  organizationId: string().required(membershipOrganizationIdMessages.required),
  userId: string().required(membershipUserIdMessages.required),
  type: string()
    .required(membershipTypeMessages.required)
    .oneOf(MEMBERSHIP_TYPE),
  isNurse: boolean().nullable(),
  canFinalize: boolean().nullable(),
  canQa: boolean().nullable(),
});

export function MembershipModal<TMutation extends MutationParameters>({
  isDomainWithCustomPermissions,
  orgCustomUserPermissionsEnabled,
  ...props
}: ResourceModalProps<TMutation> & {
  isDomainWithCustomPermissions: boolean;
  orgCustomUserPermissionsEnabled: boolean | null | undefined;
}) {
  const isCreate = !props.defaultValue?.organizationMembershipId;
  const { formatMessage } = useIntl();
  return (
    <ResourceModal<TMutation> {...props}>
      {!isCreate && (
          <Alert variant="warning" className="mb-5">
            <FormattedMessage
              id="createMembershipModal.warning"
              defaultMessage="If the organization you are creating for the membership for has Custom User Permissions on, The 'Type' selected here will be converted to the equivalent role in Custom User Permissions. And Is Nurse, Can Finalize and Can QA won't be applicable. You may change the role after creation."
            />
          </Alert>
        ) && (
          <Form.FieldGroup
            disabled
            name="organizationMembershipId"
            placeholder={formatMessage(membershipIdMessages.placeholder)}
            label={<FormattedMessage {...membershipIdMessages.label} />}
            data-cy="membershipModal-organizationMembershipId"
          />
        )}
      {!isDomainWithCustomPermissions && !orgCustomUserPermissionsEnabled && (
        <Form.FieldGroup
          name="type"
          as={DropdownList}
          data={MEMBERSHIP_TYPE}
          placeholder={membershipTypeMessages.placeholder}
          label={<FormattedMessage {...membershipTypeMessages.label} />}
          description={
            <FormattedMessage {...membershipTypeMessages.description} />
          }
          data-cy="membershipModal-typeField"
        />
      )}
      <Form.FieldGroup
        name="organizationId"
        disabled={!isCreate}
        placeholder={formatMessage(
          membershipOrganizationIdMessages.placeholder,
        )}
        label={
          <FormattedMessage {...membershipOrganizationIdMessages.label} />
        }
        className="name"
        data-cy="membershipModal-organizationId"
      />
      <Form.FieldGroup
        name="userId"
        disabled={!isCreate}
        placeholder={formatMessage(membershipUserIdMessages.placeholder)}
        label={<FormattedMessage {...membershipUserIdMessages.label} />}
        className="name"
        data-cy="membershipModal-userId"
      />
      {!isDomainWithCustomPermissions && !orgCustomUserPermissionsEnabled && (
        <>
          <Form.FieldGroup
            name="isNurse"
            placeholder={formatMessage(isNurseMessages.placeholder)}
            label={<FormattedMessage {...isNurseMessages.label} />}
            data-cy="membershipCreate-isNurse"
          />
          <Form.FieldGroup
            name="canFinalize"
            placeholder={formatMessage(canFinalizeMessages.placeholder)}
            label={<FormattedMessage {...canFinalizeMessages.label} />}
            data-cy="membershipCreate-canFinalize"
          />
          <Form.FieldGroup
            name="canQa"
            placeholder={formatMessage(canQAMessages.placeholder)}
            label={<FormattedMessage {...canQAMessages.label} />}
            data-cy="membershipCreate-canQa"
          />
        </>
      )}
    </ResourceModal>
  );
}
