import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { LinkFieldsType } from 'components/ResourceDetail';
import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import ProvisionDomainAction from './ProvisionDomainAction';
import ProvisionOrganizationAction from './ProvisionOrganizationAction';
import { UpdateCustomerModal } from './UpdateCustomerModal';
import { CustomerDetailPage_customer$key } from './__generated__/CustomerDetailPage_customer.graphql';

const fragment = graphql`
  fragment CustomerDetailPage_customer on Customer {
    ...UpdateCustomerModal_customer
    ...ProvisionOrganizationAction_customer
    ...ProvisionDomainAction_customer
    id
    customerId
    name
    zendeskId
    netsuiteId
    stripeId
    sfAccountId
  }
`;

interface CustomerDetailPageProps {
  customer: CustomerDetailPage_customer$key;
}

const linkFields: LinkFieldsType = {
  netsuiteId: (value) =>
    `https://4098050.app.netsuite.com/app/common/entity/custjob.nl?id=${value}`,
  zendeskId: (value) =>
    `https://bfly.zendesk.com/agent/organizations/${value}/tickets`,
  stripeId: (value) => `https://dashboard.stripe.com/customers/${value}`,
  sfAccountId: (value) =>
    `https://butterflynetwork.lightning.force.com/lightning/r/Opportunity/${value}/view`,
};

export default function CustomerDetailPage({
  customer: customerRef,
}: CustomerDetailPageProps) {
  const customer = useFragment(fragment, customerRef);
  const { customerId, name } = customer;
  useBreadcrumbDetector(pageTitles.customer, name);
  const { canWrite, canWriteSome } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.CUSTOMERS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.customer} />}
        name={customer.name!}
        data={customer}
        linkFields={linkFields}
        quickCopyFields={[
          'name',
          'customerId',
          'zendeskId',
          'netsuiteId',
          'stripeId',
          'sfAccountId',
        ]}
        resourceActions={
          canWriteSome(
            Resource.PROVISIONING,
            Resource.ENTERPRISE_UPGRADES,
          ) && (
            <>
              <ProvisionOrganizationAction customerRef={customer} />
              <ProvisionDomainAction customerRef={customer} />
            </>
          )
        }
        crudActions={
          canWrite(Resource.CUSTOMERS) && (
            <UpdateAction
              renderModal={(props) => (
                <UpdateCustomerModal {...props} customerRef={customer} />
              )}
            />
          )
        }
        relatedItems={
          <>
            <RelatedItem
              name="Subscriptions"
              to={{
                pathname: '/subscriptions',
                query: { customerId },
              }}
            >
              <FormattedMessage
                id="customerDetail.relatedItem.subscriptions"
                defaultMessage="Subscriptions"
              />
            </RelatedItem>
            <RelatedItem
              name="Organizations"
              to={{
                pathname: '/organizations',
                query: { customerId },
              }}
            >
              <FormattedMessage
                id="customerDetail.relatedItem.organizations"
                defaultMessage="Organizations"
              />
            </RelatedItem>
          </>
        }
      />
    </PermissionsGuard>
  );
}
