/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AndroidVersionBlacklistEntriesPageRefetchQueryVariables = {
    count?: number | null | undefined;
    cursor?: string | null | undefined;
};
export type AndroidVersionBlacklistEntriesPageRefetchQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"AndroidVersionBlacklistEntriesPage_viewer">;
    } | null;
};
export type AndroidVersionBlacklistEntriesPageRefetchQuery = {
    readonly response: AndroidVersionBlacklistEntriesPageRefetchQueryResponse;
    readonly variables: AndroidVersionBlacklistEntriesPageRefetchQueryVariables;
};



/*
query AndroidVersionBlacklistEntriesPageRefetchQuery(
  $count: Int = 20
  $cursor: String
) {
  viewer {
    ...AndroidVersionBlacklistEntriesPage_viewer_1G22uz
    id
  }
}

fragment AndroidVersionBlacklistEntriesPage_viewer_1G22uz on Viewer {
  ...CreateAndroidVersionBlacklistEntryModal_viewer
  androidVersionBlacklistEntries(first: $count, after: $cursor) {
    edges {
      node {
        id
        androidVersionBlacklistEntryId
        applicationIdentifier
        versionRange
        versionCode
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment CreateAndroidVersionBlacklistEntryModal_viewer on Viewer {
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AndroidVersionBlacklistEntriesPageRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "AndroidVersionBlacklistEntriesPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AndroidVersionBlacklistEntriesPageRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "AndroidVersionBlacklistEntryConnection",
            "kind": "LinkedField",
            "name": "androidVersionBlacklistEntries",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AndroidVersionBlacklistEntryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AndroidVersionBlacklistEntry",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "androidVersionBlacklistEntryId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "applicationIdentifier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "versionRange",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "versionCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntries",
            "kind": "LinkedHandle",
            "name": "androidVersionBlacklistEntries"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b41d5774cc69ade009f6508d0cb5cfc",
    "id": null,
    "metadata": {},
    "name": "AndroidVersionBlacklistEntriesPageRefetchQuery",
    "operationKind": "query",
    "text": "query AndroidVersionBlacklistEntriesPageRefetchQuery(\n  $count: Int = 20\n  $cursor: String\n) {\n  viewer {\n    ...AndroidVersionBlacklistEntriesPage_viewer_1G22uz\n    id\n  }\n}\n\nfragment AndroidVersionBlacklistEntriesPage_viewer_1G22uz on Viewer {\n  ...CreateAndroidVersionBlacklistEntryModal_viewer\n  androidVersionBlacklistEntries(first: $count, after: $cursor) {\n    edges {\n      node {\n        id\n        androidVersionBlacklistEntryId\n        applicationIdentifier\n        versionRange\n        versionCode\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CreateAndroidVersionBlacklistEntryModal_viewer on Viewer {\n  id\n}\n"
  }
};
})();
(node as any).hash = '1e9db903740134f73eaed8ac5da5d0d6';
export default node;
