/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type domainInvites_DomainInviteDetailPage_QueryVariables = {
    userInviteId: string;
};
export type domainInvites_DomainInviteDetailPage_QueryResponse = {
    readonly userInvite: {
        readonly " $fragmentRefs": FragmentRefs<"DomainInviteDetailPage_userInvite">;
    } | null;
};
export type domainInvites_DomainInviteDetailPage_Query = {
    readonly response: domainInvites_DomainInviteDetailPage_QueryResponse;
    readonly variables: domainInvites_DomainInviteDetailPage_QueryVariables;
};



/*
query domainInvites_DomainInviteDetailPage_Query(
  $userInviteId: String!
) {
  userInvite(userInviteId: $userInviteId) {
    ...DomainInviteDetailPage_userInvite
    id
  }
}

fragment DomainInviteDetailPage_userInvite on UserInvite {
  ...ResendUserInviteAction_userInvite
  ...ShowUserInviteLinkAction_userInvite
  id
  userInviteId
  createdAt
  usedAt
  user {
    userId
    email
    id
  }
  domain {
    domainId
    name
    id
  }
}

fragment ResendUserInviteAction_userInvite on UserInvite {
  id
  userInviteId
  usedAt
  user {
    userId
    id
  }
  domain {
    domainId
    id
  }
}

fragment ShowUserInviteLinkAction_userInvite on UserInvite {
  userInviteId
  linkUrl
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userInviteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userInviteId",
    "variableName": "userInviteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainInvites_DomainInviteDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserInvite",
        "kind": "LinkedField",
        "name": "userInvite",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainInviteDetailPage_userInvite"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainInvites_DomainInviteDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserInvite",
        "kind": "LinkedField",
        "name": "userInvite",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userInviteId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "usedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domainId",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a736091d2cc160e1340bfabd656948d4",
    "id": null,
    "metadata": {},
    "name": "domainInvites_DomainInviteDetailPage_Query",
    "operationKind": "query",
    "text": "query domainInvites_DomainInviteDetailPage_Query(\n  $userInviteId: String!\n) {\n  userInvite(userInviteId: $userInviteId) {\n    ...DomainInviteDetailPage_userInvite\n    id\n  }\n}\n\nfragment DomainInviteDetailPage_userInvite on UserInvite {\n  ...ResendUserInviteAction_userInvite\n  ...ShowUserInviteLinkAction_userInvite\n  id\n  userInviteId\n  createdAt\n  usedAt\n  user {\n    userId\n    email\n    id\n  }\n  domain {\n    domainId\n    name\n    id\n  }\n}\n\nfragment ResendUserInviteAction_userInvite on UserInvite {\n  id\n  userInviteId\n  usedAt\n  user {\n    userId\n    id\n  }\n  domain {\n    domainId\n    id\n  }\n}\n\nfragment ShowUserInviteLinkAction_userInvite on UserInvite {\n  userInviteId\n  linkUrl\n}\n"
  }
};
})();
(node as any).hash = '31e627e97a82d6341a8d8d476c165e0e';
export default node;
