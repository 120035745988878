/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainsGrid_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly createdAt: string | null;
    readonly deletedAt: string | null;
    readonly subdomainLabel: string | null;
    readonly name: string | null;
    readonly enableButterflyLogin: boolean | null;
    readonly enableSamlLogin: boolean | null;
    readonly enableSharedDeviceCodeLogin: boolean | null;
    readonly samlEmailAttributeName: string | null;
    readonly samlEmailAttributeNameRaw: string | null;
    readonly samlNicknameAttributeName: string | null;
    readonly samlNicknameAttributeNameRaw: string | null;
    readonly cognitoClientId: string | null;
    readonly inactivityTimeoutSeconds: number | null;
    readonly requiredAndroidCapabilities: ReadonlyArray<string | null> | null;
    readonly sharedDeviceKey: string | null;
    readonly numUsers: number | null;
    readonly maxNumSeats: number | null;
    readonly numAvailableSeats: number | null;
    readonly country: string | null;
    readonly " $refType": "DomainsGrid_domain";
};
export type DomainsGrid_domain$data = DomainsGrid_domain;
export type DomainsGrid_domain$key = {
    readonly " $data"?: DomainsGrid_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DomainsGrid_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DomainsGrid_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableButterflyLogin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableSamlLogin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableSharedDeviceCodeLogin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlEmailAttributeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlEmailAttributeNameRaw",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlNicknameAttributeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlNicknameAttributeNameRaw",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cognitoClientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inactivityTimeoutSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredAndroidCapabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharedDeviceKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numUsers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxNumSeats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numAvailableSeats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = '8e49da656b2b569a426b6056a1166f6a';
export default node;
