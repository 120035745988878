import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deactivateMessages } from 'messages/deleteAction';

import { DeleteFlatUserAction_user$key } from './__generated__/DeleteFlatUserAction_user.graphql';

const fragment = graphql`
  fragment DeleteFlatUserAction_user on FlatUser {
    name
    flatUserId
    userDeletedAt
    domainId
    domainUserDeletedAt
  }
`;

const mutation = graphql`
  mutation DeleteFlatUserActionMutation($input: DeleteFlatUserInput!) {
    deleteFlatUser(input: $input) {
      flatUser {
        ...FlatUsersGrid_user @relay(mask: false)
      }
    }
  }
`;

interface DeleteFlatUserActionProps {
  userRef: DeleteFlatUserAction_user$key;
}

function isLastAdminError(error): boolean {
  return error?.json?.errors?.some(
    ({ message }) => message === 'Sole admin cannot be demoted or removed',
  );
}

function getCustomErrorMessage(err, defaultMsg) {
  return isLastAdminError(err)
    ? deactivateMessages.deactiveLastAdminFailed
    : defaultMsg;
}

export default function DeleteFlatUserAction({
  userRef,
}: DeleteFlatUserActionProps) {
  const { flatUserId, domainId, name, userDeletedAt, domainUserDeletedAt } =
    useFragment(fragment, userRef);

  const deleted = domainId
    ? !!userDeletedAt && !!domainUserDeletedAt
    : !!userDeletedAt;

  return (
    <DeleteAction
      itemName={name ?? 'User'}
      mutation={mutation}
      redirectTo="/users"
      disabled={deleted}
      input={{ flatUserId }}
      getCustomErrorMessage={getCustomErrorMessage}
    />
  );
}
