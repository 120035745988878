import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import { UpdateButterflyDeviceRecallModal } from './UpdateButterflyDeviceRecallModal';
import { ButterflyDeviceRecallDetailPage_butterflyDeviceRecall$key } from './__generated__/ButterflyDeviceRecallDetailPage_butterflyDeviceRecall.graphql';

const fragment = graphql`
  fragment ButterflyDeviceRecallDetailPage_butterflyDeviceRecall on ButterflyDeviceRecall {
    ...UpdateButterflyDeviceRecallModal_butterflyDeviceRecall
    id
    recallId
    message
    createdAt
  }
`;

interface ButterflyDeviceRecallDetailPageProps {
  butterflyDeviceRecall: ButterflyDeviceRecallDetailPage_butterflyDeviceRecall$key;
}

export default function ButterflyDeviceRecallDetailPage({
  butterflyDeviceRecall: butterflyDeviceRecallRef,
}: ButterflyDeviceRecallDetailPageProps) {
  const butterflyDeviceRecall = useFragment(
    fragment,
    butterflyDeviceRecallRef,
  );
  useBreadcrumbDetector(
    pageTitles.butterflyDeviceRecall,
    butterflyDeviceRecall.recallId,
  );

  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.BUTTERFLY_DEVICE_RECALLS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.butterflyDeviceRecall} />}
        name={butterflyDeviceRecall.recallId!}
        data={butterflyDeviceRecall}
        quickCopyFields={['recallId']}
        crudActions={
          canWrite(Resource.BUTTERFLY_DEVICE_RECALLS) && (
            <UpdateAction
              renderModal={(props) => (
                <UpdateButterflyDeviceRecallModal
                  {...props}
                  butterflyDeviceRecallRef={butterflyDeviceRecall}
                />
              )}
            />
          )
        }
      />
    </PermissionsGuard>
  );
}
