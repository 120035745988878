/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CountriesGrid_country = {
    readonly id: string;
    readonly code: string | null;
    readonly name: string | null;
    readonly enabled: boolean | null;
    readonly countryConfiguration: {
        readonly dataRegion: string | null;
        readonly configuration: any | null;
    } | null;
    readonly " $refType": "CountriesGrid_country";
};
export type CountriesGrid_country$data = CountriesGrid_country;
export type CountriesGrid_country$key = {
    readonly " $data"?: CountriesGrid_country$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CountriesGrid_country">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CountriesGrid_country",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CountryConfiguration",
      "kind": "LinkedField",
      "name": "countryConfiguration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataRegion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "configuration",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Country",
  "abstractKey": null
};
(node as any).hash = '9051883e6c308aa9d28c616d16fccd04';
export default node;
