/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateInviteModalOrganizationQueryVariables = {
    organizationId: string;
};
export type UpdateInviteModalOrganizationQueryResponse = {
    readonly organization: {
        readonly customUserPermissionsEnabled: boolean | null;
    } | null;
};
export type UpdateInviteModalOrganizationQuery = {
    readonly response: UpdateInviteModalOrganizationQueryResponse;
    readonly variables: UpdateInviteModalOrganizationQueryVariables;
};



/*
query UpdateInviteModalOrganizationQuery(
  $organizationId: String!
) {
  organization(organizationId: $organizationId) {
    customUserPermissionsEnabled
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customUserPermissionsEnabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateInviteModalOrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateInviteModalOrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f645bff458425b8e26ae91fbee4b644e",
    "id": null,
    "metadata": {},
    "name": "UpdateInviteModalOrganizationQuery",
    "operationKind": "query",
    "text": "query UpdateInviteModalOrganizationQuery(\n  $organizationId: String!\n) {\n  organization(organizationId: $organizationId) {\n    customUserPermissionsEnabled\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e532c87765716653b8d4d9eafa01683f';
export default node;
