/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import ApplicationVersionRequirementsPageRefetchQuery from "./ApplicationVersionRequirementsPageRefetchQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type ApplicationVersionRequirementsPage_viewer = {
    readonly applicationVersionRequirements: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly applicationIdentifier: string | null;
                readonly minimumVersion: string | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CreateApplicationVersionRequirementModal_viewer">;
    readonly " $refType": "ApplicationVersionRequirementsPage_viewer";
};
export type ApplicationVersionRequirementsPage_viewer$data = ApplicationVersionRequirementsPage_viewer;
export type ApplicationVersionRequirementsPage_viewer$key = {
    readonly " $data"?: ApplicationVersionRequirementsPage_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ApplicationVersionRequirementsPage_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "applicationVersionRequirements"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": ApplicationVersionRequirementsPageRefetchQuery
    }
  },
  "name": "ApplicationVersionRequirementsPage_viewer",
  "selections": [
    {
      "alias": "applicationVersionRequirements",
      "args": null,
      "concreteType": "ApplicationVersionRequirementConnection",
      "kind": "LinkedField",
      "name": "__ApplicationVersionRequirementsPage_applicationVersionRequirements_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ApplicationVersionRequirementEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ApplicationVersionRequirement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "applicationIdentifier",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minimumVersion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateApplicationVersionRequirementModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = 'acf40a02e419f66706ec5fb74a360822';
export default node;
