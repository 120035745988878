import React from 'react';
import { graphql, useFragment } from 'react-relay';

import UndeleteAction from 'components/UndeleteAction';

import { UndeleteMembershipAction_membership$key } from './__generated__/UndeleteMembershipAction_membership.graphql';

const fragment = graphql`
  fragment UndeleteMembershipAction_membership on OrganizationMembership {
    organizationMembershipId
    organization {
      name
    }
  }
`;

const mutation = graphql`
  mutation UndeleteMembershipActionMutation(
    $input: UndeleteOrganizationMembershipInput!
  ) {
    undeleteOrganizationMembership(input: $input) {
      organizationMembership {
        deletedAt
      }
    }
  }
`;

interface UndeleteMembershipActionProps {
  membershipRef: UndeleteMembershipAction_membership$key;
}

export default function UndeleteMembershipAction({
  membershipRef,
}: UndeleteMembershipActionProps) {
  const membership = useFragment(fragment, membershipRef);

  return (
    <UndeleteAction
      mutation={mutation}
      itemName={`${membership.organization?.name} Membership` ?? 'Membership'}
      input={{ organizationMembershipId: membership.organizationMembershipId }}
    />
  );
}
