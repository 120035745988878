import { defineMessages } from 'react-intl';

export default defineMessages({
  educationCategoryId: {
    id: 'educationCategoriesTable.heading.educationCategoryId',
    defaultMessage: 'Education Category ID',
  },
  color: {
    id: 'educationCategoriesTable.heading.color',
    defaultMessage: 'Color',
  },
  ordering: {
    id: 'educationCategoriesTable.heading.ordering',
    defaultMessage: 'Ordering',
  },
});
