import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import ResourceGridAction from 'components/ResourceGridAction';
import ResourceGridData from 'components/ResourceGridData';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import androidVersionBlacklistEntriesTableHeadings from '../messages/androidVersionBlacklistEntriesTableHeadings';
import { CreateAndroidVersionBlacklistEntryModal } from './CreateAndroidVersionBlacklistEntryModal';
import { AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry as AndroidVersionBlacklistEntry } from './__generated__/AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry.graphql';
import { AndroidVersionBlacklistEntriesPage_viewer$key } from './__generated__/AndroidVersionBlacklistEntriesPage_viewer.graphql';

const resource = Resource.ANDROID_VERSION_BLACKLIST_ENTRIES;

const _ = graphql`
  fragment AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
    id
    androidVersionBlacklistEntryId
    applicationIdentifier
    versionRange
    versionCode
  }
`;

const fragment = graphql`
  fragment AndroidVersionBlacklistEntriesPage_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "AndroidVersionBlacklistEntriesPageRefetchQuery") {
    ...CreateAndroidVersionBlacklistEntryModal_viewer
    androidVersionBlacklistEntries(first: $count, after: $cursor)
      @connection(
        key: "AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntries"
      ) {
      edges {
        node {
          ...AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntry
            @relay(mask: false)
        }
      }
    }
  }
`;

export const tableSpec: ColumnSpec<AndroidVersionBlacklistEntry>[] = [
  {
    key: 'androidVersionBlacklistEntryId',
    label: (
      <FormattedMessage
        {...androidVersionBlacklistEntriesTableHeadings.androidVersionBlacklistEntryId}
      />
    ),
    frozen: true,
    render: ({ item }) => (
      <QuickCopyText text={item.androidVersionBlacklistEntryId}>
        <DataGridLink
          path="/android-version-blacklist-entries"
          id={item.androidVersionBlacklistEntryId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'applicationIdentifier',
    label: <FormattedMessage {...tableHeadings.applicationIdentifier} />,
    render: ({ item }) => (
      <QuickCopyText text={item.applicationIdentifier}>
        <DataGridText value={item.applicationIdentifier} />
      </QuickCopyText>
    ),
  },
  {
    key: 'versionRange',
    label: <FormattedMessage {...tableHeadings.versionRange} />,
    render: ({ item }) => <DataGridText value={item.versionRange} />,
  },
  {
    key: 'versionCode',
    label: (
      <FormattedMessage
        {...androidVersionBlacklistEntriesTableHeadings.versionCode}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.versionCode}>
        <DataGridText value={item.versionCode} />
      </QuickCopyText>
    ),
  },
];

interface AndroidVersionBlacklistEntriesPage {
  viewer: AndroidVersionBlacklistEntriesPage_viewer$key;
}

export default function AndroidVersionBlacklistEntriesPage({
  viewer,
}: AndroidVersionBlacklistEntriesPage) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.androidVersionBlacklistEntries);
  useBreadcrumbDetector(pageTitles.androidVersionBlacklistEntries);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader className="p-2">
        <Header.Title>
          <FormattedMessage {...pageTitles.androidVersionBlacklistEntries} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateAndroidVersionBlacklistEntryModal
              {...props}
              viewerRef={data}
            />
          )}
        >
          <FormattedMessage
            id="androidVersionBlacklistEntriesPage.actonTitle"
            defaultMessage="Create Android Version"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridData
        data={nodes}
        loadNext={loadNext}
        hasNext={hasNext}
        spec={tableSpec}
      />
    </PermissionsGuard>
  );
}
