import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { LinkFieldsType } from 'components/ResourceDetail';
import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DisableCaptionHealthAction from './DisableCaptionHealthAction';
import EnableCaptionHealthAction from './EnableCaptionHealthAction';
import ProvisionOrganizationForSubscriptionAction from './ProvisionOrganizationForSubscriptionAction';
import { UpdateSubscriptionModal } from './UpdateSubscriptionModal';
import { SubscriptionDetailPage_subscription$key } from './__generated__/SubscriptionDetailPage_subscription.graphql';

const fragment = graphql`
  fragment SubscriptionDetailPage_subscription on Subscription {
    ...UpdateSubscriptionModal_subscription
    ...ProvisionOrganizationForSubscriptionAction_subscription
    ...EnableCaptionHealthAction_subscription
    ...DisableCaptionHealthAction_subscription
    id
    subscriptionId
    subscriptionEndsAt
    accessEndsAt
    hasStartedActiveSubscription
    hasCompletedTrial
    canAccessProFeatures
    isInGracePeriod
    isTeam
    billingStatus
    billingProvider
    stripeSubscriptionId
    sfSubscriptionId
    featureModuleIds
    planType
    maxNumSeats
    practiceType
    domain {
      domainId
    }
    customer {
      customerId
    }
  }
`;

interface SubscriptionDetailPageProps {
  subscription: SubscriptionDetailPage_subscription$key;
}

const linkFields: LinkFieldsType = {
  stripeSubscriptionId: (value) =>
    `https://dashboard.stripe.com/subscriptions/${value}`,
};

export default function SubscriptionDetailPage({
  subscription,
}: SubscriptionDetailPageProps) {
  const data = useFragment(fragment, subscription);
  const { domain, customer, subscriptionId } = data;
  useBreadcrumbDetector(pageTitles.subscription, subscriptionId);
  const { canWrite, canWriteSome } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.SUBSCRIPTIONS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.subscription} />}
        name={data.subscriptionId!}
        data={data}
        quickCopyFields={[
          'subscriptionId',
          'stripeSubscriptionId',
          'sfSubscriptionId',
          'domain: domainId',
          'customer: customerId',
        ]}
        linkFields={linkFields}
        resourceActions={
          canWriteSome(Resource.PROVISIONING, Resource.SUBSCRIPTIONS) && (
            <>
              <ProvisionOrganizationForSubscriptionAction
                subscriptionRef={data}
              />
              <EnableCaptionHealthAction subscriptionRef={data} />
              <DisableCaptionHealthAction subscriptionRef={data} />
            </>
          )
        }
        crudActions={
          canWrite(Resource.SUBSCRIPTIONS) && (
            <UpdateAction
              renderModal={(props) => (
                <UpdateSubscriptionModal {...props} subscriptionRef={data} />
              )}
            />
          )
        }
        relatedItems={
          <>
            {domain?.domainId && (
              <RelatedItem name="Domain" to={`/domains/${domain.domainId}`}>
                <FormattedMessage
                  id="subscriptionDetail.relatedItem.domain"
                  defaultMessage="Domain"
                />
              </RelatedItem>
            )}
            <RelatedItem
              name="Customer"
              to={`/customers/${customer!.customerId}`}
            >
              <FormattedMessage
                id="subscriptionDetail.relatedItem.customer"
                defaultMessage="Customer"
              />
            </RelatedItem>
            <RelatedItem
              name="Organizations"
              to={{
                pathname: '/organizations',
                query: { subscriptionId },
              }}
            >
              <FormattedMessage
                id="subscriptionDetail.relatedItem.organizations"
                defaultMessage="Organizations"
              />
            </RelatedItem>
          </>
        }
      />
    </PermissionsGuard>
  );
}
