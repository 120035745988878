/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrganizationMembershipType = "ADMIN" | "GUEST" | "REGULAR" | "%future added value";
export type UpdateOrganizationMembershipInput = {
    organizationMembershipId: string;
    type?: OrganizationMembershipType | null | undefined;
    roleId?: string | null | undefined;
    canQa?: boolean | null | undefined;
    canFinalize?: boolean | null | undefined;
    isNurse?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateMembershipModalMutationVariables = {
    input: UpdateOrganizationMembershipInput;
};
export type UpdateMembershipModalMutationResponse = {
    readonly updateOrganizationMembership: {
        readonly organizationMembership: {
            readonly " $fragmentRefs": FragmentRefs<"MembershipsGrid_membership">;
        } | null;
    } | null;
};
export type UpdateMembershipModalMutation = {
    readonly response: UpdateMembershipModalMutationResponse;
    readonly variables: UpdateMembershipModalMutationVariables;
};



/*
mutation UpdateMembershipModalMutation(
  $input: UpdateOrganizationMembershipInput!
) {
  updateOrganizationMembership(input: $input) {
    organizationMembership {
      ...MembershipsGrid_membership
      id
    }
  }
}

fragment MembershipsGrid_membership on OrganizationMembership {
  id
  organizationMembershipId
  type
  createdAt
  deletedAt
  organization {
    organizationId
    specialType
    name
    domain {
      domainId
      id
    }
    id
  }
  user {
    userId
    name
    email
    id
  }
  referrerToken
  isNurse
  canFinalize
  canQa
  role {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateMembershipModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "organizationMembership",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MembershipsGrid_membership"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateMembershipModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "organizationMembership",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationMembershipId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "specialType",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "domain",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referrerToken",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNurse",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canFinalize",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canQa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRole",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a3fa6e884e32dddc0d47e8c12bf061b",
    "id": null,
    "metadata": {},
    "name": "UpdateMembershipModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateMembershipModalMutation(\n  $input: UpdateOrganizationMembershipInput!\n) {\n  updateOrganizationMembership(input: $input) {\n    organizationMembership {\n      ...MembershipsGrid_membership\n      id\n    }\n  }\n}\n\nfragment MembershipsGrid_membership on OrganizationMembership {\n  id\n  organizationMembershipId\n  type\n  createdAt\n  deletedAt\n  organization {\n    organizationId\n    specialType\n    name\n    domain {\n      domainId\n      id\n    }\n    id\n  }\n  user {\n    userId\n    name\n    email\n    id\n  }\n  referrerToken\n  isNurse\n  canFinalize\n  canQa\n  role {\n    name\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '34164170820feece745e2036cd562dc5';
export default node;
