import Form, { useField } from '@bfly/ui2/Form';
import Text from '@bfly/ui2/Text';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import DropdownList, { DropdownListProps } from 'components/DropdownList';

interface Props<TDataItem> extends DropdownListProps<TDataItem> {
  primaryFieldName: string;
  freeformFieldName: string;
  emptyList?: React.ReactNode;
}

function DropdownListWithFreeformField<TDataItem>({
  primaryFieldName,
  freeformFieldName,
  emptyList = '',
  ...props
}: Props<TDataItem>) {
  const [{ onChange, value }, { invalid }] = useField({
    name: primaryFieldName,
    validates: [primaryFieldName, freeformFieldName],
    mapToValue: (model) => ({
      primary: model[primaryFieldName],
      freeform: model[freeformFieldName],
    }),
    mapFromValue: {
      [primaryFieldName]: 'primary',
      [freeformFieldName]: 'freeform',
    },
  });

  const handleSelect = useCallback(
    (nextPrimary: TDataItem) => {
      onChange({
        primary: nextPrimary,
        freeform: null,
      });
    },
    [onChange],
  );

  const handleCreate = useCallback(
    (nextFreeform: string) => {
      onChange({
        primary: null,
        freeform: nextFreeform,
      });
    },
    [onChange],
  );

  return (
    <>
      <DropdownList<TDataItem>
        {...props}
        hideSelectedOptionIndicator
        allowCreate={props.busy ? false : 'onFilter'}
        onSelect={handleSelect}
        onCreate={handleCreate}
        invalid={invalid}
        value={value.primary || value.freeform}
        messages={{
          emptyFilter: '',
          emptyList,
          createOption: (_, searchTerm) => (
            <FormattedMessage
              id="dropdownListWithFreeformField.create"
              defaultMessage="Use <b>“{searchTerm}”</b>"
              values={{
                searchTerm,
                b: (msg: any) => <Text variant="body-bold">{msg}</Text>,
              }}
            />
          ),
        }}
      />
      <Form.Message for={[primaryFieldName, freeformFieldName]} />
    </>
  );
}

export default DropdownListWithFreeformField;
