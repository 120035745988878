import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteEhrAction_ehr$key } from './__generated__/DeleteEhrAction_ehr.graphql';

const fragment = graphql`
  fragment DeleteEhrAction_ehr on Ehr {
    ehrId
    name
    deletedAt
  }
`;

const mutation = graphql`
  mutation DeleteEhrActionMutation($input: DeleteEhrInput!) {
    deleteEhr(input: $input) {
      __typename
    }
  }
`;

interface DeleteEhrActionProps {
  ehrRef: DeleteEhrAction_ehr$key;
}

export default function DeleteEhrAction({ ehrRef }: DeleteEhrActionProps) {
  const { ehrId, name } = useFragment(fragment, ehrRef);
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      mutation={mutation}
      itemName={name}
      input={{ ehrId }}
      redirectTo="/ehrs"
      onDelete={() => deleteBreadcrumb(`/ehrs/${ehrId}`)}
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
