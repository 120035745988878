/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateMembershipModal_viewer = {
    readonly id: string;
    readonly " $refType": "CreateMembershipModal_viewer";
};
export type CreateMembershipModal_viewer$data = CreateMembershipModal_viewer;
export type CreateMembershipModal_viewer$key = {
    readonly " $data"?: CreateMembershipModal_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CreateMembershipModal_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateMembershipModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '9e260177d54b226b54cef537342f4a92';
export default node;
