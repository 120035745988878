import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import Text from '@bfly/ui2/Text';
import React from 'react';

interface DataGridLinkProps {
  id: string | number | null | undefined;
  title?: string;
  path: string;
  target?: string;
}

export default function DataGridLink({
  id,
  path,
  title,
  target,
}: DataGridLinkProps) {
  return id ? (
    <Link as={Anchor} to={`${path}/${id}`} target={target}>
      {title || id}
    </Link>
  ) : (
    <Text>-</Text>
  );
}
