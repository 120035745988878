/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateIosVersionBlacklistEntryInput = {
    applicationIdentifier: string;
    versionRange: string;
    buildNumber?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateIosVersionBlacklistEntryModalMutationVariables = {
    input: CreateIosVersionBlacklistEntryInput;
};
export type CreateIosVersionBlacklistEntryModalMutationResponse = {
    readonly createIosVersionBlacklistEntry: {
        readonly iosVersionBlacklistEntryEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry">;
            } | null;
        } | null;
    } | null;
};
export type CreateIosVersionBlacklistEntryModalMutation = {
    readonly response: CreateIosVersionBlacklistEntryModalMutationResponse;
    readonly variables: CreateIosVersionBlacklistEntryModalMutationVariables;
};



/*
mutation CreateIosVersionBlacklistEntryModalMutation(
  $input: CreateIosVersionBlacklistEntryInput!
) {
  createIosVersionBlacklistEntry(input: $input) {
    iosVersionBlacklistEntryEdge {
      node {
        id
        ...IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry
      }
    }
  }
}

fragment DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
  iosVersionBlacklistEntryId
}

fragment IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
  ...UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry
  ...DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry
  id
  iosVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  buildNumber
}

fragment UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
  id
  iosVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  buildNumber
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateIosVersionBlacklistEntryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateIosVersionBlacklistEntryPayload",
        "kind": "LinkedField",
        "name": "createIosVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IosVersionBlacklistEntryEdge",
            "kind": "LinkedField",
            "name": "iosVersionBlacklistEntryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IosVersionBlacklistEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateIosVersionBlacklistEntryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateIosVersionBlacklistEntryPayload",
        "kind": "LinkedField",
        "name": "createIosVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IosVersionBlacklistEntryEdge",
            "kind": "LinkedField",
            "name": "iosVersionBlacklistEntryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IosVersionBlacklistEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iosVersionBlacklistEntryId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "applicationIdentifier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "versionRange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buildNumber",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c8427fb65e418d7db8f37fe0e75466c",
    "id": null,
    "metadata": {},
    "name": "CreateIosVersionBlacklistEntryModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateIosVersionBlacklistEntryModalMutation(\n  $input: CreateIosVersionBlacklistEntryInput!\n) {\n  createIosVersionBlacklistEntry(input: $input) {\n    iosVersionBlacklistEntryEdge {\n      node {\n        id\n        ...IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry\n      }\n    }\n  }\n}\n\nfragment DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry on IosVersionBlacklistEntry {\n  iosVersionBlacklistEntryId\n}\n\nfragment IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry on IosVersionBlacklistEntry {\n  ...UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry\n  ...DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry\n  id\n  iosVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  buildNumber\n}\n\nfragment UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry on IosVersionBlacklistEntry {\n  id\n  iosVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  buildNumber\n}\n"
  }
};
})();
(node as any).hash = 'ffcf46d0b73a8919b4ccc878cdab1088';
export default node;
