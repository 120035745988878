import Layout from '@4c/layout';
import Badge from '@bfly/ui2/Badge';
import Dropdown from '@bfly/ui2/Dropdown';
import Link from '@bfly/ui2/Link';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import { PickerCaret } from '@bfly/ui2/Pickers';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import { useCallbackRef, useResizeObserver } from '@restart/hooks';
import { Query } from 'farce';
import useRouter from 'found/useRouter';
import React, { Fragment, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import ClippedText from 'components/ClippedText';
import actionMessages from 'messages/actions';
import { Breadcrumb } from 'utils/BreadcrumbsContext';
import { BREADCRUMBS_MAX_MENU_ITEMS } from 'utils/BreadcrumbsProvider';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

const BREADCRUMB_MAX_WIDTH = 160;
const DROPDOWN_WIDTH = 20;

/**
 * Transforms query parameters to a human-readable string
 * @param query
 */
function queryToString(query: Query): string {
  const values: string[] = [];
  for (const [key, val] of Object.entries(query)) {
    values.push(`${key}: ${val}`);
  }
  return values.join(', ');
}

interface BreadcrumbDropdownItemProps {
  breadcrumb: Breadcrumb;
  showSubtitle?: boolean;
}

/**
 * Used to display items in the list of grouped breadcrumbs
 * @param breadcrumb breadcrumb entity
 * @param showSubtitle menu item subtitle
 */
function BreadcrumbDropdownItem({
  breadcrumb,
  showSubtitle = false,
}: BreadcrumbDropdownItemProps) {
  const { key, title, query, pathname } = breadcrumb;
  const label = queryToString(query);
  const subtitle = breadcrumb.subtitle || queryToString(breadcrumb.query);
  return (
    <Dropdown.Item as={Link} to={{ pathname, query, hash: `#${key}` }}>
      <Text variant="body">
        {showSubtitle ? (
          <FormattedMessage {...title} />
        ) : (
          label || <FormattedMessage {...title} />
        )}
        {showSubtitle && subtitle && (
          <ClippedText className="text-sm" maxWidth={BREADCRUMB_MAX_WIDTH}>
            {subtitle}
          </ClippedText>
        )}
      </Text>
    </Dropdown.Item>
  );
}

interface BreadcrumbMenuItemProps {
  breadcrumb: Breadcrumb;
  badgeCount?: number;
}

/**
 * Used to display breadcrumb item in the header
 * @param breadcrumb breadcrumb entity
 * @param badgeCount number of elements in the list
 */
function BreadcrumbMenuItem({
  breadcrumb,
  badgeCount,
}: BreadcrumbMenuItemProps) {
  const { match } = useRouter();
  const hasDropdown = badgeCount && badgeCount > 1;
  const subtitle = breadcrumb.subtitle || queryToString(breadcrumb.query);

  return (
    <>
      <Tooltip.Trigger
        id={`breadcrumbTooltip-${breadcrumb.key}`}
        tooltip={<FormattedMessage {...breadcrumb.title} />}
      >
        <span>
          <ClippedText
            className={
              match.location.hash === `#${breadcrumb.key}` ? 'opacity-50' : ''
            }
            maxWidth={
              BREADCRUMB_MAX_WIDTH - (hasDropdown ? DROPDOWN_WIDTH : 0)
            }
          >
            <FormattedMessage {...breadcrumb.title} />
            {!hasDropdown && subtitle && (
              <ClippedText className="text-sm font-normal">
                {subtitle}
              </ClippedText>
            )}
          </ClippedText>
        </span>
      </Tooltip.Trigger>
      {hasDropdown && (
        <>
          <Badge className="ml-1">{badgeCount}</Badge>
          <PickerCaret className="ml-1" />
        </>
      )}
    </>
  );
}

/**
 * Used to display breadcrumb item as a link
 */
function BreadcrumbMenuLink(props: BreadcrumbMenuItemProps) {
  const { pathname, query, key } = props.breadcrumb;
  return (
    <Link to={{ pathname, query, hash: `#${key}` }} className="text-left">
      <BreadcrumbMenuItem {...props} />
    </Link>
  );
}

export default function Breadcrumbs() {
  const { breadcrumbGroups: groups, clearBreadcrumbs } =
    useBreadcrumbsContext();
  const [layoutRef, attachRef] = useCallbackRef<HTMLDivElement>();
  const rect = useResizeObserver(layoutRef);

  const maxSize = useMemo(
    () => Math.trunc((rect?.width || 0) / (BREADCRUMB_MAX_WIDTH + 32)), // 32px for divider
    [rect],
  );

  const [menuBreadcrumbs, visibleBreadcrumbs] = useMemo(() => {
    return [
      groups
        .slice(0, -maxSize || groups.length)
        .reverse()
        .reduce(
          (prev, curr) => [...prev, ...curr.breadcrumbs],
          [] as Breadcrumb[],
        )
        .slice(0, BREADCRUMBS_MAX_MENU_ITEMS),
      groups.slice(-maxSize || groups.length),
    ];
  }, [groups, maxSize]);

  return (
    <Layout flex align="center" ref={attachRef}>
      <MeatballDropdownButton
        id="BreadcrumbsMenuDropdown"
        placement="bottom-start"
        disabled={groups.length <= 1}
        variant="dark"
      >
        {menuBreadcrumbs.map((breadcrumb) => (
          <BreadcrumbDropdownItem
            showSubtitle
            key={breadcrumb.key}
            breadcrumb={breadcrumb}
          />
        ))}
        <Dropdown.Item variant="danger" onClick={clearBreadcrumbs}>
          <FormattedMessage {...actionMessages.clearHistory} />
        </Dropdown.Item>
      </MeatballDropdownButton>
      {visibleBreadcrumbs.map(
        ({ key: groupKey, breadcrumbs }, index) =>
          breadcrumbs.length && (
            <Fragment key={groupKey}>
              <Dropdown>
                <Dropdown.Toggle variant="text-secondary" id={groupKey}>
                  {breadcrumbs.length > 1 ? (
                    <BreadcrumbMenuItem
                      breadcrumb={breadcrumbs[0]}
                      badgeCount={breadcrumbs.length}
                    />
                  ) : (
                    <BreadcrumbMenuLink breadcrumb={breadcrumbs[0]} />
                  )}
                </Dropdown.Toggle>
                {breadcrumbs.length > 1 && (
                  <Dropdown.Menu variant="dark">
                    {breadcrumbs.map((breadcrumb) => (
                      <BreadcrumbDropdownItem
                        key={breadcrumb.key}
                        breadcrumb={breadcrumb}
                      />
                    ))}
                  </Dropdown.Menu>
                )}
              </Dropdown>
              {index !== visibleBreadcrumbs.length - 1 && (
                <Text variant="lg">/</Text>
              )}
            </Fragment>
          ),
      )}
    </Layout>
  );
}
