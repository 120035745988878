import { defineMessages } from 'react-intl';

export const buildNumberMessages = defineMessages({
  label: {
    id: 'iosVersionBlacklistEntryModal.buildNumber.label',
    defaultMessage: 'Build Number',
  },
  placeholder: {
    id: 'iosVersionBlacklistEntryModal.buildNumber.placeholder',
    defaultMessage: 'Build Number',
  },
  description: {
    id: 'iosVersionBlacklistEntryModal.buildNumber.description',
    defaultMessage: 'The iOS build number',
  },
});
