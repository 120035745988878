/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry = {
    readonly id: string;
    readonly androidVersionBlacklistEntryId: string | null;
    readonly applicationIdentifier: string | null;
    readonly versionRange: string | null;
    readonly versionCode: number | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry" | "DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry">;
    readonly " $refType": "AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry";
};
export type AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry$data = AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry;
export type AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry$key = {
    readonly " $data"?: AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "androidVersionBlacklistEntryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionCode",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry"
    }
  ],
  "type": "AndroidVersionBlacklistEntry",
  "abstractKey": null
};
(node as any).hash = 'c546b577bc5f93d10c337cbbb8357eaa';
export default node;
