/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InvitesPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateInviteModal_viewer">;
    readonly " $refType": "InvitesPage_viewer";
};
export type InvitesPage_viewer$data = InvitesPage_viewer;
export type InvitesPage_viewer$key = {
    readonly " $data"?: InvitesPage_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"InvitesPage_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitesPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateInviteModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '4c69cdd685044a23dab8806e1ee8afaf';
export default node;
