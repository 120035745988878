/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProvisionDomainAction_customer = {
    readonly customerId: string | null;
    readonly " $refType": "ProvisionDomainAction_customer";
};
export type ProvisionDomainAction_customer$data = ProvisionDomainAction_customer;
export type ProvisionDomainAction_customer$key = {
    readonly " $data"?: ProvisionDomainAction_customer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProvisionDomainAction_customer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProvisionDomainAction_customer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerId",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
(node as any).hash = '8e1a80b9fc3821695bd90b5b167c8cc8';
export default node;
