import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  IosVersionBlacklistEntryModal,
  iosVersionBlacklistEntrySchema,
} from './IosVersionBlacklistEntryModal';
import { UpdateIosVersionBlacklistEntryModalMutation } from './__generated__/UpdateIosVersionBlacklistEntryModalMutation.graphql';
import { UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry$key } from './__generated__/UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry.graphql';

const updateIosVersionBlacklistEntrySchema =
  iosVersionBlacklistEntrySchema.shape({
    iosVersionBlacklistEntryId: string().meta({ readOnly: true }),
  });

const fragment = graphql`
  fragment UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
    id
    iosVersionBlacklistEntryId
    applicationIdentifier
    versionRange
    buildNumber
  }
`;

const updateMutation = graphql`
  mutation UpdateIosVersionBlacklistEntryModalMutation(
    $input: UpdateIosVersionBlacklistEntryInput!
  ) {
    updateIosVersionBlacklistEntry(input: $input) {
      iosVersionBlacklistEntry {
        ...UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry
      }
    }
  }
`;

interface UpdateIosVersionBlacklistEntryModalProps extends ActionModalProps {
  iosVersionBlacklistEntryRef: UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry$key;
}

export function UpdateIosVersionBlacklistEntryModal({
  iosVersionBlacklistEntryRef,
  ...props
}: UpdateIosVersionBlacklistEntryModalProps) {
  const iosVersionBlacklistEntry = useFragment(
    fragment,
    iosVersionBlacklistEntryRef,
  );

  return (
    <IosVersionBlacklistEntryModal<UpdateIosVersionBlacklistEntryModalMutation>
      {...props}
      schema={updateIosVersionBlacklistEntrySchema}
      mutation={updateMutation}
      defaultValue={iosVersionBlacklistEntry}
      title={
        <FormattedMessage
          id="updateIosVersionBlacklistEntryModal.title"
          defaultMessage="Update iOS Version Recall Entry"
        />
      }
      name={iosVersionBlacklistEntry.iosVersionBlacklistEntryId!}
      submitText={<FormattedMessage {...actionMessages.update} />}
    />
  );
}
