/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CustomersPage_customer = {
    readonly id: string;
    readonly name: string | null;
    readonly customerId: string | null;
    readonly zendeskId: string | null;
    readonly netsuiteId: string | null;
    readonly stripeId: string | null;
    readonly sfAccountId: string | null;
    readonly " $refType": "CustomersPage_customer";
};
export type CustomersPage_customer$data = CustomersPage_customer;
export type CustomersPage_customer$key = {
    readonly " $data"?: CustomersPage_customer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CustomersPage_customer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomersPage_customer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zendeskId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netsuiteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sfAccountId",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};
(node as any).hash = '31c7fe922fe3c6384aedb662a3bcd69e';
export default node;
