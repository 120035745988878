import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import { CreateDomainUserIntegrationConfigModal } from './CreateDomainUserIntegrationConfigModal';
import { DomainUserIntegrationConfigsPage_viewer$key } from './__generated__/DomainUserIntegrationConfigsPage_viewer.graphql';

const resource = Resource.DOMAIN_USER_INTEGRATIONS_CONFIGS;

const gridFilterFields: FilterField[] = [
  { title: tableHeadings.domainId.defaultMessage, field: 'domainId' },
  { title: tableHeadings.userId.defaultMessage, field: 'userId' },
  {
    title: tableHeadings.integrationId.defaultMessage,
    field: 'integrationId',
  },
];

const fragment = graphql`
  fragment DomainUserIntegrationConfigsPage_viewer on Viewer {
    ...CreateDomainUserIntegrationConfigModal_viewer
  }
`;

interface DomainUserIntegrationsConfigsPageProps {
  viewer: DomainUserIntegrationConfigsPage_viewer$key;
}

export default function DomainUserIntegrationConfigsPage({
  viewer,
  children,
}: PropsWithChildren<DomainUserIntegrationsConfigsPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.domainUserIntegrationConfigs);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.domainUserIntegrationConfigs} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateDomainUserIntegrationConfigModal
              {...props}
              viewerRef={viewerRef}
            />
          )}
        >
          <FormattedMessage
            id="DomainUserIntegrationConfigsPage.create"
            defaultMessage="Create new integration config"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
