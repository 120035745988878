import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import Alert from 'components/Alert';
import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import domainActionMessages, {
  resetHostDeviceSecretMessages,
} from '../messages/domainActionMessages';
import { domainIdMessages } from '../messages/formMessages';
import {
  ResetHostDeviceSecretActionMutation,
  ResetHostDeviceSecretActionMutationResponse,
} from './__generated__/ResetHostDeviceSecretActionMutation.graphql';
import { ResetHostDeviceSecretAction_domain$key } from './__generated__/ResetHostDeviceSecretAction_domain.graphql';

const fragment = graphql`
  fragment ResetHostDeviceSecretAction_domain on Domain {
    id
    domainId
    name
    shouldAuthorizeHostDevices
    hostDeviceSecret
  }
`;

const mutation = graphql`
  mutation ResetHostDeviceSecretActionMutation(
    $input: ResetHostDeviceSecretInput!
  ) {
    resetHostDeviceSecret(input: $input) {
      __typename
      domain {
        id
        ...MdmSettingsPage_domain
      }
    }
  }
`;

const resetHostDeviceSecretSchema = object({
  domainId: string().required(domainIdMessages.required),
});

export type ResetHostDeviceSecretResponse = NonNullable<
  ResetHostDeviceSecretActionMutationResponse['resetHostDeviceSecret']
>;

interface ResetHostDeviceSecretActionProps {
  domainRef: ResetHostDeviceSecretAction_domain$key;
}

export default function ResetHostDeviceSecretAction({
  domainRef,
}: ResetHostDeviceSecretActionProps) {
  const { domainId } = useFragment(fragment, domainRef);
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <PermissionsGuard resource={Resource.DOMAINS} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage {...domainActionMessages.resetHostDeviceSecret} />
      </Button>
      <ResourceModal<ResetHostDeviceSecretActionMutation>
        defaultValue={{ domainId }}
        show={showModal}
        onClose={() => setShowModal(false)}
        title={
          <FormattedMessage {...domainActionMessages.resetHostDeviceSecret} />
        }
        submitText={
          <FormattedMessage {...domainActionMessages.resetHostDeviceSecret} />
        }
        mutation={mutation}
        schema={resetHostDeviceSecretSchema}
      >
        <Form.FieldGroup
          disabled
          name="domainId"
          label={<FormattedMessage {...domainIdMessages.label} />}
          placeholder={formatMessage(domainIdMessages.placeholder)}
          data-cy="resetHostDeviceSecretField-domainId"
        />
        <Alert variant="info" data-cy="resetHostDeviceSecretAction-info">
          <FormattedMessage {...resetHostDeviceSecretMessages.note} />
        </Alert>
      </ResourceModal>
    </PermissionsGuard>
  );
}
