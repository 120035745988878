import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import { ActionModalProps, ResourceModal } from 'components/ResourceModal';
import {
  organizationIdMessages,
  subscriptionIdMessages,
} from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { SetSubscriptionAction_organization$key } from './__generated__/SetSubscriptionAction_organization.graphql';

const setSubscriptionSchema = object({
  organizationId: string().required().meta({
    readOnly: true,
  }),
  subscriptionId: string().required(subscriptionIdMessages.required),
});

const fragment = graphql`
  fragment SetSubscriptionAction_organization on Organization {
    organizationId
    subscription {
      subscriptionId
    }
  }
`;

const setSubscriptionMutation = graphql`
  mutation SetSubscriptionAction_SetSubscriptionMutation(
    $input: SetOrganizationSubscriptionInput!
  ) {
    setOrganizationSubscription(input: $input) {
      organization {
        subscription {
          subscriptionId
        }
      }
    }
  }
`;

interface SetSubscriptionModalProps extends ActionModalProps {
  organizationRef: SetSubscriptionAction_organization$key;
}

function SetSubscriptionModal({
  organizationRef,
  ...props
}: SetSubscriptionModalProps) {
  const { organizationId, subscription } = useFragment(
    fragment,
    organizationRef,
  );
  const { subscriptionId } = subscription!;
  const { formatMessage } = useIntl();
  return (
    <ResourceModal
      {...props}
      title={
        <FormattedMessage
          id="setSubscription.title"
          defaultMessage="Set Subscription"
        />
      }
      submitText={
        <FormattedMessage
          id="setSubscription.submitText"
          defaultMessage="Set Subscription"
        />
      }
      schema={setSubscriptionSchema}
      mutation={setSubscriptionMutation}
      defaultValue={{ organizationId, subscriptionId }}
    >
      <Form.FieldGroup
        disabled
        name="organizationId"
        label={<FormattedMessage {...organizationIdMessages.label} />}
        placeholder={formatMessage(organizationIdMessages.placeholder)}
        data-cy="setSubscription-organizationId"
      />
      <Form.FieldGroup
        name="subscriptionId"
        label={<FormattedMessage {...subscriptionIdMessages.label} />}
        placeholder={formatMessage(subscriptionIdMessages.placeholder)}
        data-cy="setSubscription-subscriptionId"
      />
    </ResourceModal>
  );
}

interface SetSubscriptionActionProps {
  organizationRef: SetSubscriptionAction_organization$key;
}

export default function SetSubscriptionAction({
  organizationRef,
}: SetSubscriptionActionProps) {
  const [showSubscription, setShowSubscription] = useState(false);
  return (
    <PermissionsGuard
      resource={Resource.ORGANIZATION_MANAGEMENT_ADMIN_READ_WRITE}
      write
      hideContent
    >
      <Button variant="secondary" onClick={() => setShowSubscription(true)}>
        <FormattedMessage
          id="organizationDetail.actions.setSubscription"
          defaultMessage="Set Subscription"
        />
      </Button>
      <SetSubscriptionModal
        organizationRef={organizationRef}
        show={showSubscription}
        onClose={() => setShowSubscription(false)}
      />
    </PermissionsGuard>
  );
}
