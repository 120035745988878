/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateDomainUserIntegrationConfigInput = {
    domainUserIntegrationConfigId: string;
    interfaceCode?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateDomainUserIntegrationConfigModalMutationVariables = {
    input: UpdateDomainUserIntegrationConfigInput;
};
export type UpdateDomainUserIntegrationConfigModalMutationResponse = {
    readonly updateDomainUserIntegrationConfig: {
        readonly domainUserIntegrationConfig: {
            readonly " $fragmentRefs": FragmentRefs<"DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig">;
        } | null;
    } | null;
};
export type UpdateDomainUserIntegrationConfigModalMutation = {
    readonly response: UpdateDomainUserIntegrationConfigModalMutationResponse;
    readonly variables: UpdateDomainUserIntegrationConfigModalMutationVariables;
};



/*
mutation UpdateDomainUserIntegrationConfigModalMutation(
  $input: UpdateDomainUserIntegrationConfigInput!
) {
  updateDomainUserIntegrationConfig(input: $input) {
    domainUserIntegrationConfig {
      ...DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig
      id
    }
  }
}

fragment DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig on DomainUserIntegrationConfig {
  id
  domainUserIntegrationConfigId
  interfaceCode
  ehr {
    ehrId
    id
  }
  domainUser {
    domainUserId
    domain {
      domainId
      id
    }
    user {
      userId
      id
    }
    name
    email
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDomainUserIntegrationConfigModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDomainUserIntegrationConfigPayload",
        "kind": "LinkedField",
        "name": "updateDomainUserIntegrationConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserIntegrationConfig",
            "kind": "LinkedField",
            "name": "domainUserIntegrationConfig",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDomainUserIntegrationConfigModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDomainUserIntegrationConfigPayload",
        "kind": "LinkedField",
        "name": "updateDomainUserIntegrationConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUserIntegrationConfig",
            "kind": "LinkedField",
            "name": "domainUserIntegrationConfig",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domainUserIntegrationConfigId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "interfaceCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Ehr",
                "kind": "LinkedField",
                "name": "ehr",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ehrId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUser",
                "kind": "LinkedField",
                "name": "domainUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainUserId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "domain",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "551e831484457d05a4f03b80058261f8",
    "id": null,
    "metadata": {},
    "name": "UpdateDomainUserIntegrationConfigModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDomainUserIntegrationConfigModalMutation(\n  $input: UpdateDomainUserIntegrationConfigInput!\n) {\n  updateDomainUserIntegrationConfig(input: $input) {\n    domainUserIntegrationConfig {\n      ...DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig\n      id\n    }\n  }\n}\n\nfragment DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig on DomainUserIntegrationConfig {\n  id\n  domainUserIntegrationConfigId\n  interfaceCode\n  ehr {\n    ehrId\n    id\n  }\n  domainUser {\n    domainUserId\n    domain {\n      domainId\n      id\n    }\n    user {\n      userId\n      id\n    }\n    name\n    email\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '69fff6fe7f576972d41fe07e69dea20c';
export default node;
