import React from 'react';

import QuickCopyButton from './QuickCopyButton';

export default function QuickCopyText({ children, text }) {
  return !text || text === '-' ? (
    <>{children}</>
  ) : (
    <span className="group relative py-2 pr-5">
      {children}
      <QuickCopyButton text={text} />
    </span>
  );
}
