/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EducationCategoriesPage_educationCategory = {
    readonly id: string;
    readonly educationCategoryId: string | null;
    readonly name: string | null;
    readonly color: string | null;
    readonly ordering: number | null;
    readonly " $refType": "EducationCategoriesPage_educationCategory";
};
export type EducationCategoriesPage_educationCategory$data = EducationCategoriesPage_educationCategory;
export type EducationCategoriesPage_educationCategory$key = {
    readonly " $data"?: EducationCategoriesPage_educationCategory$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EducationCategoriesPage_educationCategory">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationCategoriesPage_educationCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationCategoryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordering",
      "storageKey": null
    }
  ],
  "type": "EducationCategory",
  "abstractKey": null
};
(node as any).hash = '5b60e6a4cde92746001f31a1c0444e6d';
export default node;
