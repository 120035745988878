import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import {
  SUBSCRIPTION_REGULAR_PLAN_TYPES,
  SubscriptionModal,
  subscriptionSchema,
} from 'components/SubscriptionModal';
import actionMessages from 'messages/actions';

import { UpdateSubscriptionModalMutation } from './__generated__/UpdateSubscriptionModalMutation.graphql';
import {
  UpdateSubscriptionModal_subscription as Subscription,
  UpdateSubscriptionModal_subscription$key,
} from './__generated__/UpdateSubscriptionModal_subscription.graphql';

const fragment = graphql`
  fragment UpdateSubscriptionModal_subscription on Subscription {
    id
    subscriptionId
    subscriptionEndsAt
    accessEndsAt
    hasStartedActiveSubscription
    hasCompletedTrial
    canAccessProFeatures
    isInGracePeriod
    isTeam
    billingStatus
    billingProvider
    stripeSubscriptionId
    sfSubscriptionId
    featureModuleIds
    planType
    maxNumSeats
    practiceType
    domain {
      domainId
    }
    customer {
      customerId
    }
  }
`;

const updateMutation = graphql`
  mutation UpdateSubscriptionModalMutation($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      subscription {
        featureModuleIds
        ...SubscriptionsGrid_subscription
      }
    }
  }
`;

const transformOnSubmit = (value: Subscription) =>
  pick(value, [
    'subscriptionId',
    'planType',
    'maxNumSeats',
    'practiceType',
    'subscriptionEndsAt',
    'billingStatus',
    'billingProvider',
    'stripeSubscriptionId',
    'sfSubscriptionId',
    'featureModuleIds',
    'customerId',
  ]);

export const updateSubscriptionSchema = subscriptionSchema.shape({
  domainId: string().nullable(),
  subscriptionId: string(),
  planType: string().required().oneOf(SUBSCRIPTION_REGULAR_PLAN_TYPES),
  // FIXME: Uncomment this when we are ok with enforcing Enterprise plan
  // type constraints during updates to existing subscriptions
  // .when('domainId', (domainId) =>
  //   domainId
  //     ? string()
  //         .required()
  //         .oneOf(PROVISION_WITH_DOMAIN_REGULAR_PLAN_TYPES)
  //     : string()
  //         .required()
  //         .oneOf(PROVISION_REGULAR_PLAN_TYPES),
  // ),
});

interface UpdateSubscriptionModalProps extends ActionModalProps {
  subscriptionRef: UpdateSubscriptionModal_subscription$key;
}

export function UpdateSubscriptionModal({
  show,
  onClose,
  subscriptionRef,
}: UpdateSubscriptionModalProps) {
  const subscription = useFragment(fragment, subscriptionRef);

  const transformedDefaultValue = useMemo(
    () => ({
      ...subscription,
      customerId: subscription!.customer!.customerId,
      domainId: subscription!.domain?.domainId,
      featureModuleIds: subscription!.featureModuleIds || [],
    }),
    [subscription],
  );

  return (
    <SubscriptionModal<UpdateSubscriptionModalMutation>
      transformOnSubmit={transformOnSubmit}
      title={
        <FormattedMessage
          id="updateSubscriptionModal.title"
          defaultMessage="Update Subscription"
        />
      }
      name={transformedDefaultValue.subscriptionId!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      schema={updateSubscriptionSchema}
      mutation={updateMutation}
      defaultValue={transformedDefaultValue}
    />
  );
}
