import { defineMessages } from 'react-intl';

export const placeOfWorkIdMessages = defineMessages({
  label: {
    id: 'placeOfWorkModal.placeOfWorkIdMessages.label',
    defaultMessage: 'Place Of Work ID*',
  },
  placeholder: {
    id: 'placeOfWorkModal.placeOfWorkIdMessages.placeholder',
    defaultMessage: 'Place Of Work ID',
  },
});

export const nameMessages = defineMessages({
  label: {
    id: 'placeOfWorkModal.name.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'placeOfWorkModal.organizationId.placeholder',
    defaultMessage: 'Name',
  },
  required: {
    id: 'placeOfWorkModal.organizationId.required',
    defaultMessage: 'Please enter a valid name',
  },
});

export const countriesMessages = defineMessages({
  label: {
    id: 'placeOfWorkModal.countries.label',
    defaultMessage: 'Countries*',
  },
  placeholder: {
    id: 'placeOfWorkModal.countries.placeholder',
    defaultMessage: 'Countries',
  },
  required: {
    id: 'placeOfWorkModal.countries.required',
    defaultMessage: 'Please enter a valid countries list',
  },
  description: {
    id: 'placeOfWorkModal.countries.description',
    defaultMessage:
      'The countries for which this place of work would be appropriate (2-letter ISO code(s))',
  },
});

export const statusMessages = defineMessages({
  label: {
    id: 'placeOfWorkModal.status.label',
    defaultMessage: 'Status*',
  },
  placeholder: {
    id: 'placeOfWorkModal.status.placeholder',
    defaultMessage: 'Status',
  },
  required: {
    id: 'placeOfWorkModal.status.required',
    defaultMessage: 'Please enter a valid status',
  },
});
