import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { object, string } from 'yup';

import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { formMessages } from 'messages/formMessages';
import { nullableString } from 'utils/yupSchemaUtils';

import {
  customerIdMessages,
  customerNameMessages,
  netsuiteIdMessages,
  sfAccountIdMessages,
  stripeIdMessages,
  zendeskIdMessages,
} from '../messages/customerCreation';

export const customerSchema = object({
  customerId: nullableString().meta({ readOnly: true }),
  name: string()
    .required(customerNameMessages.required)
    .min(2, formMessages.minLength),
  zendeskId: nullableString(),
  netsuiteId: nullableString(),
  stripeId: nullableString(),
  sfAccountId: nullableString(),
});

export default function CustomerModal<TMutation extends MutationParameters>({
  defaultValue,
  ...props
}: ResourceModalProps<TMutation>) {
  const isCreate = !defaultValue?.customerId;
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation> {...props} defaultValue={defaultValue}>
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="customerId"
          label={<FormattedMessage {...customerIdMessages.label} />}
          placeholder={formatMessage(customerIdMessages.placeholder)}
          data-cy="customerModal-customerIdField"
        />
      )}
      <Form.FieldGroup
        name="name"
        label={<FormattedMessage {...customerNameMessages.label} />}
        placeholder={formatMessage(customerNameMessages.placeholder)}
        description={
          <FormattedMessage {...customerNameMessages.description} />
        }
        className="name"
        data-cy="customerModal-nameField"
      />
      <Form.FieldGroup
        name="zendeskId"
        label={<FormattedMessage {...zendeskIdMessages.label} />}
        placeholder={formatMessage(zendeskIdMessages.placeholder)}
        description={<FormattedMessage {...zendeskIdMessages.description} />}
        data-cy="customerModal-zendeskIdField"
      />
      <Form.FieldGroup
        name="netsuiteId"
        label={<FormattedMessage {...netsuiteIdMessages.label} />}
        placeholder={formatMessage(netsuiteIdMessages.placeholder)}
        description={<FormattedMessage {...netsuiteIdMessages.description} />}
        data-cy="customerModal-netsuiteIdField"
      />
      <Form.FieldGroup
        name="stripeId"
        label={<FormattedMessage {...stripeIdMessages.label} />}
        placeholder={formatMessage(stripeIdMessages.placeholder)}
        description={<FormattedMessage {...stripeIdMessages.description} />}
        data-cy="customerModal-stripeIdField"
      />
      <Form.FieldGroup
        name="sfAccountId"
        label={<FormattedMessage {...sfAccountIdMessages.label} />}
        placeholder={formatMessage(sfAccountIdMessages.placeholder)}
        description={<FormattedMessage {...sfAccountIdMessages.description} />}
        data-cy="customerModal-sfAccountIdField"
      />
    </ResourceModal>
  );
}
