import Button from '@bfly/ui2/Button';
import useRouter from 'found/useRouter';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { date, number, string } from 'yup';

import Alert from 'components/Alert';
import {
  OrganizationModal,
  basicOrganizationSchema,
} from 'components/OrganizationModal';
import { ActionModalProps } from 'components/ResourceModal';
import { practiceTypeMessages } from 'messages/formMessages';
import {
  adminEmailMessages,
  billingProviderMessages,
  billingStatusMessages,
  countryMessages,
  customerIdMessages,
  dlDataModeOverrideMessages,
  maxNumSeatsMessages,
  planTypeMessages,
  subscriptionEndsAtMessages,
} from 'messages/organizationActions';
import {
  billingProviderData,
  billingStatusData,
  dlDataModeOverrideData,
  planTypeData,
  practiceTypeData,
} from 'messages/organizationOptionsData';
import PermissionsGuard from 'utils/PermissionsGuard';
import getOneYearFromNow from 'utils/getOneYearFromNow';
import { Resource } from 'utils/permissions';

import {
  ProvisionOrganizationActionMutation,
  ProvisionOrganizationActionMutationResponse,
} from './__generated__/ProvisionOrganizationActionMutation.graphql';
import { ProvisionOrganizationAction_customer$key } from './__generated__/ProvisionOrganizationAction_customer.graphql';

const provisionOrganizationSchema = basicOrganizationSchema.shape({
  planType: string()
    .required(planTypeMessages.required)
    .oneOf(planTypeData.map(({ value }) => value)),
  maxNumSeats: number().required(maxNumSeatsMessages.required),
  practiceType: string()
    .required(practiceTypeMessages.required)
    .oneOf(practiceTypeData.map(({ value }) => value)),
  subscriptionEndsAt: date()
    .nullable()
    .default(getOneYearFromNow)
    .meta({ type: 'datetime' })
    .required(subscriptionEndsAtMessages.required),
  billingStatus: string()
    .required(billingStatusMessages.required)
    .oneOf(billingStatusData.map(({ value }) => value)),
  billingProvider: string()
    .required(billingProviderMessages.required)
    .oneOf(billingProviderData.map(({ value }) => value)),
  stripeSubscriptionId: string(),
  sfSubscriptionId: string(),
  customerId: string().required(customerIdMessages.required),
  adminEmail: string()
    .email(adminEmailMessages.validation)
    .required(adminEmailMessages.required),
  country: string().max(2).required(countryMessages.required),
  dlDataModeOverride: string()
    .nullable()
    .oneOf(dlDataModeOverrideData.map(({ value }) => value))
    .default(dlDataModeOverrideMessages.exclude.defaultMessage),
});
const schemaDefaults = provisionOrganizationSchema.getDefault();

const fragment = graphql`
  fragment ProvisionOrganizationAction_customer on Customer {
    customerId
  }
`;

const mutation = graphql`
  mutation ProvisionOrganizationActionMutation(
    $input: ProvisionOrganizationInput!
  ) {
    provisionOrganization(input: $input) {
      organization {
        id
        organizationId
      }
    }
  }
`;

interface ProvisionOrganizationModalProps extends ActionModalProps {
  customerRef: ProvisionOrganizationAction_customer$key;
}

function ProvisionOrganizationModal({
  customerRef,
  ...props
}: ProvisionOrganizationModalProps) {
  const { router } = useRouter();
  const customer = useFragment(fragment, customerRef);

  return (
    <OrganizationModal<ProvisionOrganizationActionMutation>
      {...props}
      title={
        <FormattedMessage
          id="provisionOrganizationModal.title"
          defaultMessage="Provision Organization & Subscription"
        />
      }
      submitText={
        <FormattedMessage
          id="provisionOrganizationModal.submitText"
          defaultMessage="Provision Organization"
        />
      }
      mutation={mutation}
      schema={provisionOrganizationSchema}
      defaultValue={{ ...schemaDefaults, ...customer }}
      onCompleted={({
        provisionOrganization,
      }: ProvisionOrganizationActionMutationResponse) => {
        if (provisionOrganization?.organization?.organizationId) {
          router.push({
            pathname: `/organizations/${provisionOrganization.organization.organizationId}`,
          });
        }
      }}
      alertMessage={
        <Alert variant="warning" className="mb-5">
          <FormattedMessage
            id="createOrganizationModal.warning"
            defaultMessage="This form will provision both a new Organization and an associated Subscription. To provision a new Enterprise Organization under an existing Subscription, please navigate to that Subscription's detail page. Please note, if a domain is required for a specific plan type, it will only appear under the correct provisioning screen."
          />
        </Alert>
      }
    />
  );
}

interface ProvisionOrganizationActionProps {
  customerRef: ProvisionOrganizationAction_customer$key;
}

export default function ProvisionOrganizationAction({
  customerRef,
}: ProvisionOrganizationActionProps) {
  const [showProvisionOrganization, setShowProvisionOrganization] =
    useState(false);

  return (
    <PermissionsGuard resource={Resource.PROVISIONING} write hideContent>
      <Button
        variant="secondary"
        onClick={() => setShowProvisionOrganization(true)}
      >
        <FormattedMessage
          id="customerDetail.actions.provisionOrganization"
          defaultMessage="Provision Organization"
        />
      </Button>
      <ProvisionOrganizationModal
        customerRef={customerRef}
        show={showProvisionOrganization}
        onClose={() => setShowProvisionOrganization(false)}
      />
    </PermissionsGuard>
  );
}
