/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_QueryVariables = {
    recallId: string;
};
export type butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_QueryResponse = {
    readonly butterflyDeviceRecall: {
        readonly " $fragmentRefs": FragmentRefs<"ButterflyDeviceRecallDetailPage_butterflyDeviceRecall">;
    } | null;
};
export type butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_Query = {
    readonly response: butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_QueryResponse;
    readonly variables: butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_QueryVariables;
};



/*
query butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_Query(
  $recallId: String!
) {
  butterflyDeviceRecall(recallId: $recallId) {
    ...ButterflyDeviceRecallDetailPage_butterflyDeviceRecall
    id
  }
}

fragment ButterflyDeviceRecallDetailPage_butterflyDeviceRecall on ButterflyDeviceRecall {
  ...UpdateButterflyDeviceRecallModal_butterflyDeviceRecall
  id
  recallId
  message
  createdAt
}

fragment UpdateButterflyDeviceRecallModal_butterflyDeviceRecall on ButterflyDeviceRecall {
  id
  recallId
  message
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "recallId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "recallId",
    "variableName": "recallId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ButterflyDeviceRecall",
        "kind": "LinkedField",
        "name": "butterflyDeviceRecall",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ButterflyDeviceRecallDetailPage_butterflyDeviceRecall"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ButterflyDeviceRecall",
        "kind": "LinkedField",
        "name": "butterflyDeviceRecall",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recallId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b8c934eeb7ffab0a446ae03d966f8e10",
    "id": null,
    "metadata": {},
    "name": "butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_Query",
    "operationKind": "query",
    "text": "query butterflyDeviceRecalls_ButterflyDeviceRecallDetailPage_Query(\n  $recallId: String!\n) {\n  butterflyDeviceRecall(recallId: $recallId) {\n    ...ButterflyDeviceRecallDetailPage_butterflyDeviceRecall\n    id\n  }\n}\n\nfragment ButterflyDeviceRecallDetailPage_butterflyDeviceRecall on ButterflyDeviceRecall {\n  ...UpdateButterflyDeviceRecallModal_butterflyDeviceRecall\n  id\n  recallId\n  message\n  createdAt\n}\n\nfragment UpdateButterflyDeviceRecallModal_butterflyDeviceRecall on ButterflyDeviceRecall {\n  id\n  recallId\n  message\n}\n"
  }
};
})();
(node as any).hash = '551c89c006c8ac47f91194246c4ad784';
export default node;
