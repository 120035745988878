import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import domainInvitesMessages from '../messages/domainInvitesMessages';
import ResendUserInviteAction from './ResendUserInviteAction';
import ShowUserInviteLinkAction from './ShowUserInviteLinkAction';
import { DomainInviteDetailPage_userInvite$key } from './__generated__/DomainInviteDetailPage_userInvite.graphql';

const fragment = graphql`
  fragment DomainInviteDetailPage_userInvite on UserInvite {
    ...ResendUserInviteAction_userInvite
    ...ShowUserInviteLinkAction_userInvite
    id
    userInviteId
    createdAt
    usedAt
    user {
      userId
      email
    }
    domain {
      domainId
      name
    }
  }
`;

interface DomainInviteDetailPageProps {
  userInvite: DomainInviteDetailPage_userInvite$key;
}

export default function DomainInviteDetailPage({
  userInvite,
}: DomainInviteDetailPageProps) {
  const data = useFragment(fragment, userInvite);
  const { domain, user, usedAt } = data;
  useBreadcrumbDetector(pageTitles.domainInvite, user?.email);
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.DOMAIN_INVITES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.domainInvite} />}
        name={user?.email || '-'}
        data={data}
        quickCopyFields={[
          'userInviteId',
          'user: email',
          'user: userId',
          'domain: domainId',
          'domain: name',
        ]}
        resourceActions={
          canWrite(Resource.DOMAIN_INVITES) && (
            <>
              <ShowUserInviteLinkAction userInviteRef={data} />
              {!usedAt && <ResendUserInviteAction userInviteRef={data} />}
            </>
          )
        }
        relatedItems={
          <>
            {domain && (
              <RelatedItem name="Domain" to={`/domains/${domain.domainId}`}>
                <FormattedMessage {...domainInvitesMessages.domain} />
              </RelatedItem>
            )}
            {user && (
              <RelatedItem
                name="User"
                to={`/users/${user.userId}/${domain?.domainId ?? '-'}`}
              >
                <FormattedMessage {...domainInvitesMessages.user} />
              </RelatedItem>
            )}
          </>
        }
      />
    </PermissionsGuard>
  );
}
