import React from 'react';
import { defineMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';

import { DeleteDomainUserActionDeleteMutation } from './__generated__/DeleteDomainUserActionDeleteMutation.graphql';
import { DeleteDomainUserAction_user$key } from './__generated__/DeleteDomainUserAction_user.graphql';

const fragment = graphql`
  fragment DeleteDomainUserAction_user on FlatUser {
    id
    name
    domainUserDeletedAt
    userId
    domainId
  }
`;

const mutation = graphql`
  mutation DeleteDomainUserActionDeleteMutation(
    $input: DeleteDomainUserInput!
  ) {
    deleteDomainUser(input: $input) {
      domainUser {
        __typename
        deletedAt
      }
    }
  }
`;

interface DeleteDomainUserActionProps {
  userRef: DeleteDomainUserAction_user$key;
}

export default function DeleteDomainUserAction({
  userRef,
}: DeleteDomainUserActionProps) {
  const { id, name, userId, domainId } = useFragment(fragment, userRef);

  return (
    <DeleteAction<DeleteDomainUserActionDeleteMutation>
      mutation={mutation}
      itemName={name ?? 'Domain User'}
      input={{ domainUserId: `${domainId}/${userId}` }}
      updater={(store, { deleteDomainUser: response }) => {
        store
          .get(id)!
          .setValue(response!.domainUser!.deletedAt, 'domainUserDeletedAt');
      }}
      actionMessageMsg={defineMessage({
        id: 'deleteDomainUserAction.delete',
        defaultMessage: 'Deactivate domain user',
      })}
    />
  );
}
