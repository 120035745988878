import { LocationDescriptorObject, useRouter } from 'found';
import localStore from 'store/dist/store.modern';

export default function usePersistedUrlState<T>(
  stateKey,
  storageKey: string,
  defaultValue?: T,
) {
  const {
    router,
    match: { location },
  } = useRouter();

  const value: T =
    location.state?.[stateKey] || localStore.get(storageKey, defaultValue);

  return [
    value,
    (nextValue: T, nextLocation: LocationDescriptorObject = location) => {
      localStore.set(storageKey, nextValue);
      router.replace({
        ...nextLocation,
        state: { ...nextLocation.state, [stateKey]: nextValue },
      });
    },
  ] as const;
}
