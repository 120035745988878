/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type educationCategories_EducationCategoryDetailPage_QueryVariables = {
    educationCategoryId: string;
};
export type educationCategories_EducationCategoryDetailPage_QueryResponse = {
    readonly educationCategory: {
        readonly " $fragmentRefs": FragmentRefs<"EducationCategoryDetailPage_educationCategory">;
    } | null;
};
export type educationCategories_EducationCategoryDetailPage_Query = {
    readonly response: educationCategories_EducationCategoryDetailPage_QueryResponse;
    readonly variables: educationCategories_EducationCategoryDetailPage_QueryVariables;
};



/*
query educationCategories_EducationCategoryDetailPage_Query(
  $educationCategoryId: String!
) {
  educationCategory(educationCategoryId: $educationCategoryId) {
    ...EducationCategoryDetailPage_educationCategory
    id
  }
}

fragment DeleteEducationCategoryAction_educationCategory on EducationCategory {
  id
  educationCategoryId
  name
}

fragment EducationCategoryDetailPage_educationCategory on EducationCategory {
  ...UpdateEducationCategoryModal_educationCategory
  ...DeleteEducationCategoryAction_educationCategory
  id
  educationCategoryId
  name
  color
  ordering
}

fragment UpdateEducationCategoryModal_educationCategory on EducationCategory {
  id
  educationCategoryId
  name
  ordering
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "educationCategoryId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "educationCategoryId",
    "variableName": "educationCategoryId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "educationCategories_EducationCategoryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EducationCategory",
        "kind": "LinkedField",
        "name": "educationCategory",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EducationCategoryDetailPage_educationCategory"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "educationCategories_EducationCategoryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EducationCategory",
        "kind": "LinkedField",
        "name": "educationCategory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "educationCategoryId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordering",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ead3cde8c74ee2f29bb0b7fe3181f809",
    "id": null,
    "metadata": {},
    "name": "educationCategories_EducationCategoryDetailPage_Query",
    "operationKind": "query",
    "text": "query educationCategories_EducationCategoryDetailPage_Query(\n  $educationCategoryId: String!\n) {\n  educationCategory(educationCategoryId: $educationCategoryId) {\n    ...EducationCategoryDetailPage_educationCategory\n    id\n  }\n}\n\nfragment DeleteEducationCategoryAction_educationCategory on EducationCategory {\n  id\n  educationCategoryId\n  name\n}\n\nfragment EducationCategoryDetailPage_educationCategory on EducationCategory {\n  ...UpdateEducationCategoryModal_educationCategory\n  ...DeleteEducationCategoryAction_educationCategory\n  id\n  educationCategoryId\n  name\n  color\n  ordering\n}\n\nfragment UpdateEducationCategoryModal_educationCategory on EducationCategory {\n  id\n  educationCategoryId\n  name\n  ordering\n}\n"
  }
};
})();
(node as any).hash = '7a60458f67891a2bc6c689db8706240f';
export default node;
