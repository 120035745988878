import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { array, date, number, string } from 'yup';

import Alert from 'components/Alert';
import {
  DEPARTMENT,
  EDUCATION,
  EDU_FEATURE_MODULE_IDS,
  ENTERPRISE_WORKFLOW,
  HOSPITAL,
  INDIVIDUAL_FOREVER,
  MEDICAL_SCHOOL,
  OrganizationModal,
  PRO_CUSTOM,
  PRO_CUSTOM_FEATURE_MODULE_IDS,
  RESIDENCY_PROGRAM,
  basicOrganizationSchema,
} from 'components/OrganizationModal';
import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import { practiceTypeMessages } from 'messages/formMessages';
import {
  adminEmailMessages,
  billingProviderMessages,
  billingStatusMessages,
  countryMessages,
  customerIdMessages,
  dlDataModeOverrideMessages,
  featureModuleIdsMessages,
  maxNumSeatsMessages,
  planTypeMessages,
  subscriptionEndsAtMessages,
} from 'messages/organizationActions';
import {
  billingProviderData,
  billingStatusData,
  dlDataModeOverrideData,
  planTypeData,
  practiceTypeData,
} from 'messages/organizationOptionsData';
import createUpdater from 'utils/createUpdater';
import getOneYearFromNow from 'utils/getOneYearFromNow';

import { CreateOrganizationModal_ProvisionMutation } from './__generated__/CreateOrganizationModal_ProvisionMutation.graphql';
import { CreateOrganizationModal_viewer$key } from './__generated__/CreateOrganizationModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateOrganizationModal_ProvisionMutation(
    $input: ProvisionOrganizationInput!
  ) {
    provisionOrganization(input: $input) {
      organization {
        id
        ...OrganizationsGrid_organization
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateOrganizationModal_viewer on Viewer {
    id
  }
`;

const createOrganizationSchema = basicOrganizationSchema.shape({
  planType: string()
    .required(planTypeMessages.required)
    .oneOf(planTypeData.map(({ value }) => value)),
  maxNumSeats: number().required(maxNumSeatsMessages.required),
  practiceType: string()
    .required(practiceTypeMessages.required)
    .oneOf(practiceTypeData.map(({ value }) => value)),
  subscriptionEndsAt: date()
    .nullable()
    .default(getOneYearFromNow)
    .meta({ type: 'datetime' })
    .when('planType', (planType, fieldSchema) =>
      planType === INDIVIDUAL_FOREVER
        ? fieldSchema
        : fieldSchema.required(subscriptionEndsAtMessages.required),
    ),
  billingStatus: string()
    .required(billingStatusMessages.required)
    .oneOf(billingStatusData.map(({ value }) => value)),
  billingProvider: string()
    .required(billingProviderMessages.required)
    .oneOf(billingProviderData.map(({ value }) => value)),
  stripeSubscriptionId: string(),
  sfSubscriptionId: string(),
  customerId: string().required(customerIdMessages.required),
  featureModuleIds: array()
    .nullable()
    .of(string())
    .default([])
    .when('planType', {
      is: PRO_CUSTOM,
      then: (f) =>
        f
          .min(1, featureModuleIdsMessages.required)
          .of(string().oneOf(PRO_CUSTOM_FEATURE_MODULE_IDS)),
    })
    .when('planType', {
      is: (planType) =>
        [
          EDUCATION,
          ENTERPRISE_WORKFLOW,
          RESIDENCY_PROGRAM,
          DEPARTMENT,
          HOSPITAL,
          MEDICAL_SCHOOL,
        ].includes(planType),
      then: (f) => f.of(string().oneOf(EDU_FEATURE_MODULE_IDS)),
    }),
  adminEmail: string()
    .email(adminEmailMessages.validation)
    .required(adminEmailMessages.required),
  country: string().max(2).required(countryMessages.required),
  dlDataModeOverride: string()
    .nullable()
    .oneOf(dlDataModeOverrideData.map(({ value }) => value))
    .default(dlDataModeOverrideMessages.noOverride.defaultMessage),
});

interface CreateOrganizationModalProps extends ActionModalProps {
  viewerRef: CreateOrganizationModal_viewer$key;
}

export default function CreateOrganizationModal({
  viewerRef,
  ...props
}: CreateOrganizationModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  return (
    <OrganizationModal<CreateOrganizationModal_ProvisionMutation>
      {...props}
      title={
        <FormattedMessage
          id="createOrganizationModal.title"
          defaultMessage="Create Organization"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      mutation={createMutation}
      schema={createOrganizationSchema}
      updater={createUpdater({
        connectionKey: 'OrganizationsGrid_organizations',
        getNode: ({ provisionOrganization }) =>
          provisionOrganization!.organization!,
        connectionContainerId: viewer.id,
      })}
      alertMessage={
        <Alert variant="warning" className="mb-5">
          <FormattedMessage
            id="createOrganizationModal.warning"
            defaultMessage="This form will provision both a new Organization and an associated Subscription. To provision a new Enterprise Organization under an existing Subscription, please navigate to that Subscription's detail page. Please note, if a domain is required for a specific plan type, it will only appear under the correct provisioning screen."
          />
        </Alert>
      }
      {...props}
    />
  );
}
