/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateAndroidVersionBlacklistEntryInput = {
    androidVersionBlacklistEntryId: unknown;
    applicationIdentifier?: string | null | undefined;
    versionRange?: string | null | undefined;
    versionCode?: number | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateAndroidVersionBlacklistEntryModalMutationVariables = {
    input: UpdateAndroidVersionBlacklistEntryInput;
};
export type UpdateAndroidVersionBlacklistEntryModalMutationResponse = {
    readonly updateAndroidVersionBlacklistEntry: {
        readonly androidVersionBlacklistEntry: {
            readonly " $fragmentRefs": FragmentRefs<"UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry">;
        } | null;
    } | null;
};
export type UpdateAndroidVersionBlacklistEntryModalMutation = {
    readonly response: UpdateAndroidVersionBlacklistEntryModalMutationResponse;
    readonly variables: UpdateAndroidVersionBlacklistEntryModalMutationVariables;
};



/*
mutation UpdateAndroidVersionBlacklistEntryModalMutation(
  $input: UpdateAndroidVersionBlacklistEntryInput!
) {
  updateAndroidVersionBlacklistEntry(input: $input) {
    androidVersionBlacklistEntry {
      ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry
      id
    }
  }
}

fragment UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
  id
  androidVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  versionCode
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAndroidVersionBlacklistEntryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAndroidVersionBlacklistEntryPayload",
        "kind": "LinkedField",
        "name": "updateAndroidVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AndroidVersionBlacklistEntry",
            "kind": "LinkedField",
            "name": "androidVersionBlacklistEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAndroidVersionBlacklistEntryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAndroidVersionBlacklistEntryPayload",
        "kind": "LinkedField",
        "name": "updateAndroidVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AndroidVersionBlacklistEntry",
            "kind": "LinkedField",
            "name": "androidVersionBlacklistEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "androidVersionBlacklistEntryId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "applicationIdentifier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "versionRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "versionCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef39f6ba1d0f0077f690078c7ef77720",
    "id": null,
    "metadata": {},
    "name": "UpdateAndroidVersionBlacklistEntryModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAndroidVersionBlacklistEntryModalMutation(\n  $input: UpdateAndroidVersionBlacklistEntryInput!\n) {\n  updateAndroidVersionBlacklistEntry(input: $input) {\n    androidVersionBlacklistEntry {\n      ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry\n      id\n    }\n  }\n}\n\nfragment UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {\n  id\n  androidVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  versionCode\n}\n"
  }
};
})();
(node as any).hash = '55ab8704c0dae0f97084fbbedef336af';
export default node;
