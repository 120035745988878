/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type JoinDomainAction_organization = {
    readonly organizationId: string | null;
    readonly " $refType": "JoinDomainAction_organization";
};
export type JoinDomainAction_organization$data = JoinDomainAction_organization;
export type JoinDomainAction_organization$key = {
    readonly " $data"?: JoinDomainAction_organization$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"JoinDomainAction_organization">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JoinDomainAction_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
(node as any).hash = '21c19c77e6f7564c707e53a4397e80a3';
export default node;
