import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import Alert from 'components/Alert';
import {
  OrganizationModal,
  basicOrganizationSchema,
} from 'components/OrganizationModal';
import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { UpdateOrganizationModalMutation } from './__generated__/UpdateOrganizationModalMutation.graphql';
import { UpdateOrganizationModal_organization$key } from './__generated__/UpdateOrganizationModal_organization.graphql';

const fragment = graphql`
  fragment UpdateOrganizationModal_organization on Organization {
    id
    organizationId
    name
    slug
    subscription {
      subscriptionId
    }
    specialType
  }
`;

const updateMutation = graphql`
  mutation UpdateOrganizationModalMutation($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        ...OrganizationsGrid_organization
      }
    }
  }
`;

const updateOrganizationSchema = basicOrganizationSchema.shape({
  organizationId: string().meta({
    readOnly: true,
  }),
});

interface UpdateOrganizationModalProps extends ActionModalProps {
  organizationRef: UpdateOrganizationModal_organization$key;
}

export function UpdateOrganizationModal({
  organizationRef,
  ...props
}: UpdateOrganizationModalProps) {
  const organization = useFragment(fragment, organizationRef);

  return (
    <OrganizationModal<UpdateOrganizationModalMutation>
      {...props}
      title={
        <FormattedMessage
          id="updateOrganizationModal.title"
          defaultMessage="Update Organization"
        />
      }
      name={organization.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      mutation={updateMutation}
      defaultValue={organization}
      schema={updateOrganizationSchema}
      alertMessage={
        <Alert
          variant="warning"
          className="mb-5"
          data-cy="UpdateWarningOnOrganizationModal"
        >
          <FormattedMessage
            id="updateOrganizationModal.warning"
            defaultMessage="To update subscription-related fields, including Billing Status and Subscription End Date, directly update this organization's "
          />
          <Link
            as={Anchor}
            to={`/subscriptions/${organization?.subscription?.subscriptionId}`}
          >
            <FormattedMessage
              id="updateOrganizationModal.warningLink"
              defaultMessage="Subscription"
            />
          </Link>
        </Alert>
      }
    />
  );
}
