import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import PermissionsContext from './PermissionsContext';
import {
  PermissionsProvider_viewer$key,
  ViewerAdminRole,
} from './__generated__/PermissionsProvider_viewer.graphql';
import {
  Access,
  Permissions,
  Resource,
  accessRegistry,
  resourceRegistry,
} from './permissions';

interface PermissionsProviderViewer {
  viewer: PermissionsProvider_viewer$key | null;
}

const fragment = graphql`
  fragment PermissionsProvider_viewer on Viewer {
    email
    adminRoles
  }
`;

export default function PermissionsProvider({
  children,
  viewer,
}: PropsWithChildren<PermissionsProviderViewer>) {
  const viewerInfo = useFragment(fragment, viewer);

  const adminRoles = useMemo(
    () => (viewerInfo?.adminRoles?.filter(Boolean) as ViewerAdminRole[]) || [],
    [viewerInfo],
  );

  const getPermissions = useCallback(
    (resource: Resource): Permissions => {
      const roleRegistry = resourceRegistry[resource];
      const access = Math.max(
        ...adminRoles.map((role) => roleRegistry[role] || Access.NO_ACCESS),
      );
      return accessRegistry[access] || accessRegistry[Access.NO_ACCESS];
    },
    [adminRoles],
  );

  const canRead = useCallback(
    (resource: Resource): boolean => getPermissions(resource).read,
    [getPermissions],
  );

  const canWrite = useCallback(
    (resource: Resource): boolean => getPermissions(resource).write,
    [getPermissions],
  );

  const canWriteSome = useCallback(
    (...resources: Resource[]): boolean =>
      resources.some((resource) => getPermissions(resource).write),
    [getPermissions],
  );

  const canReadSome = useCallback(
    (...resources: Resource[]): boolean =>
      resources.some((resource) => getPermissions(resource).read),
    [getPermissions],
  );

  const value = useMemo(
    () => ({
      canRead,
      canWrite,
      getPermissions,
      canWriteSome,
      canReadSome,
      viewer: { email: viewerInfo?.email ?? null, adminRoles },
    }),
    [
      viewerInfo,
      adminRoles,
      canRead,
      canWrite,
      getPermissions,
      canWriteSome,
      canReadSome,
    ],
  );

  return (
    <PermissionsContext.Provider value={value}>
      {children}
    </PermissionsContext.Provider>
  );
}
