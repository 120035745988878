/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type placesOfWork_PlacesOfWorkGrid_QueryVariables = {
    name?: Array<string> | null | undefined;
};
export type placesOfWork_PlacesOfWorkGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"PlacesOfWorkGrid_viewer">;
    } | null;
};
export type placesOfWork_PlacesOfWorkGrid_Query = {
    readonly response: placesOfWork_PlacesOfWorkGrid_QueryResponse;
    readonly variables: placesOfWork_PlacesOfWorkGrid_QueryVariables;
};



/*
query placesOfWork_PlacesOfWorkGrid_Query(
  $name: [String!]
) {
  viewer {
    ...PlacesOfWorkGrid_viewer_2aiVTE
    id
  }
}

fragment PlacesOfWorkGrid_viewer_2aiVTE on Viewer {
  placesOfWork(first: 20, name: $name) {
    edges {
      node {
        id
        placeOfWorkId
        name
        countries
        status
        source
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "placesOfWork_PlacesOfWorkGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PlacesOfWorkGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "placesOfWork_PlacesOfWorkGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "PlaceOfWorkConnection",
            "kind": "LinkedField",
            "name": "placesOfWork",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceOfWorkEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlaceOfWork",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "placeOfWorkId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "countries",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "source",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": [
              "name"
            ],
            "handle": "connection",
            "key": "PlacesOfWorkGrid_placesOfWork",
            "kind": "LinkedHandle",
            "name": "placesOfWork"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eeb338c47db20e24aed4e78b1c8fa18e",
    "id": null,
    "metadata": {},
    "name": "placesOfWork_PlacesOfWorkGrid_Query",
    "operationKind": "query",
    "text": "query placesOfWork_PlacesOfWorkGrid_Query(\n  $name: [String!]\n) {\n  viewer {\n    ...PlacesOfWorkGrid_viewer_2aiVTE\n    id\n  }\n}\n\nfragment PlacesOfWorkGrid_viewer_2aiVTE on Viewer {\n  placesOfWork(first: 20, name: $name) {\n    edges {\n      node {\n        id\n        placeOfWorkId\n        name\n        countries\n        status\n        source\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '652c7fd3d5bcaa9f7a961a68687e3095';
export default node;
