/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry = {
    readonly id: string;
    readonly iosVersionBlacklistEntryId: string | null;
    readonly applicationIdentifier: string | null;
    readonly versionRange: string | null;
    readonly buildNumber: string | null;
    readonly " $refType": "IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry";
};
export type IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry$data = IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry;
export type IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry$key = {
    readonly " $data"?: IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IosVersionBlacklistEntriesPage_iosVersionBlacklistEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosVersionBlacklistEntryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buildNumber",
      "storageKey": null
    }
  ],
  "type": "IosVersionBlacklistEntry",
  "abstractKey": null
};
(node as any).hash = '590fb6191cf3fe6031dc44aef4c6a7ff';
export default node;
