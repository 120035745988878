import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteButterflyDeviceAction from './DeleteButterflyDeviceAction';
import DeregisterAction from './DeregisterAction';
import RequestDiagnosticTestAction from './RequestDiagnosticTestAction';
import SetProbeStatusAction from './SetProbeStatusAction';
import { ButterflyDeviceDetailPage_butterflyDevice$key } from './__generated__/ButterflyDeviceDetailPage_butterflyDevice.graphql';

const fragment = graphql`
  fragment ButterflyDeviceDetailPage_butterflyDevice on ButterflyDevice {
    ...DeregisterAction_butterflyDevice
    ...RequestDiagnosticTestAction_butterflyDevice
    ...DeleteButterflyDeviceAction_butterflyDevice
    ...SetProbeStatusAction_butterflyDevice
    id
    productionId
    registeredAt
    isEnabled
    status
    disabledStatusMessage
    lostAt
    stolenAt
    brickedAt
    brickedReason
    activeReason
    lostNote
    stolenNote
    recallId
    subscription {
      subscriptionId
    }
    hasActiveDiagnosticTestRequest
    lastDiagnosticTestRequestMinTestVersion
  }
`;

interface ButterflyDeviceDetailPageProps {
  butterflyDevice: ButterflyDeviceDetailPage_butterflyDevice$key;
}

export default function ButterflyDeviceDetailPage({
  butterflyDevice: butterflyDeviceRef,
}: ButterflyDeviceDetailPageProps) {
  const butterflyDevice = useFragment(fragment, butterflyDeviceRef);
  const { subscription, recallId, productionId } = butterflyDevice;
  useBreadcrumbDetector(pageTitles.butterflyDevice, productionId);
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.BUTTERFLY_DEVICES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.butterflyDevice} />}
        name={butterflyDevice.productionId!}
        data={butterflyDevice}
        quickCopyFields={[
          'productionId',
          'recallId',
          'subscription: subscriptionId',
        ]}
        resourceActions={
          canWrite(Resource.BUTTERFLY_DEVICES) && (
            <>
              <DeregisterAction butterflyDeviceRef={butterflyDevice} />
              <SetProbeStatusAction butterflyDeviceRef={butterflyDevice} />
              <RequestDiagnosticTestAction
                butterflyDeviceRef={butterflyDevice}
              />
            </>
          )
        }
        crudActions={
          canWrite(Resource.BUTTERFLY_DEVICES) && (
            <DeleteButterflyDeviceAction
              butterflyDeviceRef={butterflyDevice}
            />
          )
        }
        relatedItems={
          <>
            {subscription && (
              <RelatedItem
                name="Subscription"
                to={`/subscriptions/${subscription.subscriptionId}`}
              >
                <FormattedMessage
                  id="butterflyDeviceDetail.relatedItem.subscription"
                  defaultMessage="Subscription"
                />
              </RelatedItem>
            )}
            {recallId && (
              <RelatedItem
                name="Butterfly Device Recall"
                to={`/butterfly-device-recalls/${recallId}`}
              >
                <FormattedMessage
                  id="butterflyDeviceDetail.relatedItem.deviceRecall"
                  defaultMessage="Butterfly Device Recall"
                />
              </RelatedItem>
            )}
            <RelatedItem
              name="Diagnostic Test Results"
              to={{
                pathname: '/diagnostic-test-results',
                query: { deviceProductionId: productionId },
              }}
            >
              <FormattedMessage
                id="butterflyDeviceDetail.relatedItem.diagnosticTestResults"
                defaultMessage="Diagnostic Test Results"
              />
            </RelatedItem>
          </>
        }
      />
    </PermissionsGuard>
  );
}
