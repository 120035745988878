/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ApplicationVersionRequirementDetailPage_applicationVersionRequirement = {
    readonly id: string;
    readonly applicationIdentifier: string | null;
    readonly minimumVersion: string | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateApplicationVersionRequirementModal_applicationVersionRequirement" | "DeleteApplicationVersionRequirementAction_applicationVersionRequirement">;
    readonly " $refType": "ApplicationVersionRequirementDetailPage_applicationVersionRequirement";
};
export type ApplicationVersionRequirementDetailPage_applicationVersionRequirement$data = ApplicationVersionRequirementDetailPage_applicationVersionRequirement;
export type ApplicationVersionRequirementDetailPage_applicationVersionRequirement$key = {
    readonly " $data"?: ApplicationVersionRequirementDetailPage_applicationVersionRequirement$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ApplicationVersionRequirementDetailPage_applicationVersionRequirement">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationVersionRequirementDetailPage_applicationVersionRequirement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "applicationIdentifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumVersion",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateApplicationVersionRequirementModal_applicationVersionRequirement"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteApplicationVersionRequirementAction_applicationVersionRequirement"
    }
  ],
  "type": "ApplicationVersionRequirement",
  "abstractKey": null
};
(node as any).hash = '67f851f7ae7eea1adc9d64c77ca57a5e';
export default node;
