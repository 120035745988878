import { defineMessages } from 'react-intl';

export default defineMessages({
  customerId: {
    id: 'customersTable.heading.customerId',
    defaultMessage: 'Customer ID',
  },
  name: {
    id: 'customersTable.heading.name',
    defaultMessage: 'Name',
  },
  zendeskId: {
    id: 'customersTable.heading.zendeskId',
    defaultMessage: 'Zendesk ID',
  },
  netsuiteId: {
    id: 'customersTable.heading.netsuiteId',
    defaultMessage: 'Netsuite ID',
  },
  stripeId: {
    id: 'customersTable.heading.stripeId',
    defaultMessage: 'Stripe ID',
  },
  sfAccountId: {
    id: 'customersTable.heading.sfAccountId',
    defaultMessage: 'SF Account ID',
  },
});
