/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProcessingId = "D" | "P" | "T" | "%future added value";
export type StudyUrlDisplay = "NONE" | "NORMAL" | "%future added value";
export type UpdateEhrInput = {
    ehrId: string;
    name?: string | null | undefined;
    organizationId?: string | null | undefined;
    domainId?: string | null | undefined;
    connectionId?: string | null | undefined;
    processingId?: ProcessingId | null | undefined;
    procedureIdPath?: string | null | undefined;
    receivingApplication?: string | null | undefined;
    receivingFacility?: string | null | undefined;
    sendingFacility?: string | null | undefined;
    studyDescriptionMapping?: Record<string, any> | null | undefined;
    studyUrlDisplay?: StudyUrlDisplay | null | undefined;
    timeoutAck?: number | null | undefined;
    timeoutConnection?: number | null | undefined;
    timezone?: string | null | undefined;
    characterEncoding?: string | null | undefined;
    hospitalHost?: string | null | undefined;
    hospitalPort?: number | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateEhrModalMutationVariables = {
    input: UpdateEhrInput;
};
export type UpdateEhrModalMutationResponse = {
    readonly updateEhr: {
        readonly ehr: {
            readonly " $fragmentRefs": FragmentRefs<"EhrDetailPage_ehr">;
        } | null;
    } | null;
};
export type UpdateEhrModalMutation = {
    readonly response: UpdateEhrModalMutationResponse;
    readonly variables: UpdateEhrModalMutationVariables;
};



/*
mutation UpdateEhrModalMutation(
  $input: UpdateEhrInput!
) {
  updateEhr(input: $input) {
    ehr {
      ...EhrDetailPage_ehr
      id
    }
  }
}

fragment DeleteEhrAction_ehr on Ehr {
  ehrId
  name
  deletedAt
}

fragment EhrDetailPage_ehr on Ehr {
  ...UpdateEhrModal_ehr
  ...DeleteEhrAction_ehr
  id
  ehrId
  name
  connectionId
  createdAt
  updatedAt
  deletedAt
  hospitalHost
  hospitalPort
  timeoutAck
  timeoutConnection
  timezone
  procedureIdPath
  processingId
  receivingApplication
  receivingFacility
  sendingFacility
  characterEncoding
  studyDescriptionMapping
  studyUrlDisplay
  organization {
    organizationId
    id
  }
  domain {
    domainId
    id
  }
}

fragment UpdateEhrModal_ehr on Ehr {
  id
  ehrId
  name
  connectionId
  createdAt
  updatedAt
  deletedAt
  hospitalHost
  hospitalPort
  timeoutAck
  timeoutConnection
  timezone
  processingId
  procedureIdPath
  receivingApplication
  receivingFacility
  sendingFacility
  characterEncoding
  studyDescriptionMapping
  studyUrlDisplay
  organization {
    organizationId
    id
  }
  domain {
    domainId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEhrModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEhrPayload",
        "kind": "LinkedField",
        "name": "updateEhr",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ehr",
            "kind": "LinkedField",
            "name": "ehr",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EhrDetailPage_ehr"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateEhrModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateEhrPayload",
        "kind": "LinkedField",
        "name": "updateEhr",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ehr",
            "kind": "LinkedField",
            "name": "ehr",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ehrId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "connectionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hospitalHost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hospitalPort",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeoutAck",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeoutConnection",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "processingId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "procedureIdPath",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "receivingApplication",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "receivingFacility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sendingFacility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "characterEncoding",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyDescriptionMapping",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyUrlDisplay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80fcc86d96f25a562e2683ff15a96a9e",
    "id": null,
    "metadata": {},
    "name": "UpdateEhrModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEhrModalMutation(\n  $input: UpdateEhrInput!\n) {\n  updateEhr(input: $input) {\n    ehr {\n      ...EhrDetailPage_ehr\n      id\n    }\n  }\n}\n\nfragment DeleteEhrAction_ehr on Ehr {\n  ehrId\n  name\n  deletedAt\n}\n\nfragment EhrDetailPage_ehr on Ehr {\n  ...UpdateEhrModal_ehr\n  ...DeleteEhrAction_ehr\n  id\n  ehrId\n  name\n  connectionId\n  createdAt\n  updatedAt\n  deletedAt\n  hospitalHost\n  hospitalPort\n  timeoutAck\n  timeoutConnection\n  timezone\n  procedureIdPath\n  processingId\n  receivingApplication\n  receivingFacility\n  sendingFacility\n  characterEncoding\n  studyDescriptionMapping\n  studyUrlDisplay\n  organization {\n    organizationId\n    id\n  }\n  domain {\n    domainId\n    id\n  }\n}\n\nfragment UpdateEhrModal_ehr on Ehr {\n  id\n  ehrId\n  name\n  connectionId\n  createdAt\n  updatedAt\n  deletedAt\n  hospitalHost\n  hospitalPort\n  timeoutAck\n  timeoutConnection\n  timezone\n  processingId\n  procedureIdPath\n  receivingApplication\n  receivingFacility\n  sendingFacility\n  characterEncoding\n  studyDescriptionMapping\n  studyUrlDisplay\n  organization {\n    organizationId\n    id\n  }\n  domain {\n    domainId\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '0b8aba0807f5979485da0f1284d4eff3';
export default node;
