import { defineMessages } from 'react-intl';

export default defineMessages({
  hospitalHost: {
    id: 'ehrTable.heading.hospitalHost',
    defaultMessage: 'Hospital Host',
  },
  hospitalPort: {
    id: 'ehrTable.heading.hospitalPort',
    defaultMessage: 'Hospital Port',
  },
  timezone: {
    id: 'ehrTable.heading.timezone',
    defaultMessage: 'Timezone',
  },
  receivingFacility: {
    id: 'ehrTable.heading.receivingFacility',
    defaultMessage: 'Receiving Facility',
  },
  sendingFacility: {
    id: 'ehrTable.heading.sendingFacility',
    defaultMessage: 'Sending Facility',
  },
  timeoutAck: {
    id: 'ehrTable.heading.timeoutAck',
    defaultMessage: 'Timeout ACK',
  },
  timeoutConnection: {
    id: 'ehrTable.heading.timeoutConnection',
    defaultMessage: 'Timeout Connection',
  },
  processingId: {
    id: 'ehrTable.heading.processingId',
    defaultMessage: 'Processing ID',
  },
  receivingApplication: {
    id: 'ehrTable.heading.receivingApplication',
    defaultMessage: 'Receiving Application',
  },
  characterEncoding: {
    id: 'ehrTable.heading.characterEncoding',
    defaultMessage: 'Character Encoding',
  },
  studyUrlDisplay: {
    id: 'ehrTable.heading.studyUrlDisplay',
    defaultMessage: 'Study URL Display',
  },
});
