/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteEducationCategoryInput = {
    educationCategoryId: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteEducationCategoryActionMutationVariables = {
    input: DeleteEducationCategoryInput;
};
export type DeleteEducationCategoryActionMutationResponse = {
    readonly deleteEducationCategory: {
        readonly deletedId: string;
    } | null;
};
export type DeleteEducationCategoryActionMutation = {
    readonly response: DeleteEducationCategoryActionMutationResponse;
    readonly variables: DeleteEducationCategoryActionMutationVariables;
};



/*
mutation DeleteEducationCategoryActionMutation(
  $input: DeleteEducationCategoryInput!
) {
  deleteEducationCategory(input: $input) {
    deletedId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteEducationCategoryPayload",
    "kind": "LinkedField",
    "name": "deleteEducationCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteEducationCategoryActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteEducationCategoryActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "61ba33696de485f146b445e3bbca3844",
    "id": null,
    "metadata": {},
    "name": "DeleteEducationCategoryActionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteEducationCategoryActionMutation(\n  $input: DeleteEducationCategoryInput!\n) {\n  deleteEducationCategory(input: $input) {\n    deletedId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f5eca63b6d8f580bc4d5f51302704a0f';
export default node;
