import Form from '@bfly/ui2/Form';
import Multiselect from '@bfly/ui2/Multiselect';
import useQuery from '@bfly/ui2/useQuery';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters, graphql } from 'relay-runtime';

import Alert from 'components/Alert';
import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';

import {
  countryMessages,
  domainIdMessages,
  enableButterflyLoginMessages,
  enableSharedDeviceCodeLoginMessages,
  maxNumSeatsMessages,
  nameMessages,
  requiredAndroidCapabilitiesMessages,
  samlEmailAttributeNameRawMessages,
  samlNicknameAttributeNameRawMessages,
} from '../messages/formMessages';

export const ANDROID_CAPABILITIES = [
  'ENTERPRISE_CAPABILITY_MANAGEMENT',
  'MDM_RESTRICTIONS',
  'CERTIFICATE_PINNING',
  'SCREENSHOTS_DISABLED',
  'ROOT_DETECTION',
  'EAGER_UPLOADS_DISABLED',
  'APP_SWITCHING_BLUR_OUT',
  'CUT_COPY_ON_SENSITIVE_FIELDS_DISABLED',
];

const countryConfigurations = graphql`
  query DomainModalCountryConfig_Query($dataRegion: [String!]) {
    viewer {
      countryConfigurations(dataRegion: $dataRegion) {
        edges {
          node {
            dataRegion
            countryId
          }
        }
      }
    }
  }
`;

export function DomainModal<TMutation extends MutationParameters>(
  props: ResourceModalProps<TMutation>,
) {
  const { formatMessage } = useIntl();

  const { data } = useQuery<any>(countryConfigurations, {
    variables: {},
  });
  const countryConfigurationsData: any[] = [];
  data?.viewer?.countryConfigurations.edges.forEach((x) => {
    countryConfigurationsData.push(x.node.countryId);
  });

  return (
    <ResourceModal<TMutation> {...props}>
      <Form.FieldGroup
        disabled
        name="domainId"
        label={<FormattedMessage {...domainIdMessages.label} />}
        placeholder={formatMessage(domainIdMessages.placeholder)}
        data-cy="domainModal-domainIdField"
      />
      <Form.FieldGroup
        name="name"
        label={<FormattedMessage {...nameMessages.label} />}
        placeholder={formatMessage(nameMessages.placeholder)}
        description={<FormattedMessage {...nameMessages.description} />}
        data-cy="domainModal-nameField"
      />
      <Form.FieldGroup
        name="samlEmailAttributeNameRaw"
        label={
          <FormattedMessage {...samlEmailAttributeNameRawMessages.label} />
        }
        placeholder={formatMessage(
          samlEmailAttributeNameRawMessages.placeholder,
        )}
        description={
          <FormattedMessage
            {...samlEmailAttributeNameRawMessages.description}
          />
        }
        data-cy="domainModal-samlEmailAttributeNameRawField"
      />
      <Form.FieldGroup
        name="samlNicknameAttributeNameRaw"
        label={
          <FormattedMessage {...samlNicknameAttributeNameRawMessages.label} />
        }
        placeholder={formatMessage(
          samlNicknameAttributeNameRawMessages.placeholder,
        )}
        description={
          <FormattedMessage
            {...samlNicknameAttributeNameRawMessages.description}
          />
        }
        data-cy="domainModal-samlNicknameAttributeNameRawField"
      />
      <Form.FieldGroup
        name="requiredAndroidCapabilities"
        as={Multiselect}
        data={ANDROID_CAPABILITIES}
        label={
          <FormattedMessage {...requiredAndroidCapabilitiesMessages.label} />
        }
        description={
          <FormattedMessage
            {...requiredAndroidCapabilitiesMessages.description}
          />
        }
        data-cy="domainModal-requiredAndroidCapabilitiesField"
      />
      <Form.FieldGroup
        name="enableSharedDeviceCodeLogin"
        label={
          <FormattedMessage {...enableSharedDeviceCodeLoginMessages.label} />
        }
        description={
          <FormattedMessage
            {...enableSharedDeviceCodeLoginMessages.description}
          />
        }
        data-cy="domainModal-enableSharedDeviceCodeLoginField"
      />
      <Form.FieldGroup
        disabled={!props.defaultValue!.enableSamlLogin}
        name="enableButterflyLogin"
        label={<FormattedMessage {...enableButterflyLoginMessages.label} />}
        description={
          <FormattedMessage {...enableButterflyLoginMessages.description} />
        }
        data-cy="domainModal-enableButterflyLogin"
      />
      <Form.FieldGroup
        name="maxNumSeats"
        label={<FormattedMessage {...maxNumSeatsMessages.label} />}
        description={<FormattedMessage {...maxNumSeatsMessages.description} />}
        placeholder="NULL"
        data-cy="domainModal-maxNumSeats"
      />
      <Form.FieldGroup
        name="country"
        as={DropdownList}
        data={countryConfigurationsData}
        label={<FormattedMessage {...countryMessages.label} />}
        description={
          <div>
            <Alert variant="danger">
              <FormattedMessage {...countryMessages.update} />
            </Alert>
            <Alert variant="danger" className="mb-5 mt-1">
              <FormattedMessage {...countryMessages.danger} />
            </Alert>
          </div>
        }
        data-cy="domainModal-country"
      />
    </ResourceModal>
  );
}
