/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type domainInvites_DomainInvitesGrid_QueryVariables = {
    domainId?: Array<string> | null | undefined;
    email?: Array<string> | null | undefined;
};
export type domainInvites_DomainInvitesGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"DomainInvitesGrid_viewer">;
    } | null;
};
export type domainInvites_DomainInvitesGrid_Query = {
    readonly response: domainInvites_DomainInvitesGrid_QueryResponse;
    readonly variables: domainInvites_DomainInvitesGrid_QueryVariables;
};



/*
query domainInvites_DomainInvitesGrid_Query(
  $domainId: [String!]
  $email: [String!]
) {
  viewer {
    ...DomainInvitesGrid_viewer_3CPfLp
    id
  }
}

fragment DomainInvitesGrid_viewer_3CPfLp on Viewer {
  userInvites(first: 20, domainId: $domainId, email: $email) {
    edges {
      node {
        id
        userInviteId
        createdAt
        usedAt
        domain {
          domainId
          name
          id
        }
        user {
          email
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = {
  "kind": "Variable",
  "name": "domainId",
  "variableName": "domainId"
},
v2 = {
  "kind": "Variable",
  "name": "email",
  "variableName": "email"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainInvites_DomainInvitesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "DomainInvitesGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainInvites_DomainInvitesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "UserInviteConnection",
            "kind": "LinkedField",
            "name": "userInvites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserInviteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInvite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userInviteId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "usedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "domain",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domainId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "DomainInvitesGrid_userInvites",
            "kind": "LinkedHandle",
            "name": "userInvites"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2545e686e913ed47cfb1934b1edc4c42",
    "id": null,
    "metadata": {},
    "name": "domainInvites_DomainInvitesGrid_Query",
    "operationKind": "query",
    "text": "query domainInvites_DomainInvitesGrid_Query(\n  $domainId: [String!]\n  $email: [String!]\n) {\n  viewer {\n    ...DomainInvitesGrid_viewer_3CPfLp\n    id\n  }\n}\n\nfragment DomainInvitesGrid_viewer_3CPfLp on Viewer {\n  userInvites(first: 20, domainId: $domainId, email: $email) {\n    edges {\n      node {\n        id\n        userInviteId\n        createdAt\n        usedAt\n        domain {\n          domainId\n          name\n          id\n        }\n        user {\n          email\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4242ae8f581e834e10ddb111fd2578fc';
export default node;
