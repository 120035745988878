/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateStudyExportInput = {
    domainId: string;
    startDate: string;
    endDate: string;
    clientMutationId?: string | null | undefined;
};
export type CreateStudyExportActionMutationVariables = {
    input: CreateStudyExportInput;
};
export type CreateStudyExportActionMutationResponse = {
    readonly createStudyExport: {
        readonly downloadLink: string | null;
        readonly password: string | null;
    } | null;
};
export type CreateStudyExportActionMutation = {
    readonly response: CreateStudyExportActionMutationResponse;
    readonly variables: CreateStudyExportActionMutationVariables;
};



/*
mutation CreateStudyExportActionMutation(
  $input: CreateStudyExportInput!
) {
  createStudyExport(input: $input) {
    downloadLink
    password
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateStudyExportPayload",
    "kind": "LinkedField",
    "name": "createStudyExport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "password",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateStudyExportActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateStudyExportActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c71ee4f84d8a105c1ab41020d26b9c9",
    "id": null,
    "metadata": {},
    "name": "CreateStudyExportActionMutation",
    "operationKind": "mutation",
    "text": "mutation CreateStudyExportActionMutation(\n  $input: CreateStudyExportInput!\n) {\n  createStudyExport(input: $input) {\n    downloadLink\n    password\n  }\n}\n"
  }
};
})();
(node as any).hash = '283615c6cc5e29bd1ba6c0f7644793bb';
export default node;
