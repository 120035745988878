import { defineMessages } from 'react-intl';

export default defineMessages({
  domain: {
    id: 'domainInviteDetail.relatedItem.domain',
    defaultMessage: 'Domain',
  },
  user: {
    id: 'domainInviteDetail.relatedItem.User',
    defaultMessage: 'User',
  },
  createInvite: {
    id: 'DomainInvitesPage.createInvite',
    defaultMessage: 'Create Invite',
  },
});
