import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteAndroidVersionBlacklistEntryAction from './DeleteAndroidVersionBlacklistEntryAction';
import { UpdateAndroidVersionBlacklistEntryModal } from './UpdateAndroidVersionBlacklistEntryModal';
import { AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry$key } from './__generated__/AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry.graphql';

const fragment = graphql`
  fragment AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
    ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry
    ...DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry
    id
    androidVersionBlacklistEntryId
    applicationIdentifier
    versionRange
    versionCode
  }
`;

interface AndroidVersionBlacklistEntryDetailPageProps {
  androidVersionBlacklistEntry: AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry$key;
}

export default function AndroidVersionBlacklistEntryDetailPage({
  androidVersionBlacklistEntry: androidVersionBlacklistEntryRef,
}: AndroidVersionBlacklistEntryDetailPageProps) {
  const androidVersionBlacklistEntry = useFragment(
    fragment,
    androidVersionBlacklistEntryRef,
  );

  useBreadcrumbDetector(
    pageTitles.androidVersionBlacklistEntry,
    androidVersionBlacklistEntry.androidVersionBlacklistEntryId,
  );
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.ANDROID_VERSION_BLACKLIST_ENTRIES}>
      <ResourceDetailPage
        title={
          <FormattedMessage {...pageTitles.androidVersionBlacklistEntry} />
        }
        name={androidVersionBlacklistEntry.androidVersionBlacklistEntryId!}
        data={androidVersionBlacklistEntry}
        quickCopyFields={[
          'androidVersionBlacklistEntryId',
          'applicationIdentifier',
          'versionCode',
        ]}
        crudActions={
          canWrite(Resource.ANDROID_VERSION_BLACKLIST_ENTRIES) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateAndroidVersionBlacklistEntryModal
                    {...props}
                    androidVersionBlacklistEntryRef={
                      androidVersionBlacklistEntry
                    }
                  />
                )}
              />
              <DeleteAndroidVersionBlacklistEntryAction
                androidVersionBlacklistEntryRef={androidVersionBlacklistEntry}
              />
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
