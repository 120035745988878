import omit from 'lodash/omit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import { UpdateCountryModal } from './UpdateCountryModal';
import { CountryDetailPage_country$key } from './__generated__/CountryDetailPage_country.graphql';

const fragment = graphql`
  fragment CountryDetailPage_country on Country {
    ...UpdateCountryModal_country
    id
    code
    name
    enabled
    countryConfiguration {
      dataRegion
      configuration
    }
  }
`;

interface CountryDetailPageProps {
  country: CountryDetailPage_country$key;
}

export default function CountryDetailPage({
  country: countryRef,
}: CountryDetailPageProps) {
  const country = useFragment(fragment, countryRef);
  const { name } = country;
  useBreadcrumbDetector(pageTitles.country, name);
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.COUNTRIES}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.country} />}
        name={country.name!}
        data={omit(country, ['enabled'])}
        quickCopyFields={['name', 'code']}
        crudActions={
          canWrite(Resource.COUNTRIES) && (
            <UpdateAction
              renderModal={(props) => (
                <UpdateCountryModal {...props} countryRef={country} />
              )}
            />
          )
        }
      />
    </PermissionsGuard>
  );
}
