/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type subscriptions_SubscriptionsGrid_QueryVariables = {
    id?: Array<string> | null | undefined;
    customerId?: Array<string> | null | undefined;
    domainId?: Array<string> | null | undefined;
    stripeSubscriptionId?: Array<string> | null | undefined;
    sfSubscriptionId?: Array<string> | null | undefined;
};
export type subscriptions_SubscriptionsGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"SubscriptionsGrid_viewer">;
    } | null;
};
export type subscriptions_SubscriptionsGrid_Query = {
    readonly response: subscriptions_SubscriptionsGrid_QueryResponse;
    readonly variables: subscriptions_SubscriptionsGrid_QueryVariables;
};



/*
query subscriptions_SubscriptionsGrid_Query(
  $id: [String!]
  $customerId: [String!]
  $domainId: [String!]
  $stripeSubscriptionId: [String!]
  $sfSubscriptionId: [String!]
) {
  viewer {
    ...SubscriptionsGrid_viewer_1iSr2e
    id
  }
}

fragment SubscriptionsGrid_viewer_1iSr2e on Viewer {
  subscriptions(first: 20, id: $id, customerId: $customerId, domainId: $domainId, stripeSubscriptionId: $stripeSubscriptionId, sfSubscriptionId: $sfSubscriptionId) {
    edges {
      node {
        id
        subscriptionId
        subscriptionEndsAt
        accessEndsAt
        hasStartedActiveSubscription
        hasCompletedTrial
        canAccessProFeatures
        isInGracePeriod
        isTeam
        billingStatus
        billingProvider
        stripeSubscriptionId
        sfSubscriptionId
        planType
        maxNumSeats
        practiceType
        domain {
          domainId
          id
        }
        customer {
          customerId
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domainId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sfSubscriptionId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stripeSubscriptionId"
},
v5 = {
  "kind": "Variable",
  "name": "customerId",
  "variableName": "customerId"
},
v6 = {
  "kind": "Variable",
  "name": "domainId",
  "variableName": "domainId"
},
v7 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v8 = {
  "kind": "Variable",
  "name": "sfSubscriptionId",
  "variableName": "sfSubscriptionId"
},
v9 = {
  "kind": "Variable",
  "name": "stripeSubscriptionId",
  "variableName": "stripeSubscriptionId"
},
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "subscriptions_SubscriptionsGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "SubscriptionsGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "subscriptions_SubscriptionsGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "SubscriptionConnection",
            "kind": "LinkedField",
            "name": "subscriptions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubscriptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subscription",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subscriptionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subscriptionEndsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "accessEndsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasStartedActiveSubscription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasCompletedTrial",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canAccessProFeatures",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isInGracePeriod",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isTeam",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billingStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billingProvider",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stripeSubscriptionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sfSubscriptionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "planType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxNumSeats",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "practiceType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "domain",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domainId",
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customerId",
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "filters": [
              "id",
              "customerId",
              "domainId",
              "stripeSubscriptionId",
              "sfSubscriptionId"
            ],
            "handle": "connection",
            "key": "SubscriptionsGrid_subscriptions",
            "kind": "LinkedHandle",
            "name": "subscriptions"
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b06b3f02a48f343711e101f6985a0f5d",
    "id": null,
    "metadata": {},
    "name": "subscriptions_SubscriptionsGrid_Query",
    "operationKind": "query",
    "text": "query subscriptions_SubscriptionsGrid_Query(\n  $id: [String!]\n  $customerId: [String!]\n  $domainId: [String!]\n  $stripeSubscriptionId: [String!]\n  $sfSubscriptionId: [String!]\n) {\n  viewer {\n    ...SubscriptionsGrid_viewer_1iSr2e\n    id\n  }\n}\n\nfragment SubscriptionsGrid_viewer_1iSr2e on Viewer {\n  subscriptions(first: 20, id: $id, customerId: $customerId, domainId: $domainId, stripeSubscriptionId: $stripeSubscriptionId, sfSubscriptionId: $sfSubscriptionId) {\n    edges {\n      node {\n        id\n        subscriptionId\n        subscriptionEndsAt\n        accessEndsAt\n        hasStartedActiveSubscription\n        hasCompletedTrial\n        canAccessProFeatures\n        isInGracePeriod\n        isTeam\n        billingStatus\n        billingProvider\n        stripeSubscriptionId\n        sfSubscriptionId\n        planType\n        maxNumSeats\n        practiceType\n        domain {\n          domainId\n          id\n        }\n        customer {\n          customerId\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ac962a283ba638e2f611febb3c6503f6';
export default node;
