import Form from '@bfly/ui2/Form';
import Multiselect from '@bfly/ui2/Multiselect';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { array, boolean, number, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import MultilineListTextarea from 'components/MultilineListTextarea';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { formMessages, practiceTypeMessages } from 'messages/formMessages';

import {
  authoredByMessages,
  captureModesMessages,
  categoryIdMessages,
  descriptionMessages,
  durationMessages,
  educationVideoIdMessages,
  isPremiumMessages,
  presetIdsMessages,
  rankingMessages,
  toolIdsMessages,
  typeMessages,
  videoNameMessages,
  vimeoIdMessages,
} from '../messages/formMessages';

const EDUCATION_VIDEO_TYPES = ['ACQUISITION', 'INTERPRETATION'];

const PRACTICE_TYPE_HUMAN = 'HUMAN';
const PRACTICE_TYPE = ['NONE', PRACTICE_TYPE_HUMAN, 'VETERINARY'];

const CAPTURE_MODES = [
  'b-mode',
  'm-mode',
  'color doppler',
  'power doppler',
  'pulsed wave doppler',
  'biplane',
  'fetal heart sounds',
];

export const educationVideoSchema = object({
  educationContentId: string(),
  name: string()
    .required(videoNameMessages.required)
    .min(2, formMessages.minLength)
    .trim(),
  description: string()
    .required(descriptionMessages.required)
    .min(2, formMessages.minLength)
    .trim(),
  vimeoId: string().required(vimeoIdMessages.required),
  authoredBy: string().required(authoredByMessages.required),
  categoryId: string().required(categoryIdMessages.required),
  isPremium: boolean().default(true),
  type: string().oneOf(EDUCATION_VIDEO_TYPES),
  practiceType: string()
    .required(practiceTypeMessages.required)
    .oneOf(PRACTICE_TYPE)
    .default(PRACTICE_TYPE_HUMAN),
  presetIds: array().of(string()),
  toolIds: array().of(string()),
  captureModes: array().of(string().oneOf(CAPTURE_MODES)),
  duration: number()
    .integer()
    .nullable()
    .required(durationMessages.required)
    .min(1),
  ranking: number().nullable().required(rankingMessages.required).min(1),
});

export function EducationVideoModal<TMutation extends MutationParameters>(
  props: ResourceModalProps<TMutation>,
) {
  const isCreate = !props.defaultValue?.educationContentId;
  const { formatMessage } = useIntl();
  return (
    <ResourceModal<TMutation> {...props}>
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="educationContentId"
          label={<FormattedMessage {...educationVideoIdMessages.label} />}
          placeholder={formatMessage(educationVideoIdMessages.placeholder)}
          data-cy="educationContentModal-educationContentId"
        />
      )}
      <Form.FieldGroup
        name="name"
        label={<FormattedMessage {...videoNameMessages.label} />}
        placeholder={formatMessage(videoNameMessages.placeholder)}
        data-cy="educationContentModal-nameField"
      />
      <Form.FieldGroup
        name="description"
        label={<FormattedMessage {...descriptionMessages.label} />}
        placeholder={formatMessage(descriptionMessages.placeholder)}
        data-cy="educationContentModal-descriptionField"
      />
      <Form.FieldGroup
        name="vimeoId"
        label={<FormattedMessage {...vimeoIdMessages.label} />}
        placeholder={formatMessage(vimeoIdMessages.placeholder)}
        description={<FormattedMessage {...vimeoIdMessages.description} />}
        data-cy="educationContentModal-vimeoIdField"
      />
      <Form.FieldGroup
        name="authoredBy"
        label={<FormattedMessage {...authoredByMessages.label} />}
        placeholder={formatMessage(authoredByMessages.placeholder)}
        data-cy="educationContentModal-authoredByField"
      />
      <Form.FieldGroup
        name="categoryId"
        label={<FormattedMessage {...categoryIdMessages.label} />}
        placeholder={formatMessage(categoryIdMessages.placeholder)}
        data-cy="educationContentModal-categoryIdField"
      />
      <Form.FieldGroup
        name="isPremium"
        label={<FormattedMessage {...isPremiumMessages.label} />}
        data-cy="educationContentModal-isPremiumField"
      />
      <Form.FieldGroup
        name="type"
        as={DropdownList}
        data={EDUCATION_VIDEO_TYPES}
        placeholder={typeMessages.placeholder}
        label={<FormattedMessage {...typeMessages.label} />}
        data-cy="educationContentModal-typeField"
      />
      <Form.FieldGroup
        name="practiceType"
        as={DropdownList}
        data={PRACTICE_TYPE}
        placeholder={practiceTypeMessages.placeholder}
        label={<FormattedMessage {...practiceTypeMessages.label} />}
        data-cy="educationContentModal-practiceTypeField"
      />
      <Form.FieldGroup
        name="presetIds"
        rows={5}
        as={MultilineListTextarea}
        label={<FormattedMessage {...presetIdsMessages.label} />}
        placeholder={presetIdsMessages.placeholder}
        description={<FormattedMessage {...presetIdsMessages.description} />}
        data-cy="educationContentModal-presetIdsField"
      />
      <Form.FieldGroup
        name="toolIds"
        rows={5}
        as={MultilineListTextarea}
        label={<FormattedMessage {...toolIdsMessages.label} />}
        placeholder={toolIdsMessages.placeholder}
        description={<FormattedMessage {...toolIdsMessages.description} />}
        data-cy="educationContentModal-toolIdsField"
      />
      <Form.FieldGroup
        name="captureModes"
        as={Multiselect}
        data={CAPTURE_MODES}
        label={<FormattedMessage {...captureModesMessages.label} />}
        description={
          <FormattedMessage {...captureModesMessages.description} />
        }
        data-cy="educationContentModal-captureModesField"
      />
      <Form.FieldGroup
        name="duration"
        label={<FormattedMessage {...durationMessages.label} />}
        description={<FormattedMessage {...durationMessages.description} />}
        data-cy="educationContentModal-durationField"
      />
      <Form.FieldGroup
        name="ranking"
        label={<FormattedMessage {...rankingMessages.label} />}
        description={<FormattedMessage {...rankingMessages.description} />}
        data-cy="educationContentModal-rankingField"
      />
    </ResourceModal>
  );
}
