export interface Country {
  code: string;
  title: string;
}

// TODO: replace this list with API call or GraphQL enum #LOH-214
export const countries: Country[] = [
  { code: 'DZ', title: 'Algeria (DZ)' },
  { code: 'AR', title: 'Argentina (AR)' },
  { code: 'AU', title: 'Australia (AU)' },
  { code: 'AT', title: 'Austria (AT)' },
  { code: 'BE', title: 'Belgium (BE)' },
  { code: 'BR', title: 'Brazil (BR)' },
  { code: 'BG', title: 'Bulgaria (BG)' },
  { code: 'CA', title: 'Canada (CA)' },
  { code: 'CL', title: 'Chile (CL)' },
  { code: 'CN', title: 'China (CN)' },
  { code: 'CO', title: 'Colombia (CO)' },
  { code: 'HR', title: 'Croatia (HR)' },
  { code: 'CY', title: 'Cyprus (CY)' },
  { code: 'CZ', title: 'Czech Republic (CZ)' },
  { code: 'DK', title: 'Denmark (DK)' },
  { code: 'EC', title: 'Ecuador (EC)' },
  { code: 'EG', title: 'Egypt (EG)' },
  { code: 'EE', title: 'Estonia (EE)' },
  { code: 'FI', title: 'Finland (FI)' },
  { code: 'FR', title: 'France (FR)' },
  { code: 'DE', title: 'Germany (DE)' },
  { code: 'GH', title: 'Ghana (GH)' },
  { code: 'GR', title: 'Greece (GR)' },
  { code: 'HT', title: 'Haiti (HT)' },
  { code: 'HN', title: 'Honduras (HN)' },
  { code: 'HK', title: 'Hong Kong (HK)' },
  { code: 'HU', title: 'Hungary (HU)' },
  { code: 'IS', title: 'Iceland (IS)' },
  { code: 'IN', title: 'India (IN)' },
  { code: 'ID', title: 'Indonesia (ID)' },
  { code: 'IE', title: 'Ireland (IE)' },
  { code: 'IL', title: 'Israel (IL)' },
  { code: 'IT', title: 'Italy (IT)' },
  { code: 'JP', title: 'Japan (JP)' },
  { code: 'KE', title: 'Kenya (KE)' },
  { code: 'KW', title: 'Kuwait (KW)' },
  { code: 'LV', title: 'Latvia (LV)' },
  { code: 'LT', title: 'Lithuania (LT)' },
  { code: 'LU', title: 'Luxembourg (LU)' },
  { code: 'MY', title: 'Malaysia (MY)' },
  { code: 'MT', title: 'Malta (MT)' },
  { code: 'MX', title: 'Mexico (MX)' },
  { code: 'MA', title: 'Morocco (MA)' },
  { code: 'NL', title: 'Netherlands (NL)' },
  { code: 'NZ', title: 'New Zealand (NZ)' },
  { code: 'NI', title: 'Nicaragua (NI)' },
  { code: 'NG', title: 'Nigeria (NG)' },
  { code: 'NO', title: 'Norway (NO)' },
  { code: 'OM', title: 'Oman (OM)' },
  { code: 'PK', title: 'Pakistan (PK)' },
  { code: 'PY', title: 'Paraguay (PY)' },
  { code: 'PH', title: 'Philippines (PH)' },
  { code: 'PL', title: 'Poland (PL)' },
  { code: 'PT', title: 'Portugal (PT)' },
  { code: 'QA', title: 'Qatar (QA)' },
  { code: 'RO', title: 'Romania (RO)' },
  { code: 'RW', title: 'Rwanda (RW)' },
  { code: 'SA', title: 'Saudi Arabia (SA)' },
  { code: 'SG', title: 'Singapore (SG)' },
  { code: 'SK', title: 'Slovakia (SK)' },
  { code: 'SI', title: 'Slovenia (SI)' },
  { code: 'ZA', title: 'South Africa (ZA)' },
  { code: 'KR', title: 'South Korea (KR)' },
  { code: 'ES', title: 'Spain (ES)' },
  { code: 'SE', title: 'Sweden (SE)' },
  { code: 'CH', title: 'Switzerland (CH)' },
  { code: 'TZ', title: 'Tanzania (TZ)' },
  { code: 'TH', title: 'Thailand (TH)' },
  { code: 'TR', title: 'Turkey (TR)' },
  { code: 'UG', title: 'Uganda (UG)' },
  { code: 'AE', title: 'United Arab Emirates (AE)' },
  { code: 'GB', title: 'United Kingdom (GB)' },
  { code: 'US', title: 'United States of America (US)' },
  { code: 'VA', title: 'Vatican (VA)' },
  { code: 'VE', title: 'Venezuela (VE)' },
  { code: 'VN', title: 'Vietnam (VN)' },
  { code: 'ZM', title: 'Zambia (ZM)' },
];

export const getCountryName = (code: string) => {
  const country = countries.find((item) => item.code === code);
  return country ? country.title : code;
};
