/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type FlatUsersGrid_user = {
    readonly id: string;
    readonly flatUserId: string | null;
    readonly userId: string | null;
    readonly auth0Id: string | null;
    readonly name: string | null;
    readonly email: string | null;
    readonly hasUnacceptedInvite: boolean | null;
    readonly specialtyKey: string | null;
    readonly specialtyFreeform: string | null;
    readonly phoneNumber: string | null;
    readonly placeOfWork: {
        readonly placeOfWorkId: string | null;
        readonly name: string | null;
    } | null;
    readonly placeOfWorkFreeform: string | null;
    readonly setupAt: string | null;
    readonly userCreatedAt: string | null;
    readonly userDeletedAt: string | null;
    readonly lastReviewRequestedAt: string | null;
    readonly lastCloudReviewRequestTriggeredAt: string | null;
    readonly lastImmediateReviewRequestTriggeredAt: string | null;
    readonly isImplicitPublic: boolean | null;
    readonly domainId: string | null;
    readonly type: DomainUserType | null;
    readonly domainUserCreatedAt: string | null;
    readonly domainUserDeletedAt: string | null;
    readonly integrationConfigs: ReadonlyArray<{
        readonly domainUserIntegrationConfigId: string | null;
    } | null> | null;
    readonly hasTableauDashboardAccess: boolean | null;
    readonly isNurse: boolean | null;
    readonly canFinalize: boolean | null;
    readonly canQa: boolean | null;
    readonly role: {
        readonly name: string | null;
    } | null;
    readonly domain: {
        readonly subdomainLabel: string | null;
    } | null;
    readonly " $refType": "FlatUsersGrid_user";
};
export type FlatUsersGrid_user$data = FlatUsersGrid_user;
export type FlatUsersGrid_user$key = {
    readonly " $data"?: FlatUsersGrid_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FlatUsersGrid_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlatUsersGrid_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flatUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auth0Id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": "hasUnacceptedInvite",
      "args": null,
      "kind": "ScalarField",
      "name": "hasPendingOrganizationInvite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialtyKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialtyFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlaceOfWork",
      "kind": "LinkedField",
      "name": "placeOfWork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeOfWorkId",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeOfWorkFreeform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastReviewRequestedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastCloudReviewRequestTriggeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastImmediateReviewRequestTriggeredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isImplicitPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserCreatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUserIntegrationConfig",
      "kind": "LinkedField",
      "name": "integrationConfigs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainUserIntegrationConfigId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasTableauDashboardAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isNurse",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subdomainLabel",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FlatUser",
  "abstractKey": null
};
})();
(node as any).hash = '843dff1fdb246aeff26bea0bae3ff6fb';
export default node;
