/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DlDataMode = "EXCLUDE" | "INCLUDE" | "PARTIAL" | "%future added value";
export type SetOrganizationDlDataModeOverrideInput = {
    organizationId: string;
    dlDataModeOverride?: DlDataMode | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type DLDataModeOverrideAction_SetDlDataModeOverrideMutationVariables = {
    input: SetOrganizationDlDataModeOverrideInput;
};
export type DLDataModeOverrideAction_SetDlDataModeOverrideMutationResponse = {
    readonly setOrganizationDlDataModeOverride: {
        readonly organization: {
            readonly dlDataModeOverride: DlDataMode | null;
        } | null;
    } | null;
};
export type DLDataModeOverrideAction_SetDlDataModeOverrideMutation = {
    readonly response: DLDataModeOverrideAction_SetDlDataModeOverrideMutationResponse;
    readonly variables: DLDataModeOverrideAction_SetDlDataModeOverrideMutationVariables;
};



/*
mutation DLDataModeOverrideAction_SetDlDataModeOverrideMutation(
  $input: SetOrganizationDlDataModeOverrideInput!
) {
  setOrganizationDlDataModeOverride(input: $input) {
    organization {
      dlDataModeOverride
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dlDataModeOverride",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DLDataModeOverrideAction_SetDlDataModeOverrideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrganizationDlDataModeOverridePayload",
        "kind": "LinkedField",
        "name": "setOrganizationDlDataModeOverride",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DLDataModeOverrideAction_SetDlDataModeOverrideMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrganizationDlDataModeOverridePayload",
        "kind": "LinkedField",
        "name": "setOrganizationDlDataModeOverride",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "870ad3a70da3a880ea24fb48834bc7d9",
    "id": null,
    "metadata": {},
    "name": "DLDataModeOverrideAction_SetDlDataModeOverrideMutation",
    "operationKind": "mutation",
    "text": "mutation DLDataModeOverrideAction_SetDlDataModeOverrideMutation(\n  $input: SetOrganizationDlDataModeOverrideInput!\n) {\n  setOrganizationDlDataModeOverride(input: $input) {\n    organization {\n      dlDataModeOverride\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9c7af101e426a31bc21b87c3635b2826';
export default node;
