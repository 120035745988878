import Button from '@bfly/ui2/Button';
import useRouter from 'found/useRouter';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import Alert from 'components/Alert';
import DomainModal, { basicDomainSchema } from 'components/DomainModal';
import { ActionModalProps } from 'components/ResourceModal';
import { formMessages } from 'messages/formMessages';
import {
  adminEmailMessages,
  countryMessages,
  dlDataModeOverrideMessages,
  firstOrganizationNameMessages,
  organizationSlugMessages,
  specialTypeMessages,
} from 'messages/organizationActions';
import {
  dlDataModeOverrideData,
  specialTypesData,
} from 'messages/organizationOptionsData';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import {
  ProvisionDomainActionMutation,
  ProvisionDomainActionMutationResponse,
} from './__generated__/ProvisionDomainActionMutation.graphql';
import { ProvisionDomainAction_customer$key } from './__generated__/ProvisionDomainAction_customer.graphql';

const fragment = graphql`
  fragment ProvisionDomainAction_customer on Customer {
    customerId
  }
`;

const provisionDomainMutation = graphql`
  mutation ProvisionDomainActionMutation($input: ProvisionDomainInput!) {
    provisionDomain(input: $input) {
      domain {
        id
        domainId
      }
    }
  }
`;

interface ProvisionDomainModalProps extends ActionModalProps {
  customerRef: ProvisionDomainAction_customer$key;
}

function ProvisionDomainModal({
  customerRef,
  ...props
}: ProvisionDomainModalProps) {
  const { router } = useRouter();
  const customer = useFragment(fragment, customerRef);

  const provisionDomainSchema = basicDomainSchema.shape({
    customerId: string().default(customer.customerId),
    slug: string().matches(/^[A-Za-z0-9](-?[A-Za-z0-9])*$/, {
      message: organizationSlugMessages.matches,
      excludeEmptyString: true,
    }),
    specialType: string()
      .required(specialTypeMessages.required)
      .oneOf(specialTypesData.map(({ value }) => value)),
    adminEmail: string()
      .email(adminEmailMessages.validation)
      .required(adminEmailMessages.required),
    country: string().max(2).required(countryMessages.required),
    dlDataModeOverride: string()
      .nullable()
      .oneOf(dlDataModeOverrideData.map(({ value }) => value))
      .default(dlDataModeOverrideMessages.exclude.defaultMessage),
    organizationName: string()
      .required(firstOrganizationNameMessages.required)
      .min(2, formMessages.minLength)
      .max(30, formMessages.maxLength),
  });
  const schemaDefaults = provisionDomainSchema.getDefault();

  function serialize({
    adminEmail,
    country,
    organizationName,
    slug,
    specialType,
    dlDataModeOverride,
    billingProvider,
    billingStatus,
    customerId,
    maxNumSeats,
    planType,
    practiceType,
    subscriptionEndsAt,
    stripeSubscriptionId,
    sfSubscriptionId,
    ...data
  }: any) {
    return {
      ...data,
      firstOrganization: {
        adminEmail,
        country,
        name: organizationName,
        slug,
        specialType,
        dlDataModeOverride,
      },
      firstSubscription: {
        billingProvider,
        billingStatus,
        customerId,
        maxNumSeats,
        planType,
        practiceType,
        subscriptionEndsAt,
        stripeSubscriptionId,
        sfSubscriptionId,
      },
    };
  }

  return (
    <DomainModal<ProvisionDomainActionMutation>
      {...props}
      provisionDomain
      schema={provisionDomainSchema}
      mutation={provisionDomainMutation}
      defaultValue={schemaDefaults}
      transformOnSubmit={serialize}
      onCompleted={({
        provisionDomain,
      }: ProvisionDomainActionMutationResponse) => {
        if (provisionDomain?.domain?.domainId) {
          router.push({
            pathname: `/domains/${provisionDomain.domain.domainId}`,
          });
        }
      }}
      title={
        <FormattedMessage
          id="provisionDomain.title"
          defaultMessage="Provision Domain"
        />
      }
      submitText={
        <FormattedMessage
          id="provisionDomain.submitText"
          defaultMessage="Provision Domain"
        />
      }
      alertMessage={
        <Alert variant="warning" className="mb-5">
          <FormattedMessage
            id="provisionDomain.warning"
            defaultMessage="This form will provision a NEW Domain, Subscription, and Organization. To provision a new Subscription for an existing Domain, please go to that Domain's detail page."
          />
        </Alert>
      }
    />
  );
}

interface ProvisionDomainActionProps {
  customerRef: ProvisionDomainAction_customer$key;
}

export default function ProvisionDomainAction({
  customerRef,
}: ProvisionDomainActionProps) {
  const [showProvisionDomain, setShowProvisionDomain] = useState(false);

  return (
    <PermissionsGuard
      resource={Resource.ENTERPRISE_UPGRADES}
      write
      hideContent
    >
      <Button variant="secondary" onClick={() => setShowProvisionDomain(true)}>
        <FormattedMessage
          id="customerDetail.actions.provisionDomain"
          defaultMessage="Provision Domain"
        />
      </Button>
      <ProvisionDomainModal
        customerRef={customerRef}
        show={showProvisionDomain}
        onClose={() => setShowProvisionDomain(false)}
      />
    </PermissionsGuard>
  );
}
