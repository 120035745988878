import camelCase from 'lodash/camelCase';
import { Errors } from 'react-formal/types';

export const isValidationError = (e: any): boolean => {
  const upstream = e?.json?.errors[0]?.upstream;
  return (
    upstream &&
    upstream?.status === 422 &&
    Array.isArray(upstream?.errors) &&
    upstream?.errors.length > 0
  );
};

export const parseValidationError = (e: any): Errors => {
  const upstream = e?.json?.errors[0]?.upstream;
  const errors: Errors = {};

  if (upstream && Array.isArray(upstream!.errors)) {
    upstream?.errors.forEach((err) => {
      if (err?.source?.pointer) {
        const field = camelCase(err.source.pointer.slice('/data/'.length));
        errors[field] = err?.detail;
      }
    });
  }

  return errors;
};
