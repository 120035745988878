import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import CountriesGrid from './components/CountriesGrid';
import CountriesPage from './components/CountriesPage';
import CountryDetailPage from './components/CountryDetailPage';

export default (
  <Route path="countries">
    <Route Component={CountriesPage}>
      <Route
        Component={CountriesGrid}
        query={graphql`
          query countries_CountriesGrid_Query {
            viewer {
              ...CountriesGrid_viewer
            }
          }
        `}
      />
    </Route>
    <Route
      path=":countryCode"
      Component={CountryDetailPage}
      query={graphql`
        query countries_CountryDetailPage_Query($countryCode: String!) {
          country(countryCode: $countryCode) {
            ...CountryDetailPage_country
          }
        }
      `}
      prepareVariables={({ countryCode }) => ({ countryCode })}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.country) throw new HttpError(404);
      }}
    />
  </Route>
);
