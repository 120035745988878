/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserDefinedRolesPage_userDefinedRole = {
    readonly id: string;
    readonly name: string | null;
    readonly userDefinedRoleId: string | null;
    readonly domainId: string | null;
    readonly deletedAt: string | null;
    readonly " $refType": "UserDefinedRolesPage_userDefinedRole";
};
export type UserDefinedRolesPage_userDefinedRole$data = UserDefinedRolesPage_userDefinedRole;
export type UserDefinedRolesPage_userDefinedRole$key = {
    readonly " $data"?: UserDefinedRolesPage_userDefinedRole$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserDefinedRolesPage_userDefinedRole">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDefinedRolesPage_userDefinedRole",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userDefinedRoleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    }
  ],
  "type": "UserDefinedRole",
  "abstractKey": null
};
(node as any).hash = '1595df85650f08953bc9565333563e38';
export default node;
