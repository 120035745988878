/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProcessingId = "D" | "P" | "T" | "%future added value";
export type StudyUrlDisplay = "NONE" | "NORMAL" | "%future added value";
export type CreateEhrInput = {
    name: string;
    organizationId?: string | null | undefined;
    domainId?: string | null | undefined;
    connectionId: string;
    processingId?: ProcessingId | null | undefined;
    procedureIdPath?: string | null | undefined;
    receivingApplication?: string | null | undefined;
    receivingFacility?: string | null | undefined;
    sendingFacility?: string | null | undefined;
    studyDescriptionMapping?: Record<string, any> | null | undefined;
    studyUrlDisplay: StudyUrlDisplay;
    timeoutAck: number;
    timeoutConnection: number;
    timezone: string;
    characterEncoding: string;
    hospitalHost: string;
    hospitalPort: number;
    clientMutationId?: string | null | undefined;
};
export type CreateEhrModalMutationVariables = {
    input: CreateEhrInput;
};
export type CreateEhrModalMutationResponse = {
    readonly createEhr: {
        readonly ehrEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"EhrsGrid_ehr">;
            } | null;
        } | null;
    } | null;
};
export type CreateEhrModalMutation = {
    readonly response: CreateEhrModalMutationResponse;
    readonly variables: CreateEhrModalMutationVariables;
};



/*
mutation CreateEhrModalMutation(
  $input: CreateEhrInput!
) {
  createEhr(input: $input) {
    ehrEdge {
      node {
        id
        ...EhrsGrid_ehr
      }
    }
  }
}

fragment EhrsGrid_ehr on Ehr {
  id
  ehrId
  name
  connectionId
  createdAt
  updatedAt
  deletedAt
  hospitalHost
  hospitalPort
  timeoutAck
  timeoutConnection
  timezone
  processingId
  receivingApplication
  receivingFacility
  sendingFacility
  characterEncoding
  studyDescriptionMapping
  studyUrlDisplay
  organization {
    organizationId
    id
  }
  domain {
    domainId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateEhrModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateEhrPayload",
        "kind": "LinkedField",
        "name": "createEhr",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EhrEdge",
            "kind": "LinkedField",
            "name": "ehrEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Ehr",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EhrsGrid_ehr"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateEhrModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateEhrPayload",
        "kind": "LinkedField",
        "name": "createEhr",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EhrEdge",
            "kind": "LinkedField",
            "name": "ehrEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Ehr",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ehrId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "connectionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hospitalHost",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hospitalPort",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeoutAck",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeoutConnection",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timezone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "processingId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "receivingApplication",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "receivingFacility",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sendingFacility",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "characterEncoding",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studyDescriptionMapping",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studyUrlDisplay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "organizationId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "domain",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "973a52d00aa81dd615660bae3ee092f4",
    "id": null,
    "metadata": {},
    "name": "CreateEhrModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateEhrModalMutation(\n  $input: CreateEhrInput!\n) {\n  createEhr(input: $input) {\n    ehrEdge {\n      node {\n        id\n        ...EhrsGrid_ehr\n      }\n    }\n  }\n}\n\nfragment EhrsGrid_ehr on Ehr {\n  id\n  ehrId\n  name\n  connectionId\n  createdAt\n  updatedAt\n  deletedAt\n  hospitalHost\n  hospitalPort\n  timeoutAck\n  timeoutConnection\n  timezone\n  processingId\n  receivingApplication\n  receivingFacility\n  sendingFacility\n  characterEncoding\n  studyDescriptionMapping\n  studyUrlDisplay\n  organization {\n    organizationId\n    id\n  }\n  domain {\n    domainId\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a167d6e138d6103b2dc030ab2f66629';
export default node;
