import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import UserDefinedRoleDetailPage from './components/UserDefinedRoleDetailPage';
import UserDefinedRolesPage from './components/UserDefinedRolesPage';

export default (
  <Route path="user-defined">
    <Route
      Component={UserDefinedRolesPage}
      query={graphql`
        query userDefinedRolesPage_userDefinedRoles_Query(
          $domainId: [String!]
          $name: [String!]
        ) {
          viewer {
            ...UserDefinedRolesPage_viewer
              @arguments(domainId: $domainId, name: $name)
          }
        }
      `}
      prepareVariables={(_params, { location }) => ({
        domainId: location.query.domainId,
        name: location.query.name,
      })}
    />
    <Route
      path=":userDefinedRoleId"
      Component={UserDefinedRoleDetailPage}
      query={graphql`
        query userDefinedRoles_UserDefinedRoleDetailPage_Query(
          $userDefinedRoleId: String!
        ) {
          role: userDefinedRole(userDefinedRoleId: $userDefinedRoleId) {
            ...UserDefinedRoleDetailPage_role
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.role) throw new HttpError(404);
      }}
    />
  </Route>
);
