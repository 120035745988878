import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { object, string } from 'yup';

import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { formMessages } from 'messages/formMessages';

import {
  countryCodeMessages,
  countryNameMessages,
} from '../messages/countryCreation';

export const countrySchema = object({
  code: string()
    .required(countryCodeMessages.required)
    .min(2, formMessages.minLength),
  name: string()
    .required(countryNameMessages.required)
    .min(2, formMessages.minLength),
});

export default function CountryModal<TMutation extends MutationParameters>({
  defaultValue,
  ...props
}: ResourceModalProps<TMutation>) {
  const isCreate = !defaultValue?.code;
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation> {...props} defaultValue={defaultValue}>
      <Form.FieldGroup
        disabled={!isCreate}
        name="code"
        label={<FormattedMessage {...countryCodeMessages.label} />}
        placeholder={formatMessage(countryCodeMessages.placeholder)}
        description={<FormattedMessage {...countryCodeMessages.description} />}
        data-cy="countryModal-codeField"
      />
      <Form.FieldGroup
        name="name"
        label={<FormattedMessage {...countryNameMessages.label} />}
        placeholder={formatMessage(countryNameMessages.placeholder)}
        description={<FormattedMessage {...countryNameMessages.description} />}
        className="name"
        data-cy="countryModal-nameField"
      />
    </ResourceModal>
  );
}
