import { defineMessages } from 'react-intl';

export const domainUserIdMessages = defineMessages({
  label: {
    id: 'newDomainUser.domainUserId.label',
    defaultMessage: 'Domain User ID*',
  },
  placeholder: {
    id: 'newDomainUser.domainUserId.placeholder',
    defaultMessage: 'Domain User ID',
  },
  required: {
    id: 'newDomainUser.domainUserId.required',
    defaultMessage: 'Please enter a domain user ID',
  },
});

export const domainUserNameMessages = defineMessages({
  label: {
    id: 'newDomainUser.name.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'newDomainUser.name.placeholder',
    defaultMessage: 'Name',
  },
  required: {
    id: 'newDomainUser.name.required',
    defaultMessage: 'Please enter a domain user name',
  },
  description: {
    id: 'newDomainUser.name.description',
    defaultMessage: 'Domain user name',
  },
});

export const domainUserEmailMessages = defineMessages({
  label: {
    id: 'newDomainUser.email.label',
    defaultMessage: 'Email',
  },
  placeholder: {
    id: 'newDomainUser.email.placeholder',
    defaultMessage: 'Email',
  },
  description: {
    id: 'newDomainUser.email.description',
    defaultMessage: 'Domain user email',
  },
});

export const domainUserTypeMessages = defineMessages({
  label: {
    id: 'newDomainUser.type.label',
    defaultMessage: 'Is Domain Admin',
  },
  placeholder: {
    id: 'newDomainUser.type.placeholder',
    defaultMessage: 'Type',
  },
  required: {
    id: 'newDomainUser.type.required',
    defaultMessage: 'Please enter a domain user type',
  },
});

export const domainUserDomainIdMessages = defineMessages({
  label: {
    id: 'newDomainUser.domainId.label',
    defaultMessage: 'Domain ID',
  },
  placeholder: {
    id: 'newDomainUser.domainId.placeholder',
    defaultMessage: 'Domain ID',
  },
  required: {
    id: 'newDomainUser.domainId.required',
    defaultMessage: 'Please enter a domain ID',
  },
});

export const domainUserUserIdMessages = defineMessages({
  label: {
    id: 'newDomainUser.userId.label',
    defaultMessage: 'User ID*',
  },
  placeholder: {
    id: 'newDomainUser.userId.placeholder',
    defaultMessage: 'User ID',
  },
  required: {
    id: 'newDomainUser.userId.required',
    defaultMessage: 'Please enter a domain user ID',
  },
});

export const isNurseMessages = defineMessages({
  label: {
    id: 'newDomainUser.isNurse.label',
    defaultMessage: 'Is Nurse',
  },
  placeholder: {
    id: 'newDomainUser.isNurse.placeholder',
    defaultMessage: 'Is Nurse',
  },
});

export const canFinalizeMessages = defineMessages({
  label: {
    id: 'newDomainUser.canFinalize.label',
    defaultMessage: 'Can Sign Studies',
  },
  placeholder: {
    id: 'newDomainUser.canFinalize.placeholder',
    defaultMessage: 'Can Sign Studies',
  },
});

export const canQAMessages = defineMessages({
  label: {
    id: 'newDomainUser.canQA.label',
    defaultMessage: 'Can QA',
  },
  placeholder: {
    id: 'newDomainUser.canQA.placeholder',
    defaultMessage: 'Can QA',
  },
});

export const hasTableauDashboardAccess = defineMessages({
  label: {
    id: 'newDomainUser.hasTableauDashboardAccess.label',
    defaultMessage: 'Has Tableau Dashboard Access',
  },
  placeholder: {
    id: 'newDomainUser.hasTableauDashboardAccess.label',
    defaultMessage: 'Has Tableau Dashboard Access',
  },
});

export const integrationFirstName = defineMessages({
  label: {
    id: 'newDomainUser.integrationFirstName.label',
    defaultMessage: 'First name (optional)',
  },
  placeholder: {
    id: 'newDomainUser.integrationFirstName.placeholder',
    defaultMessage: 'First name',
  },
});

export const integrationMiddleName = defineMessages({
  label: {
    id: 'newDomainUser.integrationMiddleName.label',
    defaultMessage: 'Middle initial (optional)',
  },
  placeholder: {
    id: 'newDomainUser.integrationMiddleName.placeholder',
    defaultMessage: 'Middle initial',
  },
});

export const integrationLastName = defineMessages({
  label: {
    id: 'newDomainUser.integrationLastName.label',
    defaultMessage: 'Last name (optional)',
  },
  placeholder: {
    id: 'newDomainUser.integrationLastName.placeholder',
    defaultMessage: 'Last name',
  },
});
