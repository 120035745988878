import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  EducationCategoryModal,
  basicEducationCategorySchema,
} from './EducationCategoryModal';
import { CreateEducationCategoryModalMutation } from './__generated__/CreateEducationCategoryModalMutation.graphql';
import { CreateEducationCategoryModal_viewer$key } from './__generated__/CreateEducationCategoryModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateEducationCategoryModalMutation(
    $input: CreateEducationCategoryInput!
  ) {
    createEducationCategory(input: $input) {
      educationCategoryEdge {
        node {
          id
          ...EducationCategoriesPage_educationCategory
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateEducationCategoryModal_viewer on Viewer {
    id
  }
`;

interface CreateEducationCategoryModalProps extends ActionModalProps {
  viewerRef: CreateEducationCategoryModal_viewer$key;
}

export function CreateEducationCategoryModal({
  viewerRef,
  ...props
}: CreateEducationCategoryModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'EducationCategoriesPage_educationCategories',
        rootFieldName: 'createEducationCategory',
        edgeName: 'educationCategoryEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <EducationCategoryModal<CreateEducationCategoryModalMutation>
      {...props}
      title={
        <FormattedMessage
          id="createEducationCategoryModal.title"
          defaultMessage="Create Education Category"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={basicEducationCategorySchema}
      mutation={createMutation}
      updater={updater}
    />
  );
}
