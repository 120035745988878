/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ehrs_EhrsPage_QueryVariables = {};
export type ehrs_EhrsPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"EhrsPage_viewer">;
    } | null;
};
export type ehrs_EhrsPage_Query = {
    readonly response: ehrs_EhrsPage_QueryResponse;
    readonly variables: ehrs_EhrsPage_QueryVariables;
};



/*
query ehrs_EhrsPage_Query {
  viewer {
    ...EhrsPage_viewer
    id
  }
}

fragment CreateEhrModal_viewer on Viewer {
  id
}

fragment EhrsPage_viewer on Viewer {
  ...CreateEhrModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ehrs_EhrsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EhrsPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ehrs_EhrsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "052cf4ac4def995ea0009e2238687148",
    "id": null,
    "metadata": {},
    "name": "ehrs_EhrsPage_Query",
    "operationKind": "query",
    "text": "query ehrs_EhrsPage_Query {\n  viewer {\n    ...EhrsPage_viewer\n    id\n  }\n}\n\nfragment CreateEhrModal_viewer on Viewer {\n  id\n}\n\nfragment EhrsPage_viewer on Viewer {\n  ...CreateEhrModal_viewer\n}\n"
  }
};
(node as any).hash = 'd52d02bee27966b11da158470eb7ea73';
export default node;
