import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import useQuery from '@bfly/ui2/useQuery';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { membershipIdMessages } from '../messages/membershipCreation';
import {
  membershipActions,
  membershipRoleMessages,
  setMembershipRole,
} from '../messages/membershipDetails';
import { SetMembershipRoleActionMutation } from './__generated__/SetMembershipRoleActionMutation.graphql';
import { SetMembershipRoleActionQuery } from './__generated__/SetMembershipRoleActionQuery.graphql';
import { SetMembershipRoleAction_membership$key } from './__generated__/SetMembershipRoleAction_membership.graphql';

export const basicMembershipRoleSchema = object({
  organizationMembershipId: string(),
  roleId: string(),
});

const fragment = graphql`
  fragment SetMembershipRoleAction_membership on OrganizationMembership {
    organizationMembershipId
    role {
      id
      roleId
      name
      roleType
    }
  }
`;

const mutation = graphql`
  mutation SetMembershipRoleActionMutation(
    $input: UpdateOrganizationMembershipInput!
  ) {
    updateOrganizationMembership(input: $input) {
      organizationMembership {
        role {
          id
          roleId
          name
          roleType
        }
      }
    }
  }
`;

interface SetMembershipRoleActionProps {
  membershipRef: SetMembershipRoleAction_membership$key;
}

export default function SetMembershipRoleAction({
  membershipRef,
}: SetMembershipRoleActionProps) {
  const { organizationMembershipId, role } = useFragment(
    fragment,
    membershipRef,
  );

  const { data } = useQuery<SetMembershipRoleActionQuery>(
    graphql`
      query SetMembershipRoleActionQuery {
        viewer {
          systemDefinedRoles(roleType: [PUBLIC_ORG]) {
            edges {
              node {
                name
                description
                systemDefinedRoleId
              }
            }
          }
        }
      }
    `,
    { variables: {} },
  );

  const systemDefinedRoles = data && data.viewer?.systemDefinedRoles;
  const roles = useMemo(() => {
    return [
      ...(systemDefinedRoles?.edges || []).map((entry) => {
        return entry?.node
          ? {
              name: entry.node.name,
              roleId: entry.node.systemDefinedRoleId,
            }
          : null;
      }),
    ];
  }, [systemDefinedRoles]);

  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  const defaultValue = useMemo(() => {
    return {
      organizationMembershipId,
      roleId: role?.roleId,
    };
  }, [organizationMembershipId, role]);

  return (
    <PermissionsGuard resource={Resource.USER_DEFINED_ROLES} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage {...membershipActions.setRole} />
      </Button>
      <ResourceModal<SetMembershipRoleActionMutation>
        defaultValue={defaultValue}
        show={showModal}
        onClose={() => setShowModal(false)}
        title={<FormattedMessage {...setMembershipRole.title} />}
        submitText={<FormattedMessage {...setMembershipRole.submitText} />}
        mutation={mutation}
        schema={basicMembershipRoleSchema}
      >
        <Form.FieldGroup
          disabled
          name="membershipId"
          placeholder={formatMessage(membershipIdMessages.placeholder)}
          label={<FormattedMessage {...membershipIdMessages.label} />}
          data-cy="setMembershipRole-membershipIdField"
        />
        <Form.FieldGroup
          name="roleId"
          as={DropdownList}
          textField="name"
          dataKey="roleId"
          mapFromValue={({ roleId }) => roleId}
          data={roles}
          placeholder={membershipRoleMessages.placeholder}
          label={<FormattedMessage {...membershipRoleMessages.label} />}
          data-cy="setMembershipRole-roleIdField"
        />
        {/* make some room for the dropdown */}
        <div css="min-height: 8rem;" />
      </ResourceModal>
    </PermissionsGuard>
  );
}
