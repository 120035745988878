/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_QueryVariables = {
    iosVersionBlacklistEntryId: string;
};
export type iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_QueryResponse = {
    readonly iosVersionBlacklistEntry: {
        readonly " $fragmentRefs": FragmentRefs<"IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry">;
    } | null;
};
export type iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_Query = {
    readonly response: iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_QueryResponse;
    readonly variables: iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_QueryVariables;
};



/*
query iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_Query(
  $iosVersionBlacklistEntryId: String!
) {
  iosVersionBlacklistEntry(iosVersionBlacklistEntryId: $iosVersionBlacklistEntryId) {
    ...IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry
    id
  }
}

fragment DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
  iosVersionBlacklistEntryId
}

fragment IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
  ...UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry
  ...DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry
  id
  iosVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  buildNumber
}

fragment UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
  id
  iosVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  buildNumber
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "iosVersionBlacklistEntryId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "iosVersionBlacklistEntryId",
    "variableName": "iosVersionBlacklistEntryId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IosVersionBlacklistEntry",
        "kind": "LinkedField",
        "name": "iosVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IosVersionBlacklistEntry",
        "kind": "LinkedField",
        "name": "iosVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iosVersionBlacklistEntryId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "applicationIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "versionRange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "buildNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eac361e15effe6caf37d2bb0bc552cff",
    "id": null,
    "metadata": {},
    "name": "iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_Query",
    "operationKind": "query",
    "text": "query iosVersionBlacklistEntries_IosVersionBlacklistEntryDetailPage_Query(\n  $iosVersionBlacklistEntryId: String!\n) {\n  iosVersionBlacklistEntry(iosVersionBlacklistEntryId: $iosVersionBlacklistEntryId) {\n    ...IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry\n    id\n  }\n}\n\nfragment DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry on IosVersionBlacklistEntry {\n  iosVersionBlacklistEntryId\n}\n\nfragment IosVersionBlacklistEntryDetailPage_iosVersionBlacklistEntry on IosVersionBlacklistEntry {\n  ...UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry\n  ...DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry\n  id\n  iosVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  buildNumber\n}\n\nfragment UpdateIosVersionBlacklistEntryModal_iosVersionBlacklistEntry on IosVersionBlacklistEntry {\n  id\n  iosVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  buildNumber\n}\n"
  }
};
})();
(node as any).hash = '62178765ef339dc8958220a5f2bc60bf';
export default node;
