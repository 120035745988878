/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type RoleType = "PUBLIC_ORG" | "SYSTEM_DEFINED" | "USER_DEFINED" | "%future added value";
export type UpdateFlatUserInput = {
    flatUserId: string;
    roleId?: string | null | undefined;
    name?: string | null | undefined;
    userName?: string | null | undefined;
    phoneNumber?: string | null | undefined;
    specialtyKey?: string | null | undefined;
    specialtyFreeform?: string | null | undefined;
    placeOfWorkId?: string | null | undefined;
    placeOfWorkFreeform?: string | null | undefined;
    domainUserName?: string | null | undefined;
    type?: DomainUserType | null | undefined;
    canQa?: boolean | null | undefined;
    canFinalize?: boolean | null | undefined;
    isNurse?: boolean | null | undefined;
    hasTableauDashboardAccess?: boolean | null | undefined;
    integrationDisplayNameFirst?: string | null | undefined;
    integrationDisplayNameLast?: string | null | undefined;
    integrationDisplayNameMiddle?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type SetFlatUserRoleActionMutationVariables = {
    input: UpdateFlatUserInput;
};
export type SetFlatUserRoleActionMutationResponse = {
    readonly updateFlatUser: {
        readonly flatUser: {
            readonly role: {
                readonly id: string;
                readonly roleId: string | null;
                readonly name: string | null;
                readonly roleType: RoleType | null;
            } | null;
        } | null;
    } | null;
};
export type SetFlatUserRoleActionMutation = {
    readonly response: SetFlatUserRoleActionMutationResponse;
    readonly variables: SetFlatUserRoleActionMutationVariables;
};



/*
mutation SetFlatUserRoleActionMutation(
  $input: UpdateFlatUserInput!
) {
  updateFlatUser(input: $input) {
    flatUser {
      role {
        id
        roleId
        name
        roleType
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MembershipRole",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetFlatUserRoleActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFlatUserPayload",
        "kind": "LinkedField",
        "name": "updateFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUser",
            "kind": "LinkedField",
            "name": "flatUser",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetFlatUserRoleActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFlatUserPayload",
        "kind": "LinkedField",
        "name": "updateFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUser",
            "kind": "LinkedField",
            "name": "flatUser",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2fc260323c32283eb6f638d6879c6d7",
    "id": null,
    "metadata": {},
    "name": "SetFlatUserRoleActionMutation",
    "operationKind": "mutation",
    "text": "mutation SetFlatUserRoleActionMutation(\n  $input: UpdateFlatUserInput!\n) {\n  updateFlatUser(input: $input) {\n    flatUser {\n      role {\n        id\n        roleId\n        name\n        roleType\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a8ccfcbd793d052386c2265faca17e14';
export default node;
