import Anchor from '@bfly/ui2/Anchor';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Link from '@bfly/ui2/Link';
import Section from '@bfly/ui2/Section';
import Text from '@bfly/ui2/Text';
import useResponsive from '@bfly/ui2/useResponsive';
import React, { useCallback, useMemo } from 'react';

import QuickCopyText from 'components/QuickCopyText';
import actionMessages from 'messages/actions';
import isDateFromString from 'utils/isDateFromString';

import Dl from './DescriptionList';

export type Resource = Record<string, unknown>;

export type LinkFieldsType = Record<string, (value: unknown) => string>;

interface ResourceDetailProps<T extends Resource> {
  data: T;
  quickCopyFields?: string[];
  objectFields?: string[];
  className?: string;
  linkFields?: LinkFieldsType;
  renderMap?: Record<
    string,
    {
      label?: () => React.ReactNode;
      value?: () => React.ReactNode;
    }
  >;
  orderComparator?: (a: string, b: string) => 1 | -1 | 0;
}

const IGNORED_KEYS = ['id'];

function getValues(
  data: Resource,
  objectFields?: string[],
  parentKey = '',
): Array<[string, any]> {
  return Object.entries(data)
    .filter(([key]) => !key.startsWith('__') && !IGNORED_KEYS.includes(key))
    .reduce((list, [key, value]) => {
      const values: Array<[string, any]> =
        value &&
        Object.prototype.toString.call(value) === '[object Object]' &&
        !objectFields?.includes(key)
          ? getValues(
              value as Record<string, any>,
              objectFields,
              `${parentKey}${key}: `,
            )
          : [[`${parentKey}${key}`, value as any]];
      return [...list, ...values];
    }, []);
}

function formatCellValue(value: any) {
  switch (Object.prototype.toString.call(value)) {
    case '[object Boolean]':
      return value
        ? actionMessages.yes.defaultMessage
        : actionMessages.no.defaultMessage;
    case '[object Object]':
    case '[object Array]':
      return (
        <Text
          variant="body"
          as="pre"
          className="whitespace-pre-wrap font-sans"
        >
          {value.length || Object.keys(value).length
            ? JSON.stringify(value, null, 4)
            : '-'}
        </Text>
      );
    default:
      return isDateFromString(value) ? (
        <FormattedDateTime value={new Date(value)} />
      ) : (
        value || '-'
      );
  }
}

export function ResourceDetail<T extends Resource>({
  data,
  quickCopyFields,
  objectFields,
  className,
  linkFields,
  renderMap,
  orderComparator,
}: ResourceDetailProps<T>) {
  const values = useMemo(() => {
    const valuesWithDefaultOrder = getValues(data, objectFields);
    if (orderComparator) {
      return valuesWithDefaultOrder.sort((a, b) =>
        orderComparator(a[0], b[0]),
      );
    }
    return valuesWithDefaultOrder;
  }, [data, objectFields, orderComparator]);

  const isMedium = useResponsive('md', 'down');

  const formatValue = useCallback(
    (key, value) => {
      const render = renderMap?.[key]?.value || formatCellValue;
      if (linkFields && key in linkFields) {
        const dataCy = key.includes(': ')
          ? key.slice(key.lastIndexOf(' ')).trim()
          : key;
        return (
          <Link
            data-cy={`detail-link-${dataCy}`}
            as={Anchor}
            to={linkFields[key](value)}
            target="_blank"
            className="text-white"
          >
            {render(value)}
          </Link>
        );
      }
      return render(value);
    },
    [linkFields, renderMap],
  );
  return (
    <Section className={className} data-cy="resource-detail-list">
      <Dl variant={isMedium ? 'stacked' : 'table'}>
        {values.map(([key, value]) => (
          <React.Fragment key={key}>
            <Dl.Term data-cy={`key-${key}`}>
              {renderMap?.[key]?.label?.() || key}
            </Dl.Term>
            <Dl.Detail data-cy={`value-${value}`}>
              {quickCopyFields?.includes(key) ? (
                <QuickCopyText text={value}>
                  {formatValue(key, value)}
                </QuickCopyText>
              ) : (
                formatValue(key, value)
              )}
            </Dl.Detail>
          </React.Fragment>
        ))}
      </Dl>
    </Section>
  );
}
