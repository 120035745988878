import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import ClippedText from 'components/ClippedText';
import DataGridBoolean from 'components/DataGridBoolean';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';

import educationContentTableHeadings from '../messages/tableHeadings';
import { EducationContentGrid_educationContent as EducationContent } from './__generated__/EducationContentGrid_educationContent.graphql';
import { EducationContentGrid_viewer$key } from './__generated__/EducationContentGrid_viewer.graphql';

const _ = graphql`
  fragment EducationContentGrid_educationContent on EducationContent {
    id
    educationContentId
    name
    description
    type
    contentType
    practiceType
    isPremium
    vimeoId
    absorbId
    duration
    ranking
    status
    presetLocalIds
    toolLocalIds
    captureModes
    deletedAt
    authoredBy {
      name
    }
    category {
      educationCategoryId
      name
    }
    courseLanguage
  }
`;

const fragment = graphql`
  fragment EducationContentGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    name: { type: "[String!]" }
    absorbId: { type: "[String!]" }
    contentType: { type: "[String!]" }
    practiceType: { type: "[String!]" }
    categoryId: { type: "[String!]" }
    categoryName: { type: "[String!]" }
  )
  @refetchable(queryName: "EducationContentGridPageRefetchQuery") {
    educationContent(
      first: $count
      after: $cursor
      name: $name
      absorbId: $absorbId
      contentType: $contentType
      practiceType: $practiceType
      categoryId: $categoryId
      categoryName: $categoryName
    ) @connection(key: "EducationContentGrid_educationContent", filters: []) {
      edges {
        node {
          ...EducationContentGrid_educationContent @relay(mask: false)
        }
      }
    }
  }
`;

export const tableSpec: ColumnSpec<EducationContent>[] = [
  {
    key: 'educationContentId',
    frozen: true,
    label: (
      <FormattedMessage
        {...educationContentTableHeadings.educationContentId}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.educationContentId}>
        <DataGridLink path="/education-content" id={item.educationContentId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'contentType',
    label: <FormattedMessage {...educationContentTableHeadings.contentType} />,
    render: ({ item }) => <DataGridText value={item.contentType} />,
  },
  {
    key: 'name',
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridText value={item.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'description',
    label: <FormattedMessage {...tableHeadings.description} />,
    render: ({ item }) => <ClippedText>{item.description || '-'}</ClippedText>,
  },
  {
    key: 'type',
    label: <FormattedMessage {...educationContentTableHeadings.videoType} />,
    render: ({ item }) => <DataGridText value={item.type} />,
  },
  {
    key: 'practiceType',
    label: <FormattedMessage {...tableHeadings.practiceType} />,
    render: ({ item }) => <DataGridText value={item.practiceType} />,
  },
  {
    key: 'status',
    label: <FormattedMessage {...educationContentTableHeadings.status} />,
    render: ({ item }) => <DataGridText value={item.status} />,
  },
  {
    key: 'duration',
    label: <FormattedMessage {...educationContentTableHeadings.duration} />,
    render: ({ item }) => <DataGridText value={item.duration} />,
  },
  {
    key: 'ranking',
    label: <FormattedMessage {...educationContentTableHeadings.ranking} />,
    render: ({ item }) => <DataGridText value={item.ranking} />,
  },
  {
    key: 'isPremium',
    label: <FormattedMessage {...educationContentTableHeadings.isPremium} />,
    render: ({ item }) => <DataGridBoolean value={item.isPremium} />,
  },
  {
    key: 'vimeoId',
    label: <FormattedMessage {...educationContentTableHeadings.vimeoId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.vimeoId}>
        <DataGridText value={item.vimeoId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'absorbId',
    label: <FormattedMessage {...educationContentTableHeadings.absorbId} />,
    render: ({ item }) => <DataGridText value={item.absorbId} />,
  },
  {
    key: 'authoredBy',
    label: <FormattedMessage {...educationContentTableHeadings.authoredBy} />,
    render: ({ item }) => (
      <QuickCopyText text={item.authoredBy?.name}>
        <DataGridText value={item.authoredBy?.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'categoryName',
    label: (
      <FormattedMessage {...educationContentTableHeadings.categoryName} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.category?.name}>
        <DataGridText value={item.category?.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'categoryId',
    label: <FormattedMessage {...educationContentTableHeadings.categoryId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.category?.educationCategoryId}>
        <DataGridLink
          path="/education-categories"
          id={item.category?.educationCategoryId}
        />
      </QuickCopyText>
    ),
  },
];

interface EducationContentGridProps {
  viewer: EducationContentGrid_viewer$key | null;
}

export default function EducationContentGrid({
  viewer: viewerRef,
}: EducationContentGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(
    fragment,
    viewerRef,
  );
  const nodes = data ? getNodes(data.educationContent) : [];

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
      getRowProps={({ deletedAt }) => ({
        classNames: deletedAt ? deletedClass : '',
      })}
    />
  );
}
