import React from 'react';
import { defineMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';

import { DeleteUserAction_user$key } from './__generated__/DeleteUserAction_user.graphql';

const fragment = graphql`
  fragment DeleteUserAction_user on FlatUser {
    name
    userId
    userDeletedAt
  }
`;

const mutation = graphql`
  mutation DeleteUserActionMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        __typename
      }
    }
  }
`;

interface DeleteUserActionProps {
  userRef: DeleteUserAction_user$key;
}

export default function DeleteUserAction({ userRef }: DeleteUserActionProps) {
  const { userId, name, userDeletedAt } = useFragment(fragment, userRef);

  const deleted = !!userDeletedAt;

  return (
    <DeleteAction
      itemName={name ?? 'User'}
      mutation={mutation}
      redirectTo="/users"
      disabled={deleted}
      input={{ userId }}
      actionMessageMsg={defineMessage({
        id: 'deleteUserAction.delete',
        defaultMessage: 'Deactivate public user',
      })}
    />
  );
}
