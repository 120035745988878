/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateCustomerModal_viewer = {
    readonly id: string;
    readonly " $refType": "CreateCustomerModal_viewer";
};
export type CreateCustomerModal_viewer$data = CreateCustomerModal_viewer;
export type CreateCustomerModal_viewer$key = {
    readonly " $data"?: CreateCustomerModal_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CreateCustomerModal_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateCustomerModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '36fadb7d3dab3a099d65e6f4cd3faee1';
export default node;
