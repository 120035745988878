import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import educationContentTableHeadings from '../messages/tableHeadings';
import { CreateEducationContentModal } from './CreateEducationContentModal';
import { EducationContentPage_viewer$key } from './__generated__/EducationContentPage_viewer.graphql';

const resource = Resource.EDUCATION_CONTENT;

const gridFilterFields: FilterField[] = [
  {
    title: tableHeadings.name.defaultMessage,
    field: 'name',
  },
  {
    title: educationContentTableHeadings.contentType.defaultMessage,
    field: 'contentType',
  },
  { title: tableHeadings.practiceType.defaultMessage, field: 'practiceType' },
  {
    title: educationContentTableHeadings.categoryId.defaultMessage,
    field: 'categoryId',
  },
  {
    title: educationContentTableHeadings.absorbId.defaultMessage,
    field: 'absorbId',
  },
  {
    title: educationContentTableHeadings.categoryName.defaultMessage,
    field: 'categoryName',
  },
];

const _ = graphql`
  fragment EducationContentPage_educationContent on EducationContent {
    id
    educationContentId
    name
    description
    type
    contentType
    practiceType
    isPremium
    vimeoId
    absorbId
    duration
    ranking
    status
    presetLocalIds
    toolLocalIds
    captureModes
    deletedAt
    authoredBy {
      name
    }
    category {
      educationCategoryId
      name
    }
    courseLanguage
  }
`;

const fragment = graphql`
  fragment EducationContentPage_viewer on Viewer {
    ...CreateEducationContentModal_viewer
  }
`;

interface EducationContentPageProps {
  viewer: EducationContentPage_viewer$key;
}

export default function EducationContentPage({
  viewer,
  children,
}: React.PropsWithChildren<EducationContentPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.educationContent);
  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.educationContent} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateEducationContentModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage
            id="educationContentPage.actionTitle"
            defaultMessage="Create Education Content"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
