/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ResetHostDeviceSecretAction_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly name: string | null;
    readonly shouldAuthorizeHostDevices: boolean | null;
    readonly hostDeviceSecret: string | null;
    readonly " $refType": "ResetHostDeviceSecretAction_domain";
};
export type ResetHostDeviceSecretAction_domain$data = ResetHostDeviceSecretAction_domain;
export type ResetHostDeviceSecretAction_domain$key = {
    readonly " $data"?: ResetHostDeviceSecretAction_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ResetHostDeviceSecretAction_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResetHostDeviceSecretAction_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldAuthorizeHostDevices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hostDeviceSecret",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = 'faad42c3f5a20501f5050fdec8ebb10b';
export default node;
