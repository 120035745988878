import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  AndroidVersionBlacklistEntryModal,
  androidVersionBlacklistEntrySchema,
} from './AndroidVersionBlacklistEntryModal';
import { CreateAndroidVersionBlacklistEntryModalMutation } from './__generated__/CreateAndroidVersionBlacklistEntryModalMutation.graphql';
import { CreateAndroidVersionBlacklistEntryModal_viewer$key } from './__generated__/CreateAndroidVersionBlacklistEntryModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateAndroidVersionBlacklistEntryModalMutation(
    $input: CreateAndroidVersionBlacklistEntryInput!
  ) {
    createAndroidVersionBlacklistEntry(input: $input) {
      androidVersionBlacklistEntryEdge {
        node {
          id
          ...AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateAndroidVersionBlacklistEntryModal_viewer on Viewer {
    id
  }
`;

interface CreateAndroidVersionBlacklistEntryModalProps
  extends ActionModalProps {
  viewerRef: CreateAndroidVersionBlacklistEntryModal_viewer$key;
}

export function CreateAndroidVersionBlacklistEntryModal({
  viewerRef,
  ...props
}: CreateAndroidVersionBlacklistEntryModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey:
          'AndroidVersionBlacklistEntriesPage_androidVersionBlacklistEntries',
        rootFieldName: 'createAndroidVersionBlacklistEntry',
        edgeName: 'androidVersionBlacklistEntryEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <AndroidVersionBlacklistEntryModal<CreateAndroidVersionBlacklistEntryModalMutation>
      {...props}
      schema={androidVersionBlacklistEntrySchema}
      mutation={createMutation}
      updater={updater}
      title={
        <FormattedMessage
          id="createAndroidVersionBlacklistEntryModal.title"
          defaultMessage="Create Android Version Recall Entry"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
    />
  );
}
