import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry$key } from './__generated__/DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry.graphql';

const fragment = graphql`
  fragment DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
    androidVersionBlacklistEntryId
  }
`;

const mutation = graphql`
  mutation DeleteAndroidVersionBlacklistEntryActionMutation(
    $input: DeleteAndroidVersionBlacklistEntryInput!
  ) {
    deleteAndroidVersionBlacklistEntry(input: $input) {
      deletedId
    }
  }
`;

interface DeleteAndroidVersionBlacklistEntryActionProps {
  androidVersionBlacklistEntryRef: DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry$key;
}

export default function DeleteAndroidVersionBlacklistEntryAction({
  androidVersionBlacklistEntryRef,
}: DeleteAndroidVersionBlacklistEntryActionProps) {
  const { androidVersionBlacklistEntryId } = useFragment(
    fragment,
    androidVersionBlacklistEntryRef,
  );
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      mutation={mutation}
      itemName={androidVersionBlacklistEntryId}
      input={{ androidVersionBlacklistEntryId }}
      redirectTo="/android-version-blacklist-entries"
      onDelete={() =>
        deleteBreadcrumb(
          `/android-version-blacklist-entries/${androidVersionBlacklistEntryId}`,
        )
      }
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
