/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type systemDefinedRoles_SystemDefinedRoleDetailPage_QueryVariables = {
    systemDefinedRoleId: string;
};
export type systemDefinedRoles_SystemDefinedRoleDetailPage_QueryResponse = {
    readonly role: {
        readonly " $fragmentRefs": FragmentRefs<"SystemDefinedRoleDetailPage_role">;
    } | null;
};
export type systemDefinedRoles_SystemDefinedRoleDetailPage_Query = {
    readonly response: systemDefinedRoles_SystemDefinedRoleDetailPage_QueryResponse;
    readonly variables: systemDefinedRoles_SystemDefinedRoleDetailPage_QueryVariables;
};



/*
query systemDefinedRoles_SystemDefinedRoleDetailPage_Query(
  $systemDefinedRoleId: String!
) {
  role: systemDefinedRole(systemDefinedRoleId: $systemDefinedRoleId) {
    ...SystemDefinedRoleDetailPage_role
    id
  }
}

fragment SystemDefinedRoleDetailPage_role on SystemDefinedRole {
  id
  systemDefinedRoleId
  name
  description
  permissions
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "systemDefinedRoleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "systemDefinedRoleId",
    "variableName": "systemDefinedRoleId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "systemDefinedRoles_SystemDefinedRoleDetailPage_Query",
    "selections": [
      {
        "alias": "role",
        "args": (v1/*: any*/),
        "concreteType": "SystemDefinedRole",
        "kind": "LinkedField",
        "name": "systemDefinedRole",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SystemDefinedRoleDetailPage_role"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "systemDefinedRoles_SystemDefinedRoleDetailPage_Query",
    "selections": [
      {
        "alias": "role",
        "args": (v1/*: any*/),
        "concreteType": "SystemDefinedRole",
        "kind": "LinkedField",
        "name": "systemDefinedRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "systemDefinedRoleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a8961501e8f72deaab438a5670f528f",
    "id": null,
    "metadata": {},
    "name": "systemDefinedRoles_SystemDefinedRoleDetailPage_Query",
    "operationKind": "query",
    "text": "query systemDefinedRoles_SystemDefinedRoleDetailPage_Query(\n  $systemDefinedRoleId: String!\n) {\n  role: systemDefinedRole(systemDefinedRoleId: $systemDefinedRoleId) {\n    ...SystemDefinedRoleDetailPage_role\n    id\n  }\n}\n\nfragment SystemDefinedRoleDetailPage_role on SystemDefinedRole {\n  id\n  systemDefinedRoleId\n  name\n  description\n  permissions\n}\n"
  }
};
})();
(node as any).hash = '8017b8aed4fa0a9ec2ad3fb48e8fd5b7';
export default node;
