import CopyToClipboardControl from '@bfly/ui2/CopyToClipboardControl';
import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'yup';

import Alert from 'components/Alert';
import ModalForm from 'components/ModalForm';
import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import { AuditLogsResponse } from './RequestAuditLogsAction';

const schema = object({
  downloadLink: string(),
  password: string(),
});

interface ShowInviteLinkModalProps extends ActionModalProps {
  value: AuditLogsResponse;
}

export function DownloadAuditLogDataModal({
  show,
  onClose,
  value,
}: ShowInviteLinkModalProps) {
  return (
    <ModalForm
      title={
        <FormattedMessage
          id="downloadAuditLogDataModal.title"
          defaultMessage="Download Link and Password for Audit Log Dump"
        />
      }
      horizontal
      show={show}
      schema={schema}
      defaultValue={value}
      onCancel={onClose}
    >
      <Form.Group
        label={
          <FormattedMessage
            id="downloadAuditLogDataModal.downloadLink"
            defaultMessage="Download Link"
          />
        }
      >
        <CopyToClipboardControl
          autoFocus
          value={value.downloadLink!}
          data-cy="downloadAuditLogDataModal-copyDownloadLink"
          buttonLabel={<FormattedMessage {...actionMessages.copy} />}
          successMessage={
            <FormattedMessage
              id="downloadAuditLogDataModal.copyDownloadLinkSuccess"
              defaultMessage="Download Link has been copied to the clipboard"
            />
          }
        />
      </Form.Group>
      <Form.Group
        label={
          <FormattedMessage
            id="downloadAuditLogDataModal.password"
            defaultMessage="Password"
          />
        }
      >
        <CopyToClipboardControl
          autoFocus
          value={value.password!}
          data-cy="downloadAuditLogDataModal-password"
          buttonLabel={<FormattedMessage {...actionMessages.copy} />}
          successMessage={
            <FormattedMessage
              id="downloadAuditLogDataModal.copyPasswordSuccess"
              defaultMessage="Password has been copied to the clipboard"
            />
          }
        />
      </Form.Group>
      <Alert variant="info" data-cy="downloadAuditLogDataModal-info">
        <FormattedMessage
          id="downloadAuditLogDataModal.info"
          defaultMessage="NOTE: In order to open the log file, the customer will need a 7z-compatible archive utility, like 7-Zip or WinZip for Windows, or The Unarchiver for MacOS. The default MacOS Archive Utility will not work."
        />
      </Alert>
    </ModalForm>
  );
}
