/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SystemDefinedRoleDetailPage_role = {
    readonly id: string;
    readonly systemDefinedRoleId: string | null;
    readonly name: string | null;
    readonly description: string | null;
    readonly permissions: any | null;
    readonly " $refType": "SystemDefinedRoleDetailPage_role";
};
export type SystemDefinedRoleDetailPage_role$data = SystemDefinedRoleDetailPage_role;
export type SystemDefinedRoleDetailPage_role$key = {
    readonly " $data"?: SystemDefinedRoleDetailPage_role$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SystemDefinedRoleDetailPage_role">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemDefinedRoleDetailPage_role",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemDefinedRoleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "type": "SystemDefinedRole",
  "abstractKey": null
};
(node as any).hash = '39a51e8ad68c31182f130f9bee8403df';
export default node;
