import Button from '@bfly/ui2/Button';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { InferType, string } from 'yup';

import Alert from 'components/Alert';
import DomainModal, { basicDomainSchema } from 'components/DomainModal';
import { ActionModalProps } from 'components/ResourceModal';
import { firstAdminIdMessages } from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { CreateDomainActionMutation } from './__generated__/CreateDomainActionMutation.graphql';
import { CreateDomainAction_organization$key } from './__generated__/CreateDomainAction_organization.graphql';

const fragment = graphql`
  fragment CreateDomainAction_organization on Organization {
    organizationId
    customer {
      customerId
    }
  }
`;

const createDomainMutation = graphql`
  mutation CreateDomainActionMutation(
    $input: UpgradeOrganizationToEnterpriseDomainInput!
  ) {
    upgradeOrganizationToEnterpriseDomain(input: $input) {
      firstOrganization {
        id
        organizationId
        name
        slug
        country
        deletedAt
        subscription {
          subscriptionId
          subscriptionEndsAt
          accessEndsAt
          hasStartedActiveSubscription
          hasCompletedTrial
          canAccessProFeatures
          isInGracePeriod
          isTeam
          billingStatus
          billingProvider
          stripeSubscriptionId
          sfSubscriptionId
          planType
          maxNumSeats
          practiceType
        }
        isActive
        customer {
          customerId
        }
        domain {
          domainId
        }
        dlDataMode
        dlDataModeOverride
        enterpriseCapabilityManagementEnabled
        enabledPresetIds
        enabledToolIds
        enabledCaptureModes
        enabledCapabilities
        specialType
      }
    }
  }
`;

interface CreateDomainModalProps extends ActionModalProps {
  organizationRef: CreateDomainAction_organization$key;
}

function CreateDomainModal({
  organizationRef,
  ...props
}: CreateDomainModalProps) {
  const { organizationId, customer } = useFragment(fragment, organizationRef);
  const createNewDomainSchema = basicDomainSchema.shape({
    customerId: string().default(customer!.customerId),
    firstOrganizationId: string()
      .meta({
        readOnly: true,
      })
      .default(organizationId),
    firstAdminId: string().required(firstAdminIdMessages.required),
  });

  function serialize({
    name,
    subdomainLabel,
    planType,
    maxNumSeats,
    practiceType,
    subscriptionEndsAt,
    billingStatus,
    billingProvider,
    stripeSubscriptionId,
    sfSubscriptionId,
    customerId,
    domainId,
    firstOrganizationId,
    firstAdminId,
  }: InferType<typeof createNewDomainSchema>) {
    return {
      name,
      subdomainLabel,
      domainId,
      firstOrganizationId,
      firstAdminId,
      firstSubscription: {
        billingProvider,
        billingStatus,
        customerId,
        maxNumSeats,
        planType,
        practiceType,
        subscriptionEndsAt,
        stripeSubscriptionId,
        sfSubscriptionId,
      },
    };
  }

  return (
    <DomainModal<CreateDomainActionMutation>
      {...props}
      schema={createNewDomainSchema}
      mutation={createDomainMutation}
      transformOnSubmit={serialize}
      title={
        <FormattedMessage
          id="createNewDomain.title"
          defaultMessage="Create New Domain"
        />
      }
      submitText={
        <FormattedMessage
          id="createNewDomain.submitText"
          defaultMessage="Create New Domain"
        />
      }
      alertMessage={
        <Alert variant="warning" className="mb-5">
          <FormattedMessage
            id="createNewDomain.warning"
            defaultMessage="This form requires creating a new Subscription. It will usually be sufficient to copy data from the existing parent Subscription."
          />
        </Alert>
      }
    />
  );
}

interface CreateDomainActionProps {
  organizationRef: CreateDomainAction_organization$key;
}

export default function CreateDomainAction({
  organizationRef,
}: CreateDomainActionProps) {
  const [showCreateDomain, setShowCreateDomain] = useState(false);
  return (
    <PermissionsGuard
      resource={Resource.ENTERPRISE_UPGRADES}
      write
      hideContent
    >
      <Button variant="secondary" onClick={() => setShowCreateDomain(true)}>
        <FormattedMessage
          id="organizationDetail.actions.createNewDomain"
          defaultMessage="Create New Domain"
        />
      </Button>
      <CreateDomainModal
        organizationRef={organizationRef}
        show={showCreateDomain}
        onClose={() => setShowCreateDomain(false)}
      />
    </PermissionsGuard>
  );
}
