import Layout from '@4c/layout';
import FailureOutlineIcon from '@bfly/icons/FailureOutline';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Alert from 'components/Alert';

export default function DangerousActionWarning() {
  return (
    <Alert variant="danger" className="mb-4" data-cy="caution">
      <Layout className="mb-1">
        <FailureOutlineIcon className="mr-2" />
        <Text transform="uppercase" variant="body-bold">
          <FormattedMessage
            id="dangerousActionWarning.title"
            defaultMessage="Caution"
          />
        </Text>
      </Layout>
      <Text>
        <FormattedMessage
          id="dangerousActionWarning.content"
          defaultMessage="Changing this value can have serious implications for the organization's data storage."
        />
      </Text>
    </Alert>
  );
}
