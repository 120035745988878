/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type emailChangeRequests_EmailChangeRequestDetailPage_QueryVariables = {
    emailChangeRequestId: string;
};
export type emailChangeRequests_EmailChangeRequestDetailPage_QueryResponse = {
    readonly emailChangeRequest: {
        readonly " $fragmentRefs": FragmentRefs<"EmailChangeRequestDetailPage_emailChangeRequest">;
    } | null;
};
export type emailChangeRequests_EmailChangeRequestDetailPage_Query = {
    readonly response: emailChangeRequests_EmailChangeRequestDetailPage_QueryResponse;
    readonly variables: emailChangeRequests_EmailChangeRequestDetailPage_QueryVariables;
};



/*
query emailChangeRequests_EmailChangeRequestDetailPage_Query(
  $emailChangeRequestId: String!
) {
  emailChangeRequest(emailChangeRequestId: $emailChangeRequestId) {
    ...EmailChangeRequestDetailPage_emailChangeRequest
    id
  }
}

fragment DeleteEmailChangeRequestAction_emailChangeRequest on EmailChangeRequest {
  emailChangeRequestId
  email
}

fragment EmailChangeRequestDetailPage_emailChangeRequest on EmailChangeRequest {
  ...DeleteEmailChangeRequestAction_emailChangeRequest
  emailChangeRequestId
  email
  oldEmail
  createdAt
  expiresAt
  auth0Id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "emailChangeRequestId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "emailChangeRequestId",
    "variableName": "emailChangeRequestId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "emailChangeRequests_EmailChangeRequestDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmailChangeRequest",
        "kind": "LinkedField",
        "name": "emailChangeRequest",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EmailChangeRequestDetailPage_emailChangeRequest"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "emailChangeRequests_EmailChangeRequestDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmailChangeRequest",
        "kind": "LinkedField",
        "name": "emailChangeRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailChangeRequestId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oldEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "auth0Id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9db7c06279979070d387ea40e55e8acf",
    "id": null,
    "metadata": {},
    "name": "emailChangeRequests_EmailChangeRequestDetailPage_Query",
    "operationKind": "query",
    "text": "query emailChangeRequests_EmailChangeRequestDetailPage_Query(\n  $emailChangeRequestId: String!\n) {\n  emailChangeRequest(emailChangeRequestId: $emailChangeRequestId) {\n    ...EmailChangeRequestDetailPage_emailChangeRequest\n    id\n  }\n}\n\nfragment DeleteEmailChangeRequestAction_emailChangeRequest on EmailChangeRequest {\n  emailChangeRequestId\n  email\n}\n\nfragment EmailChangeRequestDetailPage_emailChangeRequest on EmailChangeRequest {\n  ...DeleteEmailChangeRequestAction_emailChangeRequest\n  emailChangeRequestId\n  email\n  oldEmail\n  createdAt\n  expiresAt\n  auth0Id\n}\n"
  }
};
})();
(node as any).hash = 'e75855ccfbe3f1c4cfef2ed649dbd9e7';
export default node;
