/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteOrganizationInviteInput = {
    organizationInviteId: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteInviteActionMutationVariables = {
    input: DeleteOrganizationInviteInput;
};
export type DeleteInviteActionMutationResponse = {
    readonly deleteOrganizationInvite: {
        readonly organizationInvite: {
            readonly organizationInviteId: string | null;
            readonly deletedAt: string | null;
        } | null;
    } | null;
};
export type DeleteInviteActionMutation = {
    readonly response: DeleteInviteActionMutationResponse;
    readonly variables: DeleteInviteActionMutationVariables;
};



/*
mutation DeleteInviteActionMutation(
  $input: DeleteOrganizationInviteInput!
) {
  deleteOrganizationInvite(input: $input) {
    organizationInvite {
      organizationInviteId
      deletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationInviteId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteInviteActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationInvitePayload",
        "kind": "LinkedField",
        "name": "deleteOrganizationInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationInvite",
            "kind": "LinkedField",
            "name": "organizationInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteInviteActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationInvitePayload",
        "kind": "LinkedField",
        "name": "deleteOrganizationInvite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationInvite",
            "kind": "LinkedField",
            "name": "organizationInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a10d8405d8ec6f828248504b6c83f492",
    "id": null,
    "metadata": {},
    "name": "DeleteInviteActionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteInviteActionMutation(\n  $input: DeleteOrganizationInviteInput!\n) {\n  deleteOrganizationInvite(input: $input) {\n    organizationInvite {\n      organizationInviteId\n      deletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '044fffb938ac53618982a7e2802ca2aa';
export default node;
