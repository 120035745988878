import MutationButton from '@bfly/ui2/MutationButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { GenerateSharedDeviceKeyActionMutation } from './__generated__/GenerateSharedDeviceKeyActionMutation.graphql';
import { GenerateSharedDeviceKeyAction_domain$key } from './__generated__/GenerateSharedDeviceKeyAction_domain.graphql';

const fragment = graphql`
  fragment GenerateSharedDeviceKeyAction_domain on Domain {
    id
    domainId
  }
`;

const mutation = graphql`
  mutation GenerateSharedDeviceKeyActionMutation(
    $input: GenerateDomainSharedDeviceKeyInput!
  ) {
    generateDomainSharedDeviceKey(input: $input) {
      __typename
      domain {
        sharedDeviceKey
      }
    }
  }
`;

interface GenerateSharedDeviceKeyActionProps {
  domainRef: GenerateSharedDeviceKeyAction_domain$key;
}

export default function GenerateSharedDeviceKeyAction({
  domainRef,
}: GenerateSharedDeviceKeyActionProps) {
  const domain = useFragment(fragment, domainRef);
  return (
    <PermissionsGuard
      resource={Resource.GENERATE_SHARED_DEVICE_KEY}
      write
      hideContent
    >
      <MutationButton<GenerateSharedDeviceKeyActionMutation>
        mutation={mutation}
        input={{ domainId: domain.domainId! }}
        variant="secondary"
        successToastMessage={
          <FormattedMessage
            id="generateSharedDeviceKeyAction.success"
            defaultMessage="Shared Device Key has been successfully generated"
          />
        }
        errorToastMessage={
          <FormattedMessage
            id="generateSharedDeviceKeyAction.error"
            defaultMessage="Failed to generate Shared Device Key"
          />
        }
      >
        <FormattedMessage
          id="generateSharedDeviceKeyAction.requestReview"
          defaultMessage="Generate Shared Device Key"
        />
      </MutationButton>
    </PermissionsGuard>
  );
}
