/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SetApplicationVersionRequirementInput = {
    applicationIdentifier: string;
    minimumVersion: string;
    clientMutationId?: string | null | undefined;
};
export type CreateApplicationVersionRequirementModalMutationVariables = {
    input: SetApplicationVersionRequirementInput;
};
export type CreateApplicationVersionRequirementModalMutationResponse = {
    readonly setApplicationVersionRequirement: {
        readonly applicationVersionRequirement: {
            readonly id: string;
            readonly applicationIdentifier: string | null;
            readonly minimumVersion: string | null;
        } | null;
    } | null;
};
export type CreateApplicationVersionRequirementModalMutation = {
    readonly response: CreateApplicationVersionRequirementModalMutationResponse;
    readonly variables: CreateApplicationVersionRequirementModalMutationVariables;
};



/*
mutation CreateApplicationVersionRequirementModalMutation(
  $input: SetApplicationVersionRequirementInput!
) {
  setApplicationVersionRequirement(input: $input) {
    applicationVersionRequirement {
      id
      applicationIdentifier
      minimumVersion
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetApplicationVersionRequirementPayload",
    "kind": "LinkedField",
    "name": "setApplicationVersionRequirement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApplicationVersionRequirement",
        "kind": "LinkedField",
        "name": "applicationVersionRequirement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "applicationIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimumVersion",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateApplicationVersionRequirementModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateApplicationVersionRequirementModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8d57749b75300e76a788590ca1f0795a",
    "id": null,
    "metadata": {},
    "name": "CreateApplicationVersionRequirementModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateApplicationVersionRequirementModalMutation(\n  $input: SetApplicationVersionRequirementInput!\n) {\n  setApplicationVersionRequirement(input: $input) {\n    applicationVersionRequirement {\n      id\n      applicationIdentifier\n      minimumVersion\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '23b24164dd727558a6645783104778a7';
export default node;
