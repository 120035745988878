import Button from '@bfly/ui2/Button';
import React, { PropsWithChildren, ReactElement, useState } from 'react';

import { ActionModalProps } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

interface ResourceGridActionProps {
  resource: Resource;
  renderActionModal?: (props: ActionModalProps) => ReactElement;
  className?: string;
}

export default function ResourceGridAction({
  resource,
  renderActionModal,
  children,
  className,
}: PropsWithChildren<ResourceGridActionProps>) {
  const [showAction, setShowAction] = useState(false);
  const hideActionModal = () => {
    setShowAction(false);
  };

  return (
    <PermissionsGuard resource={resource} write hideContent>
      {!!renderActionModal && (
        <>
          <Button
            className={className}
            variant="secondary"
            data-cy="actionButton"
            onClick={() => setShowAction((isShow) => !isShow)}
          >
            {children}
          </Button>
          {renderActionModal({ show: showAction, onClose: hideActionModal })}
        </>
      )}
    </PermissionsGuard>
  );
}
