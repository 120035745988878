/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type placesOfWork_PlacesOfWorkPage_QueryVariables = {};
export type placesOfWork_PlacesOfWorkPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"PlacesOfWorkPage_viewer">;
    } | null;
};
export type placesOfWork_PlacesOfWorkPage_Query = {
    readonly response: placesOfWork_PlacesOfWorkPage_QueryResponse;
    readonly variables: placesOfWork_PlacesOfWorkPage_QueryVariables;
};



/*
query placesOfWork_PlacesOfWorkPage_Query {
  viewer {
    ...PlacesOfWorkPage_viewer
    id
  }
}

fragment CreatePlaceOfWorkModal_viewer on Viewer {
  id
}

fragment PlacesOfWorkPage_viewer on Viewer {
  ...CreatePlaceOfWorkModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "placesOfWork_PlacesOfWorkPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlacesOfWorkPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "placesOfWork_PlacesOfWorkPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fca1b93aa47af03ec02f95eb4bfdd3aa",
    "id": null,
    "metadata": {},
    "name": "placesOfWork_PlacesOfWorkPage_Query",
    "operationKind": "query",
    "text": "query placesOfWork_PlacesOfWorkPage_Query {\n  viewer {\n    ...PlacesOfWorkPage_viewer\n    id\n  }\n}\n\nfragment CreatePlaceOfWorkModal_viewer on Viewer {\n  id\n}\n\nfragment PlacesOfWorkPage_viewer on Viewer {\n  ...CreatePlaceOfWorkModal_viewer\n}\n"
  }
};
(node as any).hash = '29d26564536b27b85028689f6f771f05';
export default node;
