import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import DropdownList from 'components/DropdownList';
import { Resource } from 'components/ResourceDetail';
import { presetLoaderMessages } from 'messages/presetLoader';

export interface PresetTemplate<TData = Resource> {
  title: MessageDescriptor;
  template: TData;
}

interface PresetLoaderProps<TData = Resource> {
  onApply: (input: any) => void;
  templates: PresetTemplate<TData>[];
}

export function PresetLoader<TData = Resource>({
  onApply,
  templates,
}: PresetLoaderProps<TData>) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedPreset, setSelectedPreset] =
    useState<PresetTemplate<TData>>();

  return (
    <Layout
      className="md:flex-row flex-col md:items-center items-stretch mb-5"
      justify="flex-end"
      data-cy="ApplyPreset"
    >
      {showDropdown ? (
        <>
          <DropdownList
            data={templates}
            textField="title"
            dataKey="template"
            data-cy="organization-choosePreset"
            placeholder={presetLoaderMessages.dropdownPlaceholder}
            onChange={(preset) => setSelectedPreset(preset)}
            className="flex-grow mb-2"
          />
          <Layout className="mb-2" justify="space-between">
            <Button
              size="lg"
              className="md:ml-2"
              data-cy="ApplyButton"
              disabled={!selectedPreset}
              onClick={() => {
                onApply(selectedPreset!.template);
                setShowDropdown(false);
              }}
            >
              <FormattedMessage
                id="presetLoader.apply"
                defaultMessage="Apply"
              />
            </Button>
            <Button
              size="lg"
              className="ml-2"
              data-cy="CancelButton"
              variant="secondary"
              onClick={() => {
                setSelectedPreset(undefined);
                setShowDropdown(false);
              }}
            >
              <FormattedMessage
                id="presetLoader.cancel"
                defaultMessage="Cancel"
              />
            </Button>
          </Layout>
        </>
      ) : (
        <Button
          data-cy="applyPresetButton"
          variant="outline-primary"
          onClick={() => setShowDropdown(true)}
        >
          <FormattedMessage
            id="presetLoader.showList"
            defaultMessage="Apply Preset"
          />
        </Button>
      )}
    </Layout>
  );
}
