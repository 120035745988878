import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';
import { deletedClass } from 'styles';

import domainInvitesTableHeadings from '../messages/domainInvitesTableHeadings';
import { DomainInvitesGrid_userInvite as UserInvite } from './__generated__/DomainInvitesGrid_userInvite.graphql';
import { DomainInvitesGrid_viewer$key } from './__generated__/DomainInvitesGrid_viewer.graphql';

const _ = graphql`
  fragment DomainInvitesGrid_userInvite on UserInvite {
    id
    userInviteId
    createdAt
    usedAt
    domain {
      domainId
      name
    }
    user {
      email
    }
  }
`;

const fragment = graphql`
  fragment DomainInvitesGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    domainId: { type: "[String!]" }
    email: { type: "[String!]" }
  )
  @refetchable(queryName: "DomainInvitesGridRefetchQuery") {
    userInvites(
      first: $count
      after: $cursor
      domainId: $domainId
      email: $email
    ) @connection(key: "DomainInvitesGrid_userInvites", filters: []) {
      edges {
        node {
          ...DomainInvitesGrid_userInvite @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<UserInvite>[] = [
  {
    key: 'userInviteId',
    frozen: true,
    label: <FormattedMessage {...domainInvitesTableHeadings.userInviteId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.userInviteId}>
        <DataGridLink path="/domain-invites" id={item.userInviteId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'email',
    label: <FormattedMessage {...tableHeadings.email} />,
    render: ({ item }) => (
      <QuickCopyText text={item.user?.email}>
        <DataGridText value={item.user?.email} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domain?.domainId}>
        <DataGridLink path="/domains" id={item.domain?.domainId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainName',
    label: <FormattedMessage {...tableHeadings.domainName} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domain?.name || '-'}>
        <DataGridText value={item.domain?.name || '-'} />
      </QuickCopyText>
    ),
  },
  {
    key: 'createdAt',
    label: <FormattedMessage {...tableHeadings.createdAt} />,
    render: ({ item }) => <DataGridDateTime value={item.createdAt} />,
  },
  {
    key: 'usedAt',
    label: <FormattedMessage {...tableHeadings.usedAt} />,
    render: ({ item }) => <DataGridDateTime value={item.usedAt} />,
  },
];

interface DomainInvitesGridProps {
  viewer: DomainInvitesGrid_viewer$key;
}

export default function DomainInvitesGrid({ viewer }: DomainInvitesGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.userInvites);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      getRowProps={({ usedAt }) => ({
        classNames: usedAt ? deletedClass : '',
      })}
      scrollKey="data-grid"
    />
  );
}
