import { stylesheet } from 'astroturf/react';
import clsx from 'clsx';
import React from 'react';

const styles = stylesheet`
  @import '~@bfly/ui/styles/theme';

  .warning {
    color: darken(theme-color('warning'), 20%);
    background-color: lighten(desaturate(theme-color('warning'), 35%), 43%);
    border: 1px solid darken(desaturate(theme-color('warning'), 35%), 1%);
  }

  .danger {
    color: darken(theme-color('danger'), 20%);
    background-color: lighten(desaturate(theme-color('danger'), 35%), 43%);
    border: 1px solid darken(desaturate(theme-color('danger'), 35%), 1%);
  }

  .info {
    color: darken(color('blue'), 20%);
    background-color: lighten(desaturate(color('blue'), 35%), 35%);
    border: 1px solid lighten(color('blue'), 20%);
  }
`;

interface AlertProps extends React.ComponentPropsWithoutRef<'div'> {
  variant: 'warning' | 'danger' | 'info';
}

const Alert = React.forwardRef(
  (props: AlertProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        {...props}
        className={clsx(
          'px-3 py-2 border rounded',
          props.variant === 'info' && styles.info,
          props.variant === 'danger' && styles.danger,
          props.variant === 'warning' && styles.warning,
          props.className,
        )}
      />
    );
  },
);

export default Alert;
