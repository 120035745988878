/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type userDefinedRolesPage_userDefinedRoles_QueryVariables = {
    domainId?: Array<string> | null | undefined;
    name?: Array<string> | null | undefined;
};
export type userDefinedRolesPage_userDefinedRoles_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"UserDefinedRolesPage_viewer">;
    } | null;
};
export type userDefinedRolesPage_userDefinedRoles_Query = {
    readonly response: userDefinedRolesPage_userDefinedRoles_QueryResponse;
    readonly variables: userDefinedRolesPage_userDefinedRoles_QueryVariables;
};



/*
query userDefinedRolesPage_userDefinedRoles_Query(
  $domainId: [String!]
  $name: [String!]
) {
  viewer {
    ...UserDefinedRolesPage_viewer_dhE9s
    id
  }
}

fragment UserDefinedRolesPage_viewer_dhE9s on Viewer {
  userDefinedRoles(first: 20, domainId: $domainId, name: $name) {
    edges {
      node {
        id
        name
        userDefinedRoleId
        domainId
        deletedAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = {
  "kind": "Variable",
  "name": "domainId",
  "variableName": "domainId"
},
v2 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userDefinedRolesPage_userDefinedRoles_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "UserDefinedRolesPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userDefinedRolesPage_userDefinedRoles_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "UserDefinedRoleConnection",
            "kind": "LinkedField",
            "name": "userDefinedRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserDefinedRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserDefinedRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userDefinedRoleId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "UserDefinedRolesPage_userDefinedRoles",
            "kind": "LinkedHandle",
            "name": "userDefinedRoles"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cf9be0f7567f04ded3eef59a7fff4c3",
    "id": null,
    "metadata": {},
    "name": "userDefinedRolesPage_userDefinedRoles_Query",
    "operationKind": "query",
    "text": "query userDefinedRolesPage_userDefinedRoles_Query(\n  $domainId: [String!]\n  $name: [String!]\n) {\n  viewer {\n    ...UserDefinedRolesPage_viewer_dhE9s\n    id\n  }\n}\n\nfragment UserDefinedRolesPage_viewer_dhE9s on Viewer {\n  userDefinedRoles(first: 20, domainId: $domainId, name: $name) {\n    edges {\n      node {\n        id\n        name\n        userDefinedRoleId\n        domainId\n        deletedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8ebb57ee4dfd0f86e2d016e05362a8d9';
export default node;
