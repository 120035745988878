/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ThumbnailContentType = "IMAGE_JPEG" | "IMAGE_PNG" | "%future added value";
export type CourseThumbnailFormThumbnailUploadInfoQueryVariables = {
    educationContentId: string;
    contentType: ThumbnailContentType;
};
export type CourseThumbnailFormThumbnailUploadInfoQueryResponse = {
    readonly educationContent: {
        readonly thumbnailUploadInfo: {
            readonly method: string | null;
            readonly postData: ReadonlyArray<any | null> | null;
            readonly url: string | null;
        } | null;
    } | null;
};
export type CourseThumbnailFormThumbnailUploadInfoQuery = {
    readonly response: CourseThumbnailFormThumbnailUploadInfoQueryResponse;
    readonly variables: CourseThumbnailFormThumbnailUploadInfoQueryVariables;
};



/*
query CourseThumbnailFormThumbnailUploadInfoQuery(
  $educationContentId: String!
  $contentType: ThumbnailContentType!
) {
  educationContent(educationContentId: $educationContentId) {
    thumbnailUploadInfo(contentType: $contentType) {
      method
      postData
      url
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "educationContentId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "educationContentId",
    "variableName": "educationContentId"
  }
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "contentType",
      "variableName": "contentType"
    }
  ],
  "concreteType": "UploadInfo",
  "kind": "LinkedField",
  "name": "thumbnailUploadInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "method",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postData",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseThumbnailFormThumbnailUploadInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EducationContent",
        "kind": "LinkedField",
        "name": "educationContent",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CourseThumbnailFormThumbnailUploadInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EducationContent",
        "kind": "LinkedField",
        "name": "educationContent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af58396383af14664a3d3e228b2373e6",
    "id": null,
    "metadata": {},
    "name": "CourseThumbnailFormThumbnailUploadInfoQuery",
    "operationKind": "query",
    "text": "query CourseThumbnailFormThumbnailUploadInfoQuery(\n  $educationContentId: String!\n  $contentType: ThumbnailContentType!\n) {\n  educationContent(educationContentId: $educationContentId) {\n    thumbnailUploadInfo(contentType: $contentType) {\n      method\n      postData\n      url\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '748660ba3dae1d2df9ef6d8f8b105930';
export default node;
