import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  DomainUserIntegrationConfigModal,
  basicDomainUserIntegrationConfigSchema,
} from './DomainUserIntegrationConfigModal';
import { DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig as DomainUserIntegrationConfig } from './__generated__/DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig.graphql';
import {
  UpdateDomainUserIntegrationConfigInput,
  UpdateDomainUserIntegrationConfigModalMutation,
} from './__generated__/UpdateDomainUserIntegrationConfigModalMutation.graphql';
import { UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig$key } from './__generated__/UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig.graphql';

const fragment = graphql`
  fragment UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig on DomainUserIntegrationConfig {
    id
    domainUserIntegrationConfigId
    interfaceCode
    ehr {
      ehrId
    }
    domainUser {
      name
      domain {
        domainId
      }
      user {
        userId
      }
    }
  }
`;

const updateMutation = graphql`
  mutation UpdateDomainUserIntegrationConfigModalMutation(
    $input: UpdateDomainUserIntegrationConfigInput!
  ) {
    updateDomainUserIntegrationConfig(input: $input) {
      domainUserIntegrationConfig {
        ...DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig
      }
    }
  }
`;

const updateDomainUserIntegrationConfigSchema =
  basicDomainUserIntegrationConfigSchema.shape({
    domainId: string().meta({
      readOnly: true,
    }),
    userId: string().meta({
      readOnly: true,
    }),
    integrationId: string().meta({
      readOnly: true,
    }),
  });

const transformOnSubmit = (
  value: DomainUserIntegrationConfig,
): UpdateDomainUserIntegrationConfigInput => ({
  domainUserIntegrationConfigId: value.domainUserIntegrationConfigId!,
  interfaceCode: value.interfaceCode,
});

interface UpdateDomainUserIntegrationConfigModalProps
  extends ActionModalProps {
  domainUserIntegrationConfigRef: UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig$key;
}

export function UpdateDomainUserIntegrationConfigModal({
  show,
  onClose,
  domainUserIntegrationConfigRef,
}: UpdateDomainUserIntegrationConfigModalProps) {
  const domainUserIntegrationConfig = useFragment(
    fragment,
    domainUserIntegrationConfigRef,
  );

  const transformedDefaultValue = useMemo(
    () => ({
      domainId: domainUserIntegrationConfig.domainUser!.domain!.domainId,
      userId: domainUserIntegrationConfig.domainUser!.user!.userId,
      integrationId: domainUserIntegrationConfig.ehr!.ehrId,
      ...domainUserIntegrationConfig,
    }),
    [domainUserIntegrationConfig],
  );

  return (
    <DomainUserIntegrationConfigModal<UpdateDomainUserIntegrationConfigModalMutation>
      title={
        <FormattedMessage
          id="updateDomainUserIntegrationConfigModal.title"
          defaultMessage="Update Domain User Integration Config"
        />
      }
      name={transformedDefaultValue.domainUser!.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      mutation={updateMutation}
      schema={updateDomainUserIntegrationConfigSchema}
      defaultValue={transformedDefaultValue}
      transformOnSubmit={transformOnSubmit}
    />
  );
}
