/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DomainsGridRefetchQueryVariables = {
    count?: number | null | undefined;
    cursor?: string | null | undefined;
    id?: Array<string> | null | undefined;
    subdomainLabel?: Array<string> | null | undefined;
};
export type DomainsGridRefetchQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"DomainsGrid_viewer">;
    } | null;
};
export type DomainsGridRefetchQuery = {
    readonly response: DomainsGridRefetchQueryResponse;
    readonly variables: DomainsGridRefetchQueryVariables;
};



/*
query DomainsGridRefetchQuery(
  $count: Int = 20
  $cursor: String
  $id: [String!]
  $subdomainLabel: [String!]
) {
  viewer {
    ...DomainsGrid_viewer_4j6eWw
    id
  }
}

fragment DomainsGrid_viewer_4j6eWw on Viewer {
  domains(first: $count, after: $cursor, id: $id, subdomainLabel: $subdomainLabel) {
    edges {
      node {
        id
        domainId
        createdAt
        deletedAt
        subdomainLabel
        name
        enableButterflyLogin
        enableSamlLogin
        enableSharedDeviceCodeLogin
        samlEmailAttributeName
        samlEmailAttributeNameRaw
        samlNicknameAttributeName
        samlNicknameAttributeNameRaw
        cognitoClientId
        inactivityTimeoutSeconds
        requiredAndroidCapabilities
        sharedDeviceKey
        numUsers
        maxNumSeats
        numAvailableSeats
        country
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subdomainLabel"
  }
],
v1 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v2 = {
  "kind": "Variable",
  "name": "subdomainLabel",
  "variableName": "subdomainLabel"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainsGridRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "DomainsGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainsGridRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "DomainConnection",
            "kind": "LinkedField",
            "name": "domains",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Domain",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subdomainLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableButterflyLogin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableSamlLogin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableSharedDeviceCodeLogin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "samlEmailAttributeName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "samlEmailAttributeNameRaw",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "samlNicknameAttributeName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "samlNicknameAttributeNameRaw",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cognitoClientId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "inactivityTimeoutSeconds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requiredAndroidCapabilities",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sharedDeviceKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numUsers",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxNumSeats",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numAvailableSeats",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [
              "id",
              "subdomainLabel"
            ],
            "handle": "connection",
            "key": "DomainsGrid_domains",
            "kind": "LinkedHandle",
            "name": "domains"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab176655c4567b1f64e30ac28e997475",
    "id": null,
    "metadata": {},
    "name": "DomainsGridRefetchQuery",
    "operationKind": "query",
    "text": "query DomainsGridRefetchQuery(\n  $count: Int = 20\n  $cursor: String\n  $id: [String!]\n  $subdomainLabel: [String!]\n) {\n  viewer {\n    ...DomainsGrid_viewer_4j6eWw\n    id\n  }\n}\n\nfragment DomainsGrid_viewer_4j6eWw on Viewer {\n  domains(first: $count, after: $cursor, id: $id, subdomainLabel: $subdomainLabel) {\n    edges {\n      node {\n        id\n        domainId\n        createdAt\n        deletedAt\n        subdomainLabel\n        name\n        enableButterflyLogin\n        enableSamlLogin\n        enableSharedDeviceCodeLogin\n        samlEmailAttributeName\n        samlEmailAttributeNameRaw\n        samlNicknameAttributeName\n        samlNicknameAttributeNameRaw\n        cognitoClientId\n        inactivityTimeoutSeconds\n        requiredAndroidCapabilities\n        sharedDeviceKey\n        numUsers\n        maxNumSeats\n        numAvailableSeats\n        country\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '22b7aeef3bc5a0a1e798c81cea97fdb8';
export default node;
