import React from 'react';

import Route from 'components/Route';

import systemDefinedRolesRoutes from './systemDefinedRoles';
import userDefinedRolesRoutes from './userDefinedRoles';

export default (
  <Route path="access-roles">
    {systemDefinedRolesRoutes}
    {userDefinedRolesRoutes}
  </Route>
);
