/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InviteUserInput = {
    organizationId: string;
    email: string;
    roleId?: string | null | undefined;
    makeOrganizationAdmin: boolean;
    clientMutationId?: string | null | undefined;
};
export type CreateInviteModalMutationVariables = {
    input: InviteUserInput;
};
export type CreateInviteModalMutationResponse = {
    readonly inviteUser: {
        readonly organizationInvite: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"InvitesGrid_organizationInvite">;
        } | null;
    } | null;
};
export type CreateInviteModalMutation = {
    readonly response: CreateInviteModalMutationResponse;
    readonly variables: CreateInviteModalMutationVariables;
};



/*
mutation CreateInviteModalMutation(
  $input: InviteUserInput!
) {
  inviteUser(input: $input) {
    organizationInvite {
      id
      ...InvitesGrid_organizationInvite
    }
  }
}

fragment InvitesGrid_organizationInvite on OrganizationInvite {
  id
  organizationInviteId
  email
  roleId
  makeOrganizationAdmin
  createdAt
  deletedAt
  organization {
    organizationId
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInviteModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUserPayload",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationInvite",
            "kind": "LinkedField",
            "name": "organizationInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "InvitesGrid_organizationInvite"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInviteModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteUserPayload",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationInvite",
            "kind": "LinkedField",
            "name": "organizationInvite",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationInviteId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "makeOrganizationAdmin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "592590bc080badca79de37d252b4b774",
    "id": null,
    "metadata": {},
    "name": "CreateInviteModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateInviteModalMutation(\n  $input: InviteUserInput!\n) {\n  inviteUser(input: $input) {\n    organizationInvite {\n      id\n      ...InvitesGrid_organizationInvite\n    }\n  }\n}\n\nfragment InvitesGrid_organizationInvite on OrganizationInvite {\n  id\n  organizationInviteId\n  email\n  roleId\n  makeOrganizationAdmin\n  createdAt\n  deletedAt\n  organization {\n    organizationId\n    name\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f82b4930c572b50102dd279d42a1eb76';
export default node;
