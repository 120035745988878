import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';
import createUpdater from 'utils/createUpdater';

import { PlaceOfWorkModal, placeOfWorkSchema } from './PlaceOfWorkModal';
import { CreatePlaceOfWorkModalMutation } from './__generated__/CreatePlaceOfWorkModalMutation.graphql';
import { CreatePlaceOfWorkModal_viewer$key } from './__generated__/CreatePlaceOfWorkModal_viewer.graphql';

const createMutation = graphql`
  mutation CreatePlaceOfWorkModalMutation($input: CreatePlaceOfWorkInput!) {
    createPlaceOfWork(input: $input) {
      placeOfWork {
        id
        ...PlacesOfWorkGrid_placeOfWork
      }
    }
  }
`;

const fragment = graphql`
  fragment CreatePlaceOfWorkModal_viewer on Viewer {
    id
  }
`;

interface CreatePlaceOfWorkModalProps extends ActionModalProps {
  viewerRef: CreatePlaceOfWorkModal_viewer$key;
}

export function CreatePlaceOfWorkModal({
  viewerRef,
  ...props
}: CreatePlaceOfWorkModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  return (
    <PlaceOfWorkModal<CreatePlaceOfWorkModalMutation>
      title={
        <FormattedMessage
          id="createPlaceOfWorkModal.title"
          defaultMessage="Create Place of Work"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={placeOfWorkSchema}
      mutation={createMutation}
      updater={createUpdater({
        connectionKey: 'PlacesOfWorkGrid_placesOfWork',
        getNode: ({ createPlaceOfWork }) => createPlaceOfWork!.placeOfWork!,
        connectionContainerId: viewer.id,
      })}
      {...props}
    />
  );
}
