/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiagnosticTestResultDetailPage_diagnosticTestResult = {
    readonly id: string;
    readonly diagnosticTestResultId: string | null;
    readonly deviceProductionId: string | null;
    readonly createdAt: string | null;
    readonly testVersion: number | null;
    readonly results: any | null;
    readonly " $refType": "DiagnosticTestResultDetailPage_diagnosticTestResult";
};
export type DiagnosticTestResultDetailPage_diagnosticTestResult$data = DiagnosticTestResultDetailPage_diagnosticTestResult;
export type DiagnosticTestResultDetailPage_diagnosticTestResult$key = {
    readonly " $data"?: DiagnosticTestResultDetailPage_diagnosticTestResult$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DiagnosticTestResultDetailPage_diagnosticTestResult">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiagnosticTestResultDetailPage_diagnosticTestResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnosticTestResultId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceProductionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "results",
      "storageKey": null
    }
  ],
  "type": "DiagnosticTestResult",
  "abstractKey": null
};
(node as any).hash = '5ffd3570928b553e48bbf902baf5c835';
export default node;
