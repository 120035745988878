/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DiagnosticTestResultsGrid_diagnosticTestResult = {
    readonly id: string;
    readonly diagnosticTestResultId: string | null;
    readonly deviceProductionId: string | null;
    readonly createdAt: string | null;
    readonly testVersion: number | null;
    readonly results: any | null;
    readonly " $refType": "DiagnosticTestResultsGrid_diagnosticTestResult";
};
export type DiagnosticTestResultsGrid_diagnosticTestResult$data = DiagnosticTestResultsGrid_diagnosticTestResult;
export type DiagnosticTestResultsGrid_diagnosticTestResult$key = {
    readonly " $data"?: DiagnosticTestResultsGrid_diagnosticTestResult$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DiagnosticTestResultsGrid_diagnosticTestResult">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiagnosticTestResultsGrid_diagnosticTestResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnosticTestResultId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceProductionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "results",
      "storageKey": null
    }
  ],
  "type": "DiagnosticTestResult",
  "abstractKey": null
};
(node as any).hash = '87ca9ec5789e02a426d5aabcabc920aa';
export default node;
