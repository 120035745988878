import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import CustomerModal, { customerSchema } from './CustomerModal';
import { CreateCustomerModal_viewer$key } from './__generated__/CreateCustomerModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateCustomerModal_CreateMutation($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customerEdge {
        node {
          id
          ...CustomersPage_customer
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateCustomerModal_viewer on Viewer {
    id
  }
`;

interface CreateCustomerModalProps extends ActionModalProps {
  viewerRef: CreateCustomerModal_viewer$key;
}

export function CreateCustomerModal({
  viewerRef,
  ...props
}: CreateCustomerModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'CustomersGrid_customers',
        rootFieldName: 'createCustomer',
        edgeName: 'customerEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <CustomerModal
      {...props}
      title={
        <FormattedMessage
          id="createCustomerModal.title"
          defaultMessage="Create Customer"
        />
      }
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={customerSchema}
      mutation={createMutation}
      updater={updater}
    />
  );
}
