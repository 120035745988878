/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type OrganizationsGridRefetchQueryVariables = {
    count?: number | null | undefined;
    cursor?: string | null | undefined;
    customerId?: Array<string> | null | undefined;
    domainId?: Array<string> | null | undefined;
    id?: Array<string> | null | undefined;
    name?: Array<string> | null | undefined;
    slug?: Array<string> | null | undefined;
    specialType?: Array<string> | null | undefined;
    stripeSubscriptionId?: Array<string> | null | undefined;
    subscriptionId?: Array<string> | null | undefined;
};
export type OrganizationsGridRefetchQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"OrganizationsGrid_viewer">;
    } | null;
};
export type OrganizationsGridRefetchQuery = {
    readonly response: OrganizationsGridRefetchQueryResponse;
    readonly variables: OrganizationsGridRefetchQueryVariables;
};



/*
query OrganizationsGridRefetchQuery(
  $count: Int = 20
  $cursor: String
  $customerId: [String!]
  $domainId: [String!]
  $id: [String!]
  $name: [String!]
  $slug: [String!]
  $specialType: [String!]
  $stripeSubscriptionId: [String!]
  $subscriptionId: [String!]
) {
  viewer {
    ...OrganizationsGrid_viewer_8VRWM
    id
  }
}

fragment OrganizationsGrid_viewer_8VRWM on Viewer {
  organizations(first: $count, after: $cursor, customerId: $customerId, subscriptionId: $subscriptionId, domainId: $domainId, specialType: $specialType, stripeSubscriptionId: $stripeSubscriptionId, slug: $slug, name: $name, id: $id) {
    edges {
      node {
        id
        organizationId
        name
        slug
        country
        deletedAt
        subscription {
          subscriptionId
          subscriptionEndsAt
          accessEndsAt
          hasStartedActiveSubscription
          hasCompletedTrial
          canAccessProFeatures
          isInGracePeriod
          isTeam
          billingStatus
          billingProvider
          stripeSubscriptionId
          planType
          maxNumSeats
          practiceType
          id
        }
        isActive
        customer {
          customerId
          id
        }
        domain {
          domainId
          id
        }
        dlDataMode
        dlDataModeOverride
        enterpriseCapabilityManagementEnabled
        enabledPresetIds
        enabledToolIds
        enabledCaptureModes
        enabledCapabilities
        specialType
        numAvailableSeats
        numOutstandingInvitations
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "specialType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stripeSubscriptionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriptionId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "customerId",
  "variableName": "customerId"
},
v2 = {
  "kind": "Variable",
  "name": "domainId",
  "variableName": "domainId"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v5 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
},
v6 = {
  "kind": "Variable",
  "name": "specialType",
  "variableName": "specialType"
},
v7 = {
  "kind": "Variable",
  "name": "stripeSubscriptionId",
  "variableName": "stripeSubscriptionId"
},
v8 = {
  "kind": "Variable",
  "name": "subscriptionId",
  "variableName": "subscriptionId"
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationsGridRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "OrganizationsGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationsGridRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "organizationId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Subscription",
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subscriptionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subscriptionEndsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accessEndsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasStartedActiveSubscription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasCompletedTrial",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canAccessProFeatures",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isInGracePeriod",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isTeam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingProvider",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "stripeSubscriptionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "planType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maxNumSeats",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "practiceType",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isActive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customerId",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "domain",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domainId",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dlDataMode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dlDataModeOverride",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enterpriseCapabilityManagementEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledPresetIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledToolIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledCaptureModes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledCapabilities",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specialType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numAvailableSeats",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOutstandingInvitations",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "OrganizationsGrid_organizations",
            "kind": "LinkedHandle",
            "name": "organizations"
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdb8d20fa91f9a82e3d1bd98a710803b",
    "id": null,
    "metadata": {},
    "name": "OrganizationsGridRefetchQuery",
    "operationKind": "query",
    "text": "query OrganizationsGridRefetchQuery(\n  $count: Int = 20\n  $cursor: String\n  $customerId: [String!]\n  $domainId: [String!]\n  $id: [String!]\n  $name: [String!]\n  $slug: [String!]\n  $specialType: [String!]\n  $stripeSubscriptionId: [String!]\n  $subscriptionId: [String!]\n) {\n  viewer {\n    ...OrganizationsGrid_viewer_8VRWM\n    id\n  }\n}\n\nfragment OrganizationsGrid_viewer_8VRWM on Viewer {\n  organizations(first: $count, after: $cursor, customerId: $customerId, subscriptionId: $subscriptionId, domainId: $domainId, specialType: $specialType, stripeSubscriptionId: $stripeSubscriptionId, slug: $slug, name: $name, id: $id) {\n    edges {\n      node {\n        id\n        organizationId\n        name\n        slug\n        country\n        deletedAt\n        subscription {\n          subscriptionId\n          subscriptionEndsAt\n          accessEndsAt\n          hasStartedActiveSubscription\n          hasCompletedTrial\n          canAccessProFeatures\n          isInGracePeriod\n          isTeam\n          billingStatus\n          billingProvider\n          stripeSubscriptionId\n          planType\n          maxNumSeats\n          practiceType\n          id\n        }\n        isActive\n        customer {\n          customerId\n          id\n        }\n        domain {\n          domainId\n          id\n        }\n        dlDataMode\n        dlDataModeOverride\n        enterpriseCapabilityManagementEnabled\n        enabledPresetIds\n        enabledToolIds\n        enabledCaptureModes\n        enabledCapabilities\n        specialType\n        numAvailableSeats\n        numOutstandingInvitations\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2e78e2372cd44c4398bc791a6b102da4';
export default node;
