import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import PlaceOfWorkDetailPage from './components/PlaceOfWorkDetailPage';
import PlacesOfWorkGrid from './components/PlacesOfWorkGrid';
import PlacesOfWorkPage from './components/PlacesOfWorkPage';

export default (
  <Route path="place-of-work">
    <Route
      Component={PlacesOfWorkPage}
      query={graphql`
        query placesOfWork_PlacesOfWorkPage_Query {
          viewer {
            ...PlacesOfWorkPage_viewer
          }
        }
      `}
    >
      <Route
        Component={PlacesOfWorkGrid}
        query={graphql`
          query placesOfWork_PlacesOfWorkGrid_Query($name: [String!]) {
            viewer {
              ...PlacesOfWorkGrid_viewer @arguments(name: $name)
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          name: location.query.name,
        })}
      />
    </Route>
    <Route
      path=":placeOfWorkId"
      Component={PlaceOfWorkDetailPage}
      query={graphql`
        query placesOfWork_PlaceOfWorkDetailPage_Query(
          $placeOfWorkId: String!
        ) {
          placeOfWork(placeOfWorkId: $placeOfWorkId) {
            ...PlaceOfWorkDetailPage_placeOfWork
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.placeOfWork) throw new HttpError(404);
      }}
    />
  </Route>
);
