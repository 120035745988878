/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type routeConfig_RootPage_QueryVariables = {};
export type routeConfig_RootPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"RootPage_viewer">;
    } | null;
};
export type routeConfig_RootPage_Query = {
    readonly response: routeConfig_RootPage_QueryResponse;
    readonly variables: routeConfig_RootPage_QueryVariables;
};



/*
query routeConfig_RootPage_Query {
  viewer {
    ...RootPage_viewer
    id
  }
}

fragment PermissionsProvider_viewer on Viewer {
  email
  adminRoles
}

fragment RootPage_viewer on Viewer {
  ...PermissionsProvider_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_RootPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RootPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routeConfig_RootPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adminRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cfe2bf69f10076972a5479c5db967d0",
    "id": null,
    "metadata": {},
    "name": "routeConfig_RootPage_Query",
    "operationKind": "query",
    "text": "query routeConfig_RootPage_Query {\n  viewer {\n    ...RootPage_viewer\n    id\n  }\n}\n\nfragment PermissionsProvider_viewer on Viewer {\n  email\n  adminRoles\n}\n\nfragment RootPage_viewer on Viewer {\n  ...PermissionsProvider_viewer\n}\n"
  }
};
(node as any).hash = '7ff9f0f9e3da40e9b00434e991f1229f';
export default node;
