import { defineMessages } from 'react-intl';

export default defineMessages({
  productionId: {
    id: 'butterflyDevicesTable.heading.productionId',
    defaultMessage: 'Production ID',
  },
  registeredAt: {
    id: 'butterflyDevicesTable.heading.registeredAt',
    defaultMessage: 'Registered At',
  },
  isEnabled: {
    id: 'butterflyDevicesTable.heading.isEnabled',
    defaultMessage: 'Is Enabled',
  },
  disabledStatusMessage: {
    id: 'butterflyDevicesTable.heading.disabledStatusMessage',
    defaultMessage: 'Disabled Status Message',
  },
  lostAt: {
    id: 'butterflyDevicesTable.heading.lostAt',
    defaultMessage: 'Lost At',
  },
  stolenAt: {
    id: 'butterflyDevicesTable.heading.stolenAt',
    defaultMessage: 'Stolen At',
  },
  recallId: {
    id: 'butterflyDevicesTable.heading.recallId',
    defaultMessage: 'Recall ID',
  },
  hasActiveDiagnosticTestRequest: {
    id: 'butterflyDevicesTable.heading.hasActiveDiagnosticTestRequest',
    defaultMessage: 'Has Active Diagnostic Test Request',
  },
  lastDiagnosticTestRequestMinTestVersion: {
    id: 'butterflyDevicesTable.heading.lastDiagnosticTestRequestMinTestVersion',
    defaultMessage: 'Last Diagnostic Test Request Min Test Version',
  },
});
