/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SpecialType = "DEMO" | "INTERNAL" | "NONE" | "TEST" | "%future added value";
export type UpdateOrganizationInput = {
    organizationId: string;
    name?: string | null | undefined;
    slug?: string | null | undefined;
    specialType?: SpecialType | null | undefined;
    enterpriseCapabilityManagementEnabled?: boolean | null | undefined;
    enabledPresetIds?: Array<string | null> | null | undefined;
    enabledToolIds?: Array<string | null> | null | undefined;
    enabledCaptureModes?: Array<string | null> | null | undefined;
    enabledCapabilities?: Array<string | null> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type EnterpriseCapabilitiesAction_UpdateMutationVariables = {
    input: UpdateOrganizationInput;
};
export type EnterpriseCapabilitiesAction_UpdateMutationResponse = {
    readonly updateOrganization: {
        readonly organization: {
            readonly " $fragmentRefs": FragmentRefs<"EnterpriseCapabilitiesActionOrganization">;
        } | null;
    } | null;
};
export type EnterpriseCapabilitiesAction_UpdateMutation = {
    readonly response: EnterpriseCapabilitiesAction_UpdateMutationResponse;
    readonly variables: EnterpriseCapabilitiesAction_UpdateMutationVariables;
};



/*
mutation EnterpriseCapabilitiesAction_UpdateMutation(
  $input: UpdateOrganizationInput!
) {
  updateOrganization(input: $input) {
    organization {
      ...EnterpriseCapabilitiesActionOrganization
      id
    }
  }
}

fragment EnterpriseCapabilitiesActionOrganization on Organization {
  id
  organizationId
  name
  slug
  country
  deletedAt
  subscription {
    subscriptionId
    subscriptionEndsAt
    accessEndsAt
    hasStartedActiveSubscription
    hasCompletedTrial
    canAccessProFeatures
    isInGracePeriod
    isTeam
    billingStatus
    billingProvider
    stripeSubscriptionId
    planType
    maxNumSeats
    practiceType
    id
  }
  isActive
  customer {
    customerId
    id
  }
  domain {
    domainId
    id
  }
  dlDataMode
  dlDataModeOverride
  enterpriseCapabilityManagementEnabled
  enabledPresetIds
  enabledToolIds
  enabledCaptureModes
  enabledCapabilities
  specialType
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnterpriseCapabilitiesAction_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationPayload",
        "kind": "LinkedField",
        "name": "updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EnterpriseCapabilitiesActionOrganization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnterpriseCapabilitiesAction_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationPayload",
        "kind": "LinkedField",
        "name": "updateOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionEndsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accessEndsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasStartedActiveSubscription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCompletedTrial",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canAccessProFeatures",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInGracePeriod",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTeam",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billingStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billingProvider",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stripeSubscriptionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "planType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxNumSeats",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "practiceType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dlDataMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dlDataModeOverride",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enterpriseCapabilityManagementEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledPresetIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledToolIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledCaptureModes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabledCapabilities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53f11195cdafc772131598f9069e5932",
    "id": null,
    "metadata": {},
    "name": "EnterpriseCapabilitiesAction_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation EnterpriseCapabilitiesAction_UpdateMutation(\n  $input: UpdateOrganizationInput!\n) {\n  updateOrganization(input: $input) {\n    organization {\n      ...EnterpriseCapabilitiesActionOrganization\n      id\n    }\n  }\n}\n\nfragment EnterpriseCapabilitiesActionOrganization on Organization {\n  id\n  organizationId\n  name\n  slug\n  country\n  deletedAt\n  subscription {\n    subscriptionId\n    subscriptionEndsAt\n    accessEndsAt\n    hasStartedActiveSubscription\n    hasCompletedTrial\n    canAccessProFeatures\n    isInGracePeriod\n    isTeam\n    billingStatus\n    billingProvider\n    stripeSubscriptionId\n    planType\n    maxNumSeats\n    practiceType\n    id\n  }\n  isActive\n  customer {\n    customerId\n    id\n  }\n  domain {\n    domainId\n    id\n  }\n  dlDataMode\n  dlDataModeOverride\n  enterpriseCapabilityManagementEnabled\n  enabledPresetIds\n  enabledToolIds\n  enabledCaptureModes\n  enabledCapabilities\n  specialType\n}\n"
  }
};
})();
(node as any).hash = '036f22eac642c44b136d2a400554f8e5';
export default node;
