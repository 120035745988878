/* eslint-disable no-param-reassign */
import Form from '@bfly/ui2/Form';
import Text from '@bfly/ui2/Text';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { boolean, object, string } from 'yup';

import Alert from 'components/Alert';
import DropdownListWithFreeformField from 'components/DropdownListWithFreeformField';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { emailMessages } from 'messages/formMessages';

import {
  domainUserDomainIdMessages,
  hasTableauDashboardAccess,
  integrationFirstName,
  integrationLastName,
  integrationMiddleName,
} from '../messages/domainUserCreation';
import {
  userEmailMessages,
  userIdMessages,
  userNameMessages,
  userPasswordMessages,
  userPhoneNumberMessages,
  userPlaceOfWorkFreeformMessages,
  userSpecialtyKeyMessages,
} from '../messages/userCreation';

const specialtyKeys = [
  'ADDICTION_MEDICINE',
  'ADMINISTRATION',
  'ADOLESCENT_MEDICINE',
  'ADULT_MEDICINE',
  'AEROSPACE_MEDICINE',
  'ALLERGYIMMUNOLOGY',
  'ALLERGYIMMUNOLOGY_PEDIATRIC',
  'ANESTHESIOLOGY_ANESTHESIOLOGIST',
  'ANESTHESIOLOGY_ANESTHESIOLOGIST_ASSISTANT',
  'ANESTHESIOLOGY_CERTIFIED_REGISTERED_NURSE_ANESTHETIST',
  'ANESTHESIOLOGY_PEDIATRIC_ANESTHESIOLOGIST',
  'AUDIOLOGIST',
  'CARDIOLOGY_CARDIAC_ELECTROPHYSIOLOGY',
  'CARDIOLOGY_CARDIAC_SURGERY',
  'CARDIOLOGY_CARDIOLOGIST',
  'CARDIOLOGY_INTERVENTIONAL_CARDIOLOGY',
  'CARDIOLOGY_PEDIATRIC_CARDIOLOGIST',
  'CARDIOLOGY_PERIPHERAL_VASCULAR_DISEASE',
  'CHIROPRACTIC',
  'CRITICAL_CARE_INTENSIVISTS',
  'CRITICAL_CARE_INTENSIVISTS_PEDIATRIC',
  'DERMATOLOGY',
  'DIETITIANNUTRITION_PROFESSIONAL',
  'EMERGENCY_MEDICINE',
  'EMERGENCY_MEDICINE_PEDIATRIC',
  'EMS_AMBULANCE',
  'ENDOCRINOLOGY',
  'ENDOCRINOLOGY_PEDIATRIC',
  'ENDOCRINOLOGY_REPRODUCTIVE',
  'FAMILY_PRACTICE',
  'GASTROENTEROLOGY',
  'GASTROENTEROLOGY_PEDIATRIC',
  'GENERAL_PRACTICE',
  'GERIATRIC_MEDICINE',
  'HEMATOLOGY',
  'HOME_HEALTH_AIDE',
  'HOSPICE_AND_PALLIATIVE_CARE',
  'HOSPITALIST',
  'INFECTIOUS_DISEASE',
  'INFECTIOUS_DISEASES_PEDIATRIC',
  'INTERNAL_MEDICINE',
  'MEDICAL_EDUCATION',
  'MEDICAL_STUDENT',
  'NEONATALPERINATAL_MEDICINE',
  'NEPHROLOGY',
  'NEPHROLOGY_PEDIATRIC',
  'NEUROLOGY',
  'NURSE_CERTIFIED_CLINICAL_NURSE_SPECIALIST',
  'NURSE_CERTIFIED_NURSE_MIDWIFE',
  'NURSE_NURSE_PRACTITIONER',
  'OBSTETRICSGYNECOLOGY',
  'OBSTETRICSGYNECOLOGY_MATERNAL_FETAL_MEDICINE',
  'OCCUPATIONAL_MEDICINE',
  'ONCOLOGY_GYNECOLOGICALONCOLOGY',
  'ONCOLOGY_HEMATOLOGYONCOLOGY',
  'ONCOLOGY_MEDICAL_ONCOLOGY',
  'ONCOLOGY_PEDIATRIC_HEMATOLOGYONCOLOGY',
  'OPHTHALMOLOGY',
  'OPTOMETRY',
  'OSTEOPATHIC_MANIPULATIVE_MEDICINE',
  'OTOLARYNGOLOGY',
  'OTOLARYNGOLOGY_PEDIATRIC',
  'PAIN_MANAGEMENT',
  'PAIN_MANAGEMENT_INTERVENTIONAL_PAIN_MANAGEMENT',
  'PATHOLOGY',
  'PATHOLOGY_CYTOPATHOLOGY',
  'PATHOLOGY_FORENSIC',
  'PEDIATRIC_MEDICINE',
  'PEDIATRIC_MEDICINE_DEVELOPMENTAL_BEHAVIORAL',
  'PHYSICAL_MEDICINE_AND_REHABILITATION',
  'PHYSICIAN_ASSISTANT',
  'PODIATRY',
  'PREVENTIVE_MEDICINE',
  'PSYCHIATRY',
  'PSYCHIATRY_ADDICTION',
  'PSYCHIATRY_FORENSIC',
  'PSYCHIATRY_GERIATRIC_PSYCHIATRY',
  'PSYCHIATRY_NEUROPSYCHIATRY',
  'PSYCHOLOGY_CLINICAL_NEUROPSYCHOLOGIST',
  'PSYCHOLOGY_CLINICAL_PSYCHOLOGIST',
  'PSYCHOLOGY_PSYCHOLOGIST',
  'PSYCHOSOMATIC_MEDICINE',
  'PULMONARY_DISEASE',
  'PULMONARY_DISEASE_PEDIATRIC',
  'RADIOLOGY_DIAGNOSTIC_RADIOLOGY',
  'RADIOLOGY_INTERVENTIONAL_RADIOLOGY',
  'RADIOLOGY_NEURORADIOLOGY',
  'RADIOLOGY_NUCLEAR_MEDICINE',
  'RADIOLOGY_PEDIATRIC_RADIOLOGY',
  'RADIOLOGY_RADIATION_ONCOLOGY',
  'RADIOLOGY_THERAPEUTIC_RADIOLOGY',
  'REHABILITATION_MEDICINE_PEDIATRIC',
  'RHEUMATOLOGY',
  'RHEUMATOLOGY_PEDIATRIC',
  'SLEEP_MEDICINE',
  'SOCIAL_WORKER_LICENSED_CLINICAL_SOCIAL_WORKER',
  'SPORTS_MEDICINE',
  'SURGERY_COLORECTAL_SURGERY_FORMERLY_PROCTOLOGY',
  'SURGERY_FOOT_AND_ANKLE',
  'SURGERY_GENERAL_SURGERY',
  'SURGERY_HAND_SURGERY',
  'SURGERY_MAXILLOFACIAL_SURGERY',
  'SURGERY_NEUROSURGERY',
  'SURGERY_ORAL_AND_MAXILLOFACIAL_SURGERY',
  'SURGERY_ORAL_SURGERY_DENTISTS_ONLY',
  'SURGERY_ORTHOPEDIC_SPINE_SURGERY',
  'SURGERY_ORTHOPEDIC_SURGERY',
  'SURGERY_OTOLARYNGOLOGYFACIAL_PLASTIC_SURGERY',
  'SURGERY_PEDIATRIC_ORTHOPEDIC_SURGERY',
  'SURGERY_PEDIATRIC_SURGERY',
  'SURGERY_PLASTIC_AND_RECONSTRUCTIVE_SURGERY',
  'SURGERY_SURGICAL_ONCOLOGY',
  'SURGERY_THORACIC_SURGERY',
  'SURGERY_TRAUMA',
  'SURGERY_VASCULAR_SURGERY',
  'THERAPY_MASSAGE_THERAPIST',
  'THERAPY_OCCUPATIONAL_THERAPIST',
  'THERAPY_PHYSICAL_THERAPIST',
  'THERAPY_SPEECH_LANGUAGE_PATHOLOGIST',
  'UROLOGY',
  'UROLOGY_PEDIATRIC',
  'VETERINARY_COMPANION_ANIMAL',
  'VETERINARY_EQUINE',
  'VETERINARY_MIXED',
  'VETERINARY_STUDENT',
  'VETERINARY_SURGERY',
];

export const DOMAIN_USER_TYPES = ['ADMIN', 'USER'];

const domainUserShape = {
  integrationDisplayNameFirst: string().nullable(),
  integrationDisplayNameMiddle: string().nullable(),
  integrationDisplayNameLast: string().nullable(),
  isNurse: boolean().nullable(),
  canFinalize: boolean().transform(Boolean),
  canQa: boolean().transform(Boolean),
  hasTableauDashboardAccess: boolean().nullable(),
};

export const basicUserSchema = object({
  userId: string().nullable(),
  name: string()
    .required(userNameMessages.required)
    .min(2, userNameMessages.length)
    .trim(),
  email: string()
    .email(emailMessages.validation)
    .required(userEmailMessages.required)
    .trim(),
  specialtyKey: object({
    key: string().required(),
    message: string().required(),
  })
    .nullable()
    .default(null)
    .when('specialtyFreeform', {
      is: null,
      then: (field) => field.required(userSpecialtyKeyMessages.required),
    }),
  specialtyFreeform: string().trim().nullable().default(null),
  placeOfWorkFreeform: string().nullable(),
  phoneNumber: string()
    .nullable()
    .matches(/^\+\d{10,15}$/, {
      message: userPhoneNumberMessages.matches,
    }),

  domainId: string().nullable(),
  ...domainUserShape,
});

function transformSpecialtyKey(key: string) {
  return (
    key && {
      key,
      message: startCase(key.toLowerCase()),
    }
  );
}

export const domainUserFields = Object.keys(domainUserShape);

const specialtyList = specialtyKeys.map(transformSpecialtyKey);

export function UserModal<TMutation extends MutationParameters>({
  defaultValue,
  isImplicitPublicUser,
  ...props
}: ResourceModalProps<TMutation> & {
  isImplicitPublicUser?: boolean;
}) {
  const isCreate = !defaultValue?.flatUserId;
  const { formatMessage } = useIntl();
  const transformedDefaultValue = useMemo(() => {
    let { specialtyKey } = (defaultValue as Record<string, unknown>) || {};
    specialtyKey = specialtyKey !== 'OTHER' ? specialtyKey : null;
    return {
      ...defaultValue,
      specialtyKey: transformSpecialtyKey(specialtyKey as string),
    };
  }, [defaultValue]);

  const [value, setValue] = useState<any>(transformedDefaultValue);

  const onSubmit = useCallback(
    (values) => {
      const redundantFields = ['userId']
        .concat(!values.domainId ? domainUserFields : [])
        .concat(!isCreate ? ['domainId', 'email'] : []) // these are immutable
        .concat(
          isImplicitPublicUser ? ['name'] : ['userName', 'domainUserName'], // implicit users must update specific names
        );

      values = omit(values, redundantFields);

      return {
        ...values,
        specialtyKey: values?.specialtyKey?.key || 'OTHER',
      };
    },
    [isCreate, isImplicitPublicUser],
  );

  return (
    <ResourceModal<TMutation>
      {...props}
      value={value}
      onChange={setValue}
      transformOnSubmit={onSubmit}
    >
      {isImplicitPublicUser && (
        <Alert variant="warning" className="mb-4">
          <FormattedMessage
            id="userModal.implicitUserMessage"
            defaultMessage="
              Is user is in a legacy configuration where one user record is
              shared between an enterprise and 1 or more public organizations.

              This user should be migrated to two distinct users by engineering, in the meantime
              functionality for updating this user may be limited
            "
          />
        </Alert>
      )}
      {!isCreate && (
        <Form.FieldGroup
          disabled
          name="userId"
          placeholder={formatMessage(userIdMessages.placeholder)}
          label={<FormattedMessage {...userIdMessages.label} />}
          data-cy="userCreate-userIdField"
        />
      )}

      <Form.FieldGroup
        name="domainId"
        disabled={!isCreate}
        placeholder={formatMessage(domainUserDomainIdMessages.placeholder)}
        label={<FormattedMessage {...domainUserDomainIdMessages.label} />}
        className="name"
        data-cy="domainUserCreate-domainIdField"
      />
      {isImplicitPublicUser ? (
        <>
          <Form.FieldGroup
            name="userName"
            placeholder={formatMessage(userNameMessages.placeholder)}
            className="name"
            label={
              <FormattedMessage
                id="userModal.userNameLabel"
                defaultMessage="Public organization user name"
              />
            }
            description={
              <FormattedMessage
                id="userModal.userNameDesc"
                defaultMessage="This is the name visible to others in public organizations"
              />
            }
            data-cy="userCreate-userNameField"
          />
          <Form.FieldGroup
            name="domainUserName"
            placeholder={formatMessage(userNameMessages.placeholder)}
            label={
              <FormattedMessage
                id="userModal.domainUserNameLabel"
                defaultMessage="Domain user name"
              />
            }
            description={
              <FormattedMessage
                id="userModal.domainUserNameDesc"
                defaultMessage="This is the name visible to other members of this domain"
              />
            }
            className="name"
            data-cy="userCreate-domainUserNameField"
          />
        </>
      ) : (
        <Form.FieldGroup
          name="name"
          placeholder={formatMessage(userNameMessages.placeholder)}
          label={<FormattedMessage {...userNameMessages.label} />}
          className="name"
          data-cy="userCreate-nameField"
        />
      )}
      <Form.FieldGroup
        name="email"
        disabled={!isCreate}
        placeholder={formatMessage(userEmailMessages.placeholder)}
        label={<FormattedMessage {...userEmailMessages.label} />}
        description={<FormattedMessage {...userEmailMessages.description} />}
        data-cy="userCreate-emailField"
      />
      {isCreate && (
        <>
          <Form.FieldGroup
            name="auth0Id"
            type="text"
            label={
              <FormattedMessage
                id="userModal.auth0Id.label"
                defaultMessage="Auth0 ID"
              />
            }
            description={
              <FormattedMessage
                id="userModal.auth0Id.desc"
                defaultMessage="Provide an Auth0 ID to link the new user to an existing login"
              />
            }
            data-cy="userCreate-auth0IdField"
          />
          <Form.FieldGroup
            name="password"
            type="password"
            placeholder={formatMessage(userPasswordMessages.placeholder)}
            label={<FormattedMessage {...userPasswordMessages.label} />}
            description={
              <FormattedMessage
                id="userModal.password.desc"
                defaultMessage="Only requried when Auth0 ID is blank"
              />
            }
            data-cy="userCreate-passwordField"
          />
        </>
      )}
      <Form.Group
        label={<FormattedMessage {...userSpecialtyKeyMessages.label} />}
        data-cy="userCreate-specialtyKey"
      >
        <DropdownListWithFreeformField
          primaryFieldName="specialtyKey"
          freeformFieldName="specialtyFreeform"
          filter="contains"
          data={specialtyList}
          dataKey="key"
          textField="message"
          placeholder={userSpecialtyKeyMessages.placeholder}
          data-cy="userCreate-specialtyKey-Field"
        />
      </Form.Group>
      <Form.Group label="" data-cy="userCreate-specialtyKey-description">
        <Text variant="sm" color="subtitle">
          <FormattedMessage {...userSpecialtyKeyMessages.description} />
        </Text>
      </Form.Group>
      <Form.FieldGroup
        name="placeOfWorkFreeform"
        placeholder={formatMessage(
          userPlaceOfWorkFreeformMessages.placeholder,
        )}
        label={<FormattedMessage {...userPlaceOfWorkFreeformMessages.label} />}
        description={
          <FormattedMessage {...userPlaceOfWorkFreeformMessages.description} />
        }
        data-cy="userCreate-placeOfWorkFreeformField"
      />
      <Form.FieldGroup
        name="phoneNumber"
        type="phone"
        placeholder={formatMessage(userPhoneNumberMessages.placeholder)}
        label={<FormattedMessage {...userPhoneNumberMessages.label} />}
        description={
          <FormattedMessage {...userPhoneNumberMessages.description} />
        }
        data-cy="userCreate-phoneNumberField"
      />
      {value.domainId && (
        <Form.FieldSet
          legend={
            <FormattedMessage
              id="userModal.domainUsers"
              defaultMessage="Enterprise fields"
            />
          }
        >
          <Form.FieldGroup
            name="integrationDisplayNameFirst"
            placeholder={formatMessage(integrationFirstName.placeholder)}
            label={<FormattedMessage {...integrationFirstName.label} />}
            data-cy="domainUserCreate-integrationFirstName"
          />
          <Form.FieldGroup
            name="integrationDisplayNameMiddle"
            placeholder={formatMessage(integrationMiddleName.placeholder)}
            label={<FormattedMessage {...integrationMiddleName.label} />}
            data-cy="domainUserCreate-integrationMiddleName"
          />
          <Form.FieldGroup
            name="integrationDisplayNameLast"
            placeholder={formatMessage(integrationLastName.placeholder)}
            label={<FormattedMessage {...integrationLastName.label} />}
            data-cy="domainUserCreate-integrationLastName"
          />
          <Form.FieldGroup
            name="hasTableauDashboardAccess"
            placeholder={formatMessage(hasTableauDashboardAccess.placeholder)}
            label={<FormattedMessage {...hasTableauDashboardAccess.label} />}
            data-cy="domainUserCreate-hasTableauDashboardAccess"
          />
        </Form.FieldSet>
      )}
    </ResourceModal>
  );
}
