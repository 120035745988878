import { practiceTypeMessages } from 'messages/formMessages';
import {
  billingProviderMessages,
  billingStatusMessages,
  dlDataModeOverrideMessages,
  planTypeMessages,
  specialTypeMessages,
} from 'messages/organizationActions';

export const specialTypesData = [
  { name: specialTypeMessages.none.defaultMessage, value: 'NONE' },
  { name: specialTypeMessages.test.defaultMessage, value: 'TEST' },
  { name: specialTypeMessages.demo.defaultMessage, value: 'DEMO' },
  { name: specialTypeMessages.internal.defaultMessage, value: 'INTERNAL' },
];

export const dlDataModeOverrideData = [
  { name: dlDataModeOverrideMessages.noOverride.defaultMessage, value: null },
  {
    name: dlDataModeOverrideMessages.include.defaultMessage,
    value: 'INCLUDE',
  },
  {
    name: dlDataModeOverrideMessages.partial.defaultMessage,
    value: 'PARTIAL',
  },
  {
    name: dlDataModeOverrideMessages.exclude.defaultMessage,
    value: 'EXCLUDE',
  },
];

export const planTypeData = [
  {
    name: planTypeMessages.none.defaultMessage,
    value: 'NONE',
  },
  {
    name: planTypeMessages.basic.defaultMessage,
    value: 'BASIC',
  },
  {
    name: planTypeMessages.proTrial.defaultMessage,
    value: 'PRO_TRIAL',
  },
  {
    name: planTypeMessages.proCustom.defaultMessage,
    value: 'PRO_CUSTOM',
  },
  {
    name: planTypeMessages.individual.defaultMessage,
    value: 'INDIVIDUAL',
  },
  {
    name: planTypeMessages.individualForever.defaultMessage,
    value: 'INDIVIDUAL_FOREVER',
  },
  {
    name: planTypeMessages.team.defaultMessage,
    value: 'TEAM',
  },
  {
    name: planTypeMessages.education.defaultMessage,
    value: 'EDUCATION',
  },
  {
    name: planTypeMessages.proThreeYear.defaultMessage,
    value: 'PRO_THREE_YEAR',
  },
  {
    name: planTypeMessages.enterpriseWorkflow.defaultMessage,
    value: 'ENTERPRISE_WORKFLOW',
  },
  {
    name: planTypeMessages.government.defaultMessage,
    value: 'GOVERNMENT',
  },
  {
    name: planTypeMessages.ems.defaultMessage,
    value: 'EMS',
  },
  {
    name: planTypeMessages.clinic.defaultMessage,
    value: 'CLINIC',
  },
  {
    name: planTypeMessages.residencyProgram.defaultMessage,
    value: 'RESIDENCY_PROGRAM',
  },
  {
    name: planTypeMessages.department.defaultMessage,
    value: 'DEPARTMENT',
  },
  {
    name: planTypeMessages.hospital.defaultMessage,
    value: 'HOSPITAL',
  },
  {
    name: planTypeMessages.medicalSchool.defaultMessage,
    value: 'MEDICAL_SCHOOL',
  },
];

export const practiceTypeData = [
  { name: practiceTypeMessages.none.defaultMessage, value: 'NONE' },
  { name: practiceTypeMessages.human.defaultMessage, value: 'HUMAN' },
  {
    name: practiceTypeMessages.veterinary.defaultMessage,
    value: 'VETERINARY',
  },
];

export const billingStatusData = [
  {
    name: billingStatusMessages.trialing.defaultMessage,
    value: 'TRIALING',
  },
  {
    name: billingStatusMessages.active.defaultMessage,
    value: 'ACTIVE',
  },
  {
    name: billingStatusMessages.pastDue.defaultMessage,
    value: 'PAST_DUE',
  },
  {
    name: billingStatusMessages.canceled.defaultMessage,
    value: 'CANCELED',
  },
  {
    name: billingStatusMessages.unpaid.defaultMessage,
    value: 'UNPAID',
  },
];

export const billingProviderData = [
  {
    name: billingProviderMessages.stripe.defaultMessage,
    value: 'STRIPE',
  },
  {
    name: billingProviderMessages.apple.defaultMessage,
    value: 'APPLE',
  },
  {
    name: billingProviderMessages.salesforce.defaultMessage,
    value: 'SALESFORCE',
  },
];
