export interface Timezone {
  value: string;
  name: string;
}

const ALLOWED_TIMEZONES: Timezone[] = [
  {
    value: 'Pacific/Midway',
    name: '(UTC-11:00) Midway Island, American Samoa',
  },
  {
    value: 'America/Adak',
    name: '(UTC-10:00) Aleutian Islands',
  },
  {
    value: 'Pacific/Honolulu',
    name: '(UTC-10:00) Hawaii',
  },
  {
    value: 'Pacific/Marquesas',
    name: '(UTC-09:30) Marquesas Islands',
  },
  {
    value: 'America/Anchorage',
    name: '(UTC-09:00) Alaska',
  },
  {
    value: 'America/Tijuana',
    name: '(UTC-08:00) Baja California',
  },
  {
    value: 'America/Los_Angeles',
    name: '(UTC-08:00) Pacific Time (US and Canada)',
  },
  {
    value: 'America/Phoenix',
    name: '(UTC-07:00) Arizona',
  },
  {
    value: 'America/Chihuahua',
    name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'America/Denver',
    name: '(UTC-07:00) Mountain Time (US and Canada)',
  },
  {
    value: 'America/Belize',
    name: '(UTC-06:00) Central America',
  },
  {
    value: 'America/Chicago',
    name: '(UTC-06:00) Central Time (US and Canada)',
  },
  {
    value: 'Pacific/Easter',
    name: '(UTC-06:00) Easter Island',
  },
  {
    value: 'America/Mexico_City',
    name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'America/Regina',
    name: '(UTC-06:00) Saskatchewan',
  },
  {
    value: 'America/Bogota',
    name: '(UTC-05:00) Bogota, Lima, Quito',
  },
  {
    value: 'America/Cancun',
    name: '(UTC-05:00) Chetumal',
  },
  {
    value: 'America/New_York',
    name: '(UTC-05:00) Eastern Time (US and Canada)',
  },
  {
    value: 'America/Port-au-Prince',
    name: '(UTC-05:00) Haiti',
  },
  {
    value: 'America/Havana',
    name: '(UTC-05:00) Havana',
  },
  {
    value: 'America/Indiana/Indianapolis',
    name: '(UTC-05:00) Indiana (East)',
  },
  {
    value: 'America/Asuncion',
    name: '(UTC-04:00) Asuncion',
  },
  {
    value: 'America/Halifax',
    name: '(UTC-04:00) Atlantic Time (Canada)',
  },
  {
    value: 'America/Caracas',
    name: '(UTC-04:00) Caracas',
  },
  {
    value: 'America/Cuiaba',
    name: '(UTC-04:00) Cuiaba',
  },
  {
    value: 'America/Manaus',
    name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  },
  {
    value: 'America/Santiago',
    name: '(UTC-04:00) Santiago',
  },
  {
    value: 'America/Grand_Turk',
    name: '(UTC-04:00) Turks and Caicos',
  },
  {
    value: 'America/St_Johns',
    name: '(UTC-03:30) Newfoundland',
  },
  {
    value: 'America/Fortaleza',
    name: '(UTC-03:00) Araguaina',
  },
  {
    value: 'America/Sao_Paulo',
    name: '(UTC-03:00) Brasilia',
  },
  {
    value: 'America/Cayenne',
    name: '(UTC-03:00) Cayenne, Fortaleza',
  },
  {
    value: 'America/Buenos_Aires',
    name: '(UTC-03:00) City of Buenos Aires',
  },
  {
    value: 'America/Godthab',
    name: '(UTC-03:00) Greenland',
  },
  {
    value: 'America/Montevideo',
    name: '(UTC-03:00) Montevideo',
  },
  {
    value: 'America/Miquelon',
    name: '(UTC-03:00) Saint Pierre and Miquelon',
  },
  {
    value: 'America/Bahia',
    name: '(UTC-03:00) Salvador',
  },
  {
    value: 'America/Noronha',
    name: '(UTC-02:00) Fernando de Noronha',
  },
  {
    value: 'Atlantic/Azores',
    name: '(UTC-01:00) Azores',
  },
  {
    value: 'Atlantic/Cape_Verde',
    name: '(UTC-01:00) Cabo Verde Islands',
  },
  {
    value: 'Africa/Casablanca',
    name: '(UTC) Casablanca',
  },
  {
    value: 'Europe/London',
    name: '(UTC) Dublin, Edinburgh, Lisbon, London',
  },
  {
    value: 'Africa/Monrovia',
    name: '(UTC) Monrovia, Reykjavik',
  },
  {
    value: 'Etc/UTC',
    name: 'UTC',
  },
  {
    value: 'Europe/Amsterdam',
    name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Europe/Belgrade',
    name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Europe/Brussels',
    name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Warsaw',
    name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Africa/Algiers',
    name: '(UTC+01:00) West Central Africa',
  },
  {
    value: 'Africa/Windhoek',
    name: '(UTC+01:00) Windhoek',
  },
  {
    value: 'Asia/Amman',
    name: '(UTC+02:00) Amman',
  },
  {
    value: 'Europe/Athens',
    name: '(UTC+02:00) Athens, Bucharest',
  },
  {
    value: 'Asia/Beirut',
    name: '(UTC+02:00) Beirut',
  },
  {
    value: 'Africa/Cairo',
    name: '(UTC+02:00) Cairo',
  },
  {
    value: 'Asia/Damascus',
    name: '(UTC+02:00) Damascus',
  },
  {
    value: 'Asia/Gaza',
    name: '(UTC+02:00) Gaza, Hebron',
  },
  {
    value: 'Africa/Harare',
    name: '(UTC+02:00) Harare, Pretoria',
  },
  {
    value: 'Europe/Helsinki',
    name: '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Asia/Jerusalem',
    name: '(UTC+02:00) Jerusalem',
  },
  {
    value: 'Europe/Kaliningrad',
    name: '(UTC+02:00) Kaliningrad',
  },
  {
    value: 'Africa/Tripoli',
    name: '(UTC+02:00) Tripoli',
  },
  {
    value: 'Asia/Baghdad',
    name: '(UTC+03:00) Baghdad',
  },
  {
    value: 'Asia/Istanbul',
    name: '(UTC+03:00) Istanbul',
  },
  {
    value: 'Asia/Kuwait',
    name: '(UTC+03:00) Kuwait, Riyadh',
  },
  {
    value: 'Europe/Minsk',
    name: '(UTC+03:00) Minsk',
  },
  {
    value: 'Europe/Moscow',
    name: '(UTC+03:00) Moscow, St. Petersburg, Volgograd',
  },
  {
    value: 'Africa/Nairobi',
    name: '(UTC+03:00) Nairobi',
  },
  {
    value: 'Asia/Tehran',
    name: '(UTC+03:30) Tehran',
  },
  {
    value: 'Asia/Muscat',
    name: '(UTC+04:00) Abu Dhabi, Muscat',
  },
  {
    value: 'Europe/Astrakhan',
    name: '(UTC+04:00) Astrakhan, Ulyanovsk',
  },
  {
    value: 'Asia/Baku',
    name: '(UTC+04:00) Baku',
  },
  {
    value: 'Europe/Samara',
    name: '(UTC+04:00) Izhevsk, Samara',
  },
  {
    value: 'Indian/Mauritius',
    name: '(UTC+04:00) Port Louis',
  },
  {
    value: 'Asia/Tbilisi',
    name: '(UTC+04:00) Tbilisi',
  },
  {
    value: 'Asia/Yerevan',
    name: '(UTC+04:00) Yerevan',
  },
  {
    value: 'Asia/Kabul',
    name: '(UTC+04:30) Kabul',
  },
  {
    value: 'Asia/Tashkent',
    name: '(UTC+05:00) Tashkent, Ashgabat',
  },
  {
    value: 'Asia/Yekaterinburg',
    name: '(UTC+05:00) Ekaterinburg',
  },
  {
    value: 'Asia/Karachi',
    name: '(UTC+05:00) Islamabad, Karachi',
  },
  {
    value: 'Asia/Kolkata',
    name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Colombo',
    name: '(UTC+05:30) Sri Jayawardenepura',
  },
  {
    value: 'Asia/Kathmandu',
    name: '(UTC+05:45) Kathmandu',
  },
  {
    value: 'Asia/Almaty',
    name: '(UTC+06:00) Astana',
  },
  {
    value: 'Asia/Dhaka',
    name: '(UTC+06:00) Dhaka',
  },
  {
    value: 'Asia/Rangoon',
    name: '(UTC+06:30) Yangon (Rangoon)',
  },
  {
    value: 'Asia/Novosibirsk',
    name: '(UTC+07:00) Novosibirsk',
  },
  {
    value: 'Asia/Bangkok',
    name: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Barnaul',
    name: '(UTC+07:00) Barnaul, Gorno-Altaysk',
  },
  {
    value: 'Asia/Hovd',
    name: '(UTC+07:00) Hovd',
  },
  {
    value: 'Asia/Krasnoyarsk',
    name: '(UTC+07:00) Krasnoyarsk',
  },
  {
    value: 'Asia/Tomsk',
    name: '(UTC+07:00) Tomsk',
  },
  {
    value: 'Asia/Chongqing',
    name: '(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
  },
  {
    value: 'Asia/Irkutsk',
    name: '(UTC+08:00) Irkutsk',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    name: '(UTC+08:00) Kuala Lumpur, Singapore',
  },
  {
    value: 'Australia/Perth',
    name: '(UTC+08:00) Perth',
  },
  {
    value: 'Asia/Taipei',
    name: '(UTC+08:00) Taipei',
  },
  {
    value: 'Asia/Ulaanbaatar',
    name: '(UTC+08:00) Ulaanbaatar',
  },
  {
    value: 'Asia/Pyongyang',
    name: '(UTC+08:30) Pyongyang',
  },
  {
    value: 'Australia/Eucla',
    name: '(UTC+08:45) Eucla',
  },
  {
    value: 'Asia/Chita',
    name: '(UTC+09:00) Chita',
  },
  {
    value: 'Asia/Tokyo',
    name: '(UTC+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Asia/Seoul',
    name: '(UTC+09:00) Seoul',
  },
  {
    value: 'Asia/Yakutsk',
    name: '(UTC+09:00) Yakutsk',
  },
  {
    value: 'Australia/Adelaide',
    name: '(UTC+09:30) Adelaide',
  },
  {
    value: 'Australia/Darwin',
    name: '(UTC+09:30) Darwin',
  },
  {
    value: 'Australia/Brisbane',
    name: '(UTC+10:00) Brisbane',
  },
  {
    value: 'Australia/Canberra',
    name: '(UTC+10:00) Canberra, Melbourne, Sydney',
  },
  {
    value: 'Pacific/Guam',
    name: '(UTC+10:00) Guam, Port Moresby',
  },
  {
    value: 'Australia/Hobart',
    name: '(UTC+10:00) Hobart',
  },
  {
    value: 'Asia/Vladivostok',
    name: '(UTC+10:00) Vladivostok',
  },
  {
    value: 'Australia/Lord_Howe',
    name: '(UTC+10:30) Lord Howe Island',
  },
  {
    value: 'Pacific/Bougainville',
    name: '(UTC+11:00) Bougainville Island',
  },
  {
    value: 'Asia/Srednekolymsk',
    name: '(UTC+11:00) Chokirdakh',
  },
  {
    value: 'Asia/Magadan',
    name: '(UTC+11:00) Magadan',
  },
  {
    value: 'Pacific/Norfolk',
    name: '(UTC+11:00) Norfolk Island',
  },
  {
    value: 'Asia/Sakhalin',
    name: '(UTC+11:00) Sakhalin',
  },
  {
    value: 'Pacific/Guadalcanal',
    name: '(UTC+11:00) Solomon Islands, New Caledonia',
  },
  {
    value: 'Asia/Anadyr',
    name: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
  },
  {
    value: 'Pacific/Auckland',
    name: '(UTC+12:00) Auckland, Wellington',
  },
  {
    value: 'Pacific/Fiji',
    name: '(UTC+12:00) Fiji Islands',
  },
  {
    value: 'Pacific/Chatham',
    name: '(UTC+12:45) Chatham Islands',
  },
  {
    value: 'Pacific/Tongatapu',
    name: "(UTC+13:00) Nuku'alofa",
  },
  {
    value: 'Pacific/Apia',
    name: '(UTC+13:00) Samoa',
  },
  {
    value: 'Pacific/Kiritimati',
    name: '(UTC+14:00) Kiritimati Island',
  },
];

export default ALLOWED_TIMEZONES;
