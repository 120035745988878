import { defineMessages } from 'react-intl';

export default defineMessages({
  code: {
    id: 'countriesTable.heading.code',
    defaultMessage: 'Code',
  },
  dataRegion: {
    id: 'countriesTable.heading.dataRegion',
    defaultMessage: 'Region',
  },
  name: {
    id: 'countriesTable.heading.name',
    defaultMessage: 'Name',
  },
});
