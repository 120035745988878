import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import DomainInviteDetailPage from './components/DomainInviteDetailPage';
import DomainInvitesGrid from './components/DomainInvitesGrid';
import DomainInvitesPage from './components/DomainInvitesPage';

export default (
  <Route path="domain-invites">
    <Route
      Component={DomainInvitesPage}
      query={graphql`
        query domainInvites_DomainInvitesPage_Query {
          viewer {
            ...DomainInvitesPage_viewer
          }
        }
      `}
    >
      <Route
        Component={DomainInvitesGrid}
        query={graphql`
          query domainInvites_DomainInvitesGrid_Query(
            $domainId: [String!]
            $email: [String!]
          ) {
            viewer {
              ...DomainInvitesGrid_viewer
                @arguments(domainId: $domainId, email: $email)
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          domainId: location.query.domainId,
          email: location.query.email,
        })}
      />
    </Route>
    <Route
      path=":userInviteId"
      Component={DomainInviteDetailPage}
      query={graphql`
        query domainInvites_DomainInviteDetailPage_Query(
          $userInviteId: String!
        ) {
          userInvite: userInvite(userInviteId: $userInviteId) {
            ...DomainInviteDetailPage_userInvite
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.userInvite) throw new HttpError(404);
      }}
    />
  </Route>
);
