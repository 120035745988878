/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ButterflyDeviceRecallsPage_butterflyDeviceRecall = {
    readonly id: string;
    readonly recallId: string | null;
    readonly message: string | null;
    readonly " $refType": "ButterflyDeviceRecallsPage_butterflyDeviceRecall";
};
export type ButterflyDeviceRecallsPage_butterflyDeviceRecall$data = ButterflyDeviceRecallsPage_butterflyDeviceRecall;
export type ButterflyDeviceRecallsPage_butterflyDeviceRecall$key = {
    readonly " $data"?: ButterflyDeviceRecallsPage_butterflyDeviceRecall$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ButterflyDeviceRecallsPage_butterflyDeviceRecall">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ButterflyDeviceRecallsPage_butterflyDeviceRecall",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recallId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "ButterflyDeviceRecall",
  "abstractKey": null
};
(node as any).hash = '7a6773bdd550af64427bfb4ec3496394';
export default node;
