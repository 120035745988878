import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import DeleteDomainUserIntegrationConfigAction from './DeleteDomainUserIntegrationConfigAction';
import { UpdateDomainUserIntegrationConfigModal } from './UpdateDomainUserIntegrationConfigModal';
import { DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig$key } from './__generated__/DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig.graphql';

const fragment = graphql`
  fragment DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig on DomainUserIntegrationConfig {
    ...UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig
    ...DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig
    id
    domainUserIntegrationConfigId
    interfaceCode
    ehr {
      ehrId
    }
    domainUser {
      domainUserId
      domain {
        domainId
      }
      user {
        userId
      }
      name
      email
    }
  }
`;

interface DomainUserIntegrationConfigDetailPageProps {
  domainUserIntegrationConfig: DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig$key;
}

export default function DomainUserIntegrationConfigDetailPage({
  domainUserIntegrationConfig,
}: DomainUserIntegrationConfigDetailPageProps) {
  const domainUserIntegrationConfigFragment = useFragment(
    fragment,
    domainUserIntegrationConfig,
  );
  const ehrId = domainUserIntegrationConfigFragment.ehr!.ehrId!;
  const domainId =
    domainUserIntegrationConfigFragment.domainUser!.domain!.domainId!;
  const userId = domainUserIntegrationConfigFragment.domainUser!.user!.userId!;

  useBreadcrumbDetector(
    pageTitles.domainUserIntegrationConfig,
    domainUserIntegrationConfigFragment.domainUserIntegrationConfigId,
  );
  const { canWrite } = usePermissionsContext();

  return (
    <PermissionsGuard resource={Resource.DOMAIN_USER_INTEGRATIONS_CONFIGS}>
      <ResourceDetailPage
        title={
          <FormattedMessage {...pageTitles.domainUserIntegrationConfig} />
        }
        name={domainUserIntegrationConfigFragment.domainUser!.name!}
        data={domainUserIntegrationConfigFragment}
        quickCopyFields={[
          'domainUserIntegrationConfigId',
          'interfaceCode',
          'ehr: ehrId',
          'domainUser: domainUserId',
          'domainUser: domain: domainId',
          'domainUser: user: userId',
          'domainUser: name',
          'domainUser: email',
        ]}
        relatedItems={
          <>
            <RelatedItem name="Domain" to={`/domains/${domainId}`}>
              <FormattedMessage
                id="domainUserIntegrationConfigDetail.relatedItem.domain"
                defaultMessage="Domain"
              />
            </RelatedItem>
            <RelatedItem name="User" to={`/users/${userId}/${domainId}`}>
              <FormattedMessage
                id="domainUserIntegrationConfigDetail.relatedItem.user"
                defaultMessage="User"
              />
            </RelatedItem>
            <RelatedItem name="Ehr" to={`/ehrs/${ehrId}`}>
              <FormattedMessage
                id="domainUserIntegrationConfigDetail.relatedItem.ehr"
                defaultMessage="Ehr"
              />
            </RelatedItem>
          </>
        }
        crudActions={
          canWrite(Resource.DOMAIN_USER_INTEGRATIONS_CONFIGS) && (
            <>
              <UpdateAction
                renderModal={(props) => (
                  <UpdateDomainUserIntegrationConfigModal
                    {...props}
                    domainUserIntegrationConfigRef={
                      domainUserIntegrationConfigFragment
                    }
                  />
                )}
              />
              <DeleteDomainUserIntegrationConfigAction
                domainUserIntegrationConfigRef={
                  domainUserIntegrationConfigFragment
                }
              />
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
