/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type customers_CustomerDetailPage_QueryVariables = {
    customerId: string;
};
export type customers_CustomerDetailPage_QueryResponse = {
    readonly customer: {
        readonly " $fragmentRefs": FragmentRefs<"CustomerDetailPage_customer">;
    } | null;
};
export type customers_CustomerDetailPage_Query = {
    readonly response: customers_CustomerDetailPage_QueryResponse;
    readonly variables: customers_CustomerDetailPage_QueryVariables;
};



/*
query customers_CustomerDetailPage_Query(
  $customerId: String!
) {
  customer(customerId: $customerId) {
    ...CustomerDetailPage_customer
    id
  }
}

fragment CustomerDetailPage_customer on Customer {
  ...UpdateCustomerModal_customer
  ...ProvisionOrganizationAction_customer
  ...ProvisionDomainAction_customer
  id
  customerId
  name
  zendeskId
  netsuiteId
  stripeId
  sfAccountId
}

fragment ProvisionDomainAction_customer on Customer {
  customerId
}

fragment ProvisionOrganizationAction_customer on Customer {
  customerId
}

fragment UpdateCustomerModal_customer on Customer {
  id
  customerId
  name
  zendeskId
  netsuiteId
  stripeId
  sfAccountId
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customerId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "customers_CustomerDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "customer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomerDetailPage_customer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "customers_CustomerDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Customer",
        "kind": "LinkedField",
        "name": "customer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "customerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zendeskId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "netsuiteId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stripeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sfAccountId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21e46b5b73d1a4aa753c012e90d0383e",
    "id": null,
    "metadata": {},
    "name": "customers_CustomerDetailPage_Query",
    "operationKind": "query",
    "text": "query customers_CustomerDetailPage_Query(\n  $customerId: String!\n) {\n  customer(customerId: $customerId) {\n    ...CustomerDetailPage_customer\n    id\n  }\n}\n\nfragment CustomerDetailPage_customer on Customer {\n  ...UpdateCustomerModal_customer\n  ...ProvisionOrganizationAction_customer\n  ...ProvisionDomainAction_customer\n  id\n  customerId\n  name\n  zendeskId\n  netsuiteId\n  stripeId\n  sfAccountId\n}\n\nfragment ProvisionDomainAction_customer on Customer {\n  customerId\n}\n\nfragment ProvisionOrganizationAction_customer on Customer {\n  customerId\n}\n\nfragment UpdateCustomerModal_customer on Customer {\n  id\n  customerId\n  name\n  zendeskId\n  netsuiteId\n  stripeId\n  sfAccountId\n}\n"
  }
};
})();
(node as any).hash = 'de73c06f6f4c12f201066d9cea92dd9c';
export default node;
