/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type BillingStatus = "ACTIVE" | "CANCELED" | "PAST_DUE" | "TRIALING" | "UNPAID" | "%future added value";
export type PlanType = "BASIC" | "CLINIC" | "DEPARTMENT" | "EDUCATION" | "EMS" | "ENTERPRISE_SECURITY" | "ENTERPRISE_WORKFLOW" | "GOVERNMENT" | "HOSPITAL" | "INDIVIDUAL" | "INDIVIDUAL_FOREVER" | "MEDICAL_SCHOOL" | "NONE" | "PRO_CUSTOM" | "PRO_THREE_YEAR" | "PRO_TRIAL" | "RESIDENCY_PROGRAM" | "TEAM" | "%future added value";
export type PracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type CreateSubscriptionInput = {
    domainId?: string | null | undefined;
    planType: PlanType;
    maxNumSeats: number;
    practiceType: PracticeType;
    subscriptionEndsAt?: string | null | undefined;
    billingStatus?: BillingStatus | null | undefined;
    billingProvider?: BillingProvider | null | undefined;
    stripeSubscriptionId?: string | null | undefined;
    sfSubscriptionId?: string | null | undefined;
    customerId: string;
    featureModuleIds?: Array<string | null> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type ProvisionSubscriptionActionMutationVariables = {
    input: CreateSubscriptionInput;
};
export type ProvisionSubscriptionActionMutationResponse = {
    readonly createSubscription: {
        readonly subscription: {
            readonly id: string;
            readonly subscriptionId: string | null;
            readonly featureModuleIds: ReadonlyArray<string | null> | null;
            readonly " $fragmentRefs": FragmentRefs<"SubscriptionsGrid_subscription">;
        } | null;
    } | null;
};
export type ProvisionSubscriptionActionMutation = {
    readonly response: ProvisionSubscriptionActionMutationResponse;
    readonly variables: ProvisionSubscriptionActionMutationVariables;
};



/*
mutation ProvisionSubscriptionActionMutation(
  $input: CreateSubscriptionInput!
) {
  createSubscription(input: $input) {
    subscription {
      id
      subscriptionId
      featureModuleIds
      ...SubscriptionsGrid_subscription
    }
  }
}

fragment SubscriptionsGrid_subscription on Subscription {
  id
  subscriptionId
  subscriptionEndsAt
  accessEndsAt
  hasStartedActiveSubscription
  hasCompletedTrial
  canAccessProFeatures
  isInGracePeriod
  isTeam
  billingStatus
  billingProvider
  stripeSubscriptionId
  sfSubscriptionId
  planType
  maxNumSeats
  practiceType
  domain {
    domainId
    id
  }
  customer {
    customerId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "featureModuleIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProvisionSubscriptionActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSubscriptionPayload",
        "kind": "LinkedField",
        "name": "createSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubscriptionsGrid_subscription"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProvisionSubscriptionActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateSubscriptionPayload",
        "kind": "LinkedField",
        "name": "createSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subscriptionEndsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessEndsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasStartedActiveSubscription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasCompletedTrial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canAccessProFeatures",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInGracePeriod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTeam",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billingStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billingProvider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stripeSubscriptionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sfSubscriptionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxNumSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "practiceType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34da5aad3f71cc45b3706815d936e8db",
    "id": null,
    "metadata": {},
    "name": "ProvisionSubscriptionActionMutation",
    "operationKind": "mutation",
    "text": "mutation ProvisionSubscriptionActionMutation(\n  $input: CreateSubscriptionInput!\n) {\n  createSubscription(input: $input) {\n    subscription {\n      id\n      subscriptionId\n      featureModuleIds\n      ...SubscriptionsGrid_subscription\n    }\n  }\n}\n\nfragment SubscriptionsGrid_subscription on Subscription {\n  id\n  subscriptionId\n  subscriptionEndsAt\n  accessEndsAt\n  hasStartedActiveSubscription\n  hasCompletedTrial\n  canAccessProFeatures\n  isInGracePeriod\n  isTeam\n  billingStatus\n  billingProvider\n  stripeSubscriptionId\n  sfSubscriptionId\n  planType\n  maxNumSeats\n  practiceType\n  domain {\n    domainId\n    id\n  }\n  customer {\n    customerId\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '81e05da145be7c552ac57343bb2ae341';
export default node;
