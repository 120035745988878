/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type organizations_OrganizationsGrid_QueryVariables = {
    customerId?: Array<string> | null | undefined;
    subscriptionId?: Array<string> | null | undefined;
    domainId?: Array<string> | null | undefined;
    specialType?: Array<string> | null | undefined;
    stripeSubscriptionId?: Array<string> | null | undefined;
    slug?: Array<string> | null | undefined;
    name?: Array<string> | null | undefined;
    id?: Array<string> | null | undefined;
};
export type organizations_OrganizationsGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"OrganizationsGrid_viewer">;
    } | null;
};
export type organizations_OrganizationsGrid_Query = {
    readonly response: organizations_OrganizationsGrid_QueryResponse;
    readonly variables: organizations_OrganizationsGrid_QueryVariables;
};



/*
query organizations_OrganizationsGrid_Query(
  $customerId: [String!]
  $subscriptionId: [String!]
  $domainId: [String!]
  $specialType: [String!]
  $stripeSubscriptionId: [String!]
  $slug: [String!]
  $name: [String!]
  $id: [String!]
) {
  viewer {
    ...OrganizationsGrid_viewer_4zfJ8f
    id
  }
}

fragment OrganizationsGrid_viewer_4zfJ8f on Viewer {
  organizations(first: 20, customerId: $customerId, subscriptionId: $subscriptionId, domainId: $domainId, specialType: $specialType, stripeSubscriptionId: $stripeSubscriptionId, slug: $slug, name: $name, id: $id) {
    edges {
      node {
        id
        organizationId
        name
        slug
        country
        deletedAt
        subscription {
          subscriptionId
          subscriptionEndsAt
          accessEndsAt
          hasStartedActiveSubscription
          hasCompletedTrial
          canAccessProFeatures
          isInGracePeriod
          isTeam
          billingStatus
          billingProvider
          stripeSubscriptionId
          planType
          maxNumSeats
          practiceType
          id
        }
        isActive
        customer {
          customerId
          id
        }
        domain {
          domainId
          id
        }
        dlDataMode
        dlDataModeOverride
        enterpriseCapabilityManagementEnabled
        enabledPresetIds
        enabledToolIds
        enabledCaptureModes
        enabledCapabilities
        specialType
        numAvailableSeats
        numOutstandingInvitations
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domainId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "specialType"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stripeSubscriptionId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscriptionId"
},
v8 = {
  "kind": "Variable",
  "name": "customerId",
  "variableName": "customerId"
},
v9 = {
  "kind": "Variable",
  "name": "domainId",
  "variableName": "domainId"
},
v10 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v11 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v12 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
},
v13 = {
  "kind": "Variable",
  "name": "specialType",
  "variableName": "specialType"
},
v14 = {
  "kind": "Variable",
  "name": "stripeSubscriptionId",
  "variableName": "stripeSubscriptionId"
},
v15 = {
  "kind": "Variable",
  "name": "subscriptionId",
  "variableName": "subscriptionId"
},
v16 = [
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "organizations_OrganizationsGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "OrganizationsGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "organizations_OrganizationsGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v16/*: any*/),
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "organizationId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Subscription",
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subscriptionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subscriptionEndsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accessEndsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasStartedActiveSubscription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasCompletedTrial",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canAccessProFeatures",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isInGracePeriod",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isTeam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingProvider",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "stripeSubscriptionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "planType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maxNumSeats",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "practiceType",
                            "storageKey": null
                          },
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isActive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "customerId",
                            "storageKey": null
                          },
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "domain",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domainId",
                            "storageKey": null
                          },
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dlDataMode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dlDataModeOverride",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enterpriseCapabilityManagementEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledPresetIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledToolIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledCaptureModes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enabledCapabilities",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specialType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numAvailableSeats",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numOutstandingInvitations",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v16/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "OrganizationsGrid_organizations",
            "kind": "LinkedHandle",
            "name": "organizations"
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc7d0420f0d6a6c657956a1705b6dde9",
    "id": null,
    "metadata": {},
    "name": "organizations_OrganizationsGrid_Query",
    "operationKind": "query",
    "text": "query organizations_OrganizationsGrid_Query(\n  $customerId: [String!]\n  $subscriptionId: [String!]\n  $domainId: [String!]\n  $specialType: [String!]\n  $stripeSubscriptionId: [String!]\n  $slug: [String!]\n  $name: [String!]\n  $id: [String!]\n) {\n  viewer {\n    ...OrganizationsGrid_viewer_4zfJ8f\n    id\n  }\n}\n\nfragment OrganizationsGrid_viewer_4zfJ8f on Viewer {\n  organizations(first: 20, customerId: $customerId, subscriptionId: $subscriptionId, domainId: $domainId, specialType: $specialType, stripeSubscriptionId: $stripeSubscriptionId, slug: $slug, name: $name, id: $id) {\n    edges {\n      node {\n        id\n        organizationId\n        name\n        slug\n        country\n        deletedAt\n        subscription {\n          subscriptionId\n          subscriptionEndsAt\n          accessEndsAt\n          hasStartedActiveSubscription\n          hasCompletedTrial\n          canAccessProFeatures\n          isInGracePeriod\n          isTeam\n          billingStatus\n          billingProvider\n          stripeSubscriptionId\n          planType\n          maxNumSeats\n          practiceType\n          id\n        }\n        isActive\n        customer {\n          customerId\n          id\n        }\n        domain {\n          domainId\n          id\n        }\n        dlDataMode\n        dlDataModeOverride\n        enterpriseCapabilityManagementEnabled\n        enabledPresetIds\n        enabledToolIds\n        enabledCaptureModes\n        enabledCapabilities\n        specialType\n        numAvailableSeats\n        numOutstandingInvitations\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ea17d872722f21fe7323df55de16a484';
export default node;
