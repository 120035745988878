/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateDomainModal_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly createdAt: string | null;
    readonly deletedAt: string | null;
    readonly subdomainLabel: string | null;
    readonly name: string | null;
    readonly enableButterflyLogin: boolean | null;
    readonly enableSamlLogin: boolean | null;
    readonly enableSharedDeviceCodeLogin: boolean | null;
    readonly samlEmailAttributeName: string | null;
    readonly samlEmailAttributeNameRaw: string | null;
    readonly samlMetadata: string | null;
    readonly samlNicknameAttributeName: string | null;
    readonly samlNicknameAttributeNameRaw: string | null;
    readonly cognitoClientId: string | null;
    readonly inactivityTimeoutSeconds: number | null;
    readonly requiredAndroidCapabilities: ReadonlyArray<string | null> | null;
    readonly sharedDeviceKey: string | null;
    readonly maxNumSeats: number | null;
    readonly country: string | null;
    readonly " $refType": "UpdateDomainModal_domain";
};
export type UpdateDomainModal_domain$data = UpdateDomainModal_domain;
export type UpdateDomainModal_domain$key = {
    readonly " $data"?: UpdateDomainModal_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateDomainModal_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateDomainModal_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdomainLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableButterflyLogin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableSamlLogin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableSharedDeviceCodeLogin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlEmailAttributeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlEmailAttributeNameRaw",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlMetadata",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlNicknameAttributeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "samlNicknameAttributeNameRaw",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cognitoClientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inactivityTimeoutSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredAndroidCapabilities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharedDeviceKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxNumSeats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = 'e11674d7c22a3358660387aaad18d26f';
export default node;
