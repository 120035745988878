import { defineMessages } from 'react-intl';

export const ehrIdMessages = defineMessages({
  label: {
    id: 'ehrModal.ehrId.label',
    defaultMessage: 'Ehr ID*',
  },
  placeholder: {
    id: 'ehrModal.ehrId.placeholder',
    defaultMessage: 'Ehr ID',
  },
});

export const nameMessages = defineMessages({
  label: {
    id: 'ehrModal.name.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'ehrModal.name.placeholder',
    defaultMessage: 'Name',
  },
  required: {
    id: 'ehrModal.ehrId.required',
    defaultMessage: 'Please enter a Name',
  },
});

export const organizationIdMessages = defineMessages({
  label: {
    id: 'ehrModal.organizationId.label',
    defaultMessage: 'Organization ID',
  },
  placeholder: {
    id: 'ehrModal.organizationId.placeholder',
    defaultMessage: 'Organization ID',
  },
});

export const domainIdMessages = defineMessages({
  label: {
    id: 'ehrModal.domainId.label',
    defaultMessage: 'Domain ID',
  },
  placeholder: {
    id: 'ehrModal.domainId.placeholder',
    defaultMessage: 'Domain ID',
  },
});

export const connectionIdMessages = defineMessages({
  label: {
    id: 'ehrModal.connectionId.label',
    defaultMessage: 'Connection ID*',
  },
  placeholder: {
    id: 'ehrModal.connectionId.placeholder',
    defaultMessage: 'Connection ID',
  },
  required: {
    id: 'ehrModal.connectionId.required',
    defaultMessage: 'Please enter a Connection ID',
  },
});

export const processingIdMessages = defineMessages({
  label: {
    id: 'ehrModal.processingId.label',
    defaultMessage: 'Processing ID',
  },
  placeholder: {
    id: 'ehrModal.processingId.placeholder',
    defaultMessage: 'Processing ID',
  },
  description: {
    id: 'ehrModal.processingId.description',
    defaultMessage: 'Processing ID to populate MSH.11',
  },
});

export const hospitalHostMessages = defineMessages({
  label: {
    id: 'ehrModal.hospitalHost.label',
    defaultMessage: 'Hospital Host*',
  },
  placeholder: {
    id: 'ehrModal.hospitalHost.placeholder',
    defaultMessage: 'Hospital Host',
  },
  required: {
    id: 'ehrModal.hospitalHost.required',
    defaultMessage: 'Please enter a Hospital Host',
  },
});

export const hospitalPortMessages = defineMessages({
  label: {
    id: 'ehrModal.hospitalPort.label',
    defaultMessage: 'Hospital Port*',
  },
  placeholder: {
    id: 'ehrModal.hospitalPort.placeholder',
    defaultMessage: 'Hospital Port',
  },
  required: {
    id: 'ehrModal.hospitalPort.required',
    defaultMessage: 'Please enter a Hospital Port',
  },
  typeError: {
    id: 'ehrModal.hospitalPort.typeError',
    defaultMessage: 'Hospital Port must be a "number"',
  },
});

export const timeoutAckMessages = defineMessages({
  label: {
    id: 'ehrModal.timeoutAck.label',
    defaultMessage: 'Timeout Ack*',
  },
  placeholder: {
    id: 'ehrModal.timeoutAck.placeholder',
    defaultMessage: 'Timeout Ack',
  },
  required: {
    id: 'ehrModal.timeoutAck.required',
    defaultMessage: 'Please enter a Timeout Ack',
  },
  typeError: {
    id: 'ehrModal.timeoutAck.typeError',
    defaultMessage: 'Timeout Ack must be a "number"',
  },
});

export const timeoutConnectionMessages = defineMessages({
  label: {
    id: 'ehrModal.timeoutConnection.label',
    defaultMessage: 'Timeout Connection*',
  },
  placeholder: {
    id: 'ehrModal.timeoutConnection.placeholder',
    defaultMessage: 'Timeout Connection',
  },
  required: {
    id: 'ehrModal.timeoutConnection.required',
    defaultMessage: 'Please enter a Timeout Connection',
  },
  typeError: {
    id: 'ehrModal.timeoutConnection.typeError',
    defaultMessage: 'Timeout Connection must be a "number"',
  },
});

export const timezoneMessages = defineMessages({
  label: {
    id: 'ehrModal.timezone.label',
    defaultMessage: 'Timezone*',
  },
  placeholder: {
    id: 'ehrModal.timezone.placeholder',
    defaultMessage: 'Select Timezone',
  },
  required: {
    id: 'ehrModal.timezone.required',
    defaultMessage: 'Please enter a Timezone',
  },
});

export const characterEncodingMessages = defineMessages({
  label: {
    id: 'ehrModal.characterEncoding.label',
    defaultMessage: 'Character Encoding*',
  },
  placeholder: {
    id: 'ehrModal.characterEncoding.placeholder',
    defaultMessage: 'Character Encoding',
  },
  required: {
    id: 'ehrModal.characterEncoding.required',
    defaultMessage: 'Please enter a Character Encoding',
  },
});

export const receivingApplicationMessages = defineMessages({
  label: {
    id: 'ehrModal.receivingApplication.label',
    defaultMessage: 'Receiving Application',
  },
  placeholder: {
    id: 'ehrModal.receivingApplication.placeholder',
    defaultMessage: 'Receiving Application',
  },
});

export const receivingFacilityMessages = defineMessages({
  label: {
    id: 'ehrModal.receivingFacility.label',
    defaultMessage: 'Receiving Facility',
  },
  placeholder: {
    id: 'ehrModal.receivingFacility.placeholder',
    defaultMessage: 'Receiving Facility',
  },
});

export const sendingFacilityMessages = defineMessages({
  label: {
    id: 'ehrModal.sendingFacility.label',
    defaultMessage: 'Sending Facility',
  },
  placeholder: {
    id: 'ehrModal.sendingFacility.placeholder',
    defaultMessage: 'Sending Facility',
  },
});

export const studyDescriptionMappingMessages = defineMessages({
  label: {
    id: 'ehrModal.studyDescriptionMapping.label',
    defaultMessage: 'Study Description Mapping',
  },
  placeholder: {
    id: 'ehrModal.studyDescriptionMapping.placeholder',
    defaultMessage: 'Study Description Mapping',
  },
  description: {
    id: 'ehrModal.studyDescriptionMapping.description',
    defaultMessage:
      'JSON mapping of study descriptions (strings) to procedure IDs (strings)',
  },
  typeError: {
    id: 'ehrModal.studyDescriptionMapping.typeError',
    defaultMessage:
      'Invalid JSON. Must be a mapping of study descriptions (strings) to procedure IDs (strings)',
  },
});

export const studyUrlDisplayMessages = defineMessages({
  label: {
    id: 'ehrModal.studyUrlDisplay.label',
    defaultMessage: 'Study Url Display*',
  },
  placeholder: {
    id: 'ehrModal.studyUrlDisplay.placeholder',
    defaultMessage: 'Study Url Display',
  },
  required: {
    id: 'ehrModal.studyUrlDisplay.required',
    defaultMessage: 'Please enter a Study Url Display',
  },
});

export const procedureIdPathMessages = defineMessages({
  label: {
    id: 'ehrModal.procedureIdPath.label',
    defaultMessage: 'Procedure ID Path',
  },
  placeholder: {
    id: 'ehrModal.procedureIdPath.placeholder',
    defaultMessage: 'Procedure ID Path',
  },
  required: {
    id: 'ehrModal.procedureIdPath.required',
    defaultMessage: 'Please select a Procedure ID Path',
  },
});
