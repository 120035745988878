import { defineMessages } from 'react-intl';

export default defineMessages({
  placeOfWorkId: {
    id: 'placeOfWorkTable.heading.placeOfWorkId',
    defaultMessage: 'Place Of Work ID',
  },
  countries: {
    id: 'placeOfWorkTable.heading.countries',
    defaultMessage: 'Countries',
  },
  source: {
    id: 'placeOfWorkTable.heading.source',
    defaultMessage: 'Source',
  },
});
