/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserDefinedRoleDetailPage_role = {
    readonly id: string;
    readonly userDefinedRoleId: string | null;
    readonly domainId: string | null;
    readonly name: string | null;
    readonly description: string | null;
    readonly permissions: any | null;
    readonly deletedAt: string | null;
    readonly " $fragmentRefs": FragmentRefs<"DeleteUserDefinedRoleAction_role" | "UndeleteUserDefinedRoleAction_role">;
    readonly " $refType": "UserDefinedRoleDetailPage_role";
};
export type UserDefinedRoleDetailPage_role$data = UserDefinedRoleDetailPage_role;
export type UserDefinedRoleDetailPage_role$key = {
    readonly " $data"?: UserDefinedRoleDetailPage_role$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserDefinedRoleDetailPage_role">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDefinedRoleDetailPage_role",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userDefinedRoleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteUserDefinedRoleAction_role"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UndeleteUserDefinedRoleAction_role"
    }
  ],
  "type": "UserDefinedRole",
  "abstractKey": null
};
(node as any).hash = 'b710172256342bf8b33fffd70ed98c2a';
export default node;
