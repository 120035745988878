import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import ApplicationVersionRequirementDetailPage from './components/ApplicationVersionRequirementDetailPage';
import ApplicationVersionRequirementsPage from './components/ApplicationVersionRequirementsPage';

export default (
  <Route path="application-version-requirements">
    <Route
      Component={ApplicationVersionRequirementsPage}
      query={graphql`
        query applicationVersionRequirements_ApplicationVersionRequirementsPage_Query {
          viewer {
            ...ApplicationVersionRequirementsPage_viewer
          }
        }
      `}
    />
    <Route
      path=":applicationIdentifier"
      Component={ApplicationVersionRequirementDetailPage}
      query={graphql`
        query applicationVersionRequirements_ApplicationVersionRequirementyDetailPage_Query(
          $applicationIdentifier: String!
        ) {
          applicationVersionRequirement(
            applicationIdentifier: $applicationIdentifier
          ) {
            ...ApplicationVersionRequirementDetailPage_applicationVersionRequirement
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.applicationVersionRequirement) throw new HttpError(404);
      }}
    />
  </Route>
);
