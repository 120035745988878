import { defineMessages } from 'react-intl';

export default defineMessages({
  educationContentId: {
    id: 'educationVideosTable.heading.educationContentId',
    defaultMessage: 'Education Content ID',
  },
  isPremium: {
    id: 'educationVideosTable.heading.isPremium',
    defaultMessage: 'Is Premium',
  },
  vimeoId: {
    id: 'educationVideosTable.heading.vimeoId',
    defaultMessage: 'Vimeo ID',
  },
  videoType: {
    id: 'educationVideosTable.heading.videoType',
    defaultMessage: 'Video Type',
  },
  contentType: {
    id: 'table.heading.contentType',
    defaultMessage: 'Content Type',
  },
  absorbId: {
    id: 'educationVideosTable.heading.absorbId',
    defaultMessage: 'Absorb ID',
  },
  duration: {
    id: 'educationVideosTable.heading.duration',
    defaultMessage: 'Duration',
  },
  ranking: {
    id: 'educationVideosTable.heading.ranking',
    defaultMessage: 'Ranking',
  },
  status: {
    id: 'educationVideosTable.heading.status',
    defaultMessage: 'Course Status',
  },
  authoredBy: {
    id: 'educationVideosTable.heading.authoredBy',
    defaultMessage: 'Authored By',
  },
  categoryName: {
    id: 'educationVideosTable.heading.categoryName',
    defaultMessage: 'Category Name',
  },
  categoryId: {
    id: 'educationVideosTable.heading.categoryId',
    defaultMessage: 'Category ID',
  },
});
