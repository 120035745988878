import React from 'react';
import { graphql, useFragment } from 'react-relay';

import UndeleteAction from 'components/UndeleteAction';

import { UndeleteUserDefinedRoleAction_role$key } from './__generated__/UndeleteUserDefinedRoleAction_role.graphql';

const fragment = graphql`
  fragment UndeleteUserDefinedRoleAction_role on UserDefinedRole {
    userDefinedRoleId
    name
  }
`;

const mutation = graphql`
  mutation UndeleteUserDefinedRoleActionMutation(
    $input: UndeleteUserDefinedRoleInput!
  ) {
    undeleteUserDefinedRole(input: $input) {
      userDefinedRole {
        userDefinedRoleId
        deletedAt
      }
    }
  }
`;

interface UndeleteUserDefinedRoleActionProps {
  roleRef: UndeleteUserDefinedRoleAction_role$key;
}

export default function UndeleteUserDefinedRoleAction({
  roleRef,
}: UndeleteUserDefinedRoleActionProps) {
  const { userDefinedRoleId, name } = useFragment(fragment, roleRef);

  return (
    <UndeleteAction
      mutation={mutation}
      itemName={`${name} Role` ?? 'Role'}
      input={{ userDefinedRoleId }}
    />
  );
}
