import styled from 'astroturf/react';
import { ReactNode } from 'react';

const DescriptionListTerm = styled('dt')`
  font-weight: inherit;
`;

const DescriptionListDetail = styled('dd')`
  /* ! */
`;

interface Props {
  variant?: 'stacked' | 'table';
  className?: string;
  children?: ReactNode;
}

const DescriptionList = styled('dl')<Props>`
  @import '~@bfly/ui/styles/theme';

  margin: 1rem 0 0 0;

  &.variant-stacked {
    & ${DescriptionListTerm} {
      font-size: $font-size-sm;
      color: theme-value('dark', 'text-color-subtitle');

      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    & ${DescriptionListDetail} {
      margin: 1rem 0 0;
    }
  }

  &.variant-table {
    display: grid;
    grid-template-columns: auto 1fr;
    border-top: 1px solid theme-value('dark', 'divider-color');

    & ${DescriptionListTerm} {
      color: theme-value('dark', 'text-color');
      padding: 0.8rem 2rem 0.8rem 0;
      border-bottom: 1px solid theme-value('dark', 'divider-color');
      padding-right: 2rem;
    }

    & ${DescriptionListDetail} {
      word-break: break-word;
      padding: 0.8rem 0;
      margin: 0;
      color: theme-value('dark', 'text-color-headline');
      border-bottom: 1px solid theme-value('dark', 'divider-color');
    }
  }
`;

DescriptionList.defaultProps = {
  variant: 'stacked',
};

export default Object.assign(DescriptionList, {
  Term: DescriptionListTerm,
  Detail: DescriptionListDetail,
});
