import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';

import { educationCourseMessages } from '../messages/formMessages';
import {
  EducationCourseModal,
  educationCourseSchema,
} from './EducationCourseModal';
import {
  UpdateEducationContentInput,
  UpdateEducationCourseModalMutation,
} from './__generated__/UpdateEducationCourseModalMutation.graphql';
import { UpdateEducationCourseModal_educationContent$key } from './__generated__/UpdateEducationCourseModal_educationContent.graphql';

const fragment = graphql`
  fragment UpdateEducationCourseModal_educationContent on EducationContent {
    id
    educationContentId
    absorbId
    name
    description
    type
    practiceType
    isPremium
    vimeoId
    status
    presetLocalIds
    toolLocalIds
    captureModes
    authoredBy {
      userId
    }
    category {
      educationCategoryId
    }
    ranking
    duration
    courseLanguage
  }
`;

const updateMutation = graphql`
  mutation UpdateEducationCourseModalMutation(
    $input: UpdateEducationContentInput!
  ) {
    updateEducationContent(input: $input) {
      educationContent {
        id
        ...EducationContentPage_educationContent
      }
    }
  }
`;

const transformOnSubmit = (value: UpdateEducationContentInput) => {
  return {
    educationContentId: value.educationContentId,
    practiceType: value.practiceType,
    categoryId: value.categoryId,
    duration: value.duration,
    ranking: value.ranking,
    courseLanguage:
      value.courseLanguage === ('NONE' as any) ? null : value.courseLanguage,
  };
};

interface UpdateEducationCourseModalProps extends ActionModalProps {
  educationCourseRef: UpdateEducationCourseModal_educationContent$key;
}

export function UpdateEducationCourseModal({
  show,
  onClose,
  educationCourseRef,
}: UpdateEducationCourseModalProps) {
  const educationCourse = useFragment(fragment, educationCourseRef);

  const transformedDefaultValue = useMemo(
    () => ({
      ...educationCourse,
      authoredBy: educationCourse.authoredBy?.userId,
      categoryId: educationCourse.category?.educationCategoryId,
      presetIds: educationCourse.presetLocalIds ?? [],
      toolIds: educationCourse.toolLocalIds ?? [],
      courseLanguage:
        educationCourse.courseLanguage === null
          ? 'NONE'
          : educationCourse.courseLanguage,
    }),
    [educationCourse],
  );

  return (
    <EducationCourseModal<UpdateEducationCourseModalMutation>
      title={<FormattedMessage {...educationCourseMessages.update} />}
      name={educationCourse.name!}
      submitText={<FormattedMessage {...educationCourseMessages.update} />}
      show={show}
      onClose={onClose}
      schema={educationCourseSchema}
      mutation={updateMutation}
      defaultValue={transformedDefaultValue}
      transformOnSubmit={transformOnSubmit}
    />
  );
}
