import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import DangerousActionConfirmation from 'components/DangerousActionConfirmation';
import DangerousActionWarning from 'components/DangerousActionWarning';
import DropdownList from 'components/DropdownList';
import { ActionModalProps, ResourceModal } from 'components/ResourceModal';
import { setCountryMessages } from 'messages/formMessages';
import {
  countryMessages,
  organizationIdMessages,
} from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { countries } from '../utils/countries';
import { SetCountryAction_organization$key } from './__generated__/SetCountryAction_organization.graphql';

const setCountrySchema = object({
  organizationId: string().required().meta({
    readOnly: true,
  }),
  country: string()
    .oneOf(countries.map((country) => country.code))
    .required(countryMessages.required),
});

const fragment = graphql`
  fragment SetCountryAction_organization on Organization {
    organizationId
    country
  }
`;

const setCountryMutation = graphql`
  mutation SetCountryAction_SetCountryMutation(
    $input: SetOrganizationCountryInput!
  ) {
    setOrganizationCountry(input: $input) {
      organization {
        country
      }
    }
  }
`;

interface SetCountryModalProps extends ActionModalProps {
  organizationRef: SetCountryAction_organization$key;
}

function SetCountryModal({
  organizationRef,
  onClose,
  ...props
}: SetCountryModalProps) {
  const { organizationId, country } = useFragment(fragment, organizationRef);
  const [allowDangerousAction, setAllowDangerousAction] = useState(false);
  const { formatMessage } = useIntl();
  const handleClose = () => {
    setAllowDangerousAction(false);
    onClose();
  };

  return (
    <ResourceModal
      onClose={handleClose}
      disabled={!allowDangerousAction}
      {...props}
      title={<FormattedMessage {...setCountryMessages.label} />}
      submitText={<FormattedMessage {...setCountryMessages.label} />}
      schema={setCountrySchema}
      mutation={setCountryMutation}
      defaultValue={{ organizationId, country }}
    >
      <DangerousActionWarning />
      <Form.FieldGroup
        disabled
        name="organizationId"
        label={<FormattedMessage {...organizationIdMessages.label} />}
        placeholder={formatMessage(organizationIdMessages.placeholder)}
        description={
          <FormattedMessage {...organizationIdMessages.placeholder} />
        }
        data-cy="setCountry-organizationId"
      />
      <Form.FieldGroup
        name="country"
        as={DropdownList}
        data={countries}
        dataKey="code"
        textField="title"
        mapFromValue={{ country: 'code' }}
        label={<FormattedMessage {...countryMessages.label} />}
        placeholder={countryMessages.placeholder}
        data-cy="setCountry-country"
      />
      <DangerousActionConfirmation
        confirmed={allowDangerousAction}
        setConfirmed={setAllowDangerousAction}
      />
    </ResourceModal>
  );
}

interface SetCountryActionProps {
  organizationRef: SetCountryAction_organization$key;
}

export default function SetCountryAction({
  organizationRef,
}: SetCountryActionProps) {
  const [showCountry, setShowCountry] = useState(false);
  return (
    <PermissionsGuard
      resource={Resource.ORGANIZATION_MANAGEMENT_ADMIN_READ_WRITE}
      write
      hideContent
    >
      <Button variant="secondary" onClick={() => setShowCountry(true)}>
        <FormattedMessage {...setCountryMessages.label} />
      </Button>
      <SetCountryModal
        organizationRef={organizationRef}
        show={showCountry}
        onClose={() => setShowCountry(false)}
      />
    </PermissionsGuard>
  );
}
