/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type countries_CountryDetailPage_QueryVariables = {
    countryCode: string;
};
export type countries_CountryDetailPage_QueryResponse = {
    readonly country: {
        readonly " $fragmentRefs": FragmentRefs<"CountryDetailPage_country">;
    } | null;
};
export type countries_CountryDetailPage_Query = {
    readonly response: countries_CountryDetailPage_QueryResponse;
    readonly variables: countries_CountryDetailPage_QueryVariables;
};



/*
query countries_CountryDetailPage_Query(
  $countryCode: String!
) {
  country(countryCode: $countryCode) {
    ...CountryDetailPage_country
    id
  }
}

fragment CountryDetailPage_country on Country {
  ...UpdateCountryModal_country
  id
  code
  name
  enabled
  countryConfiguration {
    dataRegion
    configuration
    id
  }
}

fragment UpdateCountryModal_country on Country {
  id
  code
  name
  enabled
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "countries_CountryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "country",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CountryDetailPage_country"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "countries_CountryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "country",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CountryConfiguration",
            "kind": "LinkedField",
            "name": "countryConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataRegion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "configuration",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d866a8ed90f38425b4321f9909de951",
    "id": null,
    "metadata": {},
    "name": "countries_CountryDetailPage_Query",
    "operationKind": "query",
    "text": "query countries_CountryDetailPage_Query(\n  $countryCode: String!\n) {\n  country(countryCode: $countryCode) {\n    ...CountryDetailPage_country\n    id\n  }\n}\n\nfragment CountryDetailPage_country on Country {\n  ...UpdateCountryModal_country\n  id\n  code\n  name\n  enabled\n  countryConfiguration {\n    dataRegion\n    configuration\n    id\n  }\n}\n\nfragment UpdateCountryModal_country on Country {\n  id\n  code\n  name\n  enabled\n}\n"
  }
};
})();
(node as any).hash = '19b32807c41d1852d52b9a7acb17415f';
export default node;
