import { defineMessages } from 'react-intl';

export const studyIdMessages = defineMessages({
  label: {
    id: 'copyStudy.studyId.label',
    defaultMessage: 'Study ID*',
  },
  placeholder: {
    id: 'copyStudy.studyId.placeholder',
    defaultMessage: 'Study ID',
  },
  description: {
    id: 'copyStudy.studyId.description',
    defaultMessage:
      'The ID of the study to copy. The field supports handles and UUIDs',
  },
  required: {
    id: 'copyStudy.studyId.required',
    defaultMessage: 'Study ID is a required field',
  },
});

export const destinationArchiveIdMessages = defineMessages({
  label: {
    id: 'copyStudy.destinationArchiveId.label',
    defaultMessage: 'Destination Archive ID*',
  },
  placeholder: {
    id: 'copyStudy.destinationArchiveId.placeholder',
    defaultMessage: 'Destination Archive ID',
  },
  description: {
    id: 'copyStudy.destinationArchiveId.description',
    defaultMessage:
      'The ID of the archive into which to copy the study. The field supports handles and UUIDs',
  },
  required: {
    id: 'copyStudy.destinationArchiveId.required',
    defaultMessage: 'Destination Archive ID is a required field',
  },
});
