import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteButterflyDeviceAction_butterflyDevice$key } from './__generated__/DeleteButterflyDeviceAction_butterflyDevice.graphql';

const fragment = graphql`
  fragment DeleteButterflyDeviceAction_butterflyDevice on ButterflyDevice {
    id
    productionId
  }
`;

const mutation = graphql`
  mutation DeleteButterflyDeviceActionMutation(
    $input: DeleteButterflyDeviceInput!
  ) {
    deleteButterflyDevice(input: $input) {
      deletedId
    }
  }
`;

interface DeleteButterflyDeviceActionProps {
  butterflyDeviceRef: DeleteButterflyDeviceAction_butterflyDevice$key;
}

export default function DeleteButterflyDeviceAction({
  butterflyDeviceRef,
}: DeleteButterflyDeviceActionProps) {
  const { productionId } = useFragment(fragment, butterflyDeviceRef);
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      mutation={mutation}
      itemName={productionId}
      input={{ productionId }}
      redirectTo="/butterfly-devices"
      onDelete={() => deleteBreadcrumb(`/butterfly-devices/${productionId}`)}
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
