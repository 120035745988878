import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteApplicationVersionRequirementAction_applicationVersionRequirement$key } from './__generated__/DeleteApplicationVersionRequirementAction_applicationVersionRequirement.graphql';

const fragment = graphql`
  fragment DeleteApplicationVersionRequirementAction_applicationVersionRequirement on ApplicationVersionRequirement {
    id
    applicationIdentifier
  }
`;

const mutation = graphql`
  mutation DeleteApplicationVersionRequirementActionMutation(
    $input: DeleteApplicationVersionRequirementInput!
  ) {
    deleteApplicationVersionRequirement(input: $input) {
      deletedId
    }
  }
`;

interface DeleteApplicationVersionRequirementActionProps {
  applicationVersionRequirementRef: DeleteApplicationVersionRequirementAction_applicationVersionRequirement$key;
}

export default function DeleteApplicationVersionRequirementAction({
  applicationVersionRequirementRef,
}: DeleteApplicationVersionRequirementActionProps) {
  const { applicationIdentifier } = useFragment(
    fragment,
    applicationVersionRequirementRef,
  );
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      itemName={applicationIdentifier}
      mutation={mutation}
      redirectTo="/application-version-requirements"
      onDelete={() =>
        deleteBreadcrumb(
          `/application-version-requirements/${applicationIdentifier}`,
        )
      }
      input={{
        applicationIdentifier,
      }}
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
