import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import DiagnosticTestResultDetailPage from './components/DiagnosticTestResultDetailPage';
import DiagnosticTestResultsGrid from './components/DiagnosticTestResultsGrid';
import DiagnosticTestResultsPage from './components/DiagnosticTestResultsPage';

export default (
  <Route path="diagnostic-test-results">
    <Route Component={DiagnosticTestResultsPage}>
      <Route
        Component={DiagnosticTestResultsGrid}
        query={graphql`
          query diagnosticTestResults_DiagnosticTestResultsGrid_Query(
            $deviceProductionId: [String!]
          ) {
            viewer {
              ...DiagnosticTestResultsGrid_viewer
                @arguments(deviceProductionId: $deviceProductionId)
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          deviceProductionId: location.query.deviceProductionId,
        })}
      />
    </Route>
    <Route
      path=":diagnosticTestResultId"
      Component={DiagnosticTestResultDetailPage}
      query={graphql`
        query diagnosticTestResults_DiagnosticTestResultDetailPage_Query(
          $diagnosticTestResultId: String!
        ) {
          diagnosticTestResult(
            diagnosticTestResultId: $diagnosticTestResultId
          ) {
            ...DiagnosticTestResultDetailPage_diagnosticTestResult
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.diagnosticTestResult) throw new HttpError(404);
      }}
    />
  </Route>
);
