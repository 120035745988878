/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type domainInvites_DomainInvitesPage_QueryVariables = {};
export type domainInvites_DomainInvitesPage_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"DomainInvitesPage_viewer">;
    } | null;
};
export type domainInvites_DomainInvitesPage_Query = {
    readonly response: domainInvites_DomainInvitesPage_QueryResponse;
    readonly variables: domainInvites_DomainInvitesPage_QueryVariables;
};



/*
query domainInvites_DomainInvitesPage_Query {
  viewer {
    ...DomainInvitesPage_viewer
    id
  }
}

fragment CreateUserInviteModal_viewer on Viewer {
  id
}

fragment DomainInvitesPage_viewer on Viewer {
  ...CreateUserInviteModal_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainInvites_DomainInvitesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainInvitesPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainInvites_DomainInvitesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0435493db1c16f7a2e809a9608f41fb0",
    "id": null,
    "metadata": {},
    "name": "domainInvites_DomainInvitesPage_Query",
    "operationKind": "query",
    "text": "query domainInvites_DomainInvitesPage_Query {\n  viewer {\n    ...DomainInvitesPage_viewer\n    id\n  }\n}\n\nfragment CreateUserInviteModal_viewer on Viewer {\n  id\n}\n\nfragment DomainInvitesPage_viewer on Viewer {\n  ...CreateUserInviteModal_viewer\n}\n"
  }
};
(node as any).hash = '377ac581e6b529dd34d7c92dede30c6f';
export default node;
