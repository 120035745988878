/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type butterflyDevices_ButterflyDevicesGrid_QueryVariables = {
    productionId?: Array<string> | null | undefined;
    organizationId?: Array<string> | null | undefined;
};
export type butterflyDevices_ButterflyDevicesGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"ButterflyDevicesGrid_viewer">;
    } | null;
};
export type butterflyDevices_ButterflyDevicesGrid_Query = {
    readonly response: butterflyDevices_ButterflyDevicesGrid_QueryResponse;
    readonly variables: butterflyDevices_ButterflyDevicesGrid_QueryVariables;
};



/*
query butterflyDevices_ButterflyDevicesGrid_Query(
  $productionId: [String!]
  $organizationId: [String!]
) {
  viewer {
    ...ButterflyDevicesGrid_viewer_40q8SI
    id
  }
}

fragment ButterflyDevicesGrid_viewer_40q8SI on Viewer {
  butterflyDevices(first: 20, productionId: $productionId, organizationId: $organizationId) {
    edges {
      node {
        id
        productionId
        registeredAt
        isEnabled
        disabledStatusMessage
        lostAt
        stolenAt
        recallId
        subscription {
          subscriptionId
          id
        }
        hasActiveDiagnosticTestRequest
        lastDiagnosticTestRequestMinTestVersion
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productionId"
},
v2 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v3 = {
  "kind": "Variable",
  "name": "productionId",
  "variableName": "productionId"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "butterflyDevices_ButterflyDevicesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ButterflyDevicesGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "butterflyDevices_ButterflyDevicesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ButterflyDeviceConnection",
            "kind": "LinkedField",
            "name": "butterflyDevices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ButterflyDeviceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ButterflyDevice",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "disabledStatusMessage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lostAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stolenAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recallId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Subscription",
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subscriptionId",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasActiveDiagnosticTestRequest",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastDiagnosticTestRequestMinTestVersion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "productionId",
              "organizationId"
            ],
            "handle": "connection",
            "key": "ButterflyDevicesGrid_butterflyDevices",
            "kind": "LinkedHandle",
            "name": "butterflyDevices"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7872330fbacc8fc48b9d90835c4d4f1f",
    "id": null,
    "metadata": {},
    "name": "butterflyDevices_ButterflyDevicesGrid_Query",
    "operationKind": "query",
    "text": "query butterflyDevices_ButterflyDevicesGrid_Query(\n  $productionId: [String!]\n  $organizationId: [String!]\n) {\n  viewer {\n    ...ButterflyDevicesGrid_viewer_40q8SI\n    id\n  }\n}\n\nfragment ButterflyDevicesGrid_viewer_40q8SI on Viewer {\n  butterflyDevices(first: 20, productionId: $productionId, organizationId: $organizationId) {\n    edges {\n      node {\n        id\n        productionId\n        registeredAt\n        isEnabled\n        disabledStatusMessage\n        lostAt\n        stolenAt\n        recallId\n        subscription {\n          subscriptionId\n          id\n        }\n        hasActiveDiagnosticTestRequest\n        lastDiagnosticTestRequestMinTestVersion\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '87d091158a7acf4959930ee653769a2d';
export default node;
