/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RequestDomainAuditLogsInput = {
    domainId: string;
    startDate: string;
    endDate: string;
    clientMutationId?: string | null | undefined;
};
export type RequestAuditLogsActionMutationVariables = {
    input: RequestDomainAuditLogsInput;
};
export type RequestAuditLogsActionMutationResponse = {
    readonly requestDomainAuditLogs: {
        readonly downloadLink: string | null;
        readonly password: string | null;
    } | null;
};
export type RequestAuditLogsActionMutation = {
    readonly response: RequestAuditLogsActionMutationResponse;
    readonly variables: RequestAuditLogsActionMutationVariables;
};



/*
mutation RequestAuditLogsActionMutation(
  $input: RequestDomainAuditLogsInput!
) {
  requestDomainAuditLogs(input: $input) {
    downloadLink
    password
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestDomainAuditLogsPayload",
    "kind": "LinkedField",
    "name": "requestDomainAuditLogs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "downloadLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "password",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestAuditLogsActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestAuditLogsActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f1963d1e8cb766c0adbb8b42724488d4",
    "id": null,
    "metadata": {},
    "name": "RequestAuditLogsActionMutation",
    "operationKind": "mutation",
    "text": "mutation RequestAuditLogsActionMutation(\n  $input: RequestDomainAuditLogsInput!\n) {\n  requestDomainAuditLogs(input: $input) {\n    downloadLink\n    password\n  }\n}\n"
  }
};
})();
(node as any).hash = 'aec83b4bbf8f92b23c6cd20b92a9d178';
export default node;
