import React, { PropsWithChildren } from 'react';

import PermissionDeniedPage from '../../components/PermissionDeniedPage';
import { Resource } from './permissions';
import { usePermissionsContext } from './usePermissionsContext';

export default function PermissionsGuard({
  children,
  resource,
  read = true,
  write = false,
  hideContent = false,
}: PropsWithChildren<{
  resource: Resource;
  read?: boolean;
  write?: boolean;
  hideContent?: boolean;
}>) {
  const { getPermissions } = usePermissionsContext();
  const permissions = getPermissions(resource);

  if ((read && !permissions.read) || (write && !permissions.write)) {
    return hideContent ? null : <PermissionDeniedPage />;
  }

  return <>{children}</>;
}
