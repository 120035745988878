/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_QueryVariables = {
    androidVersionBlacklistEntryId: string;
};
export type androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_QueryResponse = {
    readonly androidVersionBlacklistEntry: {
        readonly " $fragmentRefs": FragmentRefs<"AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry">;
    } | null;
};
export type androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_Query = {
    readonly response: androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_QueryResponse;
    readonly variables: androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_QueryVariables;
};



/*
query androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_Query(
  $androidVersionBlacklistEntryId: String!
) {
  androidVersionBlacklistEntry(androidVersionBlacklistEntryId: $androidVersionBlacklistEntryId) {
    ...AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry
    id
  }
}

fragment AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
  ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry
  ...DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry
  id
  androidVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  versionCode
}

fragment DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
  androidVersionBlacklistEntryId
}

fragment UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
  id
  androidVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  versionCode
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "androidVersionBlacklistEntryId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "androidVersionBlacklistEntryId",
    "variableName": "androidVersionBlacklistEntryId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AndroidVersionBlacklistEntry",
        "kind": "LinkedField",
        "name": "androidVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AndroidVersionBlacklistEntry",
        "kind": "LinkedField",
        "name": "androidVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "androidVersionBlacklistEntryId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "applicationIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "versionRange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "versionCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ed254f73fc9882543c82768b14c8361",
    "id": null,
    "metadata": {},
    "name": "androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_Query",
    "operationKind": "query",
    "text": "query androidVersionBlacklistEntries_AndroidVersionBlacklistEntryDetailPage_Query(\n  $androidVersionBlacklistEntryId: String!\n) {\n  androidVersionBlacklistEntry(androidVersionBlacklistEntryId: $androidVersionBlacklistEntryId) {\n    ...AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry\n    id\n  }\n}\n\nfragment AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {\n  ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry\n  ...DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry\n  id\n  androidVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  versionCode\n}\n\nfragment DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {\n  androidVersionBlacklistEntryId\n}\n\nfragment UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {\n  id\n  androidVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  versionCode\n}\n"
  }
};
})();
(node as any).hash = 'a4e7d8753346539d6b8625274858f712';
export default node;
