/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig = {
    readonly id: string;
    readonly domainUserIntegrationConfigId: string | null;
    readonly domainUser: {
        readonly name: string | null;
    } | null;
    readonly " $refType": "DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig";
};
export type DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig$data = DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig;
export type DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig$key = {
    readonly " $data"?: DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserIntegrationConfigId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainUser",
      "kind": "LinkedField",
      "name": "domainUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DomainUserIntegrationConfig",
  "abstractKey": null
};
(node as any).hash = 'f08b51a3ac2f15fb60759d8a8b37fd02';
export default node;
