/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UndeleteUserDefinedRoleInput = {
    userDefinedRoleId: string;
    clientMutationId?: string | null | undefined;
};
export type UndeleteUserDefinedRoleActionMutationVariables = {
    input: UndeleteUserDefinedRoleInput;
};
export type UndeleteUserDefinedRoleActionMutationResponse = {
    readonly undeleteUserDefinedRole: {
        readonly userDefinedRole: {
            readonly userDefinedRoleId: string | null;
            readonly deletedAt: string | null;
        } | null;
    } | null;
};
export type UndeleteUserDefinedRoleActionMutation = {
    readonly response: UndeleteUserDefinedRoleActionMutationResponse;
    readonly variables: UndeleteUserDefinedRoleActionMutationVariables;
};



/*
mutation UndeleteUserDefinedRoleActionMutation(
  $input: UndeleteUserDefinedRoleInput!
) {
  undeleteUserDefinedRole(input: $input) {
    userDefinedRole {
      userDefinedRoleId
      deletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userDefinedRoleId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UndeleteUserDefinedRoleActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UndeleteUserDefinedRolePayload",
        "kind": "LinkedField",
        "name": "undeleteUserDefinedRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDefinedRole",
            "kind": "LinkedField",
            "name": "userDefinedRole",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UndeleteUserDefinedRoleActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UndeleteUserDefinedRolePayload",
        "kind": "LinkedField",
        "name": "undeleteUserDefinedRole",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserDefinedRole",
            "kind": "LinkedField",
            "name": "userDefinedRole",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "893f5137eddb8fbedf0816733599ace8",
    "id": null,
    "metadata": {},
    "name": "UndeleteUserDefinedRoleActionMutation",
    "operationKind": "mutation",
    "text": "mutation UndeleteUserDefinedRoleActionMutation(\n  $input: UndeleteUserDefinedRoleInput!\n) {\n  undeleteUserDefinedRole(input: $input) {\n    userDefinedRole {\n      userDefinedRoleId\n      deletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c1331a474efee064aaf69ca302062c69';
export default node;
