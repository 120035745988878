/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DisableCaptionHealthAction_subscription = {
    readonly id: string;
    readonly subscriptionId: string | null;
    readonly " $refType": "DisableCaptionHealthAction_subscription";
};
export type DisableCaptionHealthAction_subscription$data = DisableCaptionHealthAction_subscription;
export type DisableCaptionHealthAction_subscription$key = {
    readonly " $data"?: DisableCaptionHealthAction_subscription$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DisableCaptionHealthAction_subscription">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisableCaptionHealthAction_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionId",
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};
(node as any).hash = '8ac6d8668dcb4611e1e750ff32ef1d9a';
export default node;
