import React from 'react';
import { graphql, useFragment } from 'react-relay';

import UndeleteAction from 'components/UndeleteAction';

import { UndeleteOrganizationAction_organization$key } from './__generated__/UndeleteOrganizationAction_organization.graphql';

const fragment = graphql`
  fragment UndeleteOrganizationAction_organization on Organization {
    organizationId
    name
  }
`;

const mutation = graphql`
  mutation UndeleteOrganizationActionMutation(
    $input: UndeleteOrganizationInput!
  ) {
    undeleteOrganization(input: $input) {
      organization {
        deletedAt
      }
    }
  }
`;

interface UndeleteOrganizationActionProps {
  organizationRef: UndeleteOrganizationAction_organization$key;
}

export default function UndeleteOrganizationAction({
  organizationRef,
}: UndeleteOrganizationActionProps) {
  const organization = useFragment(fragment, organizationRef);

  return (
    <UndeleteAction
      mutation={mutation}
      itemName={`${organization.name} Organization` ?? 'Organization'}
      input={{ organizationId: organization.organizationId }}
    />
  );
}
