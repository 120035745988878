import React from 'react';
import { graphql, useFragment } from 'react-relay';

import UndeleteAction from 'components/UndeleteAction';

import { UndeleteEducationContentAction_educationContent$key } from './__generated__/UndeleteEducationContentAction_educationContent.graphql';

const fragment = graphql`
  fragment UndeleteEducationContentAction_educationContent on EducationContent {
    name
    educationContentId
    deletedAt
  }
`;

const mutation = graphql`
  mutation UndeleteEducationContentActionMutation(
    $input: UndeleteEducationContentInput!
  ) {
    undeleteEducationContent(input: $input) {
      educationContent {
        educationContentId
        deletedAt
      }
    }
  }
`;

interface UndeleteEducationContentActionProps {
  educationContentRef: UndeleteEducationContentAction_educationContent$key;
}

export default function UndeleteEducationContentAction({
  educationContentRef,
}: UndeleteEducationContentActionProps) {
  const educationContent = useFragment(fragment, educationContentRef);

  return (
    <UndeleteAction
      mutation={mutation}
      itemName={`${educationContent?.name} Course` ?? 'Course'}
      input={{ educationContentId: educationContent.educationContentId }}
    />
  );
}
