import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import actionMessages from 'messages/actions';
import tableHeadings from 'messages/tableHeadings';

import testResultsTableHeadings from '../messages/tableHeadings';
import { DiagnosticTestResultsGrid_diagnosticTestResult as DiagnosticTestResult } from './__generated__/DiagnosticTestResultsGrid_diagnosticTestResult.graphql';
import { DiagnosticTestResultsGrid_viewer$key } from './__generated__/DiagnosticTestResultsGrid_viewer.graphql';

const _ = graphql`
  fragment DiagnosticTestResultsGrid_diagnosticTestResult on DiagnosticTestResult {
    id
    diagnosticTestResultId
    deviceProductionId
    createdAt
    testVersion
    results
  }
`;

const fragment = graphql`
  fragment DiagnosticTestResultsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    deviceProductionId: { type: "[String!]" }
  )
  @refetchable(queryName: "DiagnosticTestResultsGridRefetchQuery") {
    diagnosticTestResults(
      first: $count
      after: $cursor
      deviceProductionId: $deviceProductionId
    ) @connection(key: "DiagnosticTestResultsGrid_diagnosticTestResults") {
      edges {
        node {
          ...DiagnosticTestResultsGrid_diagnosticTestResult @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<DiagnosticTestResult>[] = [
  {
    key: 'diagnosticTestResultId',
    frozen: true,
    label: (
      <FormattedMessage {...testResultsTableHeadings.diagnosticTestResultId} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.diagnosticTestResultId}>
        <DataGridLink
          path="/diagnostic-test-results"
          id={item.diagnosticTestResultId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'deviceProductionId',
    label: (
      <FormattedMessage {...testResultsTableHeadings.deviceProductionId} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.deviceProductionId}>
        <DataGridLink path="/butterfly-devices" id={item.deviceProductionId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'results',
    label: <FormattedMessage {...testResultsTableHeadings.testResultPassed} />,
    render: ({ item }) => {
      const testResults = (item.results as any)?.results;
      if (!testResults) {
        return <Text>-</Text>;
      }
      return (
        <Text>
          {testResults.passed ? (
            <FormattedMessage {...actionMessages.pass} />
          ) : (
            <FormattedMessage {...actionMessages.fail} />
          )}
        </Text>
      );
    },
  },
  {
    key: 'testVersion',
    label: <FormattedMessage {...testResultsTableHeadings.testVersion} />,
    render: ({ item }) => <DataGridText value={item.testVersion} />,
  },
  {
    key: 'createdAt',
    label: <FormattedMessage {...tableHeadings.createdAt} />,
    render: ({ item }) => <DataGridDateTime value={item.createdAt} />,
  },
];

interface DiagnosticTestResultsGridProps {
  viewer: DiagnosticTestResultsGrid_viewer$key;
}

export default function DiagnosticTestResultsGrid({
  viewer: viewerRef,
}: DiagnosticTestResultsGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(
    fragment,
    viewerRef,
  );
  const nodes = getNodes(data!.diagnosticTestResults);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
    />
  );
}
