import { defineMessages } from 'react-intl';

export const userIdMessages = defineMessages({
  label: {
    id: 'newUser.userId.label',
    defaultMessage: 'User ID*',
  },
  placeholder: {
    id: 'newUser.userId.placeholder',
    defaultMessage: 'User ID',
  },
  required: {
    id: 'newUser.userId.required',
    defaultMessage: 'Please enter a user ID',
  },
});

export const userNameMessages = defineMessages({
  label: {
    id: 'newUser.name.label',
    defaultMessage: 'User Name*',
  },
  placeholder: {
    id: 'newUser.name.placeholder',
    defaultMessage: 'User Name',
  },
  required: {
    id: 'newUser.name.required',
    defaultMessage: 'Please enter a user name',
  },
  length: {
    id: 'newUser.name.length',
    defaultMessage: 'User name must be at least 2 characters long',
  },
});

export const userEmailMessages = defineMessages({
  label: {
    id: 'newUser.email.label',
    defaultMessage: 'User Email*',
  },
  placeholder: {
    id: 'newUser.email.placeholder',
    defaultMessage: 'User Email',
  },
  description: {
    id: 'newUser.email.description',
    defaultMessage:
      'User email address (no confirmation will be sent to this email)',
  },
  required: {
    id: 'newUser.email.required',
    defaultMessage: 'Please enter a user email',
  },
  alreadyExists: {
    id: 'newUser.email.exist',
    defaultMessage: 'This email is already inuse',
  },
});

export const userPasswordMessages = defineMessages({
  label: {
    id: 'newUser.password.label',
    defaultMessage: 'Password',
  },
  placeholder: {
    id: 'newUser.password.placeholder',
    defaultMessage: 'User Password',
  },
  required: {
    id: 'newUser.password.required',
    defaultMessage: 'Please enter a password',
  },
  length: {
    id: 'newUser.password.length',
    defaultMessage: 'Password must be at least 8 characters long',
  },
  validation: {
    id: 'newUser.password.validation',
    defaultMessage:
      'Please choose a stronger password. Try a mix of letters, numbers, and symbols',
  },
});

export const userSpecialtyKeyMessages = defineMessages({
  label: {
    id: 'newUser.specialty.label',
    defaultMessage: 'Specialty*',
  },
  required: {
    id: 'newUser.specialty.required',
    defaultMessage: 'Specialty is a required field',
  },
  placeholder: {
    id: 'newUser.specialty.placeholder',
    defaultMessage: 'Type to search specialty',
  },
  description: {
    id: 'newUser.specialty.description',
    defaultMessage:
      "You can choose from the list or type the user's specialty",
  },
});

export const userPlaceOfWorkFreeformMessages = defineMessages({
  label: {
    id: 'newUser.placeOfWorkFreeform.label',
    defaultMessage: 'Place of Work Freeform',
  },
  placeholder: {
    id: 'newUser.placeOfWorkFreeform.placeholder',
    defaultMessage: 'Place of Work Freeform',
  },
  description: {
    id: 'newUser.placeOfWorkFreeform.description',
    defaultMessage: "If place of work is not listed, the user's place of work",
  },
});

export const userPhoneNumberMessages = defineMessages({
  label: {
    id: 'newUser.phoneNumber.label',
    defaultMessage: 'User Phone',
  },
  placeholder: {
    id: 'newUser.phoneNumber.placeholder',
    defaultMessage: 'Phone Number',
  },
  description: {
    id: 'newUser.phoneNumber.description',
    defaultMessage: "The user's phone number",
  },
  matches: {
    id: 'newUser.phoneNumber.matches',
    defaultMessage:
      'Value is not a valid phone number of the form +17895551234 (10-15 digits)',
  },
});
