/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateIosVersionBlacklistEntryModal_viewer = {
    readonly id: string;
    readonly " $refType": "CreateIosVersionBlacklistEntryModal_viewer";
};
export type CreateIosVersionBlacklistEntryModal_viewer$data = CreateIosVersionBlacklistEntryModal_viewer;
export type CreateIosVersionBlacklistEntryModal_viewer$key = {
    readonly " $data"?: CreateIosVersionBlacklistEntryModal_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CreateIosVersionBlacklistEntryModal_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateIosVersionBlacklistEntryModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'b805aa70fda3ff8815619ca12f90da5b';
export default node;
