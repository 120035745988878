import { defineMessages } from 'react-intl';

export const domainUserActions = defineMessages({
  setType: {
    id: 'domainUserActions.setType',
    defaultMessage: 'Set Type',
  },
  setRole: {
    id: 'domainUserActions.setRole',
    defaultMessage: 'Set Role',
  },
});

export const setFlatUserRole = defineMessages({
  title: {
    id: 'setFlatUserRole.title',
    defaultMessage: 'Set User Role',
  },
  submitText: {
    id: 'setFlatUserRole.submitText',
    defaultMessage: 'Set User Role',
  },
});

export const userRoleMessages = defineMessages({
  required: {
    id: 'userRole.required',
    defaultMessage: 'Please select a role',
  },
  label: {
    id: 'userRole.label',
    defaultMessage: 'User Role*',
  },
  placeholder: {
    id: 'userRole.placeholder',
    defaultMessage: 'User Role',
  },
});
