import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';

import countryTableHeadings from '../messages/countryTableHeadings';
import { CountriesGrid_country as Country } from './__generated__/CountriesGrid_country.graphql';
import { CountriesGrid_viewer$key } from './__generated__/CountriesGrid_viewer.graphql';

const _ = graphql`
  fragment CountriesGrid_country on Country {
    id
    code
    name
    enabled
    countryConfiguration {
      dataRegion
      configuration
    }
  }
`;

const fragment = graphql`
  fragment CountriesGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "CountriesGridRefetchQuery") {
    countries(first: $count, after: $cursor)
      @connection(key: "CountriesGrid_countries", filters: []) {
      edges {
        node {
          ...CountriesGrid_country @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Country>[] = [
  {
    key: 'name',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridLink path="/countries" id={item.code} title={item.name!} />
      </QuickCopyText>
    ),
  },
  {
    key: 'code',
    label: <FormattedMessage {...countryTableHeadings.code} />,
    render: ({ item }) => (
      <QuickCopyText text={item.code}>
        <DataGridLink path="/countries" id={item.code} />
      </QuickCopyText>
    ),
  },
  {
    key: 'dataRegion',
    frozen: true,
    label: <FormattedMessage {...countryTableHeadings.dataRegion} />,
    render: ({ item }) => (
      <QuickCopyText text={item.countryConfiguration?.dataRegion}>
        <DataGridText value={item.countryConfiguration?.dataRegion} />
      </QuickCopyText>
    ),
  },
];

interface CountriesGridProps {
  viewer: CountriesGrid_viewer$key;
}

export default function CountriesGrid({
  viewer: viewerRef,
}: CountriesGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(
    fragment,
    viewerRef,
  );
  const nodes = data.countries ? getNodes(data.countries) : [];

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
    />
  );
}
