import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import FlatUsersDetails from './components/FlatUserDetailPage';
import FlatUsersGrid from './components/FlatUsersGrid';
import FlatUsersPage from './components/FlatUsersPage';

export default (
  <Route path="users">
    <Route
      Component={FlatUsersPage}
      query={graphql`
        query users_UsersPage_Query {
          viewer {
            ...FlatUsersPage_viewer
          }
        }
      `}
    >
      <Route
        Component={FlatUsersGrid}
        query={graphql`
          query users_UsersGrid_Query(
            $id: [String!]
            $domainId: [String!]
            $email: [String!]
            $emailDomain: [String!]
            $phoneNumber: [String!]
            $roleId: [String!]
            $name: [String!]
            $isUserDeleted: [String!]
          ) {
            viewer {
              ...FlatUsersGrid_viewer
                @arguments(
                  id: $id
                  domainId: $domainId
                  email: $email
                  emailDomain: $emailDomain
                  phoneNumber: $phoneNumber
                  roleId: $roleId
                  name: $name
                  isUserDeleted: $isUserDeleted
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          id: location.query.id,
          email: location.query.email,
          domainId: location.query.domainId,
          emailDomain: location.query.emailDomain,
          phoneNumber: location.query.phoneNumber,
          roleId: location.query.roleId,
          name: location.query.name,
          isUserDeleted:
            location.query.isUserDeleted === 'true' ||
            location.query.isUserDeleted === 'false'
              ? location.query.isUserDeleted
              : undefined,
        })}
      />
    </Route>
    <Route
      path=":userId/:domainId"
      Component={FlatUsersDetails}
      prepareVariables={({ userId, domainId, ...params }) => ({
        ...params,
        // TODO remove
        id: userId ? btoa(`FlatUser:${userId}/${domainId || '-'}`) : undefined,
        domainId: domainId !== '-' ? domainId : undefined,
        skipRolesQuery: !domainId || domainId === '-',
      })}
      query={graphql`
        query users_FlatUserDetailPage_Query(
          $id: ID!
          $domainId: [String!]
          $skipRolesQuery: Boolean!
        ) {
          flatUser: node(id: $id) {
            ...FlatUserDetailPage_flatUser
          }
          viewer {
            ...SetFlatUserRoleAction_roles
            ...FlatUserDetailPage_roles
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.flatUser) throw new HttpError(404);
      }}
    />
  </Route>
);
