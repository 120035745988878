import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import actionMessages from 'messages/actions';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import { DiagnosticTestResultDetailPage_diagnosticTestResult$key } from './__generated__/DiagnosticTestResultDetailPage_diagnosticTestResult.graphql';

const fragment = graphql`
  fragment DiagnosticTestResultDetailPage_diagnosticTestResult on DiagnosticTestResult {
    id
    diagnosticTestResultId
    deviceProductionId
    createdAt
    testVersion
    results
  }
`;

interface DiagnosticTestResultDetailPageProps {
  diagnosticTestResult: DiagnosticTestResultDetailPage_diagnosticTestResult$key;
}

export default function DiagnosticTestResultDetailPage({
  diagnosticTestResult: diagnosticTestResultRef,
}: DiagnosticTestResultDetailPageProps) {
  let diagnosticTestResult = useFragment(fragment, diagnosticTestResultRef);
  const { results, ...data } = diagnosticTestResult;
  const { deviceProductionId, diagnosticTestResultId } = data;
  const { canWrite } = usePermissionsContext();

  useBreadcrumbDetector(
    pageTitles.diagnosticTestResult,
    diagnosticTestResultId,
  );

  if ((results as Record<string, unknown>)?.results) {
    const { results: innerResults, ...innerResultsRest } = results as Record<
      string,
      unknown
    >;
    const { results: innerResultsData, ...innerResultsDataRest } =
      innerResults as Record<string, unknown>;

    // The "results" field should be the last one to improve UI/UX [KSP-495]
    diagnosticTestResult = {
      ...data,
      results: {
        ...innerResultsRest,
        results: {
          ...innerResultsDataRest,
          passed: innerResultsDataRest.passed
            ? actionMessages.pass.defaultMessage
            : actionMessages.fail.defaultMessage,
          results: innerResultsData,
        },
      },
    };
  }

  return (
    <PermissionsGuard resource={Resource.DIAGNOSTIC_TEST_RESULTS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.diagnosticTestResult} />}
        name={diagnosticTestResult.deviceProductionId!}
        data={diagnosticTestResult}
        quickCopyFields={['diagnosticTestResultId', 'deviceProductionId']}
        relatedItems={
          canWrite(Resource.DOMAIN_USERS) && (
            <RelatedItem
              name="Butterfly Device"
              to={`/butterfly-devices/${deviceProductionId}`}
            >
              <FormattedMessage
                id="diagnosticTestResultDetail.relatedItem.butterflyDevice"
                defaultMessage="Butterfly Device"
              />
            </RelatedItem>
          )
        }
      />
    </PermissionsGuard>
  );
}
