/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteEhrInput = {
    ehrId: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteEhrActionMutationVariables = {
    input: DeleteEhrInput;
};
export type DeleteEhrActionMutationResponse = {
    readonly deleteEhr: {
        readonly __typename: string;
    } | null;
};
export type DeleteEhrActionMutation = {
    readonly response: DeleteEhrActionMutationResponse;
    readonly variables: DeleteEhrActionMutationVariables;
};



/*
mutation DeleteEhrActionMutation(
  $input: DeleteEhrInput!
) {
  deleteEhr(input: $input) {
    __typename
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteEhrPayload",
    "kind": "LinkedField",
    "name": "deleteEhr",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteEhrActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteEhrActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c0a8d7f9bee17e471385b620a95020d5",
    "id": null,
    "metadata": {},
    "name": "DeleteEhrActionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteEhrActionMutation(\n  $input: DeleteEhrInput!\n) {\n  deleteEhr(input: $input) {\n    __typename\n  }\n}\n"
  }
};
})();
(node as any).hash = '104269e7ebe2c60171d4697798c75b80';
export default node;
