/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InvitesGrid_organizationInvite = {
    readonly id: string;
    readonly organizationInviteId: string | null;
    readonly email: string | null;
    readonly roleId: string | null;
    readonly makeOrganizationAdmin: boolean | null;
    readonly createdAt: string | null;
    readonly deletedAt: string | null;
    readonly organization: {
        readonly organizationId: string | null;
        readonly name: string | null;
    } | null;
    readonly " $refType": "InvitesGrid_organizationInvite";
};
export type InvitesGrid_organizationInvite$data = InvitesGrid_organizationInvite;
export type InvitesGrid_organizationInvite$key = {
    readonly " $data"?: InvitesGrid_organizationInvite$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"InvitesGrid_organizationInvite">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitesGrid_organizationInvite",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationInviteId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "makeOrganizationAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationInvite",
  "abstractKey": null
};
(node as any).hash = 'cc6911b66b674eeb412e5caae5911029';
export default node;
