/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type educationContent_EducationContentGrid_QueryVariables = {
    name?: Array<string> | null | undefined;
    absorbId?: Array<string> | null | undefined;
    contentType?: Array<string> | null | undefined;
    practiceType?: Array<string> | null | undefined;
    categoryId?: Array<string> | null | undefined;
    categoryName?: Array<string> | null | undefined;
};
export type educationContent_EducationContentGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"EducationContentGrid_viewer">;
    } | null;
};
export type educationContent_EducationContentGrid_Query = {
    readonly response: educationContent_EducationContentGrid_QueryResponse;
    readonly variables: educationContent_EducationContentGrid_QueryVariables;
};



/*
query educationContent_EducationContentGrid_Query(
  $name: [String!]
  $absorbId: [String!]
  $contentType: [String!]
  $practiceType: [String!]
  $categoryId: [String!]
  $categoryName: [String!]
) {
  viewer {
    ...EducationContentGrid_viewer_2Vgh3F
    id
  }
}

fragment EducationContentGrid_viewer_2Vgh3F on Viewer {
  educationContent(first: 20, name: $name, absorbId: $absorbId, contentType: $contentType, practiceType: $practiceType, categoryId: $categoryId, categoryName: $categoryName) {
    edges {
      node {
        id
        educationContentId
        name
        description
        type
        contentType
        practiceType
        isPremium
        vimeoId
        absorbId
        duration
        ranking
        status
        presetLocalIds
        toolLocalIds
        captureModes
        deletedAt
        authoredBy {
          name
          id
        }
        category {
          educationCategoryId
          name
          id
        }
        courseLanguage
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "absorbId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practiceType"
},
v6 = {
  "kind": "Variable",
  "name": "absorbId",
  "variableName": "absorbId"
},
v7 = {
  "kind": "Variable",
  "name": "categoryId",
  "variableName": "categoryId"
},
v8 = {
  "kind": "Variable",
  "name": "categoryName",
  "variableName": "categoryName"
},
v9 = {
  "kind": "Variable",
  "name": "contentType",
  "variableName": "contentType"
},
v10 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v11 = {
  "kind": "Variable",
  "name": "practiceType",
  "variableName": "practiceType"
},
v12 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "educationContent_EducationContentGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "EducationContentGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "educationContent_EducationContentGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v12/*: any*/),
            "concreteType": "EducationContentConnection",
            "kind": "LinkedField",
            "name": "educationContent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EducationContentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EducationContent",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "educationContentId",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "practiceType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isPremium",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vimeoId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "absorbId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ranking",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "presetLocalIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "toolLocalIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "captureModes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "authoredBy",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EducationCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "educationCategoryId",
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "courseLanguage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v12/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "EducationContentGrid_educationContent",
            "kind": "LinkedHandle",
            "name": "educationContent"
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70e72167919e1e73023c16565a470061",
    "id": null,
    "metadata": {},
    "name": "educationContent_EducationContentGrid_Query",
    "operationKind": "query",
    "text": "query educationContent_EducationContentGrid_Query(\n  $name: [String!]\n  $absorbId: [String!]\n  $contentType: [String!]\n  $practiceType: [String!]\n  $categoryId: [String!]\n  $categoryName: [String!]\n) {\n  viewer {\n    ...EducationContentGrid_viewer_2Vgh3F\n    id\n  }\n}\n\nfragment EducationContentGrid_viewer_2Vgh3F on Viewer {\n  educationContent(first: 20, name: $name, absorbId: $absorbId, contentType: $contentType, practiceType: $practiceType, categoryId: $categoryId, categoryName: $categoryName) {\n    edges {\n      node {\n        id\n        educationContentId\n        name\n        description\n        type\n        contentType\n        practiceType\n        isPremium\n        vimeoId\n        absorbId\n        duration\n        ranking\n        status\n        presetLocalIds\n        toolLocalIds\n        captureModes\n        deletedAt\n        authoredBy {\n          name\n          id\n        }\n        category {\n          educationCategoryId\n          name\n          id\n        }\n        courseLanguage\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '92eeb9a3a1b4f2795e898aea4f51c41b';
export default node;
