/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteUserAction_user = {
    readonly name: string | null;
    readonly userId: string | null;
    readonly userDeletedAt: string | null;
    readonly " $refType": "DeleteUserAction_user";
};
export type DeleteUserAction_user$data = DeleteUserAction_user;
export type DeleteUserAction_user$key = {
    readonly " $data"?: DeleteUserAction_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteUserAction_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteUserAction_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userDeletedAt",
      "storageKey": null
    }
  ],
  "type": "FlatUser",
  "abstractKey": null
};
(node as any).hash = 'a451d37cae2295a64658823d2394ebc2';
export default node;
