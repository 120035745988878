import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridBoolean from 'components/DataGridBoolean';
import DataGridDateTime from 'components/DataGridDateTime';
import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';

import butterflyDevicesTableHeadings from '../messages/butterflyDevicesTableHeadings';
import { ButterflyDevicesGrid_butterflyDevice as ButterflyDevice } from './__generated__/ButterflyDevicesGrid_butterflyDevice.graphql';
import { ButterflyDevicesGrid_viewer$key } from './__generated__/ButterflyDevicesGrid_viewer.graphql';

const _ = graphql`
  fragment ButterflyDevicesGrid_butterflyDevice on ButterflyDevice {
    id
    productionId
    registeredAt
    isEnabled
    disabledStatusMessage
    lostAt
    stolenAt
    recallId
    subscription {
      subscriptionId
    }
    hasActiveDiagnosticTestRequest
    lastDiagnosticTestRequestMinTestVersion
  }
`;

const fragment = graphql`
  fragment ButterflyDevicesGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    productionId: { type: "[String!]" }
    organizationId: { type: "[String!]" }
  )
  @refetchable(queryName: "ButterflyDevicesGridRefetchQuery") {
    butterflyDevices(
      first: $count
      after: $cursor
      productionId: $productionId
      organizationId: $organizationId
    ) @connection(key: "ButterflyDevicesGrid_butterflyDevices") {
      edges {
        node {
          ...ButterflyDevicesGrid_butterflyDevice @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<ButterflyDevice>[] = [
  {
    key: 'productionId',
    frozen: true,
    label: (
      <FormattedMessage {...butterflyDevicesTableHeadings.productionId} />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.productionId}>
        <DataGridLink path="/butterfly-devices" id={item.productionId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'registeredAt',
    label: (
      <FormattedMessage {...butterflyDevicesTableHeadings.registeredAt} />
    ),
    render: ({ item }) => <DataGridDateTime value={item.registeredAt} />,
  },
  {
    key: 'isEnabled',
    label: <FormattedMessage {...butterflyDevicesTableHeadings.isEnabled} />,
    render: ({ item }) => <DataGridBoolean value={item.isEnabled} />,
  },
  {
    key: 'disabledStatusMessage',
    label: (
      <FormattedMessage
        {...butterflyDevicesTableHeadings.disabledStatusMessage}
      />
    ),
    render: ({ item }) => <DataGridText value={item.disabledStatusMessage} />,
  },
  {
    key: 'lostAt',
    label: <FormattedMessage {...butterflyDevicesTableHeadings.lostAt} />,
    render: ({ item }) => <DataGridDateTime value={item.lostAt} />,
  },
  {
    key: 'stolenAt',
    label: <FormattedMessage {...butterflyDevicesTableHeadings.stolenAt} />,
    render: ({ item }) => <DataGridDateTime value={item.stolenAt} />,
  },
  {
    key: 'recallId',
    label: <FormattedMessage {...butterflyDevicesTableHeadings.recallId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.recallId}>
        <DataGridLink path="/butterfly-device-recalls" id={item.recallId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'subscriptionId',
    label: <FormattedMessage {...tableHeadings.subscriptionId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.subscription?.subscriptionId}>
        <DataGridLink
          path="/subscriptions"
          id={item.subscription?.subscriptionId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'hasActiveDiagnosticTestRequest',
    label: (
      <FormattedMessage
        {...butterflyDevicesTableHeadings.hasActiveDiagnosticTestRequest}
      />
    ),
    render: ({ item }) => (
      <DataGridBoolean value={item.hasActiveDiagnosticTestRequest} />
    ),
  },
  {
    key: 'lastDiagnosticTestRequestMinTestVersion',
    label: (
      <FormattedMessage
        {...butterflyDevicesTableHeadings.lastDiagnosticTestRequestMinTestVersion}
      />
    ),
    render: ({ item }) => (
      <DataGridText value={item.lastDiagnosticTestRequestMinTestVersion} />
    ),
  },
];

interface ButterflyDevicesGridProps {
  viewer: ButterflyDevicesGrid_viewer$key;
}

export default function ButterflyDevicesGrid({
  viewer,
}: ButterflyDevicesGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.butterflyDevices);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
    />
  );
}
