/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type BillingStatus = "ACTIVE" | "CANCELED" | "PAST_DUE" | "TRIALING" | "UNPAID" | "%future added value";
export type PlanType = "BASIC" | "CLINIC" | "DEPARTMENT" | "EDUCATION" | "EMS" | "ENTERPRISE_SECURITY" | "ENTERPRISE_WORKFLOW" | "GOVERNMENT" | "HOSPITAL" | "INDIVIDUAL" | "INDIVIDUAL_FOREVER" | "MEDICAL_SCHOOL" | "NONE" | "PRO_CUSTOM" | "PRO_THREE_YEAR" | "PRO_TRIAL" | "RESIDENCY_PROGRAM" | "TEAM" | "%future added value";
export type PracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type UpdateSubscriptionModal_subscription = {
    readonly id: string;
    readonly subscriptionId: string | null;
    readonly subscriptionEndsAt: string | null;
    readonly accessEndsAt: string | null;
    readonly hasStartedActiveSubscription: boolean | null;
    readonly hasCompletedTrial: boolean | null;
    readonly canAccessProFeatures: boolean | null;
    readonly isInGracePeriod: boolean | null;
    readonly isTeam: boolean | null;
    readonly billingStatus: BillingStatus | null;
    readonly billingProvider: BillingProvider | null;
    readonly stripeSubscriptionId: string | null;
    readonly sfSubscriptionId: string | null;
    readonly featureModuleIds: ReadonlyArray<string | null> | null;
    readonly planType: PlanType | null;
    readonly maxNumSeats: number | null;
    readonly practiceType: PracticeType | null;
    readonly domain: {
        readonly domainId: string | null;
    } | null;
    readonly customer: {
        readonly customerId: string | null;
    } | null;
    readonly " $refType": "UpdateSubscriptionModal_subscription";
};
export type UpdateSubscriptionModal_subscription$data = UpdateSubscriptionModal_subscription;
export type UpdateSubscriptionModal_subscription$key = {
    readonly " $data"?: UpdateSubscriptionModal_subscription$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateSubscriptionModal_subscription">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSubscriptionModal_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasStartedActiveSubscription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCompletedTrial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessProFeatures",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInGracePeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTeam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeSubscriptionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sfSubscriptionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featureModuleIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxNumSeats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domainId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};
(node as any).hash = '0d010ca3a324d873c3724e6886f216fa';
export default node;
