/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type OrganizationMembershipType = "ADMIN" | "GUEST" | "REGULAR" | "%future added value";
export type RoleType = "PUBLIC_ORG" | "SYSTEM_DEFINED" | "USER_DEFINED" | "%future added value";
export type UpdateOrganizationMembershipInput = {
    organizationMembershipId: string;
    type?: OrganizationMembershipType | null | undefined;
    roleId?: string | null | undefined;
    canQa?: boolean | null | undefined;
    canFinalize?: boolean | null | undefined;
    isNurse?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type SetMembershipRoleActionMutationVariables = {
    input: UpdateOrganizationMembershipInput;
};
export type SetMembershipRoleActionMutationResponse = {
    readonly updateOrganizationMembership: {
        readonly organizationMembership: {
            readonly role: {
                readonly id: string;
                readonly roleId: string | null;
                readonly name: string | null;
                readonly roleType: RoleType | null;
            } | null;
        } | null;
    } | null;
};
export type SetMembershipRoleActionMutation = {
    readonly response: SetMembershipRoleActionMutationResponse;
    readonly variables: SetMembershipRoleActionMutationVariables;
};



/*
mutation SetMembershipRoleActionMutation(
  $input: UpdateOrganizationMembershipInput!
) {
  updateOrganizationMembership(input: $input) {
    organizationMembership {
      role {
        id
        roleId
        name
        roleType
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MembershipRole",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetMembershipRoleActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "organizationMembership",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetMembershipRoleActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "organizationMembership",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c755b65e58970982aaa7ad3a2f822d48",
    "id": null,
    "metadata": {},
    "name": "SetMembershipRoleActionMutation",
    "operationKind": "mutation",
    "text": "mutation SetMembershipRoleActionMutation(\n  $input: UpdateOrganizationMembershipInput!\n) {\n  updateOrganizationMembership(input: $input) {\n    organizationMembership {\n      role {\n        id\n        roleId\n        name\n        roleType\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '16ca4df209a461fe7a25b7e589f27e4e';
export default node;
