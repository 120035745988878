import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { object, string } from 'yup';

import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';

import {
  messageMessages,
  recallIdMessages,
} from '../messages/butterflyDeviceRecallsCreation';

export const butterflyDeviceRecallSchema = object({
  recallId: string().meta({ readOnly: true }),
  message: string().required(messageMessages.required),
});

export function ButterflyDeviceRecallModal<
  TMutation extends MutationParameters,
>({ defaultValue, ...props }: ResourceModalProps<TMutation>) {
  const isCreate = !defaultValue?.recallId;
  return (
    <ResourceModal<TMutation> {...props} defaultValue={defaultValue}>
      {!isCreate && (
        <Form.FieldGroup
          name="recallId"
          disabled
          label={<FormattedMessage {...recallIdMessages.label} />}
          placeholder={recallIdMessages.placeholder}
          data-cy="butterflyDeviceRecall-recallIdField"
        />
      )}
      <Form.FieldGroup
        name="message"
        label={<FormattedMessage {...messageMessages.label} />}
        placeholder={messageMessages.placeholder}
        description={<FormattedMessage {...messageMessages.description} />}
        data-cy="butterflyDeviceRecall-messageField"
      />
    </ResourceModal>
  );
}
