/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteEducationCourseAction_educationContent = {
    readonly name: string | null;
    readonly educationContentId: string | null;
    readonly deletedAt: string | null;
    readonly " $refType": "DeleteEducationCourseAction_educationContent";
};
export type DeleteEducationCourseAction_educationContent$data = DeleteEducationCourseAction_educationContent;
export type DeleteEducationCourseAction_educationContent$key = {
    readonly " $data"?: DeleteEducationCourseAction_educationContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteEducationCourseAction_educationContent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteEducationCourseAction_educationContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationContentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletedAt",
      "storageKey": null
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
(node as any).hash = 'b7aec8c93050861b2ae2725e28a20f05';
export default node;
