import { defineMessages } from 'react-intl';

export default defineMessages({
  androidVersionBlacklistEntryId: {
    id: 'androidVersionBlacklistEntriesTable.heading.androidVersionBlacklistEntryId',
    defaultMessage: 'Android Version Recall Entry ID',
  },
  versionCode: {
    id: 'androidVersionBlacklistEntriesTable.heading.versionCode',
    defaultMessage: 'Version Code',
  },
});
