/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RequestDiagnosticTestAction_butterflyDevice = {
    readonly id: string;
    readonly productionId: string | null;
    readonly lastDiagnosticTestRequestMinTestVersion: number | null;
    readonly " $refType": "RequestDiagnosticTestAction_butterflyDevice";
};
export type RequestDiagnosticTestAction_butterflyDevice$data = RequestDiagnosticTestAction_butterflyDevice;
export type RequestDiagnosticTestAction_butterflyDevice$key = {
    readonly " $data"?: RequestDiagnosticTestAction_butterflyDevice$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RequestDiagnosticTestAction_butterflyDevice">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequestDiagnosticTestAction_butterflyDevice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastDiagnosticTestRequestMinTestVersion",
      "storageKey": null
    }
  ],
  "type": "ButterflyDevice",
  "abstractKey": null
};
(node as any).hash = 'fcf536575850b20c76124b2de87d3919';
export default node;
