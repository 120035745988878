import { RedirectException, makeRouteConfig } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import LandingPage from './components/LandingPage';
import RootPage from './components/RootPage';
import accessRolesRoutes from './routes/accessRoles';
import androidVersionBlacklistEntries from './routes/androidVersionBlacklistEntries';
import applicationVersionRequirementsRoutes from './routes/applicationVersionRequirements';
import butterflyDeviceRecalls from './routes/butterflyDeviceRecalls';
import butterflyDevices from './routes/butterflyDevices';
import countryRoutes from './routes/countries';
import customers from './routes/customers';
import diagnosticTestResultRoutes from './routes/diagnosticTestResults';
import domainInviteRoutes from './routes/domainInvites';
import domainUserIntegrationConfigs from './routes/domainUserIntegrationConfigs';
import domainRoutes from './routes/domains';
import educationCategoriesRoutes from './routes/educationCategories';
import educationContentRoutes from './routes/educationContent';
import ehrs from './routes/ehrs';
import emailChangeRequestRoutes from './routes/emailChangeRequests';
import inviteRoutes from './routes/invites';
import iosVersionBlacklistEntries from './routes/iosVersionBlacklistEntries';
import membershipRoutes from './routes/memberships';
import organizationRoutes from './routes/organizations';
import placesOfWorkRoutes from './routes/placesOfWork';
import subscriptionRoutes from './routes/subscriptions';
import userRoutes from './routes/users';

export default makeRouteConfig(
  <>
    <Route
      path="/-/(.*)?"
      render={({ match: { location } }) => {
        throw new RedirectException({
          ...location,
          pathname: location.pathname.slice(2),
        });
      }}
    />
    <Route
      path="/"
      query={graphql`
        query routeConfig_RootPage_Query {
          viewer {
            ...RootPage_viewer
          }
        }
      `}
      Component={RootPage}
    >
      <Route Component={LandingPage} />
      {butterflyDevices}
      {butterflyDeviceRecalls}
      {domainRoutes}
      {userRoutes}
      {diagnosticTestResultRoutes}
      {inviteRoutes}
      {domainUserIntegrationConfigs}
      {organizationRoutes}
      {placesOfWorkRoutes}
      {customers}
      {membershipRoutes}
      {subscriptionRoutes}
      {educationContentRoutes}
      {applicationVersionRequirementsRoutes}
      {educationCategoriesRoutes}
      {ehrs}
      {androidVersionBlacklistEntries}
      {iosVersionBlacklistEntries}
      {accessRolesRoutes}
      {domainInviteRoutes}
      {emailChangeRequestRoutes}
      {countryRoutes}
    </Route>
  </>,
);
