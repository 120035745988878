import Header from '@bfly/ui2/Header';
import clsx from 'clsx';
import React from 'react';

interface SectionHeaderProps {
  children?: React.ReactNode;
  className?: string;
}

export default function SectionHeader({
  children,
  className,
}: SectionHeaderProps) {
  return (
    <Header className={clsx(className, 'flex-col p-2 h-auto sm:flex-row')}>
      {children}
    </Header>
  );
}
