import Layout from '@4c/layout';
import PlusIcon from '@bfly/icons/Plus';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import FormControlWithAddon from '@bfly/ui2/FormControlWithAddon';
import SrOnly from '@bfly/ui2/SrOnly';
import Text from '@bfly/ui2/Text';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { css } from 'astroturf';
import { useRouter } from 'found';
import debounce from 'lodash/debounce';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import Tag from 'react-widgets/MultiselectTag';

import DropdownList from 'components/DropdownList';

export interface FilterField {
  title: string;
  field: string;
  isBoolean?: boolean;
}
interface SearchParams {
  filter: string;
  value: string;
}
/**
 * [KSP-48] Add "+" to the phone number if it is missed in search parameters
 * @param params
 */
const formatPhoneNumber = (params: SearchParams[]) => {
  return params.map(({ filter, value }: SearchParams) => {
    return filter === 'phoneNumber' && value.length && !value.startsWith('+')
      ? { filter, value: `+${value}` }
      : { filter, value };
  });
};

/**
 * Generates a search query string from search parameters. The string value is
 * used to preserve the order of the parameters.
 * @param params
 */
const getQuerySearch = (params: SearchParams[]) => {
  const search = `${formatPhoneNumber(params)
    .filter(({ filter, value }) => !!filter && !!value.trim())
    .map(({ filter, value }) => `${filter}=${encodeURIComponent(value)}`)
    .join('&')}`;

  return search ? `?${search}` : '';
};
/**
 * Generates search parameters from a search query string. The string value is
 * used to preserve the order of the parameters.
 * @param search
 * @param filters
 */
const parseSearchParams = (
  search: string,
  filters: FilterField[],
): SearchParams[] => {
  const result: SearchParams[] = [];
  const searchParams = new URLSearchParams(search);
  const filterFields = filters.map((filter) => filter.field);

  searchParams.forEach((value, filter) => {
    if (filterFields.includes(filter)) {
      result.push({ filter, value });
    }
  });

  return result;
};

/**
 * Returns the search form value. The last parameter of the URL query is
 * the value of the search form.
 * @param params
 * @param previousSearch
 */
const getActiveSearch = (params: SearchParams[], previousSearch: string) => {
  return params.length
    ? params[params.length - 1]
    : { filter: previousSearch || '', value: '' };
};
/**
 * Returns a list of search tags. All URL query parameters, except the last one,
 * become search tags.
 * @param params
 */
const getSearchTags = (params: SearchParams[]) => {
  return params.length > 1 ? params.slice(0, -1) : [];
};

interface SearchTagProps {
  dataItem: number;
  onRemove: (value: number) => void;
}

function SearchTag({
  dataItem,
  onRemove,
  children,
}: PropsWithChildren<SearchTagProps>) {
  return (
    <Tag
      className="my-3 sm:mt-0"
      css={css`
        @import '~@bfly/ui/styles/theme';
        -form-control-tag-color: #{$input-dark-tag-color};
        --form-control-tag-bg-color: #{$input-dark-tag-bg-color};
        --form-control-tag-border-color: #{$input-dark-tag-border-color};
        --form-control-tag-hover-bg-color: #{$input-dark-tag-hover-bg-color};
        & :global(.rw-multiselect-tag-btn) {
          font-size: 2rem;
          border-left: 2px solid $bg-color;
        }
      `}
      dataItem={dataItem}
      onRemove={onRemove}
      clearTagIcon={<Text variant="inherit">&times;</Text>}
    >
      {children}
    </Tag>
  );
}

interface ResourceGridFilterProps {
  filterFields: FilterField[];
}
function convertString(value: string): string {
  if (value === 'true') {
    return 'Yes';
  }
  if (value === 'false') {
    return 'No';
  }
  return value;
}

export default function ResourceGridFilter({
  filterFields,
}: ResourceGridFilterProps) {
  const { match, router } = useRouter();
  const { location } = match;
  const { search } = location;

  const [pathname] = useState(location.pathname);
  const [previousSearch, setPreviousSearch] = useState('');
  const [initialSearchParams] = useState(
    parseSearchParams(search, filterFields),
  );

  const [searchTags, setSearchTags] = useState<SearchParams[]>(
    getSearchTags(initialSearchParams),
  );

  const [activeSearch, setActiveSearch] = useState<SearchParams>(
    getActiveSearch(initialSearchParams, previousSearch),
  );

  const filterLabels = useMemo(() => {
    return filterFields.reduce((dictionary, filter) => {
      return { ...dictionary, [filter.field]: filter.title };
    }, {});
  }, [filterFields]);

  const filterBooleans = useMemo(() => {
    return filterFields.reduce((dictionary, filter) => {
      return { ...dictionary, [filter.field]: filter.isBoolean };
    }, {});
  }, [filterFields]);

  const updateURL = useMemo(
    () =>
      debounce((value) => {
        router.replace({
          pathname,
          search: value,
        });
      }, 500),
    [router, pathname],
  );

  useUpdateEffect(() => {
    const searchParams = parseSearchParams(search, filterFields);
    setSearchTags(getSearchTags(searchParams));
    setActiveSearch(getActiveSearch(searchParams, previousSearch));
  }, [search, filterFields]);

  useUpdateEffect(() => {
    const searchQuery = getQuerySearch([...searchTags, activeSearch]);
    if (search !== searchQuery) {
      updateURL(searchQuery);
    }
  }, [activeSearch, searchTags, updateURL]);

  useEffect(() => {
    return () => {
      updateURL.cancel(); // cancels the debounced action when leaving the page
    };
  }, [updateURL]);

  const availableFilters = useMemo(() => {
    const usedFilters = searchTags.map(({ filter }) => filter);
    return filterFields.filter(
      (filter) => !usedFilters.includes(filter.field),
    );
  }, [filterFields, searchTags]);
  const handleClear = () => {
    setPreviousSearch(activeSearch.filter);
    setActiveSearch({ filter: '', value: '' });
  };
  const handleAddTag = () => {
    setSearchTags((prevTags) => [...prevTags, activeSearch]);
    setActiveSearch({ filter: '', value: '' });
  };

  const handleRemoveTag = (index: number) => {
    setSearchTags((prevParams) =>
      prevParams.filter((_, idx) => idx !== index),
    );
  };

  return (
    <Layout
      pad
      as={Form}
      align="center"
      value={activeSearch}
      onChange={setActiveSearch}
      className="flex-col w-full sm:flex-row"
      variant="secondary"
    >
      <Form.FieldGroup
        as={DropdownList}
        name="filter"
        data={availableFilters}
        dataKey="field"
        textField={(filter: FilterField) => filter.title}
        mapFromValue={(filter: FilterField) => filter.field}
        placeholder={defineMessage({
          id: 'resourceGridFilter.select.placeholder',
          defaultMessage: 'Select a field',
        })}
        className="w-full p-2 sm:w-48 mb-0"
        data-cy="filterDropdownList"
        variant="secondary"
      />

      {filterBooleans[activeSearch.filter] ? (
        <Form.Field
          name="value"
          disabled={!activeSearch.filter}
          placeholder={defineMessage({
            id: 'resourceGridFilter.option.placeholder',
            defaultMessage: 'Enter query',
          })}
          className="w-full p-2 ml-0 sm:w-auto"
          data-cy="filterQueryField"
        >
          {(props) => (
            <FormCheckGroup
              {...props}
              type="radio"
              direction="row"
              pad={5}
              data={['true', 'false']}
              data-cy="radioButton"
              renderItem={(item) =>
                item === 'true' ? (
                  <FormattedMessage
                    id="template.yes"
                    defaultMessage="Yes"
                    values={{
                      b: (msg) => <Text variant="body-bold">{msg}</Text>,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="template.no"
                    defaultMessage="No"
                    values={{
                      b: (msg) => <Text variant="body-bold">{msg}</Text>,
                    }}
                  />
                )
              }
            />
          )}
        </Form.Field>
      ) : (
        <Form.Field
          name="value"
          disabled={!activeSearch.filter}
          placeholder={defineMessage({
            id: 'resourceGridFilter.option.placeholder',
            defaultMessage: 'Enter query',
          })}
          className="w-full p-2 ml-0 sm:w-auto"
          data-cy="filterQueryField"
        >
          {(props) => (
            <FormControlWithAddon
              {...props}
              css={css`
                // autocomplete styles fix
                & input:-webkit-autofill,
                & input:-webkit-autofill:hover,
                & input:-webkit-autofill:focus {
                  border: 0;
                  -webkit-text-fill-color: white;
                  -webkit-box-shadow: 0 0 0px 1000px
                    var(--form-control-bg-color) inset;
                  transition: background-color 5000s ease-in-out 0s;
                }
              `}
              onChange={(e) => props.onChange(e.target.value)}
              addon={
                <Button
                  type="reset"
                  variant="text-secondary"
                  data-cy="clearFilterFieldsButton"
                  disabled={!activeSearch.filter && !activeSearch.value}
                  onClick={handleClear}
                >
                  <FormattedMessage
                    id="resourceGridFilter.clear"
                    defaultMessage="Clear"
                  />
                </Button>
              }
            />
          )}
        </Form.Field>
      )}
      {availableFilters.length > 1 && searchTags.length < 3 && (
        <Button
          size="lg"
          variant="text-secondary"
          data-cy="submitFilterButton"
          disabled={!activeSearch.filter || !activeSearch.value}
          onClick={handleAddTag}
        >
          <PlusIcon width={20} height={20} />
          <SrOnly>
            <FormattedMessage
              id="resourceGridFilter.filtersBtn"
              defaultMessage="All filters"
            />
          </SrOnly>
        </Button>
      )}
      {searchTags.map(({ filter, value }, index) => (
        <SearchTag
          key={`${filter}-${value}`}
          dataItem={index}
          onRemove={handleRemoveTag}
        >
          {filterLabels[filter]}: {convertString(value)}
        </SearchTag>
      ))}
    </Layout>
  );
}
