/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SetCountryAction_organization = {
    readonly organizationId: string | null;
    readonly country: string | null;
    readonly " $refType": "SetCountryAction_organization";
};
export type SetCountryAction_organization$data = SetCountryAction_organization;
export type SetCountryAction_organization$key = {
    readonly " $data"?: SetCountryAction_organization$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SetCountryAction_organization">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetCountryAction_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
(node as any).hash = 'f41b3a7d784d70aa8008d7e1bb1da6bd';
export default node;
