/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry = {
    readonly androidVersionBlacklistEntryId: string | null;
    readonly " $refType": "DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry";
};
export type DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry$data = DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry;
export type DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry$key = {
    readonly " $data"?: DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "androidVersionBlacklistEntryId",
      "storageKey": null
    }
  ],
  "type": "AndroidVersionBlacklistEntry",
  "abstractKey": null
};
(node as any).hash = '3407eaccf3a439b799507492bea7217c';
export default node;
