/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type invites_InvitesGrid_QueryVariables = {
    organizationId?: Array<string> | null | undefined;
    email?: Array<string> | null | undefined;
    isDeleted?: Array<string> | null | undefined;
};
export type invites_InvitesGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"InvitesGrid_viewer">;
    } | null;
};
export type invites_InvitesGrid_Query = {
    readonly response: invites_InvitesGrid_QueryResponse;
    readonly variables: invites_InvitesGrid_QueryVariables;
};



/*
query invites_InvitesGrid_Query(
  $organizationId: [String!]
  $email: [String!]
  $isDeleted: [String!]
) {
  viewer {
    ...InvitesGrid_viewer_1acZUX
    id
  }
}

fragment InvitesGrid_viewer_1acZUX on Viewer {
  organizationInvites(first: 20, organizationId: $organizationId, email: $email, isDeleted: $isDeleted) {
    edges {
      node {
        id
        organizationInviteId
        email
        roleId
        makeOrganizationAdmin
        createdAt
        deletedAt
        organization {
          organizationId
          name
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDeleted"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = {
  "kind": "Variable",
  "name": "email",
  "variableName": "email"
},
v4 = {
  "kind": "Variable",
  "name": "isDeleted",
  "variableName": "isDeleted"
},
v5 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v6 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "invites_InvitesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "InvitesGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "invites_InvitesGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "OrganizationInviteConnection",
            "kind": "LinkedField",
            "name": "organizationInvites",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationInviteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationInvite",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "organizationInviteId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "roleId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "makeOrganizationAdmin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "organizationId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "InvitesGrid_organizationInvites",
            "kind": "LinkedHandle",
            "name": "organizationInvites"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b2217b33a17741cac06d51b18c356c9",
    "id": null,
    "metadata": {},
    "name": "invites_InvitesGrid_Query",
    "operationKind": "query",
    "text": "query invites_InvitesGrid_Query(\n  $organizationId: [String!]\n  $email: [String!]\n  $isDeleted: [String!]\n) {\n  viewer {\n    ...InvitesGrid_viewer_1acZUX\n    id\n  }\n}\n\nfragment InvitesGrid_viewer_1acZUX on Viewer {\n  organizationInvites(first: 20, organizationId: $organizationId, email: $email, isDeleted: $isDeleted) {\n    edges {\n      node {\n        id\n        organizationInviteId\n        email\n        roleId\n        makeOrganizationAdmin\n        createdAt\n        deletedAt\n        organization {\n          organizationId\n          name\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0a9a53cafa4625f2de6492bd86054e3c';
export default node;
