import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry$key } from './__generated__/DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry.graphql';

const fragment = graphql`
  fragment DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry on IosVersionBlacklistEntry {
    iosVersionBlacklistEntryId
  }
`;

const mutation = graphql`
  mutation DeleteIosVersionBlacklistEntryActionMutation(
    $input: DeleteIosVersionBlacklistEntryInput!
  ) {
    deleteIosVersionBlacklistEntry(input: $input) {
      deletedId
    }
  }
`;

interface DeleteIosVersionBlacklistEntryActionProps {
  iosVersionBlacklistEntryRef: DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry$key;
}

export default function DeleteIosVersionBlacklistEntryAction({
  iosVersionBlacklistEntryRef,
}: DeleteIosVersionBlacklistEntryActionProps) {
  const { iosVersionBlacklistEntryId } = useFragment(
    fragment,
    iosVersionBlacklistEntryRef,
  );
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      mutation={mutation}
      itemName={iosVersionBlacklistEntryId}
      input={{ iosVersionBlacklistEntryId }}
      redirectTo="/ios-version-blacklist-entries"
      onDelete={() =>
        deleteBreadcrumb(
          `/ios-version-blacklist-entries/${iosVersionBlacklistEntryId}`,
        )
      }
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
