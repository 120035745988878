import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { boolean, object, string } from 'yup';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import domainActionMessages from '../messages/domainActionMessages';
import { MdmSettingsModal } from './MdmSettingsModal';
import { UpdateMdmSettingsModalMutation } from './__generated__/UpdateMdmSettingsModalMutation.graphql';
import { UpdateMdmSettingsModal_domain$key } from './__generated__/UpdateMdmSettingsModal_domain.graphql';

const fragment = graphql`
  fragment UpdateMdmSettingsModal_domain on Domain {
    id
    domainId
    name
    shouldAuthorizeHostDevices
    hostDeviceSecret
  }
`;

const updateMutation = graphql`
  mutation UpdateMdmSettingsModalMutation($input: UpdateMdmSettingsInput!) {
    updateMdmSettings(input: $input) {
      domain {
        id
        ...MdmSettingsPage_domain
      }
    }
  }
`;

const updateMdmSettingsSchema = object({
  domainId: string(),
  shouldAuthorizeHostDevices: boolean(),
});

interface UpdateMdmSettingsModalProps extends ActionModalProps {
  domainRef: UpdateMdmSettingsModal_domain$key;
}

export function UpdateMdmSettingsModal({
  show,
  onClose,
  domainRef,
}: UpdateMdmSettingsModalProps) {
  const domain = useFragment(fragment, domainRef);

  return (
    <MdmSettingsModal<UpdateMdmSettingsModalMutation>
      title={<FormattedMessage {...domainActionMessages.updateMdmSettings} />}
      name={domain.name!}
      submitText={<FormattedMessage {...actionMessages.update} />}
      show={show}
      onClose={onClose}
      schema={updateMdmSettingsSchema}
      mutation={updateMutation}
      defaultValue={domain}
    />
  );
}
