import { defineMessages } from 'react-intl';

export const membershipIdMessages = defineMessages({
  label: {
    id: 'membershipModal.membershipIdMessages.label',
    defaultMessage: 'Organization Membership ID*',
  },
  placeholder: {
    id: 'membershipModal.membershipIdMessages.placeholder',
    defaultMessage: 'Organization Membership ID',
  },
});

export const membershipOrganizationIdMessages = defineMessages({
  label: {
    id: 'membershipModal.organizationId.label',
    defaultMessage: 'Organization ID*',
  },
  placeholder: {
    id: 'membershipModal.organizationId.placeholder',
    defaultMessage: 'Organization ID',
  },
  required: {
    id: 'membershipModal.organizationId.required',
    defaultMessage: 'Please enter a valid organization ID',
  },
});

export const membershipUserIdMessages = defineMessages({
  label: {
    id: 'membershipModal.userId.label',
    defaultMessage: 'User ID*',
  },
  placeholder: {
    id: 'membershipModal.userId.placeholder',
    defaultMessage: 'User ID',
  },
  required: {
    id: 'membershipModal.userId.required',
    defaultMessage: 'Please enter a valid user ID',
  },
});

export const membershipTypeMessages = defineMessages({
  label: {
    id: 'membershipModal.type.label',
    defaultMessage: 'Type*',
  },
  placeholder: {
    id: 'membershipModal.type.placeholder',
    defaultMessage: 'Type',
  },
  description: {
    id: 'membershipModal.type.description',
    defaultMessage: 'Membership type',
  },
  required: {
    id: 'membershipModal.type.required',
    defaultMessage: 'Please enter a valid membership type',
  },
});

export const isNurseMessages = defineMessages({
  label: {
    id: 'membershipModal.isNurse.label',
    defaultMessage: 'Is Nurse',
  },
  placeholder: {
    id: 'membershipModal.isNurse.placeholder',
    defaultMessage: 'Is Nurse',
  },
});

export const canFinalizeMessages = defineMessages({
  label: {
    id: 'membershipModal.canFinalize.label',
    defaultMessage: 'Can Finalize',
  },
  placeholder: {
    id: 'membershipModal.canFinalize.placeholder',
    defaultMessage: 'Can Finalize',
  },
});

export const canQAMessages = defineMessages({
  label: {
    id: 'membershipModal.canQA.label',
    defaultMessage: 'Can QA',
  },
  placeholder: {
    id: 'membershipModal.canQA.placeholder',
    defaultMessage: 'Can QA',
  },
});
