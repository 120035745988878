import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceGridAction from 'components/ResourceGridAction';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import { CreateMembershipModal } from './CreateMembershipModal';
import { MembershipsPage_viewer$key } from './__generated__/MembershipsPage_viewer.graphql';

const resource = Resource.MEMBERSHIPS;

const gridFilterFields: FilterField[] = [
  {
    title: tableHeadings.organizationId.defaultMessage,
    field: 'organizationId',
  },
  { title: tableHeadings.userId.defaultMessage, field: 'userId' },
  {
    title: tableHeadings.membershipActive.defaultMessage,
    field: 'isDeleted',
    isBoolean: true,
  },
];

const fragment = graphql`
  fragment MembershipsPage_viewer on Viewer {
    ...CreateMembershipModal_viewer
  }
`;

interface MembershipsPageProps {
  viewer: MembershipsPage_viewer$key;
}

export default function MembershipsPage({
  viewer,
  children,
}: PropsWithChildren<MembershipsPageProps>) {
  const viewerRef = useFragment(fragment, viewer);
  useBreadcrumbDetector(pageTitles.memberships);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader>
        <Header.Title>
          <FormattedMessage {...pageTitles.memberships} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateMembershipModal {...props} viewerRef={viewerRef} />
          )}
        >
          <FormattedMessage
            id="membershipsPage.actonTitle"
            defaultMessage="Create Membership"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        {children}
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
