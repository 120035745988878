import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import MembershipDetailPage from './components/MembershipDetailPage';
import MembershipsGrid from './components/MembershipsGrid';
import MembershipsPage from './components/MembershipsPage';

export default (
  <Route path="memberships">
    <Route
      Component={MembershipsPage}
      query={graphql`
        query memberships_MembershipsPage_Query {
          viewer {
            ...MembershipsPage_viewer
          }
        }
      `}
    >
      <Route
        Component={MembershipsGrid}
        query={graphql`
          query memberships_MembershipsGrid_Query(
            $organizationId: [String!]
            $userId: [String!]
            $isDeleted: [String!]
          ) {
            viewer {
              ...MembershipsGrid_viewer
                @arguments(
                  organizationId: $organizationId
                  userId: $userId
                  isDeleted: $isDeleted
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          organizationId: location.query.organizationId,
          userId: location.query.userId,
          isDeleted:
            location.query.isDeleted === 'true' ||
            location.query.isDeleted === 'false'
              ? location.query.isDeleted
              : undefined,
        })}
      />
    </Route>
    <Route
      path=":organizationId/:userId"
      Component={MembershipDetailPage}
      query={graphql`
        query memberships_MembershipDetailPage_Query(
          $organizationMembershipId: String!
        ) {
          membership: organizationMembership(
            organizationMembershipId: $organizationMembershipId
          ) {
            ...MembershipDetailPage_membership
          }
        }
      `}
      prepareVariables={({ organizationId, userId }) => ({
        organizationMembershipId: `${organizationId}/${userId}`,
      })}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.membership) throw new HttpError(404);
      }}
    />
  </Route>
);
