/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type subscriptions_SubscriptionDetailPage_QueryVariables = {
    subscriptionId: string;
};
export type subscriptions_SubscriptionDetailPage_QueryResponse = {
    readonly subscription: {
        readonly " $fragmentRefs": FragmentRefs<"SubscriptionDetailPage_subscription">;
    } | null;
};
export type subscriptions_SubscriptionDetailPage_Query = {
    readonly response: subscriptions_SubscriptionDetailPage_QueryResponse;
    readonly variables: subscriptions_SubscriptionDetailPage_QueryVariables;
};



/*
query subscriptions_SubscriptionDetailPage_Query(
  $subscriptionId: String!
) {
  subscription(subscriptionId: $subscriptionId) {
    ...SubscriptionDetailPage_subscription
    id
  }
}

fragment DisableCaptionHealthAction_subscription on Subscription {
  id
  subscriptionId
}

fragment EnableCaptionHealthAction_subscription on Subscription {
  id
  subscriptionId
}

fragment ProvisionOrganizationForSubscriptionAction_subscription on Subscription {
  id
  subscriptionId
}

fragment SubscriptionDetailPage_subscription on Subscription {
  ...UpdateSubscriptionModal_subscription
  ...ProvisionOrganizationForSubscriptionAction_subscription
  ...EnableCaptionHealthAction_subscription
  ...DisableCaptionHealthAction_subscription
  id
  subscriptionId
  subscriptionEndsAt
  accessEndsAt
  hasStartedActiveSubscription
  hasCompletedTrial
  canAccessProFeatures
  isInGracePeriod
  isTeam
  billingStatus
  billingProvider
  stripeSubscriptionId
  sfSubscriptionId
  featureModuleIds
  planType
  maxNumSeats
  practiceType
  domain {
    domainId
    id
  }
  customer {
    customerId
    id
  }
}

fragment UpdateSubscriptionModal_subscription on Subscription {
  id
  subscriptionId
  subscriptionEndsAt
  accessEndsAt
  hasStartedActiveSubscription
  hasCompletedTrial
  canAccessProFeatures
  isInGracePeriod
  isTeam
  billingStatus
  billingProvider
  stripeSubscriptionId
  sfSubscriptionId
  featureModuleIds
  planType
  maxNumSeats
  practiceType
  domain {
    domainId
    id
  }
  customer {
    customerId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriptionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "subscriptionId",
    "variableName": "subscriptionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "subscriptions_SubscriptionDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubscriptionDetailPage_subscription"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "subscriptions_SubscriptionDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriptionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriptionEndsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessEndsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasStartedActiveSubscription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasCompletedTrial",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canAccessProFeatures",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInGracePeriod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTeam",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billingStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billingProvider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stripeSubscriptionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sfSubscriptionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "featureModuleIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxNumSeats",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "practiceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domainId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a3f15c23218895bbd032a69fa623fbc",
    "id": null,
    "metadata": {},
    "name": "subscriptions_SubscriptionDetailPage_Query",
    "operationKind": "query",
    "text": "query subscriptions_SubscriptionDetailPage_Query(\n  $subscriptionId: String!\n) {\n  subscription(subscriptionId: $subscriptionId) {\n    ...SubscriptionDetailPage_subscription\n    id\n  }\n}\n\nfragment DisableCaptionHealthAction_subscription on Subscription {\n  id\n  subscriptionId\n}\n\nfragment EnableCaptionHealthAction_subscription on Subscription {\n  id\n  subscriptionId\n}\n\nfragment ProvisionOrganizationForSubscriptionAction_subscription on Subscription {\n  id\n  subscriptionId\n}\n\nfragment SubscriptionDetailPage_subscription on Subscription {\n  ...UpdateSubscriptionModal_subscription\n  ...ProvisionOrganizationForSubscriptionAction_subscription\n  ...EnableCaptionHealthAction_subscription\n  ...DisableCaptionHealthAction_subscription\n  id\n  subscriptionId\n  subscriptionEndsAt\n  accessEndsAt\n  hasStartedActiveSubscription\n  hasCompletedTrial\n  canAccessProFeatures\n  isInGracePeriod\n  isTeam\n  billingStatus\n  billingProvider\n  stripeSubscriptionId\n  sfSubscriptionId\n  featureModuleIds\n  planType\n  maxNumSeats\n  practiceType\n  domain {\n    domainId\n    id\n  }\n  customer {\n    customerId\n    id\n  }\n}\n\nfragment UpdateSubscriptionModal_subscription on Subscription {\n  id\n  subscriptionId\n  subscriptionEndsAt\n  accessEndsAt\n  hasStartedActiveSubscription\n  hasCompletedTrial\n  canAccessProFeatures\n  isInGracePeriod\n  isTeam\n  billingStatus\n  billingProvider\n  stripeSubscriptionId\n  sfSubscriptionId\n  featureModuleIds\n  planType\n  maxNumSeats\n  practiceType\n  domain {\n    domainId\n    id\n  }\n  customer {\n    customerId\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cc43b8e8e1772a8188f26ee94c8adadd';
export default node;
