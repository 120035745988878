import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import CreateStudyExportAction, {
  CreateStudyExportResponse,
} from './CreateStudyExportAction';
import { DownloadAuditLogDataModal } from './DownloadAuditLogDataModal';
import { DownloadCreateStudyExportModal } from './DownloadCreateStudyExportModal';
import GenerateSharedDeviceKeyAction from './GenerateSharedDeviceKeyAction';
import MigrateEnterpriseConnectivityAction from './MigrateEnterpriseConnectivityAction';
import MigrateEnterpriseWorkflowAction from './MigrateEnterpriseWorkflowAction';
import ProvisionSubscriptionAction from './ProvisionSubscriptionAction';
import RequestAuditLogsAction, {
  AuditLogsResponse,
} from './RequestAuditLogsAction';
import { UpdateDomainModal } from './UpdateDomainModal';
import { DomainDetailPage_domain$key } from './__generated__/DomainDetailPage_domain.graphql';

const fragment = graphql`
  fragment DomainDetailPage_domain on Domain {
    ...UpdateDomainModal_domain
    ...RequestAuditLogsAction_domain
    ...CreateStudyExportAction_domain
    ...ProvisionSubscriptionAction_domain
    ...GenerateSharedDeviceKeyAction_domain
    ...MigrateEnterpriseWorkflowAction_domain
    ...MigrateEnterpriseConnectivityAction_domain
    id
    domainId
    createdAt
    deletedAt
    subdomainLabel
    name
    enableButterflyLogin
    enableSamlLogin
    enableSharedDeviceCodeLogin
    samlEmailAttributeName
    samlEmailAttributeNameRaw
    samlMetadata
    samlNicknameAttributeName
    samlNicknameAttributeNameRaw
    cognitoClientId
    inactivityTimeoutSeconds
    requiredAndroidCapabilities
    sharedDeviceKey
    numUsers
    maxNumSeats
    numAvailableSeats
    country
  }
`;

interface DomainDetailPageProps {
  domain: DomainDetailPage_domain$key;
}

export default function DomainDetailPage({
  domain: domainRef,
}: DomainDetailPageProps) {
  const domain = useFragment(fragment, domainRef);
  const { domainId, sharedDeviceKey, name } = domain;
  useBreadcrumbDetector(pageTitles.domain, name);
  const { canWrite, canWriteSome } = usePermissionsContext();

  const [showAuditLogModal, toggleAuditLogModal] = useState(false);
  const [createStudyExportModal, toggleCreateStudyExportModal] =
    useState(false);
  const [auditLogData, setAuditLogData] = useState<AuditLogsResponse>({
    downloadLink: '',
    password: '',
  });
  const [createStudyExportResponse, setCreateStudyExportResponse] =
    useState<CreateStudyExportResponse>({
      downloadLink: '',
      password: '',
    });

  const handleAuditLogsCompleted = (response: AuditLogsResponse) => {
    setAuditLogData(response);
    toggleAuditLogModal(true);
  };

  const handleCreateStudyExportCompleted = (response: AuditLogsResponse) => {
    setCreateStudyExportResponse(response);
    toggleCreateStudyExportModal(true);
  };

  const isResourceActionsEnabled = () => {
    const isActionsEnabled = canWriteSome(
      Resource.ENTERPRISE_UPGRADES,
      Resource.PROVISIONING,
      Resource.AUDIT_LOGS,
      Resource.DOMAINS,
    );
    return (
      (!isActionsEnabled &&
        canWrite(Resource.GENERATE_SHARED_DEVICE_KEY) &&
        !sharedDeviceKey) ||
      isActionsEnabled
    );
  };

  return (
    <PermissionsGuard resource={Resource.DOMAIN_USERS}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.domain} />}
        name={domain.name!}
        data={domain}
        quickCopyFields={[
          'name',
          'domainId',
          'samlEmailAttributeName',
          'samlEmailAttributeNameRaw',
          'samlNicknameAttributeName',
          'samlNicknameAttributeNameRaw',
          'cognitoClientId',
          'sharedDeviceKey',
        ]}
        resourceActions={
          isResourceActionsEnabled() && (
            <>
              <ProvisionSubscriptionAction domainRef={domain} />
              <MigrateEnterpriseConnectivityAction domainRef={domain} />
              <RequestAuditLogsAction
                domainRef={domain}
                onCompleted={handleAuditLogsCompleted}
              />
              <CreateStudyExportAction
                domainRef={domain}
                onCompleted={handleCreateStudyExportCompleted}
              />
              <MigrateEnterpriseWorkflowAction domainRef={domain} />
              {!sharedDeviceKey && (
                <GenerateSharedDeviceKeyAction domainRef={domain} />
              )}
            </>
          )
        }
        crudActions={
          canWrite(Resource.DOMAIN_USERS) && (
            <UpdateAction
              renderModal={(props) => (
                <UpdateDomainModal {...props} domainRef={domain} />
              )}
            />
          )
        }
        relatedItems={
          <>
            <RelatedItem
              name="Subscriptions"
              to={{
                pathname: '/subscriptions',
                query: { domainId },
              }}
            >
              <FormattedMessage
                id="domainDetail.relatedItem.subscriptions"
                defaultMessage="Subscriptions"
              />
            </RelatedItem>
            <RelatedItem
              name="Organizations"
              to={{
                pathname: '/organizations',
                query: { domainId },
              }}
            >
              <FormattedMessage
                id="domainDetail.relatedItem.organizations"
                defaultMessage="Organizations"
              />
            </RelatedItem>
            <RelatedItem
              name="Users"
              to={{
                pathname: '/users',
                query: { domainId },
              }}
            >
              <FormattedMessage
                id="domainDetail.relatedItem.users"
                defaultMessage="Users"
              />
            </RelatedItem>
            <RelatedItem
              name="EHRs"
              to={{
                pathname: '/ehrs',
                query: { domainId },
              }}
            >
              <FormattedMessage
                id="domainDetail.relatedItem.ehrs"
                defaultMessage="EHRs"
              />
            </RelatedItem>
            <RelatedItem
              name="Integration Configs"
              to={{
                pathname: '/domain-user-integrations-configs',
                query: { domainId },
              }}
            >
              <FormattedMessage
                id="domainDetail.relatedItem.domainUserIntegrationsConfigs"
                defaultMessage="Integration Configs"
              />
            </RelatedItem>
            <RelatedItem name="MDM" to={`/domains/${domainId}/mdm`}>
              <FormattedMessage
                id="domainDetail.relatedItem.mdm"
                defaultMessage="MDM"
              />
            </RelatedItem>
          </>
        }
      />
      <DownloadCreateStudyExportModal
        show={createStudyExportModal}
        value={createStudyExportResponse}
        onClose={() => toggleCreateStudyExportModal(false)}
      />
      <DownloadAuditLogDataModal
        show={showAuditLogModal}
        value={auditLogData}
        onClose={() => toggleAuditLogModal(false)}
      />
    </PermissionsGuard>
  );
}
