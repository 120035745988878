import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import EducationContentDetailPage from './components/EducationContentDetailPage';
import EducationContentGrid from './components/EducationContentGrid';
import EducationContentPage from './components/EducationContentPage';

export default (
  <Route path="education-content">
    <Route
      Component={EducationContentPage}
      query={graphql`
        query educationContent_EducationContentPage_Query {
          viewer {
            ...EducationContentPage_viewer
          }
        }
      `}
    >
      <Route
        Component={EducationContentGrid}
        query={graphql`
          query educationContent_EducationContentGrid_Query(
            $name: [String!]
            $absorbId: [String!]
            $contentType: [String!]
            $practiceType: [String!]
            $categoryId: [String!]
            $categoryName: [String!]
          ) {
            viewer {
              ...EducationContentGrid_viewer
                @arguments(
                  name: $name
                  absorbId: $absorbId
                  contentType: $contentType
                  practiceType: $practiceType
                  categoryId: $categoryId
                  categoryName: $categoryName
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          name: location.query.name,
          absorbId: location.query.absorbId,
          contentType: location.query.contentType?.toUpperCase(),
          practiceType: location.query.practiceType?.toUpperCase(),
          categoryId: location.query.categoryId,
          categoryName: location.query.categoryName,
        })}
        renderError={(error) => <div>{error.message}</div>}
      />
    </Route>
    <Route
      path=":educationContentId"
      Component={EducationContentDetailPage}
      query={graphql`
        query educationContent_EducationContentDetailPage_Query(
          $educationContentId: String!
        ) {
          educationContent(educationContentId: $educationContentId) {
            ...EducationContentDetailPage_educationContent
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.educationContent) throw new HttpError(404);
      }}
    />
  </Route>
);
