/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GenerateSharedDeviceKeyAction_domain = {
    readonly id: string;
    readonly domainId: string | null;
    readonly " $refType": "GenerateSharedDeviceKeyAction_domain";
};
export type GenerateSharedDeviceKeyAction_domain$data = GenerateSharedDeviceKeyAction_domain;
export type GenerateSharedDeviceKeyAction_domain$key = {
    readonly " $data"?: GenerateSharedDeviceKeyAction_domain$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"GenerateSharedDeviceKeyAction_domain">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GenerateSharedDeviceKeyAction_domain",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    }
  ],
  "type": "Domain",
  "abstractKey": null
};
(node as any).hash = '5a3577c544ca87b3030674f1ec1ce1a2';
export default node;
