/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteOrganizationMembershipInput = {
    organizationMembershipId: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteMembershipActionMutationVariables = {
    input: DeleteOrganizationMembershipInput;
};
export type DeleteMembershipActionMutationResponse = {
    readonly deleteOrganizationMembership: {
        readonly organizationMembership: {
            readonly deletedAt: string | null;
        } | null;
    } | null;
};
export type DeleteMembershipActionMutation = {
    readonly response: DeleteMembershipActionMutationResponse;
    readonly variables: DeleteMembershipActionMutationVariables;
};



/*
mutation DeleteMembershipActionMutation(
  $input: DeleteOrganizationMembershipInput!
) {
  deleteOrganizationMembership(input: $input) {
    organizationMembership {
      deletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteMembershipActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "deleteOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "organizationMembership",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteMembershipActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteOrganizationMembershipPayload",
        "kind": "LinkedField",
        "name": "deleteOrganizationMembership",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "organizationMembership",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d82135d272fb897a441ebcce1aa039cf",
    "id": null,
    "metadata": {},
    "name": "DeleteMembershipActionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteMembershipActionMutation(\n  $input: DeleteOrganizationMembershipInput!\n) {\n  deleteOrganizationMembership(input: $input) {\n    organizationMembership {\n      deletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fbab8fa2197f4f5255af17a14a8824e4';
export default node;
