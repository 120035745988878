import Button from '@bfly/ui2/Button';
import React, { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Resource } from 'components/ResourceDetail';
import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

interface UpdateActionProps<TData = Resource> {
  renderModal: (props: ActionModalProps<TData>) => ReactElement;
}

export function UpdateAction({ renderModal }: UpdateActionProps) {
  const [show, setShow] = useState(false);

  const onClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <>
      <Button variant="primary" data-cy="update" onClick={() => setShow(true)}>
        <FormattedMessage {...actionMessages.update} />
      </Button>
      {renderModal({ show, onClose })}
    </>
  );
}
