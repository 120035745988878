/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type placesOfWork_PlaceOfWorkDetailPage_QueryVariables = {
    placeOfWorkId: string;
};
export type placesOfWork_PlaceOfWorkDetailPage_QueryResponse = {
    readonly placeOfWork: {
        readonly " $fragmentRefs": FragmentRefs<"PlaceOfWorkDetailPage_placeOfWork">;
    } | null;
};
export type placesOfWork_PlaceOfWorkDetailPage_Query = {
    readonly response: placesOfWork_PlaceOfWorkDetailPage_QueryResponse;
    readonly variables: placesOfWork_PlaceOfWorkDetailPage_QueryVariables;
};



/*
query placesOfWork_PlaceOfWorkDetailPage_Query(
  $placeOfWorkId: String!
) {
  placeOfWork(placeOfWorkId: $placeOfWorkId) {
    ...PlaceOfWorkDetailPage_placeOfWork
    id
  }
}

fragment PlaceOfWorkDetailPage_placeOfWork on PlaceOfWork {
  ...UpdatePlaceOfWorkModal_placeOfWork
  id
  placeOfWorkId
  name
  countries
  status
  source
}

fragment UpdatePlaceOfWorkModal_placeOfWork on PlaceOfWork {
  id
  placeOfWorkId
  name
  countries
  status
  source
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "placeOfWorkId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "placeOfWorkId",
    "variableName": "placeOfWorkId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "placesOfWork_PlaceOfWorkDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PlaceOfWork",
        "kind": "LinkedField",
        "name": "placeOfWork",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlaceOfWorkDetailPage_placeOfWork"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "placesOfWork_PlaceOfWorkDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PlaceOfWork",
        "kind": "LinkedField",
        "name": "placeOfWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placeOfWorkId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countries",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "source",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3095372718bfb2f84e731808d1e2f575",
    "id": null,
    "metadata": {},
    "name": "placesOfWork_PlaceOfWorkDetailPage_Query",
    "operationKind": "query",
    "text": "query placesOfWork_PlaceOfWorkDetailPage_Query(\n  $placeOfWorkId: String!\n) {\n  placeOfWork(placeOfWorkId: $placeOfWorkId) {\n    ...PlaceOfWorkDetailPage_placeOfWork\n    id\n  }\n}\n\nfragment PlaceOfWorkDetailPage_placeOfWork on PlaceOfWork {\n  ...UpdatePlaceOfWorkModal_placeOfWork\n  id\n  placeOfWorkId\n  name\n  countries\n  status\n  source\n}\n\nfragment UpdatePlaceOfWorkModal_placeOfWork on PlaceOfWork {\n  id\n  placeOfWorkId\n  name\n  countries\n  status\n  source\n}\n"
  }
};
})();
(node as any).hash = '0fd94fe83d98b653e9dbdc4680a920c6';
export default node;
