import { defineMessages } from 'react-intl';

export const domainUserIntegrationConfigIdMessages = defineMessages({
  label: {
    id: 'newDomainUserIntegrationConfigs.domainUserId.label',
    defaultMessage: 'Domain User Integration Config ID',
  },
  placeholder: {
    id: 'newDomainUserIntegrationConfigs.domainUserId.placeholder',
    defaultMessage: 'Domain User Integration Config ID',
  },
});

export const domainIdMessages = defineMessages({
  label: {
    id: 'newDomainUserIntegrationConfigs.domainId.label',
    defaultMessage: 'Domain ID*',
  },
  placeholder: {
    id: 'newDomainUserIntegrationConfigs.domainId.placeholder',
    defaultMessage: 'Domain ID',
  },
  required: {
    id: 'newDomainUserIntegrationConfigs.domainId.required',
    defaultMessage: 'Please enter a Domain ID',
  },
});

export const userIdMessages = defineMessages({
  label: {
    id: 'newDomainUserIntegrationConfigs.userId.label',
    defaultMessage: 'User ID*',
  },
  placeholder: {
    id: 'newDomainUserIntegrationConfigs.userId.placeholder',
    defaultMessage: 'User ID',
  },
  required: {
    id: 'newDomainUserIntegrationConfigs.userId.required',
    defaultMessage: 'Please enter a User ID',
  },
});

export const integrationIdMessages = defineMessages({
  label: {
    id: 'newDomainUserIntegrationConfigs.integrationId.label',
    defaultMessage: 'Integration ID*',
  },
  placeholder: {
    id: 'newDomainUserIntegrationConfigs.integrationId.placeholder',
    defaultMessage: 'Integration ID',
  },
  required: {
    id: 'newDomainUserIntegrationConfigs.integrationId.required',
    defaultMessage: 'Please enter a Integration ID',
  },
});

export const interfaceCodeMessages = defineMessages({
  label: {
    id: 'newDomainUserIntegrationConfigs.interfaceCode.label',
    defaultMessage: 'Interface Code',
  },
  placeholder: {
    id: 'newDomainUserIntegrationConfigs.interfaceCode.placeholder',
    defaultMessage: 'Interface Code',
  },
  description: {
    id: 'newDomainUserIntegrationConfigs.interfaceCode.description',
    defaultMessage: 'Domain user interface code',
  },
});
