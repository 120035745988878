/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteFlatUserAction_user = {
    readonly name: string | null;
    readonly flatUserId: string | null;
    readonly userDeletedAt: string | null;
    readonly domainId: string | null;
    readonly domainUserDeletedAt: string | null;
    readonly " $refType": "DeleteFlatUserAction_user";
};
export type DeleteFlatUserAction_user$data = DeleteFlatUserAction_user;
export type DeleteFlatUserAction_user$key = {
    readonly " $data"?: DeleteFlatUserAction_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteFlatUserAction_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteFlatUserAction_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flatUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userDeletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainUserDeletedAt",
      "storageKey": null
    }
  ],
  "type": "FlatUser",
  "abstractKey": null
};
(node as any).hash = '677f79b5d7ecd01a37c3ace510384364';
export default node;
