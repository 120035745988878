import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';

import customerTableHeadings from '../messages/customerTableHeadings';
import { CustomersGrid_customer as Customer } from './__generated__/CustomersGrid_customer.graphql';
import { CustomersGrid_viewer$key } from './__generated__/CustomersGrid_viewer.graphql';

const _ = graphql`
  fragment CustomersGrid_customer on Customer {
    id
    name
    customerId
    zendeskId
    netsuiteId
    stripeId
    sfAccountId
  }
`;

const fragment = graphql`
  fragment CustomersGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    zendeskId: { type: "[String!]" }
    netsuiteId: { type: "[String!]" }
    stripeId: { type: "[String!]" }
    sfAccountId: { type: "[String!]" }
    name: { type: "[String!]" }
    id: { type: "[String!]" }
  )
  @refetchable(queryName: "CustomersGridRefetchQuery") {
    customers(
      first: $count
      after: $cursor
      zendeskId: $zendeskId
      stripeId: $stripeId
      sfAccountId: $sfAccountId
      netsuiteId: $netsuiteId
      name: $name
      id: $id
    ) @connection(key: "CustomersGrid_customers", filters: []) {
      edges {
        node {
          ...CustomersGrid_customer @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<Customer>[] = [
  {
    key: 'name',
    frozen: true,
    label: <FormattedMessage {...tableHeadings.name} />,
    render: ({ item }) => (
      <QuickCopyText text={item.name}>
        <DataGridLink
          path="/customers"
          id={item.customerId}
          title={item.name!}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'customerId',
    label: <FormattedMessage {...tableHeadings.customerId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.customerId}>
        <DataGridLink path="/customers" id={item.customerId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'zendeskId',
    label: <FormattedMessage {...customerTableHeadings.zendeskId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.zendeskId}>
        <DataGridLink
          path="https://bfly.zendesk.com/agent/organizations"
          id={`${item.zendeskId}/tickets`}
          title={item.zendeskId!}
          target="_blank"
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'netsuiteId',
    label: <FormattedMessage {...customerTableHeadings.netsuiteId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.netsuiteId}>
        <Link
          as={Anchor}
          to={`https://4098050.app.netsuite.com/app/common/entity/custjob.nl?id=${item.netsuiteId}`}
          target="_blank"
        >
          {item.netsuiteId}
        </Link>
      </QuickCopyText>
    ),
  },
  {
    key: 'stripeId',
    label: <FormattedMessage {...customerTableHeadings.stripeId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.stripeId}>
        <DataGridLink
          path="https://dashboard.stripe.com/customers"
          id={item.stripeId}
          target="_blank"
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'sfAccountId',
    label: <FormattedMessage {...customerTableHeadings.sfAccountId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.sfAccountId}>
        <DataGridLink
          path="https://butterflynetwork.lightning.force.com/lightning/r/Opportunity"
          id={`${item.sfAccountId}/view`}
          title={item.sfAccountId!}
          target="_blank"
        />
      </QuickCopyText>
    ),
  },
];

interface CustomersGridProps {
  viewer: CustomersGrid_viewer$key;
}

export default function CustomersGrid({
  viewer: viewerRef,
}: CustomersGridProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(
    fragment,
    viewerRef,
  );
  const nodes = data ? getNodes(data.customers) : [];

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
    />
  );
}
