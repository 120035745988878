import { defineMessages } from 'react-intl';

export default defineMessages({
  androidVersionBlacklistEntry: {
    id: 'pageTitles.androidVersionBlacklistEntry',
    defaultMessage: 'Android Version Recall Entry',
  },
  androidVersionBlacklistEntries: {
    id: 'pageTitles.androidVersionBlacklistEntries',
    defaultMessage: 'Android Version Recall Entries',
  },
  applicationVersionRequirement: {
    id: 'pageTitles.applicationVersionRequirement',
    defaultMessage: 'Application Version Requirement',
  },
  applicationVersionRequirements: {
    id: 'pageTitles.applicationVersionRequirements',
    defaultMessage: 'Application Version Requirements',
  },
  butterflyDevice: {
    id: 'pageTitles.butterflyDevice',
    defaultMessage: 'Butterfly Device',
  },
  butterflyDevices: {
    id: 'pageTitles.butterflyDevices',
    defaultMessage: 'Butterfly Devices',
  },
  butterflyDeviceRecall: {
    id: 'pageTitles.butterflyDeviceRecall',
    defaultMessage: 'Butterfly Device Recall',
  },
  butterflyDeviceRecalls: {
    id: 'pageTitles.butterflyDeviceRecalls',
    defaultMessage: 'Butterfly Device Recalls',
  },
  countries: {
    id: 'pageTitles.countries',
    defaultMessage: 'Countries',
  },
  country: {
    id: 'pageTitles.country',
    defaultMessage: 'Country',
  },
  customer: {
    id: 'pageTitles.customer',
    defaultMessage: 'Customer',
  },
  customers: {
    id: 'pageTitles.customers',
    defaultMessage: 'Customers',
  },
  diagnosticTestResult: {
    id: 'pageTitles.diagnosticTestResult',
    defaultMessage: 'Diagnostic Test Result',
  },
  diagnosticTestResults: {
    id: 'pageTitles.diagnosticTestResults',
    defaultMessage: 'Diagnostic Test Results',
  },
  domain: {
    id: 'pageTitles.domain',
    defaultMessage: 'Domain',
  },
  domainInvite: {
    id: 'pageTitles.domainInvite',
    defaultMessage: 'Domain Invite',
  },
  domainInvites: {
    id: 'pageTitles.domainInvites',
    defaultMessage: 'Domain Invites',
  },
  domains: {
    id: 'pageTitles.domains',
    defaultMessage: 'Domains',
  },
  domainUser: {
    id: 'pageTitles.domainUser',
    defaultMessage: 'Domain User',
  },
  domainUserIntegrationConfig: {
    id: 'pageTitles.domainUserIntegrationConfig',
    defaultMessage: 'Domain User Integration Config',
  },
  domainUserIntegrationConfigs: {
    id: 'pageTitles.domainUserIntegrationConfigs',
    defaultMessage: 'Domain User Integration Configs',
  },
  educationCategory: {
    id: 'pageTitles.educationCategory',
    defaultMessage: 'Education Category',
  },
  educationCategories: {
    id: 'pageTitles.educationCategories',
    defaultMessage: 'Education Categories',
  },
  educationContent: {
    id: 'pageTitles.educationContent',
    defaultMessage: 'Education Content',
  },
  ehr: {
    id: 'pageTitles.ehr',
    defaultMessage: 'Electronic Health Record',
  },
  ehrs: {
    id: 'pageTitles.ehrs',
    defaultMessage: 'Electronic Health Records',
  },
  emailChangeRequests: {
    id: 'pageTitles.emailChangeRequests',
    defaultMessage: 'Email Change Requests',
  },
  invite: {
    id: 'pageTitles.invite',
    defaultMessage: 'Invite',
  },
  invites: {
    id: 'pageTitles.invites',
    defaultMessage: 'Invites',
  },
  iosVersionBlacklistEntry: {
    id: 'pageTitles.iosVersionBlacklistEntry',
    defaultMessage: 'iOS Version Recall Entry',
  },
  iosVersionBlacklistEntries: {
    id: 'pageTitles.iosVersionBlacklistEntries',
    defaultMessage: 'iOS Version Recall Entries',
  },
  membership: {
    id: 'pageTitles.membership',
    defaultMessage: 'Membership',
  },
  memberships: {
    id: 'pageTitles.memberships',
    defaultMessage: 'Membership',
  },
  organization: {
    id: 'pageTitles.organization',
    defaultMessage: 'Organization',
  },
  organizations: {
    id: 'pageTitles.organizations',
    defaultMessage: 'Organizations',
  },
  placesOfWork: {
    id: 'pageTitles.placesOfWork',
    defaultMessage: 'Place Of Work',
  },
  subscription: {
    id: 'pageTitles.subscription',
    defaultMessage: 'Subscription',
  },
  subscriptions: {
    id: 'pageTitles.subscriptions',
    defaultMessage: 'Subscriptions',
  },
  user: {
    id: 'pageTitles.user',
    defaultMessage: 'User',
  },
  users: {
    id: 'pageTitles.users',
    defaultMessage: 'Users',
  },
  systemDefinedRoles: {
    id: 'pageTitles.systemDefinedRoles',
    defaultMessage: 'Butterfly Access Roles - System Defined',
  },
  userDefinedRoles: {
    id: 'pageTitles.userDefinedRoles',
    defaultMessage: 'Butterfly Access Roles - User Defined',
  },
  systemDefinedRole: {
    id: 'pageTitles.systemDefinedRole',
    defaultMessage: 'System Defined Role',
  },
  userDefinedRole: {
    id: 'pageTitles.userDefinedRole',
    defaultMessage: 'User Defined Role',
  },
  mdm: {
    id: 'pageTitles.mdm',
    defaultMessage: 'MDM',
  },
  domainMdmSettings: {
    id: 'pageTitles.domainMdmSettings',
    defaultMessage: 'Domain MDM Settings',
  },
});
