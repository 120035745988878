/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SetFlatUserRoleAction_roles = {
    readonly systemDefinedRoles?: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly name: string | null;
                readonly description: string | null;
                readonly systemDefinedRoleId: string | null;
            } | null;
        } | null> | null;
    } | null | undefined;
    readonly userDefinedRoles?: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly domainId: string | null;
                readonly name: string | null;
                readonly description: string | null;
                readonly userDefinedRoleId: string | null;
            } | null;
        } | null> | null;
    } | null | undefined;
    readonly " $refType": "SetFlatUserRoleAction_roles";
};
export type SetFlatUserRoleAction_roles$data = SetFlatUserRoleAction_roles;
export type SetFlatUserRoleAction_roles$key = {
    readonly " $data"?: SetFlatUserRoleAction_roles$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SetFlatUserRoleAction_roles">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "domainId"
    },
    {
      "kind": "RootArgument",
      "name": "skipRolesQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetFlatUserRoleAction_roles",
  "selections": [
    {
      "condition": "skipRolesQuery",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "roleType",
              "value": [
                "SYSTEM_DEFINED"
              ]
            }
          ],
          "concreteType": "SystemDefinedRoleConnection",
          "kind": "LinkedField",
          "name": "systemDefinedRoles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SystemDefinedRoleEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SystemDefinedRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "systemDefinedRoleId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "systemDefinedRoles(roleType:[\"SYSTEM_DEFINED\"])"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "domainId",
              "variableName": "domainId"
            }
          ],
          "concreteType": "UserDefinedRoleConnection",
          "kind": "LinkedField",
          "name": "userDefinedRoles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserDefinedRoleEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserDefinedRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "domainId",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userDefinedRoleId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = '122e498481f4bb66c7193bebe1a87d97';
export default node;
