import { defineMessages } from 'react-intl';

export default defineMessages({
  createCountry: {
    id: 'country.modal.createCountry',
    defaultMessage: 'Create Country',
  },
  updateCountry: {
    id: 'country.modal.updateCountry',
    defaultMessage: 'Update Country',
  },
});
