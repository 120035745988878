/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type domains_MdmSettingsPage_QueryVariables = {
    domainId: string;
};
export type domains_MdmSettingsPage_QueryResponse = {
    readonly domain: {
        readonly " $fragmentRefs": FragmentRefs<"MdmSettingsPage_domain">;
    } | null;
};
export type domains_MdmSettingsPage_Query = {
    readonly response: domains_MdmSettingsPage_QueryResponse;
    readonly variables: domains_MdmSettingsPage_QueryVariables;
};



/*
query domains_MdmSettingsPage_Query(
  $domainId: String!
) {
  domain(domainId: $domainId) {
    ...MdmSettingsPage_domain
    id
  }
}

fragment MdmSettingsPage_domain on Domain {
  ...UpdateMdmSettingsModal_domain
  ...ResetHostDeviceSecretAction_domain
  id
  domainId
  name
  shouldAuthorizeHostDevices
  hostDeviceSecret
}

fragment ResetHostDeviceSecretAction_domain on Domain {
  id
  domainId
  name
  shouldAuthorizeHostDevices
  hostDeviceSecret
}

fragment UpdateMdmSettingsModal_domain on Domain {
  id
  domainId
  name
  shouldAuthorizeHostDevices
  hostDeviceSecret
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "domainId",
    "variableName": "domainId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domains_MdmSettingsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Domain",
        "kind": "LinkedField",
        "name": "domain",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MdmSettingsPage_domain"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domains_MdmSettingsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Domain",
        "kind": "LinkedField",
        "name": "domain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domainId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shouldAuthorizeHostDevices",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hostDeviceSecret",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a11f33869aa5d2fdb51aa0e21e7a462e",
    "id": null,
    "metadata": {},
    "name": "domains_MdmSettingsPage_Query",
    "operationKind": "query",
    "text": "query domains_MdmSettingsPage_Query(\n  $domainId: String!\n) {\n  domain(domainId: $domainId) {\n    ...MdmSettingsPage_domain\n    id\n  }\n}\n\nfragment MdmSettingsPage_domain on Domain {\n  ...UpdateMdmSettingsModal_domain\n  ...ResetHostDeviceSecretAction_domain\n  id\n  domainId\n  name\n  shouldAuthorizeHostDevices\n  hostDeviceSecret\n}\n\nfragment ResetHostDeviceSecretAction_domain on Domain {\n  id\n  domainId\n  name\n  shouldAuthorizeHostDevices\n  hostDeviceSecret\n}\n\nfragment UpdateMdmSettingsModal_domain on Domain {\n  id\n  domainId\n  name\n  shouldAuthorizeHostDevices\n  hostDeviceSecret\n}\n"
  }
};
})();
(node as any).hash = '31f3b5b8ba4cf7c3a868580dbc0a951a';
export default node;
