/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ehrs_EhrsGrid_QueryVariables = {
    organizationId?: Array<string> | null | undefined;
    domainId?: Array<string> | null | undefined;
};
export type ehrs_EhrsGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"EhrsGrid_viewer">;
    } | null;
};
export type ehrs_EhrsGrid_Query = {
    readonly response: ehrs_EhrsGrid_QueryResponse;
    readonly variables: ehrs_EhrsGrid_QueryVariables;
};



/*
query ehrs_EhrsGrid_Query(
  $organizationId: [String!]
  $domainId: [String!]
) {
  viewer {
    ...EhrsGrid_viewer_2sfYNA
    id
  }
}

fragment EhrsGrid_viewer_2sfYNA on Viewer {
  ehrs(first: 20, organizationId: $organizationId, domainId: $domainId) {
    edges {
      node {
        id
        ehrId
        name
        connectionId
        createdAt
        updatedAt
        deletedAt
        hospitalHost
        hospitalPort
        timeoutAck
        timeoutConnection
        timezone
        processingId
        receivingApplication
        receivingFacility
        sendingFacility
        characterEncoding
        studyDescriptionMapping
        studyUrlDisplay
        organization {
          organizationId
          id
        }
        domain {
          domainId
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domainId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "kind": "Variable",
  "name": "domainId",
  "variableName": "domainId"
},
v3 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ehrs_EhrsGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "EhrsGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ehrs_EhrsGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "EhrConnection",
            "kind": "LinkedField",
            "name": "ehrs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EhrEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Ehr",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ehrId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "connectionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hospitalHost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hospitalPort",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeoutAck",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeoutConnection",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timezone",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "processingId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "receivingApplication",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "receivingFacility",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sendingFacility",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "characterEncoding",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "studyDescriptionMapping",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "studyUrlDisplay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "organizationId",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "domain",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domainId",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "organizationId",
              "domainId"
            ],
            "handle": "connection",
            "key": "EhrsGrid_ehrs",
            "kind": "LinkedHandle",
            "name": "ehrs"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07dda3a99984e0dd6c6d120f967205c9",
    "id": null,
    "metadata": {},
    "name": "ehrs_EhrsGrid_Query",
    "operationKind": "query",
    "text": "query ehrs_EhrsGrid_Query(\n  $organizationId: [String!]\n  $domainId: [String!]\n) {\n  viewer {\n    ...EhrsGrid_viewer_2sfYNA\n    id\n  }\n}\n\nfragment EhrsGrid_viewer_2sfYNA on Viewer {\n  ehrs(first: 20, organizationId: $organizationId, domainId: $domainId) {\n    edges {\n      node {\n        id\n        ehrId\n        name\n        connectionId\n        createdAt\n        updatedAt\n        deletedAt\n        hospitalHost\n        hospitalPort\n        timeoutAck\n        timeoutConnection\n        timezone\n        processingId\n        receivingApplication\n        receivingFacility\n        sendingFacility\n        characterEncoding\n        studyDescriptionMapping\n        studyUrlDisplay\n        organization {\n          organizationId\n          id\n        }\n        domain {\n          domainId\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bcc34d9ffd2e2facf27deb2463d714ca';
export default node;
