import Header from '@bfly/ui2/Header';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import { ResourceGridDataLayoutWithFilters } from 'components/ResourceGridData';
import { FilterField } from 'components/ResourceGridFilter';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import tableHeadings from 'messages/tableHeadings';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import { SystemDefinedRolesPage_systemDefinedRole as SystemDefinedRole } from './__generated__/SystemDefinedRolesPage_systemDefinedRole.graphql';
import { SystemDefinedRolesPage_viewer$key } from './__generated__/SystemDefinedRolesPage_viewer.graphql';

const resource = Resource.SYSTEM_DEFINED_ROLES;

const gridFilterFields: FilterField[] = [
  {
    title: 'Role Name',
    field: 'name',
  },
];

const _ = graphql`
  fragment SystemDefinedRolesPage_systemDefinedRole on SystemDefinedRole {
    id
    name
    systemDefinedRoleId
  }
`;

const fragment = graphql`
  fragment SystemDefinedRolesPage_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    name: { type: "[String!]" }
  )
  @refetchable(queryName: "SystemDefinedRolesPageRefetchQuery") {
    systemDefinedRoles(first: $count, after: $cursor, name: $name)
      @connection(
        key: "SystemDefinedRolesPage_systemDefinedRoles"
        filters: []
      ) {
      edges {
        node {
          ...SystemDefinedRolesPage_systemDefinedRole @relay(mask: false)
        }
      }
    }
  }
`;

export const tableSpec: ColumnSpec<SystemDefinedRole>[] = [
  {
    key: 'systemDefinedRoleName',
    label: <FormattedMessage {...tableHeadings.accessRoleName} />,
    render: ({ item }) => (
      <DataGridLink
        path="/access-roles/system-defined"
        id={item.systemDefinedRoleId}
        title={item.name!}
      />
    ),
  },
  {
    key: 'systemDefinedRoleId',
    label: <FormattedMessage {...tableHeadings.accessRoleId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.systemDefinedRoleId}>
        <DataGridText value={item.systemDefinedRoleId} />
      </QuickCopyText>
    ),
  },
];

interface SystemDefinedRolesPage {
  viewer: SystemDefinedRolesPage_viewer$key;
}

export default function SystemDefinedRolesPage({
  viewer,
}: SystemDefinedRolesPage) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.systemDefinedRoles);
  useBreadcrumbDetector(pageTitles.systemDefinedRoles);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader className="p-2">
        <Header.Title>
          <FormattedMessage {...pageTitles.systemDefinedRoles} />
        </Header.Title>
      </SectionHeader>
      <ResourceGridDataLayoutWithFilters filterFields={gridFilterFields}>
        <DataGridPage
          data={nodes}
          loadNext={loadNext}
          hasNext={hasNext}
          spec={tableSpec}
          scrollKey="data-grid"
        />
      </ResourceGridDataLayoutWithFilters>
    </PermissionsGuard>
  );
}
