/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ShowInviteLinkAction_organizationInvite = {
    readonly organizationInviteId: string | null;
    readonly " $refType": "ShowInviteLinkAction_organizationInvite";
};
export type ShowInviteLinkAction_organizationInvite$data = ShowInviteLinkAction_organizationInvite;
export type ShowInviteLinkAction_organizationInvite$key = {
    readonly " $data"?: ShowInviteLinkAction_organizationInvite$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ShowInviteLinkAction_organizationInvite">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowInviteLinkAction_organizationInvite",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationInviteId",
      "storageKey": null
    }
  ],
  "type": "OrganizationInvite",
  "abstractKey": null
};
(node as any).hash = 'b318940f783b68cef1d8cd168460a445';
export default node;
