import Layout from '@4c/layout';
import Text from '@bfly/ui2/Text';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function AppFooter() {
  return (
    <footer className="p-4">
      <Layout justify="center">
        <Text variant="body-bold" color="headline">
          <FormattedMessage
            id="footer.info"
            defaultMessage="Internal use only. All activity is logged."
          />
        </Text>
      </Layout>
    </footer>
  );
}
