import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import DomainDetailPage from './components/DomainDetailPage';
import DomainsGrid from './components/DomainsGrid';
import DomainsPage from './components/DomainsPage';
import MdmSettingsPage from './components/MdmSettingsPage';

export default (
  <Route path="domains">
    <Route Component={DomainsPage}>
      <Route
        Component={DomainsGrid}
        query={graphql`
          query domains_DomainsGrid_Query(
            $id: [String!]
            $subdomainLabel: [String!]
          ) {
            viewer {
              ...DomainsGrid_viewer
                @arguments(id: $id, subdomainLabel: $subdomainLabel)
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          id: location.query.domainId,
          subdomainLabel: location.query.subdomainLabel,
        })}
      />
    </Route>
    <Route
      path=":domainId"
      Component={DomainDetailPage}
      query={graphql`
        query domains_DomainDetailPage_Query($domainId: String!) {
          domain(domainId: $domainId) {
            ...DomainDetailPage_domain
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.domain) throw new HttpError(404);
      }}
    />
    <Route
      path=":domainId/mdm"
      Component={MdmSettingsPage}
      query={graphql`
        query domains_MdmSettingsPage_Query($domainId: String!) {
          domain(domainId: $domainId) {
            ...MdmSettingsPage_domain
          }
        }
      `}
    />
  </Route>
);
