/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateDomainInput = {
    domainId: string;
    name?: string | null | undefined;
    samlEmailAttributeNameRaw?: string | null | undefined;
    samlNicknameAttributeNameRaw?: string | null | undefined;
    requiredAndroidCapabilities?: Array<string | null> | null | undefined;
    enableSharedDeviceCodeLogin?: boolean | null | undefined;
    enableButterflyLogin?: boolean | null | undefined;
    maxNumSeats?: number | null | undefined;
    country?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateDomainModalMutationVariables = {
    input: UpdateDomainInput;
};
export type UpdateDomainModalMutationResponse = {
    readonly updateDomain: {
        readonly domain: {
            readonly " $fragmentRefs": FragmentRefs<"DomainsGrid_domain">;
        } | null;
    } | null;
};
export type UpdateDomainModalMutation = {
    readonly response: UpdateDomainModalMutationResponse;
    readonly variables: UpdateDomainModalMutationVariables;
};



/*
mutation UpdateDomainModalMutation(
  $input: UpdateDomainInput!
) {
  updateDomain(input: $input) {
    domain {
      ...DomainsGrid_domain
      id
    }
  }
}

fragment DomainsGrid_domain on Domain {
  id
  domainId
  createdAt
  deletedAt
  subdomainLabel
  name
  enableButterflyLogin
  enableSamlLogin
  enableSharedDeviceCodeLogin
  samlEmailAttributeName
  samlEmailAttributeNameRaw
  samlNicknameAttributeName
  samlNicknameAttributeNameRaw
  cognitoClientId
  inactivityTimeoutSeconds
  requiredAndroidCapabilities
  sharedDeviceKey
  numUsers
  maxNumSeats
  numAvailableSeats
  country
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDomainModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDomainPayload",
        "kind": "LinkedField",
        "name": "updateDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DomainsGrid_domain"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDomainModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDomainPayload",
        "kind": "LinkedField",
        "name": "updateDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domainId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deletedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subdomainLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableButterflyLogin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableSamlLogin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableSharedDeviceCodeLogin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlEmailAttributeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlEmailAttributeNameRaw",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlNicknameAttributeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "samlNicknameAttributeNameRaw",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cognitoClientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inactivityTimeoutSeconds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredAndroidCapabilities",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sharedDeviceKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numUsers",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxNumSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numAvailableSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42d4e255ffaf77fa8626f56d2711e884",
    "id": null,
    "metadata": {},
    "name": "UpdateDomainModalMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDomainModalMutation(\n  $input: UpdateDomainInput!\n) {\n  updateDomain(input: $input) {\n    domain {\n      ...DomainsGrid_domain\n      id\n    }\n  }\n}\n\nfragment DomainsGrid_domain on Domain {\n  id\n  domainId\n  createdAt\n  deletedAt\n  subdomainLabel\n  name\n  enableButterflyLogin\n  enableSamlLogin\n  enableSharedDeviceCodeLogin\n  samlEmailAttributeName\n  samlEmailAttributeNameRaw\n  samlNicknameAttributeName\n  samlNicknameAttributeNameRaw\n  cognitoClientId\n  inactivityTimeoutSeconds\n  requiredAndroidCapabilities\n  sharedDeviceKey\n  numUsers\n  maxNumSeats\n  numAvailableSeats\n  country\n}\n"
  }
};
})();
(node as any).hash = 'c4c3dbb4d336c686d2c49d6308145ced';
export default node;
