import { defineMessages } from 'react-intl';

export const productionIdMessages = defineMessages({
  label: {
    id: 'newButterflyDevice.productionId.label',
    defaultMessage: 'Production ID*',
  },
  placeholder: {
    id: 'newButterflyDevice.productionId.placeholder',
    defaultMessage: 'Production ID',
  },
  required: {
    id: 'newButterflyDevice.productionId.required',
    defaultMessage: 'Please enter a Production ID',
  },
});
