import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import EducationCategoriesPage from './components/EducationCategoriesPage';
import EducationCategoryDetailPage from './components/EducationCategoryDetailPage';

export default (
  <Route path="education-categories">
    <Route
      Component={EducationCategoriesPage}
      query={graphql`
        query educationCategories_EducationCategoriesPage_Query {
          viewer {
            ...EducationCategoriesPage_viewer
          }
        }
      `}
    />
    <Route
      path=":educationCategoryId"
      Component={EducationCategoryDetailPage}
      query={graphql`
        query educationCategories_EducationCategoryDetailPage_Query(
          $educationCategoryId: String!
        ) {
          educationCategory(educationCategoryId: $educationCategoryId) {
            ...EducationCategoryDetailPage_educationCategory
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.educationCategory) throw new HttpError(404);
      }}
    />
  </Route>
);
