import rangeAddUpdater from '@bfly/utils/rangeAddUpdater';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import domainInvitesMessages from '../messages/domainInvitesMessages';
import UserInviteModal, { userInviteSchema } from './UserInviteModal';
import { CreateUserInviteModal_viewer$key } from './__generated__/CreateUserInviteModal_viewer.graphql';

const createMutation = graphql`
  mutation CreateUserInviteModalMutation($input: CreateUserInviteInput!) {
    createUserInvite(input: $input) {
      userInviteEdge {
        node {
          id
          ...DomainInvitesGrid_userInvite
        }
      }
    }
  }
`;

const fragment = graphql`
  fragment CreateUserInviteModal_viewer on Viewer {
    id
  }
`;

interface CreateUserInviteModalProps extends ActionModalProps {
  viewerRef: CreateUserInviteModal_viewer$key;
}

export default function CreateUserInviteModal({
  viewerRef,
  ...props
}: CreateUserInviteModalProps) {
  const viewer = useFragment(fragment, viewerRef);
  const updater = useCallback(
    (store) => {
      return rangeAddUpdater(store, {
        parentId: viewer.id,
        connectionKey: 'DomainInvitesGrid_userInvites',
        rootFieldName: 'createUserInvite',
        edgeName: 'userInviteEdge',
        rangeBehavior: 'prepend',
      });
    },
    [viewer],
  );

  return (
    <UserInviteModal
      title={<FormattedMessage {...domainInvitesMessages.createInvite} />}
      submitText={<FormattedMessage {...actionMessages.create} />}
      schema={userInviteSchema}
      mutation={createMutation}
      updater={updater}
      {...props}
    />
  );
}
