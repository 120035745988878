import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';
import { useBreadcrumbsContext } from 'utils/useBreadcrumbsContext';

import { DeleteEducationCategoryAction_educationCategory$key } from './__generated__/DeleteEducationCategoryAction_educationCategory.graphql';

const fragment = graphql`
  fragment DeleteEducationCategoryAction_educationCategory on EducationCategory {
    id
    educationCategoryId
    name
  }
`;

const mutation = graphql`
  mutation DeleteEducationCategoryActionMutation(
    $input: DeleteEducationCategoryInput!
  ) {
    deleteEducationCategory(input: $input) {
      deletedId
    }
  }
`;

interface DeleteEducationCategoryActionProps {
  educationCategoryRef: DeleteEducationCategoryAction_educationCategory$key;
}

export default function DeleteDomainUserIntegrationConfigAction({
  educationCategoryRef,
}: DeleteEducationCategoryActionProps) {
  const educationCategory = useFragment(fragment, educationCategoryRef);
  const { deleteBreadcrumb } = useBreadcrumbsContext();
  return (
    <DeleteAction
      itemName={educationCategory.name}
      mutation={mutation}
      redirectTo="/education-categories"
      onDelete={() =>
        deleteBreadcrumb(
          `/education-categories/${educationCategory.educationCategoryId}`,
        )
      }
      input={{
        educationCategoryId: educationCategory.educationCategoryId,
      }}
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
