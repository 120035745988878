import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import EmailChangeRequestDetailPage from './components/EmailChangeRequestDetailPage';
import EmailChangeRequestsGrid from './components/EmailChangeRequestsGrid';
import EmailChangeRequestsPage from './components/EmailChangeRequestsPage';

export default (
  <Route path="email-changes">
    <Route Component={EmailChangeRequestsPage}>
      <Route
        Component={EmailChangeRequestsGrid}
        query={graphql`
          query emailChangeRequests_EmailChangeRequestsGrid_Query(
            $email: [String!]
            $oldEmail: [String!]
          ) {
            viewer {
              ...EmailChangeRequestsGrid_viewer
                @arguments(email: $email, oldEmail: $oldEmail)
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          email: location.query.email,
          oldEmail: location.query.oldEmail,
        })}
      />
    </Route>
    <Route
      path=":emailChangeRequestId"
      Component={EmailChangeRequestDetailPage}
      query={graphql`
        query emailChangeRequests_EmailChangeRequestDetailPage_Query(
          $emailChangeRequestId: String!
        ) {
          emailChangeRequest: emailChangeRequest(
            emailChangeRequestId: $emailChangeRequestId
          ) {
            ...EmailChangeRequestDetailPage_emailChangeRequest
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.emailChangeRequest) throw new HttpError(404);
      }}
    />
  </Route>
);
