import Layout from '@4c/layout';
import Header from '@bfly/ui2/Header';
import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import ResourceGridAction from 'components/ResourceGridAction';
import ResourceGridData from 'components/ResourceGridData';
import SectionHeader from 'components/SectionHeader';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';

import tableHeadings from '../messages/butterflyDeviceRecallsTableHeadings';
import { CreateButterflyDeviceRecallModal } from './CreateButterflyDeviceRecallModal';
import { ButterflyDeviceRecallsPage_butterflyDeviceRecall as DeviceRecall } from './__generated__/ButterflyDeviceRecallsPage_butterflyDeviceRecall.graphql';
import { ButterflyDeviceRecallsPage_viewer$key } from './__generated__/ButterflyDeviceRecallsPage_viewer.graphql';

const resource = Resource.BUTTERFLY_DEVICE_RECALLS;

const _ = graphql`
  fragment ButterflyDeviceRecallsPage_butterflyDeviceRecall on ButterflyDeviceRecall {
    id
    recallId
    message
  }
`;

const fragment = graphql`
  fragment ButterflyDeviceRecallsPage_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "ButterflyDeviceRecallsPageRefetchQuery") {
    ...CreateButterflyDeviceRecallModal_viewer
    butterflyDeviceRecalls(first: $count, after: $cursor)
      @connection(key: "ButterflyDeviceRecallsPage_butterflyDeviceRecalls") {
      edges {
        node {
          ...ButterflyDeviceRecallsPage_butterflyDeviceRecall
            @relay(mask: false)
        }
      }
    }
  }
`;

export const tableSpec: ColumnSpec<DeviceRecall>[] = [
  {
    key: 'recallId',
    label: <FormattedMessage {...tableHeadings.recallId} />,
    frozen: true,
    render: ({ item }) => (
      <QuickCopyText text={item.recallId}>
        <DataGridLink path="/butterfly-device-recalls" id={item.recallId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'message',
    label: <FormattedMessage {...tableHeadings.message} />,
    render: ({ item }) => <DataGridText value={item.message} />,
  },
];

interface ButterflyDeviceRecallsPage {
  viewer: ButterflyDeviceRecallsPage_viewer$key;
}

export default function ButterflyDeviceRecallsPage({
  viewer,
}: ButterflyDeviceRecallsPage) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data!.butterflyDeviceRecalls);
  useBreadcrumbDetector(pageTitles.butterflyDeviceRecalls);

  return (
    <PermissionsGuard resource={resource}>
      <SectionHeader className="p-2">
        <Header.Title>
          <FormattedMessage {...pageTitles.butterflyDeviceRecalls} />
        </Header.Title>
        <Layout.Spacer />
        <ResourceGridAction
          resource={resource}
          renderActionModal={(props) => (
            <CreateButterflyDeviceRecallModal {...props} viewerRef={data} />
          )}
        >
          <FormattedMessage
            id="ButterflyDeviceRecallsPage.actonTitle"
            defaultMessage="Create Device Recall"
          />
        </ResourceGridAction>
      </SectionHeader>
      <ResourceGridData
        data={nodes}
        loadNext={loadNext}
        hasNext={hasNext}
        spec={tableSpec}
      />
    </PermissionsGuard>
  );
}
