/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DomainModalCountryConfiguration_QueryVariables = {
    dataRegion?: Array<string> | null | undefined;
};
export type DomainModalCountryConfiguration_QueryResponse = {
    readonly viewer: {
        readonly countryConfigurations: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly dataRegion: string | null;
                    readonly countryId: string | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type DomainModalCountryConfiguration_Query = {
    readonly response: DomainModalCountryConfiguration_QueryResponse;
    readonly variables: DomainModalCountryConfiguration_QueryVariables;
};



/*
query DomainModalCountryConfiguration_Query(
  $dataRegion: [String!]
) {
  viewer {
    countryConfigurations(dataRegion: $dataRegion) {
      edges {
        node {
          dataRegion
          countryId
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataRegion"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "dataRegion",
    "variableName": "dataRegion"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataRegion",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DomainModalCountryConfiguration_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CountryConfigurationConnection",
            "kind": "LinkedField",
            "name": "countryConfigurations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CountryConfigurationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CountryConfiguration",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DomainModalCountryConfiguration_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CountryConfigurationConnection",
            "kind": "LinkedField",
            "name": "countryConfigurations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CountryConfigurationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CountryConfiguration",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2628dba0e0312abe215e897183b6b0a7",
    "id": null,
    "metadata": {},
    "name": "DomainModalCountryConfiguration_Query",
    "operationKind": "query",
    "text": "query DomainModalCountryConfiguration_Query(\n  $dataRegion: [String!]\n) {\n  viewer {\n    countryConfigurations(dataRegion: $dataRegion) {\n      edges {\n        node {\n          dataRegion\n          countryId\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '9ec7c42eac72d25176bbe17763241f40';
export default node;
