/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EducationContentPage_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"CreateEducationContentModal_viewer">;
    readonly " $refType": "EducationContentPage_viewer";
};
export type EducationContentPage_viewer$data = EducationContentPage_viewer;
export type EducationContentPage_viewer$key = {
    readonly " $data"?: EducationContentPage_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EducationContentPage_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EducationContentPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateEducationContentModal_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '0dac266f688a98c907c13fae43e8fda5';
export default node;
