import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import DomainUserIntegrationConfigDetailPage from './components/DomainUserIntegrationConfigDetailPage';
import DomainUserIntegrationConfigsGrid from './components/DomainUserIntegrationConfigsGrid';
import DomainUserIntegrationConfigsPage from './components/DomainUserIntegrationConfigsPage';

export default (
  <Route path="domain-user-integrations-configs">
    <Route
      Component={DomainUserIntegrationConfigsPage}
      query={graphql`
        query domainUserIntegrationConfigs_DomainUserIntegrationConfigsPage_Query {
          viewer {
            ...DomainUserIntegrationConfigsPage_viewer
          }
        }
      `}
    >
      <Route
        Component={DomainUserIntegrationConfigsGrid}
        query={graphql`
          query domainUserIntegrationConfigs_DomainUserIntegrationConfigsGrid_Query(
            $domainId: [String!]
            $userId: [String!]
          ) {
            viewer {
              ...DomainUserIntegrationConfigsGrid_viewer
                @arguments(domainId: $domainId, userId: $userId)
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          domainId: location.query.domainId,
          userId: location.query.userId,
        })}
      />
    </Route>
    <Route
      path=":domainId/:userId/:integrationId"
      Component={DomainUserIntegrationConfigDetailPage}
      query={graphql`
        query domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_Query(
          $domainUserIntegrationConfigId: String!
        ) {
          domainUserIntegrationConfig(
            domainUserIntegrationConfigId: $domainUserIntegrationConfigId
          ) {
            ...DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig
          }
        }
      `}
      prepareVariables={({ domainId, userId, integrationId }) => ({
        domainUserIntegrationConfigId: `${domainId}/${userId}/${integrationId}`,
      })}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.domainUserIntegrationConfig) throw new HttpError(404);
      }}
    />
  </Route>
);
