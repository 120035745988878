import getNodes from '@bfly/utils/getNodes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';

import DataGridLink from 'components/DataGridLink';
import DataGridPage, { ColumnSpec } from 'components/DataGridPage';
import DataGridText from 'components/DataGridText';
import QuickCopyText from 'components/QuickCopyText';
import tableHeadings from 'messages/tableHeadings';

import domainUserIntegrationConfigTableHeadings from '../messages/domainUserIntegrationConfigsTableHeadings';
import { DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig as DomainUserIntegrationConfig } from './__generated__/DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig.graphql';
import { DomainUserIntegrationConfigsGrid_viewer$key } from './__generated__/DomainUserIntegrationConfigsGrid_viewer.graphql';

const _ = graphql`
  fragment DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig on DomainUserIntegrationConfig {
    id
    domainUserIntegrationConfigId
    interfaceCode
    ehr {
      ehrId
    }
    domainUser {
      domainUserId
      domain {
        domainId
      }
      user {
        userId
      }
      name
      email
    }
  }
`;

const fragment = graphql`
  fragment DomainUserIntegrationConfigsGrid_viewer on Viewer
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 20 }
    cursor: { type: "String" }
    domainId: { type: "[String!]" }
    userId: { type: "[String!]" }
  )
  @refetchable(queryName: "DomainUserIntegrationConfigsGridRefetchQuery") {
    ...CreateDomainUserIntegrationConfigModal_viewer
    domainUserIntegrationConfigs(
      first: $count
      after: $cursor
      domainId: $domainId
      userId: $userId
    )
      @connection(
        key: "DomainUserIntegrationConfigsGrid_domainUserIntegrationConfigs"
        filters: []
      ) {
      edges {
        node {
          ...DomainUserIntegrationConfigsGrid_domainUserIntegrationConfig
            @relay(mask: false)
        }
      }
    }
  }
`;

const tableSpec: ColumnSpec<DomainUserIntegrationConfig>[] = [
  {
    key: 'domainUserIntegrationConfigId',
    frozen: true,
    label: (
      <FormattedMessage
        {...domainUserIntegrationConfigTableHeadings.domainUserIntegrationConfigId}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.domainUserIntegrationConfigId}>
        <DataGridLink
          path="/domain-user-integrations-configs"
          id={item.domainUserIntegrationConfigId}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'interfaceCode',
    label: (
      <FormattedMessage
        {...domainUserIntegrationConfigTableHeadings.interfaceCode}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.interfaceCode}>
        <DataGridText value={item.interfaceCode} />
      </QuickCopyText>
    ),
  },
  {
    key: 'ehrId',
    label: <FormattedMessage {...tableHeadings.ehrId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.ehr?.ehrId}>
        <DataGridLink path="/ehrs" id={item.ehr?.ehrId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'domainUserDomainId',
    label: <FormattedMessage {...tableHeadings.domainId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domainUser?.domain?.domainId}>
        <DataGridLink path="/domains" id={item.domainUser?.domain?.domainId} />
      </QuickCopyText>
    ),
  },
  {
    key: 'userId',
    label: <FormattedMessage {...tableHeadings.userId} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domainUser?.user?.userId}>
        <DataGridLink
          path="/users"
          title={item.domainUser!.user!.userId!}
          id={`${item.domainUser?.user?.userId}/${
            item.domainUser?.domain?.domainId ?? '-'
          }`}
        />
      </QuickCopyText>
    ),
  },
  {
    key: 'userName',
    label: <FormattedMessage {...tableHeadings.userName} />,
    render: ({ item }) => (
      <QuickCopyText text={item.domainUser?.name}>
        <DataGridText value={item.domainUser?.name} />
      </QuickCopyText>
    ),
  },
  {
    key: 'userEmail',
    label: (
      <FormattedMessage
        {...domainUserIntegrationConfigTableHeadings.userEmail}
      />
    ),
    render: ({ item }) => (
      <QuickCopyText text={item.domainUser?.email}>
        <DataGridText value={item.domainUser?.email} />
      </QuickCopyText>
    ),
  },
];

interface DomainUserIntegrationsConfigsPageProps {
  viewer: DomainUserIntegrationConfigsGrid_viewer$key;
}

export default function DomainUserIntegrationConfigsGrid({
  viewer,
}: DomainUserIntegrationsConfigsPageProps) {
  const { data, loadNext, hasNext } = usePaginationFragment(fragment, viewer);
  const nodes = getNodes(data.domainUserIntegrationConfigs);

  return (
    <DataGridPage
      data={nodes}
      loadNext={loadNext}
      hasNext={hasNext}
      spec={tableSpec}
      scrollKey="data-grid"
    />
  );
}
