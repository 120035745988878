/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ButterflyDeviceRecallDetailPage_butterflyDeviceRecall = {
    readonly id: string;
    readonly recallId: string | null;
    readonly message: string | null;
    readonly createdAt: string | null;
    readonly " $fragmentRefs": FragmentRefs<"UpdateButterflyDeviceRecallModal_butterflyDeviceRecall">;
    readonly " $refType": "ButterflyDeviceRecallDetailPage_butterflyDeviceRecall";
};
export type ButterflyDeviceRecallDetailPage_butterflyDeviceRecall$data = ButterflyDeviceRecallDetailPage_butterflyDeviceRecall;
export type ButterflyDeviceRecallDetailPage_butterflyDeviceRecall$key = {
    readonly " $data"?: ButterflyDeviceRecallDetailPage_butterflyDeviceRecall$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ButterflyDeviceRecallDetailPage_butterflyDeviceRecall">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ButterflyDeviceRecallDetailPage_butterflyDeviceRecall",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recallId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateButterflyDeviceRecallModal_butterflyDeviceRecall"
    }
  ],
  "type": "ButterflyDeviceRecall",
  "abstractKey": null
};
(node as any).hash = '813e7e13a28ab7ddf4149dbd0d1d005a';
export default node;
