import Button from '@bfly/ui2/Button';
import useRouter from 'found/useRouter';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { string } from 'yup';

import Alert from 'components/Alert';
import {
  OrganizationModal,
  basicOrganizationSchema,
} from 'components/OrganizationModal';
import {
  adminEmailMessages,
  countryMessages,
} from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { dlDataModeOverrideFieldSchema } from '../../organizations/components/DLDataModeOverrideAction';
import {
  ProvisionOrganizationForSubscriptionActionMutation,
  ProvisionOrganizationForSubscriptionActionMutationResponse,
} from './__generated__/ProvisionOrganizationForSubscriptionActionMutation.graphql';
import { ProvisionOrganizationForSubscriptionAction_subscription$key } from './__generated__/ProvisionOrganizationForSubscriptionAction_subscription.graphql';

export const provisionForSubscriptionSchema = basicOrganizationSchema.shape({
  subscriptionId: string(),
  country: string().required(countryMessages.required).max(2),
  adminEmail: string()
    .email(adminEmailMessages.validation)
    .required(adminEmailMessages.required),
  dlDataModeOverride: dlDataModeOverrideFieldSchema.default('EXCLUDE'),
});

const schemaDefaults = provisionForSubscriptionSchema.getDefault();

const fragment = graphql`
  fragment ProvisionOrganizationForSubscriptionAction_subscription on Subscription {
    id
    subscriptionId
  }
`;

const mutation = graphql`
  mutation ProvisionOrganizationForSubscriptionActionMutation(
    $input: ProvisionOrganizationForSubscriptionInput!
  ) {
    provisionOrganizationForSubscription(input: $input) {
      organization {
        id
        organizationId
      }
    }
  }
`;

interface ProvisionOrganizationActionProps {
  subscriptionRef: ProvisionOrganizationForSubscriptionAction_subscription$key;
}

export default function ProvisionOrganizationForSubscriptionAction({
  subscriptionRef,
}: ProvisionOrganizationActionProps) {
  const { router } = useRouter();
  const { subscriptionId } = useFragment(fragment, subscriptionRef);
  const [showModal, setShowModal] = useState(false);

  return (
    <PermissionsGuard resource={Resource.PROVISIONING} write hideContent>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        <FormattedMessage
          id="provisionOrganizationForSubscription.title"
          defaultMessage="Provision Organization"
        />
      </Button>
      <OrganizationModal<ProvisionOrganizationForSubscriptionActionMutation>
        forSubscription
        show={showModal}
        onClose={() => setShowModal(false)}
        title={
          <FormattedMessage
            id="provisionOrganizationForSubscription.title"
            defaultMessage="Provision Organization"
          />
        }
        submitText={
          <FormattedMessage
            id="provisionOrganizationForSubscription.submitText"
            defaultMessage="Provision Organization"
          />
        }
        mutation={mutation}
        schema={provisionForSubscriptionSchema}
        defaultValue={{ ...schemaDefaults, subscriptionId }}
        onCompleted={({
          provisionOrganizationForSubscription,
        }: ProvisionOrganizationForSubscriptionActionMutationResponse) => {
          if (
            provisionOrganizationForSubscription?.organization?.organizationId
          ) {
            router.push({
              pathname: `/organizations/${provisionOrganizationForSubscription.organization.organizationId}`,
            });
          }
        }}
        alertMessage={
          <Alert
            variant="warning"
            className="mb-5"
            data-cy="ProvisionOrgForSubscriptionWarning"
          >
            <FormattedMessage
              id="provisionOrganizationForSubscription.warning"
              defaultMessage="This form will provision a new Organization under this Subscription. If you need to create a new Subscription (PlanType, MaxNumSeats, etc), do so from the Domain detail page (for Enterprise) or Customer detail page (for non-Enterprise)."
            />
          </Alert>
        }
      />
    </PermissionsGuard>
  );
}
