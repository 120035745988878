import Button from '@bfly/ui2/Button';
import CopyToClipboardControl from '@bfly/ui2/CopyToClipboardControl';
import Form from '@bfly/ui2/Form';
import useQuery from '@bfly/ui2/useQuery';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import ModalForm from 'components/ModalForm';
import { ActionModalProps } from 'components/ResourceModal';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import {
  inviteLinkUrlMessages,
  organizationInviteIdMessages,
} from '../messages/inviteActions';
import { ShowInviteLinkAction_Link_Query as Query } from './__generated__/ShowInviteLinkAction_Link_Query.graphql';
import { ShowInviteLinkAction_organizationInvite$key } from './__generated__/ShowInviteLinkAction_organizationInvite.graphql';

const showInviteLinkSchema = object({
  organizationInviteId: string().meta({ readOnly: true }),
});

const fragment = graphql`
  fragment ShowInviteLinkAction_organizationInvite on OrganizationInvite {
    organizationInviteId
  }
`;

const inviteLinkQuery = graphql`
  query ShowInviteLinkAction_Link_Query($organizationInviteId: String!) {
    invite: organizationInvite(organizationInviteId: $organizationInviteId) {
      linkUrl
    }
  }
`;

interface ShowInviteLinkModalProps extends ActionModalProps {
  organizationInviteId: string;
}

function ShowInviteLinkModal({
  show,
  onClose,
  organizationInviteId,
}: ShowInviteLinkModalProps) {
  const { data } = useQuery<Query>(inviteLinkQuery, {
    variables: { organizationInviteId },
  });
  return (
    <ModalForm
      title={
        <FormattedMessage
          id="showInviteLinkModal.title"
          defaultMessage="Show Invite Link"
        />
      }
      schema={showInviteLinkSchema}
      defaultValue={{ organizationInviteId }}
      loading={!data}
      show={show}
      onCancel={() => onClose()}
      horizontal
    >
      <Form.FieldGroup
        name="organizationInviteId"
        label={<FormattedMessage {...organizationInviteIdMessages.label} />}
        data-cy="showInviteLinkModal-organizationInviteId"
        disabled
      />
      <Form.Group
        label={<FormattedMessage {...inviteLinkUrlMessages.label} />}
        data-cy="showInviteLinkModal-linkUrl"
      >
        <CopyToClipboardControl
          autoFocus
          value={data?.invite?.linkUrl || ''}
          data-cy="showInviteLinkModal-copyLink"
          buttonLabel={
            <FormattedMessage
              id="showInviteLinkModal.copyLink"
              defaultMessage="Copy URL"
            />
          }
          successMessage={
            <FormattedMessage
              id="showInviteLinkModal.copyToClipboard.success"
              defaultMessage="Link URL is copied to clipboard"
            />
          }
        />
      </Form.Group>
    </ModalForm>
  );
}

interface InviteActionsProps {
  inviteRef: ShowInviteLinkAction_organizationInvite$key;
}

export default function ShowInviteLinkAction({
  inviteRef,
}: InviteActionsProps) {
  const { organizationInviteId } = useFragment(fragment, inviteRef);
  const [showInviteLinkModal, setShowInviteLinkModal] = useState(false);
  return (
    <PermissionsGuard resource={Resource.INVITES} write hideContent>
      <Button
        variant="secondary"
        onClick={() => setShowInviteLinkModal(true)}
        data-cy="inviteDetail-actions-showInviteLink"
      >
        <FormattedMessage
          id="inviteDetail.actions.showInviteLink"
          defaultMessage="Show Invite Link"
        />
      </Button>
      <ShowInviteLinkModal
        show={showInviteLinkModal}
        onClose={() => setShowInviteLinkModal(false)}
        organizationInviteId={organizationInviteId!}
      />
    </PermissionsGuard>
  );
}
