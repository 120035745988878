import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import ButterflyDeviceDetailPage from './components/ButterflyDeviceDetailPage';
import ButterflyDevicesGrid from './components/ButterflyDevicesGrid';
import ButterflyDevicesPage from './components/ButterflyDevicesPage';

export default (
  <Route path="butterfly-devices">
    <Route
      Component={ButterflyDevicesPage}
      query={graphql`
        query butterflyDevices_ButterflyDevicesPage_Query {
          viewer {
            ...ButterflyDevicesPage_viewer
          }
        }
      `}
    >
      <Route
        Component={ButterflyDevicesGrid}
        query={graphql`
          query butterflyDevices_ButterflyDevicesGrid_Query(
            $productionId: [String!]
            $organizationId: [String!]
          ) {
            viewer {
              ...ButterflyDevicesGrid_viewer
                @arguments(
                  productionId: $productionId
                  organizationId: $organizationId
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          productionId: location.query.productionId,
          organizationId: location.query.organizationId,
        })}
      />
    </Route>
    <Route
      path=":productionId"
      Component={ButterflyDeviceDetailPage}
      query={graphql`
        query butterflyDevices_ButterflyDeviceDetailPage_Query(
          $productionId: String!
        ) {
          butterflyDevice(productionId: $productionId) {
            ...ButterflyDeviceDetailPage_butterflyDevice
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.butterflyDevice) throw new HttpError(404);
      }}
    />
  </Route>
);
