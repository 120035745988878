import { defineMessages } from 'react-intl';

export const customerIdMessages = defineMessages({
  label: {
    id: 'customerModal.customerId.label',
    defaultMessage: 'Customer ID',
  },
  placeholder: {
    id: 'customerModal.customerId.placeholder',
    defaultMessage: 'Customer ID',
  },
});

export const customerNameMessages = defineMessages({
  label: {
    id: 'customerModal.name.label',
    defaultMessage: 'Name*',
  },
  placeholder: {
    id: 'customerModal.name.placeholder',
    defaultMessage: 'Name',
  },
  required: {
    id: 'customerModal.name.required',
    defaultMessage: 'Please enter a customer name',
  },
  description: {
    id: 'customerModal.name.description',
    defaultMessage: 'Customer Name',
  },
});

export const zendeskIdMessages = defineMessages({
  label: {
    id: 'customerModal.zendeskId.label',
    defaultMessage: 'Zendesk ID',
  },
  placeholder: {
    id: 'customerModal.zendeskId.placeholder',
    defaultMessage: 'Zendesk ID',
  },
  description: {
    id: 'customerModal.zendeskId.description',
    defaultMessage:
      'The Zendesk Organization ID associated with this Customer',
  },
});

export const netsuiteIdMessages = defineMessages({
  label: {
    id: 'customerModal.netsuiteId.label',
    defaultMessage: 'Netsuite ID',
  },
  placeholder: {
    id: 'customerModal.netsuiteId.placeholder',
    defaultMessage: 'Netsuite ID',
  },
  description: {
    id: 'customerModal.netsuiteId.description',
    defaultMessage: 'The Netsuite ID associated with this Customer',
  },
});

export const stripeIdMessages = defineMessages({
  label: {
    id: 'customerModal.stripeId.label',
    defaultMessage: 'Stripe ID',
  },
  placeholder: {
    id: 'customerModal.stripeId.placeholder',
    defaultMessage: 'Stripe ID',
  },
  description: {
    id: 'customerModal.stripeId.description',
    defaultMessage: 'The Stripe Customer ID associated with this Customer',
  },
});

export const sfAccountIdMessages = defineMessages({
  label: {
    id: 'customerModal.sfAccountId.label',
    defaultMessage: 'Sf Account ID',
  },
  placeholder: {
    id: 'customerModal.sfAccountId.placeholder',
    defaultMessage: 'Sf Account ID',
  },
  description: {
    id: 'customerModal.sfAccountId.description',
    defaultMessage: 'The Salesforce Account ID associated with this Customer',
  },
});
