/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteDomainUserInput = {
    domainUserId: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteDomainUserActionDeleteMutationVariables = {
    input: DeleteDomainUserInput;
};
export type DeleteDomainUserActionDeleteMutationResponse = {
    readonly deleteDomainUser: {
        readonly domainUser: {
            readonly __typename: string;
            readonly deletedAt: string | null;
        } | null;
    } | null;
};
export type DeleteDomainUserActionDeleteMutation = {
    readonly response: DeleteDomainUserActionDeleteMutationResponse;
    readonly variables: DeleteDomainUserActionDeleteMutationVariables;
};



/*
mutation DeleteDomainUserActionDeleteMutation(
  $input: DeleteDomainUserInput!
) {
  deleteDomainUser(input: $input) {
    domainUser {
      __typename
      deletedAt
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDomainUserActionDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteDomainUserPayload",
        "kind": "LinkedField",
        "name": "deleteDomainUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUser",
            "kind": "LinkedField",
            "name": "domainUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDomainUserActionDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteDomainUserPayload",
        "kind": "LinkedField",
        "name": "deleteDomainUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUser",
            "kind": "LinkedField",
            "name": "domainUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4011406753858589188cd02076d548e8",
    "id": null,
    "metadata": {},
    "name": "DeleteDomainUserActionDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDomainUserActionDeleteMutation(\n  $input: DeleteDomainUserInput!\n) {\n  deleteDomainUser(input: $input) {\n    domainUser {\n      __typename\n      deletedAt\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e7804c6dcdbeaa819975bb8f0b77b176';
export default node;
