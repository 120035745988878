/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SetOrganizationSubscriptionInput = {
    organizationId: string;
    subscriptionId: string;
    clientMutationId?: string | null | undefined;
};
export type SetSubscriptionAction_SetSubscriptionMutationVariables = {
    input: SetOrganizationSubscriptionInput;
};
export type SetSubscriptionAction_SetSubscriptionMutationResponse = {
    readonly setOrganizationSubscription: {
        readonly organization: {
            readonly subscription: {
                readonly subscriptionId: string | null;
            } | null;
        } | null;
    } | null;
};
export type SetSubscriptionAction_SetSubscriptionMutation = {
    readonly response: SetSubscriptionAction_SetSubscriptionMutationResponse;
    readonly variables: SetSubscriptionAction_SetSubscriptionMutationVariables;
};



/*
mutation SetSubscriptionAction_SetSubscriptionMutation(
  $input: SetOrganizationSubscriptionInput!
) {
  setOrganizationSubscription(input: $input) {
    organization {
      subscription {
        subscriptionId
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetSubscriptionAction_SetSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrganizationSubscriptionPayload",
        "kind": "LinkedField",
        "name": "setOrganizationSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetSubscriptionAction_SetSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetOrganizationSubscriptionPayload",
        "kind": "LinkedField",
        "name": "setOrganizationSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54a90d36ce4e62784180613d6d74f636",
    "id": null,
    "metadata": {},
    "name": "SetSubscriptionAction_SetSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation SetSubscriptionAction_SetSubscriptionMutation(\n  $input: SetOrganizationSubscriptionInput!\n) {\n  setOrganizationSubscription(input: $input) {\n    organization {\n      subscription {\n        subscriptionId\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '113137db00607e94ca0172c930aac7b8';
export default node;
