import { HttpError } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

import OrganizationDetailPage from './components/OrganizationDetailPage';
import OrganizationsGrid from './components/OrganizationsGrid';
import OrganizationsPage from './components/OrganizationsPage';

export default (
  <Route path="organizations">
    <Route
      Component={OrganizationsPage}
      query={graphql`
        query organizations_OrganizationsPage_Query {
          viewer {
            ...OrganizationsPage_viewer
          }
        }
      `}
    >
      <Route
        Component={OrganizationsGrid}
        query={graphql`
          query organizations_OrganizationsGrid_Query(
            $customerId: [String!]
            $subscriptionId: [String!]
            $domainId: [String!]
            $specialType: [String!]
            $stripeSubscriptionId: [String!]
            $slug: [String!]
            $name: [String!]
            $id: [String!]
          ) {
            viewer {
              ...OrganizationsGrid_viewer
                @arguments(
                  customerId: $customerId
                  subscriptionId: $subscriptionId
                  domainId: $domainId
                  specialType: $specialType
                  stripeSubscriptionId: $stripeSubscriptionId
                  slug: $slug
                  name: $name
                  id: $id
                )
            }
          }
        `}
        prepareVariables={(_params, { location }) => ({
          customerId: location.query.customerId,
          subscriptionId: location.query.subscriptionId,
          domainId: location.query.domainId,
          specialType: location.query.specialType,
          stripeSubscriptionId: location.query.stripeSubscriptionId,
          slug: location.query.slug,
          name: location.query.name,
          id: location.query.id,
        })}
      />
    </Route>
    <Route
      path=":organizationId"
      Component={OrganizationDetailPage}
      query={graphql`
        query organizations_OrganizationDetailPage_Query(
          $organizationId: String!
        ) {
          organization(organizationId: $organizationId) {
            ...OrganizationDetailPage_organization
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.organization) throw new HttpError(404);
      }}
    />
  </Route>
);
