import React from 'react';
import { graphql, useFragment } from 'react-relay';

import DeleteAction from 'components/DeleteAction';
import { deleteMessages } from 'messages/deleteAction';

import { DeleteEducationCourseAction_educationContent$key } from './__generated__/DeleteEducationCourseAction_educationContent.graphql';

const fragment = graphql`
  fragment DeleteEducationCourseAction_educationContent on EducationContent {
    name
    educationContentId
    deletedAt
  }
`;

const mutation = graphql`
  mutation DeleteEducationCourseActionMutation(
    $input: DeleteEducationContentInput!
  ) {
    deleteEducationContent(input: $input) {
      educationContent {
        educationContentId
        deletedAt
      }
    }
  }
`;

interface DeleteEducationCourseActionProps {
  educationCourseRef: DeleteEducationCourseAction_educationContent$key;
}

export default function DeleteEducationCourseAction({
  educationCourseRef,
}: DeleteEducationCourseActionProps) {
  const educationCourse = useFragment(fragment, educationCourseRef);
  return (
    <DeleteAction
      mutation={mutation}
      itemName={educationCourse.name}
      input={{ educationContentId: educationCourse.educationContentId }}
      actionFailedMsg={deleteMessages.actionFailed}
      actionSuccessfulMsg={deleteMessages.actionSuccessful}
      confirmationBodyMsg={deleteMessages.confirmationBody}
      confirmationTitleMsg={deleteMessages.confirmationTitle}
      actionMessageMsg={deleteMessages.actionMessage}
    />
  );
}
