import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import { PlaceOfWorkSource } from './PlaceOfWorkModal';
import { UpdatePlaceOfWorkModal } from './UpdatePlaceOfWorkModal';
import { PlaceOfWorkDetailPage_placeOfWork$key } from './__generated__/PlaceOfWorkDetailPage_placeOfWork.graphql';

const fragment = graphql`
  fragment PlaceOfWorkDetailPage_placeOfWork on PlaceOfWork {
    ...UpdatePlaceOfWorkModal_placeOfWork
    id
    placeOfWorkId
    name
    countries
    status
    source
  }
`;

interface PlaceOfWorkDetailPageProps {
  placeOfWork: PlaceOfWorkDetailPage_placeOfWork$key;
}

export default function PlaceOfWorkDetailPage({
  placeOfWork: placeOfWorkRef,
}: PlaceOfWorkDetailPageProps) {
  const placeOfWork = useFragment(fragment, placeOfWorkRef);
  const { source, name } = placeOfWork;
  useBreadcrumbDetector(pageTitles.placesOfWork, name);
  const { canWrite } = usePermissionsContext();
  return (
    <PermissionsGuard resource={Resource.PLACES_OF_WORK}>
      <ResourceDetailPage
        data={placeOfWork}
        quickCopyFields={['placeOfWorkId', 'name']}
        title={<FormattedMessage {...pageTitles.placesOfWork} />}
        name={placeOfWork.name!}
        crudActions={
          source === PlaceOfWorkSource.INTERNAL &&
          canWrite(Resource.PLACES_OF_WORK) && (
            <UpdateAction
              renderModal={(props) => (
                <UpdatePlaceOfWorkModal
                  {...props}
                  placeOfWorkRef={placeOfWork}
                />
              )}
            />
          )
        }
      />
    </PermissionsGuard>
  );
}
