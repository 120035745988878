/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type users_UsersGrid_QueryVariables = {
    id?: Array<string> | null | undefined;
    domainId?: Array<string> | null | undefined;
    email?: Array<string> | null | undefined;
    emailDomain?: Array<string> | null | undefined;
    phoneNumber?: Array<string> | null | undefined;
    roleId?: Array<string> | null | undefined;
    name?: Array<string> | null | undefined;
    isUserDeleted?: Array<string> | null | undefined;
};
export type users_UsersGrid_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"FlatUsersGrid_viewer">;
    } | null;
};
export type users_UsersGrid_Query = {
    readonly response: users_UsersGrid_QueryResponse;
    readonly variables: users_UsersGrid_QueryVariables;
};



/*
query users_UsersGrid_Query(
  $id: [String!]
  $domainId: [String!]
  $email: [String!]
  $emailDomain: [String!]
  $phoneNumber: [String!]
  $name: [String!]
  $isUserDeleted: [String!]
) {
  viewer {
    ...FlatUsersGrid_viewer_35GEGF
    id
  }
}

fragment FlatUsersGrid_viewer_35GEGF on Viewer {
  flatUsers(first: 20, id: $id, domainId: $domainId, email: $email, emailDomain: $emailDomain, phoneNumber: $phoneNumber, name: $name, isUserDeleted: $isUserDeleted) {
    edges {
      node {
        id
        flatUserId
        userId
        auth0Id
        name
        email
        hasUnacceptedInvite: hasPendingOrganizationInvite
        specialtyKey
        specialtyFreeform
        phoneNumber
        placeOfWork {
          placeOfWorkId
          name
          id
        }
        placeOfWorkFreeform
        setupAt
        userCreatedAt
        userDeletedAt
        lastReviewRequestedAt
        lastCloudReviewRequestTriggeredAt
        lastImmediateReviewRequestTriggeredAt
        isImplicitPublic
        domainId
        type
        domainUserCreatedAt
        domainUserDeletedAt
        integrationConfigs {
          domainUserIntegrationConfigId
          id
        }
        hasTableauDashboardAccess
        isNurse
        canFinalize
        canQa
        role {
          name
          id
        }
        domain {
          subdomainLabel
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domainId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "emailDomain"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isUserDeleted"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneNumber"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roleId"
},
v8 = {
  "kind": "Variable",
  "name": "domainId",
  "variableName": "domainId"
},
v9 = {
  "kind": "Variable",
  "name": "email",
  "variableName": "email"
},
v10 = {
  "kind": "Variable",
  "name": "emailDomain",
  "variableName": "emailDomain"
},
v11 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v12 = {
  "kind": "Variable",
  "name": "isUserDeleted",
  "variableName": "isUserDeleted"
},
v13 = {
  "kind": "Variable",
  "name": "name",
  "variableName": "name"
},
v14 = {
  "kind": "Variable",
  "name": "phoneNumber",
  "variableName": "phoneNumber"
},
v15 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "users_UsersGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "kind": "Variable",
                "name": "roleId",
                "variableName": "roleId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "FlatUsersGrid_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "users_UsersGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v15/*: any*/),
            "concreteType": "FlatUserConnection",
            "kind": "LinkedField",
            "name": "flatUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FlatUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlatUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "flatUserId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "auth0Id",
                        "storageKey": null
                      },
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": "hasUnacceptedInvite",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPendingOrganizationInvite",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specialtyKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "specialtyFreeform",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phoneNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlaceOfWork",
                        "kind": "LinkedField",
                        "name": "placeOfWork",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "placeOfWorkId",
                            "storageKey": null
                          },
                          (v17/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "placeOfWorkFreeform",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "setupAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userCreatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userDeletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastReviewRequestedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastCloudReviewRequestTriggeredAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastImmediateReviewRequestTriggeredAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isImplicitPublic",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainUserCreatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domainUserDeletedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DomainUserIntegrationConfig",
                        "kind": "LinkedField",
                        "name": "integrationConfigs",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "domainUserIntegrationConfigId",
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasTableauDashboardAccess",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isNurse",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canFinalize",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canQa",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MembershipRole",
                        "kind": "LinkedField",
                        "name": "role",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Domain",
                        "kind": "LinkedField",
                        "name": "domain",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subdomainLabel",
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v15/*: any*/),
            "filters": [
              "id",
              "domainId",
              "email",
              "emailDomain",
              "phoneNumber",
              "name",
              "isUserDeleted"
            ],
            "handle": "connection",
            "key": "FlatUsersGrid_flatUsers",
            "kind": "LinkedHandle",
            "name": "flatUsers"
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff24b60ef43e6f0df5c737859821626f",
    "id": null,
    "metadata": {},
    "name": "users_UsersGrid_Query",
    "operationKind": "query",
    "text": "query users_UsersGrid_Query(\n  $id: [String!]\n  $domainId: [String!]\n  $email: [String!]\n  $emailDomain: [String!]\n  $phoneNumber: [String!]\n  $name: [String!]\n  $isUserDeleted: [String!]\n) {\n  viewer {\n    ...FlatUsersGrid_viewer_35GEGF\n    id\n  }\n}\n\nfragment FlatUsersGrid_viewer_35GEGF on Viewer {\n  flatUsers(first: 20, id: $id, domainId: $domainId, email: $email, emailDomain: $emailDomain, phoneNumber: $phoneNumber, name: $name, isUserDeleted: $isUserDeleted) {\n    edges {\n      node {\n        id\n        flatUserId\n        userId\n        auth0Id\n        name\n        email\n        hasUnacceptedInvite: hasPendingOrganizationInvite\n        specialtyKey\n        specialtyFreeform\n        phoneNumber\n        placeOfWork {\n          placeOfWorkId\n          name\n          id\n        }\n        placeOfWorkFreeform\n        setupAt\n        userCreatedAt\n        userDeletedAt\n        lastReviewRequestedAt\n        lastCloudReviewRequestTriggeredAt\n        lastImmediateReviewRequestTriggeredAt\n        isImplicitPublic\n        domainId\n        type\n        domainUserCreatedAt\n        domainUserDeletedAt\n        integrationConfigs {\n          domainUserIntegrationConfigId\n          id\n        }\n        hasTableauDashboardAccess\n        isNurse\n        canFinalize\n        canQa\n        role {\n          name\n          id\n        }\n        domain {\n          subdomainLabel\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e0078c81264702903fefd87e323a89cd';
export default node;
