import localStore from 'store/dist/store.modern';

const PREFIX = 'kldsp';

function getKey(prefix: string): string {
  return `${PREFIX}:${prefix}`;
}

export function getStorageItem(
  key: string,
  options?: { prefix: string },
): any {
  if (!key) {
    return null;
  }
  const { prefix = '' } = { ...options };
  const keyName = getKey(prefix || key);
  return localStore.get(keyName);
}

export function setStorageItem(
  key: string,
  value: any,
  options?: { prefix: string },
): void {
  if (key) {
    const { prefix = '' } = { ...options };
    const keyName = getKey(prefix || key);
    localStore.set(keyName, value);
  }
}

export function removeStorageItem(
  key: string,
  options?: { prefix: string },
): void {
  if (key) {
    const { prefix = '' } = { ...options };
    const keyName = getKey(prefix || key);
    localStore.remove(keyName);
  }
}
