import { defineMessages } from 'react-intl';

export default defineMessages({
  createdAt: {
    id: 'table.heading.createdAt',
    defaultMessage: 'Created At',
  },
  customerId: {
    id: 'table.heading.customerId',
    defaultMessage: 'Customer ID',
  },
  customerName: {
    id: 'table.heading.customerName',
    defaultMessage: 'Customer Name',
  },
  deletedAt: {
    id: 'table.heading.deletedAt',
    defaultMessage: 'Deleted At',
  },
  description: {
    id: 'table.heading.description',
    defaultMessage: 'Description',
  },
  domainId: {
    id: 'table.heading.domainId',
    defaultMessage: 'Domain ID',
  },
  domainName: {
    id: 'table.heading.domainName',
    defaultMessage: 'Domain Name',
  },
  subdomainLabel: {
    id: 'table.heading.subdomainLabel',
    defaultMessage: 'Subdomain Label',
  },
  ehrId: {
    id: 'table.heading.ehrId',
    defaultMessage: 'EHR ID',
  },
  email: {
    id: 'table.heading.email',
    defaultMessage: 'Email',
  },
  emailDomain: {
    id: 'table.heading.emailDomain',
    defaultMessage: 'Email Domain',
  },
  invitesDeleted: {
    id: 'table.heading.invitesDeleted',
    defaultMessage: 'Invites Deleted',
  },
  userDeleted: {
    id: 'table.heading.userDeleted',
    defaultMessage: 'Users Deleted',
  },
  integrationId: {
    id: 'table.heading.integrationId',
    defaultMessage: 'Integration ID',
  },
  name: {
    id: 'table.heading.name',
    defaultMessage: 'Name',
  },
  organizationId: {
    id: 'table.heading.organizationId',
    defaultMessage: 'Organization ID',
  },
  organizationSpecialType: {
    id: 'table.heading.organizationSpecialType',
    defaultMessage: 'Special Org Type',
  },
  organizationName: {
    id: 'table.heading.organizationName',
    defaultMessage: 'Organization Name',
  },
  phoneNumber: {
    id: 'table.heading.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  status: {
    id: 'table.heading.status',
    defaultMessage: 'Status',
  },
  subscriptionId: {
    id: 'table.heading.subscriptionId',
    defaultMessage: 'Subscription ID',
  },
  connectionId: {
    id: 'table.heading.connectionId',
    defaultMessage: 'Connection ID',
  },
  type: {
    id: 'table.heading.type',
    defaultMessage: 'Type',
  },
  domainAdmin: {
    id: 'table.heading.domainAdmin',
    defaultMessage: 'Domain Admin',
  },
  userId: {
    id: 'table.heading.userId',
    defaultMessage: 'User ID',
  },
  membershipActive: {
    id: 'table.heading.membershipActive',
    defaultMessage: 'Membership Deleted',
  },
  userName: {
    id: 'table.heading.userName',
    defaultMessage: 'User Name',
  },
  applicationIdentifier: {
    id: 'table.heading.applicationIdentifier',
    defaultMessage: 'Application Identifier',
  },
  versionRange: {
    id: 'table.heading.versionRange',
    defaultMessage: 'Version Range',
  },
  subscriptionEndsAt: {
    id: 'subscriptionsTable.heading.subscriptionEndsAt',
    defaultMessage: 'Subscription Ends At',
  },
  accessEndsAt: {
    id: 'subscriptionsTable.heading.accessEndsAt',
    defaultMessage: 'Access Ends At',
  },
  billingStatus: {
    id: 'subscriptionsTable.heading.billingStatus',
    defaultMessage: 'Billing Status',
  },
  billingProvider: {
    id: 'subscriptionsTable.heading.billingProvider',
    defaultMessage: 'Billing Provider',
  },
  stripeSubscriptionId: {
    id: 'subscriptionsTable.heading.stripeSubscriptionId',
    defaultMessage: 'Stripe Subscription ID',
  },
  sfSubscriptionId: {
    id: 'subscriptionsTable.heading.sfSubscriptionId',
    defaultMessage: 'SF Subscription ID',
  },
  planType: {
    id: 'subscriptionsTable.heading.planType',
    defaultMessage: 'Plan Type',
  },
  practiceType: {
    id: 'subscriptionsTable.heading.practiceType',
    defaultMessage: 'Practice Type',
  },
  hasStartedActiveSubscription: {
    id: 'organizationTable.heading.hasStartedActiveSubscription',
    defaultMessage: 'Has Started Active Subscription',
  },
  hasCompletedTrial: {
    id: 'organizationTable.heading.hasCompletedTrial',
    defaultMessage: 'Has Completed Trial',
  },
  canAccessProFeatures: {
    id: 'organizationTable.heading.canAccessProFeatures',
    defaultMessage: 'Can Access Pro Features',
  },
  isInGracePeriod: {
    id: 'organizationTable.heading.isInGracePeriod',
    defaultMessage: 'Is In Grace Period',
  },
  isTeam: {
    id: 'organizationTable.heading.isTeam',
    defaultMessage: 'Is Team',
  },
  maxNumSeats: {
    id: 'organizationTable.heading.maxNumSeats',
    defaultMessage: 'Max Num Seats',
  },
  isNurse: {
    id: 'table.heading.isNurse',
    defaultMessage: 'Is Nurse',
  },
  canFinalize: {
    id: 'table.heading.canFinalize',
    defaultMessage: 'Can Finalize',
  },
  canQA: {
    id: 'table.heading.canQA',
    defaultMessage: 'Can QA',
  },
  accessRole: {
    id: 'table.heading.accessRole',
    defaultMessage: 'Butterfly Access Role',
  },
  accessRoleName: {
    id: 'accessRolesTable.heading.accessRoleName',
    defaultMessage: 'Butterfly Access Role Name',
  },
  accessRoleId: {
    id: 'accessRolesTable.heading.accessRoleId',
    defaultMessage: 'Butterfly Access Role ID',
  },
  usedAt: {
    id: 'table.heading.usedAt',
    defaultMessage: 'Used At',
  },
  newEmail: {
    id: 'table.heading.email',
    defaultMessage: 'Email',
  },
  oldEmail: {
    id: 'table.heading.oldEmail',
    defaultMessage: 'Old Email',
  },
});
