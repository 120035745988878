/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_QueryVariables = {
    domainUserIntegrationConfigId: string;
};
export type domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_QueryResponse = {
    readonly domainUserIntegrationConfig: {
        readonly " $fragmentRefs": FragmentRefs<"DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig">;
    } | null;
};
export type domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_Query = {
    readonly response: domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_QueryResponse;
    readonly variables: domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_QueryVariables;
};



/*
query domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_Query(
  $domainUserIntegrationConfigId: String!
) {
  domainUserIntegrationConfig(domainUserIntegrationConfigId: $domainUserIntegrationConfigId) {
    ...DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig
    id
  }
}

fragment DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig on DomainUserIntegrationConfig {
  id
  domainUserIntegrationConfigId
  domainUser {
    name
    id
  }
}

fragment DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig on DomainUserIntegrationConfig {
  ...UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig
  ...DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig
  id
  domainUserIntegrationConfigId
  interfaceCode
  ehr {
    ehrId
    id
  }
  domainUser {
    domainUserId
    domain {
      domainId
      id
    }
    user {
      userId
      id
    }
    name
    email
    id
  }
}

fragment UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig on DomainUserIntegrationConfig {
  id
  domainUserIntegrationConfigId
  interfaceCode
  ehr {
    ehrId
    id
  }
  domainUser {
    name
    domain {
      domainId
      id
    }
    user {
      userId
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainUserIntegrationConfigId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "domainUserIntegrationConfigId",
    "variableName": "domainUserIntegrationConfigId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DomainUserIntegrationConfig",
        "kind": "LinkedField",
        "name": "domainUserIntegrationConfig",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DomainUserIntegrationConfig",
        "kind": "LinkedField",
        "name": "domainUserIntegrationConfig",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domainUserIntegrationConfigId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "interfaceCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Ehr",
            "kind": "LinkedField",
            "name": "ehr",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ehrId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DomainUser",
            "kind": "LinkedField",
            "name": "domainUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domainId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "domainUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e137f25dca8245ed362a02bec9fea58",
    "id": null,
    "metadata": {},
    "name": "domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_Query",
    "operationKind": "query",
    "text": "query domainUserIntegrationConfigs_DomainUserIntegrationConfigDetailPage_Query(\n  $domainUserIntegrationConfigId: String!\n) {\n  domainUserIntegrationConfig(domainUserIntegrationConfigId: $domainUserIntegrationConfigId) {\n    ...DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig\n    id\n  }\n}\n\nfragment DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig on DomainUserIntegrationConfig {\n  id\n  domainUserIntegrationConfigId\n  domainUser {\n    name\n    id\n  }\n}\n\nfragment DomainUserIntegrationConfigDetailPage_domainUserIntegrationConfig on DomainUserIntegrationConfig {\n  ...UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig\n  ...DeleteDomainUserIntegrationConfigAction_domainUserIntegrationConfig\n  id\n  domainUserIntegrationConfigId\n  interfaceCode\n  ehr {\n    ehrId\n    id\n  }\n  domainUser {\n    domainUserId\n    domain {\n      domainId\n      id\n    }\n    user {\n      userId\n      id\n    }\n    name\n    email\n    id\n  }\n}\n\nfragment UpdateDomainUserIntegrationConfigModal_domainUserIntegrationConfig on DomainUserIntegrationConfig {\n  id\n  domainUserIntegrationConfigId\n  interfaceCode\n  ehr {\n    ehrId\n    id\n  }\n  domainUser {\n    name\n    domain {\n      domainId\n      id\n    }\n    user {\n      userId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '3135abbfec1769ecdcbc53a8d57e635b';
export default node;
