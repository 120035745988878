import omit from 'lodash/omit';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import ResourceDetailPage from 'components/ResourceDetailPage';
import { RelatedItem } from 'components/ResourceDetailRelatedItems';
import { UpdateAction } from 'components/UpdateAction';
import pageTitles from 'messages/pageTitles';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';
import { useBreadcrumbDetector } from 'utils/useBreadcrumbDetector';
import { usePermissionsContext } from 'utils/usePermissionsContext';

import CourseThumbnailForm from './CourseThumbnailForm';
import DeleteEducationCourseAction from './DeleteEducationCourseAction';
import DeleteEducationVideoAction from './DeleteEducationVideoAction';
import UndeleteEducationContentAction from './UndeleteEducationContentAction';
import { UpdateEducationCourseModal } from './UpdateEducationCourseModal';
import { UpdateEducationVideoModal } from './UpdateEducationVideoModal';
import { EducationContentDetailPage_educationContent$key } from './__generated__/EducationContentDetailPage_educationContent.graphql';

const fragment = graphql`
  fragment EducationContentDetailPage_educationContent on EducationContent {
    ...UpdateEducationVideoModal_educationContent
    ...DeleteEducationVideoAction_educationContent
    ...UpdateEducationCourseModal_educationContent
    ...DeleteEducationCourseAction_educationContent
    ...UndeleteEducationContentAction_educationContent
    id
    educationContentId
    name
    description
    type
    contentType
    practiceType
    isPremium
    vimeoId
    absorbId
    duration
    ranking
    status
    presetLocalIds
    toolLocalIds
    captureModes
    deletedAt
    authoredBy {
      userId
      name
    }
    category {
      id
      name
      educationCategoryId
    }
    thumbnail(width: 229) {
      url
    }
    courseLanguage
  }
`;

const fieldsOrderComparator = (a: string, b: string) => {
  if (a.startsWith('thumbnail') && !b.startsWith('thumbnail')) {
    return -1;
  }
  if (!a.startsWith('thumbnail') && b.startsWith('thumbnail')) {
    return 1;
  }
  return 0;
};

interface EducationContentDetailPageProps {
  educationContent: EducationContentDetailPage_educationContent$key;
}

export default function EducationContentDetailPage({
  educationContent: educationContentRef,
}: EducationContentDetailPageProps) {
  const educationContent = useFragment(fragment, educationContentRef);
  useBreadcrumbDetector(pageTitles.educationContent, educationContent.name);
  const { canWrite } = usePermissionsContext();

  const renderMap = useMemo(() => {
    const renderProps = {
      label: () => 'thumbnail*',
      value: () => (
        <CourseThumbnailForm
          imageSrc={educationContent.thumbnail?.url || undefined}
          educationContentId={educationContent.educationContentId || ''}
        />
      ),
    };
    return {
      'thumbnail: url': renderProps,
      'thumbnail': renderProps,
    };
  }, [educationContent]);

  return (
    <PermissionsGuard resource={Resource.EDUCATION_CONTENT}>
      <ResourceDetailPage
        title={<FormattedMessage {...pageTitles.educationContent} />}
        name={educationContent.name!}
        data={omit(
          educationContent,
          educationContent.contentType === 'VIDEO'
            ? ['thumbnail', 'courseLanguage']
            : [],
        )}
        orderComparator={fieldsOrderComparator}
        renderMap={renderMap}
        quickCopyFields={[
          'educationContentId',
          'name',
          'vimeoId',
          'absorbId',
          'authoredBy: userId',
          'authoredBy: name',
          'category: name',
          'category: educationCategoryId',
        ]}
        relatedItems={
          educationContent?.category?.educationCategoryId && (
            <RelatedItem
              name="Education Category"
              to={`/education-categories/${educationContent.category.educationCategoryId}`}
            >
              <FormattedMessage
                id="educationContentDetailPage.relatedItem.educationCategory"
                defaultMessage="Education Category"
              />
            </RelatedItem>
          )
        }
        crudActions={
          canWrite(Resource.EDUCATION_CONTENT) && (
            <>
              {educationContent.contentType === 'VIDEO' ? (
                <>
                  <UpdateAction
                    renderModal={(props) => (
                      <UpdateEducationVideoModal
                        {...props}
                        educationVideoRef={educationContent}
                      />
                    )}
                  />
                  <DeleteEducationVideoAction
                    educationVideoRef={educationContent}
                  />
                </>
              ) : (
                <>
                  <UpdateAction
                    renderModal={(props) => (
                      <UpdateEducationCourseModal
                        {...props}
                        educationCourseRef={educationContent}
                      />
                    )}
                  />
                  {educationContent.status !== 'INACTIVE' && (
                    <>
                      {educationContent.deletedAt ? (
                        <UndeleteEducationContentAction
                          educationContentRef={educationContent}
                        />
                      ) : (
                        <DeleteEducationCourseAction
                          educationCourseRef={educationContent}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )
        }
      />
    </PermissionsGuard>
  );
}
