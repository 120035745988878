/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import FlatUsersGridRefetchQuery from "./FlatUsersGridRefetchQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type FlatUsersGrid_viewer = {
    readonly flatUsers: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly flatUserId: string | null;
                readonly userId: string | null;
                readonly auth0Id: string | null;
                readonly name: string | null;
                readonly email: string | null;
                readonly hasUnacceptedInvite: boolean | null;
                readonly specialtyKey: string | null;
                readonly specialtyFreeform: string | null;
                readonly phoneNumber: string | null;
                readonly placeOfWork: {
                    readonly placeOfWorkId: string | null;
                    readonly name: string | null;
                } | null;
                readonly placeOfWorkFreeform: string | null;
                readonly setupAt: string | null;
                readonly userCreatedAt: string | null;
                readonly userDeletedAt: string | null;
                readonly lastReviewRequestedAt: string | null;
                readonly lastCloudReviewRequestTriggeredAt: string | null;
                readonly lastImmediateReviewRequestTriggeredAt: string | null;
                readonly isImplicitPublic: boolean | null;
                readonly domainId: string | null;
                readonly type: DomainUserType | null;
                readonly domainUserCreatedAt: string | null;
                readonly domainUserDeletedAt: string | null;
                readonly integrationConfigs: ReadonlyArray<{
                    readonly domainUserIntegrationConfigId: string | null;
                } | null> | null;
                readonly hasTableauDashboardAccess: boolean | null;
                readonly isNurse: boolean | null;
                readonly canFinalize: boolean | null;
                readonly canQa: boolean | null;
                readonly role: {
                    readonly name: string | null;
                } | null;
                readonly domain: {
                    readonly subdomainLabel: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "FlatUsersGrid_viewer";
};
export type FlatUsersGrid_viewer$data = FlatUsersGrid_viewer;
export type FlatUsersGrid_viewer$key = {
    readonly " $data"?: FlatUsersGrid_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FlatUsersGrid_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "flatUsers"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "domainId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "email"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "emailDomain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isUserDeleted"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "name"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "phoneNumber"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "roleId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": FlatUsersGridRefetchQuery
    }
  },
  "name": "FlatUsersGrid_viewer",
  "selections": [
    {
      "alias": "flatUsers",
      "args": [
        {
          "kind": "Variable",
          "name": "domainId",
          "variableName": "domainId"
        },
        {
          "kind": "Variable",
          "name": "email",
          "variableName": "email"
        },
        {
          "kind": "Variable",
          "name": "emailDomain",
          "variableName": "emailDomain"
        },
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        },
        {
          "kind": "Variable",
          "name": "isUserDeleted",
          "variableName": "isUserDeleted"
        },
        {
          "kind": "Variable",
          "name": "name",
          "variableName": "name"
        },
        {
          "kind": "Variable",
          "name": "phoneNumber",
          "variableName": "phoneNumber"
        }
      ],
      "concreteType": "FlatUserConnection",
      "kind": "LinkedField",
      "name": "__FlatUsersGrid_flatUsers_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FlatUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FlatUser",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "flatUserId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "auth0Id",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": "hasUnacceptedInvite",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPendingOrganizationInvite",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specialtyKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specialtyFreeform",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "phoneNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PlaceOfWork",
                  "kind": "LinkedField",
                  "name": "placeOfWork",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "placeOfWorkId",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "placeOfWorkFreeform",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "setupAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userCreatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userDeletedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastReviewRequestedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastCloudReviewRequestTriggeredAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastImmediateReviewRequestTriggeredAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isImplicitPublic",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "domainId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "domainUserCreatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "domainUserDeletedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DomainUserIntegrationConfig",
                  "kind": "LinkedField",
                  "name": "integrationConfigs",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "domainUserIntegrationConfigId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasTableauDashboardAccess",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isNurse",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canFinalize",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canQa",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MembershipRole",
                  "kind": "LinkedField",
                  "name": "role",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Domain",
                  "kind": "LinkedField",
                  "name": "domain",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subdomainLabel",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = '5cf98833f9bfd7d5407a8003db8d8be4';
export default node;
