import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import {
  ButterflyDeviceRecallModal,
  butterflyDeviceRecallSchema,
} from './ButterflyDeviceRecallModal';
import { UpdateButterflyDeviceRecallModalMutation } from './__generated__/UpdateButterflyDeviceRecallModalMutation.graphql';
import { UpdateButterflyDeviceRecallModal_butterflyDeviceRecall$key } from './__generated__/UpdateButterflyDeviceRecallModal_butterflyDeviceRecall.graphql';

const fragment = graphql`
  fragment UpdateButterflyDeviceRecallModal_butterflyDeviceRecall on ButterflyDeviceRecall {
    id
    recallId
    message
  }
`;

const updateMutation = graphql`
  mutation UpdateButterflyDeviceRecallModalMutation(
    $input: UpdateButterflyDeviceRecallInput!
  ) {
    updateButterflyDeviceRecall(input: $input) {
      butterflyDeviceRecall {
        ...UpdateButterflyDeviceRecallModal_butterflyDeviceRecall
      }
    }
  }
`;

interface UpdateButterflyDeviceRecallModalProps extends ActionModalProps {
  butterflyDeviceRecallRef: UpdateButterflyDeviceRecallModal_butterflyDeviceRecall$key;
}

export function UpdateButterflyDeviceRecallModal({
  butterflyDeviceRecallRef,
  ...props
}: UpdateButterflyDeviceRecallModalProps) {
  const butterflyDeviceRecall = useFragment(
    fragment,
    butterflyDeviceRecallRef,
  );

  return (
    <ButterflyDeviceRecallModal<UpdateButterflyDeviceRecallModalMutation>
      {...props}
      schema={butterflyDeviceRecallSchema}
      mutation={updateMutation}
      defaultValue={butterflyDeviceRecall}
      title={
        <FormattedMessage
          id="updateButterflyDeviceRecallModal.title"
          defaultMessage="Update Butterfly Device Recall"
        />
      }
      name={butterflyDeviceRecall.recallId!}
      submitText={<FormattedMessage {...actionMessages.update} />}
    />
  );
}
