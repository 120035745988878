import { defineMessages } from 'react-intl';

export const minimumVersionMessages = defineMessages({
  label: {
    id: 'applicationVersionRequirementModal.minimumVersion.label',
    defaultMessage: 'Minimum Version*',
  },
  placeholder: {
    id: 'applicationVersionRequirementModal.minimumVersion.placeholder',
    defaultMessage: 'Minimum Version',
  },
  required: {
    id: 'applicationVersionRequirementModal.minimumVersion.required',
    defaultMessage: 'Please enter a Minimum Version',
  },
  description: {
    id: 'applicationVersionRequirementModal.minimumVersion.description',
    defaultMessage: 'The minimum semantic version',
  },
});
