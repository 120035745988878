import Button from '@bfly/ui2/Button';
import { useToast } from '@bfly/ui2/ToastContext';
import useDialog from '@bfly/ui2/useDialog';
import useRouter from 'found/useRouter';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GraphQLTaggedNode, useMutation } from 'react-relay';
import { MutationParameters, SelectorStoreUpdater } from 'relay-runtime';

import { Resource } from 'components/ResourceDetail';
import actionMessages from 'messages/actions';
import { deactivateMessages } from 'messages/deleteAction';

interface DeleteActionProps<TMutation extends MutationParameters> {
  itemName: string | React.ReactNode;
  disabled?: boolean;
  redirectTo?: string;
  mutation: GraphQLTaggedNode;
  input: Resource;
  actionFailedMsg?: Record<string, unknown>;
  actionSuccessfulMsg?: Record<string, unknown>;
  confirmationBodyMsg?: Record<string, unknown>;
  confirmationTitleMsg?: Record<string, unknown>;
  actionMessageMsg?: Record<string, unknown>;
  getCustomErrorMessage?: (
    err: Error,
    defaultMsg: Record<string, unknown>,
  ) => Record<string, unknown>;
  onDelete?: () => void;
  updater?: SelectorStoreUpdater<TMutation['response']> | null | undefined;
}

export default function DeleteAction<TMutation extends MutationParameters>({
  mutation,
  redirectTo,
  itemName: name,
  input,
  disabled = false,
  actionFailedMsg = deactivateMessages.actionFailed,
  actionSuccessfulMsg = deactivateMessages.actionSuccessful,
  confirmationBodyMsg = deactivateMessages.confirmationBody,
  confirmationTitleMsg = deactivateMessages.confirmationTitle,
  actionMessageMsg = actionMessages.deactivate,
  getCustomErrorMessage = (_, defaultMsg) => defaultMsg,
  onDelete,
  updater,
}: DeleteActionProps<TMutation>) {
  const { router } = useRouter();
  const dialog = useDialog();
  const toast = useToast();
  const [mutate] = useMutation(mutation);

  const handleDelete = async () => {
    await mutate({
      variables: {
        input,
      },
      updater,
      onError: (err) => {
        toast!.error(
          <FormattedMessage
            {...getCustomErrorMessage(err, actionFailedMsg)}
            values={{ name }}
          />,
        );
      },
      onCompleted: () => {
        toast!.success(
          <FormattedMessage {...actionSuccessfulMsg} values={{ name }} />,
        );
        if (onDelete) onDelete();
        if (redirectTo) router.replace(redirectTo);
      },
    });
  };

  const handleDeleteClick = () => {
    return dialog.open(
      <FormattedMessage {...confirmationBodyMsg} values={{ name }} />,
      {
        runConfirm: () => handleDelete(),
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        title: (
          <FormattedMessage {...confirmationTitleMsg} values={{ name }} />
        ),
        confirmLabel: <FormattedMessage {...actionMessageMsg} />,
      },
    );
  };

  return (
    <Button
      variant="danger"
      data-cy="delete"
      onClick={handleDeleteClick}
      disabled={disabled}
    >
      <FormattedMessage {...actionMessageMsg} />
    </Button>
  );
}
