/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry = {
    readonly iosVersionBlacklistEntryId: string | null;
    readonly " $refType": "DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry";
};
export type DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry$data = DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry;
export type DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry$key = {
    readonly " $data"?: DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteIosVersionBlacklistEntryAction_iosVersionBlacklistEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iosVersionBlacklistEntryId",
      "storageKey": null
    }
  ],
  "type": "IosVersionBlacklistEntry",
  "abstractKey": null
};
(node as any).hash = '90c6cbdac28ed645236488c1fa4af1f8';
export default node;
