import Button from '@bfly/ui2/Button';
import { useToast } from '@bfly/ui2/ToastContext';
import useDialog from '@bfly/ui2/useDialog';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment, useMutation } from 'react-relay';

import actionMessages from 'messages/actions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { DisableCaptionHealthAction_subscription$key as SubscriptionKey } from './__generated__/DisableCaptionHealthAction_subscription.graphql';

interface DisableCaptionHealthActionProps {
  subscriptionRef: SubscriptionKey;
}

const fragment = graphql`
  fragment DisableCaptionHealthAction_subscription on Subscription {
    id
    subscriptionId
  }
`;

export default function DisableCaptionHealthAction({
  subscriptionRef,
}: DisableCaptionHealthActionProps) {
  const dialog = useDialog();
  const toast = useToast();
  const subscription = useFragment(fragment, subscriptionRef);

  const [mutate] = useMutation(graphql`
    mutation DisableCaptionHealthActionMutation(
      $input: DisableCaptionHealthInput!
    ) {
      disableCaptionHealth(input: $input) {
        subscription {
          featureModuleIds
        }
      }
    }
  `);

  const handleMutate = async () => {
    await mutate({
      variables: {
        input: { subscriptionId: subscription.subscriptionId },
      },
      onError: () => {
        toast!.error(
          <FormattedMessage
            id="disableCaptionHealthAction.error"
            defaultMessage="Something went wrong"
          />,
        );
      },
      onCompleted: () => {
        toast!.success(
          <FormattedMessage
            id="disableCaptionHealthAction.success"
            defaultMessage="Caption Health disabled."
          />,
        );
      },
    });
  };

  const handleClick = () => {
    return dialog.open(
      <FormattedMessage
        id="disableCaptionHealthAction.dialog.content"
        defaultMessage="Are you sure you want to Disable caption health features? This will have no effect if already disabled."
      />,
      {
        runConfirm: () => handleMutate(),
        title: (
          <FormattedMessage
            id="disableCaptionHealthAction.dialog.title"
            defaultMessage="Disable Caption Health?"
          />
        ),
        confirmLabel: <FormattedMessage {...actionMessages.confirm} />,
      },
    );
  };

  return (
    <PermissionsGuard resource={Resource.SUBSCRIPTIONS} write hideContent>
      <Button
        variant="secondary"
        data-cy="disable-caption-health"
        onClick={handleClick}
      >
        <FormattedMessage
          id="disableCaptionHealthAction.action"
          defaultMessage="Disable Caption Health"
        />
      </Button>
    </PermissionsGuard>
  );
}
