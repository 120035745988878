import useQuery from '@bfly/ui2/useQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { ActionModalProps } from 'components/ResourceModal';
import actionMessages from 'messages/actions';

import InviteModal, { getInviteSchema } from './InviteModal';
import { UpdateInviteModalOrganizationQuery } from './__generated__/UpdateInviteModalOrganizationQuery.graphql';
import { UpdateInviteModal_organizationInvite$key } from './__generated__/UpdateInviteModal_organizationInvite.graphql';

const fragment = graphql`
  fragment UpdateInviteModal_organizationInvite on OrganizationInvite {
    id
    organizationInviteId
    email
    roleId
    makeOrganizationAdmin
    organization {
      organizationId
    }
  }
`;

const updateMutation = graphql`
  mutation UpdateInviteModalMutation($input: UpdateOrganizationInviteInput!) {
    updateOrganizationInvite(input: $input) {
      organizationInvite {
        ...UpdateInviteModal_organizationInvite @relay(mask: false)
      }
    }
  }
`;

interface UpdateInviteModalProps extends ActionModalProps {
  inviteRef: UpdateInviteModal_organizationInvite$key;
}

export default function UpdateInviteModal({
  inviteRef,
  ...props
}: UpdateInviteModalProps) {
  const { organization, ...fields } = useFragment(fragment, inviteRef);
  const { organizationId } = organization!;
  const { data } = useQuery<UpdateInviteModalOrganizationQuery>(
    graphql`
      query UpdateInviteModalOrganizationQuery($organizationId: String!) {
        organization(organizationId: $organizationId) {
          customUserPermissionsEnabled
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      skip: !organizationId,
      variables: {
        organizationId: organizationId!,
      },
    },
  );

  const customUserPermissionsEnabled =
    data?.organization?.customUserPermissionsEnabled;
  return (
    <InviteModal
      {...props}
      defaultValue={{
        ...fields,
        organizationId,
        customUserPermissionsEnabled,
      }}
      title={
        <FormattedMessage
          id="updateInviteModal.title"
          defaultMessage="Update Invite"
        />
      }
      submitText={<FormattedMessage {...actionMessages.update} />}
      name={fields.email!}
      schema={getInviteSchema()}
      mutation={updateMutation}
      transformOnSubmit={({
        organizationInviteId,
        makeOrganizationAdmin,
      }) => ({
        organizationInviteId,
        makeOrganizationAdmin,
      })}
    />
  );
}
