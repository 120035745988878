/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateCustomerInput = {
    customerId: string;
    name?: string | null | undefined;
    zendeskId?: string | null | undefined;
    netsuiteId?: string | null | undefined;
    stripeId?: string | null | undefined;
    sfAccountId?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateCustomerModal_UpdateMutationVariables = {
    input: UpdateCustomerInput;
};
export type UpdateCustomerModal_UpdateMutationResponse = {
    readonly updateCustomer: {
        readonly customer: {
            readonly " $fragmentRefs": FragmentRefs<"CustomersPage_customer">;
        } | null;
    } | null;
};
export type UpdateCustomerModal_UpdateMutation = {
    readonly response: UpdateCustomerModal_UpdateMutationResponse;
    readonly variables: UpdateCustomerModal_UpdateMutationVariables;
};



/*
mutation UpdateCustomerModal_UpdateMutation(
  $input: UpdateCustomerInput!
) {
  updateCustomer(input: $input) {
    customer {
      ...CustomersPage_customer
      id
    }
  }
}

fragment CustomersPage_customer on Customer {
  id
  name
  customerId
  zendeskId
  netsuiteId
  stripeId
  sfAccountId
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateCustomerModal_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerPayload",
        "kind": "LinkedField",
        "name": "updateCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomersPage_customer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateCustomerModal_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCustomerPayload",
        "kind": "LinkedField",
        "name": "updateCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zendeskId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "netsuiteId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stripeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sfAccountId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00185bf51c0848de9d40c8d8ccda080e",
    "id": null,
    "metadata": {},
    "name": "UpdateCustomerModal_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateCustomerModal_UpdateMutation(\n  $input: UpdateCustomerInput!\n) {\n  updateCustomer(input: $input) {\n    customer {\n      ...CustomersPage_customer\n      id\n    }\n  }\n}\n\nfragment CustomersPage_customer on Customer {\n  id\n  name\n  customerId\n  zendeskId\n  netsuiteId\n  stripeId\n  sfAccountId\n}\n"
  }
};
})();
(node as any).hash = 'd64e62112ab854b56c8117047de0d802';
export default node;
