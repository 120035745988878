/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SystemDefinedRolesPage_systemDefinedRole = {
    readonly id: string;
    readonly name: string | null;
    readonly systemDefinedRoleId: string | null;
    readonly " $refType": "SystemDefinedRolesPage_systemDefinedRole";
};
export type SystemDefinedRolesPage_systemDefinedRole$data = SystemDefinedRolesPage_systemDefinedRole;
export type SystemDefinedRolesPage_systemDefinedRole$key = {
    readonly " $data"?: SystemDefinedRolesPage_systemDefinedRole$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SystemDefinedRolesPage_systemDefinedRole">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SystemDefinedRolesPage_systemDefinedRole",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "systemDefinedRoleId",
      "storageKey": null
    }
  ],
  "type": "SystemDefinedRole",
  "abstractKey": null
};
(node as any).hash = '3ff5edceaa47142b90bfe37a6a0c377c';
export default node;
