import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { object, string } from 'yup';

import DangerousActionConfirmation from 'components/DangerousActionConfirmation';
import DangerousActionWarning from 'components/DangerousActionWarning';
import DropdownList from 'components/DropdownList';
import { ActionModalProps, ResourceModal } from 'components/ResourceModal';
import {
  dlDataModeOverrideMessages,
  organizationIdMessages,
} from 'messages/organizationActions';
import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { DLDataModeOverrideAction_organization$key } from './__generated__/DLDataModeOverrideAction_organization.graphql';

const dlDataModeOverrideList = [
  { name: dlDataModeOverrideMessages.noOverride, value: null },
  { name: dlDataModeOverrideMessages.include, value: 'INCLUDE' },
  { name: dlDataModeOverrideMessages.partial, value: 'PARTIAL' },
  { name: dlDataModeOverrideMessages.exclude, value: 'EXCLUDE' },
];

export const dlDataModeOverrideFieldSchema = string()
  .nullable()
  .oneOf(dlDataModeOverrideList.map(({ value }) => value));

const dlDataModeOverrideSchema = object({
  organizationId: string().required().meta({
    readOnly: true,
  }),
  dlDataModeOverride: dlDataModeOverrideFieldSchema.default(
    dlDataModeOverrideMessages.noOverride.defaultMessage,
  ),
});

const fragment = graphql`
  fragment DLDataModeOverrideAction_organization on Organization {
    organizationId
    dlDataModeOverride
  }
`;

const dlDataModeOverrideMutation = graphql`
  mutation DLDataModeOverrideAction_SetDlDataModeOverrideMutation(
    $input: SetOrganizationDlDataModeOverrideInput!
  ) {
    setOrganizationDlDataModeOverride(input: $input) {
      organization {
        dlDataModeOverride
      }
    }
  }
`;

interface DLDataModeOverrideModalProps extends ActionModalProps {
  organizationRef: DLDataModeOverrideAction_organization$key;
}

function DLDataModeOverrideModal({
  organizationRef,
  ...props
}: DLDataModeOverrideModalProps) {
  const { organizationId, dlDataModeOverride } = useFragment(
    fragment,
    organizationRef,
  );
  const [allowDangerousAction, setAllowDangerousAction] = useState(false);
  const { formatMessage } = useIntl();
  return (
    <ResourceModal
      disabled={!allowDangerousAction}
      {...props}
      title={
        <FormattedMessage
          id="setDLDataModeOverride.title"
          defaultMessage="Set DL Data Mode Override"
        />
      }
      submitText={
        <FormattedMessage
          id="setDLDataModeOverride.submitText"
          defaultMessage="Set DL Data Mode Override"
        />
      }
      schema={dlDataModeOverrideSchema}
      mutation={dlDataModeOverrideMutation}
      defaultValue={{ organizationId, dlDataModeOverride }}
    >
      <DangerousActionWarning />
      <Form.FieldGroup
        disabled
        name="organizationId"
        label={<FormattedMessage {...organizationIdMessages.label} />}
        placeholder={formatMessage(organizationIdMessages.placeholder)}
        data-cy="DataModeModal-organizationId"
      />
      <Form.FieldGroup
        name="dlDataModeOverride"
        as={DropdownList}
        data={dlDataModeOverrideList}
        label={<FormattedMessage {...dlDataModeOverrideMessages.label} />}
        placeholder={dlDataModeOverrideMessages.placeholder}
        textField="name"
        dataKey="value"
        mapFromValue={({ value }) => value}
        data-cy="DataModeModal-dlDataModeOverride"
      />
      {/* make some room for the dropdown */}
      <div css="min-height: 4rem;" />
      <DangerousActionConfirmation
        confirmed={allowDangerousAction}
        setConfirmed={setAllowDangerousAction}
      />
    </ResourceModal>
  );
}

interface DLDataModeOverrideActionProps {
  organizationRef: DLDataModeOverrideAction_organization$key;
}

export default function DLDataModeOverrideAction({
  organizationRef,
}: DLDataModeOverrideActionProps) {
  const [showDataMode, setShowDataMode] = useState(false);
  return (
    <PermissionsGuard
      resource={Resource.ORGANIZATION_MANAGEMENT_ADMIN_READ_WRITE}
      write
      hideContent
    >
      <Button variant="secondary" onClick={() => setShowDataMode(true)}>
        <FormattedMessage
          id="organizationDetail.actions.setDLDataModeOverride"
          defaultMessage="Set DL Data Mode Override"
        />
      </Button>
      <DLDataModeOverrideModal
        organizationRef={organizationRef}
        show={showDataMode}
        onClose={() => setShowDataMode(false)}
      />
    </PermissionsGuard>
  );
}
