import MutationButton from '@bfly/ui2/MutationButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import PermissionsGuard from 'utils/PermissionsGuard';
import { Resource } from 'utils/permissions';

import { MigrateEnterpriseWorkflowActionMutation } from './__generated__/MigrateEnterpriseWorkflowActionMutation.graphql';
import { MigrateEnterpriseWorkflowAction_domain$key } from './__generated__/MigrateEnterpriseWorkflowAction_domain.graphql';

const fragment = graphql`
  fragment MigrateEnterpriseWorkflowAction_domain on Domain {
    id
    domainId
  }
`;

const mutation = graphql`
  mutation MigrateEnterpriseWorkflowActionMutation(
    $input: MigrateDomainToEnterpriseWorkflowInput!
  ) {
    migrateDomainToEnterpriseWorkflow(input: $input) {
      __typename
      domain {
        id
        ...DomainDetailPage_domain
      }
    }
  }
`;

interface MigrateEnterpriseWorkflowActionProps {
  domainRef: MigrateEnterpriseWorkflowAction_domain$key;
}

export default function MigrateEnterpriseWorkflowAction({
  domainRef,
}: MigrateEnterpriseWorkflowActionProps) {
  const domain = useFragment(fragment, domainRef);
  return (
    <PermissionsGuard
      write
      hideContent
      resource={Resource.ENTERPRISE_UPGRADES}
    >
      <MutationButton<MigrateEnterpriseWorkflowActionMutation>
        mutation={mutation}
        input={{ domainId: domain.domainId! }}
        variant="secondary"
        successToastMessage={
          <FormattedMessage
            id="migrateEnterpriseWorkflowAction.success"
            defaultMessage="Enterprise Workflow (Worksheet & QA templates) has been successfully migrated"
          />
        }
        errorToastMessage={
          <FormattedMessage
            id="migrateEnterpriseWorkflowAction.error"
            defaultMessage="Failed to migrate Enterprise Workflow"
          />
        }
      >
        <FormattedMessage
          id="migrateWorkflowAction.requestReview"
          defaultMessage="Migrate Workflow Templates"
        />
      </MutationButton>
    </PermissionsGuard>
  );
}
