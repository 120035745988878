/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DomainUserType = "ADMIN" | "USER" | "%future added value";
export type DeleteFlatUserInput = {
    flatUserId: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteFlatUserActionMutationVariables = {
    input: DeleteFlatUserInput;
};
export type DeleteFlatUserActionMutationResponse = {
    readonly deleteFlatUser: {
        readonly flatUser: {
            readonly id: string;
            readonly flatUserId: string | null;
            readonly userId: string | null;
            readonly auth0Id: string | null;
            readonly name: string | null;
            readonly email: string | null;
            readonly hasUnacceptedInvite: boolean | null;
            readonly specialtyKey: string | null;
            readonly specialtyFreeform: string | null;
            readonly phoneNumber: string | null;
            readonly placeOfWork: {
                readonly placeOfWorkId: string | null;
                readonly name: string | null;
            } | null;
            readonly placeOfWorkFreeform: string | null;
            readonly setupAt: string | null;
            readonly userCreatedAt: string | null;
            readonly userDeletedAt: string | null;
            readonly lastReviewRequestedAt: string | null;
            readonly lastCloudReviewRequestTriggeredAt: string | null;
            readonly lastImmediateReviewRequestTriggeredAt: string | null;
            readonly isImplicitPublic: boolean | null;
            readonly domainId: string | null;
            readonly type: DomainUserType | null;
            readonly domainUserCreatedAt: string | null;
            readonly domainUserDeletedAt: string | null;
            readonly integrationConfigs: ReadonlyArray<{
                readonly domainUserIntegrationConfigId: string | null;
            } | null> | null;
            readonly hasTableauDashboardAccess: boolean | null;
            readonly isNurse: boolean | null;
            readonly canFinalize: boolean | null;
            readonly canQa: boolean | null;
            readonly role: {
                readonly name: string | null;
            } | null;
            readonly domain: {
                readonly subdomainLabel: string | null;
            } | null;
        } | null;
    } | null;
};
export type DeleteFlatUserActionMutation = {
    readonly response: DeleteFlatUserActionMutationResponse;
    readonly variables: DeleteFlatUserActionMutationVariables;
};



/*
mutation DeleteFlatUserActionMutation(
  $input: DeleteFlatUserInput!
) {
  deleteFlatUser(input: $input) {
    flatUser {
      id
      flatUserId
      userId
      auth0Id
      name
      email
      hasUnacceptedInvite: hasPendingOrganizationInvite
      specialtyKey
      specialtyFreeform
      phoneNumber
      placeOfWork {
        placeOfWorkId
        name
        id
      }
      placeOfWorkFreeform
      setupAt
      userCreatedAt
      userDeletedAt
      lastReviewRequestedAt
      lastCloudReviewRequestTriggeredAt
      lastImmediateReviewRequestTriggeredAt
      isImplicitPublic
      domainId
      type
      domainUserCreatedAt
      domainUserDeletedAt
      integrationConfigs {
        domainUserIntegrationConfigId
        id
      }
      hasTableauDashboardAccess
      isNurse
      canFinalize
      canQa
      role {
        name
        id
      }
      domain {
        subdomainLabel
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatUserId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auth0Id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": "hasUnacceptedInvite",
  "args": null,
  "kind": "ScalarField",
  "name": "hasPendingOrganizationInvite",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialtyKey",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialtyFreeform",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeOfWorkId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeOfWorkFreeform",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "setupAt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userCreatedAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userDeletedAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastReviewRequestedAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastCloudReviewRequestTriggeredAt",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastImmediateReviewRequestTriggeredAt",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isImplicitPublic",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainId",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainUserCreatedAt",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainUserDeletedAt",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainUserIntegrationConfigId",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTableauDashboardAccess",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNurse",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canFinalize",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canQa",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subdomainLabel",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteFlatUserActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFlatUserPayload",
        "kind": "LinkedField",
        "name": "deleteFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUser",
            "kind": "LinkedField",
            "name": "flatUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceOfWork",
                "kind": "LinkedField",
                "name": "placeOfWork",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserIntegrationConfig",
                "kind": "LinkedField",
                "name": "integrationConfigs",
                "plural": true,
                "selections": [
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRole",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v30/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteFlatUserActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFlatUserPayload",
        "kind": "LinkedField",
        "name": "deleteFlatUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlatUser",
            "kind": "LinkedField",
            "name": "flatUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceOfWork",
                "kind": "LinkedField",
                "name": "placeOfWork",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DomainUserIntegrationConfig",
                "kind": "LinkedField",
                "name": "integrationConfigs",
                "plural": true,
                "selections": [
                  (v25/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRole",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v30/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb0a91d95eea69f4e37f884c2f00735f",
    "id": null,
    "metadata": {},
    "name": "DeleteFlatUserActionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteFlatUserActionMutation(\n  $input: DeleteFlatUserInput!\n) {\n  deleteFlatUser(input: $input) {\n    flatUser {\n      id\n      flatUserId\n      userId\n      auth0Id\n      name\n      email\n      hasUnacceptedInvite: hasPendingOrganizationInvite\n      specialtyKey\n      specialtyFreeform\n      phoneNumber\n      placeOfWork {\n        placeOfWorkId\n        name\n        id\n      }\n      placeOfWorkFreeform\n      setupAt\n      userCreatedAt\n      userDeletedAt\n      lastReviewRequestedAt\n      lastCloudReviewRequestTriggeredAt\n      lastImmediateReviewRequestTriggeredAt\n      isImplicitPublic\n      domainId\n      type\n      domainUserCreatedAt\n      domainUserDeletedAt\n      integrationConfigs {\n        domainUserIntegrationConfigId\n        id\n      }\n      hasTableauDashboardAccess\n      isNurse\n      canFinalize\n      canQa\n      role {\n        name\n        id\n      }\n      domain {\n        subdomainLabel\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fa8b7fd66916afc451202fee89b69911';
export default node;
