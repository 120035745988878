/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateButterflyDeviceInput = {
    productionId: string;
    clientMutationId?: string | null | undefined;
};
export type CreateButterflyDeviceModalMutationVariables = {
    input: CreateButterflyDeviceInput;
};
export type CreateButterflyDeviceModalMutationResponse = {
    readonly createButterflyDevice: {
        readonly butterflyDeviceEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ButterflyDevicesGrid_butterflyDevice">;
            } | null;
        } | null;
    } | null;
};
export type CreateButterflyDeviceModalMutation = {
    readonly response: CreateButterflyDeviceModalMutationResponse;
    readonly variables: CreateButterflyDeviceModalMutationVariables;
};



/*
mutation CreateButterflyDeviceModalMutation(
  $input: CreateButterflyDeviceInput!
) {
  createButterflyDevice(input: $input) {
    butterflyDeviceEdge {
      node {
        id
        ...ButterflyDevicesGrid_butterflyDevice
      }
    }
  }
}

fragment ButterflyDevicesGrid_butterflyDevice on ButterflyDevice {
  id
  productionId
  registeredAt
  isEnabled
  disabledStatusMessage
  lostAt
  stolenAt
  recallId
  subscription {
    subscriptionId
    id
  }
  hasActiveDiagnosticTestRequest
  lastDiagnosticTestRequestMinTestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateButterflyDeviceModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateButterflyDevicePayload",
        "kind": "LinkedField",
        "name": "createButterflyDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDeviceEdge",
            "kind": "LinkedField",
            "name": "butterflyDeviceEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ButterflyDevice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ButterflyDevicesGrid_butterflyDevice"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateButterflyDeviceModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateButterflyDevicePayload",
        "kind": "LinkedField",
        "name": "createButterflyDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ButterflyDeviceEdge",
            "kind": "LinkedField",
            "name": "butterflyDeviceEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ButterflyDevice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productionId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disabledStatusMessage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lostAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stolenAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "recallId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subscriptionId",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasActiveDiagnosticTestRequest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastDiagnosticTestRequestMinTestVersion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50f89585b3f486ae2b7b532a1c98c924",
    "id": null,
    "metadata": {},
    "name": "CreateButterflyDeviceModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateButterflyDeviceModalMutation(\n  $input: CreateButterflyDeviceInput!\n) {\n  createButterflyDevice(input: $input) {\n    butterflyDeviceEdge {\n      node {\n        id\n        ...ButterflyDevicesGrid_butterflyDevice\n      }\n    }\n  }\n}\n\nfragment ButterflyDevicesGrid_butterflyDevice on ButterflyDevice {\n  id\n  productionId\n  registeredAt\n  isEnabled\n  disabledStatusMessage\n  lostAt\n  stolenAt\n  recallId\n  subscription {\n    subscriptionId\n    id\n  }\n  hasActiveDiagnosticTestRequest\n  lastDiagnosticTestRequestMinTestVersion\n}\n"
  }
};
})();
(node as any).hash = '5522812f47c107fd40b601aa23f1ba25';
export default node;
