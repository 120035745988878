import { defineMessages } from 'react-intl';

export default defineMessages({
  applicationIdentifier: {
    id: 'applicationVersionRequirementsTable.heading.applicationIdentifier',
    defaultMessage: 'Application Identifier',
  },
  minimumVersion: {
    id: 'applicationVersionRequirementsTable.heading.minimumVersion',
    defaultMessage: 'Minimum Version',
  },
});
