import Form from '@bfly/ui2/Form';
import { decodeHandle, isUuid } from '@bfly/utils/codecs';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { object, string } from 'yup';

import { ActionModalProps, ResourceModal } from 'components/ResourceModal';

import {
  destinationArchiveIdMessages,
  studyIdMessages,
} from '../messages/copyStudy';

const copyStudySchema = object({
  studyId: string().required(studyIdMessages.required),
  destinationArchiveId: string().required(
    destinationArchiveIdMessages.required,
  ),
});

const defaultValue = copyStudySchema.getDefault();

const copyStudyMutation = graphql`
  mutation CopyStudyModal_CopyStudyMutation($input: CopyStudyInput!) {
    copyStudy(input: $input) {
      study {
        studyId
      }
    }
  }
`;

interface CopyStudyRecord {
  studyId: string;
  destinationArchiveId: string;
}

export default function CopyStudyModal(props: ActionModalProps) {
  const decodeRecord = useCallback(
    (input: CopyStudyRecord) =>
      Object.entries(input).reduce(
        (memo, [key, value]) => ({
          ...memo,
          [key]: (value && !isUuid(value) && decodeHandle(value)) || value,
        }),
        {},
      ),
    [],
  );
  const { formatMessage } = useIntl();

  return (
    <ResourceModal
      {...props}
      title={
        <FormattedMessage id="studyModal.title" defaultMessage="Copy Study" />
      }
      submitText={
        <FormattedMessage
          id="studyModal.makeCopy"
          defaultMessage="Make Copy"
        />
      }
      schema={copyStudySchema}
      mutation={copyStudyMutation}
      defaultValue={defaultValue}
      transformOnSubmit={decodeRecord}
    >
      <Form.FieldGroup
        name="studyId"
        placeholder={formatMessage(studyIdMessages.placeholder)}
        label={<FormattedMessage {...studyIdMessages.label} />}
        description={<FormattedMessage {...studyIdMessages.description} />}
        data-cy="studyModal-studyId"
      />
      <Form.FieldGroup
        name="destinationArchiveId"
        placeholder={formatMessage(destinationArchiveIdMessages.placeholder)}
        label={<FormattedMessage {...destinationArchiveIdMessages.label} />}
        description={
          <FormattedMessage {...destinationArchiveIdMessages.description} />
        }
        data-cy="studyModal-destinationArchiveId"
      />
    </ResourceModal>
  );
}
