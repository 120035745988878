/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BillingProvider = "APPLE" | "SALESFORCE" | "STRIPE" | "%future added value";
export type BillingStatus = "ACTIVE" | "CANCELED" | "PAST_DUE" | "TRIALING" | "UNPAID" | "%future added value";
export type DlDataMode = "EXCLUDE" | "INCLUDE" | "PARTIAL" | "%future added value";
export type PlanType = "BASIC" | "CLINIC" | "DEPARTMENT" | "EDUCATION" | "EMS" | "ENTERPRISE_SECURITY" | "ENTERPRISE_WORKFLOW" | "GOVERNMENT" | "HOSPITAL" | "INDIVIDUAL" | "INDIVIDUAL_FOREVER" | "MEDICAL_SCHOOL" | "NONE" | "PRO_CUSTOM" | "PRO_THREE_YEAR" | "PRO_TRIAL" | "RESIDENCY_PROGRAM" | "TEAM" | "%future added value";
export type PracticeType = "HUMAN" | "NONE" | "VETERINARY" | "%future added value";
export type SpecialType = "DEMO" | "INTERNAL" | "NONE" | "TEST" | "%future added value";
export type AddOrganizationToDomainInput = {
    organizationId: string;
    subscriptionId: string;
    clientMutationId?: string | null | undefined;
};
export type JoinDomainActionMutationVariables = {
    input: AddOrganizationToDomainInput;
};
export type JoinDomainActionMutationResponse = {
    readonly addOrganizationToDomain: {
        readonly organization: {
            readonly id: string;
            readonly organizationId: string | null;
            readonly name: string | null;
            readonly slug: string | null;
            readonly country: string | null;
            readonly deletedAt: string | null;
            readonly subscription: {
                readonly subscriptionId: string | null;
                readonly subscriptionEndsAt: string | null;
                readonly accessEndsAt: string | null;
                readonly hasStartedActiveSubscription: boolean | null;
                readonly hasCompletedTrial: boolean | null;
                readonly canAccessProFeatures: boolean | null;
                readonly isInGracePeriod: boolean | null;
                readonly isTeam: boolean | null;
                readonly billingStatus: BillingStatus | null;
                readonly billingProvider: BillingProvider | null;
                readonly stripeSubscriptionId: string | null;
                readonly sfSubscriptionId: string | null;
                readonly planType: PlanType | null;
                readonly maxNumSeats: number | null;
                readonly practiceType: PracticeType | null;
            } | null;
            readonly isActive: boolean | null;
            readonly customer: {
                readonly customerId: string | null;
            } | null;
            readonly domain: {
                readonly domainId: string | null;
            } | null;
            readonly dlDataMode: DlDataMode | null;
            readonly dlDataModeOverride: DlDataMode | null;
            readonly enterpriseCapabilityManagementEnabled: boolean | null;
            readonly enabledPresetIds: ReadonlyArray<string | null> | null;
            readonly enabledToolIds: ReadonlyArray<string | null> | null;
            readonly enabledCaptureModes: ReadonlyArray<string | null> | null;
            readonly enabledCapabilities: ReadonlyArray<string | null> | null;
            readonly specialType: SpecialType | null;
        } | null;
    } | null;
};
export type JoinDomainActionMutation = {
    readonly response: JoinDomainActionMutationResponse;
    readonly variables: JoinDomainActionMutationVariables;
};



/*
mutation JoinDomainActionMutation(
  $input: AddOrganizationToDomainInput!
) {
  addOrganizationToDomain(input: $input) {
    organization {
      id
      organizationId
      name
      slug
      country
      deletedAt
      subscription {
        subscriptionId
        subscriptionEndsAt
        accessEndsAt
        hasStartedActiveSubscription
        hasCompletedTrial
        canAccessProFeatures
        isInGracePeriod
        isTeam
        billingStatus
        billingProvider
        stripeSubscriptionId
        sfSubscriptionId
        planType
        maxNumSeats
        practiceType
        id
      }
      isActive
      customer {
        customerId
        id
      }
      domain {
        domainId
        id
      }
      dlDataMode
      dlDataModeOverride
      enterpriseCapabilityManagementEnabled
      enabledPresetIds
      enabledToolIds
      enabledCaptureModes
      enabledCapabilities
      specialType
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionEndsAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessEndsAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasStartedActiveSubscription",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCompletedTrial",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canAccessProFeatures",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInGracePeriod",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTeam",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingStatus",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingProvider",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeSubscriptionId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sfSubscriptionId",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planType",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxNumSeats",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "practiceType",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerId",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domainId",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dlDataMode",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dlDataModeOverride",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enterpriseCapabilityManagementEnabled",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabledPresetIds",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabledToolIds",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabledCaptureModes",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabledCapabilities",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JoinDomainActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOrganizationToDomainPayload",
        "kind": "LinkedField",
        "name": "addOrganizationToDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              },
              (v23/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v24/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v25/*: any*/)
                ],
                "storageKey": null
              },
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JoinDomainActionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOrganizationToDomainPayload",
        "kind": "LinkedField",
        "name": "addOrganizationToDomain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v23/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Domain",
                "kind": "LinkedField",
                "name": "domain",
                "plural": false,
                "selections": [
                  (v25/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e7667f4b02dc41f2f17f77027ce0e5bd",
    "id": null,
    "metadata": {},
    "name": "JoinDomainActionMutation",
    "operationKind": "mutation",
    "text": "mutation JoinDomainActionMutation(\n  $input: AddOrganizationToDomainInput!\n) {\n  addOrganizationToDomain(input: $input) {\n    organization {\n      id\n      organizationId\n      name\n      slug\n      country\n      deletedAt\n      subscription {\n        subscriptionId\n        subscriptionEndsAt\n        accessEndsAt\n        hasStartedActiveSubscription\n        hasCompletedTrial\n        canAccessProFeatures\n        isInGracePeriod\n        isTeam\n        billingStatus\n        billingProvider\n        stripeSubscriptionId\n        sfSubscriptionId\n        planType\n        maxNumSeats\n        practiceType\n        id\n      }\n      isActive\n      customer {\n        customerId\n        id\n      }\n      domain {\n        domainId\n        id\n      }\n      dlDataMode\n      dlDataModeOverride\n      enterpriseCapabilityManagementEnabled\n      enabledPresetIds\n      enabledToolIds\n      enabledCaptureModes\n      enabledCapabilities\n      specialType\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b9ea5f1a5ec6831d3773f76704eac3df';
export default node;
