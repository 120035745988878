import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { number, object, string } from 'yup';

import DropdownList from 'components/DropdownList';
import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import {
  courseLanguageMessages,
  practiceTypeMessages,
} from 'messages/formMessages';

import {
  absorbIdMessages,
  courseCategoryIdMessages,
  courseDescriptionMessages,
  courseNameMessages,
  courseStatusMessages,
  durationMessages,
  rankingMessages,
} from '../messages/formMessages';

const PRACTICE_TYPE_HUMAN = 'HUMAN';
const PRACTICE_TYPE = ['NONE', PRACTICE_TYPE_HUMAN, 'VETERINARY'];
const LANGUAGE = [
  'NONE',
  'EN',
  'DE',
  'FR',
  'IT',
  'ES',
  'DA',
  'NL',
  'FI',
  'NO',
  'PL',
  'PT',
  'SV',
];

export const educationCourseSchema = object({
  educationContentId: string(),
  name: string().nullable(),
  description: string().nullable(),
  absorbId: string().nullable(),
  status: string().nullable(),
  authoredBy: string(),
  categoryId: string(),
  courseLanguage: string().nullable().oneOf(LANGUAGE).default(LANGUAGE[0]),
  practiceType: string()
    .required(practiceTypeMessages.required)
    .oneOf(PRACTICE_TYPE)
    .default(PRACTICE_TYPE_HUMAN),
  duration: number().integer().nullable().required().min(1),
  ranking: number().nullable().required().min(1),
});

export function EducationCourseModal<TMutation extends MutationParameters>(
  props: ResourceModalProps<TMutation>,
) {
  const { formatMessage } = useIntl();
  return (
    <ResourceModal<TMutation> {...props}>
      <Form.FieldGroup
        disabled
        name="name"
        label={<FormattedMessage {...courseNameMessages.label} />}
        placeholder={formatMessage(courseNameMessages.placeholder)}
        data-cy="educationContentModal-courseNameField"
      />
      <Form.FieldGroup
        disabled
        name="description"
        label={<FormattedMessage {...courseDescriptionMessages.label} />}
        placeholder={formatMessage(courseDescriptionMessages.placeholder)}
        data-cy="educationContentModal-courseDescriptionField"
      />
      <Form.FieldGroup
        disabled
        name="absorbId"
        label={<FormattedMessage {...absorbIdMessages.label} />}
        placeholder={formatMessage(absorbIdMessages.placeholder)}
        description={<FormattedMessage {...absorbIdMessages.description} />}
        data-cy="educationContentModal-absorbIdField"
      />
      <Form.FieldGroup
        disabled
        name="status"
        label={<FormattedMessage {...courseStatusMessages.label} />}
        placeholder={formatMessage(courseStatusMessages.placeholder)}
        data-cy="educationContentModal-courseStatusField"
      />
      <Form.FieldGroup
        name="courseLanguage"
        as={DropdownList}
        data={LANGUAGE}
        placeholder={courseLanguageMessages.placeholder}
        label={<FormattedMessage {...courseLanguageMessages.label} />}
        data-cy="educationContentModal-courseLanguageField"
      />
      <Form.FieldGroup
        name="practiceType"
        as={DropdownList}
        data={PRACTICE_TYPE}
        placeholder={practiceTypeMessages.placeholder}
        label={<FormattedMessage {...practiceTypeMessages.label} />}
        data-cy="educationContentModal-coursePracticeTypeField"
      />
      <Form.FieldGroup
        name="categoryId"
        label={<FormattedMessage {...courseCategoryIdMessages.label} />}
        placeholder={formatMessage(courseCategoryIdMessages.placeholder)}
        data-cy="educationContentModal-courseCategoryIdField"
      />
      <Form.FieldGroup
        name="duration"
        label={<FormattedMessage {...durationMessages.label} />}
        data-cy="educationContentModal-courseDurationField"
      />
      <Form.FieldGroup
        name="ranking"
        label={<FormattedMessage {...rankingMessages.label} />}
        data-cy="educationContentModal-courseRankingField"
      />
    </ResourceModal>
  );
}
