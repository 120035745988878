import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Text from '@bfly/ui2/Text';
import React from 'react';

interface DataGridDateTimeProps {
  value: string | undefined | null;
}

export default function DataGridDateTime({ value }: DataGridDateTimeProps) {
  return (
    <Text>{value ? <FormattedDateTime value={new Date(value)} /> : '-'}</Text>
  );
}
