import { defineMessages } from 'react-intl';

export const versionCodeMessages = defineMessages({
  label: {
    id: 'androidVersionBlacklistEntryModal.versionCode.label',
    defaultMessage: 'Version Code',
  },
  placeholder: {
    id: 'androidVersionBlacklistEntryModal.versionCode.placeholder',
    defaultMessage: 'Version Code',
  },
  description: {
    id: 'androidVersionBlacklistEntryModal.versionCode.description',
    defaultMessage: 'The Android version code',
  },
});
