/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EnableCaptionHealthAction_subscription = {
    readonly id: string;
    readonly subscriptionId: string | null;
    readonly " $refType": "EnableCaptionHealthAction_subscription";
};
export type EnableCaptionHealthAction_subscription$data = EnableCaptionHealthAction_subscription;
export type EnableCaptionHealthAction_subscription$key = {
    readonly " $data"?: EnableCaptionHealthAction_subscription$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EnableCaptionHealthAction_subscription">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EnableCaptionHealthAction_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionId",
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};
(node as any).hash = 'f9ca1446abd6547c6b7522a7fb452ee3';
export default node;
