/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteEducationVideoAction_educationContent = {
    readonly name: string | null;
    readonly educationContentId: string | null;
    readonly " $refType": "DeleteEducationVideoAction_educationContent";
};
export type DeleteEducationVideoAction_educationContent$data = DeleteEducationVideoAction_educationContent;
export type DeleteEducationVideoAction_educationContent$key = {
    readonly " $data"?: DeleteEducationVideoAction_educationContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DeleteEducationVideoAction_educationContent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteEducationVideoAction_educationContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "educationContentId",
      "storageKey": null
    }
  ],
  "type": "EducationContent",
  "abstractKey": null
};
(node as any).hash = '021942249b5661ac1e0be3e6e6a2e7be';
export default node;
