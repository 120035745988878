import BlankSlate from '@bfly/ui2/BlankSlate';
import Link from '@bfly/ui2/Link';
import Page from '@bfly/ui2/Page';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import AppHeader from 'components/AppHeader';
import BreadcrumbsProvider from 'utils/BreadcrumbsProvider';
import PermissionsProvider from 'utils/PermissionsProvider';

/*

In order to facilitate the use case for a user navigating across regions, we would like to persist the 
region selector dropdown even in the case of a 404. As such, we cannot have the 404 page be a full screen
and instead need it to be a page with the app header. Permissions are not required for UX purposes on this page


*/
export default function NotFoundPage() {
  return (
    <PermissionsProvider viewer={null}>
      <BreadcrumbsProvider>
        <Page>
          <AppHeader />
          <Page.Container>
            <Page.Main
              className="fixed flex flex-col bottom-0 w-full top-navbar"
              data-bni-id="NotFoundPage"
            >
              <BlankSlate>
                <BlankSlate.Title>
                  <FormattedMessage id="404.title" defaultMessage="404" />
                </BlankSlate.Title>
                <BlankSlate.Body>
                  <FormattedMessage
                    id="404.body"
                    defaultMessage="The page you are looking for cannot be found."
                  />
                </BlankSlate.Body>
                <Link as={BlankSlate.Action} to="/" size="lg">
                  <FormattedMessage
                    id="404.returnHome"
                    defaultMessage="Return Home"
                  />
                </Link>
              </BlankSlate>
            </Page.Main>
          </Page.Container>
        </Page>
      </BreadcrumbsProvider>
    </PermissionsProvider>
  );
}
