import Form from '@bfly/ui2/Form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MutationParameters } from 'relay-runtime';
import { number, object, string } from 'yup';

import { ResourceModal, ResourceModalProps } from 'components/ResourceModal';
import { formMessages } from 'messages/formMessages';

import {
  educationCategoryIdMessages,
  educationCategoryNameMessages,
  educationCategoryOrderingMessages,
} from '../messages/educationCategoryModal';

export const basicEducationCategorySchema = object({
  educationCategoryId: string().meta({ readOnly: true }),
  name: string()
    .required(educationCategoryNameMessages.required)
    .min(2, formMessages.minLength),
  ordering: number().required(educationCategoryOrderingMessages.required),
});

export function EducationCategoryModal<TMutation extends MutationParameters>(
  props: ResourceModalProps<TMutation>,
) {
  const { formatMessage } = useIntl();

  return (
    <ResourceModal<TMutation> {...props}>
      <Form.FieldGroup
        disabled
        name="educationCategoryId"
        placeholder={formatMessage(educationCategoryIdMessages.placeholder)}
        label={<FormattedMessage {...educationCategoryIdMessages.label} />}
        data-cy="educationCategoryModal-educationCategoryIdField"
      />
      <Form.FieldGroup
        name="name"
        placeholder={formatMessage(educationCategoryNameMessages.placeholder)}
        label={<FormattedMessage {...educationCategoryNameMessages.label} />}
        data-cy="educationCategoryModal-educationCategoryNameField"
      />
      <Form.FieldGroup
        name="ordering"
        placeholder={formatMessage(
          educationCategoryOrderingMessages.placeholder,
        )}
        label={
          <FormattedMessage {...educationCategoryOrderingMessages.label} />
        }
        data-cy="educationCategoryModal-educationCategoryOrderingField"
      />
    </ResourceModal>
  );
}
