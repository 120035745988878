/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateAndroidVersionBlacklistEntryInput = {
    applicationIdentifier: string;
    versionRange: string;
    versionCode?: number | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateAndroidVersionBlacklistEntryModalMutationVariables = {
    input: CreateAndroidVersionBlacklistEntryInput;
};
export type CreateAndroidVersionBlacklistEntryModalMutationResponse = {
    readonly createAndroidVersionBlacklistEntry: {
        readonly androidVersionBlacklistEntryEdge: {
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry">;
            } | null;
        } | null;
    } | null;
};
export type CreateAndroidVersionBlacklistEntryModalMutation = {
    readonly response: CreateAndroidVersionBlacklistEntryModalMutationResponse;
    readonly variables: CreateAndroidVersionBlacklistEntryModalMutationVariables;
};



/*
mutation CreateAndroidVersionBlacklistEntryModalMutation(
  $input: CreateAndroidVersionBlacklistEntryInput!
) {
  createAndroidVersionBlacklistEntry(input: $input) {
    androidVersionBlacklistEntryEdge {
      node {
        id
        ...AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry
      }
    }
  }
}

fragment AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
  ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry
  ...DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry
  id
  androidVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  versionCode
}

fragment DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
  androidVersionBlacklistEntryId
}

fragment UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {
  id
  androidVersionBlacklistEntryId
  applicationIdentifier
  versionRange
  versionCode
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAndroidVersionBlacklistEntryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAndroidVersionBlacklistEntryPayload",
        "kind": "LinkedField",
        "name": "createAndroidVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AndroidVersionBlacklistEntryEdge",
            "kind": "LinkedField",
            "name": "androidVersionBlacklistEntryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AndroidVersionBlacklistEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAndroidVersionBlacklistEntryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAndroidVersionBlacklistEntryPayload",
        "kind": "LinkedField",
        "name": "createAndroidVersionBlacklistEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AndroidVersionBlacklistEntryEdge",
            "kind": "LinkedField",
            "name": "androidVersionBlacklistEntryEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AndroidVersionBlacklistEntry",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "androidVersionBlacklistEntryId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "applicationIdentifier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "versionRange",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "versionCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "401a53d3fc81457d7a0c5c5b6f1109f8",
    "id": null,
    "metadata": {},
    "name": "CreateAndroidVersionBlacklistEntryModalMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAndroidVersionBlacklistEntryModalMutation(\n  $input: CreateAndroidVersionBlacklistEntryInput!\n) {\n  createAndroidVersionBlacklistEntry(input: $input) {\n    androidVersionBlacklistEntryEdge {\n      node {\n        id\n        ...AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry\n      }\n    }\n  }\n}\n\nfragment AndroidVersionBlacklistEntryDetailPage_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {\n  ...UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry\n  ...DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry\n  id\n  androidVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  versionCode\n}\n\nfragment DeleteAndroidVersionBlacklistEntryAction_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {\n  androidVersionBlacklistEntryId\n}\n\nfragment UpdateAndroidVersionBlacklistEntryModal_androidVersionBlacklistEntry on AndroidVersionBlacklistEntry {\n  id\n  androidVersionBlacklistEntryId\n  applicationIdentifier\n  versionRange\n  versionCode\n}\n"
  }
};
})();
(node as any).hash = '58a3708bc8d7a6ace014d56a117db90c';
export default node;
