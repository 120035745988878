import Text from '@bfly/ui2/Text';
import React from 'react';

interface DataGridArrayProps {
  value: ReadonlyArray<string | number | null> | null;
}

export default function DataGridArray({ value }: DataGridArrayProps) {
  return (
    <>
      {Array.isArray(value) && value.length ? (
        <Text className="mr-1">{value?.filter(Boolean).join(', ')}</Text>
      ) : (
        <Text>-</Text>
      )}
    </>
  );
}
